import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { deleteCard, IResponseData } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

interface IProps {
  onClose: () => void;
}

function ModalText({ text, ismobile }: { text: string; ismobile: number }) {
  return (
    <Typography
      component="span"
      sx={{ px: theme.spacing(ismobile ? 0 : 6), pt: theme.spacing(2) }}
      variant={ismobile ? 'h6' : 'title2'}
    >
      {text}
    </Typography>
  );
}

function ModalButton({ onClose }: { onClose: () => void }) {
  return (
    <Button
      disableRipple
      disableTouchRipple
      fullWidth
      size="large"
      variant="contained"
      onClick={onClose}
    >
      Got it!
    </Button>
  );
}

function RemoveCardModal({ onClose }: IProps) {
  const dispatch = useAppDispatch();
  const { isDeleteCardLoading, cards, previewCardIndex } = useAppSelector(s => s.payment);
  const card = cards[previewCardIndex];
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDeleteCard = async () => {
    const response = (await dispatch(
      deleteCard(cards[previewCardIndex].documentId),
    )) as IResponseData;
    if (response && typeof response.error !== 'undefined' && !response.error) {
      onClose?.();
    }
  };

  return (
    <Box
      sx={{
        width: 'inherit',
        height: '100%',
        mt: theme.spacing(isMobile ? 2 : 0),
      }}
    >
      <Stack
        alignItems="center"
        height="inherit"
        justifyContent="space-between"
        spacing={theme.spacing(4)}
        width="inherit"
      >
        {/* {cards.length === 1 && (
          <>
            <ModalText
              ismobile={+isMobile}
              text="Your account must have a card on file."
            />
            <ModalButton onClose={onClose} />
          </>
        )} */}
        {(cards.length > 1 || cards.length === 1) && card?.isDefault && (
          <>
            <ModalText
              ismobile={+isMobile}
              text="This card is set to default. In order to remove this card, please assign a new default card."
            />
            <ModalButton onClose={onClose} />
          </>
        )}
        {cards.length !== 1 && !card?.isDefault && (
          <>
            <ModalText
              ismobile={+isMobile}
              text="Are you sure you want to remove this credit card from your
              account?"
            />
            <Stack
              direction={isMobile ? 'column-reverse' : 'row'}
              spacing={theme.spacing(2)}
              width="100%"
            >
              <Button
                disableRipple
                disableTouchRipple
                fullWidth
                size="large"
                variant="outlined"
                onClick={onClose}
              >
                Cancel
              </Button>
              <LoadingButton
                disableRipple
                disableTouchRipple
                fullWidth
                loading={isDeleteCardLoading}
                size="large"
                variant="contained"
                onClick={handleDeleteCard}
              >
                Remove card
              </LoadingButton>
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
}

export default RemoveCardModal;
