import client from 'Api';
import { CURRENT_AD_SEQUENCE_TEXT, DEFAULT_AD_SEQUENCE } from 'constants/constants';
import { adsUrl } from 'helpers/urls';
import { Dispatch } from 'redux';
import { IResponseData } from 'store/common/CommonInterface';

import { AdsActions, AdsPages, IAdvertisement } from './AdvertisementInterface';
import AdsType from './AdvertisementTypes';

// Get Current Ads Sequence from LocalStorage.
const getCurrentSequenceFromLS = () => {
  const currentAdSequence = JSON.parse(
    localStorage.getItem(CURRENT_AD_SEQUENCE_TEXT) ?? JSON.stringify(DEFAULT_AD_SEQUENCE),
  );
  return currentAdSequence;
};

// The below Positions are based on the values stored in the localStorage.
const getCommonAdPositionsByPage = (page: string): string[] => {
  let positions: string[] = [];
  switch (page) {
    case AdsPages.HOME:
    case AdsPages.NEWS:
    case AdsPages.PODCASTS:
      positions = ['A', 'B'];
      break;
    case AdsPages.FOLLOWING:
      positions = ['A'];
      break;
    case AdsPages.BUSINESS:
      positions = ['BA'];
      break;
    default:
      positions = ['A', 'B'];
      break;
  }
  return positions;
};

// Get Current Sequence from LocalStorage based on the positions.
const getPageAdSequencesByPositions = (page: string): string => {
  let currentSequence = '';
  const commonAdsSequence = getCurrentSequenceFromLS();
  if (Object.keys(commonAdsSequence).length > 0) {
    const adPositions = getCommonAdPositionsByPage(page);
    const sequences: string[] = [];
    adPositions.forEach((position: string) => {
      sequences.push(commonAdsSequence?.[position]);
    });
    currentSequence = sequences.filter(Boolean).join(',');
  }
  return currentSequence;
};

export const fetchAds = (page: string) => {
  return async (dispatch: Dispatch<AdsActions>) => {
    try {
      let adsRepsonseData: IAdvertisement[] = [];
      const currentPageSequence = getPageAdSequencesByPositions(page);
      dispatch({ type: AdsType.FETCH_COMMON_ADS_REQUEST });
      if (currentPageSequence) {
        const response: IResponseData = await client.get(
          `${adsUrl}sponsors?page=${page}&currentSequence=${currentPageSequence}`,
        );
        if (response.data && response.data.length > 0) {
          const responseData: IAdvertisement = response.data?.[0];
          const commonAdsSequence = getCurrentSequenceFromLS();
          const newSequence: string[] = responseData?.currentSequence?.split(',');
          newSequence.forEach((sequence: string) => {
            const newPosition = sequence.split('=')?.[0];
            const positionText = page === AdsPages.BUSINESS ? `B${newPosition}` : newPosition;
            commonAdsSequence[positionText] = sequence;
          });
          localStorage.setItem(CURRENT_AD_SEQUENCE_TEXT, JSON.stringify(commonAdsSequence));
          adsRepsonseData = response.data;
        }
        dispatch({
          type: AdsType.FETCH_COMMON_ADS_SUCCESS,
          payload: adsRepsonseData,
        });
        return response;
      }
      return false;
    } catch (error) {
      dispatch({ type: AdsType.FETCH_COMMON_ADS_ERROR });
      return error;
    }
  };
};

export const fetchAdsByPosition = (page: string, pagePosition: string, recursionLength = 2) => {
  return async (dispatch: Dispatch<AdsActions>) => {
    try {
      dispatch({ type: AdsType.FETCH_POSITION_ADS_REQUEST });
      const totalResponse: IAdvertisement[] = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < recursionLength; i++) {
        const currentAdSequence = getCurrentSequenceFromLS();
        const positionSequence = currentAdSequence?.[pagePosition];
        // eslint-disable-next-line no-await-in-loop
        const responseOne: IResponseData = await client.get(
          `${adsUrl}sponsors?page=${page}&currentSequence=${positionSequence}`,
        );
        if (responseOne.data && responseOne.data.length > 0) {
          const responseData: IAdvertisement = responseOne.data?.[0];
          const newSequence = responseData?.currentSequence;
          const newAdSequence = getCurrentSequenceFromLS();
          newAdSequence[pagePosition] = newSequence;
          localStorage.setItem(CURRENT_AD_SEQUENCE_TEXT, JSON.stringify(newAdSequence));
          totalResponse.push(responseOne.data?.[0]);
        }
      }

      dispatch({
        type: AdsType.FETCH_POSITION_ADS_SUCCESS,
        payload: totalResponse,
      });
      return totalResponse;
    } catch (error) {
      dispatch({ type: AdsType.FETCH_POSITION_ADS_ERROR });
      return error;
    }
  };
};

export const adTracker = async (url: string) => {
  try {
    const response: IResponseData = await client.get(url);
    return response;
  } catch (error) {
    return error;
  }
};

export const resetAdsState = () => {
  return async (dispatch: Dispatch<AdsActions>) => {
    dispatch({ type: AdsType.RESET_ADS_STATE });
  };
};

export const removeAd = (adId: string) => {
  return async (dispatch: Dispatch<AdsActions>) => {
    try {
      dispatch({ type: AdsType.REMOVE_AD_REQUEST });
      const response: IResponseData = await client.delete(`${adsUrl}sponsors?adId=${adId}`);
      dispatch({ type: AdsType.REMOVE_AD_SUCCESS });
      return response;
    } catch (error) {
      dispatch({ type: AdsType.REMOVE_AD_ERROR });
      return error;
    }
  };
};
