enum HomeTimelineType {
  RESET_HOME_TIMELINE_STATE = 'RESET_HOME_TIMELINE_STATE',

  GET_TIMELINE_FEATURED_POSTS_REQUEST = 'GET_TIMELINE_FEATURED_POSTS_REQUEST',
  GET_TIMELINE_FEATURED_POSTS_SUCCESS = 'GET_TIMELINE_FEATURED_POSTS_SUCCESS',
  GET_TIMELINE_FEATURED_POSTS_ERROR = 'GET_TIMELINE_FEATURED_POSTS_ERROR',

  GET_TIMELINE_HEADLINES_POSTS_REQUEST = 'GET_TIMELINE_HEADLINES_POSTS_REQUEST',
  GET_TIMELINE_HEADLINES_POSTS_SUCCESS = 'GET_TIMELINE_HEADLINES_POSTS_SUCCESS',
  GET_TIMELINE_HEADLINES_POSTS_ERROR = 'GET_TIMELINE_HEADLINES_POSTS_ERROR',

  FEATURED_LIKE_DISLIKE_POST_SUCCESS = 'FEATURED_LIKE_DISLIKE_POST_SUCCESS',
  ALL_POST_LIKE_DISLIKE_POST_SUCCESS = 'ALL_POST_LIKE_DISLIKE_POST_SUCCESS',

  GET_TOWN_CRIER_POST_REQUEST = 'GET_TOWN_CRIER_POST_REQUEST',
  GET_TOWN_CRIER_POST_SUCCESS = 'GET_TOWN_CRIER_POST_SUCCESS',
  GET_TOWN_CRIER_POST_ERROR = 'GET_TOWN_CRIER_POST_ERROR',
  TOWN_CRIER_POST_LIKE_DISLIKE_POST_SUCCESS = 'TOWN_CRIER_POST_LIKE_DISLIKE_POST_SUCCESS',
}

export default HomeTimelineType;
