import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomInputField from 'components/customFields/CustomInputField';
import { memo } from 'react';
import { addPodcastProvider, editPodcast, IAddPodcastProviderState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { addPodcastProviderValidationSchema } from 'utils/validationSchema';

import { PodcastType } from '../Providers';
import useAddPodcastProviderForm from './useAddPodcastProviderForm';

interface IAddPodcastProviderProps {
  closeModal: () => void;
  podcast?: PodcastType;
  isEdit?: boolean;
}

function AddPodcastProvider({ closeModal, podcast, isEdit }: IAddPodcastProviderProps) {
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { addProviderLoading } = useAppSelector(s => s.vettingDashboard);

  const initialState: IAddPodcastProviderState = {
    name: podcast?.name || '',
    externalShowLink: podcast?.externalShowLink || '',
  };

  const handelSubmit = async () => {
    const isUrlChanged = podcast?.externalShowLink !== values.externalShowLink;

    const params = {
      ...podcast,
      name: values.name,
      externalShowLink: values.externalShowLink,
      isUrlChanged,
    };

    let response;

    if (podcast) {
      response = await dispatch(editPodcast(params));
    } else {
      response = await dispatch(addPodcastProvider(values));
    }

    if (!response.error) {
      closeModal();
    }
  };

  const formik = useAddPodcastProviderForm(
    handelSubmit,
    initialState,
    addPodcastProviderValidationSchema,
  );
  const { touched, values, errors, handleChange, handleBlur, handleSubmit, isValid } = formik;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
  ) => {
    handleChange(fieldName)(e.target.value);
  };

  return (
    <Stack alignItems="center" justifyContent="center" width="100%">
      <Stack
        noValidate
        alignItems="center"
        autoComplete="off"
        component="form"
        spacing={theme.spacing(3)}
        width="100%"
        onSubmit={handleSubmit}
      >
        <CustomInputField
          fullWidth
          required
          error={!!(touched.name && errors.name)}
          errormessage={touched.name ? errors.name : undefined}
          id="name"
          label="Display Name"
          name="name"
          type="text"
          value={values.name}
          onBlur={handleBlur}
          onChange={e => handleInputChange(e, 'name')}
        />

        <CustomInputField
          fullWidth
          required
          error={!!(touched.externalShowLink && errors.externalShowLink)}
          errormessage={touched.externalShowLink ? errors.externalShowLink : undefined}
          id="externalShowLink"
          label="External ShowLink"
          name="externalShowLink"
          type="text"
          value={values.externalShowLink}
          onBlur={handleBlur}
          onChange={e => handleInputChange(e, 'externalShowLink')}
        />
        <Stack
          alignItems="center"
          direction={isMobile ? 'column' : 'row'}
          justifyContent="center"
          marginTop={`${theme.spacing(4)} !important`}
          spacing={theme.spacing(3)}
          width="100%"
        >
          <Button fullWidth size="large" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <LoadingButton
            disableRipple
            disableTouchRipple
            fullWidth
            disabled={!isValid}
            loading={addProviderLoading}
            size="large"
            type="submit"
            variant="contained"
          >
            Submit
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default memo(AddPodcastProvider);
