import cloneDeep from 'clone-deep';
import { Reducer } from 'redux';

import {
  ITrendingInitialState,
  ITrendingPosts,
  TrendingTimelineActions,
} from './TrendingInterface';
import TrendingType from './TrendingTypes';

export const initialState: ITrendingInitialState = {
  isTrendingLoading: false,
  trendingPosts: [] as ITrendingPosts[],
};

const TrendingReducer: Reducer<ITrendingInitialState, TrendingTimelineActions> = (
  state = initialState,
  action = {} as TrendingTimelineActions,
) => {
  switch (action.type) {
    case TrendingType.RESET_TRENDING_STATE: {
      return {
        ...state,
        trendingPosts: [] as ITrendingPosts[],
      };
    }
    case TrendingType.GET_TRENDING_POSTS_REQUEST: {
      return {
        ...state,
        isTrendingLoading: true,
      };
    }
    case TrendingType.GET_TRENDING_POSTS_SUCCESS: {
      return {
        ...state,
        isTrendingLoading: false,
        trendingPosts: action.payload,
      };
    }
    case TrendingType.GET_TRENDING_POSTS_ERROR: {
      return {
        ...state,
        isTrendingLoading: false,
      };
    }
    case TrendingType.TRENDING_LIKE_DISLIKE_POST_SUCCESS: {
      const selectedIndex = state.trendingPosts.findIndex(
        (e: ITrendingPosts) => e.postId === action.payload,
      );
      const trendingPosts = cloneDeep(state.trendingPosts);
      if (selectedIndex !== -1) {
        const likeStatus = !trendingPosts[selectedIndex].isLiked;
        trendingPosts[selectedIndex].isLiked = likeStatus;
        trendingPosts[selectedIndex].likesCount = likeStatus
          ? trendingPosts[selectedIndex].likesCount + 1
          : trendingPosts[selectedIndex].likesCount - 1;
      }
      return {
        ...state,
        trendingPosts,
      };
    }
    default:
      return state;
  }
};

export default TrendingReducer;
