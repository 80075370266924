import { FormikHelpers, useFormik } from 'formik';
import { businessPaymentValidationSchema } from 'utils/validationSchema';

import { IBusinessPaymentState } from '.';

const useBusinessPaymentForm = (
  onSubmit: (
    values: IBusinessPaymentState,
    formikHelpers: FormikHelpers<IBusinessPaymentState>,
  ) => void | Promise<unknown>,
  initialValues: IBusinessPaymentState,
) => {
  return useFormik<IBusinessPaymentState>({
    initialValues,
    validationSchema: businessPaymentValidationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export default useBusinessPaymentForm;
