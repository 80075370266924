import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/system/Box';
import Stack from '@mui/system/Stack';
import { PLAN_NAMES, UserType } from 'constants/constants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';
import { CookieNames, getCookieItem } from 'utils/cookies';
import { scrollToTop } from 'utils/utils';

function ProfileAccountSubmission() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const userType = getCookieItem(CookieNames.USER_TYPE);
  const { userData } = useAppSelector(s => s.account);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Stack alignItems="center" spacing={theme.spacing(2.5)}>
      <Typography
        align="center"
        component="span"
        marginBottom={theme.spacing(isMobile ? 1 : 2)}
        variant={isMobile ? 'h5' : 'h4'}
      >
        {userType === 'payment_initiated' ? PLAN_NAMES.paid_member_initiated : ''} Submission
      </Typography>
      {(userType === UserType.PAYMENT_INITIATED || userType === UserType.PAID) && (
        <Typography
          align="center"
          component="span"
          fontWeight={600}
          marginBottom={theme.spacing(isMobile ? 3 : 5)}
          variant="title2"
        >
          Payment Successful
        </Typography>
      )}
      <Typography
        align="center"
        component="span"
        marginBottom={theme.spacing(isMobile ? 3 : 5)}
        variant="title2"
      >
        Welcome to Freedom Square
      </Typography>
      <Typography
        align="center"
        component="span"
        marginBottom={theme.spacing(isMobile ? 3 : 5)}
        variant="title2"
      >
        Please take a moment to review your new profile page.
      </Typography>
      <Typography
        align="center"
        component="span"
        marginBottom={theme.spacing(isMobile ? 3 : 5)}
        variant="title2"
      >
        Updates can be made via your account profile.
      </Typography>
      <Box sx={{ mt: `${theme.spacing(4)} !important` }} width="100%">
        <Button
          disableRipple
          disableTouchRipple
          fullWidth
          size="large"
          variant="contained"
          onClick={() => navigate(`/profile-wall/${userData?.documentId}`)}
        >
          Go to My Profile Page
        </Button>
      </Box>
    </Stack>
  );
}

export default ProfileAccountSubmission;
