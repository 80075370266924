import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from 'theme';

interface IProps {
  loading: boolean;
  modalText: string;
  onClose: () => void;
  onSubmit: () => void;
  submitBtnText?: string;
}

function ConfirmationModal({
  loading,
  modalText,
  onClose,
  onSubmit,
  submitBtnText = 'Submit',
}: IProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        height: '100%',
        mt: theme.spacing(isMobile ? 2 : 0),
      }}
    >
      <Stack
        alignItems="center"
        height="inherit"
        justifyContent="space-between"
        spacing={theme.spacing(4)}
      >
        <Typography component="h6" variant={isMobile ? 'h6' : 'title2'}>
          {modalText}
        </Typography>

        <Stack
          direction={isMobile ? 'column-reverse' : 'row'}
          spacing={theme.spacing(2)}
          width="100%"
        >
          <Button
            disableRipple
            disableTouchRipple
            fullWidth
            size="large"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            disableRipple
            disableTouchRipple
            fullWidth
            loading={loading}
            size="large"
            variant="contained"
            onClick={onSubmit}
          >
            {submitBtnText}
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
}

export default ConfirmationModal;
