import { Box, Button, Container, SvgIcon, Typography, useTheme } from '@mui/material';
import Loader from 'components/loader/Loader';
import CommentsList from 'components/reactions/CommentsList';
import CommentTile from 'components/reactions/CommentTile';
import CreateComment from 'components/reactions/CreateComment';
import { ICommentFormValues, useCommentForm } from 'components/reactions/useCommentForm';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { ElementRef, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentType, IAddCommentParams, IGetCommentsParams } from 'store';
import { addComment, getCommentReplies } from 'store/comment/CommentReducer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { newsCommentValidationSchema } from 'utils/validationSchema';

import { BackIcon } from '../../helpers/images';

type IParams = {
  contentId: string;
  commentId: string;
  type: ContentType;
};

function CommentDetails() {
  const navigate = useNavigate();
  const { contentId, commentId, type } = useParams<IParams>();

  const [isPrivate, setIsPrivate] = useState<boolean>(false);

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const commentsRef = useRef<ElementRef<'div'>>();

  const { selectedComment, commentReplies, loading, addCommentLoading } = useAppSelector(
    s => s.comment,
  );

  const hasMoreData = false;

  useEffect(() => {
    getComments(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId, commentId]);

  const getComments = (initialLoad: boolean) => {
    if (commentId && type) {
      const params: IGetCommentsParams = {
        contentId: commentId,
        type,
        offset: initialLoad ? 0 : commentReplies.length,
      };

      dispatch(getCommentReplies(params));
    }
  };

  const handleAddComment = async (submittedValues: ICommentFormValues) => {
    const payload: IAddCommentParams = {
      contentId,
      comment: submittedValues.comment,
      commentText: submittedValues.comment,
      parentCommentID: commentId,
      type: type || ContentType.ARTICLE,
      isPrivate,
    };

    if (isPrivate) {
      toggleIsPrivate();
    }

    await dispatch(addComment(payload));
    commentFormik.resetForm();
  };

  const commentState: ICommentFormValues = { comment: '' };

  const commentFormik = useCommentForm(handleAddComment, commentState, newsCommentValidationSchema);

  const toggleIsPrivate = () => setIsPrivate(v => !v);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <HomeWrapper maxWidth="lg">
      <Button style={{ paddingBottom: 10 }} onClick={handleBack}>
        <SvgIcon inheritViewBox component={BackIcon} sx={{ height: '16px', width: '10px' }} />
        <Typography component="span" sx={{ ml: theme.spacing(1.25) }}>
          Back
        </Typography>
      </Button>
      <Container disableGutters maxWidth="lg">
        <ComponentWrapper isTitleInsideWrapper title="" withDivider={false}>
          {selectedComment?.documentID ? (
            <CommentTile
              disableDetails
              comment={selectedComment}
              type={type || ContentType.ARTICLE}
            />
          ) : (
            <Loader />
          )}
        </ComponentWrapper>
        <Box ref={commentsRef} sx={{ my: theme.spacing(3) }}>
          <ComponentWrapper
            isTitleInsideWrapper
            withInfiniteScroll
            infiniteScrollProps={{
              loadOnMount: true,
              hasMoreData,
              isLoading: loading,
              onBottomHit: getComments,
            }}
            title={`Responses (${commentReplies.length})`}
            withDivider={false}
          >
            <CreateComment
              commentFormik={commentFormik}
              createType={type}
              isLoading={addCommentLoading}
              isPrivate={isPrivate}
              toggleIsPrivate={toggleIsPrivate}
              type="create"
            />
            {loading ? (
              <Loader />
            ) : (
              <CommentsList comments={commentReplies} type={type || ContentType.ARTICLE} />
            )}
          </ComponentWrapper>
        </Box>
      </Container>
    </HomeWrapper>
  );
}

export default CommentDetails;
