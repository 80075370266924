import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';

export default function GuestMenu() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  return (
    <Stack
      direction="row"
      paddingLeft={theme.spacing(isMobile ? 0 : 3)}
      spacing={theme.spacing(isMobile ? 2 : 3)}
    >
      <Button
        fullWidth
        disableFocusRipple={false}
        size={isMobile ? 'small' : 'large'}
        sx={{
          boxShadow: 'unset',
          '&:hover': {
            outline: `1px solid ${theme.palette.common.white}`,
          },
          ...(isMobile && {
            border: `1px solid ${theme.palette.common.white}`,
          }),
        }}
        variant="contained"
        onClick={() => navigate('login')}
      >
        Login
      </Button>
      <Button
        fullWidth
        size={isMobile ? 'small' : 'large'}
        sx={{
          whiteSpace: 'nowrap',
          border: `1px solid ${theme.palette.common.white}`,
        }}
        variant="contained"
        onClick={() => navigate('signup')}
      >
        Sign Up
      </Button>
    </Stack>
  );
}
