import { Dispatch } from 'redux';
import {
  AlertActions,
  AlertType,
  FollowingTimelineActions,
  HomeTimelineActions,
  IAlertPayload,
  ImageType,
  IResponseData,
  UpdateCommentCountType,
} from 'store';
import FollowingTimelineType from 'store/followingTimeline/FollowingTimelineTypes';
import HomeTimelineType from 'store/homeTimeline/HomeTimelineTypes';
import TrendingType from 'store/trending/TrendingTypes';
import { CookieNames, getCookieItem, setBusinessSession } from 'utils/cookies';

import client from '../../Api';
import { authUrl } from '../../helpers/urls';
import {
  AccountActions,
  ICreateProfilePaymentPayload,
  ICreateProfilePostPayload,
  IDeleteAccountPayload,
  IGetProfilePostsParams,
  IProfilePost,
  IUserNameUpdateState,
  IUserProfileInfoState,
} from './AccountInterface';
import AccountType from './AccountTypes';

const multiPartFormHeader = {
  headers: { 'Content-Type': 'multipart/form-data' },
};

export const getUserPersonalInfo = () => {
  return async (dispatch: Dispatch<AccountActions>) => {
    try {
      const userId = getCookieItem(CookieNames.USER_ID);
      const businessType = getCookieItem(CookieNames.BUSINESS_TYPE);
      dispatch({ type: AccountType.GET_USER_INFO_REQUEST });
      const response: IResponseData = await client.get(`${authUrl}users/${userId}`);

      dispatch({ type: AccountType.GET_USER_INFO_SUCCESS, payload: response?.data });

      if (businessType !== response?.data?.businessType)
        setBusinessSession(response.data?.businessType);

      return response;
    } catch (error) {
      dispatch({ type: AccountType.GET_USER_INFO_ERROR });
      return error;
    }
  };
};

export const updateUserPersonalInfo = (userData: IUserNameUpdateState) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      dispatch({ type: AccountType.UPDATE_USER_INFO_REQUEST });
      const response: IResponseData = await client.patch(`${authUrl}users`, userData);
      dispatch({
        type: AccountType.UPDATE_USER_INFO_SUCCESS,
        payload: userData,
      });
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: { message: 'Successfully saved Username' },
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: AccountType.UPDATE_USER_INFO_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: 'Unable to update Username' },
      });
      return error;
    }
  };
};

export const changeUserPassword = (newPassword: string, oldPassword: string) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      dispatch({ type: AccountType.CHANGE_PASSWORD_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}users/updatePassword`, {
        oldPassword,
        newPassword,
      });
      dispatch({ type: AccountType.CHANGE_PASSWORD_SUCCESS });
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: { message: 'Password Updated!' },
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: AccountType.CHANGE_PASSWORD_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: 'Unable to change password' },
      });
      return error;
    }
  };
};

export const uploadProfilePhoto = (payload: any, imageID?: string) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      const formData = new FormData();
      formData.append('image', payload.image);
      formData.append('croppedImage', payload.croppedImage);
      formData.append('imageViewAttribute', JSON.stringify(payload.imageViewAttribute));
      formData.append('imageType', payload.imageType);
      formData.append('imageID', imageID || '');
      dispatch({ type: AccountType.UPLOAD_PROFILE_AVATAR_REQUEST });
      const response: IResponseData = await client.put(`${authUrl}users/image`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const successPayload: IAlertPayload = { message: 'Upload Successful' };

      dispatch({ type: AlertType.ALERT_SUCCESS, payload: successPayload });

      dispatch({ type: AccountType.UPLOAD_PROFILE_AVATAR_SUCCESS, payload: response?.data });
      return response;
    } catch (error: any) {
      dispatch({ type: AccountType.UPLOAD_PROFILE_AVATAR_ERROR });
      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const deleteProfileImage = () => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      dispatch({ type: AccountType.DELETE_PROFILE_PHOTO_REQUEST });
      const response: IResponseData = await client.delete(`${authUrl}users/image`);

      dispatch({ type: AccountType.DELETE_PROFILE_PHOTO_SUCCESS, payload: response?.data });
      return response;
    } catch (error: any) {
      dispatch({ type: AccountType.DELETE_PROFILE_PHOTO_ERROR });
      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const deleteProfileMedia = (imageId: string, imageType: ImageType) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      dispatch({ type: AccountType.DELETE_PROFILE_MEDIA_REQUEST });
      const response: IResponseData = await client.delete(`${authUrl}users/images/${imageId}`);

      dispatch({ type: AccountType.DELETE_PROFILE_MEDIA_SUCCESS, payload: { imageId, imageType } });

      const successPayload: IAlertPayload = { message: 'Deleted Successfully' };
      dispatch({ type: AlertType.ALERT_SUCCESS, payload: successPayload });
      return response;
    } catch (error: any) {
      dispatch({ type: AccountType.DELETE_PROFILE_MEDIA_ERROR });
      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const changeEmail = (email: string) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      dispatch({ type: AccountType.CHANGE_EMAIL_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}users/updateEmail`, {
        email,
      });
      dispatch({ type: AccountType.CHANGE_EMAIL_SUCCESS });
      return response;
    } catch (error: any) {
      dispatch({ type: AccountType.CHANGE_EMAIL_ERROR });
      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const deleteUserAccount = (payload: IDeleteAccountPayload) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      dispatch({ type: AccountType.DELETE_ACCOUNT_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}users/delete-account`, payload);

      dispatch({ type: AccountType.DELETE_ACCOUNT_SUCCESS, payload: response?.data });
      return response;
    } catch (error: any) {
      const errorPayload: IAlertPayload = {
        message: error?.message || 'Problem while deleting account, please try again.',
      };
      dispatch({ type: AccountType.DELETE_ACCOUNT_ERROR });
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const updateUserProfileInfo = (userData: IUserProfileInfoState) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      dispatch({ type: AccountType.UPDATE_PROFILE_INFO_REQUEST });
      const response: IResponseData = await client.patch(`${authUrl}updateProfileInfo`, userData);
      dispatch({ type: AccountType.UPDATE_PROFILE_INFO_SUCCESS, payload: userData });
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: { message: 'Successfully saved profile information' },
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: AccountType.UPDATE_PROFILE_INFO_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: 'Unable to update profile information' },
      });
      return error;
    }
  };
};

export const getProfileWallInfo = (userId: string) => {
  return async (dispatch: Dispatch<AccountActions>) => {
    try {
      dispatch({ type: AccountType.GET_PROFILE_WALL_INFO_REQUEST });
      const response: IResponseData = await client.get(`${authUrl}users/${userId}`);
      dispatch({
        type: AccountType.GET_PROFILE_WALL_INFO_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: AccountType.GET_PROFILE_WALL_INFO_ERROR });
      return error;
    }
  };
};

export const resetProfileWallState = () => {
  return async (dispatch: Dispatch<AccountActions>) => {
    dispatch({ type: AccountType.RESET_PROFILE_WALL_STATE });
  };
};

export const createProfilePayment = (payload: ICreateProfilePaymentPayload) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      dispatch({ type: AccountType.CREATE_PROFILE_PAYMENT_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}user/payments`, payload);
      dispatch({
        type: AccountType.CREATE_PROFILE_PAYMENT_SUCCESS,
        payload: response?.data,
      });
      if (response.data.status === 'failed') {
        const errorPayload: IAlertPayload = {
          message: 'Payment failed.',
        };
        dispatch({
          type: AlertType.ALERT_ERROR,
          payload: errorPayload,
        });
        return { ...response, error: true };
      }
      return response;
    } catch (error: any) {
      dispatch({ type: AccountType.CREATE_PROFILE_PAYMENT_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

const getProfilePostFormData = (payload: ICreateProfilePostPayload) => {
  const formData = new FormData();
  formData.append('textContent', payload.textContent);
  formData.append('contentDataType', payload.contentDataType);
  if (payload.mediaContent) {
    formData.append('mediaContent', payload.mediaContent);
    formData.append('croppedMediaContent', payload.croppedMediaContent);
    formData.append('imageViewAttribute', payload.imageViewAttribute);
  }
  return formData;
};

export const createProfilePost = (payload: ICreateProfilePostPayload) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      dispatch({ type: AccountType.CREATE_PROFILE_POST_REQUEST });
      const createProfilePostPayload = getProfilePostFormData(payload);

      const response: IResponseData = await client.post(
        `${authUrl}content`,
        createProfilePostPayload,
        multiPartFormHeader,
      );
      dispatch({
        type: AccountType.CREATE_PROFILE_POST_SUCCESS,
        payload: response.data as IProfilePost,
      });

      const successPayload: IAlertPayload = {
        message: 'Successfully created profile post',
      };
      dispatch({ type: AlertType.ALERT_SUCCESS, payload: successPayload });

      return response;
    } catch (error: any) {
      dispatch({ type: AccountType.CREATE_PROFILE_POST_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const editProfilePost = (contentId: string, payload: ICreateProfilePostPayload) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      dispatch({ type: AccountType.EDIT_PROFILE_POST_REQUEST });
      const createProfilePostPayload = getProfilePostFormData(payload);

      const response: IResponseData = await client.put(
        `${authUrl}content/${contentId}`,
        createProfilePostPayload,
        multiPartFormHeader,
      );
      dispatch({
        type: AccountType.EDIT_PROFILE_POST_SUCCESS,
        payload: response.data as IProfilePost,
      });

      const successPayload: IAlertPayload = {
        message: 'Successfully updated profile post',
      };
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: successPayload,
      });

      return response;
    } catch (error: any) {
      dispatch({ type: AccountType.EDIT_PROFILE_POST_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const resetProfilePostsState = () => {
  return async (dispatch: Dispatch<AccountActions>) => {
    dispatch({ type: AccountType.RESET_PROFILE_POSTS_STATE });
  };
};

export const fetchProfilePosts = (params: IGetProfilePostsParams) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      const { userId, offset, limit, reset } = params;

      dispatch({ type: AccountType.FETCH_PROFILE_POSTS_REQUEST });
      const response: IResponseData = await client.get(
        `${authUrl}/${userId}/content?limit=${limit}&offset=${offset}`,
      );

      const payload = {
        posts: (response?.data || []) as IProfilePost[],
        offset: offset + 10,
        reset,
        hasMoreData: response?.data?.length === 10,
      };
      dispatch({ type: AccountType.FETCH_PROFILE_POSTS_SUCCESS, payload });
      return response;
    } catch (error: any) {
      dispatch({ type: AccountType.FETCH_PROFILE_POSTS_ERROR });
      return error;
    }
  };
};

export const deleteProfilePost = (postId: string) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      dispatch({ type: AccountType.DELETE_PROFILE_POST_REQUEST });
      const response: IResponseData = await client.delete(`${authUrl}content/${postId}`);
      dispatch({
        type: AccountType.DELETE_PROFILE_POST_SUCCESS,
        payload: postId,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: AccountType.DELETE_PROFILE_POST_ERROR });
      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const likeDislikeProfilePost = (
  contentId: string,
  isLiked: boolean,
  component: 'profile' | 'trending' | 'following' | 'home',
  type?:
    | 'profile-single-post'
    | 'trending'
    | 'profile-wall'
    | 'featured'
    | 'all-home-business'
    | 'home-town-crier-post',
) => {
  return async (
    dispatch: Dispatch<
      AccountActions | FollowingTimelineActions | HomeTimelineActions | AlertActions
    >,
  ) => {
    try {
      dispatch({ type: AccountType.LIKE_DISLIKE_PROFILE_WALL_POST_REQUEST });
      let response: IResponseData;
      const likeDislikeUrl = `${authUrl}content/${contentId}/like`;

      if (!isLiked) {
        response = await client.post(likeDislikeUrl);
      } else {
        response = await client.delete(likeDislikeUrl);
      }
      if (component === 'profile' && type === 'profile-wall') {
        dispatch({ type: AccountType.LIKE_DISLIKE_PROFILE_WALL_POST_SUCCESS, payload: contentId });
      } else if (component === 'profile' && type === 'profile-single-post') {
        dispatch({ type: AccountType.LIKE_DISLIKE_PROFILE_DETAILS_POST_SUCCESS });
      } else if (component === 'trending') {
        dispatch({ type: TrendingType.TRENDING_LIKE_DISLIKE_POST_SUCCESS, payload: contentId });
      } else if (component === 'following') {
        dispatch({
          type: FollowingTimelineType.FOLLOWING_LIKE_DISLIKE_POST_SUCCESS,
          payload: contentId,
        });
      } else if (component === 'home' && type === 'featured') {
        dispatch({ type: HomeTimelineType.FEATURED_LIKE_DISLIKE_POST_SUCCESS, payload: contentId });
      } else if (component === 'home' && type === 'all-home-business') {
        dispatch({ type: HomeTimelineType.ALL_POST_LIKE_DISLIKE_POST_SUCCESS, payload: contentId });
      } else if (component === 'home' && type === 'home-town-crier-post') {
        dispatch({
          type: HomeTimelineType.TOWN_CRIER_POST_LIKE_DISLIKE_POST_SUCCESS,
          payload: contentId,
        });
      }
      // if (component === "business" && type === "business-single-post") {
      //   dispatch({ type: BusinessType.LIKE_DISLIKE_BUSINESS_POST_SUCCESS });
      // } else if (component === "business" && type === "business-wall") {
      //   dispatch({
      //     type: BusinessType.BUSINESS_LIKE_DISLIKE_BUSINESS_POST_SUCCESS,
      //     payload: contentId,
      //   });
      // } else if (component === "search") {
      //   dispatch({
      //     type: SearchType.SEARCH_LIKE_DISLIKE_POST_SUCCESS,
      //     payload: contentId,
      //   });
      // }

      return response;
    } catch (error: unknown) {
      dispatch({ type: AccountType.LIKE_DISLIKE_PROFILE_WALL_POST_ERROR });
      const e = error as IResponseData;
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: e?.message },
      });
      return error;
    }
  };
};

export const getProfilePostDetailsById = (contentId: string) => {
  return async (dispatch: Dispatch<AccountActions>) => {
    try {
      dispatch({ type: AccountType.FETCH_PROFILE_POST_DETAILS_REQUEST });
      const response: IResponseData = await client.get(`${authUrl}content/${contentId}`);

      dispatch({ type: AccountType.FETCH_PROFILE_POST_DETAILS_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: AccountType.FETCH_PROFILE_POST_DETAILS_ERROR });
      return error;
    }
  };
};

export const resetProfilePostDetailsState = () => {
  return async (dispatch: Dispatch<AccountActions>) => {
    dispatch({ type: AccountType.RESET_PROFILE_POST_DETAILS_STATE });
  };
};

export const followAndUnFollowProfile = (userId: string, type: string) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      const url =
        type === 'follow' ? `${authUrl}user/${userId}/follow` : `${authUrl}user/${userId}/unfollow`;
      dispatch({ type: AccountType.FOLLOW_AND_UNFOLLOW_PROFILE_WALL_REQUEST });
      const response: IResponseData = await client.post(url);
      dispatch({
        type: AccountType.FOLLOW_AND_UNFOLLOW_PROFILE_WALL_SUCCESS,
      });
      const successPayload: IAlertPayload = {
        message: type === 'follow' ? 'Successfully followed user' : 'Successfully unfollowed user',
      };
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: successPayload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: AccountType.FOLLOW_AND_UNFOLLOW_PROFILE_WALL_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const fetchProfileMedia = (params: any) => {
  return async (dispatch: Dispatch<AccountActions | AlertActions>) => {
    try {
      const { userId, offset, limit, reset } = params;
      dispatch({ type: AccountType.FETCH_PROFILE_MEDIA_REQUEST });
      const response: IResponseData = await client.get(
        `${authUrl}${userId}/media?limit=${limit}&offset=${offset}`,
      );

      dispatch({
        type: AccountType.FETCH_PROFILE_MEDIA_SUCCESS,
        payload: {
          profileMedia: response?.data || {},
          offset: offset + 10,
          hasMoreData: response?.data?.length === 10,
          reset,
        },
      });

      return response;
    } catch (error: any) {
      dispatch({ type: AccountType.FETCH_PROFILE_MEDIA_ERROR });
      return error;
    }
  };
};

export const resetProfileMediaState = () => {
  return async (dispatch: Dispatch<AccountActions>) => {
    dispatch({ type: AccountType.RESET_PROFILE_MEDIA_STATE });
  };
};

export const updateProfilePostCommentCount = (type: UpdateCommentCountType) => {
  return async (dispatch: Dispatch<AccountActions>) => {
    dispatch({ type: AccountType.UPDATE_COMMENT_COUNT, payload: type });
  };
};
export const updateProfilePostContent = (params: any) => {
  return async (dispatch: Dispatch<AccountActions>) => {
    dispatch({ type: AccountType.PROFILE_UPDATE_POST_CONTENT, payload: params });
  };
};
