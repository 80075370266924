import Stack from '@mui/material/Stack';
import { baseUIUrl } from 'helpers/urls';
import styles from 'theme/styles';

export default function PrivacyPolicy() {
  return (
    <Stack sx={styles.tncStyles}>
      <p>
        <strong>Freedom Square Website Privacy Policy</strong>
      </p>
      <p>Last modified: November 1, 2022</p>
      <p>
        <strong>Introduction</strong>
      </p>
      <p>
        Freedom Square, Inc. (<strong>&quot;Company&quot;</strong> or
        <strong>&nbsp;&quot;We&quot;</strong>) respect your privacy and are committed to protecting
        it through our compliance with this policy.
      </p>
      <p>
        This policy describes the types of information we may collect from you or that you may
        provide when you visit the website www.freedomsquare.com (our &quot;<strong>Website</strong>
        &quot;) and our practices for collecting, using, maintaining, protecting, and disclosing
        that information.
      </p>
      <p>This policy applies to information we collect:</p>
      <ul>
        <li>On this Website.</li>
        <li>In email, text, and other electronic messages between you and this Website.</li>
        <li>
          Through mobile and desktop applications you download from this Website, which provide
          dedicated non-browser-based interaction between you and this Website.
        </li>
        <li>
          When you interact with our advertising and applications on third-party websites and
          services, if those applications or advertising include links to this policy.
        </li>
        <li>Directly from you when you provide it to Us.</li>
        <li>
          Automatically as you navigate through the site. (Information collected automatically may
          include usage details, IP addresses, and information collected through cookies).
        </li>
      </ul>
      <p>It does not apply to information collected by:</p>
      <ul>
        <li>
          Us offline or through any other means, including on any other website operated by Company
          or any third party (including our affiliates and subsidiaries); or
        </li>
        <li>
          Any third party (including our affiliates and subsidiaries), including through any
          application or content (including advertising) that may link to or be accessible from the
          Website
        </li>
      </ul>
      <p>
        Please read this policy carefully to understand our policies and practices regarding your
        information and how we will treat it. If you do not agree with our policies and practices,
        your choice is not to use our Website. By accessing or using this Website, you agree to this
        privacy policy. This policy may change from time to time (see Changes to Our Privacy
        Policy). Your continued use of this Website after we make changes is deemed to be acceptance
        of those changes, so please check the policy periodically for updates.
      </p>
      <p>
        <strong>Children Under the Age of 18</strong>
      </p>
      <p>
        Our Website is not intended for children under 18 years of age. No one under age 18 may
        provide any personal information to or on the Website. We do not knowingly collect personal
        information from children under 18. If you are under 18, do not use or provide any
        information on this Website or through any of its features, register on the Website, make
        any purchases through the Website, use any of the interactive or public comment features of
        this Website, or provide any information about yourself to us, including your name, address,
        telephone number, email address, or any screen name or user name you may use. If we learn we
        have collected or received personal information from a child under 18 without verification
        of parental consent, we will delete that information. If you believe we might have any
        information from or about a child under 18, please contact us at{' '}
        <a href="mailto:admin@freedomsquare.com">admin@freedomsquare.com</a>.
      </p>
      <p>
        <strong>Information We Collect About You and How We Collect It</strong>
      </p>
      <p>
        We collect several types of information from and about users of our Website, including
        information:
      </p>
      <ul>
        <li>
          By which you may be personally identified, such as name, postal address, e-mail address,
          or telephone number, (&quot;
          <strong>personal information</strong>&quot;); and/or
        </li>
        <li>
          About your internet connection, the equipment you use to access our Website, and usage
          details.
        </li>
      </ul>
      <p>We collect this information:</p>
      <ul>
        <li>Directly from you when you provide it to us.</li>
        <li>
          Automatically as you navigate through the site. Information collected automatically may
          include usage details, IP addresses, and information collected through cookies, web
          beacons, and other tracking technologies.
        </li>
        <li>From third parties, for example, our business partners.</li>
      </ul>
      <p>
        <em>Information You Provide to Us</em>
        <strong>&nbsp;</strong>
      </p>
      <p>The information we collect on or through our Website may include:</p>
      <ul>
        <li>
          Information that you provide by filling in forms on our Website. This includes information
          provided at the time of registering to use our Website, subscribing to our service,
          posting material, or requesting further services.
        </li>
        <li>
          Records and copies of your correspondence (including email addresses), if you contact us.
        </li>
        <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
        <li>
          Details of transactions you carry out through our Website and of the fulfillment of your
          orders. You may be required to provide financial information before placing an order
          through our Website.
        </li>
        <li>Your search queries on the Website.</li>
      </ul>
      <p>
        You also may provide information to be published or displayed (hereinafter, &quot;
        <strong>posted</strong>&quot;) on public areas of the Website, or transmitted to other users
        of the Website or third parties (collectively, &quot;<strong>User Contributions</strong>
        &quot;). Your User Contributions are posted on and transmitted to others at your own risk.
        Although we limit access to certain pages, please be aware that no security measures are
        perfect or impenetrable. Additionally, we cannot control the actions of other users of the
        Website with whom you may choose to share your User Contributions. Therefore, we cannot and
        do not guarantee that your User Contributions will not be viewed by unauthorized persons.
      </p>
      <p>
        <em>Information We Collect Through Automatic Data Collection Technologies</em>
        <strong>
          <em>&nbsp;</em>
        </strong>
      </p>
      <p>
        As you navigate through and interact with our Website, we may use automatic data collection
        technologies to collect certain information about your equipment, browsing actions, and
        patterns, including:
      </p>
      <ul>
        <li>
          Details of your visits to our Website, including traffic data, location data, logs, and
          other communication data and the resources that you access and use on the Website.
        </li>
        <li>
          Information about your computer and internet connection, including your IP address,
          operating system, and browser type.
        </li>
      </ul>
      <p>
        <strong>Third-Party Use of Cookies and Other Tracking Technologies</strong>
      </p>
      <p>
        Some content or applications, including advertisements, on the Website are served by
        third-parties, including advertisers, ad networks and servers, content providers, and
        application providers. These third parties may use cookies alone or in conjunction with web
        beacons or other tracking technologies to collect information about you when you use our
        website. The information they collect may be associated with your personal information or
        they may collect information, including personal information, about your online activities
        over time and across different websites and other online services. They may use this
        information to provide you with interest-based (behavioral) advertising or other targeted
        content.
      </p>
      <p>
        We do not control these third parties&apos; tracking technologies or how they may be used.
        If you have any questions about an advertisement or other targeted content, you should
        contact the responsible provider directly. In addition, users can opt out of the Google
        Analytics Advertising Features through their Google Ad Settings, Ad Settings for mobile
        apps, Google Analytics opt out Browser Add On, and the NAI&rsquo;s Consumer Opt Out.
      </p>
      <p>
        <strong>How We Use Your Information</strong>
      </p>
      <p>
        We use information that we collect about you or that you provide to us, including any
        personal information:
      </p>
      <ul>
        <li>To present our Website and its contents to you.</li>
        <li>To provide you with information, products, or services that you request from us.</li>
        <li>To fulfill any other purpose for which you provide it.</li>
        <li>
          To provide you with notices about your account, including expiration and renewal notices.
        </li>
        <li>
          To carry out our obligations and enforce our rights arising from any contracts entered
          into between you and us, including for billing and collection.
        </li>
        <li>
          To notify you about changes to our Website or any products or services we offer or provide
          though it.
        </li>
        <li>To allow you to participate in interactive features on our Website.</li>
        <li>In any other way we may describe when you provide the information.</li>
        <li>For any other purpose with your consent.</li>
      </ul>
      <p>
        <strong>Disclosure of Your Information</strong>
      </p>
      <p>
        We may disclose aggregated information about our users, and information that does not
        identify any individual, without restriction.
      </p>
      <p>
        We may disclose personal information that we collect or you provide as described in this
        privacy policy:
      </p>
      <ul>
        <li>To our subsidiaries and affiliates.</li>
        <li>
          To contractors, service providers, and other third parties we use to support our business
          and who are bound by contractual obligations to keep personal information confidential and
          use it only for the purposes for which we disclose it to them.
        </li>
        <li>
          To a buyer or other successor in the event of a merger, divestiture, restructuring,
          reorganization, dissolution, or other sale or transfer of some or all of Freedom
          Square&apos;s assets, whether as a going concern or as part of bankruptcy, liquidation, or
          similar proceeding, in which personal information held by Freedom Square about our Website
          users is among the assets transferred.
        </li>
        <li>
          To third parties to market their products or services to you if you have consented to/not
          opted out of these disclosures. We contractually require these third parties to keep
          personal information confidential and use it only for the purposes for which we disclose
          it to them.
        </li>
        <li>
          To fulfill the purpose for which you provide it. [For example, if you give us an email
          address to use the &quot;email a friend&quot; feature of our Website, we will transmit the
          contents of that email and your email address to the recipients.]
        </li>
        <li>For any other purpose disclosed by us when you provide the information.</li>
        <li>With your consent.</li>
      </ul>
      <p>We may also disclose your personal information:</p>
      <ul>
        <li>
          To comply with any court order, law, or legal process, including to respond to any
          government or regulatory request.
        </li>
        <li>
          To enforce or apply our <a href={`${baseUIUrl}account/terms`}>terms of use</a> and other
          agreements, including for billing and collection purposes.
        </li>
        <li>
          If we believe disclosure is necessary or appropriate to protect the rights, property, or
          safety of Freedom Square, our customers, or others. This includes exchanging information
          with other companies and organizations for the purposes of fraud protection and credit
          risk reduction.
        </li>
      </ul>
      <p>
        <strong>Choices About How We Use and Disclose Your Information</strong>
      </p>
      <p>
        We strive to provide you with choices regarding the personal information you provide to us.
        We have created mechanisms to provide you with the following control over your information:
      </p>
      <ul>
        <li>
          <strong>Tracking Technologies and Advertising.</strong> You can set your browser to refuse
          all or some browser cookies, or to alert you when cookies are being sent. To learn how you
          can manage your Flash cookie settings, visit the Flash player settings page on
          Adobe&apos;s website. If you disable or refuse cookies, please note that some parts of
          this site may then be inaccessible or not function properly.
        </li>
        <li>
          <strong>Promotional Offers from the Company.</strong> If you do not wish to have your
          email address used by the Company to promote our own or third parties&apos; products or
          services, you can opt-out by checking the relevant box located on the form on which we
          collect your data (the registration form or at any other time by logging into the Website
          and adjusting your user preferences in your account profile by checking or unchecking the
          relevant boxes or by sending us an email stating your request to{' '}
          <a href="mailto:DMCAnotices@freedomsquare.com">DMCAnotices@freedomsquare.com</a>. If we
          have sent you a promotional email, you may send us a return email asking to be omitted
          from future email distributions.
        </li>
        <li>
          <strong>Targeted Advertising.</strong> If you do not want us to use information that we
          collect or that you provide to us to deliver advertisements according to our
          advertisers&apos; target-audience preferences, you can opt-out by emailing us at
          <a href="mailto:DMCAnotices@freedomsquare.com">DMCAnotices@freedomsquare.com</a>.
        </li>
      </ul>
      <p>
        We do not control third parties&apos; collection or use of your information to serve
        interest-based advertising. However, these third parties may provide you with ways to choose
        not to have your information collected or used in this way. You can opt out of receiving
        targeted ads from members of the Network Advertising Initiative (&quot;
        <strong>NAI</strong>&quot;) on the NAI&apos;s website. In addition, users can opt out of the
        Google Analytics Advertising Features through their Google Ad Settings, Ad Settings for
        mobile apps, Google Analytics opt out Browser Add On, and the NAI&rsquo;s Consumer Opt Out.
      </p>
      <p>
        <strong>Accessing and Correcting Your Information</strong>
      </p>
      <p>
        You can review and change your personal information by logging into the Website and visiting
        your account profile page.
      </p>
      <p>
        You may also send us an email at{' '}
        <a href="mailto:DMCAnotices@freedomsquare.com">DMCAnotices@freedomsquare.com</a> to request
        access to, correct or delete any personal information that you have provided to us. We
        cannot delete your personal information except by also deleting your user account. We may
        not accommodate a request to change information if we believe the change would violate any
        law or legal requirement or cause the information to be incorrect.
      </p>
      <p>
        <strong>YOUR CALIFORNIA PRIVACY RIGHTS</strong>
      </p>
      <p>
        If you are a California resident you have the right to request information from us regarding
        the manner in which we share certain categories of your personal information with third
        parties for the third parties&rsquo; direct marketing purposes. California law provides that
        you have the right to submit a request to us at our designated address and receive the
        following information: the categories of information we disclosed to third parties for the
        third parties&rsquo; direct marketing purposes during the preceding calendar year; and the
        names and addresses of third parties that received such information, or if the nature of
        their business cannot be determined from the name, then examples of the products or services
        marketed.
      </p>
      <p>
        You are entitled to receive a copy of this information in a standardized format and the
        information will not be specific to you individually. You may make such a request by
        emailing{' '}
        <strong>
          <a href="mailto:support@freedomsquare.com">support@freedomsquare.com</a>.
        </strong>
      </p>
      <p>
        <strong>EUROPEAN UNION CITIZENS&rsquo; RIGHTS UNDER GDPR</strong>
      </p>
      <p>
        If you are a citizen under the European Union, you have certain rights under the General
        Data Protection Regulation regarding information we collect, including the following:&nbsp;
      </p>
      <ul>
        <li>
          The right to be provided with a copy of your personal information (the right of access).
        </li>
        <li>The right to require us to correct any mistakes in your personal information.</li>
        <li>The right to require us to delete your personal information in certain situations.</li>
        <li>
          The right to require us to restrict processing of your personal information in certain
          circumstances, e.g. if you contest the accuracy of the data.
        </li>
        <li>
          The right to receive the personal information you provided to us, in a structured,
          commonly used and machine-readable format and/or transmit that data to a third party
          &ndash; in certain situations.
        </li>
        <li>
          The right to object:
          <ul>
            <li>
              at any time to your personal information being processed for direct marketing
              (including profiling);
            </li>
            <li>
              in certain other situations to our continued processing of your personal information,
              e.g. processing carried out for the purpose of our legitimate interests.
            </li>
          </ul>
        </li>
        <li>
          The right not to be subject to a decision based solely on automated processing (including
          profiling) that produces legal effects concerning you or similarly significantly affects
          you.
        </li>
      </ul>
      <p>
        If you are a citizen under the European Union who has provided us with information subject
        to GDPR and would like exercise any of the foregoing rights, please contact us at{' '}
        <strong>
          <a href="mailto:support@freedomsquare.com">support@freedomsquare.com</a>
        </strong>
      </p>
      <p>
        <strong>Data Security</strong>
      </p>
      <p>
        We have implemented measures designed to secure your personal information from accidental
        loss and from unauthorized access, use, alteration, and disclosure. All information you
        provide to us is stored on our secure servers behind firewalls. Any payment transactions and
        OTHER INFORMATION will be encrypted using SSL technology.
      </p>
      <p>
        The safety and security of your information also depends on you. Where we have given you (or
        where you have chosen) a password for access to certain parts of our Website, you are
        responsible for keeping this password confidential. We ask you not to share your password
        with anyone. We urge you to be careful about giving out information in public areas of the
        Website like message boards. The information you share in public areas may be viewed by any
        user of the Website.
      </p>
      <p>
        Unfortunately, the transmission of information via the internet is not completely secure.
        Although we do our best to protect your personal information, we cannot guarantee the
        security of your personal information transmitted to our Website. Any transmission of
        personal information is at your own risk. We are not responsible for circumvention of any
        privacy settings or security measures contained on the Website.
      </p>
      <p>
        <strong>Changes to Our Privacy Policy</strong>
      </p>
      <p>
        It is our policy to post any changes we make to our privacy policy on this page. If we make
        material changes to how we treat our users&apos; personal information, we will notify you by
        email to the email address specified in your account and/or through a notice on the Website
        home page. The date the privacy policy was last revised is identified at the top of the
        page. You are responsible for ensuring we have an up-to-date active and deliverable email
        address for you, and for periodically visiting our Website and this privacy policy to check
        for any changes.
      </p>
      <p>
        <strong>Contact Information</strong>
      </p>
      <p>
        To ask questions or comment about this privacy policy and our privacy practices, contact us
        at: <a href="mailto:DMCAnotices@freedomsquare.com">DMCAnotices@freedomsquare.com</a>.
      </p>
      <p>
        <br />
      </p>
    </Stack>
  );
}
