enum FollowingTimelineType {
  RESET_FOLLOWING_TIMELINE_STATE = 'RESET_FOLLOWING_TIMELINE_STATE',
  RESET_FOLLOWING_PREFERENCES_STATE = 'RESET_FOLLOWING_PREFERENCES_STATE',

  GET_FOLLOWING_POSTS_REQUEST = 'GET_FOLLOWING_POSTS_REQUEST',
  GET_FOLLOWING_POSTS_SUCCESS = 'GET_FOLLOWING_POSTS_SUCCESS',
  GET_FOLLOWING_POSTS_ERROR = 'GET_FOLLOWING_POSTS_ERROR',

  GET_ALL_BUSINESSES_REQUEST = 'GET_ALL_BUSINESSES_REQUEST',
  GET_ALL_BUSINESSES_SUCCESS = 'GET_ALL_BUSINESSES_SUCCESS',
  GET_ALL_BUSINESSES_ERROR = 'GET_ALL_BUSINESSES_ERROR',

  GET_ALL_NEWS_PROVIDERS_REQUEST = 'GET_ALL_NEWS_PROVIDERS_REQUEST',
  GET_ALL_NEWS_PROVIDERS_SUCCESS = 'GET_ALL_NEWS_PROVIDERS_SUCCESS',
  GET_ALL_NEWS_PROVIDERS_ERROR = 'GET_ALL_NEWS_PROVIDERS_ERROR',

  GET_ALL_PODCASTS_REQUEST = 'GET_ALL_PODCASTS_REQUEST',
  GET_ALL_PODCASTS_SUCCESS = 'GET_ALL_PODCASTS_SUCCESS',
  GET_ALL_PODCASTS_ERROR = 'GET_ALL_PODCASTS_ERROR',

  FOLLOW_PODCASTS_REQUEST = 'FOLLOW_PODCASTS_REQUEST',
  FOLLOW_PODCASTS_SUCCESS = 'FOLLOW_PODCASTS_SUCCESS',
  FOLLOW_PODCASTS_ERROR = 'FOLLOW_PODCASTS_ERROR',

  FOLLOW_BUSINESSES_REQUEST = 'FOLLOW_BUSINESSES_REQUEST',
  FOLLOW_BUSINESSES_SUCCESS = 'FOLLOW_BUSINESSES_SUCCESS',
  FOLLOW_BUSINESSES_ERROR = 'FOLLOW_BUSINESSES_ERROR',

  FOLLOW_NEWS_PROVIDERS_REQUEST = 'FOLLOW_NEWS_PROVIDERS_REQUEST',
  FOLLOW_NEWS_PROVIDERS_SUCCESS = 'FOLLOW_NEWS_PROVIDERS_SUCCESS',
  FOLLOW_NEWS_PROVIDERS_ERROR = 'FOLLOW_NEWS_PROVIDERS_ERROR',

  RESET_SEARCH_STATE = 'RESET_SEARCH_STATE',

  SEARCH_BUSINESSES_REQUEST = 'SEARCH_BUSINESSES_REQUEST',
  SEARCH_BUSINESSES_SUCCESS = 'SEARCH_BUSINESSES_SUCCESS',
  SEARCH_BUSINESSES_ERROR = 'SEARCH_BUSINESSES_ERROR',

  RESET_ALL_BUSINESSES_STATE = 'RESET_ALL_BUSINESSES_STATE',

  FOLLOWING_LIKE_DISLIKE_POST_SUCCESS = 'FOLLOWING_LIKE_DISLIKE_POST_SUCCESS',

  GET_ALL_FOLLOWED_BUSINESSES_REQUEST = 'GET_ALL_FOLLOWED_BUSINESSES_REQUEST',
  GET_ALL_FOLLOWED_BUSINESSES_SUCCESS = 'GET_ALL_FOLLOWED_BUSINESSES_SUCCESS',
  GET_ALL_FOLLOWED_BUSINESSES_ERROR = 'GET_ALL_FOLLOWED_BUSINESSES_ERROR',

  GET_ALL_PAID_USERS_REQUEST = 'GET_ALL_PAID_USERS_REQUEST',
  GET_ALL_PAID_USERS_SUCCESS = 'GET_ALL_PAID_USERS_SUCCESS',
  GET_ALL_PAID_USERS_ERROR = 'GET_ALL_PAID_USERS_ERROR',

  GET_ALL_FOLLOWED_PAID_USERS_REQUEST = 'GET_ALL_FOLLOWED_PAID_USERS_REQUEST',
  GET_ALL_FOLLOWED_PAID_USERS_SUCCESS = 'GET_ALL_FOLLOWED_PAID_USERS_SUCCESS',
  GET_ALL_FOLLOWED_PAID_USERS_ERROR = 'GET_ALL_FOLLOWED_PAID_USERS_ERROR',

  SEARCH_PAID_USERS_REQUEST = 'SEARCH_PAID_USERS_REQUEST',
  SEARCH_PAID_USERS_SUCCESS = 'SEARCH_PAID_USERS_SUCCESS',
  SEARCH_PAID_USERS_ERROR = 'SEARCH_PAID_USERS_ERROR',

  FOLLOW_PAID_USERS_REQUEST = 'FOLLOW_PAID_USERS_REQUEST',
  FOLLOW_PAID_USERS_SUCCESS = 'FOLLOW_PAID_USERS_SUCCESS',
  FOLLOW_PAID_USERS_ERROR = 'FOLLOW_PAID_USERS_ERROR',

  RESET_ALL_PAID_USERS_STATE = 'RESET_ALL_PAID_USERS_STATE',
  RESET_SEARCH_PAID_USERS_STATE = 'RESET_SEARCH_PAID_USERS_STATE',
}

export default FollowingTimelineType;
