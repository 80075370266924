import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import HeaderMenu from 'components/menu/HeaderMenu';
import { UserType } from 'constants/constants';
import { AddIcon, HomeIcon } from 'helpers/images';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';
import { checkBusiness, isDeactivatedBusiness } from 'utils/utils';

interface INav {
  title: string;
  route: string;
  icon?: string | React.ReactElement;
  visibleInMobileViewNav?: boolean;
}

export const NAV_ITEMS: INav[] = [
  {
    title: 'Home',
    route: '/home',
    icon: <HomeIcon />,
    visibleInMobileViewNav: false,
  },
  // {
  //   title: "Notifications",
  //   route: "/notifications",
  //   icon: <NotificationIcon />,
  //   visibleInMobileViewNav: true,
  // },
  {
    title: 'Add Business',
    route: '/business-plan',
    icon: <AddIcon />,
    visibleInMobileViewNav: false,
  },
  {
    title: 'Profile Wall',
    route: '/profile-wall',
    icon: <PersonIcon />,
    visibleInMobileViewNav: false,
  },
];

interface ILinkTextProps {
  title: string;
  route: string;
  icon?: string | React.ReactElement;
  showNav?: boolean;
}

export function LinkNav({ title, route, icon, showNav }: ILinkTextProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  return (
    <Stack
      end
      component={NavLink}
      height="100%"
      justifyContent="center"
      position="relative"
      sx={{
        display: !isMobile ? 'flex' : showNav ? 'flex' : 'none',
        cursor: 'pointer',
        textDecoration: 'none !important',
        '&.active .MuiBox-root': {
          width: '100%',
          backgroundColor: theme.palette.secondary.main,
        },
        '&:hover .MuiBox-root': {
          width: '100%',
          backgroundColor: theme.palette.secondary.main,
        },
      }}
      to={route}
    >
      <Stack
        alignItems="center"
        height={{ xs: 'auto', midsm: '52px' }}
        justifyContent="space-between"
      >
        {icon}
        {!isMobile && (
          <Typography noWrap color="common.white" variant="caption">
            {title}
          </Typography>
        )}
      </Stack>
      <Box
        sx={{
          height: '3px',
          transition: 'width 0.3s ease-in-out',
          backgroundColor: 'transparent',
          position: 'absolute',
          bottom: 0,
          width: 0,
        }}
      />
    </Stack>
  );
}

function HeaderNav() {
  const { businessDetails, userBusinessId } = useAppSelector(s => s.business);
  const { userData } = useAppSelector(s => s.account);

  return (
    <Stack
      component="nav"
      direction="row"
      height="100%"
      paddingLeft={{
        xs: theme.spacing(0),
        midmd: theme.spacing(5),
      }}
    >
      <Stack
        alignItems="center"
        boxSizing="border-box"
        direction="row"
        height="100%"
        spacing={{
          xs: theme.spacing(2),
          midsm: theme.spacing(4),
          midmd: theme.spacing(5),
        }}
      >
        <>
          {NAV_ITEMS.map((n, index) => {
            if (n.route === '/business-plan') {
              if (checkBusiness(businessDetails)) {
                return (
                  <LinkNav
                    key={`${n.title}_1_${index.toString()}`}
                    icon={
                      <BusinessCenterIcon
                        sx={{
                          color: theme.palette.common.white,
                          fontSize: theme.spacing(3.5),
                        }}
                      />
                    }
                    route={`/business-wall/${userBusinessId}`}
                    showNav={false}
                    title="Business Wall"
                  />
                );
              }

              if (isDeactivatedBusiness(businessDetails)) {
                return (
                  <LinkNav
                    key={`${n.title}_2_${index.toString()}`}
                    icon={
                      <BusinessCenterIcon
                        sx={{
                          color: theme.palette.common.white,
                          fontSize: theme.spacing(3.5),
                        }}
                      />
                    }
                    route="/business-deactivated-info"
                    showNav={false}
                    title="Business Wall"
                  />
                );
              }
            }

            if (n.route === '/profile-wall' && userData?.userType === UserType.PAID) {
              return (
                <LinkNav
                  key={`${n.title}_3_${index.toString()}`}
                  icon={
                    <PersonIcon
                      sx={{
                        color: theme.palette.common.white,
                        fontSize: theme.spacing(3.5),
                      }}
                    />
                  }
                  route={`${n.route}/${userData?.documentId}`}
                  showNav={n.visibleInMobileViewNav}
                  title={n.title}
                />
              );
            }

            if (n.route !== '/profile-wall') {
              return (
                <LinkNav
                  key={`${n.title}_4_${index.toString()}`}
                  icon={n.icon}
                  route={n.route}
                  showNav={n.visibleInMobileViewNav}
                  title={n.title}
                />
              );
            }

            return <></>;
          })}
          <Box height={{ xs: 'auto', midsm: '52px' }}>
            <HeaderMenu />
          </Box>
        </>
      </Stack>
    </Stack>
  );
}

export default HeaderNav;
