import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccountDesktopNav from 'components/menu/AccountDesktopNav';
import AccountMobileNav from 'components/menu/AccountMobileNav';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

function Account() {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  useEffect(() => {
    if (!isMobile && location.pathname === '/account') {
      navigate('/account/profile');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <HomeWrapper>
      <Box
        border={isMobile ? 'none' : `0.25px solid ${theme.palette.text.secondary}`}
        borderRadius="4px"
        boxSizing="border-box"
        height="100%"
        padding={theme.spacing(isMobile ? 0 : 5)}
        width="100%"
      >
        <Grid container>
          {isMobile ? (
            outlet === null && (
              <Grid item xs={12}>
                <Stack spacing={theme.spacing(2)} sx={{ px: theme.spacing(2) }}>
                  <AccountMobileNav />
                </Stack>
              </Grid>
            )
          ) : (
            <Grid item xs={4}>
              <AccountDesktopNav />
            </Grid>
          )}
          <Grid item midsm={6} xs={12}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </HomeWrapper>
  );
}

export default Account;
