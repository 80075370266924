import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Checkbox from "@mui/material/Checkbox";
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CardNumberInput from 'components/customFields/CardNumberInput';
import CustomInputField from 'components/customFields/CustomInputField';
import CustomSwitch from 'components/customFields/CustomSwitch';
import CustomSelectField from 'components/customSelect/CustomSelectField';
import useCard from 'hooks/useCard';
import React, { useEffect, useState } from 'react';
import { createProfilePayment, getCards, ICardData, IResponseData } from 'store';
import {
  createBusinessPayment,
  getStates,
  paymentExistingCard,
} from 'store/business/BusinessAction';
import { ICreateBusinessPaymentPayload } from 'store/business/BusinessInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import {
  handleAllowOnlyNumber,
  handleAllowOnlyOneSpaceBetweenWords,
  handleAllowOnlyOneSpaceBetweenWordsAndAlphabets,
} from 'utils/utils';

import PaymentSummary from './PaymentSummary';
import useBusinessPaymentForm from './useBusinessPayment';

export interface IBusinessPaymentState {
  billingName: string;
  billingAddress: {
    address: string;
    city: string;
    state: string;
    zipcode: string;
  };
  paymentInfo: {
    alias: string;
    cardNumber: string;
    expiryMonth: string;
    expiryYear: string;
  };
  isMonthly: boolean;
  partnerCode: string;
}

interface IBusinessBusinessPaymentProps {
  handleNext: () => void;
  handlePrev: () => void;
  type: string;
}

export default function BusinessPayment({
  handleNext,
  handlePrev,
  type,
}: IBusinessBusinessPaymentProps) {
  const dispatch = useAppDispatch();
  const { removeNonNumber } = useCard();

  const { businessDetails, loading, states } = useAppSelector(s => s.business);
  const { loading: isGetCardsLoading, cards } = useAppSelector(s => s.payment);
  const { userData, profilePaymentLoading } = useAppSelector(s => s.account);

  const businessId: string = businessDetails.documentId ?? '';
  const currentYear = new Date().getFullYear().toString().slice(-2);
  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  // const [checked, setChecked] = useState(false);
  const [selectCardOption, setSelectCardOption] = useState('old');
  const [selectedExistingCard, setSelectedExistingCard] = useState<string>(
    cards.length > 0 ? cards[0].documentId : '',
  );

  const initialState: IBusinessPaymentState = {
    billingName: '',
    billingAddress: {
      address: '',
      city: '',
      state: '',
      zipcode: '',
    },
    paymentInfo: {
      alias: type === 'business' ? businessDetails?.name : userData.userName || '',
      cardNumber: '',
      expiryMonth: currentMonth,
      expiryYear: currentYear,
    },
    isMonthly: false,
    partnerCode: '',
  };

  const getCreditCards = async () => {
    const response: IResponseData = (await dispatch(getCards())) as IResponseData;
    if (response && typeof response.error !== 'undefined' && !response.error) {
      if (response.data.length > 0) {
        setSelectedExistingCard(response.data[0].documentId);
      } else {
        setSelectCardOption('new');
      }
    }
  };

  useEffect(() => {
    if (cards?.length === 0) {
      getCreditCards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStates = async () => {
    await dispatch(getStates());
  };

  useEffect(() => {
    if (!states.length) {
      fetchStates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectCardOption === 'old' && Object.keys(values).length > 0) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCardOption]);

  const handleCardOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectCardOption((event.target as HTMLInputElement).value);
  };

  const handleExistingCardSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedExistingCard(event.target.value);
  };

  // const handleChecked = () => {
  //   if (!checked) {
  //     setValues({
  //       ...values,
  //       billingAddress: {
  //         address: businessDetails?.address ?? "",
  //         city: businessDetails?.city ?? "",
  //         state: businessDetails?.stateId ?? "",
  //         zipcode: businessDetails?.zipcode ?? "",
  //       },
  //     });
  //   } else {
  //     setValues({
  //       ...values,
  //       billingAddress: {
  //         address: "",
  //         city: "",
  //         state: "",
  //         zipcode: "",
  //       },
  //     });
  //   }
  //   setChecked(!checked);
  // };

  const handleDisablePastMonth = (month: string): boolean => {
    return parseInt(values.paymentInfo.expiryYear, 10) > parseInt(currentYear, 10)
      ? false
      : parseInt(month, 10) < parseInt(currentMonth, 10);
  };

  const existingCardPayment = async () => {
    const response = (await dispatch(
      paymentExistingCard(businessId, selectedExistingCard, 29900),
    )) as IResponseData;
    if (response && typeof response.error !== 'undefined' && !response.error) {
      handleNext();
    }
  };

  const addNewCardPayment = async () => {
    const {
      billingName,
      billingAddress: { address, city, state, zipcode },
      paymentInfo: { alias, cardNumber, expiryYear, expiryMonth },
      isMonthly,
    } = values;

    const createRequestObj: ICreateBusinessPaymentPayload = {
      billingName: billingName.trim(),
      numbers: removeNonNumber(cardNumber),
      expirationDate: `${expiryMonth}${expiryYear}`,
      address: address.trim(),
      city: city.trim(),
      stateID: state,
      zipcode: zipcode.trim(),
      amount: type === 'member' ? 299 : isMonthly ? 3900 : 39900,
      subscriptionType: type === 'member' ? 'monthly' : isMonthly ? 'monthly' : 'yearly',
      alias: alias.trim(),
    };
    if (type === 'business') {
      const response = (await dispatch(
        createBusinessPayment(createRequestObj, businessId),
      )) as IResponseData;
      if (response && typeof response.error !== 'undefined' && !response.error) {
        handleNext();
      }
    } else {
      const response = (await dispatch(createProfilePayment(createRequestObj))) as IResponseData;
      if (response && typeof response.error !== 'undefined' && !response.error) {
        handleNext();
      }
    }
  };

  const formik = useBusinessPaymentForm(addNewCardPayment, initialState);
  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isValid,
    dirty,
    // setValues,
    resetForm,
  } = formik;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
    allowOnlyAlphabets = false,
  ) => {
    const value = allowOnlyAlphabets ? e.target.value.replace(/[^a-zA-Z ]/gi, '') : e.target.value;

    if (fieldName === 'billingName') {
      handleChange(fieldName)(handleAllowOnlyOneSpaceBetweenWordsAndAlphabets(value));
    } else {
      handleChange(fieldName)(handleAllowOnlyOneSpaceBetweenWords(value));
    }
  };

  useEffect(() => {
    if (
      values.paymentInfo.expiryYear === currentYear &&
      !(values.paymentInfo.expiryMonth > currentMonth)
    ) {
      setFieldValue('paymentInfo.expiryMonth', currentMonth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.paymentInfo.expiryYear]);

  return (
    <Box width="100%">
      <Typography
        align="center"
        component="div"
        marginBottom={theme.spacing(2)}
        variant={isMobile ? 'h5' : 'h4'}
      >
        Billing Information
      </Typography>
      <FormControl sx={{ marginBottom: theme.spacing(0), width: '100%' }}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectCardOption}
          onChange={handleCardOptionChange}
        >
          <FormControlLabel control={<Radio />} label="Existing Credit Card" value="old" />
          <Box display={selectCardOption === 'old' ? 'block' : 'none'}>
            <Box marginLeft={theme.spacing(4)}>
              {isGetCardsLoading ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  {cards.length > 0 ? (
                    <CustomSelectField
                      inputProps={{
                        name: 'existingCards',
                      }}
                      label=""
                      sx={{
                        marginBottom: theme.spacing(2),
                      }}
                      value={selectedExistingCard}
                      onChange={handleExistingCardSelectChange}
                    >
                      {cards.map((card: ICardData, index: number) => {
                        return (
                          <option key={`existingCard-${index + 1}`} value={card.documentId}>
                            {card.aliasName} ....
                            {card.lastNumbers}
                          </option>
                        );
                      })}
                    </CustomSelectField>
                  ) : (
                    <Typography>No cards found</Typography>
                  )}
                </>
              )}
            </Box>
          </Box>
          <FormControlLabel control={<Radio />} label="Add New Card" value="new" />
        </RadioGroup>
      </FormControl>
      <Grid
        container
        display={selectCardOption === 'new' ? 'block' : 'none'}
        margin={theme.spacing(2, 0)}
      >
        <Grid item xs={12}>
          <Stack
            noValidate
            autoComplete="off"
            component="form"
            spacing={theme.spacing(3)}
            onSubmit={handleSubmit}
          >
            <CardNumberInput
              errorMsg={errors.paymentInfo?.cardNumber}
              handleBlur={handleBlur('paymentInfo.cardNumber')}
              handleChange={handleChange('paymentInfo.cardNumber')}
              label="Card Number"
              name="paymentInfo.cardNumber"
              setFieldValue={setFieldValue}
              touched={touched.paymentInfo?.cardNumber}
              value={values.paymentInfo.cardNumber}
            />
            <CustomInputField
              fullWidth
              error={!!(touched.billingName && errors.billingName)}
              errormessage={touched.billingName ? errors.billingName : undefined}
              id="billingName"
              inputProps={{ maxLength: 35 }}
              label="Billing Name"
              name="billingName"
              type="text"
              value={values.billingName}
              onBlur={handleBlur}
              onChange={e => handleInputChange(e, 'billingName')}
            />
            {/* <CustomInputField
              fullWidth
              error={
                !!(touched.paymentInfo?.alias && errors.paymentInfo?.alias)
              }
              errormessage={
                touched.paymentInfo?.alias
                  ? errors.paymentInfo?.alias
                  : undefined
              }
              id="alias"
              inputProps={{ maxLength: 32 }}
              label="Alias"
              name="paymentInfo.alias"
              type="text"
              value={values.paymentInfo.alias}
              onBlur={handleBlur}
              onChange={(e) => handleInputChange(e, "paymentInfo.alias")}
            /> */}
            <Stack direction="row" spacing={theme.spacing(2)}>
              <CustomSelectField
                error={!!(touched.paymentInfo?.expiryMonth && errors?.paymentInfo?.expiryMonth)}
                errormessage={
                  touched.paymentInfo?.expiryMonth ? errors?.paymentInfo?.expiryMonth : undefined
                }
                inputProps={{
                  name: 'paymentInfo.expiryMonth',
                }}
                label="Expiry Month"
                value={values.paymentInfo.expiryMonth}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                {Array(12)
                  .fill('')
                  .map((_, index) => {
                    const month = (index + 1).toString().padStart(2, '0');
                    return (
                      <option
                        key={`expiryMonth-${index + 1}`}
                        disabled={handleDisablePastMonth(month)}
                        value={month}
                      >
                        {month}
                      </option>
                    );
                  })}
              </CustomSelectField>
              <CustomSelectField
                error={!!(touched.paymentInfo?.expiryYear && errors?.paymentInfo?.expiryYear)}
                errormessage={
                  touched.paymentInfo?.expiryYear ? errors?.paymentInfo?.expiryYear : undefined
                }
                inputProps={{
                  name: 'paymentInfo.expiryYear',
                }}
                label="Expiry Year"
                value={values.paymentInfo?.expiryYear}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                {Array(16)
                  .fill('')
                  .map((_, index) => (
                    <option
                      key={`expiryYear-${index + 1}`}
                      value={parseInt(currentYear, 10) + index}
                    >
                      {parseInt(currentYear, 10) + index}
                    </option>
                  ))}
              </CustomSelectField>
            </Stack>
            <Divider
              sx={{
                borderColor: theme.palette.secondary.main,
                my: theme.spacing(5),
                width: '100%',
              }}
            />
            {/* <Stack
              alignItems="center"
              alignSelf="flex-start"
              direction="row"
              justifyContent="center"
            >
              <Checkbox
                disableRipple
                checked={checked}
                color="secondary"
                id="sameAsBusinessInfo"
                name="sameAsBusinessInfo"
                sx={{ p: 0 }}
                onChange={handleChecked}
              />
              <Typography marginLeft={theme.spacing(1)}>
                Billing address same as Business Address
              </Typography>
            </Stack> */}
            <CustomInputField
              fullWidth
              error={!!(touched.billingAddress?.address && errors.billingAddress?.address)}
              errormessage={
                touched.billingAddress?.address ? errors.billingAddress?.address : undefined
              }
              id="address"
              inputProps={{ maxLength: 32 }}
              label="Address"
              name="billingAddress.address"
              type="text"
              value={values.billingAddress.address}
              onBlur={handleBlur}
              onChange={e => handleInputChange(e, 'billingAddress.address')}
            />
            <Stack direction="row" spacing={theme.spacing(2)}>
              <CustomInputField
                fullWidth
                error={!!(touched.billingAddress?.city && errors.billingAddress?.city)}
                errormessage={
                  touched.billingAddress?.city ? errors.billingAddress?.city : undefined
                }
                id="city"
                inputProps={{ maxLength: 36 }}
                label="City"
                name="billingAddress.city"
                type="text"
                value={values.billingAddress.city}
                onBlur={handleBlur}
                onChange={e => handleInputChange(e, 'billingAddress.city', true)}
              />

              <CustomSelectField
                fullWidth
                error={!!(touched.billingAddress?.state && errors.billingAddress?.state)}
                errormessage={
                  touched.billingAddress?.state ? errors.billingAddress?.state : undefined
                }
                inputProps={{
                  name: 'billingAddress.state',
                }}
                label="State"
                value={values.billingAddress.state}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option key="Select" value="">
                  Select State
                </option>
                {states.map(state => (
                  <option key={state.documentId} value={state.documentId}>
                    {state.name === 'Federated States of Micronesia' ? 'Micronesia' : state.name}
                  </option>
                ))}
              </CustomSelectField>
            </Stack>
            <Grid item paddingRight={theme.spacing(1)} xs={6}>
              <CustomInputField
                fullWidth
                error={!!(touched.billingAddress?.zipcode && errors.billingAddress?.zipcode)}
                errormessage={
                  touched.billingAddress?.zipcode ? errors.billingAddress?.zipcode : undefined
                }
                id="zipcode"
                inputProps={{ maxLength: 5 }}
                label="Zip Code"
                name="billingAddress.zipcode"
                type="text"
                value={values.billingAddress.zipcode}
                onBlur={handleBlur}
                onChange={e => handleInputChange(e, 'billingAddress.zipcode')}
                onKeyPress={handleAllowOnlyNumber}
              />
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      {type === 'business' && (
        <>
          <Divider
            sx={{
              borderColor: theme.palette.secondary.main,
              mt: theme.spacing(3),
              mb: theme.spacing(5),
              width: '100%',
            }}
          />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            marginTop={theme.spacing(3.5)}
            spacing={theme.spacing(3)}
          >
            <Typography
              align="center"
              color="text.secondary"
              id="Annual-label"
              sx={{
                ...(!values.isMonthly && {
                  textDecoration: 'underline',
                  textUnderlineOffset: '2px',
                }),
              }}
              variant="menu"
            >
              Annual - $399
            </Typography>
            <CustomSwitch
              checked={values.isMonthly}
              id="isMonthly"
              inputProps={{ 'aria-label': 'Account Type' }}
              name="isMonthly"
              value={values.isMonthly}
              onChange={handleChange}
            />
            <Typography
              align="center"
              color="text.secondary"
              id="Monthly-label"
              sx={{
                ...(values.isMonthly && {
                  textDecoration: 'underline',
                  textUnderlineOffset: '2px',
                }),
              }}
              variant="menu"
            >
              Monthly - $39
            </Typography>
          </Stack>
          <Divider
            sx={{
              borderColor: theme.palette.secondary.main,
              mt: theme.spacing(3),
              mb: theme.spacing(5),
              width: '100%',
            }}
          />
        </>
      )}
      <PaymentSummary formik={formik} type={type} />
      <Stack direction="row" marginTop={theme.spacing(4)} spacing={theme.spacing(3)} width="100%">
        <Button fullWidth size="large" variant="outlined" onClick={handlePrev}>
          Back
        </Button>
        <LoadingButton
          fullWidth
          disabled={
            selectCardOption === 'old' && selectedExistingCard.length > 0
              ? false
              : !(isValid && dirty)
          }
          loading={loading || profilePaymentLoading}
          size="large"
          type="submit"
          variant="contained"
          onClick={selectCardOption === 'old' ? existingCardPayment : () => handleSubmit()}
        >
          Checkout
        </LoadingButton>
      </Stack>
    </Box>
  );
}
