import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from 'components/header/Header';
import BusinessPlan from 'components/plans/BusinessPlan';
import Search from 'components/search/Search';
import SearchResults from 'components/search/SearchResults';
import { PrivacyPolicy as PrivacyTerms, TermsNConditions } from 'components/terms';
import CoreValues from 'components/terms/CoreValues';
import { UserType } from 'constants/constants';
import useAuthRouteCheck from 'hooks/useAuthRouteCheck';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useShowHomeNav from 'hooks/useShowHomeNav';
import useTermRouteCheck from 'hooks/useTermRouteCheck';
import About from 'pages/About';
import VettingDashboard from 'pages/admin/VettingDashboard';
import PendingBusinessDetails from 'pages/admin/VettingDashboard/PendingBusinessDetails';
import Affiliate from 'pages/Affiliate';
import CheckAffiliate from 'pages/Affiliate/CheckAffiliate';
import AffiliatePayment from 'pages/Affiliate/JoinAffiliate/AffiliatePayment';
import BusinessDirectory from 'pages/business/BusinessDirectory';
import RecentBusinessContainer from 'pages/business/BusinessDirectory/RecentBusinesses/index';
import BusinessPost from 'pages/business/BusinessPost';
import BusinessSetup from 'pages/business/BusinessSetup';
import BusinessWall from 'pages/business/BusinessWall';
import DeactivatedBusinessInfo from 'pages/business/DeactivatedBusinessInfo';
import CommentDetails from 'pages/Comment';
import FollowingTimeline from 'pages/FollowingTimeline';
import ForgotPassword from 'pages/forgotPassword';
import Home from 'pages/Home';
import HomeNav from 'pages/Home/HomeNav';
import Login from 'pages/Login';
import Account from 'pages/myAccount';
import BusinessDetails from 'pages/myAccount/Business/BusinessDetails';
import BusinessInformation from 'pages/myAccount/Business/BusinessInformation';
import BusinessPhotos from 'pages/myAccount/Business/BusinessPhotos';
import DeleteAccountSuccessPage from 'pages/myAccount/DeleteAccount/DeleteAccountSuccessPage';
import Payment from 'pages/myAccount/Payment';
import PersonalDetails from 'pages/myAccount/personal';
import ProfileDetails from 'pages/myAccount/Profile/ProfileDetails';
import ProfilePhotos from 'pages/myAccount/Profile/ProfilePhotos';
import News from 'pages/news';
import NewsArticle from 'pages/news/Article';
import NewsProviderWall from 'pages/news/ProviderWall';
import Podcasts from 'pages/Podcasts';
import PodcastEpisode from 'pages/Podcasts/Episode';
import PodcastShow from 'pages/Podcasts/Show';
import ProfilePost from 'pages/Profile/ProfilePost';
import ProfileWall from 'pages/Profile/ProfileWall';
import ResetPassword from 'pages/resetPassword';
import Resources from 'pages/Resources';
import SetUsername from 'pages/setUsername';
import SharePost from 'pages/Share';
import SignUp from 'pages/signUp';
import ShowTerms from 'pages/terms';
import Trending from 'pages/Trending';
import UserProfileSetUp from 'pages/UserProfileSetUp';
import VerifyMail from 'pages/VerifyMail';
import ActivateAccount from 'pages/VerifyMail/ActivateAccount';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import browserPath from 'utils/browserPath';
import { CookieNames, getCookieItem } from 'utils/cookies';

import { updateLoggedInStatus } from '../store/authentication/AuthenticationAction';
import {
  BusinessSetupStatus,
  BusinessSetupSteps,
  UserSetUpSteps,
} from '../store/business/BusinessInterface';

function AuthRoute() {
  const token = useIsLoggedIn().getUserToken();
  return token ? <Navigate replace to="/home" /> : <Outlet />;
}

function PrivateRoute() {
  const token = useIsLoggedIn().getUserToken();
  return token ? <Outlet /> : <Navigate replace to="/home" />;
}

function HandleBusinessPlanRoutes() {
  const businessType = getCookieItem(CookieNames.BUSINESS_TYPE);
  const redirectToBusinessSetup = businessType === UserType.PAYMENT_INITIATED;
  return redirectToBusinessSetup ? <Navigate replace to="/business-setup" /> : <Outlet />;
}

function UsernameAuthRoute() {
  const location = useLocation();
  const token = useIsLoggedIn().getUserToken();
  const { userData } = useAppSelector(s => s.account);
  const isSetUserNameRoute = location.pathname === '/set-username';

  return token ? (
    Object.keys(userData).length > 0 ? (
      userData.userName !== userData.email ? (
        <Outlet />
      ) : (
        <Navigate replace to="/set-username" />
      )
    ) : isSetUserNameRoute ? (
      <Grid container spacing={8}>
        <Grid item xs>
          <LinearProgress />
        </Grid>
      </Grid>
    ) : location.pathname.includes('/change-email/token') ||
      location.pathname === '/business-plan' ||
      location.pathname === '/business-setup' ||
      location.pathname === '/profile-setup' ||
      location.pathname === '/affiliate-payment' ? (
      <Outlet />
    ) : (
      <></>
    )
  ) : (
    <Outlet />
  );
}

function PrivateUsernameAuthRoute() {
  const token = useIsLoggedIn().getUserToken();
  const { userData } = useAppSelector(s => s.account);
  return token ? (
    Object.keys(userData).length > 0 ? (
      userData.userName === userData.email ? (
        <Outlet />
      ) : (
        <Navigate replace to="/home" />
      )
    ) : (
      <Grid container spacing={8}>
        <Grid item xs>
          <LinearProgress />
        </Grid>
      </Grid>
    )
  ) : (
    <Navigate replace to="/home" />
  );
}

function HandleProfileRoutes() {
  const { userData } = useAppSelector(s => s.account);
  const { USER_INFO, PAYMENT, PAYMENT_SUCCESS } = UserSetUpSteps;

  const statusDraft = [USER_INFO, PAYMENT].includes(userData?.step);
  const statusPaymentSuccess = userData?.step === PAYMENT_SUCCESS;

  const checkStatus = statusDraft || statusPaymentSuccess;

  const showSetup =
    Object.keys(userData).length === 0 || (Object.keys(userData).length >= 0 && checkStatus);
  if (showSetup) {
    return <Outlet />;
  }
  return <Navigate replace to="/home" />;
}

function HandleBusinessRoutes() {
  const { businessDetails } = useAppSelector(s => s.business);
  const {
    BUSINESS_IMAGES,
    BUSINESS_DETAILS,
    BUSINESS_SOCIAL_LINKS,
    BUSINESS_INFO,
    PAYMENT,
    PAYMENT_PROCESSING,
    PAYMENT_SUCCESS,
    PAYMENT_FAILED,
    COMPLETED,
    FREE_BUSINESS_LISTED,
  } = BusinessSetupSteps;

  const { DRAFT, PAYMENT_SUCCESSFUL, PAYMENT_DECLINED, APPROVED } = BusinessSetupStatus;

  const statusDraft =
    businessDetails?.status === DRAFT &&
    [
      BUSINESS_INFO,
      BUSINESS_DETAILS,
      BUSINESS_SOCIAL_LINKS,
      BUSINESS_IMAGES,
      PAYMENT,
      PAYMENT_PROCESSING,
    ].includes(businessDetails?.step);
  const statusPaymentSuccess =
    businessDetails?.status === PAYMENT_SUCCESSFUL && businessDetails?.step === PAYMENT_SUCCESS;

  const statusPaymentFailed =
    businessDetails?.status === PAYMENT_DECLINED && businessDetails?.step === PAYMENT_FAILED;
  const statusApproved =
    businessDetails?.status === APPROVED && businessDetails?.step === COMPLETED;

  const freeBusiness = businessDetails?.step === FREE_BUSINESS_LISTED;

  const checkStatus =
    statusDraft || statusPaymentSuccess || statusPaymentFailed || statusApproved || freeBusiness;

  const showSetup =
    Object.keys(businessDetails).length === 0 ||
    (Object.keys(businessDetails).length >= 0 && checkStatus);
  if (showSetup) {
    return <Outlet />;
  }
  return <Navigate replace to="/home" />;
}

function AppRoutes() {
  const { REACT_APP_GA_ID } = process.env;

  const dispatch = useAppDispatch();
  const location = useLocation();
  const isAuthRoute = useAuthRouteCheck();
  const isHomeNavRoute = useShowHomeNav();
  const isTermsRoute = useTermRouteCheck();
  const isMobileAuth = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const token = useIsLoggedIn().getUserToken();

  const { isLoggedIn } = useAppSelector(s => s.auth);

  const isSetUserNameRoute = location.pathname === '/set-username';

  const showHeader =
    !isAuthRoute ||
    isTermsRoute ||
    (isAuthRoute && isMobileAuth) ||
    (isAuthRoute && isSetUserNameRoute);

  useEffect(() => {
    if (token === '' && isLoggedIn) {
      dispatch(updateLoggedInStatus(false));
    }
  }, [dispatch, isLoggedIn, token]);

  useEffect(() => {
    if (REACT_APP_GA_ID) {
      const title = Object.entries(browserPath).find(([key]) =>
        location.pathname.includes(key),
      )?.[1];
      document.title = `${title}` || 'Freedom Square';
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
        title: `${title}` || 'Freedom Square',
      });
    }
  }, [REACT_APP_GA_ID, location]);

  return (
    <>
      {showHeader ? (
        <Box
          component="header"
          id="header-nav"
          position="sticky"
          sx={{
            backgroundColor: theme.palette.common.white,
          }}
          top={0}
          width="100%"
          zIndex={10}
        >
          <Header {...(isTermsRoute && { showHeaderNav: false })} />
          {isMobile && location.pathname === '/search' && <Search searchMobile />}
          {isHomeNavRoute ? <HomeNav /> : null}
        </Box>
      ) : null}

      <Routes>
        {/* GUEST Public Routes */}
        <Route element={<AuthRoute />}>
          <Route element={<Login />} path="login" />
          <Route element={<SignUp />} path="signup" />
          <Route element={<ForgotPassword />} path="forgot-password" />
          <Route element={<ActivateAccount />} path="activate-account/token/:token/email/:email" />
          <Route element={<ActivateAccount />} path="activate-business/token/:token/email/:email" />
          <Route element={<ResetPassword />} path="reset-password/token/:token/email/:email" />
          <Route element={<VerifyMail />} path="verification-mail" />
          <Route element={<VerifyMail />} path="forgot-password-success" />
          <Route element={<DeleteAccountSuccessPage />} path="delete-account" />
        </Route>
        <Route element={<PrivateUsernameAuthRoute />}>
          <Route element={<SetUsername />} path="set-username" />
        </Route>

        <Route element={<UsernameAuthRoute />}>
          <Route element={<PrivateRoute />}>
            <Route element={<Affiliate />} path="affiliate" />
            <Route element={<AffiliatePayment />} path="affiliate-payment" />

            <Route element={<VettingDashboard />} path="admin-dashboard" />
            <Route
              element={<PendingBusinessDetails />}
              path="admin-dashboard/businessId/:businessId"
            />

            <Route element={<Account />} path="/account">
              <Route element={<PersonalDetails />} path="profile" />
              <Route element={<Payment />} path="payment" />
              <Route element={<ProfileDetails />} path="profile-details" />
              <Route element={<ProfilePhotos />} path="profile-photos" />
              <Route element={<BusinessInformation />} path="business-information" />
              <Route element={<BusinessDetails />} path="business-details" />
              <Route element={<BusinessPhotos />} path="business-photos" />
              <Route element={<PrivacyTerms />} path="privacy-terms" />
              <Route element={<TermsNConditions />} path="terms" />
              <Route element={<CoreValues />} path="coreValues" />
              {!isMobile && <Route index element={<PersonalDetails />} />}
            </Route>

            <Route element={<HandleBusinessRoutes />}>
              <Route element={<BusinessSetup />} path="business-setup" />
            </Route>
            <Route element={<HandleProfileRoutes />}>
              <Route element={<UserProfileSetUp />} path="profile-setup" />
            </Route>
          </Route>
          <Route element={<HandleBusinessPlanRoutes />}>
            <Route element={<BusinessPlan />} path="business-plan" />
          </Route>
          <Route element={<News />} path="news" />
          <Route element={<NewsArticle />} path="article/:articleId" />
          <Route element={<CommentDetails />} path=":type/:contentId/comment/:commentId" />
          <Route element={<Podcasts />} path="podcasts" />
          <Route element={<PodcastEpisode />} path="podcast/:episodeId" />
          <Route element={<PodcastShow />} path="podcast-show/:podcastShowId" />
          <Route element={<ShowTerms />} path="privacy-policy" />
          <Route element={<Resources />} path="resources" />
          <Route element={<About />} path="about" />
          <Route element={<ShowTerms />} path="terms-and-conditions" />
          <Route element={<ShowTerms />} path="core-values" />

          <Route element={<BusinessDirectory />} path="businesses" />
          <Route element={<RecentBusinessContainer />} path="recent-businesses" />

          <Route element={<BusinessWall />} path="business-wall/:businessId" />
          <Route element={<DeactivatedBusinessInfo />} path="business-deactivated-info" />
          <Route element={<BusinessPost />} path="business/:contentId" />
          <Route element={<NewsProviderWall />} path="news-provider-wall/:providerId" />
          <Route element={<ActivateAccount />} path="change-email/token/:token/email/:email" />
          <Route element={<SearchResults />} path="search" />
          <Route element={<SharePost />} path="share/:shareId" />
          <Route element={<FollowingTimeline />} path="following" />
          <Route element={<Trending />} path="trending" />

          <Route element={<CheckAffiliate />} path="affiliate/:affiliateId" />
          <Route element={<ProfileWall />} path="profile-wall/:userId" />
          <Route element={<ProfileWall />} path="town-crier-wall/:userId" />
          <Route element={<ProfilePost />} path="post/:contentId" />
          <Route element={<ProfilePost />} path="town-crier/:contentId" />
        </Route>
        <Route element={<Home />} path="home" />
        {/* Final Redirection */}
        <Route element={<Navigate replace to="/home" />} path="*" />
        {/* Final Redirection */}
      </Routes>
      {/* <ErrorBoundary> */}
      {/* </ErrorBoundary> */}
    </>
  );
}

export default AppRoutes;
