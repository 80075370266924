import NotFound from 'components/notFound/NotFound';
import BusinessPost from 'pages/business/BusinessPost';
import NewsArticle from 'pages/news/Article';
import PodcastEpisode from 'pages/Podcasts/Episode';
import { useParams } from 'react-router-dom';

enum ShareType {
  ARTICLE = 'article',
  BUSINESS = 'business',
  PODCAST = 'podcast',
}
const { ARTICLE, BUSINESS, PODCAST } = ShareType;

export default function SharePost() {
  const { shareId } = useParams();
  const decodedShareDetails = window.atob(shareId as string)?.split('/');
  const postType = decodedShareDetails?.[0] || '';
  const postId = decodedShareDetails?.[1] || '';

  return (
    <>
      {postType && postId ? (
        <>
          {postType === ARTICLE && <NewsArticle postId={postId} />}
          {postType === BUSINESS && <BusinessPost postId={postId} />}
          {postType === PODCAST && <PodcastEpisode postId={postId} />}
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
}
