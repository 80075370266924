import { Button, SvgIcon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import ModalContainer from 'components/modal/ModalContainer';
import { BackIcon } from 'helpers/images';
import { useState } from 'react';
import { IMembers } from 'store';
import theme from 'theme';

import AdminAddUser from './AdminAddUser/AdminAddUser';
import AdminBusinessSignUp from './AdminBusinessSignUp/AdminBusinessSignUp';

type IProps = {
  showNewMemberModal: boolean;
  setMemberModal: () => void;
};

function AddNewMemberBusiness({ showNewMemberModal, setMemberModal }: IProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const [activeType, setActiveType] = useState('adminAddMember');
  const [selectedMember, setSelectedMember] = useState<IMembers>({} as IMembers);

  const onUserCreate = (members: IMembers) => {
    setSelectedMember(members);
    setActiveType('AdminBusinessSignUp');
  };

  return (
    <ModalContainer
      disableScroll
      fullWidth
      showCloseIcon
      handleClose={() => setMemberModal()}
      maxWidth="md"
      open={showNewMemberModal}
      PaperProps={{
        sx: {
          ...(!isMobile && {
            maxHeight: '600px',
            minHeight: '600px',
          }),
        },
      }}
      title="Add New Business"
    >
      <Box sx={{ backgroundColor: theme.palette.common.white }} width="100%">
        {activeType === 'BusinessType' && (
          <Box mt={2}>
            <Button onClick={() => setActiveType('MemberType')}>
              <SvgIcon inheritViewBox component={BackIcon} sx={{ height: '16px', width: '10px' }} />
              <Typography component="span" sx={{ ml: theme.spacing(1.25) }}>
                Back
              </Typography>
            </Button>
          </Box>
        )}

        <Stack boxSizing="border-box" height="100%" padding={theme.spacing(0, 3)}>
          {activeType === 'adminAddMember' && <AdminAddUser onUserCreate={onUserCreate} />}

          {activeType === 'AdminBusinessSignUp' && (
            <AdminBusinessSignUp
              isNewAddMember
              selectedBusinessType="free"
              selectedMemberData={selectedMember}
              setActiveType={setActiveType}
              setMemberModal={setMemberModal}
            />
          )}
        </Stack>
      </Box>
    </ModalContainer>
  );
}

export default AddNewMemberBusiness;
