import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import LocationKey from 'components/locationKey';
import { BackIcon } from 'helpers/images';
import { useState } from 'react';
import { ISponsor } from 'store';
import theme from 'theme';

import AddAds from './AddAds';
import AddSponsor from './AddSponsor';
import AllSponsorsReport from './AllSponsorsReport';
import SponsorsReport from './SponsorsReport';

function SponsorsAds() {
  const [showAdsPage, setShowAdsPage] = useState<boolean>(false);
  const [selectedSponsor, setSelectedSponsor] = useState<ISponsor>({} as ISponsor);
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const [showAddSponsor, setShowAddSponsor] = useState<boolean>(false);
  const [showAddAds, setShowAddAds] = useState<boolean>(false);
  const [addDialogTitle, setAddDialogTitle] = useState('');

  const [showLocationKeyModal, setShowLocationKeyModal] = useState<boolean>(false);

  const handleShowAdsPage = (show: boolean, sponsor?: ISponsor) => {
    setShowAdsPage(show);
    setSelectedSponsor(sponsor || ({} as ISponsor));
  };

  const onCreateAd = () => setShowAddAds(false);

  return (
    <Stack sx={{ marginTop: theme.spacing(-4) }}>
      <Stack
        direction="row"
        justifyContent={showAdsPage ? 'space-between' : 'flex-end'}
        sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
      >
        {showAdsPage && (
          <Button onClick={() => handleShowAdsPage(false)}>
            <SvgIcon inheritViewBox component={BackIcon} sx={{ height: '16px', width: '10px' }} />
            <Typography component="span" sx={{ ml: theme.spacing(1.25) }}>
              Back
            </Typography>
          </Button>
        )}
        <Button
          size="large"
          sx={{ marginRight: theme.spacing(1.25) }}
          variant="contained"
          onClick={() => setShowLocationKeyModal(true)}
        >
          Location Key
        </Button>
        {!showAdsPage && (
          <Button size="large" variant="contained" onClick={() => setShowAddSponsor(true)}>
            Add Sponsors
          </Button>
        )}
      </Stack>
      {showAdsPage ? (
        <AllSponsorsReport
          selectedSponsor={selectedSponsor}
          setAddModal={() => setShowAddAds(true)}
          setTitle={setAddDialogTitle}
        />
      ) : (
        <SponsorsReport handleShowAdsPage={handleShowAdsPage} />
      )}

      {showAddSponsor && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowAddSponsor(false)}
          isMobile={isMobile}
          open={showAddSponsor}
          setOpen={setShowAddSponsor}
          title="Add Sponsor"
        >
          <AddSponsor closeModal={() => setShowAddSponsor(false)} />
        </ToggleDrawerDialog>
      )}

      {showAddAds && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowAddAds(false)}
          isMobile={isMobile}
          open={showAddAds}
          setOpen={setShowAddAds}
          title={`Add ${addDialogTitle}`}
        >
          <AddAds
            closeModal={() => setShowAddAds(false)}
            sponsorId={selectedSponsor?.documentId}
            sponsorType={addDialogTitle}
            onCreate={onCreateAd}
          />
        </ToggleDrawerDialog>
      )}
      {showLocationKeyModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowLocationKeyModal(false)}
          isMobile={isMobile}
          open={showLocationKeyModal}
          setOpen={setShowLocationKeyModal}
          title="Location Key"
        >
          <LocationKey />
        </ToggleDrawerDialog>
      )}
    </Stack>
  );
}

export default SponsorsAds;
