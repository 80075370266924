import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import BusinessBanner from 'components/business/BusinessBanner';
import InfiniteScroll from 'components/infiniteScroll/InfiniteScroll';
import Loader from 'components/loader/Loader';
import GuestReactionView from 'components/reactions/GuestReactionView';
import TabPanel from 'components/tab/tabPanel';
import Tile from 'components/tile/Tile';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { ProviderWallBgImage } from 'helpers/images';
import { newsUrl } from 'helpers/urls';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  followUnFollowNewsProvider,
  getNewsProviderArticles,
  getNewsProviderInfo,
  INewsArticle,
  resetNewsProviderWallState,
} from 'store';
import getPostShareUrl from 'store/common/CommonActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { getNewsProviderBackground, getNewsProviderLogo, scrollToTop } from 'utils/utils';

function NewsProviderWall() {
  const dispatch = useAppDispatch();
  const { providerId } = useParams();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { loading: shareLoading } = useAppSelector(s => s.common);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const {
    isFollowStatusLoading,
    newsProviderWallState,
    newsProviderWallLoading,
    newsProviderInfo,
    newsProviderInfoLoading,
  } = useAppSelector(s => s.news);
  const { articles, pageNumber, hasMoreData } = newsProviderWallState;
  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();

  const providerName = useMemo(() => newsProviderInfo.name || '', [newsProviderInfo]);

  const getNewsProviderInformation = useCallback(() => {
    dispatch(getNewsProviderInfo(providerId as string));
  }, [dispatch, providerId]);

  useEffect(() => {
    getProviderArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, providerId]);

  useEffect(() => {
    scrollToTop();
    getNewsProviderInformation();
    return () => {
      dispatch(resetNewsProviderWallState());
    };
  }, [dispatch, getNewsProviderInformation]);

  const changeFollowNewsProviderStatus = async () => {
    if (isUserLoggedIn()) {
      await dispatch(
        followUnFollowNewsProvider(newsProviderInfo?.documentID, newsProviderInfo?.isFollowed),
      );
    } else {
      setShowModal(true);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const getProviderArticles = () => {
    dispatch(getNewsProviderArticles(providerId as string, pageNumber + 1));
  };

  const handleDisplayProviderLogo = () => {
    return getNewsProviderLogo(providerName);
  };

  const sharePost = async () => {
    if (isUserLoggedIn()) {
      await dispatch(
        getPostShareUrl(`${newsUrl}users/news/${providerId as string}/share?type=wall`),
      );
    } else {
      setShowModal(true);
    }
  };

  return (
    <HomeWrapper maxWidth="midmd">
      <Stack spacing={theme.spacing(5)} width="100%">
        <Box border={`0.25px solid ${theme.palette.text.secondary}`} width="100%">
          {!newsProviderInfoLoading ? (
            <>
              <BusinessBanner
                backgroundImg={getNewsProviderBackground(providerName) || ProviderWallBgImage} //
                logoImg={handleDisplayProviderLogo()}
                logoImgAlt={providerName}
              />
              <Box padding={{ xs: theme.spacing(2), sm: theme.spacing(3) }}>
                <Stack spacing={theme.spacing(2)} width="100%">
                  <Typography variant="h4">{newsProviderInfo?.name}</Typography>

                  <Stack direction="row" spacing={theme.spacing(2)}>
                    <LoadingButton
                      disableRipple
                      disableTouchRipple
                      loading={isFollowStatusLoading}
                      variant="contained"
                      onClick={() => changeFollowNewsProviderStatus()}
                    >
                      {newsProviderInfo?.isFollowed ? 'Unfollow' : 'Follow'}
                    </LoadingButton>
                    <LoadingButton
                      disableRipple
                      disableTouchRipple
                      loading={shareLoading}
                      variant="contained"
                      onClick={() => sharePost()}
                    >
                      Share
                    </LoadingButton>
                    <Button
                      disableRipple
                      disableTouchRipple
                      sx={{ borderRadius: '4px' }}
                      type="button"
                      variant="outlined"
                      onClick={() => window.open(newsProviderInfo.url, '_blank')}
                    >
                      VISIT WEBSITE
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </>
          ) : (
            <Loader />
          )}
        </Box>

        <ComponentWrapper title="">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                aria-label="business tabs"
                indicatorColor="secondary"
                sx={{
                  '& .MuiTabs-indicator': {
                    height: '3px',
                  },
                }}
                textColor="secondary"
                value={selectedTab}
                onChange={handleTabChange}
              >
                <Tab label="ARTICLES" sx={{ fontWeight: 'bold' }} />
              </Tabs>
            </Box>
            <TabPanel index={0} value={selectedTab}>
              <InfiniteScroll
                hasMoreData={hasMoreData}
                isLoading={newsProviderWallLoading}
                loadOnMount={false}
                onBottomHit={getProviderArticles}
              >
                <Box>
                  <Grid container item width="100%" xs={12}>
                    {articles && articles.length > 0 ? (
                      <>
                        {articles.map((article: INewsArticle, index: number) => (
                          <Fragment key={article.documentID + index.toString()}>
                            <Grid item xs={12}>
                              <Tile
                                key={(article?.documentID as string) + index.toString()}
                                withDivider
                                commentsCount={article?.commentsCount}
                                componentType="news-wall"
                                description={article?.desc}
                                id={article?.documentID}
                                image={article?.thumbnail}
                                isLiked={article?.isLiked}
                                likesCount={article?.likesCount}
                                name={article?.provider?.name}
                                postedAt={article?.updateDate}
                                providerId={article.providerID}
                                providerUrl={article?.provider?.url}
                                tileType={isMobile ? 'small' : 'large'}
                                title={article?.title}
                                type="news"
                              />
                            </Grid>
                          </Fragment>
                        ))}
                      </>
                    ) : (
                      !newsProviderWallLoading && (
                        <Typography component="h6" variant="h6Bold">
                          No Articles available
                        </Typography>
                      )
                    )}
                    {newsProviderWallLoading && <Loader />}
                  </Grid>
                </Box>
              </InfiniteScroll>
            </TabPanel>
          </Box>
        </ComponentWrapper>
      </Stack>

      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </HomeWrapper>
  );
}

export default NewsProviderWall;
