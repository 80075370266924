import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import moment from 'moment';
import { useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import { getAffiliateCountInfo } from 'store/affiliate/AffiliateActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

function Counts() {
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const { affiliateCountInfo, affiliateCountLoading } = useAppSelector(s => s.affiliate);

  const [range, setRange] = useState<Range[]>([
    {
      startDate: undefined,
      endDate: new Date(''),
      key: `selection`,
    },
  ]);

  const handleGetCounts = () => {
    if (range[0]?.startDate && range[0]?.endDate) {
      dispatch(
        getAffiliateCountInfo(
          moment(range[0]?.startDate).format('YYYY-MM-DD'),
          moment(range[0]?.endDate).format('YYYY-MM-DD'),
        ),
      );
    }
  };

  return (
    <Stack boxSizing="border-box" spacing={theme.spacing(1.5)} width="100%">
      <Typography variant="title2">My Counts</Typography>
      <ComponentWrapper title="">
        <Stack spacing={theme.spacing(2)}>
          <Stack spacing={theme.spacing(2)}>
            <Stack direction="row" spacing={theme.spacing(2)}>
              <DateRange
                preventSnapRefocus
                showPreview
                direction={isMobile ? 'vertical' : 'horizontal'}
                endDatePlaceholder="End Date"
                maxDate={moment().toDate()}
                minDate={moment().subtract(365, 'days').toDate()}
                months={2}
                moveRangeOnFirstSelection={false}
                rangeColors={['#3C2B6B']}
                ranges={range}
                startDatePlaceholder="Start Date"
                onChange={ranges => setRange([ranges.selection])}
              />
            </Stack>
            <LoadingButton
              disableRipple
              disableTouchRipple
              fullWidth
              disabled={!(range[0]?.startDate && range[0]?.endDate)}
              loading={affiliateCountLoading}
              size="large"
              sx={{ textTransform: 'none' }}
              variant="contained"
              onClick={handleGetCounts}
            >
              Apply
            </LoadingButton>
          </Stack>
          <Stack direction={isMobile ? 'column' : 'row'} spacing={theme.spacing(2)}>
            <Box
              alignItems="center"
              border={`0.25px solid ${theme.palette.text.secondary}`}
              borderRadius="4px"
              boxSizing="border-box"
              justifyContent="center"
              sx={{ py: theme.spacing(1.25) }}
              width="100%"
            >
              <Stack alignItems="center" height="100%" justifyContent="center">
                <Typography component="span" textAlign="center" variant="h6">
                  No of conversions: {affiliateCountInfo?.businessCount}
                </Typography>
              </Stack>
            </Box>
            <Box
              alignItems="center"
              border={`0.25px solid ${theme.palette.text.secondary}`}
              borderRadius="4px"
              boxSizing="border-box"
              justifyContent="center"
              sx={{ py: theme.spacing(1.25) }}
              width="100%"
            >
              <Stack alignItems="center" height="100%" justifyContent="center">
                <Typography component="span" textAlign="center" variant="h6">
                  No of Registrants: {affiliateCountInfo?.registers}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </ComponentWrapper>
    </Stack>
  );
}

export default Counts;
