import cloneDeep from 'clone-deep';
import { Reducer } from 'redux';
import {
  IAllPodcastsState,
  IPodcastDetails,
  IPodcastEpisode,
  IPodcastState,
  PodcastActions,
  UpdateCommentCountType,
} from 'store';

import PodcastType from './PodcastTypes';

export const intialAllPodcastsState: IAllPodcastsState = {
  currentCount: 0,
  podcasts: [] as IPodcastEpisode[],
};

export const initialState: IPodcastState = {
  allPodcasts: intialAllPodcastsState,
  featuredPodcasts: [] as IPodcastEpisode[],
  isEpisodeDetailsLoading: false,
  isFollowUnFollowLoading: false,
  isGetPodcastsLoading: false,
  isGetFeaturedPodcastsLoading: false,
  podcastEpisode: {} as IPodcastEpisode,
  podcastEpisodesList: [] as IPodcastEpisode[],
  podcastDetails: {} as IPodcastDetails,
};

const PodcastReducer: Reducer<IPodcastState, PodcastActions> = (
  state = initialState,
  action = {} as PodcastActions,
) => {
  switch (action.type) {
    case PodcastType.RESET_PODCAST_STATE: {
      return {
        ...state,
        allPodcasts: intialAllPodcastsState,
        featuredPodcasts: [],
      };
    }
    case PodcastType.RESET_PODCAST_EPISODE_STATE: {
      return {
        ...state,
        podcastEpisode: {} as IPodcastEpisode,
      };
    }
    case PodcastType.UPDATE_COMMENT_COUNT: {
      const { commentCount } = state.podcastEpisode;
      return {
        ...state,
        podcastEpisode: {
          ...state.podcastEpisode,
          commentCount:
            action.payload === UpdateCommentCountType.INC ? commentCount + 1 : commentCount - 1,
        },
      };
    }
    case PodcastType.GET_ALL_PODCAST_EPISODES_REQUEST: {
      return {
        ...state,
        isGetPodcastsLoading: true,
      };
    }
    case PodcastType.GET_ALL_PODCAST_EPISODES_SUCCESS: {
      return {
        ...state,
        isGetPodcastsLoading: false,
        allPodcasts: {
          currentCount: action.payload.currentCount,
          podcasts: [...state.allPodcasts.podcasts, ...action.payload.podcasts],
        },
      };
    }
    case PodcastType.GET_ALL_PODCAST_EPISODES_ERROR: {
      return {
        ...state,
        isGetPodcastsLoading: false,
      };
    }
    case PodcastType.GET_FEATURED_PODCAST_EPISODES_REQUEST: {
      return {
        ...state,
        isGetFeaturedPodcastsLoading: true,
      };
    }
    case PodcastType.GET_FEATURED_PODCAST_EPISODES_SUCCESS: {
      return {
        ...state,
        isGetFeaturedPodcastsLoading: false,
        featuredPodcasts: action.payload,
      };
    }
    case PodcastType.GET_FEATURED_PODCAST_EPISODES_ERROR: {
      return {
        ...state,
        isGetFeaturedPodcastsLoading: false,
      };
    }
    case PodcastType.GET_EPISODE_DETAILS_REQUEST: {
      return {
        ...state,
        isEpisodeDetailsLoading: true,
      };
    }
    case PodcastType.GET_EPISODE_DETAILS_SUCCESS: {
      return {
        ...state,
        isEpisodeDetailsLoading: false,
        podcastEpisode: action.payload,
      };
    }
    case PodcastType.GET_EPISODE_DETAILS_ERROR: {
      return {
        ...state,
        isEpisodeDetailsLoading: false,
      };
    }
    case PodcastType.LIKE_DISLIKE_EPISODE_REQUEST:
    case PodcastType.LIKE_DISLIKE_EPISODE_ERROR: {
      return {
        ...state,
      };
    }
    case PodcastType.LIKE_DISLIKE_EPISODE_SUCCESS: {
      const isLiked = !state.podcastEpisode.isLiked;
      const likeCount = isLiked
        ? state.podcastEpisode.likeCount + 1
        : state.podcastEpisode.likeCount - 1;

      return {
        ...state,
        podcastEpisode: {
          ...state.podcastEpisode,
          isLiked,
          likeCount,
        },
      };
    }

    case PodcastType.FEATURED_LIKE_DISLIKE_EPISODE_SUCCESS: {
      const selectedIndex = state.featuredPodcasts.findIndex(
        (e: IPodcastEpisode) => e.episodeID === action.payload,
      );
      const featuredPodcasts = cloneDeep(state.featuredPodcasts);
      if (selectedIndex !== -1) {
        const likeStatus = !featuredPodcasts[selectedIndex].isLiked;
        featuredPodcasts[selectedIndex].isLiked = likeStatus;
        featuredPodcasts[selectedIndex].likeCount = likeStatus
          ? featuredPodcasts[selectedIndex].likeCount + 1
          : featuredPodcasts[selectedIndex].likeCount - 1;
      }
      return {
        ...state,
        featuredPodcasts,
      };
    }

    case PodcastType.ALL_PODCAST_LIKE_DISLIKE_EPISODE_SUCCESS: {
      const selectedIndex = state.allPodcasts.podcasts.findIndex(
        (e: IPodcastEpisode) => e.episodeID === action.payload,
      );
      const podcasts = cloneDeep(state.allPodcasts.podcasts);
      if (selectedIndex !== -1) {
        const likeStatus = !podcasts[selectedIndex].isLiked;
        podcasts[selectedIndex].isLiked = likeStatus;
        podcasts[selectedIndex].likeCount = likeStatus
          ? podcasts[selectedIndex].likeCount + 1
          : podcasts[selectedIndex].likeCount - 1;
      }
      return {
        ...state,
        allPodcasts: {
          ...state.allPodcasts,
          podcasts,
        },
      };
    }

    case PodcastType.UPDATE_EPISODE_PROVIDER_RELATIONSHIP_STATUS_REQUEST: {
      return {
        ...state,
        isFollowUnFollowLoading: true,
      };
    }
    case PodcastType.UPDATE_EPISODE_PROVIDER_RELATIONSHIP_STATUS_SUCCESS: {
      return {
        ...state,
        isFollowUnFollowLoading: false,
        podcastEpisode: {
          ...state.podcastEpisode,
          isFollowed: action.payload,
        },
      };
    }
    case PodcastType.UPDATE_EPISODE_PROVIDER_RELATIONSHIP_STATUS_ERROR: {
      return {
        ...state,
        isFollowUnFollowLoading: false,
      };
    }
    case PodcastType.GET_PODCAST_SHOW_EPISODES_REQUEST: {
      return {
        ...state,
        isEpisodeDetailsLoading: true,
      };
    }
    case PodcastType.GET_PODCAST_SHOW_EPISODES_SUCCESS: {
      const { episodesList, initialLoad } = action.payload;
      return {
        ...state,
        isEpisodeDetailsLoading: false,
        podcastEpisodesList: initialLoad
          ? [...episodesList]
          : [...state.podcastEpisodesList, ...episodesList],
      };
    }
    case PodcastType.GET_PODCAST_SHOW_EPISODES_ERROR: {
      return {
        ...state,
        isEpisodeDetailsLoading: false,
      };
    }
    case PodcastType.RESET_PODCAST_SHOW_EPISODES_LIST: {
      return {
        ...state,
        podcastEpisodesList: [] as IPodcastEpisode[],
      };
    }
    case PodcastType.FETCH_PODCAST_DETAILS_REQUEST:
    case PodcastType.FETCH_PODCAST_DETAILS_ERROR: {
      return {
        ...state,
      };
    }
    case PodcastType.FETCH_PODCAST_DETAILS_SUCCESS: {
      return {
        ...state,
        podcastDetails: action.payload,
      };
    }
    case PodcastType.RESET_PODCAST_DETAILS_STATE: {
      return {
        ...state,
        podcastDetails: {} as IPodcastDetails,
      };
    }
    default:
      return state;
  }
};

export default PodcastReducer;
