import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import NotFound from 'components/notFound/NotFound';
import CommentsList from 'components/reactions/CommentsList';
import CreateComment from 'components/reactions/CreateComment';
import GuestReactionView from 'components/reactions/GuestReactionView';
import { ICommentFormValues, useCommentForm } from 'components/reactions/useCommentForm';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { authUrl } from 'helpers/urls';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ContentType,
  followAndUnFollowProfile,
  getProfilePostDetailsById,
  IAddCommentParams,
  likeDislikeProfilePost,
  resetProfilePostDetailsState,
} from 'store';
import { addComment, getComments } from 'store/comment/CommentReducer';
import getPostShareUrl from 'store/common/CommonActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { newsCommentValidationSchema } from 'utils/validationSchema';

import ProfileSinglePost from './ProfileSinglePost';

interface IProfilePostProps {
  postId?: string;
}

export default function ProfilePost(props: IProfilePostProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const commentsRef = useRef<any>();
  const { contentId } = useParams();
  const { postId } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();
  const [showModal, setShowModal] = useState<boolean>(false);

  const { profilePostDetails, profilePostDetailsLoading } = useAppSelector(s => s.account);
  const { comments, loading: loadingComments, addCommentLoading } = useAppSelector(s => s.comment);

  const hasMoreData = profilePostDetails?.commentsCount > comments.length;
  const profilePostId = contentId || postId;

  useEffect(() => {
    if (profilePostId) dispatch(getProfilePostDetailsById(profilePostId));
    return () => {
      dispatch(resetProfilePostDetailsState());
    };
  }, [dispatch, profilePostId]);

  useEffect(() => {
    setTimeout(() => {
      if (
        !profilePostDetailsLoading &&
        profilePostDetails &&
        Object.keys(profilePostDetails).length > 0 &&
        isUserLoggedIn() &&
        commentsRef?.current &&
        location?.state === 'scroll-to-comments'
      ) {
        onCommentClick();
        navigate(location.pathname, {});
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePostDetailsLoading]);

  const getProfileComments = (initialLoad: boolean) => {
    if (profilePostId) {
      const params = {
        contentId: profilePostId,
        offset: !initialLoad ? comments.length : 0,
        type: ContentType.PROFILE,
      };
      dispatch(getComments(params));
    }
  };

  const onCommentClick = () => {
    if (isUserLoggedIn() && commentsRef?.current) {
      commentsRef.current.scrollIntoView(true);
    } else {
      setShowModal(true);
    }
  };

  const handleAddComment = async (submittedValues: ICommentFormValues) => {
    const payload: IAddCommentParams = {
      contentId: profilePostId,
      comment: submittedValues.comment,
      type: ContentType.PROFILE,
    };
    await dispatch(addComment(payload));

    resetForm();
  };

  const commentState: ICommentFormValues = {
    comment: '',
  };

  const commentFormik = useCommentForm(handleAddComment, commentState, newsCommentValidationSchema);

  const { resetForm } = commentFormik;

  const onLikeClick = async (setDisableLike: (value: boolean) => void) => {
    if (isUserLoggedIn()) {
      await dispatch(
        likeDislikeProfilePost(
          profilePostId as string,
          profilePostDetails?.isLiked,
          'profile',
          'profile-single-post',
        ),
      );
      setDisableLike(false);
    } else {
      setShowModal(true);
      setDisableLike(false);
    }
  };

  const shareProfilePost = async () => {
    if (isUserLoggedIn()) {
      await dispatch(getPostShareUrl(`${authUrl}share/${profilePostId}?type=post`));
    } else {
      setShowModal(true);
    }
  };

  const handleRelationChange = async () => {
    if (isUserLoggedIn()) {
      if (profilePostDetails?.isFollowing) {
        await dispatch(followAndUnFollowProfile(profilePostDetails?.userId, 'unfollow'));
      } else {
        await dispatch(followAndUnFollowProfile(profilePostDetails?.userId, 'follow'));
      }
    } else {
      setShowModal(true);
    }
  };

  return (
    <HomeWrapper maxWidth="lg">
      <Container disableGutters maxWidth="lg">
        <Grid container alignItems="center" justifyContent="center">
          {profilePostDetailsLoading ? (
            <Loader />
          ) : (
            profilePostDetails &&
            Object.keys(profilePostDetails).length > 0 && (
              <Grid item md={8} xs={12}>
                <ProfileSinglePost
                  handleRelationChange={handleRelationChange}
                  shareProfilePost={shareProfilePost}
                  onCommentClick={onCommentClick}
                  onLikeClick={onLikeClick}
                />

                {isUserLoggedIn() && (
                  <Box ref={commentsRef} sx={{ my: theme.spacing(3) }}>
                    <ComponentWrapper
                      isTitleInsideWrapper
                      withInfiniteScroll
                      infiniteScrollProps={{
                        loadOnMount: true,
                        hasMoreData,
                        isLoading: loadingComments,
                        onBottomHit: getProfileComments,
                      }}
                      title={`Responses (${profilePostDetails?.commentsCount ?? 0})`}
                      withDivider={false}
                    >
                      <CreateComment
                        commentFormik={commentFormik}
                        isLoading={addCommentLoading}
                        type="create"
                      />
                      <CommentsList comments={comments} type={ContentType.PROFILE} />
                      {loadingComments && <Loader />}
                    </ComponentWrapper>
                  </Box>
                )}
              </Grid>
            )
          )}
          {!profilePostDetailsLoading && Object.keys(profilePostDetails).length === 0 && (
            <NotFound />
          )}
          {/* <Grid item display={isMobile ? "none" : "block"} md={4} /> */}
        </Grid>
      </Container>
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </HomeWrapper>
  );
}
