import { FormikHelpers, useFormik } from 'formik';
import { IAdminAddUserState, ISignUpState } from 'store';

export const useSignupForm = (
  onSubmit: (
    values: ISignUpState,
    formikHelpers: FormikHelpers<ISignUpState>,
  ) => void | Promise<unknown>,
  initialValues: ISignUpState,
  validationSchema: any,
) => {
  return useFormik<ISignUpState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export const useAdminAddUserForm = (
  onSubmit: (
    values: IAdminAddUserState,
    formikHelpers: FormikHelpers<IAdminAddUserState>,
  ) => void | Promise<unknown>,
  initialValues: IAdminAddUserState,
  validationSchema: any,
) => {
  return useFormik<IAdminAddUserState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};
