import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomInputField from 'components/customFields/CustomInputField';
import AuthWrapper from 'components/wrapper/AuthWrapper';
import ErrorMessages from 'constants/ErrorMessages';
import { useUserNameForm } from 'pages/myAccount/personal/usePersonalForm';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkUserExists, IUserNameUpdateState, updateUserPersonalInfo } from 'store';
import { IResponseData } from 'store/common/CommonInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { handleRestrictSpaces } from 'utils/utils';
import { userNameValidationSchema } from 'utils/validationSchema';

function SetUsername() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loading } = useAppSelector(s => s.auth);

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const userNameState: IUserNameUpdateState = {
    userName: '',
  };

  const updateProfileUserName = async () => {
    if (values.userName) {
      const userExistsResponse: IResponseData = (await checkUserExists(
        `userName=${encodeURIComponent(values.userName ?? '')}`,
      )) as IResponseData;
      const { userNameExists } = userExistsResponse.data;
      if (!userNameExists) {
        const response: IResponseData = (await dispatch(
          updateUserPersonalInfo(values),
        )) as IResponseData;
        if (!response.error) {
          navigate('/');
        }
      } else {
        const signupErrorMsg = ErrorMessages.signup;
        setFieldTouched('userName', true, false);
        setFieldError('userName', signupErrorMsg.userNameExists);
      }
    }
  };

  const userNameFormik = useUserNameForm(
    updateProfileUserName,
    userNameState,
    userNameValidationSchema,
  );

  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldTouched,
    setFieldError,
  } = userNameFormik;

  return (
    <>
      <AuthWrapper>
        <Stack
          noValidate
          alignItems="center"
          autoComplete="new-password"
          component="form"
          height="100%"
          spacing={theme.spacing(2.5)}
          width="100%"
          onSubmit={handleSubmit}
        >
          <Typography component="div" sx={{ textAlign: 'center' }} variant={isMobile ? 'h5' : 'h2'}>
            Welcome Back!
          </Typography>
          <Typography
            color={theme.palette.text.secondary}
            component="div"
            fontWeight={400}
            variant={isMobile ? 'menu' : 'title2'}
          >
            Please enter a Username so you can interact with the community.
          </Typography>

          <CustomInputField
            fullWidth
            required
            autoComplete="new-password"
            error={!!(touched.userName && errors.userName)}
            errormessage={touched.userName ? errors.userName : undefined}
            id="userName"
            inputProps={{
              maxLength: 75,
              autoComplete: 'new-password',
            }}
            label="Username"
            name="userName"
            type="text"
            value={values.userName}
            onBlur={handleBlur}
            onChange={e => handleChange('userName')(handleRestrictSpaces(e.target.value))}
          />
          <Stack
            spacing={theme.spacing(3.5)}
            sx={{ mt: `${theme.spacing(4)} !important` }}
            width="100%"
          >
            <LoadingButton
              disableRipple
              disableTouchRipple
              disabled={!(isValid && dirty)}
              loading={loading}
              size="large"
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </AuthWrapper>
    </>
  );
}

export default memo(SetUsername);
