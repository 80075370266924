import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const homeNavRoutes = [
  '/',
  '/about',
  '/home',
  '/following',
  '/trending',
  '/news',
  '/podcasts',
  '/businesses',
  '/recent-businesses',
  '/article/:articleId',
  '/podcast/:podcastId',
  '/podcast-show/:podcastShowId',
  '/resources',
  '/business-wall/:businessId',
  '/news-provider-wall/:providerId',
  '/business/:contentId',
  '/share/:shareId',
  '/search',
  '/profile-wall/:userId',
  '/post/:contentId',
  '/town-crier/:contentId',
  'comment/:commentId',
];

export default function useShowHomeNav() {
  const location = useLocation();

  const isHomeNavRoute = useMemo(() => {
    const routeRegex =
      /^\/(article|business|business-wall|news-provider-wall|podcast|podcast-show|search|share|comment|profile-wall|post|town-crier)\/[a-zA-Z0-9=]{15,40}/gi;
    return homeNavRoutes.some(route => {
      if (routeRegex.test(location.pathname)) {
        return true;
      }
      return location.pathname === route;
    });
  }, [location.pathname]);

  return isHomeNavRoute;
}
