import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import React, { useEffect } from 'react';
import { getCards, resetPaymentState, showCardForm } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CardFormType } from 'store/payment/PaymentTypes';
import theme from 'theme';

import CardForm from './CardForm';
import ListCards from './ListCards';

const { ADD } = CardFormType;

function Payment() {
  const dispatch = useAppDispatch();
  const { showForm, cards, loading } = useAppSelector(s => s.payment);
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  useEffect(() => {
    dispatch(getCards());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetPaymentState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowForm = () => {
    dispatch(showCardForm(true, ADD));
  };

  return (
    <Stack spacing={theme.spacing(3)}>
      <Typography component="div" variant={isMobile ? 'h5' : 'h3'}>
        Payment
      </Typography>
      {showForm ? (
        <CardForm />
      ) : (
        <>
          <Stack spacing={theme.spacing(3.5)}>
            <Stack spacing={theme.spacing(1.5)}>
              <Typography variant={isMobile ? 'title3' : 'title1'}>Payment methods</Typography>
              <Typography fontWeight={400} variant={isMobile ? 'menu' : 'title2'}>
                Add and manage your payment methods.
              </Typography>
            </Stack>
            <Stack spacing={theme.spacing(2)}>
              {loading ? (
                <Loader customPaddingThemeSpacing={2} position="start" size={30} />
              ) : (
                cards.length > 0 && <ListCards />
              )}
              <Typography
                color="secondary.main"
                sx={{
                  textDecoration: 'underline',
                  textUnderlineOffset: '4px',
                  textDecorationThickness: '1.25px !important',
                  cursor: 'pointer',
                }}
                variant="h6"
                onClick={handleShowForm}
              >
                Add other payment method
              </Typography>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
}

export default Payment;
