import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import { StyledTableCell, StyledTableRow } from 'components/table/StyledTable';
import { useEffect } from 'react';
import { getMembersReport, IMembers, resetMembersState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

interface IProps {
  searchQuery: string;
  selectedMemberType: string;
  onSelectMember: (members: IMembers) => void;
}

const highlightStyle = {
  cursor: 'pointer',
  textUnderlineOffset: '2px',
  textDecoration: 'none',
  color: theme.palette.primary.main,
  '&:hover': {
    textDecoration: 'underline',
  },
};

function MembersList({ searchQuery, selectedMemberType, onSelectMember }: IProps) {
  const dispatch = useAppDispatch();

  const { loading, isMembersLoading, membersData } = useAppSelector(s => s.vettingDashboard);

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  useEffect(() => {
    return () => {
      dispatch(resetMembersState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(resetMembersState());
    if (searchQuery.length >= 3) {
      fetchMembersReport(true, selectedMemberType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, selectedMemberType]);

  const fetchMembersReport = (initialLoad: boolean, type?: string) => {
    const params = {
      offset: initialLoad ? 0 : membersData.offset,
      userType: type || selectedMemberType,
      reset: initialLoad,
      searchQuery,
      userWithBusiness: 'no',
    };
    dispatch(getMembersReport(params));
  };

  const handleScroll = (event: any) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;

    if (scrollHeight <= Math.ceil(scrollTop + clientHeight)) {
      if (searchQuery.length >= 3 && membersData.hasMoreData) {
        fetchMembersReport(false);
      }
    }
  };

  return (
    <>
      <Stack
        className="custom-pref-scrollbar"
        height={isMobile ? 'calc(100vh - 275px)' : '100%'}
        paddingBottom={theme.spacing(1)}
        paddingRight={theme.spacing(2)}
        spacing={theme.spacing(1.5)}
        sx={{ overflowY: 'auto' }}
        onScroll={handleScroll}
      >
        {membersData && membersData.members.length > 0 ? (
          <TableContainer style={{ overflow: 'initial' }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>User Name</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(!isMembersLoading || membersData.members.length > 0) &&
                  membersData.members.map((member, index) => {
                    const disabled = Boolean(member.businessType);

                    const handleClick = () => {
                      if (!disabled) {
                        onSelectMember(member);
                      }
                    };

                    return (
                      <StyledTableRow key={`${member.documentId}_${index.toString()}`}>
                        <StyledTableCell>
                          <Typography
                            component="div"
                            sx={!disabled ? highlightStyle : null}
                            onClick={handleClick}
                          >
                            {member.fullName}
                          </Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Typography
                            component="div"
                            sx={!disabled ? highlightStyle : null}
                            onClick={handleClick}
                          >
                            {member.userName}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>{member.email}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Stack alignItems="center" flexGrow={1} height="100%" justifyContent="center">
            <Typography align="center" component="div" variant="title2">
              No users found
            </Typography>
          </Stack>
        )}
        {loading && <Loader customPaddingThemeSpacing={1} size={30} />}
      </Stack>
    </>
  );
}

export default MembersList;
