import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import CustomMenu from 'components/menu/CustomMenu';
import { EllipsisIconLarge } from 'helpers/images';
import React, { useState } from 'react';
import theme from 'theme';

interface IListCardMenuProps {
  index?: number;
  onEdit: (index?: number) => void;
  onDelete: (index?: number) => void;
}

function ListCardMenu(props: IListCardMenuProps) {
  const { onEdit, onDelete, index } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    onEdit(index as number);
  };

  const handleDelete = () => {
    handleClose();
    onDelete(index as number);
  };

  return (
    <>
      <CustomMenu
        excludeMarginTop
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        variant="menu"
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </CustomMenu>
      <Box alignSelf="flex-start" display="flex" sx={{ cursor: 'pointer' }} onClick={handleClick}>
        <IconButton sx={{ padding: theme.spacing(1.75, 1) }}>
          <EllipsisIconLarge />
        </IconButton>
      </Box>
    </>
  );
}

export default ListCardMenu;
