import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import Badge from '@mui/material/Badge';
import BadgeAvatar from 'components/avatar/BadgeAvatar';
import { IMAGE_EXTENSIONS } from 'constants/constants';
import { ImageType } from 'store';

interface IImageBadgeProps {
  handleEditImage: () => void;
  handleDeleteImage?: () => void;
  showDelete?: boolean;
  imageSrc: string;
  imageType: string;
  changeImage?: boolean;
  selectFile?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ImageBadgeComponent(props: IImageBadgeProps) {
  const {
    handleDeleteImage,
    handleEditImage,
    imageSrc,
    imageType,
    showDelete = false,
    changeImage = false,
    selectFile,
  } = props;

  const { BACKGROUND, MEDIA, LOGO, AD, COUPON } = ImageType;

  const boxDimention = {
    [LOGO]: { height: 132, width: 132 },
    [BACKGROUND]: { height: 'auto', width: undefined },
    [MEDIA]: { height: 112, width: 112 },
    [AD]: { height: 'auto', width: undefined },
    [COUPON]: { height: 'auto', width: undefined },
  };

  return (
    <Badge
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      badgeContent={
        <>
          {showDelete && (
            <BadgeAvatar onClick={handleDeleteImage}>
              <DeleteIcon fontSize="small" />
            </BadgeAvatar>
          )}
        </>
      }
      sx={{
        height: boxDimention[imageType as ImageType]?.height,
        width: boxDimention[imageType as ImageType]?.width ?? '100%',
      }}
    >
      <img
        alt={imageType}
        height="100%"
        src={imageSrc}
        style={{ border: '1px solid lightgrey', borderRadius: '10px' }}
        width="100%"
      />
      {changeImage ? (
        <BadgeAvatar showSecondBadge>
          <label
            htmlFor={imageType}
            style={{
              width: boxDimention[imageType as ImageType]?.width ?? '100%',
              height: boxDimention[imageType as ImageType]?.height,
            }}
          >
            <input
              accept={IMAGE_EXTENSIONS}
              id={imageType}
              name={imageType}
              style={{ display: 'none' }}
              type="file"
              onChange={selectFile}
              onClick={(e: any) => {
                e.target.value = null;
              }}
            />
            <AddPhotoAlternateIcon fontSize="small" />
          </label>
        </BadgeAvatar>
      ) : (
        <BadgeAvatar showSecondBadge onClick={handleEditImage}>
          <AddPhotoAlternateIcon fontSize="small" />
        </BadgeAvatar>
      )}
    </Badge>
  );
}
