import AdsType from './AdvertisementTypes';

export interface IAdvertisement {
  clickUrl: string;
  currentSequence: string;
  documentId: string;
  enddate: string;
  height: number;
  impressionUrl: string;
  media: string;
  page: string;
  position: string;
  sequence: number;
  startdate: string;
  text: string;
  type: string;
  url: string;
  width: number;
}

interface IFetchAdsRequest {
  type: AdsType.FETCH_COMMON_ADS_REQUEST;
}

interface IFetchAdsSuccess {
  type: AdsType.FETCH_COMMON_ADS_SUCCESS;
  payload: IAdvertisement[];
}

interface IFetchAdsError {
  type: AdsType.FETCH_COMMON_ADS_ERROR;
}

interface IFetchPositionAdsRequest {
  type: AdsType.FETCH_POSITION_ADS_REQUEST;
}

interface IFetchPositionAdsSuccess {
  type: AdsType.FETCH_POSITION_ADS_SUCCESS;
  payload: IAdvertisement[];
}

interface IFetchPositionAdsError {
  type: AdsType.FETCH_POSITION_ADS_ERROR;
}
interface IRemoveAdRequest {
  type: AdsType.REMOVE_AD_REQUEST;
}

interface IRemoveAdSuccess {
  type: AdsType.REMOVE_AD_SUCCESS;
  // payload: IAdvertisement[];
}

interface IRemoveAdError {
  type: AdsType.REMOVE_AD_ERROR;
}

interface IResetAdState {
  type: AdsType.RESET_ADS_STATE;
}

export type AdsActions =
  | IFetchAdsRequest
  | IFetchAdsSuccess
  | IFetchAdsError
  | IFetchPositionAdsRequest
  | IFetchPositionAdsSuccess
  | IFetchPositionAdsError
  | IRemoveAdRequest
  | IRemoveAdSuccess
  | IRemoveAdError
  | IResetAdState;

export type IAdsState = {
  loading: boolean;
  positionAdsLoading: boolean;
  commonAdsData: IAdvertisement[];
  positionAdsData: IAdvertisement[];
};

export enum AdsPages {
  BUSINESS = 'businesses',
  FOLLOWING = 'following',
  HOME = 'home',
  NEWS = 'news',
  PODCASTS = 'podcasts',
}

export enum AdPositions {
  A = 'A',
  B = 'B',
  D = 'D',
  G = 'G',
  H = 'H',
}
