import './ wdyr';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Store from 'store/store';

import App from './App';
import reportWebVitals from './reportWebVitals';

const { REACT_APP_GA_ID, REACT_APP_GTM_ID } = process.env;
// Google Tag Manager (GTM)
if (REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: `${REACT_APP_GTM_ID}`,
  };
  TagManager.initialize(tagManagerArgs);
}
// Google Analytics (GA)
if (REACT_APP_GA_ID) ReactGA.initialize(`${REACT_APP_GA_ID}`);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
