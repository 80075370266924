import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import LinkPreview from 'components/linkPreview';
import Reactions from 'components/reactions';
import PostContent from 'components/singlePost/PostContent';
import PostFooter from 'components/singlePost/PostFooter';
import PostHeader from 'components/singlePost/PostHeader';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import { useMemo } from 'react';
import { Relationship } from 'store';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';
import { getPreviewLink } from 'utils/utils';

interface IBusinessSinglePostProps {
  handleRelationChange: () => void;
  shareBusiness: () => void;
  onCommentClick: () => void;
  onLikeClick: (setDisableLike: React.Dispatch<React.SetStateAction<boolean>>) => void;
}

export default function BusinessSinglePost({
  handleRelationChange,
  shareBusiness,
  onCommentClick,
  onLikeClick,
}: IBusinessSinglePostProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { businessPostDetails, followLoading } = useAppSelector(s => s.business);

  const previewLink = useMemo(() => {
    const uniqueLink = getPreviewLink(businessPostDetails?.textContent);
    return !(businessPostDetails?.image?.croppedImageUrl || businessPostDetails?.image?.imageURL)
      ? uniqueLink
      : '';
  }, [
    businessPostDetails?.image?.croppedImageUrl,
    businessPostDetails?.image?.imageURL,
    businessPostDetails?.textContent,
  ]);

  return (
    <>
      <ComponentWrapper title="">
        <PostHeader
          contentType="Business"
          date={businessPostDetails?.createdAt}
          providerLogo={businessPostDetails?.logo?.croppedImageReadUrl}
          providerName={businessPostDetails?.businessName}
          providerUrl={`/business-wall/${businessPostDetails?.businessId}`}
        />
        <Box sx={{ my: theme.spacing(2) }}>
          <PostContent content={businessPostDetails?.textContent} post="business" />
        </Box>
        {businessPostDetails?.image?.croppedImageUrl || businessPostDetails?.image?.imageURL ? (
          <Box sx={{ my: theme.spacing(2) }}>
            <img
              alt="business-logo"
              src={
                businessPostDetails?.image?.croppedImageUrl || businessPostDetails?.image?.imageURL
              }
              style={{ borderRadius: '10px' }}
              width="100%"
            />
          </Box>
        ) : (
          <></>
        )}
        {previewLink ? <LinkPreview key={previewLink} type="post" url={previewLink} /> : null}
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Reactions
            showCount
            commentsCount={businessPostDetails?.commentsCount}
            isLiked={businessPostDetails?.isLiked}
            isMobile={false}
            likesCount={businessPostDetails?.likesCount}
            showReactionText={!isMobile}
            onCommentClick={onCommentClick}
            onLikeClick={onLikeClick}
            onShareClick={shareBusiness}
          />
          {businessPostDetails?.role !== 'owner' && !businessPostDetails?.isBusinessDeactivated && (
            <PostFooter
              followStatus={
                businessPostDetails?.isFollowing ? Relationship.UNFOLLOW : Relationship.FOLLOW
              }
              isLoading={followLoading}
              providerName=""
              providerType="Business"
              showText={false}
              onFollowBtnClick={handleRelationChange}
            />
          )}
        </Stack>
      </ComponentWrapper>
    </>
  );
}
