import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ConfirmationModal from 'components/confirmationModal/ConfirmationModal';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import CustomLabelField from 'components/customFields/CustomLabelField';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { BackIcon } from 'helpers/images';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  approveBusiness,
  denyBusiness,
  getBusinessesDetailsByBusinessId,
  IResponseData,
  resetVettingDashboardBusinessDetailsState,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

enum ModalType {
  APPROVE,
  DENY,
}

export default function PendingBusinessDetails() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loading, businessDetails } = useAppSelector(s => s.vettingDashboard);
  const { background, logo, media } = businessDetails.images ?? {};

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(ModalType.APPROVE);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { businessId } = useParams();

  useEffect(() => {
    scrollToTop();
    if (businessId) dispatch(getBusinessesDetailsByBusinessId(businessId));

    return () => {
      dispatch(resetVettingDashboardBusinessDetailsState());
    };
  }, [dispatch, businessId]);

  const getSocialUrlByType = (type: string): string => {
    if (businessDetails?.socialLinks?.length > 0) {
      const data = businessDetails.socialLinks.find((social: any) => social.socialType === type);
      return data?.link ?? '';
    }
    return '';
  };
  const socialLinks = {
    facebookUrl: getSocialUrlByType('facebook'),
    instagramUrl: getSocialUrlByType('instagram'),
    linkedinUrl: getSocialUrlByType('linkedin'),
    twitterUrl: getSocialUrlByType('twitter'),
  };

  const handleApprove = async () => {
    const response: IResponseData = (await dispatch(
      approveBusiness(businessId as string),
    )) as IResponseData;
    if (!response.error) {
      navigate(-1);
    }
  };
  const handleDeny = async () => {
    const response: IResponseData = (await dispatch(
      denyBusiness(businessId as string),
    )) as IResponseData;
    if (!response.error) {
      navigate(-1);
    }
  };

  const onSubmit = () => {
    if (modalType === ModalType.APPROVE) {
      handleApprove();
    } else {
      handleDeny();
    }
  };

  return (
    <HomeWrapper maxWidth="midmd">
      <ComponentWrapper title="">
        <Box>
          <Button sx={{ p: 0, mb: theme.spacing(3) }} onClick={() => navigate(-1)}>
            <SvgIcon inheritViewBox component={BackIcon} sx={{ height: '16px', width: '10px' }} />
            <Typography component="span" sx={{ ml: theme.spacing(1.25) }}>
              Back
            </Typography>
          </Button>
        </Box>
        <Grid container spacing={theme.spacing(2)}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
              Business Information
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(2)}>
              <Grid container>
                <Grid item xs={6}>
                  <CustomLabelField
                    label="Contact Person"
                    value={businessDetails.contactPersonName}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    paddingLeft: theme.spacing(2),
                  }}
                  xs={4}
                >
                  <CustomLabelField label="Business Email" value={businessDetails.email} />
                </Grid>
              </Grid>
              <CustomLabelField label="Business Name" value={businessDetails.name} />
              <CustomLabelField label="Address" value={businessDetails.address} />
              <Grid container>
                <Grid item xs={4}>
                  <CustomLabelField label="City" value={businessDetails.city} />
                </Grid>
                <Grid
                  item
                  sx={{
                    paddingLeft: theme.spacing(2),
                  }}
                  xs={4}
                >
                  <CustomLabelField label="State" value={businessDetails.state?.name} />
                </Grid>
                <Grid
                  item
                  sx={{
                    paddingLeft: theme.spacing(2),
                  }}
                  xs={4}
                >
                  <CustomLabelField label="Zip Code" value={businessDetails.zipcode} />
                </Grid>
              </Grid>
              <CustomLabelField label="Business Phone" value={businessDetails.phone} />
              <CustomLabelField label="Business Website" value={businessDetails.website} />
            </Stack>
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(2)}>
              <CustomLabelField
                label="Business Category"
                value={
                  businessDetails.businessCategories
                    ? businessDetails.businessCategories.map(x => x.name)
                    : []
                }
              />
              <Stack
                alignItems="center"
                alignSelf="flex-start"
                direction="row"
                justifyContent="center"
              >
                <Checkbox
                  key={Math.random()}
                  disabled
                  checked={businessDetails.onlineOnly}
                  className=""
                  color="secondary"
                  id="onlineBusiness"
                  name="businessInformation.onlineOnly"
                />
                <Typography>Online only Business</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Divider
          sx={{
            borderColor: theme.palette.secondary.main,
            my: theme.spacing(5),
            width: '100%',
          }}
        />
        <Grid container spacing={5}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={2} sx={{ py: theme.spacing(3) }}>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                  Business Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(2)}>
                  <CustomLabelField label="Tagline" value={businessDetails.tagline} />
                  <CustomLabelField label="Description" value={businessDetails.description} />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container spacing={theme.spacing(2)} sx={{ py: theme.spacing(3) }}>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                  Social Links
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(2)}>
                  <CustomLabelField label="Facebook URL" value={socialLinks.facebookUrl} />
                  <CustomLabelField label="Instagram URL" value={socialLinks.instagramUrl} />
                  <CustomLabelField label="Linkedin URL" value={socialLinks.linkedinUrl} />
                  <CustomLabelField label="Twitter URL" value={socialLinks.twitterUrl} />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          sx={{
            borderColor: theme.palette.secondary.main,
            my: theme.spacing(5),
            width: '100%',
          }}
        />
        <Stack spacing={theme.spacing(3)} sx={{ px: theme.spacing(4), py: theme.spacing(3) }}>
          <Typography component="h4" variant="h4Bold">
            Business Images
          </Typography>
          <Typography component="h5" variant="h5Bold">
            Logo
          </Typography>
          <Stack alignItems="start">
            {logo?.croppedImageReadUrl ? (
              <img
                alt="Logo"
                height={200}
                src={logo?.croppedImageReadUrl}
                style={{ border: '2px solid lightgrey', borderRadius: '5%' }}
                width={200}
              />
            ) : (
              <Typography component="h6" variant="h6Bold">
                No Logo available
              </Typography>
            )}
          </Stack>

          <Typography component="h5" variant="h5Bold">
            Background
          </Typography>
          <Stack alignItems="start">
            {background?.croppedImageReadUrl ? (
              <img
                alt="BACKGROUND"
                height={200}
                src={background?.croppedImageReadUrl}
                style={{ border: '2px solid lightgrey', borderRadius: '5%' }}
                width={200}
              />
            ) : (
              <Typography component="h6" variant="h6Bold">
                No Background available
              </Typography>
            )}
          </Stack>
          <Typography component="h5" variant="h5Bold">
            Media
          </Typography>
          <Stack alignItems="start">
            {media && media.length > 0 ? (
              <>
                {media.map(img => (
                  <>
                    <img
                      alt="media"
                      height={200}
                      src={img?.croppedImageReadUrl}
                      style={{
                        border: '2px solid lightgrey',
                        borderRadius: '5%',
                      }}
                      width={200}
                    />
                  </>
                ))}
              </>
            ) : (
              <Typography component="h6" variant="h6Bold">
                No Media available
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-evenly" width="100%">
          <Button
            color="secondary"
            size="large"
            sx={{
              mt: theme.spacing(5),
              alignSelf: 'center',
              px: theme.spacing(8),
              backgroundColor: theme.palette.success.dark,
              color: theme.palette.common.white,
              '&:hover': {
                backgroundColor: theme.palette.text.primary,
                color: theme.palette.common.white,
              },
            }}
            type="submit"
            variant="contained"
            onClick={() => {
              setModalType(ModalType.APPROVE);
              setShowModal(true);
            }}
          >
            Approve
          </Button>
          <Button
            color="secondary"
            size="large"
            sx={{
              mt: theme.spacing(5),
              alignSelf: 'center',
              px: theme.spacing(8),
              backgroundColor: theme.palette.error.dark,
              color: theme.palette.common.white,
              '&:hover': {
                backgroundColor: theme.palette.text.primary,
                color: theme.palette.common.white,
              },
            }}
            type="submit"
            variant="contained"
            onClick={() => {
              setModalType(ModalType.DENY);
              setShowModal(true);
            }}
          >
            Deny
          </Button>
        </Stack>
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowModal(false)}
          isMobile={isMobile}
          open={showModal}
          setOpen={setShowModal}
          title={modalType === ModalType.APPROVE ? 'Approve Business?' : 'Deny Business?'}
        >
          <ConfirmationModal
            loading={loading}
            modalText={
              modalType === ModalType.APPROVE
                ? 'Are you sure you want to approve this business?'
                : 'Are you sure you want to deny this business?'
            }
            submitBtnText={modalType === ModalType.APPROVE ? 'Approve' : 'Deny'}
            onClose={() => setShowModal(false)}
            onSubmit={onSubmit}
          />
        </ToggleDrawerDialog>
      </ComponentWrapper>
    </HomeWrapper>
  );
}
