import { IPostType } from 'store';

export const NAME_REGEX = /^(?=.*[a-zA-Z])[a-zA-Z0-9' -]*$/;
export const BUSINESS_NAME_REGEX = /^.*[a-zA-Z]+.*$/;
export const BUSINESS_ADDRESS_REGEX = /^[a-zA-Z0-9]+[ a-zA-Z0-9_,.'"\-&]*$/;
export const NAME_REGEX_2 = /^[a-zA-Z]{2}[a-zA-Z0-9' -]*$/;
export const NAME_REGEX_3 = /^(?=.*[a-zA-Z])[a-zA-Z' -]*$/;
export const USERNAME_REGEX = /^[a-zA-Z]{1}[a-zA-Z0-9 -]*$/;
export const ONLY_ALPHABETS_SPACES = /[^a-zA-Z\s]/gi;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,128})/;

export const US_STATES: string[] = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const URL_REGEX =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const US_PHONE_NUMBER_REGEX = /^((\()?[1-9]{1}[0-9]{2}(\))?)[\s-]?[0-9]{3}[\s-]?[0-9]{4}$/;
export const FACEBOOK_URL_REGEX =
  /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/;
export const INSTAGRAM_URL_REGEX =
  /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/gim;
export const LINKEDIN_URL_REGEX =
  /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))$/gm;
export const TWITTER_URL_REGEX = /(https:\/\/twitter.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/;

// The below two are Currently not in use, check and remove them later if not using at all.
export const POSITION_SEQUENCE_TEXT = 'positionSequence';
export const DEFAULT_POSITION_SEQUENCE = 'C=0'; // For Pages - Home, News & Podcasts.

export const CURRENT_AD_SEQUENCE_TEXT = 'currentAdSequence';
export const DEFAULT_AD_SEQUENCE = {
  A: 'A=0',
  B: 'B=0',
  C: 'C=0',
  BA: 'A=0', // For Business
};

export const PLAN_NAMES: any = {
  free_member: 'Member',
  paid_member_initiated: 'Patriot Member',
  free_business: 'Business Directory',
  paid_business_initiated: 'Freedom Chamber',
};

export enum UserType {
  FREE = 'free',
  PAYMENT_INITIATED = 'payment_initiated',
  PAID = 'paid',
}

export const AD_IMAGE_RESOLUTIONS: any = {
  'businesses-A': {
    height: 453,
    width: 321,
  },
  'businesses-MA': {
    height: 118,
    width: 321,
  },
  'following-A': {
    height: 119,
    width: 692,
  },
  'following-MA': {
    height: 118,
    width: 321,
  },
  'home-A': {
    height: 119,
    width: 692,
  },
  'home-B': {
    height: 118,
    width: 1123,
  },
  'home-C': {
    height: 119,
    width: 692,
  },
  'home-MA': {
    height: 118,
    width: 321,
  },
  'home-MB': {
    height: 118,
    width: 361,
  },
  'home-MC': {
    height: 118,
    width: 321,
  },
  'news-A': {
    height: 119,
    width: 692,
  },
  'news-B': {
    height: 119,
    width: 692,
  },
  'news-C': {
    height: 118,
    width: 932,
  },
  'news-MA': {
    height: 118,
    width: 321,
  },
  'news-MB': {
    height: 118,
    width: 361,
  },
  'news-MC': {
    height: 118,
    width: 321,
  },
  'podcasts-A': {
    height: 119,
    width: 692,
  },
  'podcasts-B': {
    height: 119,
    width: 692,
  },
  'podcasts-C': {
    height: 118,
    width: 932,
  },
  'podcasts-MA': {
    height: 118,
    width: 321,
  },
  'podcasts-MB': {
    height: 118,
    width: 361,
  },
  'podcasts-MC': {
    height: 118,
    width: 321,
  },
};

export const MEMBER_PLANS: any = [
  {
    plan: 'free_member',
    amount: '$0',
    subTitle: 'free forever',
    benefitsHeading: `What's included`,
    features: [
      'Personalized Dashboard',
      'Business Network Access',
      'Ability to Like, Share & Comment',
      'Ability to Interact with Other Users',
      'Ability to Follow Businesses',
    ],
    //     limitedFeatures: ['Personalized Profile Wall', 'Create Content', 'Engage with Followers'],
  },
];

export const BUSINESS_PLANS: any = [
  {
    plan: 'free_business',
    amount: '$0',
    subTitle: 'free forever',
    benefitsHeading: `What's included`,
    features: [
      'Personalized Dashboard',
      'Searchable & Shareable Profile',
      'Company Logo & Banner Images',
      'Company Description & Website URL',
      'Ability to Like, Share & Comment',
    ],
  },
  {
    plan: 'paid_business_initiated',
    amount: '',
    subTitle: '',
    benefitsTitle: 'THE Pro-American Business Network',
    benefitsHeading: 'Includes Business Directory Benefits plus',
    features: [
      'Engage with Followers',
      'Ability to Extend Custom Offers to Followers',
      'Post Pictures, Coupons & Job Openings',
      'Highlighted in Inside the Square Newsletter',
      '30 day Banner Ad Rotation (First 500 Members, Annual Subscription only)',
      'Membership Directory',
      'Exclusive Discounts & Benefits',
      'JCN Advocacy Update',
      'Legal / HR Updates (Coming Soon)',
      'IRS Updates (Coming Soon)',
      'Newsletter & Bulletins',
      'Networking',
      'Exclusive Updates',
      'Roundtables',
    ],
  },
];

export const IMAGE_EXTENSIONS = '.png,.jpeg,.jpg,.webp,.heic';

export const TYPE_LABEL_MAP: { [key in IPostType]: string } = {
  business: 'BUSINESS',
  'business-only': 'BUSINESS',
  news: 'NEWS',
  'paid-member-post': 'PATRIOT',
  'paid-member': 'PATRIOT',
  podcasts: 'PODCASTS',
  'town-crier': 'TOWN CRIER',
  'town-crier-post': 'TOWN CRIER',
};
