const ErrorMessages = {
  signup: {
    userName:
      'Username must be between 4-75 characters, contain 1 letter, and may contain no special characters except hyphen.',
    userNameExists: 'Username is taken - Please try another.',
    fullName:
      'Name must be between 1-35 characters, contain 1 letter, and may contain no special characters except hyphen (-) or apostrophe.',
    email: 'Please enter a valid email address.',
    emailExists: 'Account already exists - Please Sign In.',
    password:
      'Password must be between 8-128 characters, it must contain 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character.',
    confirmPassword: 'Password must match.',
    tncAccepted: 'Please accept the Terms & Conditions and Privacy Policy.',
  },
  login: {
    wrongCredentials: 'Incorrect Username or Password',
    emailInvalidFormat: 'Please enter a valid email address',
    // passwordInvalidFormat:
    //   "Password must be between 8-128 characters, it must contain 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character.",
  },
  payment: {
    cardAlias: 'Alias must be between 2-75 characters, contain at least 1 letter.',
    cardNumber: 'Please enter a valid card number.',
    name: 'Name must be between 1-30 characters and contain atleast 1 letter, and may contain no special characters except hyphen and apostrophe - spaces are allowed.',
    cardExpiryMonth: 'Please select a month.',
    cardExpiryYear: 'Please select a year.',
    address:
      'Address must be 5-32 characters, period, apostrophe, hyphen, ampersand, and comma allowed.',
    addressEmpty: 'Please enter Address.',
    city: 'City must be 2-36 characters.',
    cityEmpty: 'Please enter a City.',
    state: 'Please enter a State.',
    zipcode: 'Please enter a valid Zip code.',
    firstName:
      'Name must be between 1-35 characters, contain 1 letter, and may contain no special characters except hyphen (-) or apostrophe.',
    partnerCode: 'Invalid Partner Code',
    cvv: 'Please enter CVV',
  },

  forgotPassword: {
    email: 'Please enter a valid email address.',
    emailNotPresent: 'Account Not Found - Please Sign Up',
    emailRequired: 'Email is required',
    resendEmailSuccess: 'Reset Password link sent successfully',
  },
  verificationEmail: {
    resendMailSuccess: 'Verification link sent successfully.',
    linkExpired: 'Email Verification link has expired. Please request a new link email.',
    // linkNotExpired: "Please try again in 5 minutes.",
    // maxLimitReached:
    //   "You have requested the max number of links. Please try again in 60 minutes.",
  },
  businessInformation: {
    businessNameRequired:
      'Business Name must be between 2-75 characters, contain at least 1 letter.',
    businessCategoryRequired: 'Please select a Category.',
    businessWebsiteRequired: 'Please enter a valid website URL',
    businessPhoneRequired: 'Please enter a valid phone number.',
    address: 'Address must be 5-32 characters.',
    addressEmpty: 'Please enter Address.',
    city: 'City must be 2-36 characters.',
    cityEmpty: 'Please enter a City.',
    state: 'Please select a State.',
    zipcode: 'Please enter a valid Zip code.',
    email: 'Please enter a valid email address',
    contactPersonName:
      'Name must be between 1-35 characters, contain 1 letter, and may contain no special characters except hyphen (-) or apostrophe.',
  },
  businessDetails: {
    taglineRequired: 'Please enter a Tagline',
    taglineLimit: 'Tagline must be between 20-100 characters. Please revise and try again.',
    descriptionRequired: 'Please enter Description',
    descriptionLimit:
      'Description must be between 100-1000 characters. Please revise and try again.',
  },
  socialLinks: {
    invalidFacebookUrl: 'Please enter valid Facebook URL.',
    invalidInstagramUrl: 'Please enter valid Instagram URL.',
    invalidLinkedInUrl: 'Please enter valid LinkedIn URL.',
    invalidTwitterUrl: 'Please enter valid Twitter URL.',
  },
  addComment: {
    comment: 'Comment must be between 1-1000 characters. Please revise and try again.',
  },
  affiliatePaymentReceiveForm: {
    name: 'Name must be between 1-35 characters, contain 1 letter, and may contain no special characters except hyphen (-) or apostrophe.',
    routingNumber: 'Invalid Routing Number.',
    accountNumber: 'Invalid Account Number.',
  },
  addPodcastProvider: {
    name: 'Please enter a name',
    externalID: 'Please enter External ID',
    externalShowLink: 'Please enter External Show Link',
  },
  profileSetup: {
    nameRequired: 'Name must be between 2-75 characters, contain at least 1 letter.',
    tagline: 'Please enter a tagline',
  },
  addAds: {
    position: 'Please select a position',
    page: 'Please select a page',
    url: 'Please enter a URL',
    validUrl: 'Please enter valid URL',
    startDate: 'Please select a start date',
    endDate: 'Please select a end date',
  },
  addSponsor: {
    name: 'Please enter a name',
    url: 'Please enter a URL',
    validUrl: 'Please enter valid URL',
  },

  userReport: {
    others: 'Description must be between 1-200 characters, contain at least 1 letter.',
    otherEmpty: 'Please enter description.',
  },
};

export default ErrorMessages;
