import CommonTypes from './CommonTypes';

interface IAction {
  type: string;
  payload?: unknown;
}

interface IShareRequest {
  type: CommonTypes.POST_SHARE_REQUEST;
}

interface IShareSuccess {
  type: CommonTypes.POST_SHARE_SUCCESS;
}

interface IShareError {
  type: CommonTypes.POST_SHARE_ERROR;
}

export type CommonActions = IShareRequest | IShareSuccess | IShareError;

export type Action = IAction;

export interface ICountryDataInterface {
  name: string;
  code: string;
  id: number;
  phone: number;
  symbol: string;
  capital: string;
  currency: string;
  continent: string;
  continent_code: string;
  alpha_3: string;
}

export interface IResponseData {
  data: any;
  error: boolean;
  message: string;
  status: number | string;
  [Key: string]: any;
}

export enum ResponseCodes {
  OK_RESPONSE = 200,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  CONFLICT_REQUEST = 409,
  UNPROCESSIBLE_ENTITY = 422,
}

export interface IPasswordRule {
  rule: string;
  valid: boolean;
}

export enum UserRole {
  GUEST = 'guest',
  HOST = 'host',
}

export enum Relationship {
  FOLLOW = 'Follow',
  UNFOLLOW = 'Unfollow',
}

export interface IImageUploadState {
  previewImage: string;
  file: Blob | null;
  editImage: boolean;
}
