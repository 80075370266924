import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BusinessInformation from 'components/business/BusinessInformation';
import { useProfileBusinessInformationForm } from 'components/business/useBusinessForm';
import { memo } from 'react';
import { updateBusinessData } from 'store/business/BusinessAction';
import {
  BusinessComponentType,
  BusinessSetupSteps,
  IBusinessInformationState,
  IProfileBusinessInformationState,
} from 'store/business/BusinessInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { handleMaskToUSNumber, unmaskUSNumber } from 'utils/utils';
import { freeBusinessInfoSchema, profileBusinessInfoSchema } from 'utils/validationSchema';

const { PROFILE } = BusinessComponentType;

function BusinessInfo() {
  const dispatch = useAppDispatch();
  const { businessDetails, isUpdateBusinessLoading } = useAppSelector(s => s.business);
  // useEffect(() => {
  //   if (Object.keys(businessDetails).length === 0) {
  //     dispatch(getBusinessDetailsByUserId());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch]);

  const businessId: string = businessDetails.documentId ?? '';

  const businessInfoInitialState: IBusinessInformationState = {
    name: businessDetails?.name ?? '',
    businessCategories: businessDetails?.businessCategories ?? [],
    address: businessDetails?.address ?? '',
    website: businessDetails?.website ?? '',
    city: businessDetails?.city ?? '',
    stateId: businessDetails?.stateId ?? '',
    phone: handleMaskToUSNumber(businessDetails?.phone) ?? '',
    zipcode: businessDetails?.zipcode ?? '',
    onlineOnly: businessDetails?.onlineOnly ?? false,
    email: businessDetails?.email ?? '',
    contactPersonName: businessDetails?.contactPersonName ?? '',
  };

  const onSubmit = async () => {
    const requestData = {
      ...values.businessInformation,
      businessCategoryIds:
        values.businessInformation.businessCategories.length > 0
          ? values.businessInformation.businessCategories.map(c => c.documentId)
          : [],
      phone: values.businessInformation.phone
        ? unmaskUSNumber(values.businessInformation.phone)
        : '',
    };

    if (dirty) {
      await dispatch(updateBusinessData(requestData, businessId));
    }
  };

  const profileBusinessInfoInitialState: IProfileBusinessInformationState = {
    businessInformation: businessInfoInitialState,
  };

  const isFree = businessDetails?.step === BusinessSetupSteps.FREE_BUSINESS_LISTED;

  const formik = useProfileBusinessInformationForm(
    onSubmit,
    profileBusinessInfoInitialState,
    isFree ? freeBusinessInfoSchema : profileBusinessInfoSchema,
  );

  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    dirty,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = formik;

  return (
    <Box noValidate component="form" onSubmit={handleSubmit}>
      <BusinessInformation
        componentType={PROFILE}
        errors={errors.businessInformation}
        handleBlur={handleBlur}
        handleChange={handleChange}
        isFree={isFree}
        setFieldValue={setFieldValue}
        setTouched={setFieldTouched}
        touched={touched.businessInformation}
        values={values.businessInformation}
      />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={theme.spacing(2)}
        sx={{ mt: theme.spacing(4) }}
      >
        <Button
          disableRipple
          disableTouchRipple
          fullWidth
          size="large"
          type="button"
          variant="outlined"
          onClick={() => resetForm()}
        >
          Cancel
        </Button>
        <LoadingButton
          fullWidth
          loading={isUpdateBusinessLoading}
          size="large"
          type="submit"
          variant="contained"
        >
          Save
        </LoadingButton>
      </Stack>
    </Box>
  );
}

export default memo(BusinessInfo);
