import cloneDeep from 'clone-deep';
import { Reducer } from 'redux';
import { UpdateCommentCountType } from 'store/comment/CommentInterface';

import {
  IFeaturedNews,
  INewsArticle,
  INewsState,
  IProvider,
  IShortNews,
  NewsActions,
} from './NewsInterface';
import NewsType from './NewsTypes';

export const initialState: INewsState = {
  isNewsLoading: false,
  isFeaturedNewsLoading: false,
  isGetProvidersLoading: false,
  isUpdateProvidersLoading: false,
  isDeleteNewsCommentLoading: false,
  isEditNewsCommentsLoading: false,
  allNews: {
    pageNumber: 0,
    hasMoreData: true,
    news: [] as IShortNews[] | IFeaturedNews[],
  },
  featuredNews: [] as IFeaturedNews[],
  newsProviders: [] as IProvider[],
  userSelectedProviders: [] as IProvider[],
  newsArticle: {} as INewsArticle,
  isFollowStatusLoading: false,
  newsProviderWallState: {
    pageNumber: 0,
    hasMoreData: true,
    articles: [] as INewsArticle[],
  },
  newsProviderWallLoading: false,
  newsProviderInfo: {} as IProvider,
  newsProviderInfoLoading: false,
};

const NewsReducer: Reducer<INewsState, NewsActions> = (
  state = initialState,
  action = {} as NewsActions,
) => {
  switch (action.type) {
    case NewsType.RESET_NEWS_STATE: {
      return {
        ...state,
        featuredNews: [] as IFeaturedNews[],
        allNews: {
          pageNumber: 0,
          hasMoreData: true,
          news: [],
        },
      };
    }
    case NewsType.RESET_NEWS_ARTICLE_STATE: {
      return {
        ...state,
        newsArticle: {} as INewsArticle,
      };
    }

    case NewsType.UPDATE_COMMENT_COUNT: {
      const { payload } = action;
      const { commentsCount } = state.newsArticle;
      return {
        ...state,
        newsArticle: {
          ...state.newsArticle,
          commentsCount:
            payload === UpdateCommentCountType.INC ? commentsCount + 1 : commentsCount - 1,
        },
      };
    }
    case NewsType.GET_ALL_NEWS_REQUEST: {
      return {
        ...state,
        isNewsLoading: true,
      };
    }
    case NewsType.GET_ALL_NEWS_SUCCESS: {
      return {
        ...state,
        isNewsLoading: false,
        allNews: {
          pageNumber: action.payload.pageNumber,
          hasMoreData: action.payload.hasMoreData,
          news: [...state.allNews.news, ...action.payload.news],
        },
      };
    }
    case NewsType.GET_ALL_NEWS_ERROR: {
      return {
        ...state,
        isNewsLoading: false,
      };
    }
    case NewsType.GET_FEATURED_NEWS_REQUEST: {
      return {
        ...state,
        isFeaturedNewsLoading: true,
      };
    }
    case NewsType.GET_FEATURED_NEWS_SUCCESS: {
      return {
        ...state,
        isFeaturedNewsLoading: false,
        featuredNews: action.payload,
      };
    }
    case NewsType.GET_FEATURED_NEWS_ERROR: {
      return {
        ...state,
        isFeaturedNewsLoading: false,
      };
    }
    case NewsType.GET_ALL_NEWS_PROVIDERS_REQUEST: {
      return {
        ...state,
        isGetProvidersLoading: true,
      };
    }
    case NewsType.GET_ALL_NEWS_PROVIDERS_SUCCESS: {
      return {
        ...state,
        isGetProvidersLoading: false,
        newsProviders: action.payload,
      };
    }
    case NewsType.GET_ALL_NEWS_PROVIDERS_ERROR: {
      return {
        ...state,
        isGetProvidersLoading: false,
      };
    }
    case NewsType.GET_USER_SELECTED_PROVIDERS_REQUEST: {
      return {
        ...state,
        isGetProvidersLoading: true,
      };
    }
    case NewsType.GET_USER_SELECTED_PROVIDERS_SUCCESS: {
      return {
        ...state,
        isGetProvidersLoading: false,
        userSelectedProviders: action.payload,
      };
    }
    case NewsType.GET_USER_SELECTED_PROVIDERS_ERROR: {
      return {
        ...state,
        isGetProvidersLoading: false,
      };
    }
    case NewsType.UPDATE_USER_SELECTED_PROVIDERS_REQUEST: {
      return {
        ...state,
        isUpdateProvidersLoading: true,
      };
    }
    case NewsType.UPDATE_USER_SELECTED_PROVIDERS_SUCCESS: {
      return {
        ...state,
        isUpdateProvidersLoading: false,
        userSelectedProviders: action.payload,
      };
    }
    case NewsType.UPDATE_USER_SELECTED_PROVIDERS_ERROR: {
      return {
        ...state,
        isUpdateProvidersLoading: false,
      };
    }
    case NewsType.GET_NEWS_ARTICLE_DETAILS_REQUEST: {
      return {
        ...state,
        isNewsLoading: true,
      };
    }
    case NewsType.GET_NEWS_ARTICLE_DETAILS_SUCCESS: {
      return {
        ...state,
        isNewsLoading: false,
        newsArticle: action.payload,
      };
    }
    case NewsType.GET_NEWS_ARTICLE_DETAILS_ERROR: {
      return {
        ...state,
        isNewsLoading: false,
      };
    }

    case NewsType.LIKE_DISLIKE_POST_REQUEST:
    case NewsType.LIKE_DISLIKE_POST_ERROR: {
      return {
        ...state,
      };
    }
    case NewsType.LIKE_DISLIKE_POST_SUCCESS: {
      const likeStatus = !state.newsArticle.isLiked;
      const likesCount = likeStatus
        ? state.newsArticle.likesCount + 1
        : state.newsArticle.likesCount - 1;

      return {
        ...state,
        newsArticle: { ...state.newsArticle, isLiked: likeStatus, likesCount },
      };
    }

    case NewsType.FEATURED_LIKE_DISLIKE_POST_SUCCESS: {
      const selectedIndex = state.featuredNews.findIndex(
        (e: IFeaturedNews) => e.documentID === action.payload,
      );
      const featuredNews = cloneDeep(state.featuredNews);
      if (selectedIndex !== -1) {
        const likeStatus = !featuredNews[selectedIndex].isLiked;
        featuredNews[selectedIndex].isLiked = likeStatus;
        featuredNews[selectedIndex].likesCount = likeStatus
          ? featuredNews[selectedIndex].likesCount + 1
          : featuredNews[selectedIndex].likesCount - 1;
      }
      return {
        ...state,
        featuredNews,
      };
    }

    case NewsType.ALL_NEWS_LIKE_DISLIKE_POST_SUCCESS: {
      const selectedIndex = state.allNews.news.findIndex(e => e.documentID === action.payload);
      const news = cloneDeep(state.allNews.news);
      if (selectedIndex !== -1) {
        const likeStatus = !news[selectedIndex].isLiked;
        news[selectedIndex].isLiked = likeStatus;
        news[selectedIndex].likesCount = likeStatus
          ? news[selectedIndex].likesCount + 1
          : news[selectedIndex].likesCount - 1;
      }
      return {
        ...state,
        allNews: {
          ...state.allNews,
          news,
        },
      };
    }

    case NewsType.GET_USER_PROVIDER_RELATIONSHIP_STATUS_REQUEST: {
      return {
        ...state,
        isFollowStatusLoading: true,
      };
    }
    case NewsType.GET_USER_PROVIDER_RELATIONSHIP_STATUS_SUCCESS: {
      return {
        ...state,
        isFollowStatusLoading: false,
        userProviderFollowStatus: action.payload,
      };
    }
    case NewsType.GET_USER_PROVIDER_RELATIONSHIP_STATUS_ERROR: {
      return {
        ...state,
        isFollowStatusLoading: false,
      };
    }
    case NewsType.UPDATE_USER_PROVIDER_RELATIONSHIP_STATUS_REQUEST: {
      return {
        ...state,
        isFollowStatusLoading: true,
      };
    }
    case NewsType.UPDATE_USER_PROVIDER_RELATIONSHIP_STATUS_SUCCESS: {
      const providerWallArticles = cloneDeep(state.newsProviderWallState.articles);
      if (providerWallArticles.length > 0) {
        providerWallArticles[0].provider.isFollowed = action.payload;
      }
      const newsProviderInfo = { ...state.newsProviderInfo };
      if (Object.keys(newsProviderInfo).length > 0) {
        newsProviderInfo.isFollowed = action.payload;
      }
      return {
        ...state,
        isFollowStatusLoading: false,
        newsArticle: {
          ...state.newsArticle,
          provider: {
            ...state.newsArticle?.provider,
            isFollowed: action.payload,
          },
        },
        newsProviderWallState: {
          ...state.newsProviderWallState,
          articles: providerWallArticles,
        },
        newsProviderInfo,
      };
    }
    case NewsType.UPDATE_USER_PROVIDER_RELATIONSHIP_STATUS_ERROR: {
      return {
        ...state,
        isFollowStatusLoading: false,
      };
    }
    case NewsType.GET_NEWS_ARTICLES_BY_PROVIDER_ID_REQUEST: {
      return {
        ...state,
        newsProviderWallLoading: true,
      };
    }
    case NewsType.GET_NEWS_ARTICLES_BY_PROVIDER_ID_SUCCESS: {
      return {
        ...state,
        newsProviderWallLoading: false,
        newsProviderWallState: {
          pageNumber: action.payload.pageNumber,
          hasMoreData: action.payload.hasMoreData,
          articles: [...state.newsProviderWallState.articles, ...action.payload.articles],
        },
      };
    }
    case NewsType.GET_NEWS_ARTICLES_BY_PROVIDER_ID_ERROR: {
      return {
        ...state,
        newsProviderWallLoading: false,
      };
    }
    case NewsType.RESET_NEWS_PROVIDER_WALL_STATE: {
      return {
        ...state,
        newsProviderWallState: {
          pageNumber: 0,
          hasMoreData: true,
          articles: [] as INewsArticle[],
        },
      };
    }
    case NewsType.GET_NEWS_PROVIDER_INFO_REQUEST: {
      return {
        ...state,
        newsProviderInfoLoading: true,
      };
    }
    case NewsType.GET_NEWS_PROVIDER_INFO_SUCCESS: {
      return {
        ...state,
        newsProviderInfoLoading: false,
        newsProviderInfo: action.payload,
      };
    }
    case NewsType.GET_NEWS_PROVIDER_INFO_ERROR: {
      return {
        ...state,
        newsProviderInfoLoading: false,
      };
    }
    case NewsType.NEWS_PROVIDER_LIKE_DISLIKE_POST_SUCCESS: {
      const selectedIndex = state.newsProviderWallState.articles.findIndex(
        (e: INewsArticle) => e.documentID === action.payload,
      );
      const articles = cloneDeep(state.newsProviderWallState.articles);
      if (selectedIndex !== -1) {
        const likeStatus = !articles[selectedIndex].isLiked;
        articles[selectedIndex].isLiked = likeStatus;
        articles[selectedIndex].likesCount = likeStatus
          ? articles[selectedIndex].likesCount + 1
          : articles[selectedIndex].likesCount - 1;
      }

      return {
        ...state,
        newsProviderWallState: {
          ...state.newsProviderWallState,
          articles,
        },
      };
    }
    default:
      return state;
  }
};

export default NewsReducer;
