import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { DefaultBusinessLogo, HeaderBgImg } from 'helpers/images';

interface IBusinessBannerProps {
  backgroundImg: string;
  logoImg: string;
  logoImgAlt?: string;
  setHeightAuto?: boolean;
}

export default function BusinessBanner(props: IBusinessBannerProps) {
  const { backgroundImg, logoImg, logoImgAlt = 'logo', setHeightAuto = false } = props;

  return (
    <>
      <Box>
        <img
          alt="background"
          loading="lazy"
          src={backgroundImg || HeaderBgImg}
          style={{ height: setHeightAuto ? 'auto' : '25vw' }}
          width="100%"
        />
        <Avatar
          style={{
            marginTop: '-50px',
            marginLeft: '5%',
            width: '100px',
            height: '100px',
            borderRadius: '10px',
          }}
          variant="square"
        >
          <img
            alt={logoImgAlt}
            loading="lazy"
            src={logoImg || DefaultBusinessLogo}
            style={{
              objectFit: 'contain',
              width: '100%',
              height: '100%',
              textAlign: 'center',
              color: 'transparent',
              textIndent: '10000px',
            }}
          />
        </Avatar>
      </Box>
    </>
  );
}
