enum AdsType {
  FETCH_COMMON_ADS_REQUEST = 'FETCH_COMMON_ADS_REQUEST',
  FETCH_COMMON_ADS_SUCCESS = 'FETCH_COMMON_ADS_SUCCESS',
  FETCH_COMMON_ADS_ERROR = 'FETCH_COMMON_ADS_ERROR',

  FETCH_POSITION_ADS_REQUEST = 'FETCH_POSITION_ADS_REQUEST',
  FETCH_POSITION_ADS_SUCCESS = 'FETCH_POSITION_ADS_SUCCESS',
  FETCH_POSITION_ADS_ERROR = 'FETCH_POSITION_sADS_ERROR',

  REMOVE_AD_REQUEST = 'REMOVE_AD_REQUEST',
  REMOVE_AD_SUCCESS = 'REMOVE_AD_SUCCESS',
  REMOVE_AD_ERROR = 'REMOVE_AD_ERROR',

  RESET_ADS_STATE = 'RESET_ADS_STATE',
}

export default AdsType;
