import { CaseReducer } from '@reduxjs/toolkit';

export const handleLoading: CaseReducer<any> = state => {
  state.loading = true;
};

export const handleFulfilled: CaseReducer<any> = state => {
  state.loading = false;
};

export const handleRejected: CaseReducer<any> = state => {
  state.loading = false;
};
