import Box from '@mui/material/Box';
import { memo } from 'react';
import theme from 'theme';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box boxSizing="border-box" sx={{ marginTop: theme.spacing(4) }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default TabPanel;
