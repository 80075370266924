import { Dispatch } from 'redux';
import {
  AlertActions,
  AlertType,
  FollowingTimelineActions,
  HomeTimelineActions,
  IAllPodcastsState,
  IPodcastEpisode,
  PodcastActions,
  SearchActions,
  TrendingTimelineActions,
  UpdateCommentCountType,
} from 'store';
import FollowingTimelineType from 'store/followingTimeline/FollowingTimelineTypes';
import HomeTimelineType from 'store/homeTimeline/HomeTimelineTypes';
import SearchType from 'store/search/SearchTypes';
import TrendingType from 'store/trending/TrendingTypes';

import client from '../../Api';
import { podcastUrl } from '../../helpers/urls';
import { IResponseData } from '../common/CommonInterface';
import PodcastType from './PodcastTypes';

export const resetPodcastState = () => {
  return async (dispatch: Dispatch<PodcastActions>) => {
    dispatch({ type: PodcastType.RESET_PODCAST_STATE });
  };
};

export const updateEpisodeCommentCount = (type: UpdateCommentCountType) => {
  return async (dispatch: Dispatch<PodcastActions>) => {
    dispatch({ type: PodcastType.UPDATE_COMMENT_COUNT, payload: type });
  };
};

export const getAllPodcastEpisodes = (offset: number, limit: number) => {
  return async (dispatch: Dispatch<PodcastActions>) => {
    try {
      dispatch({ type: PodcastType.GET_ALL_PODCAST_EPISODES_REQUEST });
      const response: IResponseData = await client.get(
        `${podcastUrl}podcasts/episodes?offset=${offset}&limit=${limit}&includeFeatured=false`,
      );

      const payload: IAllPodcastsState = {
        podcasts: response.data,
        currentCount: response.data.length,
      };

      dispatch({
        type: PodcastType.GET_ALL_PODCAST_EPISODES_SUCCESS,
        payload,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: PodcastType.GET_ALL_PODCAST_EPISODES_ERROR });
      return error;
    }
  };
};

export const getFeaturedPodcastEpisodes = () => {
  return async (dispatch: Dispatch<PodcastActions>) => {
    try {
      dispatch({ type: PodcastType.GET_FEATURED_PODCAST_EPISODES_REQUEST });
      const response: IResponseData = await client.get(`${podcastUrl}featured`);
      dispatch({
        type: PodcastType.GET_FEATURED_PODCAST_EPISODES_SUCCESS,
        payload: (response?.data || []) as IPodcastEpisode[],
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: PodcastType.GET_FEATURED_PODCAST_EPISODES_ERROR });
      return error;
    }
  };
};

export const getEpisodeDetailsById = (episodeId: string) => {
  return async (dispatch: Dispatch<PodcastActions>) => {
    try {
      dispatch({ type: PodcastType.GET_EPISODE_DETAILS_REQUEST });
      const response: IResponseData = await client.get(`${podcastUrl}episode/${episodeId}`);
      dispatch({
        type: PodcastType.GET_EPISODE_DETAILS_SUCCESS,
        payload:
          response.data && response.data?.length > 0 ? response.data?.[0] : ({} as IPodcastEpisode),
      });
      return response;
    } catch (error) {
      dispatch({ type: PodcastType.GET_EPISODE_DETAILS_ERROR });
      return error;
    }
  };
};

export const likeDislikeEpisode = (
  episodeId: string,
  likeEpisode: boolean,
  component = '',
  type = '',
) => {
  return async (
    dispatch: Dispatch<
      | PodcastActions
      | AlertActions
      | FollowingTimelineActions
      | SearchActions
      | HomeTimelineActions
      | TrendingTimelineActions
    >,
  ) => {
    try {
      dispatch({ type: PodcastType.LIKE_DISLIKE_EPISODE_REQUEST });
      let response: IResponseData;
      const likeDislikeUrl = `${podcastUrl}episode/${episodeId}/like`;
      if (!likeEpisode) {
        response = await client.post(likeDislikeUrl);
      } else {
        response = await client.delete(likeDislikeUrl);
      }
      if (component === 'podcasts' && type === 'episode') {
        dispatch({ type: PodcastType.LIKE_DISLIKE_EPISODE_SUCCESS });
      } else if (component === 'podcasts' && type === 'featured') {
        dispatch({
          type: PodcastType.FEATURED_LIKE_DISLIKE_EPISODE_SUCCESS,
          payload: episodeId,
        });
      } else if (component === 'podcasts' && type === 'all-podcast') {
        dispatch({
          type: PodcastType.ALL_PODCAST_LIKE_DISLIKE_EPISODE_SUCCESS,
          payload: episodeId,
        });
      } else if (component === 'following') {
        dispatch({
          type: FollowingTimelineType.FOLLOWING_LIKE_DISLIKE_POST_SUCCESS,
          payload: episodeId,
        });
      } else if (component === 'search') {
        dispatch({
          type: SearchType.SEARCH_LIKE_DISLIKE_POST_SUCCESS,
          payload: episodeId,
        });
      } else if (component === 'home' && type === 'featured') {
        dispatch({
          type: HomeTimelineType.FEATURED_LIKE_DISLIKE_POST_SUCCESS,
          payload: episodeId,
        });
      } else if (component === 'home' && type === 'all-podcast') {
        dispatch({
          type: HomeTimelineType.ALL_POST_LIKE_DISLIKE_POST_SUCCESS,
          payload: episodeId,
        });
      } else if (component === 'trending') {
        dispatch({
          type: TrendingType.TRENDING_LIKE_DISLIKE_POST_SUCCESS,
          payload: episodeId,
        });
      }
      return response;
    } catch (error: unknown) {
      dispatch({ type: PodcastType.LIKE_DISLIKE_EPISODE_ERROR });
      const e = error as IResponseData;
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: e?.message },
      });
      return error;
    }
  };
};

export const followUnFollowPodcastProvider = (podcastId: string, isFollowing: boolean) => {
  return async (dispatch: Dispatch<PodcastActions | AlertActions>) => {
    try {
      dispatch({
        type: PodcastType.UPDATE_EPISODE_PROVIDER_RELATIONSHIP_STATUS_REQUEST,
      });
      let response: IResponseData;
      const followUnFollowUrl = `${podcastUrl}podcasts/follow/${podcastId}`;
      if (isFollowing) {
        response = await client.delete(followUnFollowUrl);
      } else {
        response = await client.post(followUnFollowUrl);
      }
      dispatch({
        type: PodcastType.UPDATE_EPISODE_PROVIDER_RELATIONSHIP_STATUS_SUCCESS,
        payload: !response.error ? !isFollowing : false,
      });
      return response;
    } catch (error: unknown) {
      dispatch({
        type: PodcastType.UPDATE_EPISODE_PROVIDER_RELATIONSHIP_STATUS_ERROR,
      });
      const e = error as IResponseData;
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: e?.message },
      });
      return error;
    }
  };
};

export const resetPodcastEpisodeState = () => {
  return async (dispatch: Dispatch<PodcastActions>) => {
    dispatch({ type: PodcastType.RESET_PODCAST_EPISODE_STATE });
  };
};

export const getPodcastShowEpisodes = (podcastShowId: string, offset: number, limit = 10) => {
  return async (dispatch: Dispatch<PodcastActions>) => {
    try {
      dispatch({ type: PodcastType.GET_PODCAST_SHOW_EPISODES_REQUEST });
      const response: IResponseData = await client.get(
        `${podcastUrl}podcasts/${podcastShowId}/episodes?limit=${limit}&offset=${offset}`,
      );
      dispatch({
        type: PodcastType.GET_PODCAST_SHOW_EPISODES_SUCCESS,
        payload: !response.error
          ? { episodesList: response.data, initialLoad: offset === 0 }
          : {
              episodesList: [] as IPodcastEpisode[],
              initialLoad: false,
            },
      });
      return response;
    } catch (error) {
      dispatch({ type: PodcastType.GET_PODCAST_SHOW_EPISODES_ERROR });
      return error;
    }
  };
};

export const resetPodcastShowEpisodesList = () => {
  return (dispatch: Dispatch<PodcastActions>) => {
    dispatch({ type: PodcastType.RESET_PODCAST_SHOW_EPISODES_LIST });
  };
};

export const getPodcastDetails = (podcastId: string) => {
  return async (dispatch: Dispatch<PodcastActions>) => {
    try {
      dispatch({ type: PodcastType.FETCH_PODCAST_DETAILS_REQUEST });
      const response: IResponseData = await client.get(`${podcastUrl}podcasts/${podcastId}`);
      dispatch({
        type: PodcastType.FETCH_PODCAST_DETAILS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({ type: PodcastType.FETCH_PODCAST_DETAILS_ERROR });
      return error;
    }
  };
};

export const resetPodcastDetails = () => {
  return (dispatch: Dispatch<PodcastActions>) => {
    dispatch({ type: PodcastType.RESET_PODCAST_DETAILS_STATE });
  };
};
