import { Dispatch } from 'redux';

import { AlertActions, IAlertPayload } from './AlertInterface';
import { AlertType } from './AlertTypes';

export const alertSuccess = (payload: IAlertPayload) => {
  return (dispatch: Dispatch<AlertActions>) => {
    dispatch({
      type: AlertType.ALERT_SUCCESS,
      payload,
    });
  };
};

export const clearAlert = () => {
  return (dispatch: Dispatch<AlertActions>) => {
    dispatch({
      type: AlertType.CLEAR_ALERT,
    });
  };
};

export const alertError = (payload: IAlertPayload) => {
  return (dispatch: Dispatch<AlertActions>) => {
    dispatch({
      type: AlertType.ALERT_ERROR,
      payload,
    });
  };
};

export const alertWarning = (payload: IAlertPayload) => {
  return (dispatch: Dispatch<AlertActions>) => {
    dispatch({
      type: AlertType.ALERT_WARNING,
      payload,
    });
  };
};
export const alertInfo = (payload: IAlertPayload) => {
  return (dispatch: Dispatch<AlertActions>) => {
    dispatch({
      type: AlertType.ALERT_INFO,
      payload,
    });
  };
};
