import { Dispatch } from 'redux';
import { AlertActions, IAlertPayload } from 'store/alerts/AlertInterface';
import { AlertType } from 'store/alerts/AlertTypes';

import client from '../../Api';
import { timelineUrl } from '../../helpers/urls';
import { IResponseData } from '../common/CommonInterface';
import { TrendingTimelineActions } from './TrendingInterface';
import TrendingType from './TrendingTypes';

export const resetTrendingState = () => {
  return async (dispatch: Dispatch<TrendingTimelineActions>) => {
    dispatch({ type: TrendingType.RESET_TRENDING_STATE });
  };
};

export const getTrendingPosts = () => {
  return async (dispatch: Dispatch<TrendingTimelineActions | AlertActions>) => {
    try {
      dispatch({ type: TrendingType.GET_TRENDING_POSTS_REQUEST });
      const response: IResponseData = await client.get(
        `${timelineUrl}timeline/trending?limit=5&offset=0`,
      );
      dispatch({
        type: TrendingType.GET_TRENDING_POSTS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: TrendingType.GET_TRENDING_POSTS_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};
