import { InputAdornment, SvgIcon } from '@mui/material';
import valid from 'card-validator';
import { Amex, Diners, Discover, Jcb, Master, Visa } from 'helpers/images';
import useCard from 'hooks/useCard';
import React, { useState } from 'react';
import { handleAllowOnlyNumber } from 'utils/utils';

import CustomInputField, { ICustomInputFieldProps } from './CustomInputField';

interface ICardImageProps {
  cardType: string;
  width: string;
  height: string;
}

export function CardImage({ cardType, width, height }: ICardImageProps) {
  return (
    <SvgIcon
      inheritViewBox
      component={
        cardType === 'american-express'
          ? Amex
          : cardType === 'visa'
          ? Visa
          : cardType === 'diners-club'
          ? Diners
          : cardType === 'discover'
          ? Discover
          : cardType === 'mastercard'
          ? Master
          : Jcb
      }
      sx={{ height, width }}
    />
  );
}

interface ICardNumberInputProps extends ICustomInputFieldProps {
  name: string;
  setFieldValue: any;
  touched: boolean | undefined;
  errorMsg: string | undefined;
  handleChange: (e: string | React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent) => void;
}

function CardNumberInput({
  name,
  label,
  setFieldValue,
  touched,
  errorMsg,
  value,
  handleBlur,
  handleChange,
  ...props
}: ICardNumberInputProps) {
  const { FALLBACK_CARD, formatCardNumber } = useCard();
  const [cardType, setCardType] = useState('visa');

  const cardFormat = (text: string) => {
    const userInputCard = valid.number(text).card || FALLBACK_CARD;
    setCardType(userInputCard.type);
    const formatted = formatCardNumber(text, userInputCard);
    return formatted;
  };

  const handlePasteCardNumber = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    let pastedText = '';
    if (event.clipboardData && event.clipboardData.getData) {
      pastedText = event.clipboardData.getData('text/plain');
    }
    setFieldValue(name, cardFormat(pastedText));
  };

  return (
    <CustomInputField
      fullWidth
      endAdornment={
        <InputAdornment position="end">
          <CardImage cardType={cardType} height="45px" width="60px" />
        </InputAdornment>
      }
      error={!!(touched && errorMsg)}
      errormessage={touched ? errorMsg : undefined}
      id="cardNumber"
      inputProps={{ minLength: 13, maxLength: 25 }}
      label={label}
      name={name}
      type="text"
      value={value}
      onBlur={handleBlur}
      onChange={e => handleChange(cardFormat(e.target.value))}
      onKeyPress={handleAllowOnlyNumber}
      onPaste={handlePasteCardNumber}
      {...props}
    />
  );
}

export default CardNumberInput;
