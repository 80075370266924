import styled from '@emotion/styled';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { InputBaseProps } from '@mui/material/InputBase';
import Paper, { PaperProps } from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TYPE_LABEL_MAP } from 'constants/constants';
import { CloseIcon, DefaultBusinessLogo, SearchIcon } from 'helpers/images';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IPostType,
  ITimelinePost,
  resetBusinessWallState,
  resetNewsProviderWallState,
  resetPodcastDetails,
  resetPodcastShowEpisodesList,
  resetProfileWallState,
} from 'store';
import { useAppDispatch } from 'store/hooks';
import theme from 'theme';
import { getNewsProviderLogo } from 'utils/utils';

const SearchIconWrapper = styled('div')(() => ({
  marginRight: theme.spacing(2),
  height: '100%',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

interface ISearchInputProps extends InputBaseProps {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  posts: ITimelinePost[];
  loading: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function CustomPopperComponent(props: PopperProps) {
  return (
    <Popper
      {...props}
      placement="bottom"
      style={{ width: '500px' }}
      sx={{ paddingTop: theme.spacing(1) }}
    />
  );
}

interface IPaperProps extends PaperProps {
  handleRoute: () => void;
}
function CustomPaperComponent(props: IPaperProps) {
  const { children, handleRoute, ...prop } = props;

  return (
    <Paper {...prop} onMouseDown={event => event.preventDefault()}>
      <Stack spacing={theme.spacing(0.5)} width="100%">
        <Stack
          alignItems="center"
          boxSizing="border-box"
          direction="row"
          justifyContent="space-between"
          padding={theme.spacing(2, 2, 1)}
          width="100%"
        >
          <Typography variant="title2">Results</Typography>
          <Typography
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              textUnderlineOffset: '1.5px',
            }}
            variant="title2"
            onClick={handleRoute}
          >
            View All
          </Typography>
        </Stack>
        {children}
      </Stack>
    </Paper>
  );
}

function SearchInputTextField(props: ISearchInputProps) {
  const { value, setValue, posts, loading, open, setOpen } = props;
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState<ITimelinePost>(posts[0]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();

  const getProviderRouteText = (providerType: IPostType, wallRoute = false) => {
    let providerRoute: string;
    switch (providerType) {
      case 'news': {
        if (wallRoute) {
          dispatch(resetNewsProviderWallState());
          providerRoute = 'news-provider-wall';
        } else {
          providerRoute = 'article';
        }
        break;
      }
      case 'podcasts': {
        if (wallRoute) {
          dispatch(resetPodcastDetails());
          dispatch(resetPodcastShowEpisodesList());
          providerRoute = 'podcast-show';
        } else {
          providerRoute = 'podcast';
        }
        break;
      }
      case 'business': {
        if (wallRoute) {
          dispatch(resetBusinessWallState());
          providerRoute = 'business-wall';
        } else {
          providerRoute = 'business';
        }
        break;
      }
      case 'business-only': {
        providerRoute = 'business-wall';
        break;
      }

      case 'paid-member': {
        providerRoute = 'profile-wall';
        break;
      }
      case 'town-crier': {
        providerRoute = 'town-crier-wall';
        break;
      }
      case 'paid-member-post': {
        if (wallRoute) {
          dispatch(resetProfileWallState());
          providerRoute = 'profile-wall';
        } else {
          providerRoute = 'post';
        }
        break;
      }
      case 'town-crier-post': {
        if (wallRoute) {
          dispatch(resetProfileWallState());
          providerRoute = 'town-crier-wall';
        } else {
          providerRoute = 'town-crier';
        }
        break;
      }
      default:
        providerRoute = '';
    }
    return providerRoute;
  };

  return (
    <Autocomplete
      disableClearable
      autoComplete={false}
      filterOptions={x => x}
      forcePopupIcon={false}
      getOptionLabel={option => option.title}
      id="search-input"
      inputValue={value}
      isOptionEqualToValue={(option, value) => option.documentId === value.documentId}
      noOptionsText={
        <Typography align="center" variant="h6" width="100%">
          No results found
        </Typography>
      }
      open={open}
      options={posts}
      // eslint-disable-next-line react/no-unstable-nested-components
      PaperComponent={({ children, ...props }) => (
        <CustomPaperComponent
          {...props}
          handleRoute={() => {
            if (value.length > 0 && Object.keys(posts).length > 0) {
              navigate({
                pathname: 'search',
                search: new URLSearchParams({ q: value }).toString(),
              });
            }
          }}
        >
          {children}
        </CustomPaperComponent>
      )}
      PopperComponent={CustomPopperComponent}
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
            onKeyDown: e => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            },
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            ...params.InputProps,
            type: 'search',
            startAdornment: (
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
            ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  value && (
                    <IconButton onClick={() => setValue('')}>
                      <CloseIcon color="inherit" fontSize="small" />
                    </IconButton>
                  )
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          name="search-input"
          placeholder="Search for topics or businesses"
          sx={{
            '& .MuiInputBase-root': {
              width: '100%',
              height: '48px',
              padding: 0,
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(2),
              '& .MuiInputBase-input': {
                padding: 0,
              },
              '& fieldset': {
                border: 'unset',
              },
            },
            height: '100%',
            width: '100%',
            border: `0.25px solid ${theme.palette.text.secondary}`,
            borderRadius: theme.spacing(3.275),
            padding: 0,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '22px',
            position: 'relative',
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box
          key={option.documentId + option.postId}
          boxSizing="border-box"
          component="li"
          width="100%"
          {...props}
        >
          <Grid container justifyContent="space-between" spacing={theme.spacing(2)}>
            <Grid item xs={2}>
              <Box
                alt="image"
                component="img"
                height={24}
                loading="lazy"
                minHeight="100%"
                overflow="hidden"
                src={
                  option.type === 'news'
                    ? getNewsProviderLogo(option.postProvider)
                    : option.type === 'podcasts'
                    ? option.postProviderLogo
                    : option.postProviderLogo || DefaultBusinessLogo
                }
                sx={{
                  objectFit: 'contain',
                  objectPosition: '50% 50%',
                  cursor: 'pointer',
                }}
                width="100%"
                onClick={() => {
                  setOpen(false);
                  navigate(`/${getProviderRouteText(option?.type, true)}/${option.postProviderId}`);
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography
                className="text-ellipsis text-clamp-2 text-ul-offset_4_hover"
                sx={{
                  ...(isMobile && {
                    height: { midmd: theme.spacing(4.5), xs: 'auto' },
                  }),
                }}
                variant={isMobile ? 'body2' : 'body1Bold'}
                onClick={() => {
                  setOpen(false);
                  navigate(`/${getProviderRouteText(option?.type, true)}/${option.postProviderId}`);
                }}
              >
                {option.postProvider}
              </Typography>

              <Typography
                className="text-ellipsis text-clamp-2 text-ul-offset_4_hover"
                height={theme.spacing(5)}
                variant="subtitle1"
                onClick={() => {
                  setOpen(false);
                  navigate(`/${getProviderRouteText(option?.type)}/${option.postId}`);
                }}
              >
                {[
                  'business',
                  'business-only',
                  'paid-member',
                  'paid-member-post',
                  'town-crier',
                  'town-crier-post',
                ].includes(option.type)
                  ? option.description
                  : option.title}
              </Typography>
              <Typography
                sx={{
                  ...(isMobile && {
                    height: { midmd: theme.spacing(4.5), xs: 'auto' },
                  }),
                }}
                variant={isMobile ? 'body2' : 'body1Bold'}
              >
                {TYPE_LABEL_MAP[option.type] || option.type.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      sx={{
        width: '100%',
        height: '100%',
      }}
      value={selectedValue}
      onChange={(event: React.SyntheticEvent<Element, Event>, newValue: ITimelinePost | null) => {
        setSelectedValue(newValue as ITimelinePost);
      }}
      onInputChange={(
        event: React.SyntheticEvent<Element, Event>,
        newInputValue: string,
        reason,
      ) => {
        if (reason === 'input') setValue(newInputValue);
      }}
    />
  );
}

export default SearchInputTextField;
