import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SocialLinks from 'components/business/SocialLinks';
import { useSetupSocialLinksForm } from 'components/business/useBusinessForm';
import { IResponseData, updateBusinessData } from 'store';
import {
  BusinessComponentType,
  ISetupBusinessSocialLinksState,
  ISocialLinksState,
} from 'store/business/BusinessInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { setupBusinessSocialLinksSchema } from 'utils/validationSchema';

interface IBusinessSocialLinks {
  handleNext: () => void;
  handlePrev: () => void;
}

const { SETUP } = BusinessComponentType;

function BusinessSocialLinks({ handleNext, handlePrev }: IBusinessSocialLinks) {
  const dispatch = useAppDispatch();
  const { businessDetails, isUpdateBusinessLoading } = useAppSelector(s => s.business);

  const getSocialUrlByType = (type: string): string => {
    if (businessDetails?.socialLinks?.length > 0) {
      const data = businessDetails?.socialLinks?.find((social: any) => social.socialType === type);
      return data?.link ?? '';
    }
    return '';
  };

  const socialLinksInitialState: ISocialLinksState = {
    facebookUrl: getSocialUrlByType('facebook'),
    instagramUrl: getSocialUrlByType('instagram'),
    linkedinUrl: getSocialUrlByType('linkedin'),
    twitterUrl: getSocialUrlByType('twitter'),
  };

  const handleUpdateSocials = async () => {
    const socialLinks = [
      {
        socialType: 'facebook',
        link: values.socialLinks.facebookUrl,
      },
      {
        socialType: 'instagram',
        link: values.socialLinks.instagramUrl,
      },
      {
        socialType: 'linkedin',
        link: values.socialLinks.linkedinUrl,
      },
      {
        socialType: 'twitter',
        link: values.socialLinks.twitterUrl,
      },
    ];
    const socials = socialLinks.filter(s => s.link !== '');
    const data = { socialLinks: socials };
    const response = (await dispatch(
      updateBusinessData(data, businessDetails?.documentId),
    )) as IResponseData;
    if (response && typeof response.error !== 'undefined' && !response.error) {
      handleNext();
    }
  };

  const handleSocials = () => {
    if (dirty) {
      handleUpdateSocials();
    } else {
      handleNext();
    }
  };

  const setupSocialLinksInitialState: ISetupBusinessSocialLinksState = {
    socialLinks: socialLinksInitialState,
  };

  const formik = useSetupSocialLinksForm(
    handleSocials,
    setupSocialLinksInitialState,
    setupBusinessSocialLinksSchema,
  );

  const { touched, values, errors, handleChange, handleBlur, handleSubmit, isValid, dirty } =
    formik;

  return (
    <Box noValidate component="form" onSubmit={handleSubmit}>
      <SocialLinks
        componentType={SETUP}
        errors={errors.socialLinks}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched.socialLinks}
        values={values.socialLinks}
      />
      <Stack direction="row" marginTop={theme.spacing(4)} spacing={theme.spacing(3)} width="100%">
        <Button fullWidth size="large" variant="outlined" onClick={handlePrev}>
          Back
        </Button>
        <LoadingButton
          fullWidth
          disabled={!isValid}
          loading={isUpdateBusinessLoading}
          size="large"
          type="submit"
          variant="contained"
        >
          Next
        </LoadingButton>
      </Stack>
      <Typography
        align="center"
        color="secondary.main"
        marginTop={theme.spacing(3)}
        sx={{
          textUnderlineOffset: '3px',
          textDecorationThickness: '1.5px !important',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        variant="h6"
        onClick={handleNext}
      >
        Skip this step
      </Typography>
    </Box>
  );
}

export default BusinessSocialLinks;
