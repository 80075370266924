/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';
import { isDeletedBusiness } from 'utils/utils';

function DeactivatedBusinessInfo() {
  const { businessDetails } = useAppSelector(s => s.business);

  const isDeleted = isDeletedBusiness(businessDetails);

  return (
    <HomeWrapper maxWidth="midmd">
      <Box border={`0.25px solid ${theme.palette.text.secondary}`} borderRadius="4px" width="100%">
        <Stack
          alignItems="center"
          padding={{ xs: theme.spacing(2), sm: theme.spacing(3) }}
          spacing={theme.spacing(0.5)}
          width="100%"
        >
          <Typography component="h4" variant="h4">
            {`Your business has been ${!isDeleted ? 'deleted' : 'deactivated'}.`}
          </Typography>
        </Stack>
      </Box>
    </HomeWrapper>
  );
}

export default DeactivatedBusinessInfo;
