import { FormikHelpers, useFormik } from 'formik';
import { IEmailUpdateState, IUserNameUpdateState } from 'store';

const useUserNameForm = (
  onSubmit: (
    values: IUserNameUpdateState,
    formikHelpers: FormikHelpers<IUserNameUpdateState>,
  ) => void | Promise<unknown>,
  initialValues: IUserNameUpdateState,
  validationSchema: any,
) => {
  return useFormik<IUserNameUpdateState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

const useEmailUpdateForm = (
  onSubmit: (
    values: IEmailUpdateState,
    formikHelpers: FormikHelpers<IEmailUpdateState>,
  ) => void | Promise<unknown>,
  initialValues: IEmailUpdateState,
  validationSchema: any,
) => {
  return useFormik<IEmailUpdateState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export { useEmailUpdateForm, useUserNameForm };
