import { IBusiness, IBusinessDetails, IPreferencePodcast, IProvider } from 'store';

import VettingDashboardType from './VettingDashboardTypes';

interface IResetVettingDashboardState {
  type: VettingDashboardType.RESET_DASHBOARD_STATE;
}

interface IGetUnApprovedBusinessesRequest {
  type: VettingDashboardType.GET_UNAPPROVED_BUSINESSES_REQUEST;
}

interface IGetUnApprovedBusinessesSuccess {
  type: VettingDashboardType.GET_UNAPPROVED_BUSINESSES_SUCCESS;
  payload: IUnApprovedBusinessesState;
}

interface IGetUnApprovedBusinessesError {
  type: VettingDashboardType.GET_UNAPPROVED_BUSINESSES_ERROR;
}

interface IGetBusinessDetailsRequest {
  type: VettingDashboardType.GET_BUSINESS_DETAILS_REQUEST;
}

interface IGetBusinessDetailsSuccess {
  type: VettingDashboardType.GET_BUSINESS_DETAILS_SUCCESS;
  payload: IBusinessDetails;
}

interface IGetBusinessDetailsError {
  type: VettingDashboardType.GET_BUSINESS_DETAILS_ERROR;
}

interface IApproveBusinessRequest {
  type: VettingDashboardType.APPROVE_BUSINESS_REQUEST;
}

interface IApproveBusinessSuccess {
  type: VettingDashboardType.APPROVE_BUSINESS_SUCCESS;
  payload: string;
}

interface IApproveBusinessError {
  type: VettingDashboardType.APPROVE_BUSINESS_ERROR;
}

interface IMembersRequest {
  type: VettingDashboardType.GET_MEMBERS_REQUEST;
}

interface IMembersSuccess {
  type: VettingDashboardType.GET_MEMBERS_SUCCESS;
  payload: IMembersState;
}

interface IMembersError {
  type: VettingDashboardType.GET_MEMBERS_ERROR;
}

interface IResetMembersState {
  type: VettingDashboardType.RESET_MEMBERS_STATE;
}

interface ISetTownCrierRequest {
  type: VettingDashboardType.SET_TOWN_CRIER_ADMIN_REQUEST;
}
interface ISetTownCrierSuccess {
  type: VettingDashboardType.SET_TOWN_CRIER_ADMIN_SUCCESS;
}

interface ISetTownCrierError {
  type: VettingDashboardType.SET_TOWN_CRIER_ADMIN_ERROR;
}

interface IDeleteAccountRequest {
  type: VettingDashboardType.DELETE_USER_ADMIN_REQUEST;
}

interface IDeleteAccountSuccess {
  type: VettingDashboardType.DELETE_USER_ADMIN_SUCCESS;
  payload: string;
}

interface IDeleteAccountError {
  type: VettingDashboardType.DELETE_USER_ADMIN_ERROR;
}

interface IDenyBusinessRequest {
  type: VettingDashboardType.DENY_BUSINESS_REQUEST;
}

interface IDenyBusinessSuccess {
  type: VettingDashboardType.DENY_BUSINESS_SUCCESS;
  payload: string;
}

interface IDenyBusinessError {
  type: VettingDashboardType.DENY_BUSINESS_ERROR;
}

interface ISendEmailToBusinessRequest {
  type: VettingDashboardType.SEND_EMAIL_TO_BUSINESS_REQUEST;
}

interface ISendEmailToBusinessSuccess {
  type: VettingDashboardType.SEND_EMAIL_TO_BUSINESS_SUCCESS;
}

interface ISendEmailToBusinessError {
  type: VettingDashboardType.SEND_EMAIL_TO_BUSINESS_ERROR;
}

interface IResetVettingDashboardBusinessDetailsState {
  type: VettingDashboardType.RESET_BUSINESS_DETAILS_STATE;
}

interface IListAffiliatesRequest {
  type: VettingDashboardType.LIST_AFFILIATES_REQUEST;
}

interface IListAffiliatesSuccess {
  type: VettingDashboardType.LIST_AFFILIATES_SUCCESS;
  payload: IListAffiliatesState;
}

interface IListAffiliatesError {
  type: VettingDashboardType.LIST_AFFILIATES_ERROR;
}

interface ISetDateRange {
  type: VettingDashboardType.SET_DATE_RANGE;
  payload: IDateRangePayload;
}

interface IResetAffiliateListState {
  type: VettingDashboardType.RESET_AFFLIATES_LIST_STATE;
}

interface IGetInfluencersRequest {
  type: VettingDashboardType.GET_INFLUENCERS_REQUEST;
}

interface IGetInfluencersSuccess {
  type: VettingDashboardType.GET_INFLUENCERS_SUCCESS;
  payload: IInfluencer[];
}

interface IGetInfluencersError {
  type: VettingDashboardType.GET_INFLUENCERS_ERROR;
}

interface IAffiliatesPayoutRequest {
  type: VettingDashboardType.AFFILIATES_PAYOUT_REQUEST;
}

interface IAffiliatesPayoutSuccess {
  type: VettingDashboardType.AFFILIATES_PAYOUT_SUCCESS;
  payload: IListAffiliatesPayoutPayload;
}

interface IAffiliatesPayoutError {
  type: VettingDashboardType.AFFILIATES_PAYOUT_ERROR;
}

interface IResetAffiliatesPayoutState {
  type: VettingDashboardType.RESET_AFFILIATES_PAYOUT_STATE;
}

interface ICreatePayoutRequest {
  type: VettingDashboardType.CREATE_PAYOUT_REQUEST;
}
interface ICreateBusinessAdminRequest {
  type: VettingDashboardType.CREATE_BUSINESS_ADMIN_REQUEST;
}

interface ICreateBusinessAdminSuccess {
  type: VettingDashboardType.CREATE_BUSINESS_ADMIN_SUCCESS;
}

interface ICreateBusinessAdminError {
  type: VettingDashboardType.CREATE_BUSINESS_ADMIN_ERROR;
}

interface IPodcastEditRequest {
  type: VettingDashboardType.EDIT_PODCAST_REQUEST;
}

interface IPodcastEditSuccess {
  type: VettingDashboardType.EDIT_PODCAST_SUCCESS;
  payload: IPreferencePodcast;
}

interface IPodcastEditError {
  type: VettingDashboardType.EDIT_PODCAST_ERROR;
}

interface IDeletePodcastRequest {
  type: VettingDashboardType.DELETE_PODCAST_REQUEST;
}

interface IDeletePodcastSuccess {
  type: VettingDashboardType.DELETE_PODCAST_SUCCESS;
  payload: string;
}

interface IDeletePodcastError {
  type: VettingDashboardType.DELETE_PODCAST_ERROR;
}

interface IDeleteBusinessAdminRequest {
  type: VettingDashboardType.DELETE_BUSINESS_ADMIN_REQUEST;
}

interface IDeleteBusinessAdminSuccess {
  type: VettingDashboardType.DELETE_BUSINESS_ADMIN_SUCCESS;
  payload: string;
}

interface IDeleteBusinessAdminError {
  type: VettingDashboardType.DELETE_BUSINESS_ADMIN_ERROR;
}

interface ICreatePayoutSuccess {
  type: VettingDashboardType.CREATE_PAYOUT_SUCCESS;
  payload: {
    index: number;
    documentId: string;
  };
}

interface ICreatePayoutError {
  type: VettingDashboardType.CREATE_PAYOUT_ERROR;
}

interface IGetApprovedBusinessesRequest {
  type: VettingDashboardType.GET_APPROVED_BUSINESSES_REQUEST;
}

interface IGetApprovedBusinessesSuccess {
  type: VettingDashboardType.GET_APPROVED_BUSINESSES_SUCCESS;
  payload: IApprovedBusinessesState;
}

interface IGetApprovedBusinessesError {
  type: VettingDashboardType.GET_APPROVED_BUSINESSES_ERROR;
}

interface IResetApprovedBusinessesState {
  type: VettingDashboardType.RESET_APPROVED_BUSINESSES_STATE;
}

interface IDeletePayoutRequest {
  type: VettingDashboardType.DELETE_PAYOUT_REQUEST;
}

interface IDeletePayoutSuccess {
  type: VettingDashboardType.DELETE_PAYOUT_SUCCESS;
  payload: number;
}

interface IDeletePayoutError {
  type: VettingDashboardType.DELETE_PAYOUT_ERROR;
}

interface ISetSelectedProviderType {
  type: VettingDashboardType.SET_SELECTED_PROVIDER_TYPE;
  payload: string;
}

interface IAddPodcastProviderRequest {
  type: VettingDashboardType.ADD_PODCAST_PROVIDER_REQUEST;
}

interface IAddPodcastProviderSuccess {
  type: VettingDashboardType.ADD_PODCAST_PROVIDER_SUCCESS;
  payload: IPreferencePodcast;
}

interface IAddPodcastProviderError {
  type: VettingDashboardType.ADD_PODCAST_PROVIDER_ERROR;
}

interface IGetAllBusinessesRequest {
  type: VettingDashboardType.GET_ALL_BUSINESSES_ADMIN_REQUEST;
}

interface IGetAllBusinessesSuccess {
  type: VettingDashboardType.GET_ALL_BUSINESSES_ADMIN_SUCCESS;
  payload: IBusiness[];
}

interface IGetAllBusinessesError {
  type: VettingDashboardType.GET_ALL_BUSINESSES_ADMIN_ERROR;
}

interface IGetAllNewsProvidersRequest {
  type: VettingDashboardType.GET_ALL_NEWS_PROVIDERS_ADMIN_REQUEST;
}

interface IGetAllNewsProvidersSuccess {
  type: VettingDashboardType.GET_ALL_NEWS_PROVIDERS_ADMIN_SUCCESS;
  payload: IProvider[];
}

interface IGetAllNewsProvidersError {
  type: VettingDashboardType.GET_ALL_NEWS_PROVIDERS_ADMIN_ERROR;
}

interface IGetAllPodcastsRequest {
  type: VettingDashboardType.GET_ALL_PODCASTS_ADMIN_REQUEST;
}

interface IGetAllPodcastsSuccess {
  type: VettingDashboardType.GET_ALL_PODCASTS_ADMIN_SUCCESS;
  payload: IPreferencePodcast[];
}

interface IGetAllPodcastsError {
  type: VettingDashboardType.GET_ALL_PODCASTS_ADMIN_ERROR;
}

interface IFeaturedPodcastsRequest {
  type: VettingDashboardType.SAVE_FEATURED_PODCAST_PROVIDERS_REQUEST;
}

interface IFeaturedPodcastsSuccess {
  type: VettingDashboardType.SAVE_FEATURED_PODCAST_PROVIDERS_SUCCESS;
  payload: string[];
}

interface IFeaturedPodcastsError {
  type: VettingDashboardType.SAVE_FEATURED_PODCAST_PROVIDERS_ERROR;
}

interface IFeaturedBusinessesRequest {
  type: VettingDashboardType.SAVE_FEATURED_BUSINESS_REQUEST;
}

interface IFeaturedBusinessesSuccess {
  type: VettingDashboardType.SAVE_FEATURED_BUSINESS_SUCCESS;
  payload: string[];
}

interface IFeaturedBusinessesError {
  type: VettingDashboardType.SAVE_FEATURED_BUSINESS_ERROR;
}

interface IFeaturedNewsProvidersRequest {
  type: VettingDashboardType.SAVE_FEATURED_NEWS_PROVIDERS_REQUEST;
}

interface IFeaturedNewsProvidersSuccess {
  type: VettingDashboardType.SAVE_FEATURED_NEWS_PROVIDERS_SUCCESS;
  payload: string[];
}

interface IFeaturedNewsProvidersError {
  type: VettingDashboardType.SAVE_FEATURED_NEWS_PROVIDERS_ERROR;
}

interface IGetAllSponsorsReportRequest {
  type: VettingDashboardType.GET_ADS_REPORT_REQUEST;
}

interface IGetAllSponsorsReportSuccess {
  type: VettingDashboardType.GET_ADS_REPORT_SUCCESS;
  payload: IAllSponsorsReportState;
}

interface IGetAllSponsorsReportError {
  type: VettingDashboardType.GET_ADS_REPORT_ERROR;
}

interface IResetAllSponsorsReportState {
  type: VettingDashboardType.RESET_ADS_REPORT_STATE;
}

interface ICreateAdsRequest {
  type: VettingDashboardType.CREATE_ADS_REQUEST;
}

interface ICreateAdsSuccess {
  type: VettingDashboardType.CREATE_ADS_SUCCESS;
  payload: any;
}

interface ICreateAdsError {
  type: VettingDashboardType.CREATE_ADS_ERROR;
}

interface IAddSponsorRequest {
  type: VettingDashboardType.ADD_SPONSOR_REQUEST;
}

interface IAddSponsorSuccess {
  type: VettingDashboardType.ADD_SPONSOR_SUCCESS;
  payload: ISponsor;
}

interface IAddSponsorError {
  type: VettingDashboardType.ADD_SPONSOR_ERROR;
}

interface IGetSponsorsListRequest {
  type: VettingDashboardType.GET_SPONSORS_LIST_REQUEST;
}

interface IGetSponsorsListSuccess {
  type: VettingDashboardType.GET_SPONSORS_LIST_SUCCESS;
  payload: ISponsorsListPayload;
}

interface IGetSponsorsListError {
  type: VettingDashboardType.GET_SPONSORS_LIST_ERROR;
}

interface IGetAdsBySponsorIdRequest {
  type: VettingDashboardType.GET_ADS_BY_SPONSORID_REQUEST;
}

interface IGetAdsBySponsorIdSuccess {
  type: VettingDashboardType.GET_ADS_BY_SPONSORID_SUCCESS;
  payload: ISponsorAd[];
}

interface IGetAdsBySponsorIdError {
  type: VettingDashboardType.GET_ADS_BY_SPONSORID_ERROR;
}
interface IResetSponsorAdsState {
  type: VettingDashboardType.RESET_SPONSOR_ADS_STATE;
}
interface IDeleteSponsorRequest {
  type: VettingDashboardType.DELETE_SPONSOR_REQUEST;
}

interface IDeleteSponsorSuccess {
  type: VettingDashboardType.DELETE_SPONSOR_SUCCESS;
  payload: string;
}

interface IDeleteSponsorError {
  type: VettingDashboardType.DELETE_SPONSOR_ERROR;
}
interface IEditSponsorRequest {
  type: VettingDashboardType.EDIT_SPONSOR_REQUEST;
}

interface IEditSponsorSuccess {
  type: VettingDashboardType.EDIT_SPONSOR_SUCCESS;
  payload: ISponsor;
}

interface IEditSponsorError {
  type: VettingDashboardType.EDIT_SPONSOR_ERROR;
}

interface IDeleteSponsorAdRequest {
  type: VettingDashboardType.DELETE_SPONSOR_AD_REQUEST;
}

interface IDeleteSponsorAdSuccess {
  type: VettingDashboardType.DELETE_SPONSOR_AD_SUCCESS;
}

interface IDeleteSponsorAdError {
  type: VettingDashboardType.DELETE_SPONSOR_AD_ERROR;
}
interface IEditSponsorAdRequest {
  type: VettingDashboardType.EDIT_SPONSOR_AD_REQUEST;
}

interface IEditSponsorAdSuccess {
  type: VettingDashboardType.EDIT_SPONSOR_AD_SUCCESS;
  payload: ISponsorAd;
}

interface IEditSponsorAdError {
  type: VettingDashboardType.EDIT_SPONSOR_AD_ERROR;
}

export type VettingDashboardActions =
  | IResetVettingDashboardState
  | IGetUnApprovedBusinessesRequest
  | IGetUnApprovedBusinessesSuccess
  | IGetUnApprovedBusinessesError
  | IGetBusinessDetailsRequest
  | IGetBusinessDetailsSuccess
  | IGetBusinessDetailsError
  | IApproveBusinessRequest
  | IApproveBusinessSuccess
  | IApproveBusinessError
  | IDenyBusinessRequest
  | IDenyBusinessSuccess
  | IDenyBusinessError
  | ISendEmailToBusinessRequest
  | ISendEmailToBusinessSuccess
  | ISendEmailToBusinessError
  | IListAffiliatesRequest
  | IListAffiliatesSuccess
  | IListAffiliatesError
  | ISetDateRange
  | IResetAffiliateListState
  | IResetVettingDashboardBusinessDetailsState
  | IGetInfluencersRequest
  | IGetInfluencersSuccess
  | IGetInfluencersError
  | IAffiliatesPayoutRequest
  | IAffiliatesPayoutSuccess
  | IAffiliatesPayoutError
  | IResetAffiliatesPayoutState
  | ICreatePayoutRequest
  | ICreatePayoutSuccess
  | ICreatePayoutError
  | IGetApprovedBusinessesRequest
  | IGetApprovedBusinessesSuccess
  | IGetApprovedBusinessesError
  | IResetApprovedBusinessesState
  | IDeletePayoutRequest
  | IDeletePayoutSuccess
  | IDeletePayoutError
  | ISetSelectedProviderType
  | IAddPodcastProviderRequest
  | IAddPodcastProviderSuccess
  | IAddPodcastProviderError
  | IGetAllBusinessesRequest
  | IGetAllBusinessesSuccess
  | IGetAllBusinessesError
  | IGetAllNewsProvidersRequest
  | IGetAllNewsProvidersSuccess
  | IGetAllNewsProvidersError
  | IGetAllPodcastsRequest
  | IGetAllPodcastsSuccess
  | IGetAllPodcastsError
  | IFeaturedPodcastsRequest
  | IFeaturedPodcastsSuccess
  | IFeaturedPodcastsError
  | IFeaturedBusinessesRequest
  | IFeaturedBusinessesSuccess
  | IFeaturedBusinessesError
  | IFeaturedNewsProvidersRequest
  | IFeaturedNewsProvidersSuccess
  | IFeaturedNewsProvidersError
  | IGetAllSponsorsReportRequest
  | IGetAllSponsorsReportSuccess
  | IGetAllSponsorsReportError
  | IResetAllSponsorsReportState
  | ICreateAdsRequest
  | ICreateAdsSuccess
  | ICreateAdsError
  | IAddSponsorRequest
  | IAddSponsorSuccess
  | IAddSponsorError
  | IGetSponsorsListRequest
  | IGetSponsorsListSuccess
  | IGetSponsorsListError
  | IGetAdsBySponsorIdRequest
  | IGetAdsBySponsorIdSuccess
  | IGetAdsBySponsorIdError
  | IResetSponsorAdsState
  | IDeleteSponsorRequest
  | IDeleteSponsorSuccess
  | IDeleteSponsorError
  | IEditSponsorRequest
  | IEditSponsorSuccess
  | IEditSponsorError
  | IDeleteSponsorAdRequest
  | IDeleteSponsorAdSuccess
  | IDeleteSponsorAdError
  | IEditSponsorAdRequest
  | IEditSponsorAdSuccess
  | IEditSponsorAdError
  | IMembersRequest
  | IMembersSuccess
  | IMembersError
  | IResetMembersState
  | ICreateBusinessAdminRequest
  | ICreateBusinessAdminSuccess
  | ICreateBusinessAdminError
  | IPodcastEditRequest
  | IPodcastEditSuccess
  | IPodcastEditError
  | IDeletePodcastRequest
  | IDeletePodcastSuccess
  | IDeletePodcastError
  | IDeleteBusinessAdminRequest
  | IDeleteBusinessAdminSuccess
  | IDeleteBusinessAdminError
  | IDeleteAccountRequest
  | IDeleteAccountSuccess
  | IDeleteAccountError
  | ISetTownCrierRequest
  | ISetTownCrierSuccess
  | ISetTownCrierError;

export interface IVettingDashboardState {
  loading: boolean;
  isGetBusinessesLoading: boolean;
  isListAffiliatesLoading: boolean;
  affiliatesData: IListAffiliatesState;
  unApprovedBusinesses: IUnApprovedBusinessesState;
  businessDetails: IBusinessDetails;
  influencers: IInfluencer[];
  influencersLoading: boolean;
  dateRange: IDateRange;
  affiliatesPayoutData: IAffiliatesPayoutState;
  approvedBusinessesData: IApprovedBusinessesState;
  AllSponsorsReportData: IAllSponsorsReportState;
  getAllSponsorsReportLoading: boolean;
  isApprovedBusinessesLoading: boolean;
  selectedProviderType: string;
  addProviderLoading: boolean;
  allBusinessLoading: boolean;
  allBusinesses: IBusiness[];
  allNewsProvidersLoading: boolean;
  allNewsProviders: IProvider[];
  allPodcastsLoading: boolean;
  allPodcasts: IPreferencePodcast[];
  featuredProvidersLoading: boolean;
  addAdsLoading: boolean;
  sponsorsData: ISponsorsListState;
  getSponsorsDataLoading: boolean;
  addSponsorLoading: boolean;
  sponsorAds: ISponsorAd[];
  deleteSponsorLoading: boolean;
  deleteSponsorAdLoading: boolean;
  isMembersLoading: boolean;
  membersData: IMembersState;
  addBusinessLoading: boolean;
}

export interface IDateRangePayload {
  from: string | undefined;
  to: string | undefined;
  tabIndex: number;
}

export interface IDateRange {
  from: string | undefined;
  to: string | undefined;
}

export interface IUnApprovedBusinessesState {
  offset: number;
  hasMoreData: boolean;
  unApprovedBusinesses: IUnApprovedBusinessState[];
}

export interface IUnApprovedBusinessState {
  documentId: string;
  name: string;
  phone: string;
  email: string;
  contactPersonName: string;
}

export interface IListAffiliatesState {
  offset: number;
  hasMoreData: boolean;
  affiliates: IAffiliateData[];
}

export interface IAffiliateData {
  businessCount: number;
  clicks: number;
  registers: number;
  userInfo: {
    email: string;
    userId: string;
    userName: string;
  };
}

export interface IInfluencer {
  userId: string;
  username: string;
  email: string;
}

export interface IAffiliatesPayoutState {
  offset: number;
  hasMoreData: boolean;
  affiliatesPayout: IAffiliatePayoutData[];
}

export interface IListAffiliatesPayoutPayload extends IAffiliatesPayoutState {
  initialLoad: boolean;
}

export interface IAffiliatePayoutData {
  documentId: string;
  amountCredits: number;
  type: 'paid' | 'unpaid';
  conversions: number;
  userInfo: {
    email: string;
    userId: string;
    userName: string;
  };
}

export interface IApprovedBusinessesState {
  offset: number;
  hasMoreData: boolean;
  approvedBusinesses: IApprovedBusiness[];
  reset?: boolean;
}

export interface IApprovedBusiness {
  documentId: string;
  name: string;
  contactPerson: string;
  contactPhone: string;
  email: string;
  dateSignUp: string;
  affiliateReferralLink: string;
}

export interface IMembersState {
  offset: number;
  hasMoreData: boolean;
  members: IMembers[];
  reset?: boolean;
}

export interface IDeleteAccountParams {
  email: string;
}

export interface IMembers {
  affiliateUserId: string;
  businessType: string;
  documentId: string;
  email: string;
  fullName: string;
  influencerStatus: boolean;
  isEmailVerified: boolean;
  isFollowing: boolean;
  leadId: string;
  postsCount: number;
  status: string;
  step: string;
  tagline: string;
  totalCount: number;
  userName: string;
  userType: string;
}

export interface IGetBusinessParams {
  offset: number;
  from?: string;
  to?: string;
  limit?: number;
  biztype?: string | undefined;
  searchQuery?: string;
  reset?: boolean;
  status?: string | undefined;
}

export interface IGetMembersParams {
  userType: string;
  offset: number;
  limit?: number;
  searchQuery?: string;
  reset?: boolean;
  userWithBusiness?: string;
}

export enum ProviderType {
  NEWS = 'news',
  PODCASTS = 'podcasts',
  BUSINESSES = 'businesses',
}

export interface IAddPodcastProviderState {
  name: string;
  externalShowLink?: string;
}

export interface IAddAdsState {
  pagePosition: string;
  url: string;
  couponUrl: string;
  sponsorType?: string;
  startDate?: string;
  endDate?: string;
}

export interface IAdminBusinessListState {
  featuredOffset: number;
  hasMoreFeaturedData: boolean;
  unfeaturedOffset: number;
  hasMoreUnFeaturedData: boolean;
  businesses: IBusiness[];
  isFeatured: boolean;
}

export interface IAllSponsorsReportState {
  offset: number;
  hasMoreData: boolean;
  reset?: boolean;
  AllSponsorsReport: IAllSponsorsReport[];
}

export interface IGetBusinessDownloadCSVParams {
  range?: IDateRange;
  searchQuery?: string;
  biztype?: string;
  status?: string;
}

export interface IAllSponsorsReport {
  clicks: number;
  coupon: string;
  couponClicks: number;
  couponImpressions: number;
  couponUrl: string;
  documentId: string;
  enddate: string;
  impressions: number;
  media: string;
  mobileOrWeb: string;
  position: string;
  page: string;
  sponsor: ISponsor;
  startdate: string;
  text: string;
  url: string;
}

export interface ISponsor {
  documentId: string;
  name: string;
  url: string;
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IAddSponsorState {
  name: string;
  url: string;
}

export interface ISponsorsListState {
  offset: number;
  hasMoreData: boolean;
  sponsors: ISponsor[];
}

export interface ISponsorsListPayload extends ISponsorsListState {
  initialLoad: boolean;
}

export interface ISponsorAd {
  coupon: string;
  couponUrl: string;
  createdAt: string;
  createdBy: string;
  documentId: string;
  enddate: string;
  height: number;
  media: string;
  mediaType: string;
  page: string;
  position: string;
  sequence: number;
  sponsor: ISponsor;
  startdate: string;
  text: string;
  type: string;
  updatedAt: string;
  url: string;
  width: number;
}
