import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Loader from 'components/loader/Loader';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAffiliatePayment } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

import ListOptions from './ListOptions';

function JoinAffiliate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { affiliatePaymentAccountDetails, loading, affiliateSyncData } = useAppSelector(
    s => s.affiliate,
  );

  const handleJoinAffiliate = () => {
    navigate('/affiliate-payment');
  };

  useEffect(() => {
    if (affiliateSyncData?.status !== 'deleted') dispatch(getAffiliatePayment());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ComponentWrapper title="">
      {affiliateSyncData?.status === 'deleted' ? (
        <Stack spacing={theme.spacing(2)}>
          <Typography component="span" textAlign="center" variant="title2">
            Freedom Square Affiliate Program
          </Typography>
          <Typography>
            Join a community committed to changing the American business landscape and restoring the
            American way of life.
          </Typography>
          <Typography>
            Connect like-minded business owners to our growing community that offers a safe harbor
            from cancel culture and a digital landscape designed for their businesses to thrive.
          </Typography>
          <Typography>
            Sign up for our program and share your affiliate link with qualifying businesses. For
            each business you bring into Freedom Square’s Business Network you will receive 10% of
            the subscription rate for the first year and 5% during consecutive years the business
            renews their subscription to our business network.
          </Typography>
          <Typography>
            Help celebrate American founded and American made businesses. By sharing your affiliate
            link you are helping our community of like-minded American business owners to grow.
          </Typography>
          <Box>
            <Typography>
              To apply to our Affiliate Program&nbsp;
              <Link
                href="mailto:affiliate@freedomsquare.zendesk.com?subject=Affiliate Program"
                rel="noopener noreferrer"
                target="_top"
                underline="hover"
              >
                affiliate@freedomsquare.zendesk.com
              </Link>
            </Typography>
          </Box>
          <Typography>Email Subject: Affiliate Program</Typography>
        </Stack>
      ) : (
        <>
          <Stack spacing={theme.spacing(3)}>
            <Typography component="span" textAlign="center" variant="title2">
              Freedom Square Affiliate Program
            </Typography>
            <Typography>
              Welcome to a community committed to changing the American business landscape and
              restoring the American way of life. Connect like-minded business owners to our growing
              community that offers a safe harbor from cancel culture and a digital landscape
              designed for their businesses to thrive.
            </Typography>
            <Typography>
              For each business you bring into Freedom Square’s Business Network you will receive
              10% of the subscription rate for the first year and 5% during consecutive years the
              business renews their subscription to our business network.
            </Typography>
            <Typography>
              Help celebrate American founded and American made businesses. By sharing your
              affiliate link you are helping our community of like-minded American business owners
              to grow.
            </Typography>
            <Box>
              <Typography>
                Contact:&nbsp;
                <Link
                  href="mailto:affiliate@freedomsquare.zendesk.com?subject=Affiliate Program"
                  rel="noopener noreferrer"
                  target="_top"
                  underline="hover"
                >
                  affiliate@freedomsquare.zendesk.com
                </Link>
              </Typography>
            </Box>
          </Stack>
          {loading ? (
            <Loader />
          ) : Object.keys(affiliatePaymentAccountDetails)?.length === 0 ? (
            <Stack spacing={theme.spacing(2)}>
              <Button
                disableRipple
                disableTouchRipple
                size="large"
                sx={{ textTransform: 'none', marginTop: theme.spacing(1) }}
                variant="contained"
                onClick={handleJoinAffiliate}
              >
                Add Account Information
              </Button>
            </Stack>
          ) : (
            <Stack spacing={theme.spacing(3)}>
              <Stack
                alignItems={{ xs: 'flex-start', midxs: 'center' }}
                direction={{ xs: 'column', midxs: 'row' }}
                marginTop={theme.spacing(1)}
                spacing={{ xs: theme.spacing(2), midxs: theme.spacing(5) }}
                width="100%"
              >
                <Typography sx={{ whiteSpace: 'nowrap' }}>Direct Deposit</Typography>
                <Box
                  border={`0.25px solid ${theme.palette.text.secondary}`}
                  boxSizing="border-box"
                  flexGrow={1}
                  padding={theme.spacing(1.5, 2)}
                  width="100%"
                >
                  <Stack width="100%">
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography>{affiliatePaymentAccountDetails.name}</Typography>
                      <ListOptions />
                    </Stack>
                    <Typography>
                      Account: ******
                      {affiliatePaymentAccountDetails.accountNumber.slice(-4)}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          )}
        </>
      )}
    </ComponentWrapper>
  );
}

export default JoinAffiliate;
