import { Reducer } from 'redux';

import { AuthActions, IAuthState } from './AuthenticationInterface';
import AuthenticationType from './AuthenticationTypes';

export const initialState: IAuthState = {
  loading: false,
  isLoggedIn: null,
  isSendMailLoading: false,
};

const AuthReducer: Reducer<IAuthState, AuthActions> = (
  state = initialState,
  action = {} as AuthActions,
) => {
  switch (action.type) {
    case AuthenticationType.RESET_AUTH_STATE: {
      return {
        ...state,
      };
    }
    case AuthenticationType.LOGIN_USER_REQUEST:
    case AuthenticationType.SIGNUP_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    }
    case AuthenticationType.LOGIN_USER_SUCCESS:
    case AuthenticationType.SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
      };
    }
    case AuthenticationType.LOGIN_USER_ERROR:
    case AuthenticationType.SIGNUP_USER_ERROR: {
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
      };
    }
    case AuthenticationType.USER_LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
      };
    }
    case AuthenticationType.SEND_RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthenticationType.SEND_RESET_PASSWORD_SUCCESS:
    case AuthenticationType.SEND_RESET_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthenticationType.RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthenticationType.RESET_PASSWORD_SUCCESS:
    case AuthenticationType.RESET_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthenticationType.UPDATE_LOGGEDIN_STATUS: {
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    }
    case AuthenticationType.SEND_VERIFICATION_EMAIL_REQUEST: {
      return {
        ...state,
        isSendMailLoading: true,
      };
    }
    case AuthenticationType.SEND_VERIFICATION_EMAIL_SUCCESS:
    case AuthenticationType.SEND_VERIFICATION_EMAIL_ERROR: {
      return {
        ...state,
        isSendMailLoading: false,
      };
    }
    case AuthenticationType.VERIFY_TOKEN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthenticationType.VERIFY_TOKEN_SUCCESS:
    case AuthenticationType.VERIFY_TOKEN_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthenticationType.VERIFY_EMAIL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthenticationType.VERIFY_EMAIL_SUCCESS:
    case AuthenticationType.VERIFY_EMAIL_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;
