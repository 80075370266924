import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import ProfileInformationForm from 'components/profile/ProfileInformationForm';
import useProfileInformationForm from 'components/profile/useProfileInformationForm';
import {
  IResponseData,
  IUserProfileInfoState,
  IUserProfileSetupState,
  updateUserProfileInfo,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { setUpProfileInformationSchema } from 'utils/validationSchema';

interface IProfileInformationProps {
  handleNext: () => void;
}

function ProfileInformation(props: IProfileInformationProps) {
  const { handleNext } = props;

  const dispatch = useAppDispatch();

  const { userData, isUpdateProfileInfoLoading } = useAppSelector(s => s.account);

  const profileInfoInitialState: IUserProfileInfoState = {
    fullName: userData?.fullName ?? '',
    tagline: userData?.tagline ?? '',
  };

  const handleUpdateProfileInformation = async () => {
    const requestData = {
      fullName: values.profileInformation.fullName,
      tagline: values.profileInformation.tagline,
    };

    const response = (await dispatch(updateUserProfileInfo(requestData))) as IResponseData;
    if (response && typeof response.error !== 'undefined' && !response.error) {
      handleNext();
    }
  };

  const handleProfileInfo = () => {
    if (Object.keys(userData).length > 0 && dirty) {
      handleUpdateProfileInformation();
    } else if (Object.keys(userData).length > 0 && !dirty) {
      handleNext();
    }
  };

  const setupProfileInfoInitialState: IUserProfileSetupState = {
    profileInformation: profileInfoInitialState,
  };

  const formik = useProfileInformationForm(
    handleProfileInfo,
    setupProfileInfoInitialState,
    setUpProfileInformationSchema,
  );

  const { touched, values, errors, handleChange, handleBlur, handleSubmit, isValid, dirty } =
    formik;

  return (
    <Box noValidate component="form" onSubmit={handleSubmit}>
      <ProfileInformationForm
        errors={errors.profileInformation}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched.profileInformation}
        values={values.profileInformation}
      />
      <LoadingButton
        disableRipple
        fullWidth
        disabled={!isValid}
        loading={isUpdateProfileInfoLoading}
        size="large"
        sx={{
          mt: theme.spacing(4),
        }}
        type="submit"
        variant="contained"
      >
        Next
      </LoadingButton>
    </Box>
  );
}

export default ProfileInformation;
