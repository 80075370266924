import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomInputField from 'components/customFields/CustomInputField';
import CustomPhoneInput from 'components/customFields/CustomPhoneInput';
import CustomSelectField from 'components/customSelect/CustomSelectField';
import Loader from 'components/loader/Loader';
import { FormikErrors, FormikTouched } from 'formik';
import React, { useEffect } from 'react';
import { getBusinessCategories, getStates } from 'store/business/BusinessAction';
import {
  BusinessComponentType,
  IBusinessCategory,
  IBusinessInformationState,
} from 'store/business/BusinessInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import {
  handleAllowOnlyNumber,
  handleAllowOnlyOneSpaceBetweenWords,
  handleMaskToUSNumber,
} from 'utils/utils';

const { SETUP, PROFILE } = BusinessComponentType;

interface IBusinessInformationProps {
  touched?: FormikTouched<IBusinessInformationState>;
  values: IBusinessInformationState;
  errors?: FormikErrors<IBusinessInformationState>;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T1 = string | React.ChangeEvent<any>>(field: T1): T1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: (e: React.FocusEvent) => void;
  setFieldValue: any;
  setTouched: any;
  componentType?: string;
  isFree: boolean;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" sx={{ color: 'secondary.main' }} />;

export default function BusinessInformation(props: IBusinessInformationProps) {
  const {
    handleBlur,
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
    setTouched,
    componentType,
    isFree,
  } = props;

  const dispatch = useAppDispatch();
  const { states, businessCategories, isGetBusinessDetailsLoading } = useAppSelector(
    s => s.business,
  );

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  useEffect(() => {
    if (states.length === 0) {
      fetchStates();
    }
    if (businessCategories.length === 0) {
      fetchBusinessCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStates = async () => {
    await dispatch(getStates());
  };

  const fetchBusinessCategories = async () => {
    await dispatch(getBusinessCategories());
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
    allowOnlyAlphabets = false,
  ) => {
    const value = allowOnlyAlphabets ? e.target.value.replace(/[^a-zA-Z ]/gi, '') : e.target.value;

    handleChange(fieldName)(handleAllowOnlyOneSpaceBetweenWords(value));
  };

  const handleDisableCategories = (option: IBusinessCategory) => {
    if (
      values.businessCategories.length === 5 &&
      values.businessCategories.every((c: IBusinessCategory) => c.documentId !== option.documentId)
    ) {
      return true;
    }
    return false;
  };

  return (
    <Grid container rowSpacing={theme.spacing(2.5)}>
      <Grid item xs={12}>
        <Typography
          align={componentType === SETUP ? 'center' : 'left'}
          variant={isMobile ? 'h5' : componentType === SETUP ? 'h4' : 'h3'}
        >
          Business Information
        </Typography>
      </Grid>
      {isGetBusinessDetailsLoading ? (
        <Loader />
      ) : (
        <>
          <Grid item xs={12}>
            <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(2.5)}>
              <CustomInputField
                fullWidth
                required
                error={!!(touched?.name && errors?.name)}
                errormessage={touched?.name ? errors?.name : undefined}
                id="name"
                inputProps={{ maxLength: 75 }}
                label="Business Name"
                name="businessInformation.name"
                placeholder="Enter the name of your business"
                type="text"
                value={values.name}
                onBlur={handleBlur}
                onChange={e => handleInputChange(e, 'businessInformation.name')}
              />
              {componentType === PROFILE && !isFree && (
                <>
                  <CustomInputField
                    fullWidth
                    required
                    error={!!(touched?.address && errors?.address)}
                    errormessage={touched?.address ? errors?.address : undefined}
                    id="address"
                    inputProps={{ maxLength: 32 }}
                    label="Address"
                    name="businessInformation.address"
                    type="text"
                    value={values.address}
                    onBlur={handleBlur}
                    onChange={e => handleInputChange(e, 'businessInformation.address')}
                  />
                  <Grid container>
                    <Grid item xs={6}>
                      <CustomInputField
                        fullWidth
                        required
                        error={!!(touched?.city && errors?.city)}
                        errormessage={touched?.city ? errors?.city : undefined}
                        id="city"
                        inputProps={{ maxLength: 36 }}
                        label="City"
                        name="businessInformation.city"
                        value={values.city}
                        onBlur={handleBlur}
                        onChange={e => handleInputChange(e, 'businessInformation.city', true)}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        paddingLeft: theme.spacing(2),
                      }}
                      xs={6}
                    >
                      <CustomSelectField
                        fullWidth
                        required
                        error={!!(touched?.stateId && errors?.stateId)}
                        errormessage={touched?.stateId ? errors?.stateId : undefined}
                        inputProps={{
                          name: 'businessInformation.stateId',
                        }}
                        label="State"
                        value={values.stateId}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        <option key="Select" value="">
                          Select State
                        </option>
                        {states.map(state => (
                          <option key={state.documentId} value={state.documentId}>
                            {state.name}
                          </option>
                        ))}
                      </CustomSelectField>
                    </Grid>
                  </Grid>
                  <CustomInputField
                    fullWidth
                    required
                    error={!!(touched?.zipcode && errors?.zipcode)}
                    errormessage={touched?.zipcode ? errors?.zipcode : undefined}
                    id="zipcode"
                    inputProps={{ maxLength: 5 }}
                    label="Zip Code"
                    name="businessInformation.zipcode"
                    type="text"
                    value={values.zipcode}
                    onBlur={handleBlur}
                    onChange={e => handleInputChange(e, 'businessInformation.zipcode')}
                    onKeyPress={handleAllowOnlyNumber}
                  />
                </>
              )}
              <CustomInputField
                fullWidth
                required
                error={!!(touched?.email && errors?.email)}
                errormessage={touched?.email ? errors?.email : undefined}
                id="email"
                inputProps={{ maxLength: 75 }}
                label="Business Email"
                name="businessInformation.email"
                placeholder="Enter your business email address"
                type="text"
                value={values.email}
                onBlur={handleBlur}
                onChange={e => handleInputChange(e, 'businessInformation.email')}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(2.5)}>
              {componentType === PROFILE && (
                <>
                  <Stack direction="column" spacing={0.75} sx={{ width: '100%' }}>
                    <Typography
                      color={
                        touched?.businessCategories && errors?.businessCategories
                          ? theme.palette.error.main
                          : ''
                      }
                      component="span"
                      variant="body1Bold"
                    >
                      Business Category (Max 5)
                    </Typography>
                    <FormControl fullWidth>
                      <Autocomplete
                        disableCloseOnSelect
                        multiple
                        getLimitTagsText={(more: number) =>
                          values.businessCategories.length === 3
                            ? `+${more} category`
                            : `+${more} categories`
                        }
                        getOptionDisabled={handleDisableCategories}
                        getOptionLabel={option => option.name}
                        id="business-category-chip-input"
                        isOptionEqualToValue={(option, value) =>
                          option.documentId === value.documentId
                        }
                        limitTags={2}
                        options={businessCategories}
                        renderInput={params => (
                          <TextField
                            name="businessInformation.businessCategories"
                            {...params}
                            error={!!errors?.businessCategories}
                            placeholder={
                              values.businessCategories.length > 0 ? '' : 'Select Business Category'
                            }
                            sx={{
                              '& .MuiInputBase-root': {
                                borderRadius: '4px',
                                width: '100%',
                                padding: theme.spacing(1.25, 1.5),
                                '& .MuiInputBase-input': {
                                  padding: 0,
                                },
                                '& fieldset': {
                                  border: 'unset',
                                },
                              },
                              '& .MuiChip-root': {
                                height: '26px',
                                border: 'unset',
                                background: theme.palette.secondary.main,
                                '& .MuiChip-label': {
                                  padding: theme.spacing(0, 1.5),
                                  color: theme.palette.common.white,
                                },
                                '& .MuiChip-deleteIcon': {
                                  color: theme.palette.common.white,
                                },
                                '&:hover': {
                                  background: theme.palette.secondary.dark,
                                },
                              },
                              border: `0.25px solid ${
                                touched?.businessCategories && errors?.businessCategories
                                  ? theme.palette.error.main
                                  : theme.palette.text.secondary
                              }`,
                              borderRadius: '4px',
                              padding: 0,
                              backgroundColor: theme.palette.background.paper,
                              color: theme.palette.text.primary,
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '22px',
                              position: 'relative',
                              '&:focus': {
                                borderColor: !errors?.businessCategories
                                  ? theme.palette.text.primary
                                  : '',
                              },
                            }}
                          />
                        )}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              checked={selected}
                              checkedIcon={checkedIcon}
                              icon={icon}
                              sx={{ marginRight: theme.spacing(1) }}
                            />
                            {option.name}
                          </li>
                        )}
                        sx={{
                          '.MuiOutlinedInput-root': {
                            border: 0,
                          },
                        }}
                        value={values.businessCategories}
                        onBlur={() => setTouched('businessInformation.businessCategories', true)}
                        onChange={(event, newValue) => {
                          setFieldValue('businessInformation.businessCategories', newValue);
                        }}
                      />
                    </FormControl>
                    {touched?.businessCategories && errors?.businessCategories ? (
                      <Typography color={theme.palette.error.main} fontSize="13px" variant="body1">
                        <>{errors?.businessCategories}</>
                      </Typography>
                    ) : null}
                  </Stack>
                  <CustomInputField
                    required
                    error={!!(touched?.website && errors?.website)}
                    errormessage={touched?.website ? errors?.website : undefined}
                    id="website"
                    inputProps={{ maxLength: 75 }}
                    label="Business Website"
                    name="businessInformation.website"
                    placeholder="Enter your business website"
                    type="text"
                    value={values.website}
                    onBlur={handleBlur}
                    onChange={e => handleInputChange(e, 'businessInformation.website')}
                  />
                </>
              )}
              <CustomPhoneInput
                required
                error={!!(touched?.phone && errors?.phone)}
                errormessage={touched?.phone ? errors?.phone : undefined}
                id="phone"
                inputProps={{ maxLength: 14 }}
                label="Business Phone"
                name="businessInformation.phone"
                placeholder="(xxx) xxx-xxxx"
                startAdornment={<InputAdornment position="start">+1</InputAdornment>}
                type="text"
                value={values.phone}
                onBlur={handleBlur}
                onChange={e => {
                  handleChange('businessInformation.phone')(handleMaskToUSNumber(e.target.value));
                }}
              />

              <CustomInputField
                required
                error={!!(touched?.contactPersonName && errors?.contactPersonName)}
                errormessage={touched?.contactPersonName ? errors?.contactPersonName : undefined}
                id="contactPersonName"
                inputProps={{ maxLength: 35 }}
                label="Contact Person"
                name="businessInformation.contactPersonName"
                placeholder="Enter name for contact purposes"
                type="text"
                value={values.contactPersonName}
                onBlur={handleBlur}
                onChange={e => handleInputChange(e, 'businessInformation.contactPersonName')}
              />
              <Stack
                alignItems="center"
                alignSelf="flex-start"
                direction="row"
                justifyContent="center"
              >
                <Checkbox
                  checked={values.onlineOnly}
                  className=""
                  color="secondary"
                  id="onlineBusiness"
                  name="businessInformation.onlineOnly"
                  sx={{ p: 0 }}
                  onChange={handleChange}
                />
                <Typography marginLeft={theme.spacing(1)}>
                  Set as an online only business
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </>
      )}
    </Grid>
  );
}
