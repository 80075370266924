import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import NotFound from 'components/notFound/NotFound';
import Reactions from 'components/reactions';
import CommentsList from 'components/reactions/CommentsList';
import CreateComment from 'components/reactions/CreateComment';
import GuestReactionView from 'components/reactions/GuestReactionView';
import { ICommentFormValues, useCommentForm } from 'components/reactions/useCommentForm';
import PostBanner from 'components/singlePost/PostBanner';
import PostContent from 'components/singlePost/PostContent';
import PostFooter from 'components/singlePost/PostFooter';
import PostHeader from 'components/singlePost/PostHeader';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import ConditionalWrapper from 'components/wrapper/ConditionalWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { podcastUrl } from 'helpers/urls';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ContentType,
  followUnFollowPodcastProvider,
  getEpisodeDetailsById,
  IAddCommentParams,
  IPodcastImage,
  likeDislikeEpisode,
  Relationship,
  resetPodcastEpisodeState,
} from 'store';
import { addComment, getComments } from 'store/comment/CommentReducer';
import getPostShareUrl from 'store/common/CommonActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { newsCommentValidationSchema } from 'utils/validationSchema';

interface IPodcastEpisodeProps {
  postId?: string;
}

export default function PodcastEpisode(props: IPodcastEpisodeProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const commentsRef = useRef<any>();
  const { episodeId } = useParams();
  const { postId } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showModal, setShowModal] = useState<boolean>(false);
  const { podcastEpisode, isEpisodeDetailsLoading, isFollowUnFollowLoading } = useAppSelector(
    s => s.podcast,
  );

  const { comments, addCommentLoading, loading: loadingComments } = useAppSelector(s => s.comment);

  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();
  const hasMoreData = podcastEpisode?.commentCount > comments.length;

  const podcastEpisodeId = episodeId || postId;

  const getEpisodeComments = (initialLoad: boolean) => {
    if (podcastEpisodeId) {
      const params = {
        contentId: podcastEpisodeId,
        offset: !initialLoad ? comments.length : 0,
        type: ContentType.PODCAST,
      };

      dispatch(getComments(params));
    }
  };

  useEffect(() => {
    if (podcastEpisodeId) dispatch(getEpisodeDetailsById(podcastEpisodeId));
    return () => {
      dispatch(resetPodcastEpisodeState());
    };
  }, [dispatch, podcastEpisodeId]);

  const episodeImage = useMemo(() => {
    const images = podcastEpisode?.thumbnail;
    return images || '';
  }, [podcastEpisode.thumbnail]);

  useEffect(() => {
    if (
      !isEpisodeDetailsLoading &&
      podcastEpisode &&
      Object.keys(podcastEpisode).length > 0 &&
      isUserLoggedIn() &&
      commentsRef?.current &&
      location?.state === 'scroll-to-comments'
    ) {
      onCommentClick();
      navigate(location.pathname, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEpisodeDetailsLoading]);

  const onCommentClick = () => {
    if (isUserLoggedIn() && commentsRef?.current) {
      commentsRef.current.scrollIntoView(true);
    } else {
      setShowModal(true);
    }
  };

  const onLikeClick = async (setDisableLike: (value: boolean) => void) => {
    if (isUserLoggedIn()) {
      await dispatch(
        likeDislikeEpisode(
          podcastEpisodeId as string,
          podcastEpisode?.isLiked ?? false,
          'podcasts',
          'episode',
        ),
      );
      setDisableLike(false);
    } else {
      setShowModal(true);
      setDisableLike(false);
    }
  };

  const sharePost = async () => {
    if (isUserLoggedIn()) {
      await dispatch(
        getPostShareUrl(`${podcastUrl}podcasts/${podcastEpisodeId as string}/share?type=post`),
      );
    } else {
      setShowModal(true);
    }
  };

  const handleAddComment = async (submittedValues: ICommentFormValues) => {
    const payload: IAddCommentParams = {
      commentText: submittedValues.comment,
      contentId: episodeId,
      type: ContentType.PODCAST,
    };
    await dispatch(addComment(payload));
    resetForm();
  };

  const commentState: ICommentFormValues = {
    comment: '',
  };

  const commentFormik = useCommentForm(handleAddComment, commentState, newsCommentValidationSchema);

  const { resetForm } = commentFormik;

  const handleRelationChange = async () => {
    if (isUserLoggedIn()) {
      await dispatch(
        followUnFollowPodcastProvider(podcastEpisode.podcastShowID, podcastEpisode.isFollowed),
      );
    } else {
      setShowModal(true);
    }
  };

  const podcastProviderLogo = useMemo(() => {
    const providerLogos: IPodcastImage[] = podcastEpisode?.externalShowImages
      ? JSON.parse(podcastEpisode?.externalShowImages)
      : [];
    return providerLogos?.[0]?.url || '';
  }, [podcastEpisode?.externalShowImages]);

  return (
    <HomeWrapper maxWidth="lg">
      <Container disableGutters maxWidth="lg">
        <Grid container alignItems="center" justifyContent="center">
          {isEpisodeDetailsLoading ? (
            <Loader />
          ) : podcastEpisode && Object.keys(podcastEpisode).length > 0 ? (
            <Grid item md={8} xs={12}>
              <ComponentWrapper title="">
                <PostHeader
                  contentType="Podcast"
                  date={podcastEpisode?.externalCreatedAt}
                  providerLogo={podcastProviderLogo}
                  providerName={podcastEpisode?.podcastShowName}
                  providerUrl={`/podcast-show/${podcastEpisode.podcastShowID}`}
                />
                <PostBanner
                  imgSrc={episodeImage}
                  isMobile={isMobile}
                  title={podcastEpisode?.episodeName}
                />
                <ConditionalWrapper
                  condition={!isUserLoggedIn()}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  wrapper={children => (
                    <Stack onClick={() => setShowModal(true)}>
                      <Box sx={{ pointerEvents: 'none' }}>{children}</Box>
                    </Stack>
                  )}
                >
                  <Box
                    sx={{
                      marginTop: theme.spacing(3),
                    }}
                  >
                    <iframe
                      // allowTransparency
                      allow="encrypted-media"
                      frameBorder="0"
                      height="80"
                      src={`https://open.spotify.com/embed/episode/${podcastEpisode.externalID}`}
                      title={podcastEpisode?.episodeName}
                      width="100%"
                    />
                  </Box>
                </ConditionalWrapper>
                <Reactions
                  showCount
                  commentsCount={podcastEpisode?.commentCount}
                  isLiked={podcastEpisode?.isLiked ?? false}
                  isMobile={false}
                  likesCount={podcastEpisode?.likeCount}
                  showReactionText={!isMobile}
                  onCommentClick={onCommentClick}
                  onLikeClick={onLikeClick}
                  onShareClick={sharePost}
                />
                <PostContent content={podcastEpisode?.episodeDescription} />
                <PostFooter
                  followStatus={
                    podcastEpisode?.isFollowed ? Relationship.UNFOLLOW : Relationship.FOLLOW
                  }
                  isLoading={isFollowUnFollowLoading}
                  providerName={podcastEpisode?.podcastShowName}
                  providerType="Podcast"
                  onFollowBtnClick={handleRelationChange}
                />
              </ComponentWrapper>
              {isUserLoggedIn() && (
                <Box ref={commentsRef} sx={{ my: theme.spacing(3) }}>
                  <ComponentWrapper
                    isTitleInsideWrapper
                    withInfiniteScroll
                    infiniteScrollProps={{
                      loadOnMount: true,
                      hasMoreData,
                      isLoading: loadingComments,
                      onBottomHit: getEpisodeComments,
                    }}
                    title={`Responses (${podcastEpisode?.commentCount})`}
                    withDivider={false}
                  >
                    <CreateComment
                      commentFormik={commentFormik}
                      isLoading={addCommentLoading}
                      type="create"
                    />
                    <CommentsList comments={comments} type={ContentType.PODCAST} />
                    {loadingComments && <Loader />}
                  </ComponentWrapper>
                </Box>
              )}
            </Grid>
          ) : (
            <NotFound />
          )}
          {/* <Grid item display={isMobile ? "none" : "block"} md={4} /> */}
        </Grid>
      </Container>
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </HomeWrapper>
  );
}
