import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Breakpoint } from '@mui/material/styles';
import useShowHomeNav from 'hooks/useShowHomeNav';
import theme from 'theme';

interface IProps {
  children: React.ReactNode | JSX.Element;
  maxWidth?: Breakpoint;
  useDefaultTopBottomPadding?: boolean;
  height?: string | number;
}

export default function HomeWrapper(props: IProps) {
  const { children, height = 'auto', maxWidth = 'lg', useDefaultTopBottomPadding = true } = props;
  const isHomeNavRoute = useShowHomeNav();

  return (
    <Container maxWidth={maxWidth} sx={{ height }}>
      <Box
        boxSizing="border-box"
        height="100%"
        sx={{
          ...(useDefaultTopBottomPadding && {
            py: {
              lg: theme.spacing(isHomeNavRoute ? 2 : 7),
              midsm: theme.spacing(isHomeNavRoute ? 2 : 4),
              xs: theme.spacing(isHomeNavRoute ? 4 : 3),
            },
          }),
        }}
      >
        {children}
      </Box>
    </Container>
  );
}
