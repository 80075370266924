/* eslint-disable @typescript-eslint/no-non-null-assertion */
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ProfileBanner from 'components/business/BusinessBanner';
import Loader from 'components/loader/Loader';
import GuestReactionView from 'components/reactions/GuestReactionView';
import TabPanel from 'components/tab/tabPanel';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { UserType } from 'constants/constants';
import { authUrl } from 'helpers/urls';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { followAndUnFollowProfile, getProfileWallInfo, resetProfileWallState } from 'store';
import getPostShareUrl from 'store/common/CommonActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { CookieNames, getCookieItem } from 'utils/cookies';
import { scrollToTop } from 'utils/utils';

import CreateProfilePost from './CreateProfilePost';
import ProfileMedia from './ProfileMedia';
import ProfilePosts from './ProfilePosts';

function ProfileWall() {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const ownerUserId = getCookieItem(CookieNames.USER_ID);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const { profileWallData, profileWallLoading, profileWallFollowingLoading } = useAppSelector(
    s => s.account,
  );

  const { loading: shareLoading } = useAppSelector(s => s.common);

  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();

  useEffect(() => {
    scrollToTop();
    dispatch(getProfileWallInfo(userId!));
    return () => {
      dispatch(resetProfileWallState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const changeFollowProfileStatus = () => {
    if (isUserLoggedIn()) {
      if (profileWallData.isFollowing) {
        dispatch(followAndUnFollowProfile(userId!, 'unfollow'));
      } else {
        dispatch(followAndUnFollowProfile(userId!, 'follow'));
      }
    } else {
      setShowModal(true);
    }
  };

  const shareProfile = async () => {
    if (isUserLoggedIn()) {
      await dispatch(getPostShareUrl(`${authUrl}share/${userId}?type=wall`));
    } else {
      setShowModal(true);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <HomeWrapper maxWidth="midmd">
      <Stack spacing={theme.spacing(5)} width="100%">
        <Box
          border={`0.25px solid ${theme.palette.text.secondary}`}
          borderRadius="4px"
          width="100%"
        >
          {!profileWallLoading ? (
            <>
              <ProfileBanner
                setHeightAuto
                backgroundImg={profileWallData?.images?.background?.croppedImageReadUrl}
                logoImg={
                  profileWallData?.croppedImageReadUrl || profileWallData?.originalImageReadUrl
                }
              />
              <Box padding={{ xs: theme.spacing(2), sm: theme.spacing(3) }}>
                <Stack spacing={theme.spacing(0.5)} width="100%">
                  <Typography component="h4" variant="h4">
                    {profileWallData.fullName}
                  </Typography>
                  <Typography component="h6" variant="h6">
                    {profileWallData.tagline}
                  </Typography>
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    marginTop={`${theme.spacing(3)} !important`}
                  >
                    <Stack direction="row" spacing={theme.spacing(2)}>
                      {profileWallData.userType === UserType.PAID &&
                        profileWallData.documentId !== ownerUserId && (
                          <LoadingButton
                            disableRipple
                            disableTouchRipple
                            loading={profileWallFollowingLoading}
                            variant="contained"
                            onClick={() => changeFollowProfileStatus()}
                          >
                            {profileWallData.isFollowing ? 'Unfollow' : 'Follow'}
                          </LoadingButton>
                        )}
                      <LoadingButton
                        disableRipple
                        disableTouchRipple
                        loading={shareLoading}
                        variant="contained"
                        onClick={() => shareProfile()}
                      >
                        Share
                      </LoadingButton>
                    </Stack>
                    {profileWallData.userType === UserType.PAID && (
                      <Stack
                        sx={{
                          ...(isMobile && {
                            width: '100%',
                            marginTop: theme.spacing(2),
                          }),
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: '4px',
                            border: `1px solid ${theme.palette.primary.main}`,
                            minWidth: '64px',
                            textAlign: 'center',
                            padding: theme.spacing(0.75, 3),
                            fontFamily: 'Inter',
                            letterSpacing: '0.02em',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            textTransform: 'uppercase',
                          }}
                        >
                          {profileWallData.followersCount} Follower(s)
                        </Box>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
                <Divider
                  sx={{
                    borderBottomWidth: '1px',
                    borderColor: theme.palette.text.secondary,
                    margin: theme.spacing(3, 0),
                  }}
                />
                <Stack spacing={theme.spacing(1)}>
                  <Typography variant="title2">About</Typography>
                  {profileWallData.userType === UserType.PAID ? (
                    <Typography component="h6" sx={{ overflowWrap: 'break-word' }} variant="h6">
                      {profileWallData.description}
                    </Typography>
                  ) : null}
                </Stack>
              </Box>
            </>
          ) : (
            <Loader />
          )}
        </Box>
        {profileWallData.userType === UserType.PAID && (
          <>
            {profileWallData.documentId === ownerUserId && (
              <ComponentWrapper isTitleInsideWrapper withDivider title="Create Content">
                <CreateProfilePost textContent="" type="create" />
              </ComponentWrapper>
            )}
            <ComponentWrapper title="">
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    aria-label="profile tabs"
                    indicatorColor="secondary"
                    sx={{
                      '& .MuiTabs-indicator': {
                        height: '3px',
                      },
                    }}
                    textColor="secondary"
                    value={selectedTab}
                    onChange={handleTabChange}
                  >
                    <Tab label="POSTS" sx={{ fontWeight: 'bold' }} />
                    <Tab label="MEDIA" sx={{ fontWeight: 'bold' }} />
                  </Tabs>
                </Box>
                <TabPanel index={0} value={selectedTab}>
                  <ProfilePosts />
                </TabPanel>
                <TabPanel index={1} value={selectedTab}>
                  <ProfileMedia />
                </TabPanel>
              </Box>
            </ComponentWrapper>
          </>
        )}
      </Stack>

      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </HomeWrapper>
  );
}

export default ProfileWall;
