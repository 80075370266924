import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/system';
import ConfirmationModal from 'components/confirmationModal/ConfirmationModal';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import Loader from 'components/loader/Loader';
import { StyledTableCell, StyledTableRow } from 'components/table/StyledTable';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import usePagination from 'hooks/usePagination';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  deletePodcast,
  featuredBusinesses,
  featuredNewsProviders,
  featuredPodcasts,
  getAllBusinessesProviders,
  getAllNewsProvidersList,
  getAllPodcasts,
  ProviderType,
  setSelectedProviderType,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { compareArrayObjects } from 'utils/utils';

import AddPodcastProvider from './AddPodcastProvider';
import DateRange from './DateRange';

const PER_PAGE = 10;

export type PodcastType = {
  name: string;
  documentId: string;
  dateFeatured: string;
  isFeatured: boolean;
  externalShowLink: string;
};

function Providers() {
  const dispatch = useAppDispatch();

  const {
    selectedProviderType,
    allBusinessLoading,
    allNewsProvidersLoading,
    allPodcastsLoading,
    allBusinesses,
    allNewsProviders,
    allPodcasts,
    featuredProvidersLoading,
  } = useAppSelector(s => s.vettingDashboard);

  const [providerData, setProviderData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showProviderModal, setShowProviderModal] = useState<boolean>(false);
  const [selectedPodcast, setSelectedPodcast] = useState<PodcastType>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const TABS: Array<ProviderType> = [
    ProviderType.NEWS,
    ProviderType.PODCASTS,
    ProviderType.BUSINESSES,
  ];

  const handleTypeChange = (type: ProviderType) => {
    setPage(1);
    PROVIDERS_DATA.jump(1);
    dispatch(setSelectedProviderType(type));
  };

  useEffect(() => {
    dispatch(setSelectedProviderType(ProviderType.NEWS));
    dispatch(getAllNewsProvidersList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (selectedProviderType) {
      case ProviderType.NEWS:
        dispatch(getAllNewsProvidersList());
        break;
      case ProviderType.PODCASTS:
        dispatch(getAllPodcasts());
        break;
      case ProviderType.BUSINESSES:
        dispatch(getAllBusinessesProviders());
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProviderType]);

  useEffect(() => {
    switch (selectedProviderType) {
      case ProviderType.NEWS: {
        const data = allNewsProviders.map(x => {
          return {
            name: x.name,
            documentId: x.documentID,
            dateFeatured: x.dateFeatured,
            isFeatured: x.isFeatured,
          };
        });
        setProviderData(data);
        break;
      }

      case ProviderType.PODCASTS: {
        const data = allPodcasts.map(x => {
          return {
            name: x.name,
            documentId: x.documentId,
            dateFeatured: x.dateFeatured,
            isFeatured: x.isFeatured,
            externalShowLink: x.externalShowLink,
          };
        });
        setProviderData(data);
        break;
      }

      case ProviderType.BUSINESSES: {
        const data = allBusinesses.map(x => {
          return {
            name: x.name,
            documentId: x.documentId,
            dateFeatured: x.dateFeatured,
            isFeatured: x.isFeatured,
          };
        });
        setProviderData(data);
        break;
      }
      default:
        break;
    }
  }, [selectedProviderType, allBusinesses, allNewsProviders, allPodcasts]);

  const PROVIDERS_DATA = usePagination(providerData, PER_PAGE);
  const count = Math.ceil(providerData.length / PER_PAGE);
  const isLoading = allBusinessLoading || allNewsProvidersLoading || allPodcastsLoading;

  const handlePagination = (e: any, p: number) => {
    setPage(p);
    PROVIDERS_DATA.jump(p);
  };

  const handleIsFeaturedCheck = (index: number) => {
    const updatedState = providerData.map((n: any, i: number) => {
      if (index === i) {
        return { ...n, isFeatured: !n.isFeatured };
      }
      return n;
    });
    setProviderData(updatedState);
  };

  const saveFeaturedProviders = async () => {
    switch (selectedProviderType) {
      case ProviderType.NEWS: {
        await saveFeaturedNewsProviders();
        break;
      }

      case ProviderType.PODCASTS: {
        await saveFeaturedPodcastProviders();
        break;
      }

      case ProviderType.BUSINESSES: {
        await saveFeaturedBusinesses();
        break;
      }
      default:
        break;
    }
  };
  const saveFeaturedNewsProviders = async () => {
    const newsProviderIds = providerData?.reduce((a: string[], c: any) => {
      if (c.isFeatured) {
        a.push(c.documentId);
      }
      return a;
    }, []);
    await dispatch(featuredNewsProviders(newsProviderIds));
  };

  const saveFeaturedPodcastProviders = async () => {
    const podcastProviderIds = providerData?.reduce((a: string[], c: any) => {
      if (c.isFeatured) {
        a.push(c.documentId);
      }
      return a;
    }, []);
    await dispatch(featuredPodcasts(podcastProviderIds));
  };

  const saveFeaturedBusinesses = async () => {
    const businessIds = providerData?.reduce((a: string[], c: any) => {
      if (c.isFeatured) {
        a.push(c.documentId);
      }
      return a;
    }, []);
    await dispatch(featuredBusinesses(businessIds));
  };

  const handleDisable = () => {
    switch (selectedProviderType) {
      case ProviderType.NEWS: {
        return compareArrayObjects(providerData, allNewsProviders, 'isFeatured');
      }

      case ProviderType.PODCASTS: {
        return compareArrayObjects(providerData, allPodcasts, 'isFeatured');
      }

      case ProviderType.BUSINESSES: {
        return compareArrayObjects(providerData, allBusinesses, 'isFeatured');
      }
      default:
        return true;
    }
  };

  const handleDelete = () => {
    setShowModal(false);
    dispatch(deletePodcast(selectedPodcast?.documentId || ''));
  };

  const toggleProviderModal = () => {
    setShowProviderModal(false);
    setSelectedPodcast(undefined);
  };

  const isPodcast = selectedProviderType === ProviderType.PODCASTS;

  return (
    <>
      <Box paddingBottom={2}>
        <DateRange
          isDisabled={false}
          isDownloadCSV={false}
          showDateRange={false}
          showSearch={false}
        />
      </Box>
      <ComponentWrapper borderColor="light" title="" withInfiniteScroll={false} withPadding={false}>
        <Stack
          alignItems="center"
          borderBottom={`0.25px solid ${theme.palette.divider}`}
          direction="row"
          padding={theme.spacing(3.65, 4, 3)}
          spacing={theme.spacing(1.5)}
        >
          <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
            <Stack direction="row" spacing={theme.spacing(4)}>
              {TABS.map((type: ProviderType) => (
                <Typography
                  key={type}
                  sx={{
                    cursor: 'pointer',
                    textTransform: 'capitalize',
                    ...(selectedProviderType === type && {
                      textDecoration: 'underline',
                      textUnderlineOffset: '4px',
                    }),
                    '&:hover': {
                      textDecoration: 'underline',
                      textUnderlineOffset: '4px',
                    },
                  }}
                  variant="title2"
                  onClick={() => handleTypeChange(type)}
                >
                  {type}
                </Typography>
              ))}
            </Stack>
            {isPodcast ? (
              <Box mt={isMobile ? 2 : 0}>
                <Button
                  disableRipple
                  disableTouchRipple
                  fullWidth={!!isMobile}
                  size="large"
                  sx={{
                    textTransform: 'none',
                  }}
                  variant="contained"
                  onClick={() => setShowProviderModal(true)}
                >
                  Add Podcasts Provider
                </Button>
              </Box>
            ) : null}
          </Stack>
        </Stack>
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>NAME</StyledTableCell>
                <StyledTableCell>DATE FEATURED</StyledTableCell>
                <StyledTableCell>FEATURED</StyledTableCell>
                {selectedProviderType === ProviderType.PODCASTS ? (
                  <StyledTableCell>Action</StyledTableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading &&
                PROVIDERS_DATA.currentData().map((provider, index) => (
                  <StyledTableRow key={`${provider.documentId}_${index.toString()}`}>
                    <StyledTableCell component="th" scope="row">
                      {provider.name}
                    </StyledTableCell>

                    <StyledTableCell>
                      {provider.dateFeatured && moment(provider.dateFeatured).format('YYYY-MM-DD')}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        aria-label="remove"
                        color="primary"
                        size="medium"
                        sx={{ padding: theme.spacing(0) }}
                        onClick={() => {
                          handleIsFeaturedCheck(providerData.indexOf(provider));
                          // handleBusinessCheck(businesses.indexOf(business))
                        }}
                      >
                        {provider.isFeatured ? (
                          <CheckCircleIcon fontSize="inherit" />
                        ) : (
                          <ControlPointIcon fontSize="inherit" />
                        )}
                      </IconButton>
                    </StyledTableCell>
                    {selectedProviderType === ProviderType.PODCASTS ? (
                      <StyledTableCell align="center">
                        <Stack direction="row" spacing={theme.spacing(2)}>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              setShowProviderModal(true);
                              setSelectedPodcast(provider);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => {
                              setSelectedPodcast(provider);
                              setShowModal(true);
                            }}
                          >
                            Delete
                          </Button>
                        </Stack>
                      </StyledTableCell>
                    ) : null}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && (
          <Box boxSizing="border-box" padding={theme.spacing(2, 0)} width="100%">
            <Loader />
          </Box>
        )}
        {!isLoading && PROVIDERS_DATA.currentData().length === 0 && (
          <Box boxSizing="border-box" padding={theme.spacing(4)} width="100%">
            <Typography align="center">No records found</Typography>
          </Box>
        )}
      </ComponentWrapper>
      <Stack
        alignItems="center"
        direction={isMobile ? 'column-reverse' : 'row'}
        justifyContent="space-between"
        marginTop={`${theme.spacing(3)} !important`}
        spacing={theme.spacing(2)}
      >
        <Pagination count={count} page={page} size="large" onChange={handlePagination} />
        <LoadingButton
          disabled={handleDisable()}
          fullWidth={!!isMobile}
          loading={featuredProvidersLoading}
          size="large"
          variant="contained"
          onClick={saveFeaturedProviders}
        >
          Confirm
        </LoadingButton>
      </Stack>

      <ToggleDrawerDialog
        showCloseIcon
        handleClose={() => setShowModal(false)}
        isMobile={isMobile}
        open={showModal}
        setOpen={setShowModal}
        title="Delete Podcast"
      >
        <ConfirmationModal
          loading={false}
          modalText={`Are you sure you want to permanently delete "${selectedPodcast?.name}"? This action can not be undone.`}
          submitBtnText="Confirm"
          onClose={() => setShowModal(false)}
          onSubmit={handleDelete}
        />
      </ToggleDrawerDialog>

      {showProviderModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={toggleProviderModal}
          isMobile={isMobile}
          open={showProviderModal}
          setOpen={toggleProviderModal}
          title={selectedPodcast ? 'Edit Podcast' : 'Add Podcast Provider'}
        >
          <AddPodcastProvider closeModal={toggleProviderModal} podcast={selectedPodcast} />
        </ToggleDrawerDialog>
      )}
    </>
  );
}

export default Providers;
