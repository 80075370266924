import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { useEffect } from 'react';
import { getAffiliateExists } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

import AffiliateLink from './AffiliateLink';
import Counts from './Counts';
import JoinAffiliate from './JoinAffiliate';

export default function Affiliate() {
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const dispatch = useAppDispatch();
  const { affiliateSyncData, affiliateSyncLoading } = useAppSelector(s => s.affiliate);

  useEffect(() => {
    scrollToTop();
    dispatch(getAffiliateExists());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomeWrapper>
      <Box
        border={isMobile ? 'none' : `0.25px solid ${theme.palette.text.secondary}`}
        borderRadius="4px"
        boxSizing="border-box"
        height="100%"
        padding={theme.spacing(isMobile ? 0 : 5)}
        width="100%"
      >
        <Grid container>
          <Grid item midmd={4} midsm={4} xs={12}>
            <Typography
              component="div"
              marginBottom={theme.spacing(2)}
              textAlign={isMobile ? 'center' : 'start'}
              variant="h5"
            >
              Affiliate
            </Typography>
          </Grid>
          <Grid item midmd={7} midsm={8} xs={12}>
            {affiliateSyncLoading ? (
              <Loader />
            ) : (
              <Stack spacing={theme.spacing(isMobile ? 3 : 4)}>
                {affiliateSyncData?.status === 'exists' && (
                  <>
                    <AffiliateLink />
                    <Counts />
                  </>
                )}
                <JoinAffiliate />
              </Stack>
            )}
          </Grid>
        </Grid>
      </Box>
    </HomeWrapper>
  );
}
