import useMediaQuery from '@mui/material/useMediaQuery';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import ListCardMenu from 'components/menu/ListCardMenu';
import React, { useState } from 'react';
import theme from 'theme';

import AffiliatePaymentReceiveForm from './AffiliatePayment/AffiliatePaymentReceiveForm';
import DeletePayment from './AffiliatePayment/DeletePayment';

function ListOptions() {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  return (
    <>
      <ListCardMenu
        onDelete={() => setShowDeleteModal(true)}
        onEdit={() => setShowEditModal(true)}
      />
      {showEditModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowEditModal(false)}
          isMobile={isMobile}
          open={showEditModal}
          setOpen={setShowEditModal}
          title="Edit"
        >
          <AffiliatePaymentReceiveForm
            type="edit"
            onEditModalClose={() => setShowEditModal(false)}
          />
        </ToggleDrawerDialog>
      )}
      {showDeleteModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowDeleteModal(false)}
          isMobile={isMobile}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          title="Delete"
        >
          <DeletePayment
            onDeleteModalClose={() => setShowDeleteModal(false)}
            onEditModalOpen={() => setShowEditModal(true)}
          />
        </ToggleDrawerDialog>
      )}
    </>
  );
}

export default ListOptions;
