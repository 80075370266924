import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import CustomInputField from 'components/customFields/CustomInputField';
import { useNavigate } from 'react-router-dom';
import {
  addAffiliatePayment,
  IAffiliatePaymentState,
  IResponseData,
  updateAffiliatePayment,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import {
  handleAllowOnlyNumber,
  handleAllowOnlyOneSpaceBetweenWordsAndAlphabets,
} from 'utils/utils';

import useAffiliatePaymentReceiveForm from './useAffiliatePaymentReceiveForm';

interface IProps {
  type?: string;
  onEditModalClose?: () => void;
}

function AffiliatePaymentReceiveForm({ type, onEditModalClose }: IProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { affiliatePaymentAccountDetails, loading, isUpdateLoading } = useAppSelector(
    s => s.affiliate,
  );
  const initialState: IAffiliatePaymentState = {
    name: '' || affiliatePaymentAccountDetails.name,
    routingNumber: '' || affiliatePaymentAccountDetails.routingNumber,
    accountNumber: '' || affiliatePaymentAccountDetails.accountNumber,
  };

  const onSubmit = async () => {
    const requestData: IAffiliatePaymentState = {
      ...values,
    };
    if (type === 'edit') {
      const response = (await dispatch(
        updateAffiliatePayment(affiliatePaymentAccountDetails.documentId, requestData),
      )) as IResponseData;
      if (!response.error) {
        onEditModalClose?.();
      }
    } else {
      const response = (await dispatch(addAffiliatePayment(requestData))) as IResponseData;
      if (!response.error) {
        navigate('/affiliate');
      }
    }
  };

  const formik = useAffiliatePaymentReceiveForm(onSubmit, initialState);
  const { touched, values, errors, handleChange, handleBlur, handleSubmit, isValid, dirty } =
    formik;

  return (
    <Stack
      noValidate
      component="form"
      spacing={theme.spacing(3)}
      width="100%"
      onSubmit={handleSubmit}
    >
      <Stack spacing={theme.spacing(2.5)}>
        <CustomInputField
          fullWidth
          error={!!(touched.name && errors.name)}
          errormessage={touched.name ? errors.name : undefined}
          id="ach-name"
          inputProps={{ maxLength: 35 }}
          label="Name"
          name="name"
          type="text"
          value={values.name}
          onBlur={handleBlur}
          onChange={e =>
            handleChange('name')(handleAllowOnlyOneSpaceBetweenWordsAndAlphabets(e.target.value))
          }
        />
        <CustomInputField
          fullWidth
          error={!!(touched.routingNumber && errors.routingNumber)}
          errormessage={touched.routingNumber ? errors.routingNumber : undefined}
          id="ach-routingNumber"
          inputProps={{ maxLength: 9 }}
          label="Routing #"
          name="routingNumber"
          type="text"
          value={values.routingNumber}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyPress={handleAllowOnlyNumber}
        />
        <CustomInputField
          fullWidth
          error={!!(touched.accountNumber && errors.accountNumber)}
          errormessage={touched.accountNumber ? errors.accountNumber : undefined}
          id="ach-accountNumber"
          inputProps={{ maxLength: 17 }}
          label="Account #"
          name="accountNumber"
          type="text"
          value={values.accountNumber}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyPress={handleAllowOnlyNumber}
        />
      </Stack>
      <Stack spacing={theme.spacing(type === 'edit' ? 2 : 3)}>
        {type !== 'edit' && (
          <Typography align="center">
            By completing and submitting this form you are digitally signing and giving permission
            for Freedom Square to make transactions with your Banking Institution.
          </Typography>
        )}
        <LoadingButton
          disableRipple
          disableTouchRipple
          fullWidth
          disabled={!(isValid && dirty)}
          loading={loading || isUpdateLoading}
          size="large"
          type="submit"
          variant="contained"
        >
          {type === 'edit' ? 'Save' : 'Submit'}
        </LoadingButton>
        {type === 'edit' && (
          <Button disableRipple size="large" variant="outlined" onClick={onEditModalClose}>
            Cancel
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

export default AffiliatePaymentReceiveForm;
