import { useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTimelinePosts, logout } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { CookieNames, getCookieItem } from 'utils/cookies';

import CustomMenu from './CustomMenu';

function HeaderMenu() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { userData } = useAppSelector(s => s.account);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const croppedImage: string = userData?.croppedImageReadUrl || '';
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const userRole = getCookieItem(CookieNames.USER_ROLE);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isMobile) {
      navigate('/account');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout()).then(() => {
      navigate('/home');
      dispatch(getTimelinePosts(0, 18));
      dispatch(getTimelinePosts(0, 10, false));
    });
  };

  return (
    <>
      <Stack
        alignItems="center"
        height="100%"
        justifyContent="space-between"
        sx={{ cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Avatar
          {...(croppedImage && { src: croppedImage })}
          sx={{ width: theme.spacing(4), height: theme.spacing(4) }}
        >
          {userData?.fullName?.charAt(0)?.toUpperCase()}
        </Avatar>
        {!isMobile && (
          <Typography color="common.white" component="span" variant="caption">
            Profile
          </Typography>
        )}
      </Stack>
      <CustomMenu
        anchorEl={anchorEl}
        open={open}
        variant="menu"
        onClick={handleClose}
        onClose={handleClose}
      >
        <MenuItem onClick={() => navigate(isMobile ? '/account' : '/account/profile')}>
          My Account
        </MenuItem>
        <Divider
          sx={{
            margin: theme.spacing(0.5, 2),
            borderBottomWidth: '0.25px',
            borderColor: theme.palette.text.secondary,
          }}
        />
        <MenuItem onClick={() => navigate('/affiliate')}>Affiliate</MenuItem>
        <Divider
          sx={{
            margin: theme.spacing(0.5, 2),
            borderBottomWidth: '0.25px',
            borderColor: theme.palette.text.secondary,
          }}
        />
        <MenuItem onClick={() => navigate('following')}>Preferences</MenuItem>
        {userRole.split(',').includes('admin') && (
          <MenuItem onClick={() => navigate('admin-dashboard')}>Admin Dashboard</MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      </CustomMenu>
    </>
  );
}

export default HeaderMenu;
