import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Loader from 'components/loader/Loader';
import NoDataAvailable from 'components/notFound/NoDataAvailable';
import BusinessDirectoryTile from 'components/tile/BusinessDirectoryTile';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import { useEffect, useState } from 'react';
import { getRecentBusinesses } from 'store/business/BusinessAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

interface IRecentBusinessProps {
  scrollPagination: boolean;
}

function RecentBusinesses({ scrollPagination }: IRecentBusinessProps) {
  const dispatch = useAppDispatch();

  const { recentBusinessLoading, recentBusinesses } = useAppSelector(s => s.business);

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const limit = 12;

  useEffect(() => {
    dispatch(getRecentBusinesses(0, limit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRecentBusinessList = () => {
    dispatch(getRecentBusinesses(recentBusinesses.offset, limit));
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ComponentWrapper
      isTitleInsideWrapper
      title="Recently Added"
      {...(scrollPagination
        ? {
            withInfiniteScroll: true,
            infiniteScrollProps: {
              loadOnMount: false,
              hasMoreData: recentBusinesses.hasMoreData,
              isLoading: recentBusinessLoading,
              onBottomHit: getRecentBusinessList,
            },
          }
        : {})}
    >
      <>
        {recentBusinesses.businesses && recentBusinesses.businesses.length > 0 ? (
          <Grid container spacing={4}>
            {recentBusinesses.businesses
              .slice(0, expanded ? 12 : 4)
              .map((business, index: number) => (
                <Grid
                  key={business.documentId + index.toString()}
                  item
                  md={3}
                  midsm={4}
                  sm={6}
                  xs={12}
                >
                  <BusinessDirectoryTile
                    key={business?.documentId}
                    businessId={business?.documentId}
                    businessType={business?.businessType}
                    description={business?.description}
                    image={business?.imageLogo.croppedImageReadUrl as string}
                    isFollowing={business?.isFollowing}
                    isOwner={business?.role === 'owner'}
                    name={business?.name}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          !recentBusinessLoading && <NoDataAvailable type="recent businesses" />
        )}
        {recentBusinessLoading && <Loader />}
        {recentBusinesses.businesses.length > 4 && (
          <Typography
            component="div"
            sx={{
              width: '100%',
              textAlign: 'center',
              marginTop: theme.spacing(3.5),
              cursor: 'pointer',
            }}
            variant="title2"
            onClick={() => handleExpandClick()}
          >
            {expanded ? 'Show Less' : 'See More'}
          </Typography>
        )}
      </>
    </ComponentWrapper>
  );
}

export default RecentBusinesses;
