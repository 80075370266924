import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Reactions from 'components/reactions';
import GuestReactionView from 'components/reactions/GuestReactionView';
import { DefaultBusinessLogo } from 'helpers/images';
import { newsUrl, podcastUrl } from 'helpers/urls';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { likeDislikeEpisode, likeDislikeNewsPost } from 'store';
import getPostShareUrl from 'store/common/CommonActions';
import { useAppDispatch } from 'store/hooks';
import theme from 'theme';
import {
  getNewsProviderLogo,
  isInvalidImageType,
  isValidImageUrl,
  isValidURL,
  timeDiffCalc,
} from 'utils/utils';

interface ITileProps {
  id: string;
  componentType?: string;
  image: string;
  name: string;
  logo?: string;
  title: string;
  description?: string;
  providerUrl?: string;
  type: string;
  category?: string;
  postedAt: string;
  withDivider: boolean;
  tileType?: 'small' | 'large';
  isLiked?: boolean;
  likesCount?: number;
  commentsCount?: number;
  providerId?: string;
  providerLogo?: string;
  isFeatured?: boolean;
  isTownCrierPost?: boolean;
}

// Note: isTownCrierPost - Currently, we are not using this key, but we are keeping the code for potential future use!

function Tile(props: ITileProps) {
  const {
    image,
    id,
    name,
    title,
    componentType = '',
    isFeatured = true,
    type,
    category,
    description,
    postedAt,
    withDivider,
    tileType = 'large',
    isLiked,
    likesCount,
    commentsCount,
    providerId = '',
    providerLogo = '',
    isTownCrierPost,
  } = props;

  const isSmallTile = tileType === 'small';
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();
  const routes: { [key: string]: string } = {
    news: `/article/${id}`,
    podcasts: `/podcast/${id}`,
    business: `/business/${id}`,
    profile: `/post/${id}`,
  };

  const onLikeClick = async (setDisableLike: (value: boolean) => void) => {
    if (isUserLoggedIn()) {
      if (type === 'news') {
        await dispatch(
          likeDislikeNewsPost(
            id as string,
            isLiked as boolean,
            componentType,
            isFeatured ? 'featured' : 'all-news',
          ),
        );
      } else if (type === 'podcasts') {
        await dispatch(
          likeDislikeEpisode(
            id as string,
            (isLiked as boolean) ?? false,
            componentType,
            isFeatured ? 'featured' : 'all-podcast',
          ),
        );
      }
      setDisableLike(false);
    } else {
      setShowModal(true);
      setDisableLike(false);
    }
  };

  const sharePost = async () => {
    if (isUserLoggedIn()) {
      if (type === 'news') {
        await dispatch(getPostShareUrl(`${newsUrl}users/news/${id as string}/share?type=post`));
      } else if (type === 'podcasts') {
        await dispatch(getPostShareUrl(`${podcastUrl}podcasts/${id as string}/share?type=post`));
      }
    } else {
      setShowModal(true);
    }
  };

  const handleRoute = (isComment: boolean) => {
    const route = type === 'business-only' ? `/business-wall/${id}` : routes[type];
    if (route && isComment) {
      if (isUserLoggedIn()) {
        navigate(route, { state: 'scroll-to-comments' });
      } else {
        setShowModal(true);
      }
    } else {
      navigate(route);
    }
  };

  const handleDetailsRoute = () => {
    if (type === 'business-only') {
      navigate(`/business-wall/${id}`);
    } else {
      navigate(`/${type === 'podcasts' ? 'podcast-show' : 'news-provider-wall'}/${providerId}`);
    }
  };

  const handleDisplayLogo = (): string => {
    if (type === 'business-only') {
      return providerLogo || DefaultBusinessLogo;
    }
    if (type === 'podcasts') {
      return providerLogo || name;
    }
    return getNewsProviderLogo(name);
  };

  const handleDisplayThumbnail = (): string => {
    if (isValidImageUrl(image)) {
      return image;
    }
    if (isValidURL(image) && !isInvalidImageType(image)) {
      return image;
    }
    const logo = handleDisplayLogo();
    return logo;
  };

  return (
    <>
      <Box boxSizing="border-box" height={isSmallTile ? 96 : 168}>
        <Grid container height="100%" justifyContent="space-between" spacing={2}>
          <Grid container item height="inherit" xs={7.5}>
            <Stack spacing={theme.spacing(1.5)} width="100%">
              <Stack direction="row" height="fit-content" spacing={theme.spacing(1.5)} width="100%">
                <Avatar
                  sx={{
                    width: theme.spacing(3),
                    height: theme.spacing(2.75),
                    cursor: 'pointer',
                  }}
                  variant="square"
                  onClick={handleDetailsRoute}
                >
                  <img
                    alt={name ?? 'provider-logo'}
                    loading="lazy"
                    src={handleDisplayLogo()}
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      height: '100%',
                      textAlign: 'center',
                      color: 'transparent',
                      textIndent: '10000px',
                    }}
                  />
                </Avatar>
                <Typography
                  component="span"
                  sx={{
                    cursor: 'pointer',
                    textUnderlineOffset: '2px',
                    textDecoration: 'none',
                    color: theme.palette.primary.main,
                    '&:hover': { textDecoration: 'underline' },
                  }}
                  variant="body3"
                  onClick={handleDetailsRoute}
                >
                  {name}
                </Typography>
              </Stack>
              <Stack flexGrow={1}>
                <Stack
                  alignItems="center"
                  direction="row"
                  height="100%"
                  spacing={theme.spacing(isSmallTile ? 1 : 2)}
                  width="100%"
                >
                  <Stack flexGrow={1} height="100%" justifyContent="space-evenly">
                    <Stack height="inherit" justifyContent="space-evenly">
                      {title.length > 0 ? (
                        <Typography
                          className="text-ellipsis text-clamp-2 text-ul-offset_4_hover"
                          component="p"
                          marginBottom={0}
                          sx={{
                            ...(isSmallTile && {
                              height: { midmd: theme.spacing(4.5), xs: 'auto' },
                            }),
                            marginBottom: 0,
                            '& *': { margin: 0 },
                          }}
                          variant={isSmallTile ? 'body2' : 'body1Bold'}
                          onClick={() => handleRoute(false)}
                        >
                          {title}
                        </Typography>
                      ) : null}
                      {!isSmallTile || type === 'business-only'
                        ? description && (
                            <Typography
                              className={`text-ellipsis ${
                                type === 'business-only' && !isSmallTile
                                  ? 'text-clamp-3'
                                  : isTownCrierPost
                                  ? 'text-clamp-3'
                                  : 'text-clamp-2'
                              }  text-ul-offset_4_hover`}
                              component="p"
                              dangerouslySetInnerHTML={{ __html: description as string }}
                              sx={{
                                '& *': {
                                  margin: 0,
                                  textDecoration: 'none',
                                  listStyle: 'none',
                                  fontWeight: 'normal',
                                  padding: 0,
                                },
                                marginBottom: 0,
                                paddingRight: isTownCrierPost ? 4 : 0,
                              }}
                              variant={
                                type === 'business-only' && !isSmallTile ? 'body1Bold' : 'subtitle1'
                              }
                              onClick={() => handleRoute(false)}
                            />
                          )
                        : null}
                    </Stack>
                    <Stack
                      alignItems="center"
                      // bottom={0}
                      direction="row"
                      // position="sticky"
                      spacing={{
                        sm: theme.spacing(1),
                        midmd: theme.spacing(0.75),
                        lg: theme.spacing(2),
                      }}
                    >
                      <Stack alignItems="center" direction="row" spacing={theme.spacing(1)}>
                        {type && (
                          <Typography color="text.secondary" variant="subtitle2Bold">
                            {type === 'business-only'
                              ? type.split('-')[0].toUpperCase()
                              : type.replace('_', ' ').toUpperCase()}
                          </Typography>
                        )}
                        {type !== 'business-only' ? (
                          <>
                            {category || postedAt ? (
                              <Box
                                height={theme.spacing(0.5)}
                                sx={{
                                  background: theme.palette.common.black,
                                  borderRadius: '50%',
                                }}
                                width={theme.spacing(0.5)}
                              />
                            ) : null}
                            {category && (
                              <Typography color="secondary.main" variant="subtitle2Bold">
                                {category.toUpperCase()}
                              </Typography>
                            )}
                            {postedAt && (
                              <Typography noWrap color="text.tertiary" variant="subtitle2">
                                {timeDiffCalc(postedAt, type === 'podcasts')}
                              </Typography>
                            )}
                          </>
                        ) : null}
                      </Stack>
                      {type !== 'business-only' ? (
                        <Stack alignItems="center" direction="row" justifyContent="center">
                          <Reactions
                            showCount
                            commentsCount={commentsCount as number}
                            component="tile"
                            isLiked={isLiked as boolean}
                            isMobile={isSmallTile}
                            likesCount={likesCount as number}
                            onCommentClick={() => handleRoute(true)}
                            onLikeClick={onLikeClick}
                            onShareClick={sharePost}
                          />
                        </Stack>
                      ) : null}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            display="flex"
            height="inherit"
            justifyContent="flex-end"
            paddingLeft={{
              ...(isSmallTile
                ? { lg: theme.spacing(3), xs: theme.spacing(1) }
                : {
                    lg: theme.spacing(8),
                    md: theme.spacing(7),
                    sm: theme.spacing(3),
                    xs: theme.spacing(2),
                  }),
            }}
            xs={4}
          >
            {isTownCrierPost ? (
              <Box sx={{ cursor: 'pointer', borderRadius: '10px', height: 160, width: 250 }}>
                <iframe
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  className="rumble"
                  height="360"
                  src=""
                  style={{ borderWidth: 0 }}
                  title="town-crier"
                  width="100%"
                />
              </Box>
            ) : (
              image && (
                <Box
                  alt={name ?? 'thumbnail-image'}
                  component="img"
                  loading="lazy"
                  src={handleDisplayThumbnail()}
                  sx={{
                    overflow: 'hidden',
                    objectFit: 'contain',
                    cursor: 'pointer',
                    width: '100%',
                    borderRadius: '10px',
                  }}
                  onClick={() => handleRoute(false)}
                />
              )
            )}
          </Grid>
        </Grid>
      </Box>
      {withDivider && (
        <Divider
          sx={{
            borderBottomWidth: '1px',
            borderColor: theme.palette.divider,
            margin: theme.spacing(isSmallTile ? 2 : 3, 0),
          }}
        />
      )}
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </>
  );
}

export default Tile;
