import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { IMAGE_EXTENSIONS } from 'constants/constants';
import { AddIcon } from 'helpers/images';
import { ImageType } from 'store';
import theme from 'theme';

interface IUploadImageProps {
  fileType: string;
  selectFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const { BACKGROUND, MEDIA, LOGO, AD, COUPON } = ImageType;

const boxDimention = {
  [LOGO]: { height: 132, width: 132 },
  [BACKGROUND]: { height: 170, width: undefined },
  [MEDIA]: { height: 112, width: 112 },
  [AD]: { height: 170, width: undefined },
  [COUPON]: { height: 170, width: undefined },
};

export default function UploadImageComponent({ selectFile, fileType }: IUploadImageProps) {
  const shapeStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'inherit',
    height: 'inherit',
    cursor: 'pointer',
  };

  return (
    <label
      htmlFor={fileType}
      style={{
        width: boxDimention[fileType as ImageType]?.width ?? '100%',
        height: boxDimention[fileType as ImageType]?.height,
      }}
    >
      <Box
        border={`0.25px dashed ${theme.palette.text.secondary}`}
        borderRadius="10px"
        component="span"
        sx={shapeStyles}
      >
        <input
          accept={IMAGE_EXTENSIONS}
          id={fileType}
          name={fileType}
          style={{ display: 'none' }}
          type="file"
          onChange={selectFile}
          onClick={(e: any) => {
            e.target.value = null;
          }}
        />
        <Stack
          alignItems="center"
          direction="column"
          justifyContent="center"
          spacing={theme.spacing(1)}
        >
          <SvgIcon
            inheritViewBox
            component={AddIcon}
            sx={{
              '& path': { fill: theme.palette.text.secondary },
              height: theme.spacing(1.75),
              width: theme.spacing(1.75),
            }}
          />
          <Typography variant="caption">Upload Image</Typography>
        </Stack>
      </Box>
    </label>
  );
}
