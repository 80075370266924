import { FormikHelpers, useFormik } from 'formik';
import { IDeleteAccountState } from 'store';
import { deleteAcountValidationSchema } from 'utils/validationSchema';

const useLoginForm = (
  onSubmit: (
    values: IDeleteAccountState,
    formikHelpers: FormikHelpers<IDeleteAccountState>,
  ) => void | Promise<unknown>,
  initialValues: IDeleteAccountState,
) => {
  return useFormik<IDeleteAccountState>({
    initialValues,
    validationSchema: deleteAcountValidationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export default useLoginForm;
