import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomInputField from 'components/customFields/CustomInputField';
import PasswordEyeIcon from 'components/customFields/PasswordEyeIcon';
import AuthWrapper from 'components/wrapper/AuthWrapper';
import ErrorMessages from 'constants/ErrorMessages';
import { memo, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  alertError,
  alertSuccess,
  IResetPasswordState,
  resetPassword,
  sendResetPassword,
  verifyToken,
} from 'store';
import { IResponseData } from 'store/common/CommonInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { handleRestrictSpaces } from 'utils/utils';
import { resetPasswordValidationSchema } from 'utils/validationSchema';

import useResetPasswordForm from './useResetPasswordForm';

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { token, email } = useParams();

  const { loading } = useAppSelector(s => s.auth);
  const [isLinkVerifying, setIsLinkVerifying] = useState(true);
  const [isLinkExpired, setIsLinkExpired] = useState(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const initialState: IResetPasswordState = { password: '', confirmPassword: '' };

  const isResetPassUrl = location.pathname.includes('/reset-password');

  const togglePassword = (type = 'p') => {
    if (type === 'p') setShowPassword(!showPassword);
    else setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    const handleEmailVerify = async () => {
      if (isResetPassUrl) {
        if (token) {
          const verifyTokenResponse = (await dispatch(verifyToken(token))) as IResponseData;
          setIsLinkVerifying(false);
          if (!verifyTokenResponse.error) {
            setIsLinkExpired(false);
          } else {
            setIsLinkExpired(true);
          }
        }
      }
    };

    handleEmailVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handelSubmit = async () => {
    const response: IResponseData = (await dispatch(
      resetPassword(values.password, token as string),
    )) as IResponseData;
    if (!response.error) {
      navigate('/login');
    }
  };

  const formik = useResetPasswordForm(handelSubmit, initialState, resetPasswordValidationSchema);
  const { dirty, touched, values, errors, handleChange, handleBlur, handleSubmit, isValid } =
    formik;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
  ) => handleChange(fieldName)(handleRestrictSpaces(e.target.value));

  const handleResendEmail = async () => {
    if (email) {
      const response: IResponseData = (await dispatch(sendResetPassword(email))) as IResponseData;
      if (response.error) {
        dispatch(alertError({ message: response.message }));
      } else {
        dispatch(
          alertSuccess({
            message: ErrorMessages.forgotPassword.resendEmailSuccess,
          }),
        );
      }
    }
  };

  return (
    <>
      {isLinkVerifying ? (
        <Grid container spacing={8}>
          <Grid item xs>
            <LinearProgress />
          </Grid>
        </Grid>
      ) : (
        <AuthWrapper>
          {isLinkExpired ? (
            <>
              <Stack alignItems="center" height="100%" spacing={theme.spacing(8.5)} width="100%">
                <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(2.5)}>
                  <Typography
                    align="center"
                    color={theme.palette.text.secondary}
                    component="div"
                    fontWeight={400}
                    variant={isMobile ? 'menu' : 'title2'}
                  >
                    {`${
                      isResetPassUrl ? 'Set' : 'Reset'
                    } Password link has expired. Please request a new link email.`}
                  </Typography>
                  <Typography
                    color={theme.palette.text.secondary}
                    component="span"
                    fontWeight={400}
                    variant="h6"
                  >
                    Click here to
                    <Typography
                      color={theme.palette.secondary.main}
                      component="span"
                      sx={{ cursor: 'pointer', ml: theme.spacing(0.5) }}
                      onClick={handleResendEmail}
                    >
                      resend the email
                    </Typography>
                  </Typography>
                </Stack>
              </Stack>
            </>
          ) : (
            <Stack
              noValidate
              alignItems="center"
              autoComplete="off"
              component="form"
              height="100%"
              spacing={theme.spacing(2.5)}
              width="100%"
              onSubmit={handleSubmit}
            >
              <Typography
                component="div"
                sx={{ textAlign: 'center' }}
                variant={isMobile ? 'h5' : 'h2'}
              >
                {isResetPassUrl ? 'Set Password' : 'Reset Password'}
              </Typography>
              <Typography
                color={theme.palette.text.secondary}
                component="div"
                fontWeight={400}
                variant={isMobile ? 'menu' : 'title2'}
              >
                Please enter a New Password
              </Typography>

              <CustomInputField
                fullWidth
                required
                endAdornment={
                  <PasswordEyeIcon
                    handleOnClick={() => togglePassword()}
                    showPassword={showPassword}
                  />
                }
                error={!!(touched.password && errors.password)}
                errormessage={touched.password ? errors.password : undefined}
                id="password"
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                onBlur={handleBlur}
                onChange={e => handleInputChange(e, 'password')}
              />
              <CustomInputField
                fullWidth
                required
                endAdornment={
                  <PasswordEyeIcon
                    handleOnClick={() => togglePassword('cp')}
                    showPassword={showConfirmPassword}
                  />
                }
                error={!!(touched.confirmPassword && errors.confirmPassword)}
                errormessage={touched.confirmPassword ? errors.confirmPassword : undefined}
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                value={values.confirmPassword}
                onBlur={handleBlur}
                onChange={e => handleInputChange(e, 'confirmPassword')}
              />
              <Stack
                spacing={theme.spacing(3.5)}
                sx={{ mt: `${theme.spacing(4)} !important` }}
                width="100%"
              >
                <LoadingButton
                  disableRipple
                  disableTouchRipple
                  disabled={!(isValid && dirty)}
                  loading={loading}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Change Password
                </LoadingButton>
              </Stack>
            </Stack>
          )}
        </AuthWrapper>
      )}
    </>
  );
}

export default memo(ResetPassword);
