import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import BadgeAvatar from 'components/avatar/BadgeAvatar';
import ImageCropper from 'components/imageCropper/ImageCropper';
import { IMAGE_EXTENSIONS } from 'constants/constants';
import { useState } from 'react';
import {
  alertError,
  deleteProfileImage,
  IImageUploadState,
  IResponseData,
  uploadProfilePhoto,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { dataURItoBlob, getRoundedCanvas, processImage } from 'utils/utils';
import { validateFileFormat, validateFileResolution, validateFileSize } from 'utils/validator';

type IUpload = IImageUploadState;

const validProfileImageWidth = 300;
const validProfileImageHeight = 300;

function ProfileAvatar() {
  const dispatch = useAppDispatch();
  const { profilePhotoLoader, userData } = useAppSelector(s => s.account);
  const { croppedImageReadUrl, imageViewAttribute, originalImageReadUrl } = userData;
  const [upload, setUpload] = useState<IUpload>({
    editImage: false,
    file: null,
    previewImage: '',
  });

  const croppedImage: string = croppedImageReadUrl || '';
  const originalImage: string = originalImageReadUrl || '';

  const handleEdit = () => {
    setUpload({ ...upload, editImage: true });
  };

  const selectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file = (target.files as FileList)[0];
    const isImage = file.type.includes('image');

    if (target?.files?.length === 1 && isImage) {
      if (isImage && !validateFileFormat(file.name, IMAGE_EXTENSIONS)) {
        dispatch(
          alertError({
            message:
              'File format is not supported. Please select one of the following file types: jpg, jpeg, png, heic or webp.',
          }),
        );
        return;
      }
      const maxSize = 100;
      const isFileSizeValid: boolean = validateFileSize(file.size, maxSize);

      const isFileResolutionValid = await validateFileResolution(
        file,
        validProfileImageWidth,
        validProfileImageHeight,
      );

      if (isFileSizeValid && isFileResolutionValid) {
        setUpload({
          ...upload,
          previewImage: await processImage(file),
          file,
          editImage: true,
        });
      } else {
        const errorMessage = !isFileSizeValid
          ? `The image file must be ${maxSize} MB or less.`
          : `The image resolution must be ${validProfileImageWidth} * ${validProfileImageHeight} or higher.`;
        dispatch(
          alertError({
            message: errorMessage,
          }),
        );
      }
    } else {
      dispatch(
        alertError({
          message: 'File format is not supported. Please select an image and try again.',
        }),
      );
    }
  };

  const handleCancelUploadImage = () => {
    setUpload({
      ...upload,
      previewImage: '',
      file: null,
      editImage: false,
    });
  };

  const handleApply = async (cropper: any, minZoom: number, zoom: number) => {
    let croppedCanvas = cropper.getCroppedCanvas();
    croppedCanvas = getRoundedCanvas(croppedCanvas);
    const dataURL = croppedCanvas.toDataURL('image/jpeg');
    const blob = dataURItoBlob(dataURL);
    const croppedFile = new File([blob], 'croppedImage.jpeg');
    let newFile: Blob | null = null;
    const createFile = async () => {
      try {
        const existingImage = originalImage;
        const response = await fetch(existingImage);
        const data = await response.blob();
        const metadata = {
          type: 'image/jpeg',
        };
        const file = new File([data], 'profileImage.jpeg', metadata);
        // ... do something with the file or return it
        return file;
      } catch (error) {
        dispatch(
          alertError({
            message: `Error while uploading profile image, Please try again later`,
          }),
        );
        return null;
      }
    };
    if (upload.file === null) {
      newFile = await createFile();
      if (newFile === null) return;
    }
    const uploadImageData = {
      image: upload.file || newFile,
      croppedImage: croppedFile,
      imageViewAttribute: JSON.stringify({
        data: cropper.getData(false),
        canvasData: cropper.getCanvasData(),
        cropBoxData: cropper.getCropBoxData(),
        minZoom,
        zoom,
      }),
      imageType: 'logo',
    };
    dispatch(uploadProfilePhoto(uploadImageData)).then((response: IResponseData) => {
      if (!response.error) handleCancelUploadImage();
    });
  };

  const removeProfilePhoto = () => {
    dispatch(deleteProfileImage());
  };

  return (
    <>
      {upload.editImage && (
        <ImageCropper
          enableZoom
          footer
          header
          aspectRatio={1}
          croppedImageData={imageViewAttribute ? JSON.parse(imageViewAttribute) : {}}
          handleApply={handleApply}
          handleCancelUploadImage={handleCancelUploadImage}
          image={originalImage}
          imageType="rounded"
          initialAspectRatio={1 / 1}
          loading={profilePhotoLoader}
          previewImage={upload.previewImage}
          selectFile={selectFile}
          title="Edit Profile Image"
        />
      )}
      <div className="d-flex align-items-center">
        <Typography component="h5" sx={{ marginBottom: theme.spacing(1.5) }} variant="caption">
          {`The avatar resolution must be ${validProfileImageWidth} * ${validProfileImageHeight} or higher.`}
        </Typography>
        <Badge
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <>
              {userData?.influencerStatus && (
                <BadgeAvatar>
                  <CheckCircleRoundedIcon color="primary" fontSize="medium" />
                </BadgeAvatar>
              )}
            </>
          }
          overlap="circular"
        >
          <Avatar
            src={croppedImage || upload.previewImage}
            sx={{
              width: 95,
              height: 95,
            }}
          />
        </Badge>
        {croppedImage && originalImage ? (
          <>
            <Button
              disableRipple
              disableTouchRipple
              sx={{
                px: theme.spacing(5),
                py: theme.spacing(1),
                marginLeft: theme.spacing(2),
                textTransform: 'none',
              }}
              variant="contained"
              onClick={handleEdit}
            >
              Edit
            </Button>
            <LoadingButton
              disableRipple
              disableTouchRipple
              loading={profilePhotoLoader}
              sx={{
                px: theme.spacing(5),
                py: theme.spacing(1.5),
                marginLeft: theme.spacing(2),
                color: theme.palette.text.tertiary,
                textTransform: 'none',
              }}
              variant="text"
              onClick={removeProfilePhoto}
            >
              Remove
            </LoadingButton>
          </>
        ) : (
          <>
            <Button
              disableRipple
              disableTouchRipple
              sx={{
                px: theme.spacing(5),
                py: theme.spacing(1),
                marginLeft: theme.spacing(2),
                textTransform: 'none',
              }}
              variant="contained"
            >
              <label className="d-flex align-items-center" htmlFor="btn-upload">
                <input
                  accept={IMAGE_EXTENSIONS}
                  className="d-none"
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={selectFile}
                  onClick={(e: any) => {
                    e.target.value = null;
                  }}
                />
                Upload Image
              </label>
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default ProfileAvatar;
