import { useMediaQuery } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect } from 'react';
import { clearAlert } from 'store/alerts/AlertActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

export default function Toast() {
  const { delay, message, open, status } = useAppSelector(s => s.alert);
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = useCallback(() => {
    dispatch(clearAlert());
  }, [dispatch]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleClose();
      }, delay);
    }
  }, [open, handleClose, delay]);

  return (
    <>
      {open && (
        <Box
          sx={{
            position: 'fixed',
            bottom: isMobile ? '80px' : 0,
            right: 0,
            padding: isMobile ? 0 : '30px',
            maxWidth: '400px',
            width: '100%',
            zIndex: 1310,
            marginRight: isMobile ? '10px' : 'auto',
          }}
        >
          <Alert severity={status}>
            <Typography component="span" variant="body1Bold">
              {message}
            </Typography>
          </Alert>
        </Box>
      )}
    </>
  );
}
