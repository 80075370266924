import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import LinkPreview from 'components/linkPreview';
import Reactions from 'components/reactions';
import GuestReactionView from 'components/reactions/GuestReactionView';
import { TYPE_LABEL_MAP } from 'constants/constants';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IPostType,
  likeDislikeBusiness,
  likeDislikeEpisode,
  likeDislikeNewsPost,
  likeDislikeProfilePost,
} from 'store';
import { useAppDispatch } from 'store/hooks';
import theme from 'theme';
import { getNewsProviderLogo, getPreviewLink } from 'utils/utils';

interface ITileProps {
  id: string;
  componentType?: string;
  name: string;
  logo?: string;
  title: string;
  description?: string;
  providerUrl?: string;
  type: IPostType;
  withDivider: boolean;
  tileType?: 'small' | 'large';
  isLiked?: boolean;
  likesCount?: number;
  commentsCount?: number;
  providerId?: string;
  providerLogo?: string;
  thumbnail: string;
}

function TrendingTile(props: ITileProps) {
  const {
    id,
    name,
    title,
    type,
    description,
    withDivider,
    isLiked,
    likesCount,
    commentsCount,
    providerId = '',
    providerLogo = '',
    thumbnail = '',
  } = props;

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const routes: { [key: string]: string } = {
    news: `/article/${id}`,
    podcasts: `/podcast/${id}`,
    business: `/business/${id}`,
    profile: `/post/${id}`,
    'paid-member-post': `/post/${id}`,
    'town-crier-post': `/town-crier/${id}`,
  };

  const previewLink = useMemo(() => {
    const uniqueLink = getPreviewLink(description || '');
    return !thumbnail ? uniqueLink : '';
  }, [thumbnail, description]);

  const handleRoute = (isComment: boolean) => {
    if (isComment) {
      navigate(routes[type], { state: 'scroll-to-comments' });
    } else {
      navigate(routes[type]);
    }
  };

  const onLikeClick = async (setDisableLike: (value: boolean) => void) => {
    if (isUserLoggedIn()) {
      if (type === 'news') {
        await dispatch(likeDislikeNewsPost(id as string, isLiked as boolean, 'trending'));
      } else if (type === 'podcasts') {
        await dispatch(likeDislikeEpisode(id as string, (isLiked as boolean) ?? false, 'trending'));
      } else if (type === 'business') {
        await dispatch(likeDislikeBusiness(id as string, isLiked as boolean, 'trending'));
      } else if (type === 'paid-member-post') {
        await dispatch(likeDislikeProfilePost(id as string, isLiked as boolean, 'trending'));
      } else if (type === 'town-crier-post') {
        await dispatch(likeDislikeProfilePost(id as string, isLiked as boolean, 'trending'));
      }
      setDisableLike(false);
    } else {
      setShowModal(true);
      setDisableLike(false);
    }
  };

  const handleDetailsRoute = () => {
    navigate(
      `/${
        type === 'podcasts'
          ? 'podcast-show'
          : type === 'business'
          ? 'business-wall'
          : type === 'paid-member-post'
          ? 'profile-wall'
          : type === 'town-crier-post'
          ? 'town-crier-wall'
          : 'news-provider-wall'
      }/${providerId}`,
    );
  };

  const handleDisplayLogo = (): string => {
    if (['business', 'paid-member-post', 'podcasts', 'town-crier-post'].includes(type)) {
      return providerLogo || name;
    }
    return getNewsProviderLogo(name);
  };

  return (
    <>
      <Box boxSizing="border-box">
        <Grid container item height="100%">
          <Stack spacing={theme.spacing(1.5)} width="100%">
            <Stack direction="row" height="fit-content" spacing={theme.spacing(1.5)} width="100%">
              <Avatar
                alt={name ?? 'provider-logo'}
                src={handleDisplayLogo()}
                sx={{
                  width: theme.spacing(3),
                  height: theme.spacing(2.75),
                  cursor: 'pointer',
                }}
                variant="square"
                onClick={handleDetailsRoute}
              />
              <Typography
                component="span"
                sx={{
                  cursor: 'pointer',
                  textUnderlineOffset: '2px',
                  textDecoration: 'none',
                  color: theme.palette.primary.main,
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                variant="body3"
                onClick={handleDetailsRoute}
              >
                {name}
              </Typography>
            </Stack>
            <Stack flexGrow={1}>
              <Stack
                alignItems="center"
                direction="row"
                height="100%"
                spacing={theme.spacing(1)}
                width="100%"
              >
                <Stack flexGrow={1} height="100%" justifyContent="space-evenly">
                  <Stack height="inherit" justifyContent="space-evenly">
                    {title.length > 0 ? (
                      <Typography
                        className="text-ellipsis text-clamp-2 text-ul-offset_4_hover"
                        component="p"
                        marginBottom={0}
                        sx={{
                          marginBottom: 0,
                          '& *': {
                            margin: 0,
                          },
                        }}
                        variant="body2"
                        onClick={() => handleRoute(false)}
                      >
                        {title}
                      </Typography>
                    ) : null}
                    {description &&
                    ['business', 'paid-member-post', 'town-crier-post'].includes(type) ? (
                      <Typography
                        className="text-ellipsis text-clamp-2 text-ul-offset_4_hover"
                        component="p"
                        marginBottom={0}
                        sx={{
                          marginBottom: 0,
                          '& *': {
                            margin: 0,
                          },
                        }}
                        variant="body2"
                        onClick={() => handleRoute(false)}
                      >
                        {description}
                      </Typography>
                    ) : null}
                    {thumbnail &&
                    ['business', 'paid-member-post', 'town-crier-post'].includes(type) ? (
                      <Box
                        sx={{ cursor: 'pointer', textAlign: 'center' }}
                        onClick={() => handleRoute(false)}
                      >
                        <img alt="trending-thumbnail" src={thumbnail} width="100%" />
                      </Box>
                    ) : null}

                    {previewLink ? (
                      <LinkPreview
                        key={previewLink}
                        type="post"
                        url={previewLink}
                        videoDimension="160"
                      />
                    ) : null}
                  </Stack>
                  <Stack alignItems="center" direction="row" spacing={theme.spacing(1)}>
                    <Stack alignItems="center" direction="row" spacing={theme.spacing(1)}>
                      {type && (
                        <Typography color="text.secondary" variant="subtitle2Bold">
                          {TYPE_LABEL_MAP[type] || type.toUpperCase()}
                        </Typography>
                      )}
                    </Stack>
                    <Stack alignItems="center" direction="row" justifyContent="center">
                      <Reactions
                        showCount
                        commentsCount={commentsCount as number}
                        component="tile"
                        isLiked={isLiked as boolean}
                        isMobile={false}
                        likesCount={likesCount as number}
                        showShare={false}
                        onCommentClick={() => handleRoute(true)}
                        onLikeClick={onLikeClick}
                        onShareClick={() => handleRoute(false)}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Box>
      {withDivider && (
        <Divider
          sx={{
            borderBottomWidth: '1px',
            borderColor: theme.palette.divider,
            margin: theme.spacing(2, 0),
          }}
        />
      )}
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </>
  );
}

export default TrendingTile;
