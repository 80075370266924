import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from 'theme';

const WEB_LOCATION_KEYS = [
  {
    position: 'Business: A',
    location: 'right of Featured Businesses section',
    dimensions: '321x453',
    disabled: false,
  },
  {
    position: 'Following: A',
    location: 'in Following section',
    dimensions: '692x119',
    disabled: false,
  },
  {
    position: 'Home: A',
    location: 'in Featured Posts section',
    dimensions: '692x119',
    disabled: false,
  },
  {
    position: 'Home: B',
    location: 'between Featured Posts & View All sections',
    dimensions: '1123x118',
    disabled: false,
  },
  {
    position: 'Home: C',
    location: 'in View All section',
    dimensions: '692x119',
    disabled: false,
  },
  {
    position: 'News: A DISABLED',
    location: 'in Featured News section',
    dimensions: '692x119',
    disabled: true,
  },
  {
    position: 'News: B',
    location: 'between Featured News & All News sections',
    dimensions: '692x119',
    disabled: false,
  },
  {
    position: 'News: C',
    location: 'in All News section',
    dimensions: '932x118',
    disabled: false,
  },
  {
    position: 'Podcasts: A  DISABLED',
    location: 'in Featured Podcasts section',
    dimensions: '692x119',
    disabled: true,
  },
  {
    position: 'Podcasts: B',
    location: 'between Featured Podcasts & All Podcasts sections',
    dimensions: '692x119',
    disabled: false,
  },
  {
    position: 'Podcasts: C',
    location: 'in All Podcasts section',
    dimensions: '932x118',
    disabled: false,
  },
];

const MOBILE_LOCATION_KEYS = [
  {
    position: 'Business: MA',
    location: 'right of Featured Businesses section',
    dimensions: '321x118',
    disabled: false,
  },
  {
    position: 'Following: MA',
    location: 'in Following section',
    dimensions: '321x118',
    disabled: false,
  },
  {
    position: 'Home: MA',
    location: 'in Featured Posts section',
    dimensions: '321x118',
    disabled: false,
  },
  {
    position: 'Home: MB',
    location: 'between Featured Posts & View All sections',
    dimensions: '361x118',
    disabled: false,
  },
  {
    position: 'Home: MC',
    location: 'in View All section',
    dimensions: '321x118',
    disabled: false,
  },
  {
    position: 'News: MA DISABLED',
    location: 'in Featured News section',
    dimensions: '321x118',
    disabled: true,
  },
  {
    position: 'News: MB',
    location: 'between Featured News & All News sections',
    dimensions: '361x118',
    disabled: false,
  },
  {
    position: 'News: MC',
    location: 'in All News section',
    dimensions: '321x118',
    disabled: false,
  },
  {
    position: 'Podcasts: MA  DISABLED',
    location: 'in Featured Podcasts section',
    dimensions: '321x118',
    disabled: true,
  },
  {
    position: 'Podcasts: MB',
    location: 'between Featured Podcasts & All Podcasts sections',
    dimensions: '361x118',
    disabled: false,
  },
  {
    position: 'Podcasts: MC',
    location: 'in All Podcasts section',
    dimensions: '321x118',
    disabled: false,
  },
];

export default function LocationKey() {
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography color="text.secondary" variant={isMobile ? 'title2' : 'title1'}>
            WEB
          </Typography>
          <ul>
            {WEB_LOCATION_KEYS.map(locationKey => (
              <li key={locationKey.position}>
                <Typography color="text.secondary">{locationKey.position}</Typography>
                <ul>
                  <li>
                    <Typography
                      style={{
                        ...(locationKey.disabled ? { textDecoration: 'line-through' } : {}),
                      }}
                    >
                      {locationKey.location}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{
                        ...(locationKey.disabled ? { textDecoration: 'line-through' } : {}),
                      }}
                    >
                      Dimensions: {locationKey.dimensions}
                    </Typography>
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography color="text.secondary" variant={isMobile ? 'title2' : 'title1'}>
            MOBILE
          </Typography>
          <ul>
            {MOBILE_LOCATION_KEYS.map(locationKey => (
              <li key={locationKey.position}>
                <Typography color="text.secondary">{locationKey.position}</Typography>
                <ul>
                  <li>
                    <Typography
                      style={{
                        ...(locationKey.disabled ? { textDecoration: 'line-through' } : {}),
                      }}
                    >
                      {locationKey.location}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{
                        ...(locationKey.disabled ? { textDecoration: 'line-through' } : {}),
                      }}
                    >
                      Dimensions: {locationKey.dimensions}
                    </Typography>
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Box>
  );
}
