import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Box, useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinkPreview from 'components/linkPreview';
import BusinessPostMenu from 'components/menu/BusinessPostMenu';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ContentType, ICommentLikeDisLikeParams, ICommonComment, IEditCommentParams } from 'store';
import { deleteComment, editComment, likeDislikeComment } from 'store/comment/CommentReducer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import {
  capitalizeFirstLetter,
  DELETED_COMMENT_STRING,
  getPreviewLink,
  replaceLinkWithAnchorTag,
  REPORTED_COMMENT_STRING,
  timeDiffCalc,
} from 'utils/utils';
import { newsCommentValidationSchema } from 'utils/validationSchema';

import Reactions from '.';
import CreateComment from './CreateComment';
import { ICommentFormValues, useCommentForm } from './useCommentForm';

interface ICommentsListProps {
  comment: ICommonComment;
  type: ContentType;
  disableDetails?: boolean;
  showReactions?: boolean;
}

interface IPreviewProps {
  comment: string;
}

function PreviewLink({ comment }: IPreviewProps) {
  const previewLink = useMemo(() => {
    const uniqueLink = getPreviewLink(comment);
    return uniqueLink;
  }, [comment]);

  return (
    <>{previewLink ? <LinkPreview key={previewLink} type="comment" url={previewLink} /> : null}</>
  );
}

export default function CommentTile(props: ICommentsListProps) {
  const { comment, type, disableDetails, showReactions = true } = props;

  const { likesCount, isLiked, documentID, parentCommentID, parentCommentId, business, user } =
    comment;

  const isDeleted = !comment?.comment;
  const { isReported } = comment;

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const { contentId } = useParams();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isCommentEdit, setIsCommentEdit] = useState<boolean>(false);

  const { selectedComment, deleteCommentLoading, editCommentLoading } = useAppSelector(
    s => s.comment,
  );
  const { userData } = useAppSelector(s => s.account);
  const { isMyOwnPost } = useAppSelector(s => s.business);

  const [userDetails, commentUserType] = useMemo(() => {
    const { businessName, images, businessDocumentId } = business || {};
    let { userId, fullName, userName, croppedImagePath, croppedImageReadUrl } = user;

    const name = businessName || fullName || userName;

    userId = userId || comment?.userID || '';

    const image = images?.[0]?.croppedImageReadUrl || croppedImagePath || croppedImageReadUrl || '';

    return [{ name, image, userId }, businessDocumentId ? 'business' : 'user'];
  }, [business, comment?.userID, user]);

  const textHtmlContent = useMemo(() => {
    return isDeleted
      ? isReported
        ? REPORTED_COMMENT_STRING
        : DELETED_COMMENT_STRING
      : replaceLinkWithAnchorTag(comment?.comment || '');
  }, [comment?.comment, isDeleted, isReported]);

  const handleEditComment = async (submittedValues: ICommentFormValues) => {
    const params: IEditCommentParams = {
      commentId: comment?.documentID,
      comment: submittedValues.comment,
      commentText: submittedValues.comment,
      type,
    };

    await dispatch(editComment(params));
    setIsCommentEdit(false);
  };

  const commentFormik = useCommentForm(
    handleEditComment,
    { comment: comment?.comment || '' },
    newsCommentValidationSchema,
  );

  const handleDeleteComment = async () => {
    const params = {
      commentId: documentID,
      parentCommentID: parentCommentID || parentCommentId,
      type,
    };
    dispatch(deleteComment(params));

    if (selectedComment.repliesCount <= 0) {
      navigate(-1);
    }
  };

  const handleLike = async (setDisableLike: (value: boolean) => void) => {
    const params: ICommentLikeDisLikeParams = {
      commentId: documentID,
      parentCommentId: parentCommentID || parentCommentId,
      isLiked,
      type,
    };
    await dispatch(likeDislikeComment(params));
    setDisableLike(false);
  };

  const isOwn = location.search.includes('true');

  const navToCommentDetails = () => {
    const parentID = comment?.episodeID || comment?.newsID || comment?.contentId || contentId;
    navigate(`/${type}/${parentID}/comment/${comment?.documentID}?isown=${isMyOwnPost || isOwn}`);
  };

  const disable = disableDetails || isDeleted;

  const isOwner = comment?.user?.userId === userData.documentId;

  const navigateToProfileWall = () => {
    if (commentUserType === 'user') {
      navigate(`/profile-wall/${userDetails?.userId}`);
      return;
    }
    navigate(`/business-wall/${business?.businessDocumentId}`);
  };

  return (
    <>
      {isCommentEdit ? (
        <CreateComment
          commentFormik={commentFormik}
          handleCancel={() => setIsCommentEdit(false)}
          isLoading={editCommentLoading}
          type={isCommentEdit ? 'edit' : 'create'}
        />
      ) : (
        <>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={theme.spacing(1.5)}
            width="100%"
          >
            <Stack direction="row" spacing={theme.spacing(1.5)}>
              <Avatar
                alt="provider-logo"
                src={userDetails.image}
                sx={{ width: theme.spacing(5) }}
                variant="circular"
                {...{
                  onClick: navigateToProfileWall,
                  className: 'cursor-pointer',
                }}
              />
              <Stack alignItems="flex-start" spacing={theme.spacing(2)}>
                <Stack alignItems="center" direction="row" spacing={theme.spacing(1)}>
                  <Typography
                    {...(userData && {
                      onClick: navigateToProfileWall,
                      sx: {
                        cursor: 'pointer',
                        textUnderlineOffset: '2px',
                        textDecoration: 'none',
                        color: theme.palette.primary.main,
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      },
                    })}
                    component="h6"
                    variant="h6Bold"
                  >
                    {capitalizeFirstLetter(userDetails.name)}
                  </Typography>
                  {comment?.user?.influencerStatus && (
                    <CheckCircleRoundedIcon color="primary" fontSize="small" />
                  )}
                </Stack>
                <Typography
                  noWrap
                  color="text.tertiary"
                  sx={{ mt: `0 !Important` }}
                  variant="subtitle2"
                >
                  {timeDiffCalc(comment?.createdAt)}
                </Typography>
              </Stack>
            </Stack>
            {!isDeleted && (
              <BusinessPostMenu
                contentId={comment.documentID}
                contentType="comment"
                handleDelete={handleDeleteComment}
                handleEdit={() => setIsCommentEdit(true)}
                isDeleteCommentLoading={deleteCommentLoading}
                isOwner={isOwner}
                postContentType={type}
                type="comment"
              />
            )}
          </Stack>
          <Box
            style={{ cursor: disable ? 'default' : 'pointer' }}
            onClick={disable ? undefined : navToCommentDetails}
          >
            <Typography
              color={isDeleted ? 'GrayText' : undefined}
              component="div"
              dangerouslySetInnerHTML={{ __html: textHtmlContent }}
              sx={{
                mt: theme.spacing(3),
                mb: theme.spacing(2),
                wordBreak: 'break-word',
                wordWrap: 'break-word',
              }}
              variant="title2"
            />
            {comment.isPrivate && !isDeleted ? (
              <Typography color="GrayText" component="div" variant="caption">
                This is a private communication, only you and the User can see this.
              </Typography>
            ) : null}

            <PreviewLink comment={comment?.comment} />
          </Box>

          {showReactions ? (
            <Reactions
              showCount
              commentsCount={(comment as ICommonComment)?.repliesCount}
              isDeleted={isDeleted}
              isLiked={isLiked}
              isMobile={isMobile}
              likesCount={likesCount}
              showShare={false}
              onCommentClick={navToCommentDetails}
              onLikeClick={handleLike}
            />
          ) : null}
        </>
      )}
    </>
  );
}
