import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import BusinessDetails from 'pages/myAccount/Business/BusinessDetails';
import BusinessInformation from 'pages/myAccount/Business/BusinessInformation';
import BusinessPhotos from 'pages/myAccount/Business/BusinessPhotos';
import { useState } from 'react';
import theme from 'theme';

function EditBusiness() {
  const [activeType, setActiveType] = useState('Information');
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const handleTypeChange = (type: string) => {
    setActiveType(type);
  };

  return (
    <Box
      boxSizing="border-box"
      maxWidth="100%"
      sx={{ backgroundColor: theme.palette.common.white }}
      width="100%"
    >
      <Grid container height={isMobile ? 'auto' : '100%'}>
        <Grid item xs={isMobile ? 12 : 4}>
          <Stack
            direction={isMobile ? 'row' : 'column'}
            padding={isMobile ? theme.spacing(2, 8) : theme.spacing(4, 8)}
            spacing={theme.spacing(isMobile ? 4 : 3)}
            sx={{ position: 'sticky', top: 0 }}
            {...(isMobile && {
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            {['Information', 'Description', 'Media'].map(type => (
              <Typography
                key={type}
                sx={{
                  cursor: 'pointer',
                  textTransform: 'capitalize',
                  ...(activeType === type && {
                    textDecoration: 'underline',
                    textUnderlineOffset: '4px',
                  }),
                  '&:hover': {
                    textDecoration: 'underline',
                    textUnderlineOffset: '4px',
                  },
                }}
                variant="title2"
                onClick={() => handleTypeChange(type)}
              >
                {type}
              </Typography>
            ))}
          </Stack>
        </Grid>
        <Grid item height="100%" xs={isMobile ? 12 : 8}>
          <Stack
            boxSizing="border-box"
            height="100%"
            padding={theme.spacing(3, 3)}
            sx={{ overflowY: 'auto' }}
          >
            <>
              {activeType === 'Information' && <BusinessInformation />}
              {activeType === 'Description' && <BusinessDetails />}
              {activeType === 'Media' && <BusinessPhotos />}
            </>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EditBusiness;
