import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PLAN_NAMES, UserType } from 'constants/constants';
import { FormikValues } from 'formik';
import theme from 'theme';
import { CookieNames, getCookieItem } from 'utils/cookies';

interface IPaymentSummerProps {
  formik: FormikValues;
  type: string;
}

function PaymentSummary(props: IPaymentSummerProps) {
  const { formik, type } = props;
  const { values } = formik;
  const businessOnboardingPrice = type === 'member' ? 2.99 : values.isMonthly ? 39 : 399;
  const userType = getCookieItem(CookieNames.USER_TYPE);
  const businessType = getCookieItem(CookieNames.BUSINESS_TYPE);
  return (
    <Box border={`2px solid ${theme.palette.divider}`} borderRadius="6px" width="100%">
      <Stack
        alignItems="center"
        justifyContent="center"
        padding={theme.spacing(2, 3)}
        spacing={theme.spacing(3)}
      >
        <Typography variant="h5">Summary</Typography>
        <Stack spacing={{ xs: theme.spacing(3), midmd: theme.spacing(3) }} width="100%">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">{`${
              userType === UserType.FREE && businessType === UserType.PAYMENT_INITIATED
                ? PLAN_NAMES.paid_business_initiated
                : userType === UserType.PAYMENT_INITIATED && businessType === ''
                ? PLAN_NAMES.paid_member_initiated
                : ''
            } - ${
              type === 'member' || values.isMonthly ? 'Monthly Subscription' : 'Annual Subscription'
            }`}</Typography>
            <Typography variant="h6">${businessOnboardingPrice}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default PaymentSummary;
