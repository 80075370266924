import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import BusinessDetails from 'components/business/BusinessDetails';
import SocialLinks from 'components/business/SocialLinks';
import { useProfileBusinessDetailsForm } from 'components/business/useBusinessForm';
import { UserType } from 'constants/constants';
import { memo } from 'react';
import { updateBusinessData } from 'store/business/BusinessAction';
import { IBusinessDetailsState, ISocialLinksState } from 'store/business/BusinessInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { CookieNames, getCookieItem } from 'utils/cookies';
import { profileBusinessDetailsSchema } from 'utils/validationSchema';

function ProfileBusinessDetails() {
  const dispatch = useAppDispatch();
  const { businessDetails, isUpdateBusinessLoading } = useAppSelector(s => s.business);
  const businessType = getCookieItem(CookieNames.BUSINESS_TYPE);
  const businessId: string = businessDetails.documentId ?? '';

  // useEffect(() => {
  //   if (Object.keys(businessDetails).length === 0) {
  //     dispatch(getBusinessDetailsByUserId());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch]);

  const businessDetailsInitialState: IBusinessDetailsState = {
    tagline: businessDetails.tagline,
    description: businessDetails.description,
  };

  const getSocialUrlByType = (type: string): string => {
    if (businessDetails?.socialLinks?.length > 0) {
      const data = businessDetails.socialLinks.find((social: any) => social.socialType === type);
      return data?.link ?? '';
    }
    return '';
  };

  const socialLinksInitialState: ISocialLinksState = {
    facebookUrl: getSocialUrlByType('facebook'),
    instagramUrl: getSocialUrlByType('instagram'),
    linkedinUrl: getSocialUrlByType('linkedin'),
    twitterUrl: getSocialUrlByType('twitter'),
  };

  const businessSetupInitialState = {
    businessDetails: businessDetailsInitialState,
    socialLinks: socialLinksInitialState,
  };

  const onSubmit = async () => {
    const socialLinks = [
      {
        socialType: 'facebook',
        link: values.socialLinks.facebookUrl,
      },
      {
        socialType: 'instagram',
        link: values.socialLinks.instagramUrl,
      },
      {
        socialType: 'linkedin',
        link: values.socialLinks.linkedinUrl,
      },
      {
        socialType: 'twitter',
        link: values.socialLinks.twitterUrl,
      },
    ];
    const socials = socialLinks.filter(s => s.link !== '');
    const payload = {
      ...values.businessDetails,
      socialLinks: socials,
    };
    if (dirty) {
      await dispatch(updateBusinessData(payload, businessId));
    }
  };

  const formik = useProfileBusinessDetailsForm(
    onSubmit,
    businessSetupInitialState,
    profileBusinessDetailsSchema,
  );

  const { touched, values, errors, handleChange, handleBlur, handleSubmit, dirty, resetForm } =
    formik;

  return (
    <Box noValidate component="form" onSubmit={handleSubmit}>
      <Grid container rowSpacing={theme.spacing(3.5)}>
        <Grid item xs={12}>
          <BusinessDetails
            errors={errors.businessDetails}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched.businessDetails}
            values={values.businessDetails}
          />
        </Grid>
        {businessType === UserType.PAID && (
          <Grid item xs={12}>
            <SocialLinks
              errors={errors.socialLinks}
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched.socialLinks}
              values={values.socialLinks}
            />
          </Grid>
        )}
      </Grid>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={theme.spacing(2)}
        sx={{ mt: theme.spacing(4) }}
      >
        <Button
          disableRipple
          disableTouchRipple
          fullWidth
          size="large"
          type="button"
          variant="outlined"
          onClick={() => resetForm()}
        >
          Cancel
        </Button>
        <LoadingButton
          fullWidth
          loading={isUpdateBusinessLoading}
          size="large"
          type="submit"
          variant="contained"
        >
          Save
        </LoadingButton>
      </Stack>
    </Box>
  );
}

export default memo(ProfileBusinessDetails);
