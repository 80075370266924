import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { CoreValues, PrivacyPolicy, TermsNConditions } from 'components/terms';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function ShowTerms() {
  const location = useLocation();
  const showPrivacyPolicy = useMemo(
    () => location.pathname === '/privacy-policy',
    [location.pathname],
  );
  const showCoreValues = useMemo(() => location.pathname === '/core-values', [location.pathname]);
  return (
    <HomeWrapper>
      <Container disableGutters maxWidth="midmd">
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={8} xs={12}>
            <ComponentWrapper title="">
              {showCoreValues ? (
                <CoreValues />
              ) : showPrivacyPolicy ? (
                <PrivacyPolicy />
              ) : (
                <TermsNConditions />
              )}
            </ComponentWrapper>
          </Grid>
        </Grid>
      </Container>
    </HomeWrapper>
  );
}
