import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BusinessDetails from 'components/business/BusinessDetails';
import { useSetupBusinessDetailsForm } from 'components/business/useBusinessForm';
import { useEffect } from 'react';
import { IResponseData } from 'store';
import { updateBusinessData } from 'store/business/BusinessAction';
import {
  BusinessComponentType,
  IBusinessDetailsState,
  ISetupBusinessDetailsState,
} from 'store/business/BusinessInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';
import { setupBusinessDetailsSchema } from 'utils/validationSchema';

interface IBusinessDetailsSetupProps {
  handleNext: () => void;
  handlePrev: () => void;
}

const { SETUP } = BusinessComponentType;

function BusinessDetailsSetup({ handleNext, handlePrev }: IBusinessDetailsSetupProps) {
  const dispatch = useAppDispatch();
  const { businessDetails, isUpdateBusinessLoading } = useAppSelector(s => s.business);

  useEffect(() => {
    scrollToTop();
  }, []);

  const businessDetailsInitialState: IBusinessDetailsState = {
    tagline: businessDetails.tagline ?? '',
    description: businessDetails.description ?? '',
  };

  const handleUpdateBusinessDetails = async () => {
    const data = { ...values.businessDetails };
    const response = (await dispatch(
      updateBusinessData(data, businessDetails?.documentId),
    )) as IResponseData;
    if (response && typeof response.error !== 'undefined' && !response.error) {
      handleNext();
    }
  };

  const handleBusinessDetails = () => {
    if (dirty) {
      handleUpdateBusinessDetails();
    } else {
      handleNext();
    }
  };

  const setupBusinessDetailsInitialState: ISetupBusinessDetailsState = {
    businessDetails: businessDetailsInitialState,
  };

  const formik = useSetupBusinessDetailsForm(
    handleBusinessDetails,
    setupBusinessDetailsInitialState,
    setupBusinessDetailsSchema,
  );

  const { touched, values, errors, handleChange, handleBlur, handleSubmit, dirty, isValid } =
    formik;

  return (
    <Box noValidate component="form" onSubmit={handleSubmit}>
      <BusinessDetails
        componentType={SETUP}
        errors={errors.businessDetails}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched.businessDetails}
        values={values.businessDetails}
      />
      <Stack direction="row" marginTop={theme.spacing(4)} spacing={theme.spacing(3)} width="100%">
        <Button fullWidth size="large" variant="outlined" onClick={handlePrev}>
          Back
        </Button>
        <LoadingButton
          fullWidth
          disabled={!isValid}
          loading={isUpdateBusinessLoading}
          size="large"
          type="submit"
          variant="contained"
        >
          Next
        </LoadingButton>
      </Stack>
    </Box>
  );
}

export default BusinessDetailsSetup;
