import { Box, Breakpoint } from '@mui/material';
import React from 'react';
import theme from 'theme';

import ModalContainer, { IModalProps } from '../modal/ModalContainer';
import BottomDrawer from './CustomBottomDrawer';

interface IProps extends IModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  breakpoint?: Breakpoint;
  isMobile: boolean;
}

function TogglePopupComponent(props: IProps) {
  const {
    children,
    isMobile,
    open,
    handleBack,
    handleClose,
    setOpen,
    title,
    showHeader = true,
    showBackIcon = false,
    showCloseIcon = false,
  } = props;

  return (
    <>
      {isMobile ? (
        <BottomDrawer
          withPuller
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          {children}
        </BottomDrawer>
      ) : (
        <ModalContainer
          handleBack={handleBack}
          handleClose={handleClose}
          open={open}
          showBackIcon={showBackIcon}
          showCloseIcon={showCloseIcon}
          showHeader={showHeader}
          title={title}
        >
          <Box
            sx={{
              width: '470px',
              padding: theme.spacing(3, 2),
              maxWidth: '100%',
              backgroundColor: theme.palette.common.white,
              boxSizing: 'border-box',
            }}
          >
            {children}
          </Box>
        </ModalContainer>
      )}
    </>
  );
}

export default TogglePopupComponent;
