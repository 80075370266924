import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { PLAN_NAMES } from 'constants/constants';
import { PremiumBusinessIcon } from 'helpers/images';
import theme from 'theme';

const GridItem = styled(Grid)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  border: `0.25px solid rgba(29, 29, 29, 0.2)`,
  borderRadius: theme.spacing(0.5),
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

interface IPlanTilePros {
  data: any;
  yearly: boolean;
  gridValue: number;
  handleSelectedPlan: (plan: string) => void;
}

function PlanTile({ data, yearly, gridValue, handleSelectedPlan }: IPlanTilePros) {
  return (
    <Grid item md={gridValue} xs={12}>
      <GridItem>
        <Typography
          noWrap
          align="center"
          color="text.secondary"
          component="h2"
          sx={{ mb: 2.5 }}
          variant="h5"
        >
          {PLAN_NAMES[data.plan]}
          {'  '}
          {data.plan === 'paid_business_initiated' && (
            <SvgIcon
              inheritViewBox
              component={PremiumBusinessIcon}
              sx={{
                width: theme.spacing(2),
                height: theme.spacing(2),
              }}
            />
          )}
        </Typography>
        <Divider
          flexItem
          sx={{
            borderColor: theme.palette.divider,
          }}
        />
        <Typography
          noWrap
          align="center"
          color="text.secondary"
          component="h2"
          sx={{ mt: 3 }}
          variant="h2"
        >
          {data.plan !== 'paid_business_initiated' ? data.amount : !yearly ? '$39' : '$399'}
        </Typography>
        <Typography
          align="center"
          color="text.secondary"
          component="div"
          fontWeight={300}
          sx={{ mb: 3 }}
          variant="title2"
        >
          {data.plan !== 'paid_business_initiated'
            ? data.subTitle
            : !yearly
            ? 'billed monthly'
            : 'billed annually'}
        </Typography>
        <Button
          fullWidth
          size="large"
          sx={{ mb: 3 }}
          variant="contained"
          onClick={() => handleSelectedPlan(data.plan)}
        >
          Get Started
        </Button>
        <Divider
          flexItem
          sx={{
            borderColor: theme.palette.divider,
          }}
        />

        <Typography
          color="text.secondary"
          component="h6"
          fontWeight={500}
          sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}
          textAlign="center"
          variant="h6Bold"
        >
          {data?.benefitsTitle}
        </Typography>

        <Typography
          color="text.secondary"
          component="h6"
          fontWeight={500}
          sx={{ mt: data?.benefitsTitle ? 1 : 3 }}
          textAlign="center"
          variant="h6"
        >
          {data.benefitsHeading}
        </Typography>
        <Stack>
          {data.features.map((feature: string, index: number) => (
            <Box
              key={`_${index.toString()}_`}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                mt: 1.5,
              }}
            >
              <Typography fontWeight={400} textAlign="center" variant="body1">
                <CheckIcon sx={{ fontSize: 14, pr: 1 }} />
                {feature}
              </Typography>
            </Box>
          ))}
        </Stack>
        {data?.limitedFeatures ? (
          <Stack>
            <Typography
              color="text.secondary"
              component="h5"
              fontWeight={500}
              sx={{ mt: 6 }}
              textAlign="center"
              variant="h5"
            >
              Limited Time
            </Typography>
            <Typography
              color="text.secondary"
              component="h6"
              fontWeight={500}
              textAlign="center"
              variant="h6"
            >
              Enjoy Access to Patriot Features
            </Typography>
            <Stack>
              {data.limitedFeatures.map((feature: string, index: number) => (
                <Box
                  key={`_${index.toString()}_`}
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    mt: 1.5,
                  }}
                >
                  <CheckIcon sx={{ fontSize: 14, pr: 1 }} />
                  <Typography component="h6" fontWeight={400} variant="h6">
                    {feature}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Stack>
        ) : null}
      </GridItem>
    </Grid>
  );
}

export default PlanTile;
