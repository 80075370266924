import { Reducer } from 'redux';
import {
  AffiliateActions,
  IAffiliateCountInfo,
  IAffiliatePaymentAccountDetails,
  IAffiliateState,
  IAffiliateSyncData,
} from 'store';

import AffiliateType from './AffiliateTypes';

const initialState: IAffiliateState = {
  loading: false,
  isUpdateLoading: false,
  affiliateLink: '',
  affiliatePaymentAccountDetails: {} as IAffiliatePaymentAccountDetails,
  affiliateCountInfo: {} as IAffiliateCountInfo,
  affiliateCountLoading: false,
  affiliateSyncLoading: true,
  affiliateSyncData: {} as IAffiliateSyncData,
};

const AffiliateReducer: Reducer<IAffiliateState, AffiliateActions> = (
  state = initialState,
  action = {} as AffiliateActions,
) => {
  switch (action.type) {
    case AffiliateType.CREATE_AFFILIATE_LINK_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AffiliateType.CREATE_AFFILIATE_LINK_SUCCESS: {
      return {
        ...state,
        loading: false,
        affiliateLink: action.payload.customLink ? action.payload.customLink : action.payload.link,
      };
    }
    case AffiliateType.CREATE_AFFILIATE_LINK_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case AffiliateType.LINK_AFFILIATE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AffiliateType.LINK_AFFILIATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AffiliateType.LINK_AFFILIATE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case AffiliateType.ADD_AFFILIATE_PAYMENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AffiliateType.ADD_AFFILIATE_PAYMENT_SUCCESS:
    case AffiliateType.ADD_AFFILIATE_PAYMENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case AffiliateType.GET_AFFILIATE_PAYMENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AffiliateType.GET_AFFILIATE_PAYMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        affiliatePaymentAccountDetails: { ...action.payload },
      };
    }
    case AffiliateType.GET_AFFILIATE_PAYMENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case AffiliateType.UPDATE_AFFILIATE_PAYMENT_REQUEST: {
      return {
        ...state,
        isUpdateLoading: true,
      };
    }
    case AffiliateType.UPDATE_AFFILIATE_PAYMENT_SUCCESS: {
      return {
        ...state,
        isUpdateLoading: false,
        affiliatePaymentAccountDetails: { ...action.payload },
      };
    }
    case AffiliateType.UPDATE_AFFILIATE_PAYMENT_ERROR: {
      return {
        ...state,
        isUpdateLoading: false,
      };
    }
    case AffiliateType.DELETE_AFFILIATE_PAYMENT_REQUEST: {
      return {
        ...state,
        isUpdateLoading: true,
      };
    }
    case AffiliateType.DELETE_AFFILIATE_PAYMENT_SUCCESS: {
      return {
        ...state,
        isUpdateLoading: false,
        affiliatePaymentAccountDetails: {} as IAffiliatePaymentAccountDetails,
      };
    }
    case AffiliateType.DELETE_AFFILIATE_PAYMENT_ERROR: {
      return {
        ...state,
        isUpdateLoading: false,
      };
    }
    case AffiliateType.ADD_CLICK_EVENT_REQUEST:
    case AffiliateType.ADD_CLICK_EVENT_SUCCESS:
    case AffiliateType.ADD_CLICK_EVENT_ERROR: {
      return {
        ...state,
      };
    }
    case AffiliateType.GET_AFFILIATE_COUNTS_REQUEST: {
      return {
        ...state,
        affiliateCountLoading: true,
      };
    }
    case AffiliateType.GET_AFFILIATE_COUNTS_SUCCESS: {
      return {
        ...state,
        affiliateCountLoading: false,
        affiliateCountInfo: action.payload,
      };
    }
    case AffiliateType.GET_AFFILIATE_COUNTS_ERROR: {
      return {
        ...state,
        affiliateCountLoading: false,
      };
    }
    case AffiliateType.GET_AFFILIATE_EXISTS_REQUEST: {
      return {
        ...state,
        affiliateSyncLoading: true,
      };
    }
    case AffiliateType.GET_AFFILIATE_EXISTS_SUCCESS: {
      return {
        ...state,
        affiliateSyncLoading: false,
        affiliateSyncData: action.payload,
      };
    }
    case AffiliateType.GET_AFFILIATE_EXISTS_ERROR: {
      return {
        ...state,
        affiliateSyncLoading: false,
      };
    }
    default:
      return state;
  }
};

export default AffiliateReducer;
