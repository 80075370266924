import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { replaceLinkWithAnchorTag } from 'utils/utils';

interface IPostContentProps {
  content: string;
  post?: 'business' | 'profile';
}

declare global {
  interface Window {
    twttr?: any;
    instgrm?: any;
  }
}

export default function PostContent(props: IPostContentProps) {
  const { content, post = '' } = props;

  useEffect(() => {
    if (window.twttr && content) window.twttr.widgets.load();

    if (window.instgrm && content) window.instgrm.Embeds.process();
  }, [content]);

  return (
    <Typography
      component="div"
      dangerouslySetInnerHTML={{
        __html: ['business', 'profile'].includes(post)
          ? replaceLinkWithAnchorTag(content)
          : content,
      }}
      id="articleContent"
      sx={{ wordBreak: 'break-word', wordWrap: 'break-word' }}
      variant="title2"
    />
  );
}
