import { Reducer } from 'redux';

import { CommonActions } from './CommonInterface';
import CommonTypes from './CommonTypes';

interface ICommonState {
  loading: boolean;
}

const initialState: ICommonState = {
  loading: false,
};

const CommonReducer: Reducer<ICommonState, CommonActions> = (
  state = initialState,
  action = {} as CommonActions,
) => {
  switch (action.type) {
    case CommonTypes.POST_SHARE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CommonTypes.POST_SHARE_SUCCESS:
    case CommonTypes.POST_SHARE_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default CommonReducer;
