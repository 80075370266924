import Box from '@mui/material/Box';
import Loader from 'components/loader/Loader';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addAffiliateClickEvent,
  getBusinessDetailsByUserId,
  IResponseData,
  linkAffiliate,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import {
  clearAffiliateSessionData,
  CookieNames,
  getCookieItem,
  setAffiliateCookie,
} from 'utils/cookies';

function CheckAffiliate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const homePath = '/home';
  const { affiliateId } = useParams();
  const { isGetBusinessDetailsLoading } = useAppSelector(s => s.business);
  const { userData } = useAppSelector(s => s.account);
  const { isLoggedIn } = useAppSelector(s => s.auth);

  const checkAffiliateIdInCookie = () => {
    return getCookieItem(CookieNames.AFFILIATE_ID).length > 0;
  };

  const setAffiliateDataInCookie = () => {
    setAffiliateCookie({
      affiliateId: affiliateId as string,
      isAffiliate: true,
    });
  };

  const checkBusinessExists = async () => {
    const response = (await dispatch(getBusinessDetailsByUserId())) as IResponseData;
    if (!response?.error && response?.data?.length === 0) {
      return false;
    }
    return true;
  };

  const handleLoggedIn = async () => {
    const isBusinessExists = await checkBusinessExists();
    if (!isBusinessExists) {
      if (userData?.affiliateUserId?.length === 0) {
        await setAffiliateDataInCookie();
        const affiliateId = getCookieItem(CookieNames.AFFILIATE_ID);
        const userId = getCookieItem(CookieNames.USER_ID);
        if (affiliateId.length > 0 && affiliateId !== userId) {
          dispatch(linkAffiliate(affiliateId)).then(() => clearAffiliateSessionData());
        }
        navigate(homePath);
      } else {
        navigate(homePath);
      }
    } else {
      navigate(homePath);
    }
  };

  const handleLoggedOut = async () => {
    const isAffiliateIdPresent = checkAffiliateIdInCookie();
    if (!isAffiliateIdPresent) {
      setAffiliateDataInCookie();
    }
    navigate(homePath);

    // const isAffiliateIdPresent = checkAffiliateIdInCookie();
    // let existingAffiliateId: string | undefined;
    // if (isAffiliateIdPresent) {
    //   existingAffiliateId = getCookieItem(CookieNames.AFFILIATE_ID);
    // } else {
    //   setAffiliateDataInCookie();
    // }
    // navigate(
    //   {
    //     pathname: "/signup",
    //     search: new URLSearchParams({
    //       affiliateId: isAffiliateIdPresent
    //         ? (existingAffiliateId as string)
    //         : (affiliateId as string),
    //     }).toString(),
    //   },
    //   { replace: true },
    // );
  };

  useEffect(() => {
    dispatch(addAffiliateClickEvent(affiliateId as string));
    const token = getCookieItem(CookieNames.ACCESS_TOKEN);
    if (token.length === 0) {
      handleLoggedOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const token = getCookieItem(CookieNames.ACCESS_TOKEN);
    if (isLoggedIn && token) {
      handleLoggedIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <HomeWrapper>
      {isGetBusinessDetailsLoading && (
        <Box padding={theme.spacing(8, 0)}>
          <Loader />
        </Box>
      )}
    </HomeWrapper>
  );
}

export default CheckAffiliate;
