import { FormikHelpers, useFormik } from 'formik';
import { IUserReportState } from 'store';

const useUserReportForm = (
  onSubmit: (
    values: IUserReportState,
    formikHelpers: FormikHelpers<IUserReportState>,
  ) => void | Promise<unknown>,
  initialValues: IUserReportState,
  validationSchema: any,
) => {
  return useFormik<IUserReportState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export default useUserReportForm;
