import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import GuestMenu from 'components/menu/GuestMenu';
import Search from 'components/search/Search';
import useAuthRouteCheck from 'hooks/useAuthRouteCheck';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getBusinessDetailsByUserId, getUserPersonalInfo, updateLoggedInStatus } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { CookieNames, getCookieItem } from 'utils/cookies';

import HeaderDrawer from './HeaderDrawer';
import HeaderLogo from './HeaderLogo';
import HeaderNav from './HeaderNav';

interface IHeaderProps {
  showHeaderNav?: boolean;
}

function Header(props: IHeaderProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { showHeaderNav = true } = props;
  const { isLoggedIn } = useAppSelector(s => s.auth);
  const { businessDetails } = useAppSelector(s => s.business);
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  useEffect(() => {
    const token = getCookieItem(CookieNames.ACCESS_TOKEN);
    if (token) {
      dispatch(getUserPersonalInfo()).then((response: any) => {
        if (!response.error) {
          dispatch(updateLoggedInStatus(true));
        } else dispatch(updateLoggedInStatus(false));
      });
    } else dispatch(updateLoggedInStatus(false));
  }, [dispatch]);

  useEffect(() => {
    const token = getCookieItem(CookieNames.ACCESS_TOKEN);
    if (
      token &&
      isLoggedIn &&
      Object.keys(businessDetails).length === 0 &&
      !/^\/(affiliate)\/[a-zA-Z0-9=]{15,40}/gi.test(location.pathname)
    ) {
      dispatch(getBusinessDetailsByUserId());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoggedIn]);

  const isAuthRoute = useAuthRouteCheck();
  const isBusinessRoute = window.location.pathname === '/business-setup';
  const isProfileRoute = window.location.pathname === '/profile-setup';
  const isSetUserNameRoute = location.pathname === '/set-username';

  return (
    <>
      {!isSetUserNameRoute || isMobile ? (
        <Box
          boxSizing="border-box"
          height={{ xs: '70px', midsm: '90px' }}
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
          width="100%"
        >
          <Stack
            alignItems="center"
            direction="row"
            height="100%"
            justifyContent="center"
            padding={{
              xs: theme.spacing(0, 2),
              sm: theme.spacing(0, 3),
              midlg: theme.spacing(0, 8),
            }}
          >
            <Stack
              alignItems="center"
              boxSizing="border-box"
              direction="row"
              height="100%"
              width="100%"
            >
              {!isAuthRoute && isMobile && (
                <Box marginRight={theme.spacing(3)}>
                  <HeaderDrawer />
                </Box>
              )}
              <Stack
                alignItems="center"
                direction="row"
                flex={{ xs: 1, midsm: isAuthRoute ? 1 : 0, lg: 1 }}
                justifyContent={isAuthRoute ? 'center' : 'flex-start'}
                width="100%"
              >
                <HeaderLogo
                  enableNavigation={!isBusinessRoute || !isProfileRoute}
                  height={theme.spacing(isMobile ? 3.7 : 4.75)}
                />
              </Stack>
              {!isAuthRoute && <Search />}
              {showHeaderNav &&
                ![
                  '/business-setup',
                  '/profile-setup',
                  '/affiliate-payment',
                  '/set-username',
                ].includes(location.pathname) && (
                  <Stack
                    alignItems="center"
                    direction="row"
                    flex={{ xs: 0, midsm: isAuthRoute ? 0 : 1 }}
                    height="100%"
                    justifyContent="flex-end"
                  >
                    {isLoggedIn ? (
                      <HeaderNav />
                    ) : !isAuthRoute && isLoggedIn !== null ? (
                      <GuestMenu />
                    ) : null}
                  </Stack>
                )}
            </Stack>
          </Stack>
          {isLoggedIn === null && <LinearProgress color="secondary" />}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

export default Header;
