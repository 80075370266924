import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { getBusinessDetailsByUserId } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { checkBusiness, checkPaidUser } from 'utils/utils';

export interface ILinkText {
  title: string;
  route: string;
  onClick?: () => void;
}

export function LinkText({ title, route, onClick }: ILinkText) {
  return (
    <Box
      end
      component={NavLink}
      sx={{
        textDecoration: 'none !important',
        '&.active .MuiTypography-root': {
          textUnderlineOffset: '4px',
          textDecorationThickness: '1.5px !important',
          textDecoration: 'underline',
        },
        '&:hover .MuiTypography-root': {
          textUnderlineOffset: '4px',
          textDecoration: 'underline',
        },
      }}
      to={route}
      onClick={onClick}
    >
      <Typography variant="title2">{title}</Typography>
    </Box>
  );
}

function AccountDesktopNav() {
  const dispatch = useAppDispatch();
  const { businessDetails, userBusinessId } = useAppSelector(s => s.business);
  const { userData } = useAppSelector(s => s.account);

  const showBusiness = checkBusiness(businessDetails);
  const showProfile = checkPaidUser(userData);

  const businessDetailsNavLinksClick = () => {
    if (
      Object.keys(businessDetails).length === 0 ||
      businessDetails.documentId !== userBusinessId
    ) {
      dispatch(getBusinessDetailsByUserId());
    }
  };

  return (
    <Stack spacing={theme.spacing(2)}>
      <LinkText route="profile" title="Account" />
      <LinkText route="payment" title="Payment" />
      {showProfile && (
        <Stack spacing={theme.spacing(2)}>
          <Typography component="span" variant="title2">
            Profile
          </Typography>
          <Stack marginLeft={`${theme.spacing(2)} !important`} spacing={theme.spacing(2)}>
            <LinkText route="profile-details" title="Details" />
            <LinkText route="profile-photos" title="Media" />
          </Stack>
        </Stack>
      )}
      {showBusiness && (
        <Stack spacing={theme.spacing(2)}>
          <Typography component="span" variant="title2">
            Business
          </Typography>
          <Stack marginLeft={`${theme.spacing(2)} !important`} spacing={theme.spacing(2)}>
            <LinkText
              route="business-information"
              title="Information"
              onClick={businessDetailsNavLinksClick}
            />
            <LinkText
              route="business-details"
              title="Description"
              onClick={businessDetailsNavLinksClick}
            />
            <LinkText
              route="business-photos"
              title="Media"
              onClick={businessDetailsNavLinksClick}
            />
          </Stack>
        </Stack>
      )}
      <LinkText route="terms" title="Terms of Use" />
      <LinkText route="privacy-terms" title="Privacy Policy" />
      <LinkText route="coreValues" title="Core Values" />
    </Stack>
  );
}

export default AccountDesktopNav;
