import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import GuestReactionView from 'components/reactions/GuestReactionView';
import { UserType } from 'constants/constants';
import { DefaultBusinessLogo } from 'helpers/images';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { followBusiness, unFollowBusiness } from 'store/business/BusinessAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

interface IBusinessTileProps {
  businessId: string;
  image: string;
  name: string;
  description?: string;
  isFollowing: boolean;
  isOwner: boolean;
  businessType: string;
}

function BusinessDirectoryTile(props: IBusinessTileProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { image, businessId, name, description, isFollowing, isOwner, businessType } = props;

  const { followLoading } = useAppSelector(s => s.business);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showModal, setShowModal] = useState<boolean>(false);
  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();
  const [selectedBusinessId, setSelectedBusinessId] = useState('');

  const changeFollowBusinessStatus = () => {
    if (isUserLoggedIn()) {
      setSelectedBusinessId(businessId);
      if (isFollowing) {
        dispatch(unFollowBusiness(businessId));
      } else {
        dispatch(followBusiness(businessId));
      }
    } else {
      setShowModal(true);
    }
  };

  const navigateToBusinessWall = () => navigate(`/business-wall/${businessId}`);

  return (
    <>
      <Stack
        display="flex"
        flexDirection="column"
        flexGrow={1}
        height="100%"
        spacing={theme.spacing(1)}
      >
        <Box alignItems="center" display="flex" flex={1} justifyContent="center">
          <Box
            alt="image"
            component="img"
            loading="lazy"
            overflow="hidden"
            src={image !== '' ? image : DefaultBusinessLogo}
            sx={{
              objectFit: 'contain',
              objectPosition: '50% 50%',
              cursor: 'pointer',
              borderRadius: '10px',
            }}
            width="100%"
            onClick={navigateToBusinessWall}
          />
        </Box>

        <Box display="flex" flexDirection="column" height={100} position="relative">
          <Typography
            className="text-ellipsis text-clamp-2 text-ul-offset_4_hover"
            variant="body1Bold"
            onClick={navigateToBusinessWall}
          >
            {name}
          </Typography>

          <Typography
            className="text-ellipsis text-clamp-3"
            sx={{ fontWeight: 400, lineHeight: '18px', marginTop: '5px' }}
            variant="subtitle1"
          >
            {description}
          </Typography>
        </Box>

        <Divider
          sx={{
            borderBottomWidth: '1px',
            borderColor: theme.palette.divider,
            margin: `${theme.spacing(1, 0)} !important`,
          }}
        />
        {!isOwner && businessType === UserType.PAID ? (
          <LoadingButton
            disableRipple
            disableTouchRipple
            fullWidth
            loading={followLoading && selectedBusinessId === businessId}
            size="small"
            variant="contained"
            onClick={() => changeFollowBusinessStatus()}
          >
            {isFollowing ? 'Unfollow' : 'Follow'}
          </LoadingButton>
        ) : (
          <Button
            disableRipple
            disableTouchRipple
            fullWidth
            size="small"
            variant="contained"
            onClick={navigateToBusinessWall}
          >
            {businessType === UserType.PAID ? 'My Business' : 'View'}
          </Button>
        )}
      </Stack>
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </>
  );
}

export default BusinessDirectoryTile;
