import { IconButton, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ConfirmationModal from 'components/confirmationModal/ConfirmationModal';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import Loader from 'components/loader/Loader';
import CustomMenu from 'components/menu/CustomMenu';
import { StyledTableCell, StyledTableRow } from 'components/table/StyledTable';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import ErrorMessages from 'constants/ErrorMessages';
import { EllipsisIconLarge } from 'helpers/images';
import { useCallback, useEffect, useState } from 'react';
import { alertError, alertSuccess, IMembers, sendResetPassword } from 'store';
import {
  deleteUserByAdmin,
  getMembersReport,
  getMembersReportFile,
  resetMembersState,
  setAsTownCrierByAdmin,
} from 'store/admin/vettingDashboard/VettingDashboardActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { downloadCsv } from 'utils/utils';

import DateRange, { IFilters } from './DateRange';

type MemberType = 'basic' | 'patriot' | 'deactivated' | 'deleted';

interface IMemberTypeOption {
  _id: number;
  label: string;
  value: MemberType;
}

const MEMBERS_TYPE_OPTIONS: IMemberTypeOption[] = [
  { _id: 0, label: 'Basic', value: 'basic' },
  { _id: 1, label: 'Patriot', value: 'patriot' },
  // { _id: 2, label: 'Deactivated', value: 'deactivated' },
  { _id: 3, label: 'Deleted', value: 'deleted' },
];

const USER_ACTIONS: {
  [key in MemberType]: { label: string; type: string }[];
} = {
  basic: [
    { type: 'resetPassword', label: 'Reset-Password' },
    // { type: 'deActivate', label: 'De-activate' },
    { type: 'delete', label: 'Delete' },
  ],

  patriot: [
    { type: 'setAsTownCrier', label: 'Set As Town-Crier' },
    { type: 'resetPassword', label: 'Reset-Password' },
    // { type: 'deActivate', label: 'De-activate' },
    { type: 'delete', label: 'Delete' },
  ],

  deactivated: [
    { type: 'reActivate', label: 'Re-Activate' },
    { type: 'delete', label: 'Delete' },
  ],

  deleted: [],
};

const modalText = (type: string, members: IMembers) => {
  switch (type) {
    case 'resetPassword': {
      return `Confirm to send Password Reset link to ${members.email}.`;
    }
    case 'delete':
      return `Are you sure you want to Delete ${members.userName}? This action can not be undone.`;

    case 'setAsTownCrier':
      return `Are you sure you want make ${members.userName} as town-crier?`;
    default:
      break;
  }
  return '';
};

const modalTitle = (type: string) => {
  switch (type) {
    case 'resetPassword': {
      return 'Reset-Password';
    }
    case 'delete':
      return 'Delete Member';

    case 'setAsTownCrier':
      return 'Set As Town-crier';
    default:
      break;
  }
  return '';
};

function MembersReport() {
  const dispatch = useAppDispatch();
  const [selectedMember, setSelectedMember] = useState({} as IMembers);
  const [actionType, setActionType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [csvLoader, showCsvLoader] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilters>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [selectedMemberType, setSelectedMemberType] = useState<MemberType>(
    MEMBERS_TYPE_OPTIONS[0].value,
  );

  const { loading, isMembersLoading, membersData } = useAppSelector(s => s.vettingDashboard);

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  useEffect(() => {
    return () => {
      dispatch(resetMembersState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const debounceSearchFn = setTimeout(async () => {
      dispatch(resetMembersState());
      fetchMembersReport(true, selectedMemberType);
    }, 200);

    return () => clearTimeout(debounceSearchFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const fetchMembersReport = useCallback(
    (initialLoad: boolean, type?: string) => {
      const params = {
        offset: initialLoad ? 0 : membersData.offset,
        userType: type || selectedMemberType,
        reset: initialLoad,
        searchQuery: filters?.searchQuery,
      };
      dispatch(getMembersReport(params));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters?.searchQuery, membersData.offset, selectedMemberType],
  );

  const handleTypeChange = (type: any) => {
    dispatch(resetMembersState());
    setSelectedMemberType(type);
    fetchMembersReport(true, type);
  };

  const onSubmit = async () => {
    handleCloseDrawerDialog();

    switch (actionType) {
      case 'resetPassword': {
        const response = await dispatch(sendResetPassword(selectedMember.email));
        if (response.error) {
          dispatch(alertError({ message: response.message }));
        } else {
          dispatch(alertSuccess({ message: ErrorMessages.forgotPassword.resendEmailSuccess }));
        }

        break;
      }
      case 'delete': {
        dispatch(deleteUserByAdmin({ email: selectedMember.email }, selectedMember.documentId));
        break;
      }

      case 'setAsTownCrier': {
        dispatch(setAsTownCrierByAdmin(selectedMember.documentId));
        break;
      }
      case 'deActivate':
      case 'reActivate':
      default: {
        break;
      }
    }
  };

  const downloadAsCSV = async ({ searchQuery }: IFilters) => {
    const csvFileName = 'membersReport';

    showCsvLoader(true);

    const csvData = await getMembersReportFile(selectedMemberType, searchQuery);

    if (csvData && csvFileName) {
      downloadCsv(csvData, csvFileName);
      showCsvLoader(false);
    }
  };

  const handleCloseDrawerDialog = () => {
    setShowModal(false);
    setActionType('');
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const componentSelectedTab = USER_ACTIONS[selectedMemberType];

  const isDownloadBtnDisabled = membersData?.members?.length === 0 || csvLoader;

  return (
    <>
      <CustomMenu
        excludeMarginTop
        anchorEl={anchorEl}
        open={open}
        sx={{ borderWidth: 1, borderColor: 'red' }}
        variant="menu"
        onClick={handleClose}
        onClose={handleClose}
      >
        {componentSelectedTab.map((item, i) => {
          return (
            <div>
              <MenuItem
                onClick={() => {
                  setActionType(item.type);
                  setShowModal(true);
                }}
              >
                {item.label}
              </MenuItem>
            </div>
          );
        })}
      </CustomMenu>
      <Box paddingBottom={2}>
        <DateRange
          isDownloadCSV
          showSearch
          csvLoader={csvLoader}
          isDisabled={isDownloadBtnDisabled}
          searchPlaceHolder="Members"
          onChangeFilter={setFilters}
          onDownloadCSV={downloadAsCSV}
        />
      </Box>
      <ComponentWrapper
        withInfiniteScroll
        borderColor="light"
        infiniteScrollProps={{
          loadOnMount: false,
          hasMoreData: membersData.hasMoreData,
          isLoading: isMembersLoading,
          onBottomHit: fetchMembersReport,
          valueToBeAddedToInnerHeight: 50,
        }}
        title=""
        withPadding={false}
      >
        <Stack
          borderBottom={`0.25px solid ${theme.palette.divider}`}
          direction="column"
          padding={isMobile ? theme.spacing(2) : theme.spacing(3.65, 4, 3)}
          spacing={theme.spacing(1.5)}
        >
          <Typography color="text.secondary" variant={isMobile ? 'title2' : 'title1'}>
            Members
          </Typography>

          <Stack direction="row" spacing={theme.spacing(4)}>
            {MEMBERS_TYPE_OPTIONS.map(type => (
              <Typography
                key={type.label}
                sx={{
                  cursor: 'pointer',
                  textTransform: 'capitalize',
                  ...(selectedMemberType === type.value && {
                    textDecoration: 'underline',
                    textUnderlineOffset: '4px',
                  }),
                  '&:hover': { textDecoration: 'underline', textUnderlineOffset: '4px' },
                }}
                variant="title2"
                onClick={() => handleTypeChange(type.value)}
              >
                {type.label}
              </Typography>
            ))}
          </Stack>
        </Stack>
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>User Name</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Posts</StyledTableCell>
                {componentSelectedTab.length ? <StyledTableCell>Action</StyledTableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {(!isMembersLoading || membersData.members.length > 0) &&
                membersData.members.map((members: IMembers, index: number) => (
                  <StyledTableRow key={`${members.documentId}_${index.toString()}`}>
                    <StyledTableCell>{members.userName}</StyledTableCell>
                    <StyledTableCell>{members.email}</StyledTableCell>
                    <StyledTableCell>{members.postsCount}</StyledTableCell>
                    {/* <StyledTableCell>{members.dateSignUp}</StyledTableCell> */}

                    {componentSelectedTab.length ? (
                      <StyledTableCell align="center">
                        <Stack spacing={theme.spacing(2)}>
                          <Box
                            alignSelf="flex-start"
                            // display="flex"
                            sx={{ cursor: 'pointer' }}
                            onClick={e => {
                              handleClick(e);
                              setSelectedMember(members);
                            }}
                          >
                            <IconButton sx={{ padding: theme.spacing(1.75, 1) }}>
                              <EllipsisIconLarge />
                            </IconButton>
                          </Box>
                        </Stack>
                      </StyledTableCell>
                    ) : null}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {isMembersLoading && (
          <Box padding={theme.spacing(2, 0)} width="100%">
            <Loader />
          </Box>
        )}
        {!isMembersLoading && membersData.members.length === 0 && (
          <Box padding={theme.spacing(4)} width="100%">
            <Typography align="center">No records found</Typography>
          </Box>
        )}
      </ComponentWrapper>
      <ToggleDrawerDialog
        showCloseIcon
        handleClose={handleCloseDrawerDialog}
        isMobile={isMobile}
        open={showModal}
        setOpen={setShowModal}
        title={modalTitle(actionType)}
      >
        <ConfirmationModal
          loading={loading}
          modalText={modalText(actionType, selectedMember)}
          submitBtnText="Confirm"
          onClose={handleCloseDrawerDialog}
          onSubmit={onSubmit}
        />
      </ToggleDrawerDialog>
    </>
  );
}

export default MembersReport;
