import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { getBusinessDetailsByUserId, getUserPersonalInfo, logout } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { checkPaidBusiness, checkPaidUser } from 'utils/utils';

export interface ILinkText {
  title: string;
  route: string;
  onClick?: () => void;
}

export function LinkText({ title, route, onClick }: ILinkText) {
  return (
    <Box
      end
      component={NavLink}
      sx={{
        textDecoration: 'none !important',
        '&.active .MuiTypography-root': {
          fontWeight: 600,
          color: theme.palette.primary.main,
        },
      }}
      to={route}
      onClick={onClick}
    >
      <Typography variant="menu">{title}</Typography>
    </Box>
  );
}

function AccountMobileNav() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { businessDetails, userBusinessId } = useAppSelector(s => s.business);
  const { userData } = useAppSelector(s => s.account);
  const businessDetailsNavLinksClick = () => {
    if (
      Object.keys(businessDetails).length === 0 ||
      businessDetails.documentId !== userBusinessId
    ) {
      dispatch(getBusinessDetailsByUserId());
    }
  };

  const profileDetailsNavLinksClick = () => {
    if (Object.keys(userData).length === 0) {
      dispatch(getUserPersonalInfo());
    }
  };

  const showBusiness = checkPaidBusiness(businessDetails);
  const showProfile = checkPaidUser(userData);

  const handleLogout = () => {
    dispatch(logout()).then(() => {
      navigate('/home');
    });
  };

  return (
    <>
      <LinkText route="/account/profile" title="Account" />
      <Divider />
      <LinkText route="/account/payment" title="Payment" />
      <Divider />
      <LinkText route="/affiliate" title="Affiliate" />
      <Divider />
      <LinkText route="/following" title="Preferences" />
      {showProfile && (
        <>
          <Divider />
          <Stack spacing={theme.spacing(2)}>
            <Typography component="span" variant="menu">
              Profile
            </Typography>
            <Stack marginLeft={`${theme.spacing(2)} !important`} spacing={theme.spacing(2)}>
              <LinkText
                route="/account/profile-details"
                title="Details"
                onClick={profileDetailsNavLinksClick}
              />
              <LinkText
                route="/account/profile-photos"
                title="Media"
                onClick={profileDetailsNavLinksClick}
              />
            </Stack>
          </Stack>
        </>
      )}
      {showBusiness && (
        <>
          <Divider />
          <Stack spacing={theme.spacing(2)}>
            <Typography component="span" variant="menu">
              Business
            </Typography>
            <Stack marginLeft={`${theme.spacing(2)} !important`} spacing={theme.spacing(2)}>
              <LinkText
                route="/account/business-information"
                title="Information"
                onClick={businessDetailsNavLinksClick}
              />
              <LinkText
                route="/account/business-details"
                title="Description"
                onClick={businessDetailsNavLinksClick}
              />
              <LinkText
                route="/account/business-photos"
                title="Media"
                onClick={businessDetailsNavLinksClick}
              />
            </Stack>
          </Stack>
        </>
      )}
      <Divider />
      <LinkText route="/account/terms" title="Term of Use" />
      <Divider />
      <LinkText route="/account/privacy-terms" title="Privacy Policy" />
      <Divider />
      <LinkText route="/account/coreValues" title="Core values" />
      <Divider />
      <Button
        fullWidth
        size="large"
        sx={{
          whiteSpace: 'nowrap',
          marginTop: theme.spacing(2.5),
        }}
        variant="contained"
        onClick={handleLogout}
      >
        Log out
      </Button>
    </>
  );
}

export default AccountMobileNav;
