import client from 'Api';
import { Dispatch } from 'redux';
import { AlertActions } from 'store/alerts/AlertInterface';
import { AlertType } from 'store/alerts/AlertTypes';

import { CommonActions, IResponseData } from './CommonInterface';
import CommonTypes from './CommonTypes';

const getPostShareUrl = (url: string) => {
  return async (dispatch: Dispatch<AlertActions | CommonActions>) => {
    try {
      dispatch({ type: CommonTypes.POST_SHARE_REQUEST });
      const userBrowserAgent = navigator.userAgent;
      if (userBrowserAgent.indexOf('Safari') > -1) {
        let res: any;
        if (navigator.clipboard) {
          const type = 'text/plain';
          const copyData = [
            new ClipboardItem({
              [type]: client.post(url).then(response => {
                res = response;
                if (!(response.data && Object.keys(response.data).length > 0)) {
                  return new Promise(resolve => {
                    resolve(new Blob([``], { type: 'text/plain' }));
                  });
                }
                const shareLink = response.data?.shareLink;
                return new Promise(resolve => {
                  resolve(new Blob([shareLink], { type: 'text/plain' }));
                });
              }),
            }),
          ];
          navigator.clipboard.write(copyData).then(
            () => {
              dispatch({ type: CommonTypes.POST_SHARE_SUCCESS });
              dispatch({
                type: AlertType.ALERT_SUCCESS,
                payload: { message: 'Link copied!' },
              });
            },
            e => {
              dispatch({ type: CommonTypes.POST_SHARE_ERROR });
              dispatch({
                type: AlertType.ALERT_ERROR,
                payload: { message: e },
              });
            },
          );
        }
        return res;
        // eslint-disable-next-line
      } else {
        const response: IResponseData = await client.post(url);
        if (response.data && Object.keys(response.data).length > 0) {
          const shareLink = response.data?.shareLink;
          if (navigator.clipboard) {
            navigator.clipboard.writeText(shareLink);
            dispatch({ type: CommonTypes.POST_SHARE_SUCCESS });
            dispatch({
              type: AlertType.ALERT_SUCCESS,
              payload: { message: 'Link copied!' },
            });
          }
        }
        return response;
      }
    } catch (error: unknown) {
      const err: IResponseData = error as IResponseData;
      dispatch({ type: CommonTypes.POST_SHARE_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: err.message },
      });
      return error;
    }
  };
};

export default getPostShareUrl;
