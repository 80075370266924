/* eslint-disable @typescript-eslint/no-non-null-assertion */
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import BusinessBanner from 'components/business/BusinessBanner';
import Loader from 'components/loader/Loader';
import GuestReactionView from 'components/reactions/GuestReactionView';
import TabPanel from 'components/tab/tabPanel';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { businessUrl } from 'helpers/urls';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  BusinessSetupSteps,
  followBusiness,
  getBusinessDetailsByBusinessId,
  resetBusinessWallState,
  unFollowBusiness,
} from 'store';
import getPostShareUrl from 'store/common/CommonActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import {
  checkPaidBusiness,
  isDeactivatedBusiness,
  isDeletedBusiness,
  isValidURL,
  scrollToTop,
} from 'utils/utils';

import BusinessMedia from './BusinessMedia';
import BusinessPosts from './BusinessPosts';
import CreateBusinessPost from './CreateBusinessPost';

enum BusinessUserRole {
  NONE = 'none',
  OWNER = 'owner',
}

function BusinessWall() {
  const dispatch = useAppDispatch();
  const { businessId } = useParams();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const { businessWallDetails, followLoading, loading } = useAppSelector(s => s.business);

  const { loading: shareLoading } = useAppSelector(s => s.common);

  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();

  useEffect(() => {
    scrollToTop();
    dispatch(getBusinessDetailsByBusinessId(businessId!));
    return () => {
      dispatch(resetBusinessWallState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId]);

  const changeFollowBusinessStatus = () => {
    if (isUserLoggedIn()) {
      if (businessWallDetails.isFollowing) {
        dispatch(unFollowBusiness(businessId!));
      } else {
        dispatch(followBusiness(businessId!));
      }
    } else {
      setShowModal(true);
    }
  };

  const shareBusiness = async () => {
    if (isUserLoggedIn()) {
      await dispatch(getPostShareUrl(`${businessUrl}business/${businessId}/share?type=wall`));
    } else {
      setShowModal(true);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const businessCategories = businessWallDetails.businessCategories?.map(x => x.name).join(', ');

  const isFreeBusiness = businessWallDetails?.step === BusinessSetupSteps.FREE_BUSINESS_LISTED;

  const isPaidBusiness = checkPaidBusiness(businessWallDetails);

  const isDeactivated = isDeactivatedBusiness(businessWallDetails);
  const isDeleted = isDeletedBusiness(businessWallDetails);

  return (
    <HomeWrapper maxWidth="midmd">
      <Stack spacing={theme.spacing(5)} width="100%">
        <Box
          border={`0.25px solid ${theme.palette.text.secondary}`}
          borderRadius="4px"
          width="100%"
        >
          {!loading ? (
            <>
              <BusinessBanner
                setHeightAuto
                backgroundImg={businessWallDetails?.images?.background?.croppedImageReadUrl}
                logoImg={businessWallDetails?.images?.logo?.croppedImageReadUrl}
              />
              <Box padding={{ xs: theme.spacing(2), sm: theme.spacing(3) }}>
                <Stack spacing={theme.spacing(0.5)} width="100%">
                  <Typography component="h4" variant="h4">
                    {businessWallDetails.name}
                  </Typography>
                  <Typography component="h6" variant="h6">
                    {businessWallDetails.tagline}
                  </Typography>
                  <Typography component="h6" variant="h6">
                    {businessCategories}
                  </Typography>
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    marginTop={`${theme.spacing(3)} !important`}
                  >
                    <Stack direction="row" spacing={theme.spacing(2)}>
                      {isPaidBusiness && businessWallDetails?.role !== 'owner' && (
                        <LoadingButton
                          disableRipple
                          disableTouchRipple
                          loading={followLoading}
                          variant="contained"
                          onClick={() => changeFollowBusinessStatus()}
                        >
                          {businessWallDetails.isFollowing ? 'Unfollow' : 'Follow'}
                        </LoadingButton>
                      )}
                      {(!isDeactivated || !isDeleted) && (
                        <LoadingButton
                          disableRipple
                          disableTouchRipple
                          loading={shareLoading}
                          variant="contained"
                          onClick={() => shareBusiness()}
                        >
                          Share
                        </LoadingButton>
                      )}

                      {(isPaidBusiness || isFreeBusiness) && businessWallDetails.website && (
                        <Button
                          disableRipple
                          disableTouchRipple
                          sx={{ borderRadius: '4px' }}
                          type="button"
                          variant="outlined"
                          onClick={() =>
                            window.open(
                              isValidURL(businessWallDetails.website)
                                ? businessWallDetails.website
                                : `//${businessWallDetails.website}`,
                              '_blank',
                            )
                          }
                        >
                          VISIT WEBSITE
                        </Button>
                      )}
                    </Stack>
                    {isPaidBusiness && (
                      <Stack
                        sx={{
                          ...(isMobile && {
                            width: '100%',
                            marginTop: theme.spacing(2),
                          }),
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: '4px',
                            border: `1px solid ${theme.palette.primary.main}`,
                            minWidth: '64px',
                            textAlign: 'center',
                            padding: theme.spacing(0.75, 3),
                            fontFamily: 'Inter',
                            letterSpacing: '0.02em',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            textTransform: 'uppercase',
                          }}
                        >
                          {businessWallDetails.followersCount} Follower(s)
                        </Box>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
                <Divider
                  sx={{
                    borderBottomWidth: '1px',
                    borderColor: theme.palette.text.secondary,
                    margin: theme.spacing(3, 0),
                  }}
                />

                {businessWallDetails.description && (
                  <Stack spacing={theme.spacing(1)}>
                    <Typography variant="title2">About</Typography>
                    <Typography component="h6" sx={{ overflowWrap: 'break-word' }} variant="h6">
                      {businessWallDetails.description}
                    </Typography>
                  </Stack>
                )}
              </Box>
            </>
          ) : (
            <Loader />
          )}
        </Box>
        {isPaidBusiness && (
          <>
            {businessWallDetails.role === BusinessUserRole.OWNER && (
              <ComponentWrapper isTitleInsideWrapper withDivider title="Create Content">
                <CreateBusinessPost businessId={businessId!} textContent="" type="create" />
              </ComponentWrapper>
            )}
            <ComponentWrapper title="">
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    aria-label="business tabs"
                    indicatorColor="secondary"
                    sx={{
                      '& .MuiTabs-indicator': {
                        height: '3px',
                      },
                    }}
                    textColor="secondary"
                    value={selectedTab}
                    onChange={handleTabChange}
                  >
                    <Tab label="POSTS" sx={{ fontWeight: 'bold' }} />
                    <Tab label="MEDIA" sx={{ fontWeight: 'bold' }} />
                  </Tabs>
                </Box>
                <TabPanel index={0} value={selectedTab}>
                  <BusinessPosts />
                </TabPanel>
                <TabPanel index={1} value={selectedTab}>
                  <BusinessMedia />
                </TabPanel>
              </Box>
            </ComponentWrapper>
          </>
        )}
      </Stack>

      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </HomeWrapper>
  );
}

export default BusinessWall;
