import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomInputField from 'components/customFields/CustomInputField';
import { memo } from 'react';
import { addSponsor, editSponsor, IAddSponsorState, IResponseData, ISponsor } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { addSponsorValidationSchema } from 'utils/validationSchema';

import useAddSponsorForm from './useAddSponsorForm';

interface IAddSponsorProps {
  closeModal: () => void;
  sponsor?: ISponsor;
}

function AddSponsor({ closeModal, sponsor }: IAddSponsorProps) {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { addSponsorLoading } = useAppSelector(s => s.vettingDashboard);

  const initialState: IAddSponsorState = {
    name: sponsor ? sponsor.name : '',
    url: sponsor ? sponsor.url : '',
  };

  const handelSubmit = async () => {
    if (sponsor) {
      const response: IResponseData = (await dispatch(
        editSponsor(values, sponsor.documentId),
      )) as IResponseData;
      if (!response.error) {
        closeModal();
      }
    } else {
      const response: IResponseData = (await dispatch(addSponsor(values))) as IResponseData;
      if (!response.error) {
        closeModal();
      }
    }
  };

  const formik = useAddSponsorForm(handelSubmit, initialState, addSponsorValidationSchema);
  const { touched, values, errors, handleChange, handleBlur, handleSubmit } = formik;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
  ) => {
    handleChange(fieldName)(e.target.value);
  };

  return (
    <Stack alignItems="center" justifyContent="center" width="100%">
      <Stack
        noValidate
        alignItems="center"
        autoComplete="off"
        component="form"
        spacing={theme.spacing(3)}
        width="100%"
        onSubmit={handleSubmit}
      >
        <CustomInputField
          fullWidth
          required
          error={!!(touched.name && errors.name)}
          errormessage={touched.name ? errors.name : undefined}
          id="name"
          label="Name"
          name="name"
          type="text"
          value={values.name}
          onBlur={handleBlur}
          onChange={e => handleInputChange(e, 'name')}
        />
        <CustomInputField
          fullWidth
          required
          error={!!(touched.url && errors.url)}
          errormessage={touched.url ? errors.url : undefined}
          id="url"
          label="URL"
          name="url"
          type="text"
          value={values.url}
          onBlur={handleBlur}
          onChange={e => handleInputChange(e, 'url')}
        />
        <Stack
          alignItems="center"
          direction={isMobile ? 'column' : 'row'}
          justifyContent="center"
          marginTop={`${theme.spacing(4)} !important`}
          spacing={theme.spacing(3)}
          width="100%"
        >
          <Button fullWidth size="large" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <LoadingButton
            disableRipple
            disableTouchRipple
            fullWidth
            disabled={!(formik.isValid && formik.dirty)}
            loading={addSponsorLoading}
            size="large"
            type="submit"
            variant="contained"
          >
            Submit
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default memo(AddSponsor);
