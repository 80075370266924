enum BusinessType {
  SAVE_BUSINESS_DATA_REQUEST = 'SAVE_BUSINESS_DATA_REQUEST',
  SAVE_BUSINESS_DATA_SUCCESS = 'SAVE_BUSINESS_DATA_SUCCESS',
  SAVE_BUSINESS_DATA_ERROR = 'SAVE_BUSINESS_DATA_ERROR',

  FETCH_BUSINESS_DETAILS_REQUEST = 'FETCH_BUSINESS_DETAILS_REQUEST',
  FETCH_BUSINESS_DETAILS_SUCCESS = 'FETCH_BUSINESS_DETAILS_SUCCESS',
  FETCH_BUSINESS_DETAILS_ERROR = 'FETCH_BUSINESS_DETAILS_ERROR',

  ADD_BUSINESS_IMAGE_REQUEST = 'ADD_BUSINESS_IMAGE_REQUEST',
  ADD_BUSINESS_IMAGE_SUCCESS = 'ADD_BUSINESS_IMAGE_SUCCESS',
  ADD_BUSINESS_IMAGE_ERROR = 'ADD_BUSINESS_IMAGE_ERROR',

  UPDATE_BUSINESS_IMAGE_REQUEST = 'UPDATE_BUSINESS_IMAGE_REQUEST',
  UPDATE_BUSINESS_IMAGE_SUCCESS = 'UPDATE_BUSINESS_IMAGE_SUCCESS',
  UPDATE_BUSINESS_IMAGE_ERROR = 'UPDATE_BUSINESS_IMAGE_ERROR',

  DELETE_BUSINESS_IMAGE_REQUEST = 'DELETE_BUSINESS_IMAGE_REQUEST',
  DELETE_BUSINESS_IMAGE_SUCCESS = 'DELETE_BUSINESS_IMAGE_SUCCESS',
  DELETE_BUSINESS_IMAGE_ERROR = 'DELETE_BUSINESS_IMAGE_ERROR',

  UPDATE_BUSINESS_DATA_REQUEST = 'UPDATE_BUSINESS_DATA_REQUEST',
  UPDATE_BUSINESS_DATA_SUCCESS = 'UPDATE_BUSINESS_DATA_SUCCESS',
  UPDATE_BUSINESS_DATA_ERROR = 'UPDATE_BUSINESS_DATA_ERROR',

  CREATE_BUSINESS_PAYMENT_REQUEST = 'CREATE_BUSINESS_PAYMENT_REQUEST',
  CREATE_BUSINESS_PAYMENT_SUCCESS = 'CREATE_BUSINESS_PAYMENT_SUCCESS',
  CREATE_BUSINESS_PAYMENT_ERROR = 'CREATE_BUSINESS_PAYMENT_ERROR',

  FETCH_FEATURED_BUSINESSES_REQUEST = 'FETCH_FEATURED_BUSINESSES_REQUEST',
  FETCH_FEATURED_BUSINESSES_SUCCESS = 'FETCH_FEATURED_BUSINESSES_SUCCESS',
  FETCH_FEATURED_BUSINESSES_ERROR = 'FETCH_FEATURED_BUSINESSES_ERROR',

  FETCH_RECENT_BUSINESSES_REQUEST = 'FETCH_RECENT_BUSINESSES_REQUEST',
  FETCH_RECENT_BUSINESSES_SUCCESS = 'FETCH_RECENT_BUSINESSES_SUCCESS',
  FETCH_RECENT_BUSINESSES_ERROR = 'FETCH_RECENT_BUSINESSES_ERROR',

  FETCH_ALL_BUSINESSES_REQUEST = 'FETCH_ALL_BUSINESSES_REQUEST',
  FETCH_ALL_BUSINESSES_SUCCESS = 'FETCH_ALL_BUSINESSES_SUCCESS',
  FETCH_ALL_BUSINESSES_ERROR = 'FETCH_ALL_BUSINESSES_ERROR',

  RESET_BUSINESS_DIRECTORY_STATE = 'RESET_BUSINESS_DIRECTORY_STATE',

  FOLLOW_BUSINESS_REQUEST = 'FOLLOW_BUSINESS_REQUEST',
  FOLLOW_BUSINESS_SUCCESS = 'FOLLOW_BUSINESS_SUCCESS',
  FOLLOW_BUSINESS_ERROR = 'FOLLOW_BUSINESS_ERROR',

  UNFOLLOW_BUSINESS_REQUEST = 'UNFOLLOW_BUSINESS_REQUEST',
  UNFOLLOW_BUSINESS_SUCCESS = 'UNFOLLOW_BUSINESS_SUCCESS',
  UNFOLLOW_BUSINESS_ERROR = 'UNFOLLOW_BUSINESS_ERROR',

  PAYMENT_EXISTING_CARD_REQUEST = 'PAYMENT_EXISTING_CARD_REQUEST',
  PAYMENT_EXISTING_CARD_SUCCESS = 'PAYMENT_EXISTING_CARD_SUCCESS',
  PAYMENT_EXISTING_CARD_ERROR = 'PAYMENT_EXISTING_CARD_ERROR',

  GET_STATES_REQUEST = 'GET_STATES_REQUEST',
  GET_STATES_SUCCESS = 'GET_STATES_SUCCESS',
  GET_STATES_ERROR = 'GET_STATES_ERROR',

  GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR',

  FETCH_BUSINESS_DETAILS_BY_BUSINESSID_REQUEST = 'FETCH_BUSINESS_DETAILS_BY_BUSINESSID_REQUEST',
  FETCH_BUSINESS_DETAILS_BY_BUSINESSID_SUCCESS = 'FETCH_BUSINESS_DETAILS_BY_BUSINESSID_SUCCESS',
  FETCH_BUSINESS_DETAILS_BY_BUSINESSID_ERROR = 'FETCH_BUSINESS_DETAILS_BY_BUSINESSID_ERROR',

  FETCH_BUSINESS_POSTS_REQUEST = 'FETCH_BUSINESS_POSTS_REQUEST',
  FETCH_BUSINESS_POSTS_SUCCESS = 'FETCH_BUSINESS_POSTS_SUCCESS',
  FETCH_BUSINESS_POSTS_ERROR = 'FETCH_BUSINESS_POSTS_ERROR',

  CREATE_BUSINESS_POST_REQUEST = 'CREATE_BUSINESS_POST_REQUEST',
  CREATE_BUSINESS_POST_SUCCESS = 'CREATE_BUSINESS_POST_SUCCESS',
  CREATE_BUSINESS_POST_ERROR = 'CREATE_BUSINESS_POST_ERROR',

  EDIT_BUSINESS_POST_REQUEST = 'EDIT_BUSINESS_POST_REQUEST',
  EDIT_BUSINESS_POST_SUCCESS = 'EDIT_BUSINESS_POST_SUCCESS',
  EDIT_BUSINESS_POST_ERROR = 'EDIT_BUSINESS_POST_ERROR',

  DELETE_BUSINESS_POST_REQUEST = 'DELETE_BUSINESS_POST_REQUEST',
  DELETE_BUSINESS_POST_SUCCESS = 'DELETE_BUSINESS_POST_SUCCESS',
  DELETE_BUSINESS_POST_ERROR = 'DELETE_BUSINESS_POST_ERROR',

  FETCH_BUSINESS_MEDIA_REQUEST = 'FETCH_BUSINESS_MEDIA_REQUEST',
  FETCH_BUSINESS_MEDIA_SUCCESS = 'FETCH_BUSINESS_MEDIA_SUCCESS',
  FETCH_BUSINESS_MEDIA_ERROR = 'FETCH_BUSINESS_MEDIA_ERROR',

  RESET_BUSINESS_WALL_STATE = 'RESET_BUSINESS_WALL_STATE',
  RESET_BUSINESS_MEDIA_STATE = 'RESET_BUSINESS_MEDIA_STATE',
  RESET_BUSINESS_POSTS_STATE = 'RESET_BUSINESS_POSTS_STATE',

  FETCH_BUSINESS_POST_DETAILS_REQUEST = 'FETCH_BUSINESS_POST_DETAILS_REQUEST',
  FETCH_BUSINESS_POST_DETAILS_SUCCESS = 'FETCH_BUSINESS_POST_DETAILS_SUCCESS',
  FETCH_BUSINESS_POST_DETAILS_ERROR = 'FETCH_BUSINESS_POST_DETAILS_ERROR',

  RESET_BUSINESS_POST_DETAILS_STATE = 'RESET_BUSINESS_POST_DETAILS_STATE',

  LIKE_DISLIKE_BUSINESS_POST_REQUEST = 'LIKE_DISLIKE_BUSINESS_POST_REQUEST',
  LIKE_DISLIKE_BUSINESS_POST_SUCCESS = 'LIKE_DISLIKE_BUSINESS_POST_SUCCESS',
  LIKE_DISLIKE_BUSINESS_POST_ERROR = 'LIKE_DISLIKE_BUSINESS_POST_ERROR',

  SET_FILTER_BUSINESSES_CATEGORIES = 'SET_FILTER_BUSINESSES_CATEGORIES',
  RESET_FILTER_BUSINESSES_CATEGORIES = 'RESET_FILTER_BUSINESSES_CATEGORIES',

  BUSINESS_LIKE_DISLIKE_BUSINESS_POST_SUCCESS = 'BUSINESS_LIKE_DISLIKE_BUSINESS_POST_SUCCESS',
  RESET_BUSINESS_DETAILS_STATE = 'RESET_BUSINESS_DETAILS_STATE',
  UPDATE_COMMENT_COUNT = 'UPDATE_COMMENT_COUNT_BUSINESS',
  BUSINESS_UPDATE_POST_CONTENT = 'BUSINESS_UPDATE_POST_CONTENT',
}

export default BusinessType;
