import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import React, { useEffect, useState } from 'react';
import { followNewsProviders, getAllNewsProviders, IProvider, IResponseData } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { compareArrayObjects, getNewsProviderLogo } from 'utils/utils';

import SavePreferencesBtn from '../SavePreferencesBtn';

interface IProps {
  searchQuery: string;
}

function News({ searchQuery }: IProps) {
  const dispatch = useAppDispatch();
  const { allNewsProvidersLoading, allNewsProviders, followNewsProvidersLoading } = useAppSelector(
    s => s.followingTimeline,
  );
  const [newsProviders, setNewsProviders] = useState<IProvider[]>(
    allNewsProviders.length > 0 ? allNewsProviders : [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const fetchAllNewsProviders = async () => {
    const response = (await dispatch(getAllNewsProviders())) as IResponseData;
    if (!response.error) {
      setNewsProviders(response.data);
    }
  };

  const handleCheck = (index: number) => {
    const updatedState: IProvider[] = newsProviders.map((n: IProvider, i: number) => {
      if (index === i) {
        return { ...n, isFollowed: !n.isFollowed };
      }
      return n;
    });
    setNewsProviders(updatedState);
  };

  const handleDisable = () => {
    return compareArrayObjects(newsProviders, allNewsProviders, 'isFollowed');
  };

  const saveSelectedPodcasts = async () => {
    const newsProviderIds = newsProviders?.reduce((a: string[], c: IProvider) => {
      if (c.isFollowed) {
        a.push(c.documentID);
      }
      return a;
    }, []);
    await dispatch(followNewsProviders(newsProviderIds, newsProviders));
  };

  const filteredData = newsProviders.filter(
    (newsProvider: IProvider, index: number, arr: IProvider[]) => {
      if (searchQuery.length >= 3) {
        return newsProvider.name.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return arr;
    },
  );

  useEffect(() => {
    if (allNewsProviders.length === 0) {
      setIsLoading(true);
      fetchAllNewsProviders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allNewsProvidersLoading !== isLoading) {
      setIsLoading(allNewsProvidersLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNewsProvidersLoading]);

  return (
    <>
      <Stack
        className="custom-pref-scrollbar"
        height={isMobile ? 'calc(100vh - 275px)' : '100%'}
        paddingBottom={theme.spacing(1)}
        paddingRight={theme.spacing(2)}
        spacing={theme.spacing(1.5)}
        sx={{
          overflowY: 'auto',
        }}
      >
        <>
          {isLoading ? (
            <Loader customPaddingThemeSpacing={2} size={30} />
          ) : filteredData && filteredData.length > 0 ? (
            <>
              {filteredData.map((newsProvider: IProvider, index: number) => {
                return (
                  <Stack
                    key={newsProvider.documentID + index.toString()}
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Stack alignItems="center" direction="row">
                      <Avatar
                        alt={newsProvider.name ?? 'provider-logo'}
                        src={getNewsProviderLogo(newsProvider.name)}
                        sx={{
                          width: theme.spacing(3),
                          height: theme.spacing(2.75),
                          background: theme.palette.secondary.main,
                          marginRight: theme.spacing(2),
                        }}
                        variant="square"
                      />
                      <Typography className="text-ellipsis text-clamp-2">
                        {newsProvider.name}
                      </Typography>
                    </Stack>
                    <IconButton
                      aria-label="remove"
                      color="primary"
                      size="medium"
                      sx={{ padding: theme.spacing(0) }}
                      onClick={() => handleCheck(newsProviders.indexOf(newsProvider))}
                    >
                      {newsProvider.isFollowed ? (
                        <CheckCircleIcon fontSize="inherit" />
                      ) : (
                        <ControlPointIcon fontSize="inherit" />
                      )}
                    </IconButton>
                  </Stack>
                );
              })}
            </>
          ) : (
            !allNewsProvidersLoading &&
            filteredData.length === 0 && (
              <Stack alignItems="center" flexGrow={1} height="100%" justifyContent="center">
                <Typography align="center" component="div" variant="title2">
                  No news providers found
                </Typography>
              </Stack>
            )
          )}
        </>
      </Stack>
      <SavePreferencesBtn
        disableFn={handleDisable}
        loading={followNewsProvidersLoading}
        onSave={saveSelectedPodcasts}
      />
    </>
  );
}

export default News;
