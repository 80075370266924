import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from 'theme';

type IGuestReactionsViewProps = {
  isMobile: boolean;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function GuestReactionView(props: IGuestReactionsViewProps) {
  const { isMobile, setShowModal, showModal } = props;

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ToggleDrawerDialog
      showCloseIcon
      handleClose={() => setShowModal(false)}
      isMobile={isMobile}
      open={showModal}
      setOpen={setShowModal}
      title="Login / SignUp"
    >
      <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(4)}>
        <Typography component="h6" variant={isMobile ? 'h6' : 'title2'}>
          Join the conversation
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={theme.spacing(2)}
        >
          <Button
            disableRipple
            disableTouchRipple
            size="large"
            type="button"
            variant="contained"
            onClick={() => navigate('/login', { state: { redirectTo: location } })}
          >
            Login
          </Button>
          <Button
            disableRipple
            disableTouchRipple
            size="large"
            sx={{ whiteSpace: 'nowrap' }}
            type="button"
            variant="contained"
            onClick={() => navigate('/signup', { state: { redirectTo: location } })}
          >
            Sign Up
          </Button>
        </Stack>
      </Stack>
    </ToggleDrawerDialog>
  );
}
