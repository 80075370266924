import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import ProfileDetailsForm from 'components/profile/ProfileDetailsForm';
import useProfileInformationForm from 'components/profile/useProfileInformationForm';
import { IUserProfileInfoState, updateUserProfileInfo } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { profileEditInformationSchema } from 'utils/validationSchema';

function ProfileDetails() {
  const dispatch = useAppDispatch();

  const { userData, isUpdateProfileInfoLoading } = useAppSelector(s => s.account);

  const profileInfoInitialState: IUserProfileInfoState = {
    fullName: userData?.fullName ?? '',
    tagline: userData?.tagline ?? '',
    description: userData?.description ?? '',
  };

  const handleUpdateProfileDetails = async (values: IUserProfileInfoState) => {
    const requestData = {
      fullName: values.fullName,
      tagline: values.tagline,
      description: values.description,
    };

    if (dirty) {
      await dispatch(updateUserProfileInfo(requestData));
    }
  };

  const formik = useProfileInformationForm(
    handleUpdateProfileDetails,
    profileInfoInitialState,
    profileEditInformationSchema,
  );

  const { touched, values, errors, dirty, handleChange, handleBlur, handleSubmit, resetForm } =
    formik;

  return (
    <Box noValidate component="form" onSubmit={handleSubmit}>
      <ProfileDetailsForm
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched}
        values={values}
      />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={theme.spacing(2)}
        sx={{ mt: theme.spacing(4) }}
      >
        <Button
          disableRipple
          disableTouchRipple
          fullWidth
          size="large"
          type="button"
          variant="outlined"
          onClick={() => resetForm()}
        >
          Cancel
        </Button>
        <LoadingButton
          fullWidth
          loading={isUpdateProfileInfoLoading}
          size="large"
          type="submit"
          variant="contained"
        >
          Save
        </LoadingButton>
      </Stack>
    </Box>
  );
}

export default ProfileDetails;
