import { FormikHelpers, useFormik } from 'formik';
import { IChangePasswordState } from 'store';

const useChangePasswordForm = (
  onSubmit: (
    values: IChangePasswordState,
    formikHelpers: FormikHelpers<IChangePasswordState>,
  ) => void | Promise<unknown>,
  initialValues: IChangePasswordState,
  validationSchema: any,
) => {
  return useFormik<IChangePasswordState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export default useChangePasswordForm;
