import { FormikHelpers, useFormik } from 'formik';
import { IAddAdsState } from 'store';

const useAddAdsForm = (
  onSubmit: (
    values: IAddAdsState,
    formikHelpers: FormikHelpers<IAddAdsState>,
  ) => void | Promise<unknown>,
  initialValues: IAddAdsState,
  validationSchema: any,
) => {
  return useFormik<IAddAdsState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export default useAddAdsForm;
