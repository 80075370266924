import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { CloseIcon, SearchIcon } from 'helpers/images';
import theme from 'theme';

const Search = styled('div')(() => ({
  position: 'relative',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  borderRadius: theme.spacing(3.275),
  backgroundColor: theme.palette.common.white,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  width: '100%',
}));

const SearchIconWrapper = styled('div')(() => ({
  marginRight: theme.spacing(2),
  height: '100%',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const CloseIconWrapper = styled(IconButton)(() => ({
  marginLeft: theme.spacing(1),
  '& svg': {
    height: theme.spacing(1.75),
    width: theme.spacing(1.75),
  },
}));

const StyledInputBase = styled(InputBase)(() => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0),
    transition: theme.transitions.create('width'),
    width: '100%',
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.02em',
    lineHeight: '20px',
  },
}));

interface ISearchInputProps extends InputBaseProps {
  onClear: () => void;
  value: string;
}

function SearchInputTextField(props: ISearchInputProps) {
  const { value, onChange, onClear, placeholder = 'Search for topics or businesses' } = props;
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase placeholder={placeholder} type="search" value={value} onChange={onChange} />
      {value.length > 0 && (
        <CloseIconWrapper onClick={onClear}>
          <CloseIcon />
        </CloseIconWrapper>
      )}
    </Search>
  );
}

export default SearchInputTextField;
