import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import Loader from 'components/loader/Loader';
import { StyledTableCell, StyledTableRow } from 'components/table/StyledTable';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import { useEffect, useState } from 'react';
import { getInfluencersList, getInfluencersListFile } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { downloadCsv } from 'utils/utils';

import DateRange, { IFilters } from './DateRange';

function Influencers() {
  const dispatch = useAppDispatch();

  const { influencers, influencersLoading } = useAppSelector(s => s.vettingDashboard);

  const [csvLoader, showCsvLoader] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilters>();

  useEffect(() => {
    fetchInfluencers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInfluencers = () => {
    dispatch(getInfluencersList());
  };

  const downloadAsCSV = async () => {
    const csvFileName = 'influencers';
    showCsvLoader(true);
    const csvData = (await getInfluencersListFile()) as string;

    if (csvData && csvFileName) {
      downloadCsv(csvData, csvFileName);
      showCsvLoader(false);
    }
  };

  const isDownloadBtnDisabled = influencers?.length === 0 || csvLoader;

  return (
    <>
      <Box paddingBottom={2}>
        <DateRange
          isDownloadCSV
          csvLoader={csvLoader}
          isDisabled={isDownloadBtnDisabled}
          showDateRange={false}
          showSearch={false}
          onChangeFilter={setFilters}
          onDownloadCSV={downloadAsCSV}
        />
      </Box>
      <ComponentWrapper borderColor="light" title="" withInfiniteScroll={false} withPadding={false}>
        <Stack
          alignItems="center"
          borderBottom={`0.25px solid ${theme.palette.divider}`}
          direction="row"
          padding={theme.spacing(3.65, 4, 3)}
          spacing={theme.spacing(1.5)}
        >
          <Typography color="text.secondary" variant="title1">
            Influencers
          </Typography>
        </Stack>
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>User Name</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!influencersLoading &&
                influencers.map((row, index) => (
                  <StyledTableRow key={`${row.userId}_${index.toString()}`}>
                    <StyledTableCell component="th" scope="row">
                      {row.username}
                    </StyledTableCell>

                    <StyledTableCell>{row.email}</StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {influencersLoading && (
          <Box boxSizing="border-box" padding={theme.spacing(2, 0)} width="100%">
            <Loader />
          </Box>
        )}
        {!influencersLoading && influencers.length === 0 && (
          <Box boxSizing="border-box" padding={theme.spacing(4)} width="100%">
            <Typography align="center">No records found</Typography>
          </Box>
        )}
      </ComponentWrapper>
    </>
  );
}

export default Influencers;
