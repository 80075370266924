import client from 'Api';
import { affiliateUrl, authUrl, paymentUrl } from 'helpers/urls';
import { Dispatch } from 'redux';
import { AlertActions, IAlertPayload } from 'store/alerts/AlertInterface';
import { AlertType } from 'store/alerts/AlertTypes';
import { IResponseData } from 'store/common/CommonInterface';
import { CookieNames, getCookieItem } from 'utils/cookies';

import {
  AffiliateActions,
  IAffiliateCountInfo,
  IAffiliatePaymentAccountDetails,
  IAffiliatePaymentState,
  ICreateAffiliateLinkPayload,
} from './AffiliateInterface';
import AffiliateType from './AffiliateTypes';

export const createAffiliateLink = () => {
  return async (dispatch: Dispatch<AffiliateActions | AlertActions>) => {
    try {
      dispatch({ type: AffiliateType.CREATE_AFFILIATE_LINK_REQUEST });
      const response: IResponseData = await client.post(`${affiliateUrl}links`);
      dispatch({
        type: AffiliateType.CREATE_AFFILIATE_LINK_SUCCESS,
        payload: response.data as ICreateAffiliateLinkPayload,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: AffiliateType.CREATE_AFFILIATE_LINK_ERROR });
      return error;
    }
  };
};

export const linkAffiliate = (affiliateUserId: string) => {
  return async (dispatch: Dispatch<AffiliateActions | AlertActions>) => {
    try {
      dispatch({ type: AffiliateType.LINK_AFFILIATE_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}users/affiliate`, {
        affiliateUserId,
      });
      dispatch({ type: AffiliateType.LINK_AFFILIATE_SUCCESS });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: AffiliateType.LINK_AFFILIATE_ERROR });
      return error;
    }
  };
};

export const addAffiliatePayment = (data: IAffiliatePaymentState) => {
  return async (dispatch: Dispatch<AffiliateActions | AlertActions>) => {
    try {
      dispatch({ type: AffiliateType.ADD_AFFILIATE_PAYMENT_REQUEST });
      const response: IResponseData = await client.post(`${paymentUrl}ach`, {
        ...data,
      });
      dispatch({
        type: AffiliateType.ADD_AFFILIATE_PAYMENT_SUCCESS,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: AffiliateType.ADD_AFFILIATE_PAYMENT_ERROR });
      return error;
    }
  };
};

export const getAffiliatePayment = () => {
  return async (dispatch: Dispatch<AffiliateActions | AlertActions>) => {
    try {
      dispatch({ type: AffiliateType.GET_AFFILIATE_PAYMENT_REQUEST });
      const response: IResponseData = await client.get(`${paymentUrl}ach`);
      dispatch({
        type: AffiliateType.GET_AFFILIATE_PAYMENT_SUCCESS,
        payload: (response.data && response.data?.length > 0
          ? response.data?.[0]
          : {}) as IAffiliatePaymentAccountDetails,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: AffiliateType.GET_AFFILIATE_PAYMENT_ERROR });
      return error;
    }
  };
};

export const updateAffiliatePayment = (id: string, data: IAffiliatePaymentState) => {
  return async (dispatch: Dispatch<AffiliateActions | AlertActions>) => {
    try {
      dispatch({ type: AffiliateType.UPDATE_AFFILIATE_PAYMENT_REQUEST });
      const response: IResponseData = await client.put(`${paymentUrl}ach/${id}`, { ...data });
      dispatch({
        type: AffiliateType.UPDATE_AFFILIATE_PAYMENT_SUCCESS,
        payload: response.data as IAffiliatePaymentAccountDetails,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: AffiliateType.UPDATE_AFFILIATE_PAYMENT_ERROR });
      return error;
    }
  };
};

export const deleteAffiliatePayment = (id: string) => {
  return async (dispatch: Dispatch<AffiliateActions | AlertActions>) => {
    try {
      dispatch({ type: AffiliateType.DELETE_AFFILIATE_PAYMENT_REQUEST });
      const response: IResponseData = await client.delete(`${paymentUrl}ach/${id}`);
      dispatch({ type: AffiliateType.DELETE_AFFILIATE_PAYMENT_SUCCESS });
      dispatch({
        type: AffiliateType.GET_AFFILIATE_EXISTS_SUCCESS,
        payload: { status: 'deleted' },
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: AffiliateType.DELETE_AFFILIATE_PAYMENT_ERROR });
      return error;
    }
  };
};

export const addAffiliateClickEvent = (id: string) => {
  return async (dispatch: Dispatch<AffiliateActions | AlertActions>) => {
    try {
      dispatch({ type: AffiliateType.ADD_CLICK_EVENT_REQUEST });
      const response: IResponseData = await client.post(`${affiliateUrl}clicks`, {
        affiliateUserId: id,
        type: 'web',
      });
      dispatch({ type: AffiliateType.ADD_CLICK_EVENT_SUCCESS });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: AffiliateType.ADD_AFFILIATE_PAYMENT_ERROR });
      return error;
    }
  };
};

export const getAffiliateCountInfo = (from: string, to: string) => {
  return async (dispatch: Dispatch<AffiliateActions | AlertActions>) => {
    try {
      const userId = getCookieItem(CookieNames.USER_ID);
      dispatch({ type: AffiliateType.GET_AFFILIATE_COUNTS_REQUEST });
      const response: IResponseData = await client.get(
        `${affiliateUrl}${userId}?from=${from}&to=${to}`,
      );
      dispatch({
        type: AffiliateType.GET_AFFILIATE_COUNTS_SUCCESS,
        payload: response.data as IAffiliateCountInfo,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: AffiliateType.GET_AFFILIATE_COUNTS_ERROR });
      return error;
    }
  };
};

export const getAffiliateExists = () => {
  return async (dispatch: Dispatch<AffiliateActions | AlertActions>) => {
    try {
      dispatch({ type: AffiliateType.GET_AFFILIATE_EXISTS_REQUEST });
      const response: IResponseData = await client.get(`${paymentUrl}ach/exists`);
      dispatch({
        type: AffiliateType.GET_AFFILIATE_EXISTS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: AffiliateType.GET_AFFILIATE_EXISTS_ERROR });
      return error;
    }
  };
};

export const getAffiliatesListFile = async (
  type = 'affiliates',
  fromDate = '',
  toDate = '',
): Promise<string> => {
  try {
    const queryParam = fromDate && toDate ? `&from=${fromDate}&to=${toDate}` : '';
    const response: string = await client.get(`${affiliateUrl}listFile?type=${type}${queryParam}`);
    return response;
  } catch (error: any) {
    return error;
  }
};
