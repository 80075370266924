import { Button, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import TabPanel from 'components/tab/tabPanel';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { useEffect, useState } from 'react';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

import AffiliateReport from './AffiliateReport';
import AllSponsorsReport from './AllSponsorsReport';
import ApprovedBusinessReport from './ApprovedBusinessReport';
import Influencers from './Influencers';
import MembersReport from './MembersReport';
import PayoutReport from './PayoutReport';
import Providers from './Providers';
import SponsorsAds from './sponsorAds';

const TABS = [
  // { label: "Pending Business", id: "businessReport" },
  { label: 'Business Report', id: 'businessReport' },
  { label: 'Member Report', id: 'memberReport' },
  { label: 'Affiliate Referral Report', id: 'affiliateReferralReport' },
  { label: 'Payout Report', id: 'payoutReport' },
  { label: 'Sponsors Report', id: 'sponsorsReport' },
  { label: 'Sponsors List', id: 'sponsorsList' },
  { label: 'Influencers', id: 'influencers' },
  { label: 'Providers', id: 'providers' },
] as const;

export type TabKey = (typeof TABS)[number]['id'];

export default function VettingDashboard() {
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleTabChange = (_event: unknown, newValue: number) => {
    setSelectedTab(newValue);
  };

  const loginToHive = () => {
    const url = `https://thehive.ai/login`;
    window.open(url, 'redirect');
  };

  return (
    <HomeWrapper>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography
          component="h3"
          marginBottom={theme.spacing(isMobile ? 2 : 3)}
          variant={isMobile ? 'h5' : 'h3'}
        >
          Admin Dashboard
        </Typography>
        <Button size="large" variant="contained" onClick={loginToHive}>
          Login to hive
        </Button>
      </Stack>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            allowScrollButtonsMobile
            scrollButtons
            aria-label="admin-dashboard-tabs"
            sx={{
              '& .MuiButtonBase-root': {
                '&.MuiTab-root': {
                  px: 0,
                  marginRight: theme.spacing(4),
                  color: theme.palette.text.tertiary,
                  textTransform: 'capitalize',
                  fontSize: isMobile ? '14px' : '18px',
                  fontWeight: 500,
                  lineHeight: '24px',
                },
                '&.MuiTab-root.Mui-selected': {
                  color: `${theme.palette.text.secondary} !important`,
                },
              },
              '& .MuiTabs-indicator': {
                height: theme.spacing(0.25),
                backgroundColor: `${theme.palette.text.secondary} !important`,
              },
            }}
            value={selectedTab}
            variant="scrollable"
            onChange={handleTabChange}
          >
            {TABS.map(tab => {
              return <Tab key={tab.id} label={tab.label} sx={{ fontWeight: 'bold' }} />;
            })}
          </Tabs>
        </Box>
        {/* <TabPanel index={0} value={selectedTab}>
          <PendingBusiness />
        </TabPanel> */}
        <TabPanel index={0} value={selectedTab}>
          <ApprovedBusinessReport />
        </TabPanel>
        <TabPanel index={1} value={selectedTab}>
          <MembersReport />
        </TabPanel>
        <TabPanel index={2} value={selectedTab}>
          <AffiliateReport />
        </TabPanel>
        <TabPanel index={3} value={selectedTab}>
          <PayoutReport />
        </TabPanel>
        <TabPanel index={4} value={selectedTab}>
          <AllSponsorsReport />
        </TabPanel>
        <TabPanel index={5} value={selectedTab}>
          <SponsorsAds />
        </TabPanel>
        <TabPanel index={6} value={selectedTab}>
          <Influencers />
        </TabPanel>
        <TabPanel index={7} value={selectedTab}>
          <Providers />
        </TabPanel>
      </Box>
    </HomeWrapper>
  );
}
