import { authUrl, businessUrl, newsUrl, podcastUrl } from 'helpers/urls';

import { IGetCommentsParams, IGetUrlParams } from './CommentInterface';

export function getRepliesUrl(params: IGetCommentsParams) {
  const { contentId, type, offset, limit = 10 } = params || {};

  switch (type) {
    case 'business':
      return `${businessUrl}content/comment/${contentId}?limit=${limit}&offset=${offset}`;
    case 'podcast':
      return `${podcastUrl}episode/comment/${contentId}?limit=${limit}&offset=${offset}`;
    case 'article':
      return `${newsUrl}users/news/comment/${contentId}?limit=${limit}&offset=${offset}`;
    case 'profile':
      return `${authUrl}content/comment/${contentId}?limit=${limit}&offset=${offset}`;
    default:
      return '';
  }
}

export function getCommentsUrl(params: IGetCommentsParams) {
  const { contentId, type, offset, limit = 10 } = params || {};

  switch (type) {
    case 'business':
      return `${businessUrl}content/${contentId}/comment?limit=${limit}&offset=${offset}`;
    case 'podcast':
      return `${podcastUrl}episode/${contentId}/comment?limit=${limit}&offset=${offset}`;
    case 'article':
      return `${newsUrl}users/news/${contentId}/comments?limit=${limit}&offset=${offset}`;
    case 'profile':
      return `${authUrl}content/${contentId}/comment?limit=${limit}&offset=${offset}`;
    default:
      return '';
  }
}

export function getAddCommentUrl(params: IGetUrlParams) {
  const { contentId, type } = params || {};

  switch (type) {
    case 'business':
      return `${businessUrl}content/${contentId}/comment`;
    case 'podcast':
      return `${podcastUrl}episode/${contentId}/comment`;
    case 'article':
      return `${newsUrl}users/news/${contentId}/comment`;
    case 'profile':
      return `${authUrl}content/${contentId}/comment`;
    default:
      return '';
  }
}

export function getEditCommentUrl(params: IGetUrlParams) {
  const { type, commentId } = params || {};

  switch (type) {
    case 'business':
      return `${businessUrl}content/comment/${commentId}`;
    case 'podcast':
      return `${podcastUrl}episode/comment/${commentId}`;
    case 'article':
      return `${newsUrl}users/news/comment/${commentId}`;
    case 'profile':
      return `${authUrl}content/comment/${commentId}`;
    default:
      return '';
  }
}

export function getDeleteUrl(params: IGetUrlParams) {
  const { type, commentId } = params || {};

  switch (type) {
    case 'business':
      return `${businessUrl}content/comment/${commentId}`;
    case 'podcast':
      return `${podcastUrl}episode/comment/${commentId}`;
    case 'article':
      return `${newsUrl}users/news/comment/${commentId}`;
    case 'profile':
      return `${authUrl}content/comment/${commentId}`;
    default:
      return '';
  }
}

export function getLikeDisLikeUrl(params: IGetUrlParams) {
  const { type, commentId } = params || {};

  switch (type) {
    case 'business':
      return `${businessUrl}content/comment/${commentId}/like`;
    case 'podcast':
      return `${podcastUrl}episode/comment/${commentId}/like`;
    case 'article':
      return `${newsUrl}users/comment/${commentId}/like`;
    case 'profile':
      return `${authUrl}content/comment/${commentId}/like`;
    default:
      return '';
  }
}

export function getUserReportUrl(params: IGetUrlParams) {
  const { type } = params || {};

  switch (type) {
    case 'business':
    case 'business-wall':
      return `${businessUrl}content/report`;
    case 'podcast':
      return `${podcastUrl}report`;
    case 'article':
      return `${newsUrl}report`;
    case 'profile':
      return `${authUrl}content/report`;
    default:
      return '';
  }
}
