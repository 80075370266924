import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import NoDataAvailable from 'components/notFound/NoDataAvailable';
import GuestReactionView from 'components/reactions/GuestReactionView';
import AdvertisementTile from 'components/tile/AdvertisementTile';
import PostTile from 'components/tile/PostTile';
import Tile from 'components/tile/Tile';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import { Fragment, useState } from 'react';
import { fetchAdsByPosition } from 'store';
import { AdsPages } from 'store/advertisements/AdvertisementInterface';
import { getTimelinePosts } from 'store/homeTimeline/HomeTimelineActions';
import { ITimelinePost } from 'store/homeTimeline/HomeTimelineInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

function HeadlinesPosts() {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showModal, setShowModal] = useState<boolean>(false);
  const {
    isGetHeadlinesPostsLoading,
    headlines: { posts, currentCount },
  } = useAppSelector(s => s.homeTimeline);

  const hasMoreData = currentCount === 10;

  const getHeadlines = () => {
    dispatch(getTimelinePosts(posts.length, 10, false));
    dispatch(fetchAdsByPosition(AdsPages.HOME, 'C'));
  };

  return (
    <>
      <ComponentWrapper
        isTitleInsideWrapper
        withInfiniteScroll
        infiniteScrollProps={{
          loadOnMount: true,
          hasMoreData,
          isLoading: isGetHeadlinesPostsLoading,
          onBottomHit: getHeadlines,
        }}
        title="View All"
      >
        {posts?.length > 0 ? (
          <>
            {posts.map((post: ITimelinePost, index: number) => {
              return (
                <Fragment key={(post?.documentId as string) + index.toString()}>
                  {['paid-member-post', 'business', 'town-crier-post'].includes(post?.type) ? (
                    <PostTile
                      componentType="home"
                      post={post}
                      type="all-home-business"
                      withDivider={(index + 1) % 5 === 0 ? false : !(posts?.length === index + 1)}
                    />
                  ) : (
                    <Tile
                      key={(post?.documentId as string) + index.toString()}
                      commentsCount={post?.commentsCount}
                      componentType="home"
                      description={post?.description}
                      id={post?.postId}
                      image={post?.thumbnail as string}
                      isFeatured={post?.isFeatured}
                      isLiked={post?.isLiked}
                      likesCount={post?.likesCount}
                      name={post?.postProvider}
                      postedAt={post?.postedAt as string}
                      providerId={post?.postProviderId}
                      providerLogo={post?.postProviderLogo}
                      tileType={isMobile ? 'small' : 'large'}
                      title={post?.title}
                      type={post?.type}
                      withDivider={(index + 1) % 5 === 0 ? false : !(posts?.length === index + 1)}
                    />
                  )}
                  {(index + 1) % 5 === 0 && (
                    <AdvertisementTile
                      page={AdsPages.HOME}
                      position="C"
                      uniqueKey={index + 1}
                      useCommonData={false}
                    />
                  )}
                </Fragment>
              );
            })}
          </>
        ) : (
          !isGetHeadlinesPostsLoading && <NoDataAvailable />
        )}
        {isGetHeadlinesPostsLoading && <Loader />}
      </ComponentWrapper>
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </>
  );
}

export default HeadlinesPosts;
