import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';

import CustomInputField, { ICustomInputFieldProps } from './CustomInputField';

function CustomPhoneInput(props: ICustomInputFieldProps) {
  const { value, onChange } = props;

  const debouncedOnChange = useRef(onChange ? debounce(onChange, 2000) : undefined);

  const [rawValue, setRawValue] = useState<string>(value as string);

  useEffect(() => {
    if (value !== rawValue) {
      setRawValue(value as string);
    }
  }, [value]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setRawValue(e.target.value);
    debouncedOnChange.current?.(e);
  };

  return <CustomInputField {...props} value={rawValue} onChange={handleChange} />;
}

export default CustomPhoneInput;
