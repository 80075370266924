import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { PreferenceAccordionTypes, resetFollowingPreferencesState } from 'store';
import { useAppDispatch } from 'store/hooks';
import theme from 'theme';

import Businesses from './accordionContainers/Businesses';
import News from './accordionContainers/News';
import Podcasts from './accordionContainers/Podcasts';
import Users from './accordionContainers/Users';
import SearchInputTextField from './SearchInput';

type Tab = { label: string; value: PreferenceAccordionTypes };

function FollowingPreferences() {
  const dispatch = useAppDispatch();

  const [activeType, setActiveType] = useState<PreferenceAccordionTypes>(
    PreferenceAccordionTypes.BUSINESSES,
  );

  const PREFERENCE_TABS: Tab[] = [
    { label: 'Businesses', value: PreferenceAccordionTypes.BUSINESSES },
    { label: 'Podcasts', value: PreferenceAccordionTypes.PODCASTS },
    { label: 'News', value: PreferenceAccordionTypes.NEWS_PROVIDERS },
    { label: 'Patriots', value: PreferenceAccordionTypes.USERS },
  ];
  const [searchQuery, setSearchQuery] = useState<string>('');
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setSearchQuery(value);
  };

  const handleClearSearchField = () => {
    setSearchQuery('');
  };

  const handleTypeChange = (type: PreferenceAccordionTypes) => {
    setActiveType(type);
  };

  useEffect(() => {
    return () => {
      dispatch(resetFollowingPreferencesState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchQuery.length > 0) {
      setSearchQuery('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeType]);

  return (
    <Box
      boxSizing="border-box"
      maxWidth="100%"
      sx={{
        backgroundColor: theme.palette.common.white,
      }}
      width="100%"
    >
      <Grid container height={isMobile ? 'auto' : '100%'}>
        <Grid item xs={isMobile ? 12 : 4}>
          <Stack
            direction={isMobile ? 'row' : 'column'}
            padding={isMobile ? theme.spacing(2, 8) : theme.spacing(4, 8)}
            spacing={theme.spacing(isMobile ? 4 : 3)}
            sx={{ position: 'sticky', top: 0 }}
            {...(isMobile && {
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            {PREFERENCE_TABS.map(({ label, value }) => (
              <Typography
                key={value}
                sx={{
                  cursor: 'pointer',
                  textTransform: 'capitalize',
                  ...(activeType === value && {
                    textDecoration: 'underline',
                    textUnderlineOffset: '4px',
                  }),
                  '&:hover': {
                    textDecoration: 'underline',
                    textUnderlineOffset: '4px',
                  },
                }}
                variant="title2"
                onClick={() => handleTypeChange(value)}
              >
                {label}
              </Typography>
            ))}
          </Stack>
        </Grid>
        <Grid item height="100%" xs={isMobile ? 12 : 8}>
          <Stack boxSizing="border-box" height="100%" padding={theme.spacing(0, 3)}>
            <Box
              padding={theme.spacing(isMobile ? 1 : 3, 0, 2)}
              position="sticky"
              sx={{
                background: theme.palette.common.white,
              }}
              top={0}
              zIndex={10}
            >
              <Box
                border="1px solid black"
                borderRadius={theme.spacing(3.275)}
                boxSizing="border-box"
                height={theme.spacing(6)}
                width="100%"
              >
                <SearchInputTextField
                  placeholder={`Search for ${
                    activeType === 'news' ? 'news providers' : activeType
                  }`}
                  sx={{ border: '1px solid black' }}
                  value={searchQuery}
                  onChange={handleInputChange}
                  onClear={handleClearSearchField}
                />
              </Box>
            </Box>
            <>
              {activeType === PreferenceAccordionTypes.BUSINESSES && (
                <Businesses searchQuery={searchQuery.trim()} />
              )}
              {activeType === PreferenceAccordionTypes.PODCASTS && (
                <Podcasts searchQuery={searchQuery.trim()} />
              )}
              {activeType === PreferenceAccordionTypes.NEWS_PROVIDERS && (
                <News searchQuery={searchQuery.trim()} />
              )}
              {activeType === PreferenceAccordionTypes.USERS && (
                <Users searchQuery={searchQuery.trim()} />
              )}
            </>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FollowingPreferences;
