import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import CustomInputField from 'components/customFields/CustomInputField';
import ErrorMessages from 'constants/ErrorMessages';
import { appVersion } from 'helpers/urls';
import { memo, useState } from 'react';
import { checkUserExists, IEmailUpdateState, IResponseData, IUserNameUpdateState } from 'store';
import { changeEmail, updateUserPersonalInfo } from 'store/account/AccountAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import styles from 'theme/styles';
import { handleRestrictSpaces } from 'utils/utils';
import { emailValidationSchema, userNameValidationSchema } from 'utils/validationSchema';

import ChangePassword from '../changePassword';
import DeleteAccount from '../DeleteAccount';
import ProfileAvatar from './ProfileAvatar';
import { useEmailUpdateForm, useUserNameForm } from './usePersonalForm';

function PersonalDetails() {
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showDeleteAccountModal, setDeleteAccountModal] = useState(false);

  const { userData, isUpdateUserDataLoading, isChangeEmailLoading } = useAppSelector(
    s => s.account,
  );

  const userNameState: IUserNameUpdateState = {
    userName: userData.userName ? userData.userName : '',
  };

  const emailState: IEmailUpdateState = {
    email: userData.email ? userData.email : '',
  };

  const updateProfileUserName = async () => {
    if (uValues.userName) {
      const userExistsResponse: IResponseData = (await checkUserExists(
        `userName=${encodeURIComponent(uValues.userName ?? '')}`,
      )) as IResponseData;
      const { userNameExists } = userExistsResponse.data;
      if (!userNameExists) {
        await dispatch(updateUserPersonalInfo(uValues));
      } else {
        const signupErrorMsg = ErrorMessages.signup;
        setUsernameFieldTouched('userName', true, false);
        setUsernameFieldError('userName', signupErrorMsg.userNameExists);
      }
    }
  };

  const handleUpdateEmail = async () => {
    const emailExistsResponse: IResponseData = (await checkUserExists(
      `email=${encodeURIComponent(eValues.email ?? '')}`,
    )) as IResponseData;
    const { emailExists } = emailExistsResponse.data;

    if (!emailExists) {
      const response = (await dispatch(changeEmail(eValues.email))) as IResponseData;
      if (response && typeof response.error !== 'undefined' && !response.error) {
        setShowChangeEmailModal(true);
      }
    } else {
      setEmailFieldTouched('email', true, false);
      setEmailFieldError('email', 'Email already exists.');
    }
  };

  const userNameFormik = useUserNameForm(
    updateProfileUserName,
    userNameState,
    userNameValidationSchema,
  );

  const {
    touched: uTouched,
    values: uValues,
    errors: uErrors,
    handleChange: uHandleChange,
    handleBlur: uHandleBlur,
    handleSubmit: uHandleSubmit,
    isValid: uIsValid,
    dirty: uDirty,
    setFieldTouched: setUsernameFieldTouched,
    setFieldError: setUsernameFieldError,
    isSubmitting: isUserNameSubmitting,
  } = userNameFormik;

  const emailFormik = useEmailUpdateForm(handleUpdateEmail, emailState, emailValidationSchema);
  const {
    touched: eTouched,
    values: eValues,
    errors: eErrors,
    handleChange: eHandleChange,
    handleBlur: eHandleBlur,
    handleSubmit: eHandleSubmit,
    isValid: eIsValid,
    dirty: eDirty,
    setFieldTouched: setEmailFieldTouched,
    setFieldError: setEmailFieldError,
    isSubmitting: isEmailSubmitting,
  } = emailFormik;

  return (
    <Stack spacing={theme.spacing(3)}>
      <Typography component="div" variant={isMobile ? 'h5' : 'h3'}>
        Account
      </Typography>
      <ProfileAvatar />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={theme.spacing(2)}
      >
        <CustomInputField
          fullWidth
          required
          error={!!(uTouched.userName && uErrors.userName)}
          errormessage={uTouched.userName ? uErrors.userName : undefined}
          id="userName"
          inputProps={{ maxLength: 75 }}
          label="Username"
          name="userName"
          type="text"
          value={uValues.userName}
          onBlur={uHandleBlur}
          onChange={e => uHandleChange('userName')(handleRestrictSpaces(e.target.value))}
        />
        <LoadingButton
          disableRipple
          disableTouchRipple
          disabled={
            !(uIsValid && uDirty && uValues.userName !== userData.userName) || isUserNameSubmitting
          }
          loading={isUpdateUserDataLoading}
          size="medium"
          sx={styles.profileBtn}
          variant="contained"
          onClick={() => uHandleSubmit()}
        >
          Save
        </LoadingButton>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={theme.spacing(2)}
      >
        <CustomInputField
          fullWidth
          required
          error={!!(eTouched.email && eErrors.email)}
          errormessage={eTouched.email ? eErrors.email : undefined}
          id="email"
          label="Email"
          name="email"
          type="email"
          value={eValues.email}
          onBlur={eHandleBlur}
          onChange={eHandleChange}
        />
        <LoadingButton
          disableRipple
          disableTouchRipple
          disabled={!(eIsValid && eDirty && eValues.email !== userData.email) || isEmailSubmitting}
          loading={isChangeEmailLoading}
          size="medium"
          sx={styles.profileBtn}
          variant="contained"
          onClick={() => eHandleSubmit()}
        >
          Change
        </LoadingButton>
      </Stack>
      <Stack spacing={theme.spacing(1)}>
        <Typography component="span" variant="body1Bold">
          Password
        </Typography>
        <Button
          disableRipple
          disableTouchRipple
          size="large"
          sx={{
            width: '175px',
            textTransform: 'none',
          }}
          variant="contained"
          onClick={() => setShowChangePasswordModal(true)}
        >
          Change Password
        </Button>
      </Stack>
      <Stack spacing={theme.spacing(1)}>
        <Typography component="span" variant="body1Bold">
          Delete Account
        </Typography>
        <Button
          disableRipple
          disableTouchRipple
          size="large"
          sx={{
            width: '175px',
            textTransform: 'none',
            backgroundColor: '#B32A48',
            '&:hover': {
              backgroundColor: '#9E2540',
            },
          }}
          variant="contained"
          onClick={() => setDeleteAccountModal(true)}
        >
          Delete Account
        </Button>
        <Typography component="div" variant="body1">
          {`Version ${appVersion}`}
        </Typography>
      </Stack>
      {showDeleteAccountModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setDeleteAccountModal(false)}
          isMobile={isMobile}
          open={showDeleteAccountModal}
          setOpen={setDeleteAccountModal}
          title="Delete Account"
        >
          <DeleteAccount isMobile={isMobile} onClose={() => setDeleteAccountModal(false)} />
        </ToggleDrawerDialog>
      )}
      {showChangePasswordModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowChangePasswordModal(false)}
          isMobile={isMobile}
          open={showChangePasswordModal}
          setOpen={setShowChangePasswordModal}
          title="Change Password"
        >
          <ChangePassword closeModal={() => setShowChangePasswordModal(false)} />
        </ToggleDrawerDialog>
      )}
      {showChangeEmailModal && (
        <ToggleDrawerDialog
          handleClose={() => setShowChangeEmailModal(false)}
          isMobile={isMobile}
          open={showChangeEmailModal}
          setOpen={setShowChangeEmailModal}
          showCloseIcon={false}
          showHeader={false}
          title="Email Verification"
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={theme.spacing(isMobile ? 2 : 4)}
          >
            <Typography align="center" variant={isMobile ? 'h6' : 'title2'}>
              Please check your email to complete the changes made to your account
            </Typography>
            <Button size="large" variant="contained" onClick={() => setShowChangeEmailModal(false)}>
              Close
            </Button>
          </Stack>
        </ToggleDrawerDialog>
      )}
    </Stack>
  );
}

export default memo(PersonalDetails);
