import { Dispatch } from 'redux';
import { AlertActions } from 'store/alerts/AlertInterface';
import { AlertType } from 'store/alerts/AlertTypes';
import { UpdateCommentCountType } from 'store/comment/CommentInterface';
import { FollowingTimelineActions } from 'store/followingTimeline/FollowingTimelineInterface';
import FollowingTimelineType from 'store/followingTimeline/FollowingTimelineTypes';
import { HomeTimelineActions } from 'store/homeTimeline/HomeTimelineInterface';
import HomeTimelineType from 'store/homeTimeline/HomeTimelineTypes';
import { SearchActions } from 'store/search/SearchInterface';
import SearchType from 'store/search/SearchTypes';
import { TrendingTimelineActions } from 'store/trending/TrendingInterface';
import TrendingType from 'store/trending/TrendingTypes';

import client from '../../Api';
import { newsUrl } from '../../helpers/urls';
import { IResponseData } from '../common/CommonInterface';
import { IAllNewsState, INewsArticle, INewsProviderWallState, NewsActions } from './NewsInterface';
import NewsType from './NewsTypes';

export const resetNewsState = () => {
  return async (dispatch: Dispatch<NewsActions>) => {
    dispatch({ type: NewsType.RESET_NEWS_STATE });
  };
};

export const resetNewsArticleState = () => {
  return async (dispatch: Dispatch<NewsActions>) => {
    dispatch({ type: NewsType.RESET_NEWS_ARTICLE_STATE });
  };
};

export const updateArticleCommentCount = (type: UpdateCommentCountType) => {
  return async (dispatch: Dispatch<NewsActions>) => {
    dispatch({ type: NewsType.UPDATE_COMMENT_COUNT, payload: type });
  };
};

export const getNews = (pageNumber: number, newsType = 'short') => {
  return async (dispatch: Dispatch<NewsActions>) => {
    try {
      dispatch({ type: NewsType.GET_ALL_NEWS_REQUEST });
      const response: IResponseData = await client.get(
        `${newsUrl}news?news_in=${newsType}&page=${pageNumber}&limit=10`,
      );

      const payload: IAllNewsState = {
        news: response.data,
        pageNumber,
        hasMoreData: response?.data?.length === 10,
      };

      dispatch({
        type: NewsType.GET_ALL_NEWS_SUCCESS,
        payload,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: NewsType.GET_ALL_NEWS_ERROR });
      return error;
    }
  };
};

export const getFeaturedNews = (limit = 6) => {
  return async (dispatch: Dispatch<NewsActions>) => {
    try {
      dispatch({ type: NewsType.GET_FEATURED_NEWS_REQUEST });
      const response: IResponseData = await client.get(
        `${newsUrl}featured/news?page=1&limit=${limit}`,
      );
      dispatch({
        type: NewsType.GET_FEATURED_NEWS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: NewsType.GET_FEATURED_NEWS_ERROR });
      return error;
    }
  };
};

export const getAllNewsProviders = () => {
  return async (dispatch: Dispatch<NewsActions>) => {
    try {
      dispatch({ type: NewsType.GET_ALL_NEWS_PROVIDERS_REQUEST });
      const response: IResponseData = await client.get(`${newsUrl}providers`);
      dispatch({
        type: NewsType.GET_ALL_NEWS_PROVIDERS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: NewsType.GET_ALL_NEWS_PROVIDERS_ERROR });
      return error;
    }
  };
};

export const getUserSelectedNewsProviders = () => {
  return async (dispatch: Dispatch<NewsActions>) => {
    try {
      dispatch({ type: NewsType.GET_USER_SELECTED_PROVIDERS_REQUEST });
      const response: IResponseData = await client.get(`${newsUrl}users/providers`);
      dispatch({
        type: NewsType.GET_USER_SELECTED_PROVIDERS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: NewsType.GET_USER_SELECTED_PROVIDERS_ERROR });
      return error;
    }
  };
};

export const updateUserSelectedNewsProviders = (providerIDs: string[]) => {
  return async (dispatch: Dispatch<NewsActions>) => {
    try {
      dispatch({ type: NewsType.UPDATE_USER_SELECTED_PROVIDERS_REQUEST });
      const response: IResponseData = await client.put(`${newsUrl}users/providers`, {
        providerIDs,
      });
      dispatch({
        type: NewsType.UPDATE_USER_SELECTED_PROVIDERS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: NewsType.UPDATE_USER_SELECTED_PROVIDERS_ERROR });
      return error;
    }
  };
};

export const getNewsArticleDetailsById = (newsId: string) => {
  return async (dispatch: Dispatch<NewsActions>) => {
    try {
      dispatch({ type: NewsType.GET_NEWS_ARTICLE_DETAILS_REQUEST });
      const response: IResponseData = await client.get(`${newsUrl}news/${newsId}/article`);

      dispatch({
        type: NewsType.GET_NEWS_ARTICLE_DETAILS_SUCCESS,
        payload:
          response.data && response.data?.length > 0 ? response.data?.[0] : ({} as INewsArticle),
      });
      return response;
    } catch (error) {
      dispatch({ type: NewsType.GET_NEWS_ARTICLE_DETAILS_ERROR });
      return error;
    }
  };
};

export const likeDislikeNewsPost = (
  newsId: string,
  likePost: boolean,
  component = '',
  type = '',
) => {
  return async (
    dispatch: Dispatch<
      | NewsActions
      | AlertActions
      | FollowingTimelineActions
      | SearchActions
      | HomeTimelineActions
      | TrendingTimelineActions
    >,
  ) => {
    try {
      dispatch({ type: NewsType.LIKE_DISLIKE_POST_REQUEST });
      let response: IResponseData;
      const likeUrl = `${newsUrl}users/news/${newsId}/like`;
      if (!likePost) {
        response = await client.post(likeUrl);
      } else {
        response = await client.delete(likeUrl);
      }
      if (component === 'news' && type === 'article') {
        dispatch({ type: NewsType.LIKE_DISLIKE_POST_SUCCESS });
      } else if (component === 'news' && type === 'featured') {
        dispatch({
          type: NewsType.FEATURED_LIKE_DISLIKE_POST_SUCCESS,
          payload: newsId,
        });
      } else if (component === 'news' && type === 'all-news') {
        dispatch({
          type: NewsType.ALL_NEWS_LIKE_DISLIKE_POST_SUCCESS,
          payload: newsId,
        });
      } else if (component === 'following') {
        dispatch({
          type: FollowingTimelineType.FOLLOWING_LIKE_DISLIKE_POST_SUCCESS,
          payload: newsId,
        });
      } else if (component === 'search') {
        dispatch({
          type: SearchType.SEARCH_LIKE_DISLIKE_POST_SUCCESS,
          payload: newsId,
        });
      } else if (component === 'home' && type === 'featured') {
        dispatch({
          type: HomeTimelineType.FEATURED_LIKE_DISLIKE_POST_SUCCESS,
          payload: newsId,
        });
      } else if (component === 'home' && type === 'all-news') {
        dispatch({
          type: HomeTimelineType.ALL_POST_LIKE_DISLIKE_POST_SUCCESS,
          payload: newsId,
        });
      } else if (component === 'news-wall') {
        dispatch({
          type: NewsType.NEWS_PROVIDER_LIKE_DISLIKE_POST_SUCCESS,
          payload: newsId,
        });
      } else if (component === 'trending') {
        dispatch({
          type: TrendingType.TRENDING_LIKE_DISLIKE_POST_SUCCESS,
          payload: newsId,
        });
      }
      return response;
    } catch (error: unknown) {
      dispatch({ type: NewsType.LIKE_DISLIKE_POST_ERROR });
      const e = error as IResponseData;
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: e?.message },
      });
      return error;
    }
  };
};

export const getUserFollowStatus = (providerId: string) => {
  return async (dispatch: Dispatch<NewsActions>) => {
    try {
      dispatch({
        type: NewsType.GET_USER_PROVIDER_RELATIONSHIP_STATUS_REQUEST,
      });
      const response: IResponseData = await client.get(
        `${newsUrl}users/news/providers/${providerId}/follow`,
      );
      dispatch({
        type: NewsType.GET_USER_PROVIDER_RELATIONSHIP_STATUS_SUCCESS,
        payload: response.data?.status ?? '',
      });
      return response;
    } catch (error) {
      dispatch({ type: NewsType.GET_USER_PROVIDER_RELATIONSHIP_STATUS_ERROR });
      return error;
    }
  };
};

export const followUnFollowNewsProvider = (providerId: string, isFollowing: boolean) => {
  return async (dispatch: Dispatch<NewsActions | AlertActions>) => {
    try {
      dispatch({
        type: NewsType.UPDATE_USER_PROVIDER_RELATIONSHIP_STATUS_REQUEST,
      });
      let response: IResponseData;

      if (isFollowing) {
        response = await client.delete(`${newsUrl}users/news/providers/${providerId}/unfollow`);
      } else {
        response = await client.post(`${newsUrl}users/news/providers/follow`, {
          providerID: providerId,
        });
      }
      dispatch({
        type: NewsType.UPDATE_USER_PROVIDER_RELATIONSHIP_STATUS_SUCCESS,
        payload: !response.error ? !isFollowing : false,
      });
      return response;
    } catch (error: unknown) {
      dispatch({
        type: NewsType.UPDATE_USER_PROVIDER_RELATIONSHIP_STATUS_ERROR,
      });
      const e = error as IResponseData;
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: e?.message },
      });
      return error;
    }
  };
};

export const getNewsProviderArticles = (providerId: string, pageNumber: number) => {
  return async (dispatch: Dispatch<NewsActions>) => {
    try {
      dispatch({ type: NewsType.GET_NEWS_ARTICLES_BY_PROVIDER_ID_REQUEST });
      const response: IResponseData = await client.get(
        `${newsUrl}providers/${providerId}/news?page=${pageNumber}&limit=12`,
      );

      const payload: INewsProviderWallState = {
        articles: response?.data || [],
        pageNumber,
        hasMoreData: (response?.data || []) && response?.data?.length === 12,
      };
      dispatch({
        type: NewsType.GET_NEWS_ARTICLES_BY_PROVIDER_ID_SUCCESS,
        payload,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: NewsType.GET_NEWS_ARTICLES_BY_PROVIDER_ID_ERROR });
      return error;
    }
  };
};

export const resetNewsProviderWallState = () => {
  return async (dispatch: Dispatch<NewsActions>) => {
    dispatch({ type: NewsType.RESET_NEWS_PROVIDER_WALL_STATE });
  };
};

export const getNewsProviderInfo = (providerId: string) => {
  return async (dispatch: Dispatch<NewsActions | AlertActions>) => {
    try {
      dispatch({
        type: NewsType.GET_NEWS_PROVIDER_INFO_REQUEST,
      });
      const response: IResponseData = await client.get(`${newsUrl}provider/${providerId}`);
      dispatch({
        type: NewsType.GET_NEWS_PROVIDER_INFO_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: NewsType.GET_NEWS_PROVIDER_INFO_ERROR });
      const e = error as IResponseData;
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: e?.message },
      });
      return error;
    }
  };
};
