import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import Loader from 'components/loader/Loader';
import { StyledTableCell, StyledTableRow } from 'components/table/StyledTable';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import { useCallback, useEffect, useState } from 'react';
import { IAllSponsorsReport } from 'store';
import {
  getAllSponsorsReport,
  resetAllSponsorsReportState,
} from 'store/admin/vettingDashboard/VettingDashboardActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { capitalizeFirstLetter } from 'utils/utils';

import DateRange, { IFilters } from './DateRange';
import AddAds from './sponsorAds/AddAds';

interface ISponsorsTypeOption {
  _id: number;
  label: string;
  value: SponsorsType;
}

export type SponsorsType = 'ad' | 'coupon';

export const SPONSORS_TYPE_OPTIONS: ISponsorsTypeOption[] = [
  { _id: 0, label: 'Ad', value: 'ad' },
  { _id: 1, label: 'Coupon', value: 'coupon' },
];

function AllSponsorsReport() {
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const [selectedAd, setSelectedAd] = useState({} as IAllSponsorsReport);
  const [showEditAd, setShowEditAd] = useState(false);

  const { AllSponsorsReportData, getAllSponsorsReportLoading } = useAppSelector(
    s => s.vettingDashboard,
  );

  const [filters, setFilters] = useState<IFilters>();
  const [selectedSponsorType, setSelectedSponsorType] = useState<SponsorsType>(
    SPONSORS_TYPE_OPTIONS[0].value,
  );

  const fetchAllSponsorsReport = useCallback(
    (initialLoad: boolean, type?: string) => {
      const params = {
        offset: initialLoad ? 0 : AllSponsorsReportData.offset,
        from: filters?.range?.from,
        to: filters?.range?.to,
        type: type || selectedSponsorType,
        reset: initialLoad,
      };
      dispatch(getAllSponsorsReport(params));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [AllSponsorsReportData.offset],
  );

  useEffect(() => {
    return () => {
      dispatch(resetAllSponsorsReportState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters?.range) {
      dispatch(resetAllSponsorsReportState());
      const debounceSearchFn = setTimeout(async () => {
        fetchAllSponsorsReport(true);
      }, 200);
      return () => clearTimeout(debounceSearchFn);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleTypeChange = (type: any) => {
    dispatch(resetAllSponsorsReportState());
    setSelectedSponsorType(type);
    fetchAllSponsorsReport(true, type);
  };

  const onCreateAd = () => {
    setShowEditAd(false);
    dispatch(resetAllSponsorsReportState());
    fetchAllSponsorsReport(true, selectedSponsorType);
  };

  return (
    <>
      <Box paddingBottom={2}>
        <DateRange
          showDateRange
          showLocationKeyButton
          isDownloadCSV={false}
          showSearch={false}
          onChangeFilter={setFilters}
        />
      </Box>
      <ComponentWrapper
        withInfiniteScroll
        borderColor="light"
        infiniteScrollProps={{
          loadOnMount: true,
          hasMoreData: AllSponsorsReportData.hasMoreData,
          isLoading: getAllSponsorsReportLoading,
          onBottomHit: fetchAllSponsorsReport,
          valueToBeAddedToInnerHeight: 50,
        }}
        title=""
        withPadding={false}
      >
        <Stack
          // alignItems="center"
          borderBottom={`0.25px solid ${theme.palette.divider}`}
          padding={isMobile ? theme.spacing(2) : theme.spacing(3.65, 4, 3)}
          spacing={theme.spacing(1.5)}
        >
          <Typography color="text.secondary" variant={isMobile ? 'title2' : 'title1'}>
            Sponsors Report
          </Typography>
          <Stack direction="row" spacing={theme.spacing(4)}>
            {SPONSORS_TYPE_OPTIONS.map(type => (
              <Typography
                key={type.label}
                sx={{
                  cursor: 'pointer',
                  textTransform: 'capitalize',
                  ...(selectedSponsorType === type.value && {
                    textDecoration: 'underline',
                    textUnderlineOffset: '4px',
                  }),
                  '&:hover': {
                    textDecoration: 'underline',
                    textUnderlineOffset: '4px',
                  },
                }}
                variant="title2"
                onClick={() => handleTypeChange(type.value)}
              >
                {type.label}
              </Typography>
            ))}
          </Stack>
        </Stack>
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Location</StyledTableCell>
                <StyledTableCell>Start Date</StyledTableCell>
                <StyledTableCell>End Date</StyledTableCell>
                <StyledTableCell>#Click</StyledTableCell>
                <StyledTableCell>#Impressions</StyledTableCell>
                <StyledTableCell>
                  {selectedSponsorType === 'ad' ? 'Ad Url' : 'Coupon Url'}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {AllSponsorsReportData.AllSponsorsReport.map(
                (sponsor: IAllSponsorsReport, index: number) => (
                  <StyledTableRow key={`${sponsor.documentId}_${index.toString()}`}>
                    <StyledTableCell>{sponsor.sponsor.name}</StyledTableCell>
                    <StyledTableCell>
                      {capitalizeFirstLetter(sponsor.page)}-{sponsor.position}
                    </StyledTableCell>
                    <StyledTableCell>{sponsor.startdate}</StyledTableCell>
                    <StyledTableCell>{sponsor.enddate}</StyledTableCell>
                    <StyledTableCell>{sponsor.clicks}</StyledTableCell>
                    <StyledTableCell>{sponsor.impressions}</StyledTableCell>
                    <StyledTableCell>{sponsor.url}</StyledTableCell>
                  </StyledTableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {getAllSponsorsReportLoading && (
          <Box boxSizing="border-box" padding={theme.spacing(2, 0)} width="100%">
            <Loader />
          </Box>
        )}
        {!getAllSponsorsReportLoading && AllSponsorsReportData.AllSponsorsReport.length === 0 && (
          <Box boxSizing="border-box" padding={theme.spacing(4)} width="100%">
            <Typography align="center">No records found</Typography>
          </Box>
        )}
      </ComponentWrapper>

      {showEditAd && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowEditAd(false)}
          isMobile={isMobile}
          open={showEditAd}
          setOpen={setShowEditAd}
          title={`Edit ${selectedSponsorType === 'ad' ? 'Ad' : 'Coupon'}`}
        >
          <AddAds
            closeModal={() => setShowEditAd(false)}
            sponsorAd={selectedAd}
            sponsorId={selectedAd.documentId}
            sponsorType={selectedSponsorType}
            onCreate={onCreateAd}
          />
        </ToggleDrawerDialog>
      )}
    </>
  );
}

export default AllSponsorsReport;
