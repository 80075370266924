import ExpandMore from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Fragment, useMemo } from 'react';
import { ContentType, ICommonComment } from 'store';
import theme from 'theme';

import CommentTile from './CommentTile';

interface ICommentsListProps {
  comments: ICommonComment[];
  type: ContentType;
}

export default function CommentsList(props: ICommentsListProps) {
  const { comments, type } = props;
  const commentsLength = useMemo(() => comments?.length ?? 0, [comments]);

  return (
    <Stack sx={{ mt: theme.spacing(4) }}>
      <Stack alignItems="center" direction="row" spacing={theme.spacing(1.5)}>
        <Typography component="h6" variant="h6Bold">
          Most Relevant
        </Typography>
        <ExpandMore />
      </Stack>
      <Divider
        flexItem
        sx={{
          my: theme.spacing(3),
          borderBottomWidth: '1.5px',
          borderColor: theme.palette.divider,
        }}
      />
      {commentsLength > 0 ? (
        comments.map((comment, index) => (
          <Fragment key={(comment?.documentID ?? '') + index.toString()}>
            <CommentTile comment={comment} type={type} />
            {commentsLength !== index + 1 && (
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: '1.5px',
                  borderColor: theme.palette.divider,
                  my: theme.spacing(3),
                }}
              />
            )}
          </Fragment>
        ))
      ) : (
        <Typography component="h6" sx={{ textAlign: 'center' }} variant="h6Bold">
          No Comments
        </Typography>
      )}
    </Stack>
  );
}
