import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';

interface ILinkTextProps {
  title: string;
  route: string;
}

function LinkText({ title, route }: ILinkTextProps) {
  return (
    <Box
      end
      component={NavLink}
      sx={{
        textDecoration: 'none !important',
        '&.active .MuiTypography-root': {
          textUnderlineOffset: '4px',
          textDecorationThickness: '1.5px !important',
          textDecoration: 'underline',
        },
        '&:hover .MuiTypography-root': {
          textUnderlineOffset: '4px',
          textDecoration: 'underline',
        },
      }}
      to={route}
    >
      <Typography component="div" variant="button">
        {title}
      </Typography>
    </Box>
  );
}

function LinkButton({ title, route }: ILinkTextProps) {
  return (
    <Box
      end
      component={NavLink}
      sx={{
        boxSizing: 'border-box',
        textDecoration: 'none !important',
        padding: theme.spacing(0.75, 3),
        color: theme.palette.primary.main,
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
        '&.active.MuiBox-root': {
          backgroundColor: theme.palette.primary.main,
        },
        '&.active .MuiTypography-root': {
          fontWeight: 600,
          color: `${theme.palette.common.white} !important`,
        },
      }}
      to={route}
    >
      <Typography component="div" variant="button">
        {title}
      </Typography>
    </Box>
  );
}

export const homeNav = [
  { name: 'News', route: '/news' },
  { name: 'Podcasts', route: '/podcasts' },
  { name: 'Business Network', route: '/businesses' },
];

function HomeNav() {
  const { isLoggedIn } = useAppSelector(s => s.auth);

  return (
    <HomeWrapper useDefaultTopBottomPadding={false}>
      <Box
        sx={{
          display: { midsm: 'block', xs: 'none' },
          paddingBottom: theme.spacing(3),
          paddingTop: theme.spacing(4),
        }}
      >
        <Stack alignItems="center" direction="row" spacing={theme.spacing(4)}>
          <Stack direction="row" spacing={theme.spacing(2)}>
            <LinkButton route="/home" title="Home" />
            {isLoggedIn && (
              <>
                <LinkButton route="/following" title="Following" />
              </>
            )}
          </Stack>
          <Divider
            flexItem
            orientation="vertical"
            sx={{ borderColor: theme.palette.text.secondary }}
          />
          <Stack alignItems="center" direction="row" spacing={theme.spacing(3)}>
            {homeNav.map(n => (
              <LinkText key={n.name} route={n.route} title={n.name} />
            ))}
          </Stack>
          <Divider
            flexItem
            orientation="vertical"
            sx={{ borderColor: theme.palette.text.secondary }}
          />
          <Stack alignItems="center" direction="row" spacing={theme.spacing(3)}>
            <LinkText key="resource" route="/resources" title="Resources" />
            <LinkText key="about" route="/about" title="About" />
          </Stack>
        </Stack>
      </Box>
    </HomeWrapper>
  );
}

export default HomeNav;
