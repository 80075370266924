import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import theme from 'theme';

type IPostFooterProps = {
  followStatus: string;
  isLoading: boolean;
  onFollowBtnClick: () => void;
  providerName: string;
  providerType: string;
  showText?: boolean;
};

export default function PostFooter(props: IPostFooterProps) {
  const {
    followStatus,
    isLoading,
    onFollowBtnClick,
    providerName,
    providerType,
    showText = true,
  } = props;

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      {...(showText && { sx: { mt: theme.spacing(4) } })}
    >
      {showText && (
        <Typography component="h6" variant="h6Bold">
          More from {providerName} {providerType}
        </Typography>
      )}
      <LoadingButton
        disableRipple
        disableTouchRipple
        disabled={isLoading}
        loading={isLoading}
        type="button"
        variant="contained"
        onClick={onFollowBtnClick}
      >
        {followStatus || 'FOLLOW'}
      </LoadingButton>
    </Stack>
  );
}
