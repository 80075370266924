import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import SwipeableDrawer, { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer';
import React from 'react';

const CustomBottomDrawer = styled(SwipeableDrawer)<SwipeableDrawerProps>(({ theme }) => ({
  '& .MuiPaper-root': {
    '&.MuiDrawer-paper': {
      padding: theme.spacing(3),
      borderRadius: '20px 20px 0px 0px',
    },
  },
}));

const Puller = styled(Box)(() => ({
  width: 40,
  height: 3,
  backgroundColor: '#D9D9D9',
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

interface IDrawerProps {
  open: boolean;
  children: React.ReactNode | JSX.Element;
  onClose: () => void;
  onOpen: () => void;
  withPuller?: boolean;
}

function BottomDrawer(props: IDrawerProps) {
  const { open, children, onClose, onOpen, withPuller = false } = props;
  return (
    <CustomBottomDrawer
      anchor="bottom"
      open={open}
      sx={{ zIndex: 1320 }}
      onClose={onClose}
      onOpen={onOpen}
    >
      {withPuller && <Puller />}
      <Stack sx={{ maxHeight: '60vh' }}>{children}</Stack>
    </CustomBottomDrawer>
  );
}

export default BottomDrawer;
