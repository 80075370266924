import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'Api';
import {
  updateProfilePostCommentCount,
  updateProfilePostContent,
} from 'store/account/AccountAction';
import { IAlertPayload } from 'store/alerts/AlertInterface';
import { AlertType } from 'store/alerts/AlertTypes';
import {
  updateBusinessPostCommentCount,
  updateBusinessPostContent,
} from 'store/business/BusinessAction';
import { IResponseData } from 'store/common/CommonInterface';
import { updateArticleCommentCount } from 'store/news/NewsActions';
import { updateEpisodeCommentCount } from 'store/podcast/PodcastActions';
import { handleRejected } from 'store/utils';

import {
  ContentType,
  IAddCommentParams,
  IAddCommentPayload,
  ICommentLikeDisLikeParams,
  ICommentsState,
  ICommonComment,
  IDeleteCommentParams,
  IEditCommentParams,
  IGetCommentsParams,
  IGetCommentsPayload,
  IReportCommentParams,
  UpdateCommentCountType,
} from './CommentInterface';
import {
  getAddCommentUrl,
  getCommentsUrl,
  getDeleteUrl,
  getEditCommentUrl,
  getLikeDisLikeUrl,
  getRepliesUrl,
  getUserReportUrl,
} from './CommentUrls';

export const getComments = createAsyncThunk<IGetCommentsPayload, IGetCommentsParams>(
  'comment/getComment',
  async (params, thunkApi) => {
    try {
      const { offset = 0 } = params;

      const url = getCommentsUrl(params);

      const response: IResponseData = await client.get(url);

      return { data: response.data || [], initialLoad: !offset };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  },
);

export const getCommentReplies = createAsyncThunk<IGetCommentsPayload, IGetCommentsParams>(
  'comment/getCommentReplies',
  async (params, thunkApi) => {
    try {
      const { offset } = params;

      const url = getRepliesUrl(params);

      const response: IResponseData = await client.get(url);

      return { data: response.data || [], initialLoad: offset === 0 };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  },
);

export const addComment = createAsyncThunk<IAddCommentPayload, IAddCommentParams>(
  'comment/addComment',
  async (params, thunkApi) => {
    try {
      const { contentId, type, parentCommentID, comment, commentText, isPrivate } = params;

      const url = getAddCommentUrl({ contentId, type });

      const payload = { parentCommentID, comment, commentText, isPrivate };
      const response: IResponseData = await client.post(url, payload);

      if (!parentCommentID) {
        switch (type) {
          case ContentType.ARTICLE:
            thunkApi.dispatch(updateArticleCommentCount(UpdateCommentCountType.INC));
            break;
          case ContentType.BUSINESS:
            thunkApi.dispatch(updateBusinessPostCommentCount(UpdateCommentCountType.INC));
            break;
          case ContentType.PODCAST:
            thunkApi.dispatch(updateEpisodeCommentCount(UpdateCommentCountType.INC));
            break;
          case ContentType.PROFILE:
            thunkApi.dispatch(updateProfilePostCommentCount(UpdateCommentCountType.INC));
            break;
          default:
        }
      }

      return { data: response.data, commentsCount: response.data?.commentCount };
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  },
);

export const editComment = createAsyncThunk<IResponseData, IEditCommentParams>(
  'comment/editComment',
  async (params, thunkApi) => {
    try {
      const { commentId, type, comment, commentText } = params;

      const url = getEditCommentUrl({ commentId, type });

      const response: IResponseData = await client.put(url, { comment, commentText });

      return response;
    } catch (error: any) {
      thunkApi.rejectWithValue({ error });
      return error;
    }
  },
);

export const deleteComment = createAsyncThunk<IResponseData, IDeleteCommentParams>(
  'comment/deleteComment',
  async (params, thunkApi) => {
    try {
      const { commentId, type, parentCommentID } = params;

      const url = getDeleteUrl({ commentId, type });

      const response: IResponseData = await client.delete(url);

      if (!parentCommentID) {
        switch (type) {
          case ContentType.ARTICLE:
            thunkApi.dispatch(updateArticleCommentCount(UpdateCommentCountType.DEC));
            break;
          case ContentType.BUSINESS:
            thunkApi.dispatch(updateBusinessPostCommentCount(UpdateCommentCountType.DEC));
            break;
          case ContentType.PODCAST:
            thunkApi.dispatch(updateEpisodeCommentCount(UpdateCommentCountType.DEC));
            break;
          case ContentType.PROFILE:
            thunkApi.dispatch(updateProfilePostCommentCount(UpdateCommentCountType.DEC));
            break;
          default:
        }
      }

      return response;
    } catch (error: any) {
      thunkApi.rejectWithValue({ error });
      return error;
    }
  },
);

export const likeDislikeComment = createAsyncThunk<IResponseData, ICommentLikeDisLikeParams>(
  'comment/likeDislike',
  async (params, thunkApi) => {
    try {
      const { commentId, type, isLiked } = params;

      const url = getLikeDisLikeUrl({ commentId, type });

      const response: IResponseData = await (!isLiked ? client.post(url) : client.delete(url));

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue({ error });
    }
  },
);

export const userContentReport = createAsyncThunk<IResponseData, IReportCommentParams>(
  'comment/userContentReport',
  async (params, thunkApi) => {
    try {
      const { type, postId, postType } = params;

      const url = getUserReportUrl({ type });

      const response: IResponseData = await client.post(url, params);

      const successPayload: IAlertPayload = { message: `${postType} reported successfully` };
      thunkApi.dispatch({ type: AlertType.ALERT_SUCCESS, payload: successPayload });

      if (postType === 'content') {
        switch (type) {
          case ContentType.BUSINESS:
            thunkApi.dispatch(updateBusinessPostContent({ postId }));
            break;

          case ContentType.PROFILE:
            thunkApi.dispatch(updateProfilePostContent({ postId }));
            break;
          default:
        }
      }

      return response;
    } catch (error: any) {
      thunkApi.rejectWithValue({ error });
      const errorPayload: IAlertPayload = { message: error?.message };
      thunkApi.dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  },
);

// Define the initial state using that type
const initialState: ICommentsState = {
  loading: false,
  addCommentLoading: false,
  deleteCommentLoading: false,
  reportContentLoading: false,
  editCommentLoading: false,
  selectedComment: {} as ICommonComment,
  commentReplies: [],
  comments: [],
  commentsCount: 0,
};

export const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCommentReplies.pending, state => {
      state.loading = true;
      state.selectedComment = {} as ICommonComment;
      state.commentReplies = [];
    });
    builder.addCase(getCommentReplies.fulfilled, (state, { payload }) => {
      const { data, initialLoad } = payload;

      state.loading = false;

      if (initialLoad) {
        const [selectedComment, ...commentReplies] = data || [];

        state.selectedComment = selectedComment || {};
        state.commentReplies = commentReplies || [];
      } else {
        state.commentReplies = [...state.commentReplies, ...data];
      }
    });
    builder.addCase(getCommentReplies.rejected, handleRejected);

    builder.addCase(getComments.pending, (state, { meta: { arg } }) => {
      state.loading = true;
      state.comments = !arg.offset ? [] : state.comments;
    });
    builder.addCase(getComments.fulfilled, (state, { payload }) => {
      const { data, initialLoad } = payload;

      state.loading = false;
      state.comments = initialLoad ? [...data] : [...state.comments, ...data];
    });
    builder.addCase(getComments.rejected, handleRejected);

    builder.addCase(addComment.pending, state => {
      state.addCommentLoading = true;
    });
    builder.addCase(addComment.fulfilled, (state, { meta: { arg }, payload }) => {
      state.addCommentLoading = false;

      if (arg.parentCommentID) {
        const replies = [...state.commentReplies];
        if (replies.length % 10 === 0) replies.pop();
        replies.unshift(payload.data);

        state.commentReplies = replies;
        state.selectedComment = {
          ...state.selectedComment,
          repliesCount: payload.commentsCount || replies.length,
        };
      } else {
        const comments = [...state.comments];
        if (comments.length % 10 === 0) comments.pop();
        comments.unshift(payload.data);

        state.addCommentLoading = false;
        state.comments = comments;
      }
    });
    builder.addCase(addComment.rejected, state => {
      state.addCommentLoading = false;
    });

    builder.addCase(editComment.pending, state => {
      state.editCommentLoading = true;
    });
    builder.addCase(editComment.fulfilled, (state, { meta: { arg } }) => {
      const { comments, commentReplies, selectedComment } = state;
      let { comment, commentText, commentId } = arg;

      comment = comment || commentText || '';

      const commentIndex = comments.findIndex(i => i.documentID === commentId);
      const replyIndex = commentReplies.findIndex(i => i.documentID === commentId);

      if (commentIndex > -1) {
        comments[commentIndex].comment = comment;
      }
      if (replyIndex > -1) {
        commentReplies[replyIndex].comment = comment;
      }
      if (selectedComment.documentID === commentId) {
        selectedComment.comment = comment;
      }

      state.editCommentLoading = false;
      state.comments = comments;
      state.commentReplies = commentReplies;
      state.selectedComment = selectedComment;
    });
    builder.addCase(editComment.rejected, state => {
      state.editCommentLoading = false;
    });

    builder.addCase(deleteComment.pending, state => {
      state.deleteCommentLoading = true;
    });
    builder.addCase(deleteComment.fulfilled, (state, { meta: { arg } }) => {
      const { commentId } = arg;
      let { commentReplies, comments, selectedComment } = state;

      if (selectedComment.documentID === commentId) {
        selectedComment.comment = '';
      }

      const commentRepliesIndex = commentReplies.findIndex(i => i.documentID === commentId);

      if (commentRepliesIndex > -1) {
        commentReplies.splice(commentRepliesIndex, 1);
        selectedComment.repliesCount -= 1;
      }

      comments = comments.filter(i => i.documentID !== commentId);

      state.comments = comments;
      state.commentReplies = commentReplies;
      state.selectedComment = selectedComment;
      state.deleteCommentLoading = false;
    });
    builder.addCase(deleteComment.rejected, state => {
      state.deleteCommentLoading = false;
    });

    builder.addCase(likeDislikeComment.fulfilled, (state, { meta: { arg } }) => {
      const { commentReplies, selectedComment, comments } = state;
      const { parentCommentId, commentId, isLiked = false } = arg;

      if (parentCommentId) {
        const replyIndex = commentReplies.findIndex(i => i.documentID === commentId);
        if (replyIndex > -1) {
          const { likesCount = 0 } = commentReplies[replyIndex];

          commentReplies[replyIndex].isLiked = !isLiked;
          commentReplies[replyIndex].likesCount = isLiked ? likesCount - 1 : likesCount + 1;
        }
      } else {
        const commentIndex = comments.findIndex(i => i.documentID === commentId);
        if (commentIndex > -1) {
          const { likesCount } = comments[commentIndex];
          comments[commentIndex].isLiked = !isLiked;
          comments[commentIndex].likesCount = isLiked ? likesCount - 1 : likesCount + 1;
        }
      }

      if (selectedComment.documentID === commentId) {
        const { likesCount } = selectedComment;
        selectedComment.isLiked = !isLiked;
        selectedComment.likesCount = isLiked ? likesCount - 1 : likesCount + 1;
      }

      state.commentReplies = commentReplies;
      state.comments = comments;
      state.selectedComment = selectedComment;
    });

    builder.addCase(userContentReport.pending, state => {
      state.reportContentLoading = true;
    });
    builder.addCase(userContentReport.fulfilled, (state, { meta: { arg } }) => {
      const { postId } = arg;
      const { commentReplies, comments, selectedComment } = state;

      const updatedSelectedComment = { ...selectedComment };

      if (selectedComment.documentID === postId) {
        updatedSelectedComment.isReported = true;
        updatedSelectedComment.comment = '';
      }

      const commentRepliesIndex = commentReplies.findIndex(i => i.documentID !== postId);

      if (commentRepliesIndex > -1) {
        commentReplies[commentRepliesIndex].isReported = true;
        commentReplies[commentRepliesIndex].comment = '';
      }

      const commentIndex = comments.findIndex(i => i.documentID === postId);

      if (commentIndex > -1) {
        comments[commentIndex].isReported = true;
        comments[commentIndex].comment = '';
      }

      state.comments = comments;
      state.commentReplies = commentReplies;
      state.selectedComment = updatedSelectedComment;
      state.reportContentLoading = false;
    });
    builder.addCase(userContentReport.rejected, state => {
      state.reportContentLoading = false;
    });
  },
});

export default commentSlice.reducer;
