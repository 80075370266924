import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageCropper from 'components/imageCropper/ImageCropper';
import { IMAGE_EXTENSIONS } from 'constants/constants';
import { useMemo, useState } from 'react';
import {
  addBusinessImage,
  alertError,
  BusinessComponentType,
  IBusinessImageData,
  IImageUploadState,
  ImageType,
  IResponseData,
  updateBusinessImage,
  uploadProfilePhoto,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { capitalizeFirstLetter, dataURItoBlob, processImage } from 'utils/utils';
import { validateFileFormat, validateFileResolution, validateFileSize } from 'utils/validator';

import ImageBadgeComponent from './image/ImageBadgeComponent';
import UploadImageComponent from './image/UploadImageComponent';

const validImageWidth = 640;
const validImageHeight = 480;

type IUploadState = IImageUploadState;

interface IBusinessImagesProps {
  handleNext?: () => void;
  handlePrev?: () => void;
  hideButton?: boolean;
  componentType?: string;
  type?: string;
}

const { SETUP } = BusinessComponentType;

export default function BusinessImages(props: IBusinessImagesProps) {
  const {
    hideButton = false,
    handleNext = () => {},
    handlePrev = () => {},
    componentType,
    type = 'business',
  } = props;
  const dispatch = useAppDispatch();
  const { BACKGROUND, MEDIA, LOGO } = ImageType;
  const { businessDetails, loading } = useAppSelector(s => s.business);
  const { userData } = useAppSelector(s => s.account);
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const businessId: string = businessDetails.documentId ?? '';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logo =
    type === 'business' && businessDetails.images
      ? businessDetails.images?.logo ?? {}
      : userData.images?.logo ?? {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const background =
    type === 'business' && businessDetails.images
      ? businessDetails.images?.background ?? {}
      : userData.images?.background ?? {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const media =
    type === 'business' && businessDetails.images
      ? businessDetails.images?.media ?? []
      : userData.images?.media ?? [];
  const [upload, setUpload] = useState<IUploadState>({
    editImage: false,
    file: null,
    previewImage: '',
  });
  const [selectedImage, setSelectedImage] = useState<IBusinessImageData>({
    croppedImageReadUrl: '',
    documentId: '',
    originalImageReadUrl: '',
    croppedImageDetails: '',
    imageType: '',
  });
  const [imageType, setImageType] = useState<string>('');

  const handleEdit = (imageType: ImageType, carouselImageId = '') => {
    let imageDetails: IBusinessImageData;
    switch (imageType) {
      case BACKGROUND:
        imageDetails = background;
        break;
      case MEDIA:
        // eslint-disable-next-line no-case-declarations
        const selectedMediaImage = media.find(
          (img: IBusinessImageData) => img.documentId === carouselImageId,
        );
        imageDetails = selectedMediaImage ?? ({} as IBusinessImageData);
        break;
      case LOGO:
        imageDetails = logo;
        break;
      default:
        imageDetails = {} as IBusinessImageData;
    }
    setSelectedImage(imageDetails);
    setImageType(imageType);
    setUpload({ ...upload, editImage: true });
  };

  const selectFile = (imgType: string) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const isImage: boolean = file.type.includes('image');
    if (target?.files?.length === 1 && isImage) {
      if (isImage && !validateFileFormat(file.name, IMAGE_EXTENSIONS)) {
        dispatch(
          alertError({
            message:
              'File format is not supported. Please select one of the following file types: jpg, jpeg, png, heic or webp.',
          }),
        );
        return;
      }
      const maxSize = 100;
      const isFileSizeValid: boolean = validateFileSize(file.size, maxSize);

      const isFileResolutionValid = await validateFileResolution(
        file,
        validImageWidth,
        validImageHeight,
      );
      if (isFileSizeValid && isFileResolutionValid) {
        setUpload({
          ...upload,
          previewImage: await processImage(file),
          file,
          editImage: true,
        });
        setImageType(imgType);
      } else {
        const errorMessage = !isFileSizeValid
          ? `The image file must be ${maxSize} MB or less.`
          : `The image resolution must be ${validImageWidth} * ${validImageHeight} or higher.`;
        dispatch(alertError({ message: errorMessage }));
      }
    } else {
      dispatch(
        alertError({
          message: 'File format is not supported. Please select an image and try again.',
        }),
      );
    }
  };

  const handleCancelUploadImage = () => {
    setImageType('');
    setUpload({ ...upload, previewImage: '', file: null, editImage: false });
    setSelectedImage({} as IBusinessImageData);
  };

  const handleApply = async (cropper: any, minZoom: number, zoom: number) => {
    const croppedCanvas = cropper.getCroppedCanvas().toDataURL('image/jpeg');
    const blob = dataURItoBlob(croppedCanvas);
    const croppedFile = new File([blob], 'croppedImage.jpeg');
    let newFile: Blob | null = null;
    const createFile = async () => {
      try {
        const existingImage = selectedImage?.originalImageReadUrl ?? '';
        const response = await fetch(existingImage);
        const data = await response.blob();
        const metadata = {
          type: 'image/jpeg',
        };
        const file = new File(
          [data],
          type === 'business' ? 'businessImage.jpeg' : 'profileImage.jpeg',
          metadata,
        );
        // ... do something with the file or return it
        return file;
      } catch (error) {
        dispatch(
          alertError({ message: `Error while uploading business image, Please try again later` }),
        );
        return null;
      }
    };
    if (upload.file === null) {
      newFile = await createFile();
      if (newFile === null) return;
    }
    const uploadImageData = {
      image: upload.file || newFile,
      croppedImage: croppedFile,
      imageViewAttribute: JSON.stringify({
        data: cropper.getData(false),
        canvasData: cropper.getCanvasData(),
        cropBoxData: cropper.getCropBoxData(),
        minZoom,
        zoom,
      }),
      imageType,
    };
    if (type === 'business') {
      if (selectedImage.documentId) {
        dispatch(updateBusinessImage(uploadImageData, selectedImage.documentId)).then(
          (response: IResponseData) => {
            if (!response.error) {
              handleCancelUploadImage();
            }
          },
        );
      } else {
        dispatch(addBusinessImage(uploadImageData, businessId)).then((response: IResponseData) => {
          if (!response.error) {
            handleCancelUploadImage();
          }
        });
      }
    } else {
      dispatch(uploadProfilePhoto(uploadImageData, selectedImage.documentId)).then(
        (response: IResponseData) => {
          if (!response.error) {
            handleCancelUploadImage();
          }
        },
      );
    }
  };

  const isValid = useMemo(() => {
    const valid = [
      background && Object.keys(background).length > 0 && background?.documentId !== '',
      media && media?.length >= 1,
      logo && Object.keys(logo).length > 0 && logo?.documentId !== '',
    ].every(Boolean);
    return valid;
  }, [background, media, logo]);

  return (
    <Stack spacing={theme.spacing(3)} width="100%">
      {upload.editImage && (
        <ImageCropper
          enableZoom
          footer
          header
          {...([BACKGROUND, LOGO].includes(imageType as ImageType) && {
            aspectRatio: imageType === LOGO ? 1 : 21 / 9,
          })}
          croppedImageData={
            selectedImage?.croppedImageDetails ? JSON.parse(selectedImage.croppedImageDetails) : {}
          }
          handleApply={handleApply}
          handleCancelUploadImage={handleCancelUploadImage}
          image={selectedImage.originalImageReadUrl}
          imageType={imageType === LOGO ? 'square' : 'rectangle'}
          initialAspectRatio={21 / 9}
          loading={loading}
          previewImage={upload.previewImage}
          selectFile={selectFile(imageType)}
          styles={{ minHeight: 400, maxHeight: 600, width: '100%' }}
          title={`Upload ${capitalizeFirstLetter(imageType)}`}
        />
      )}
      <Typography
        align={componentType === SETUP ? 'center' : 'left'}
        component="h4"
        variant={isMobile ? 'h5' : componentType === SETUP ? 'h4' : 'h3'}
      >
        Images
        <Typography component="div" sx={{ marginTop: theme.spacing(0.5) }} variant="caption">
          Note:{' '}
          {`The images resolution must be ${validImageWidth} * ${validImageHeight} or higher.`}
        </Typography>
      </Typography>
      {componentType === SETUP && (
        <Typography
          align="center"
          component="div"
          fontWeight={400}
          marginTop={`${theme.spacing(1.5)} !important`}
          variant="title2"
        >
          Add images to your profile
        </Typography>
      )}
      <Stack spacing={theme.spacing(0.75)}>
        <Typography component="h5" variant="body1Bold">
          {type === 'business' ? 'Logo' : 'Avatar'}
        </Typography>
        <Stack alignItems="start">
          {logo?.croppedImageReadUrl ? (
            <ImageBadgeComponent
              handleEditImage={() => handleEdit(LOGO)}
              imageSrc={logo?.croppedImageReadUrl}
              imageType={LOGO}
            />
          ) : (
            <UploadImageComponent fileType={LOGO} selectFile={selectFile(LOGO)} />
          )}
        </Stack>
      </Stack>
      <Stack spacing={theme.spacing(0.75)}>
        <Typography component="h5" variant="body1Bold">
          Background
        </Typography>
        <Stack alignItems="start">
          {background?.croppedImageReadUrl ? (
            <ImageBadgeComponent
              handleEditImage={() => handleEdit(BACKGROUND)}
              imageSrc={background?.croppedImageReadUrl}
              imageType={BACKGROUND}
            />
          ) : (
            <UploadImageComponent fileType={BACKGROUND} selectFile={selectFile(BACKGROUND)} />
          )}
        </Stack>
      </Stack>

      {!hideButton && (
        <>
          <Stack
            direction="row"
            marginTop={`${theme.spacing(4)} !important`}
            spacing={theme.spacing(3)}
            width="100%"
          >
            <Button fullWidth variant="outlined" onClick={handlePrev}>
              Back
            </Button>
            <Button fullWidth disabled={!isValid} variant="contained" onClick={handleNext}>
              Next
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
}
