import LoadingButton from '@mui/lab/LoadingButton';
import { Button, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import BusinessInformation from 'components/business/BusinessInformation';
import { useSetupBusinessInformationForm } from 'components/business/useBusinessForm';
import ConfirmationModal from 'components/confirmationModal/ConfirmationModal';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import { BackIcon } from 'helpers/images';
import { useState } from 'react';
import { createBusinessFromAdmin, IMembers } from 'store';
import {
  BusinessComponentType,
  IBusinessInformationState,
  ISetupBusinessInformationState,
} from 'store/business/BusinessInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { unmaskUSNumber } from 'utils/utils';
import { setupBusinessInformationSchema } from 'utils/validationSchema';

interface IBusinessInformationProps {
  handleNext?: () => void;
  selectedMemberData: IMembers;
  selectedBusinessType: string;
  setActiveType: (type: string) => void;
  setMemberModal: () => void;
  isNewAddMember?: boolean;
}

const { SETUP } = BusinessComponentType;

function AdminBusinessSignUp({
  isNewAddMember,
  selectedMemberData,
  selectedBusinessType,
  setMemberModal,
  setActiveType,
}: IBusinessInformationProps) {
  const dispatch = useAppDispatch();

  const { addBusinessLoading } = useAppSelector(s => s.vettingDashboard);

  const [showModal, setShowModal] = useState(false);

  const businessInfoInitialState: IBusinessInformationState = {
    name: '',
    businessCategories: [],
    address: '',
    website: '',
    city: '',
    stateId: '',
    phone: '',
    zipcode: '',
    onlineOnly: false,
    email: '',
    contactPersonName: '',
  };

  const handleUpdateBusiness = async () => {
    setShowModal(false);
    const requestData = {
      ...values.businessInformation,
      phone: unmaskUSNumber(values.businessInformation.phone),
      type: selectedBusinessType,
      userID: selectedMemberData.documentId,
      userEmail: selectedMemberData.email,
    };

    const { data: response }: any = await dispatch(createBusinessFromAdmin(requestData));

    if (response) {
      setMemberModal();
    }
  };

  const handleBusinessInfo = () => setShowModal(true);

  const setupBusinessInfoInitialState: ISetupBusinessInformationState = {
    businessInformation: businessInfoInitialState,
  };

  const formik = useSetupBusinessInformationForm(
    handleBusinessInfo,
    setupBusinessInfoInitialState,
    setupBusinessInformationSchema,
  );

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const isFree = selectedBusinessType === 'free' || false;

  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const handleBack = () => {
    if (isNewAddMember) {
      setActiveType('adminAddMember');
    } else {
      setActiveType('BusinessType');
    }
  };

  return (
    <Box noValidate component="form" onSubmit={handleSubmit}>
      <Box position="absolute">
        <Button onClick={handleBack}>
          <SvgIcon inheritViewBox component={BackIcon} sx={{ height: '16px', width: '10px' }} />
          <Typography component="span" sx={{ ml: theme.spacing(1.25) }}>
            Back
          </Typography>
        </Button>
      </Box>
      <BusinessInformation
        componentType={SETUP}
        errors={errors.businessInformation}
        handleBlur={handleBlur}
        handleChange={handleChange}
        isFree={isFree}
        setFieldValue={setFieldValue}
        setTouched={setFieldTouched}
        touched={touched.businessInformation}
        values={values.businessInformation}
      />
      <LoadingButton
        disableRipple
        fullWidth
        disabled={!isValid}
        loading={addBusinessLoading}
        size="large"
        sx={{
          mt: theme.spacing(4),
        }}
        type="submit"
        variant="contained"
      >
        Next
      </LoadingButton>

      <ToggleDrawerDialog
        showCloseIcon
        handleClose={() => setShowModal(false)}
        isMobile={isMobile}
        open={showModal}
        setOpen={setShowModal}
        title="Create Business"
      >
        <ConfirmationModal
          loading={addBusinessLoading}
          modalText={`You are creating a ${
            selectedBusinessType === 'free' ? 'Basic' : 'Premier'
          } Business for ${values.businessInformation.name}.
          Confirming will send ${selectedMemberData.userName} their Notification Email.
          If you cancel no Business will be created and progress will not be saved.`}
          submitBtnText="Confirm"
          onClose={() => setShowModal(false)}
          onSubmit={handleUpdateBusiness}
        />
      </ToggleDrawerDialog>
    </Box>
  );
}

export default AdminBusinessSignUp;
