import { useCallback } from 'react';

interface ICard {
  gaps: number[];
  lengths: number[];
  code: {
    size: number;
  };
  type: string;
}

const useCard = () => {
  const FALLBACK_CARD = {
    gaps: [4, 8, 12],
    lengths: [16],
    type: 'visa',
    code: { size: 3 },
  };
  const removeNonNumber = useCallback((string = '') => string.replace(/[^\d]/g, ''), []);
  const limitLength = useCallback(
    (maxLength: number, string = '') => string.substr(0, maxLength),
    [],
  );
  const addGaps = useCallback((gaps: number[], string = '') => {
    const offsets = [0].concat(gaps).concat([string.length]);
    return offsets
      .map((end, index) => {
        if (index === 0) {
          return '';
        }
        const start = offsets[index - 1];
        return string.substr(start, end - start);
      })
      .filter(part => part !== '')
      .join(' ');
  }, []);

  const formatCardNumber = useCallback(
    (number: string, card: ICard) => {
      const numberSanitized = removeNonNumber(number);
      const maxLength = card.lengths[card.lengths.length - 1];
      const lengthSanitized = limitLength(maxLength, numberSanitized);
      const formatted = addGaps(card.gaps, lengthSanitized);
      return formatted;
    },
    [addGaps, limitLength, removeNonNumber],
  );

  return {
    FALLBACK_CARD,
    formatCardNumber,
    removeNonNumber,
  };
};
export default useCard;
