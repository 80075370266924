import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Facebook from 'assets/icons/facebook.svg';
import Gettr from 'assets/icons/gettr.svg';
import Instagram from 'assets/icons/instagram.svg';
import Telegram from 'assets/icons/telegram.svg';
import Tiktok from 'assets/icons/tiktok.svg';
import Twitter from 'assets/icons/twitter.svg';
import AboutImage from 'assets/images/freedom-square-patriotism-freedom-integrity-progress-about.webp';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import theme from 'theme';

function About() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const smallDevice = useMediaQuery(theme.breakpoints.down('midxs'));
  return (
    <>
      <HomeWrapper>
        <Stack
          alignItems="center"
          direction="column"
          justifyContent="center"
          spacing={theme.spacing(2)}
        >
          <Typography gutterBottom textAlign="center" variant={smallDevice ? 'h4' : 'h3'}>
            Welcome to Freedom Square
          </Typography>
          <Typography gutterBottom textAlign="center" variant="h6">
            A Digital Commonwealth for freedom-loving Americans to access News & Information,
            Podcasts, a Business Network, and a Resource Center rich in American history and key
            resources for sustaining our Liberty.
          </Typography>
          <Box
            alt="about"
            component="img"
            src={AboutImage}
            sx={{
              overflow: 'hidden',
              objectFit: 'cover',
              width: '100%',
              py: theme.spacing(5),
            }}
          />
        </Stack>
      </HomeWrapper>
      <Box
        sx={{
          padding: theme.spacing(3),
          backgroundColor: '#28356A',
        }}
      >
        <HomeWrapper>
          <Stack
            alignItems={isMobile ? 'center' : 'flex-start'}
            direction={isMobile ? 'column-reverse' : 'row'}
            justifyContent={isMobile ? 'center' : 'space-between'}
            spacing={theme.spacing(5)}
          >
            <Typography component="div" justifyContent="center" textAlign="center">
              {/* <Typography
                color="white"
                component="div"
                sx={{ textTransform: "uppercase" }}
                variant="overline"
              >
                Contact Us
              </Typography>
              <Typography color="white" component="div" variant="overline">
                <Link
                  color="white"
                  href="mailto:helpdesk@freedomsquare.com"
                  rel="noopener noreferrer"
                  target="_top"
                  underline="hover"
                >
                  helpdesk@freedomsquare.com
                </Link>
              </Typography> */}
              <Typography
                color="white"
                component="div"
                sx={{ textTransform: 'uppercase' }}
                variant="overline"
              >
                @{new Date().getFullYear()}. All rights reserved.
              </Typography>
            </Typography>
            <Typography component="div" justifyContent="center" textAlign="center">
              <Typography color="white" component="div" variant="overline">
                <Link
                  color="white"
                  href="/terms-and-conditions"
                  rel="Private Policy"
                  target="_blank"
                  underline="hover"
                >
                  Terms of Use
                </Link>
              </Typography>
              <Typography color="white" component="div" variant="overline">
                <Link
                  color="white"
                  href="/privacy-policy"
                  rel="Private Policy"
                  target="_blank"
                  underline="hover"
                >
                  Privacy Policy
                </Link>
              </Typography>
            </Typography>
            <Typography component="div" justifyContent="center" textAlign="center">
              <Typography gutterBottom color="white" component="div" variant="overline">
                Let&apos;s get social
              </Typography>
              <Typography component="div">
                <Stack
                  alignItems="flex-start"
                  direction="row"
                  justifyContent="space-between"
                  spacing={theme.spacing(2)}
                >
                  <Link
                    href="https://www.facebook.com/FreedomSquareUSA"
                    rel="facebook"
                    target="_blank"
                    underline="none"
                  >
                    <Box
                      alt="facebook"
                      component="img"
                      src={Facebook}
                      sx={{
                        width: '10px',
                      }}
                    />
                  </Link>
                  <Link
                    href="https://www.instagram.com/freedomsquareusa/"
                    rel="instagram"
                    target="_blank"
                    underline="none"
                  >
                    <Box
                      alt="instagram"
                      component="img"
                      src={Instagram}
                      sx={{
                        width: '20px',
                      }}
                    />
                  </Link>
                  <Link
                    href="https://twitter.com/freedomsquareus"
                    rel="twitter"
                    target="_blank"
                    underline="none"
                  >
                    <Box
                      alt="twitter"
                      component="img"
                      src={Twitter}
                      sx={{
                        width: '20px',
                      }}
                    />
                  </Link>
                  <Link
                    href="https://www.tiktok.com/@freedomsquareus"
                    rel="tiktok"
                    target="_blank"
                    underline="none"
                  >
                    <Box
                      alt="tiktok"
                      component="img"
                      src={Tiktok}
                      sx={{
                        width: '20px',
                      }}
                    />
                  </Link>
                  <Link
                    href="https://t.me/freedomsquareusa"
                    rel="telegram"
                    target="_blank"
                    underline="none"
                  >
                    <Box
                      alt="telegram"
                      component="img"
                      src={Telegram}
                      sx={{
                        width: '20px',
                      }}
                    />
                  </Link>
                  <Link
                    href="https://gettr.com/user/freedomsquare"
                    rel="gettr"
                    target="_blank"
                    underline="none"
                  >
                    <Box
                      alt="gettr"
                      component="img"
                      src={Gettr}
                      sx={{
                        width: '20px',
                      }}
                    />
                  </Link>
                </Stack>
              </Typography>
            </Typography>
          </Stack>
        </HomeWrapper>
      </Box>
    </>
  );
}

export default About;
