import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import { useCallback, useEffect, useState } from 'react';
import {
  fetchFollowedPaidUsers,
  followPaidUsers,
  getPreferencesAllPaidUsers,
  IPaidUser,
  IResponseData,
  resetAllPaidUsersState,
  resetPaidUsersSearchState,
  searchPaidUsers,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

import SavePreferencesBtn from '../SavePreferencesBtn';

interface IProps {
  searchQuery: string;
}

function Users({ searchQuery }: IProps) {
  const dispatch = useAppDispatch();
  const {
    allPaidUsers,
    searchedPaidUsers,
    allPaidUsersLoading,
    followPaidUsersLoading,
    isSearchPaidUsersLoading,
    followedPaidUsers,
  } = useAppSelector(s => s.followingTimeline);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paidUsers, setPaidUsers] = useState<IPaidUser[]>([]);
  const [selectedPaidUserIds, setSelectedPaidUserIds] = useState<string[]>([]);

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const fetchAllPaidUsers = async (offset: number) => {
    const response = (await dispatch(getPreferencesAllPaidUsers(offset))) as IResponseData;
    if (!response.error) {
      const allPaidUsers: IPaidUser[] =
        offset === 0 ? [...response.data] : [...paidUsers, ...response.data];
      setPaidUsers(allPaidUsers);
    }
  };

  const fetchFollowedPaidUserIds = async () => {
    const response = (await dispatch(fetchFollowedPaidUsers())) as IResponseData;
    if (!response.error) {
      setSelectedPaidUserIds(response.data || []);
    }
  };

  const fetchSearchPaidUsers = async (isSameQuery: boolean) => {
    if (!isSameQuery) {
      setPaidUsers([]);
    }
    const response = (await dispatch(
      searchPaidUsers(searchQuery, isSameQuery ? searchedPaidUsers.page + 1 : 1),
    )) as IResponseData;
    if (!response.error) {
      let allSearchPaidUsers: IPaidUser[] = [];
      if (isSameQuery) {
        allSearchPaidUsers = [...paidUsers, ...response.data];
        setPaidUsers(allSearchPaidUsers);
      } else {
        allSearchPaidUsers = [...response.data];
        setPaidUsers(allSearchPaidUsers);
      }
    }
  };

  const handleScroll = (event: any) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;

    if (scrollHeight <= Math.ceil(scrollTop + clientHeight)) {
      if (searchQuery.length >= 3 && paidUsers.length > 0) {
        if (searchedPaidUsers.hasMoreData && !isSearchPaidUsersLoading) {
          fetchSearchPaidUsers(true);
        }
      } else if (searchQuery.length < 3 && allPaidUsers.hasMoreData && !allPaidUsersLoading) {
        fetchAllPaidUsers(allPaidUsers.offset);
      }
    }
  };

  const handleUsersCheck = (index: number) => {
    const paidUserIds = [...selectedPaidUserIds];
    const paidUserIdIndex = paidUserIds.findIndex(x => x === paidUsers[index].documentId);
    if (paidUserIdIndex === -1) {
      paidUserIds.push(paidUsers[index].documentId);
    } else {
      paidUserIds.splice(paidUserIdIndex, 1);
    }
    setSelectedPaidUserIds(paidUserIds);
  };

  const saveSelectedPaidUsers = async () => {
    const response = (await dispatch(
      followPaidUsers(selectedPaidUserIds, paidUsers, searchQuery.length > 0),
    )) as IResponseData;
    if (!response.error) {
      fetchFollowedPaidUserIds();
    }
  };

  const handleDisable = useCallback(() => {
    const paidUserIdsSelectedSorted = followedPaidUsers.slice().sort();
    return (
      selectedPaidUserIds.length === followedPaidUsers.length &&
      selectedPaidUserIds
        .slice()
        .sort()
        .every(function (value, index) {
          return value === paidUserIdsSelectedSorted[index];
        })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPaidUsers.paidUsers, paidUsers, searchedPaidUsers.paidUsers, selectedPaidUserIds]);

  useEffect(() => {
    setIsLoading(true);
    fetchAllPaidUsers(allPaidUsers.offset);
    fetchFollowedPaidUserIds();
    return () => {
      dispatch(resetAllPaidUsersState());
      dispatch(resetPaidUsersSearchState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchQuery.length === 0 && searchedPaidUsers.query.length > 0) {
      if (searchedPaidUsers.paidUsers.length > 0) {
        setPaidUsers([]);
        dispatch(resetPaidUsersSearchState());
        dispatch(resetAllPaidUsersState());
        setIsLoading(true);
        fetchAllPaidUsers(0);
      } else {
        setPaidUsers(allPaidUsers.paidUsers.length > 0 ? allPaidUsers.paidUsers : []);
      }
    }
    const debounceSearchFn = setTimeout(async () => {
      if (searchQuery.length >= 3) {
        const isSameQuery = searchQuery === searchedPaidUsers.query;
        if (isSameQuery) {
          return;
        }
        setIsLoading(true);
        fetchSearchPaidUsers(isSameQuery);
      }
    }, 200);
    return () => clearTimeout(debounceSearchFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (allPaidUsersLoading !== isLoading) {
      setIsLoading(allPaidUsersLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPaidUsersLoading]);

  useEffect(() => {
    if (isSearchPaidUsersLoading !== isLoading) {
      setIsLoading(isSearchPaidUsersLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchPaidUsersLoading]);

  return (
    <>
      <Stack
        className="custom-pref-scrollbar"
        height={isMobile ? 'calc(100vh - 275px)' : '100%'}
        paddingBottom={theme.spacing(1)}
        paddingRight={theme.spacing(2)}
        spacing={theme.spacing(1.5)}
        sx={{
          overflowY: 'auto',
        }}
        onScroll={handleScroll}
      >
        {paidUsers && paidUsers.length > 0
          ? paidUsers
              .filter(paidUser => paidUser.userName !== 'owner')
              .map((paidUser, index) => {
                const name = paidUser.fullName || paidUser.userName || 'provider-logo';
                return (
                  <Stack
                    key={paidUser.documentId + index.toString()}
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Stack alignItems="center" direction="row">
                      <Avatar
                        alt={name}
                        src={name}
                        sx={{
                          width: theme.spacing(3),
                          height: theme.spacing(2.75),
                          background: theme.palette.secondary.main,
                          marginRight: theme.spacing(2),
                        }}
                        variant="square"
                      />
                      <Typography className="text-ellipsis text-clamp-2">{name}</Typography>
                    </Stack>
                    <IconButton
                      aria-label="remove"
                      color="primary"
                      size="medium"
                      sx={{ padding: theme.spacing(0) }}
                      onClick={() => handleUsersCheck(paidUsers.indexOf(paidUser))}
                    >
                      {selectedPaidUserIds.includes(paidUser.documentId) ? (
                        <CheckCircleIcon fontSize="inherit" />
                      ) : (
                        <ControlPointIcon fontSize="inherit" />
                      )}
                    </IconButton>
                  </Stack>
                );
              })
          : !isLoading && (
              <Stack alignItems="center" flexGrow={1} height="100%" justifyContent="center">
                <Typography align="center" component="div" variant="title2">
                  No users found
                </Typography>
              </Stack>
            )}
        {isLoading && <Loader customPaddingThemeSpacing={1} size={30} />}
      </Stack>
      <SavePreferencesBtn
        disableFn={handleDisable}
        loading={followPaidUsersLoading}
        onSave={saveSelectedPaidUsers}
      />
    </>
  );
}

export default Users;
