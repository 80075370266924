import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import NativeSelect, { NativeSelectProps } from '@mui/material/NativeSelect';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ArrowDown } from 'helpers/images';
import theme from 'theme';

const CustomInputSelect = styled(InputBase)(({ error, theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: '4px',
    border: `0.25px solid ${error ? theme.palette.error.main : theme.palette.text.secondary}`,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    position: 'relative',
    padding: theme.spacing(1.25, 1.5),
    '&:focus': {
      borderRadius: 0,
      borderColor: !error ? theme.palette.text.primary : '',
    },
  },
  '& .MuiNativeSelect-icon': {
    right: '16px',
    top: 'calc(50% - 0.2em)',
  },
}));

export interface ICustomSelectFieldProps extends NativeSelectProps {
  children?: React.ReactNode | JSX.Element;
  label: string;
  errormessage?: string;
  native?: boolean;
}

function CustomSelectField(props: ICustomSelectFieldProps) {
  const { children, label, error, errormessage } = props;
  return (
    <Stack direction="column" spacing={0.75} sx={{ width: '100%' }}>
      {label.length > 0 && (
        <Typography
          color={error ? theme.palette.error.main : ''}
          component="span"
          variant="body1Bold"
        >
          {label}
        </Typography>
      )}
      <FormControl fullWidth>
        <NativeSelect
          IconComponent={ArrowDown}
          input={<CustomInputSelect error={error} />}
          {...props}
        >
          {children}
        </NativeSelect>
      </FormControl>
      {error ? (
        <Typography
          color={theme.palette.error.main}
          fontSize="13px"
          marginTop={`${theme.spacing(1)} !important`}
          variant="body1"
        >
          {errormessage}
        </Typography>
      ) : null}
    </Stack>
  );
}

export default CustomSelectField;
