import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import NoDataAvailable from 'components/notFound/NoDataAvailable';
import GuestReactionView from 'components/reactions/GuestReactionView';
import AdvertisementTile from 'components/tile/AdvertisementTile';
import PostTile from 'components/tile/PostTile';
import SignUpTile from 'components/tile/SignUpTile';
import Tile from 'components/tile/Tile';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { Fragment, useEffect, useState } from 'react';
import { AdsPages } from 'store/advertisements/AdvertisementInterface';
import { getTimelinePosts } from 'store/homeTimeline/HomeTimelineActions';
import { ITimelinePost } from 'store/homeTimeline/HomeTimelineInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

function FeaturedPosts() {
  const dispatch = useAppDispatch();
  const { isGetFeaturedPostsLoading, featuredPosts } = useAppSelector(s => s.homeTimeline);
  const { checkUserLoggedIn } = useIsLoggedIn();
  const [showModal, setShowModal] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(getTimelinePosts(0, 18));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ComponentWrapper isTitleInsideWrapper title="Featured Posts">
        <>
          {isGetFeaturedPostsLoading ? (
            <Loader />
          ) : featuredPosts && featuredPosts.length > 0 ? (
            <>
              {featuredPosts.map((post: ITimelinePost, index: number) => {
                return (
                  <Fragment key={(post?.documentId as string) + index.toString()}>
                    {index === 3 && !checkUserLoggedIn() && (
                      <Box margin={theme.spacing(isMobile ? 3 : 4, 0)}>
                        <SignUpTile />
                      </Box>
                    )}
                    {(index + 1) % 5 === 0 && (
                      <AdvertisementTile
                        useCommonData
                        page={AdsPages.HOME}
                        position="A"
                        uniqueKey={index + 1}
                      />
                    )}
                    {['paid-member-post', 'business', 'town-crier-post'].includes(post?.type) ? (
                      <PostTile
                        componentType="home"
                        post={post}
                        type="featured"
                        withDivider={
                          (index === 2 && !checkUserLoggedIn()) || (index + 2) % 5 === 0
                            ? false
                            : !(featuredPosts?.length === index + 1)
                        }
                      />
                    ) : (
                      <Tile
                        key={(post?.documentId as string) + index.toString()}
                        commentsCount={post?.commentsCount}
                        componentType="home"
                        description={post?.description}
                        id={post?.postId}
                        image={post?.thumbnail as string}
                        isFeatured={post?.isFeatured}
                        isLiked={post?.isLiked}
                        likesCount={post?.likesCount}
                        name={post?.postProvider}
                        postedAt={post?.postedAt as string}
                        providerId={post?.postProviderId}
                        providerLogo={post?.postProviderLogo}
                        tileType={isMobile ? 'small' : 'large'}
                        title={post?.title}
                        type={post?.type}
                        withDivider={
                          (index === 2 && !checkUserLoggedIn()) || (index + 2) % 5 === 0
                            ? false
                            : !(featuredPosts?.length === index + 1)
                        }
                      />
                    )}
                  </Fragment>
                );
              })}
            </>
          ) : (
            <NoDataAvailable type="featured posts" />
          )}
        </>
      </ComponentWrapper>
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </>
  );
}

export default FeaturedPosts;
