import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { UserType } from 'constants/constants';
import { AddIcon } from 'helpers/images';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';
import { CookieNames, getCookieItem } from 'utils/cookies';
import { checkPaidBusiness } from 'utils/utils';

export interface ILinkText {
  title: string;
  route: string;
  sendHash?: boolean;
  hashValue?: string;
}

export function LinkText({ title, route, sendHash, hashValue }: ILinkText) {
  return (
    <Box
      end
      component={NavLink}
      sx={{
        textDecoration: 'none !important',
        '&.active .MuiTypography-root': {
          fontWeight: 600,
          color: theme.palette.primary.main,
        },
      }}
      to={{
        pathname: route,
        ...(sendHash && { hash: hashValue }),
      }}
    >
      <Typography variant="menu">{title}</Typography>
    </Box>
  );
}

function HeaderMobileMenu() {
  const { checkUserLoggedIn } = useIsLoggedIn();
  const isUserLoggedIn = checkUserLoggedIn();
  const userRole = getCookieItem(CookieNames.USER_ROLE);
  const { businessDetails, userBusinessId } = useAppSelector(s => s.business);
  const { userData } = useAppSelector(s => s.account);

  const isBusinessSetupCompleted = useMemo(
    () => checkPaidBusiness(businessDetails),
    [businessDetails],
  );

  return (
    <>
      <LinkText route={isUserLoggedIn ? '/home' : '/'} title="Home" />
      {isUserLoggedIn && (
        <>
          <LinkText route="/following" title="Following" />
        </>
      )}
      <LinkText route="/trending" title="Trending" />
      <Divider />
      <LinkText route="/news" title="News" />
      <LinkText route="/podcasts" title="Podcasts" />
      <LinkText route="/businesses" title="Businesses" />
      <Divider />
      <LinkText route="/resources" title="Resources" />
      <LinkText route="/about" title="About" />
      {isUserLoggedIn && userData.userType === UserType.PAID && (
        <>
          <Divider />
          <Stack alignItems="center" direction="row" spacing={theme.spacing(1.5)}>
            <LinkText
              hashValue="profile"
              route={`/profile-wall/${userData.documentId}`}
              sendHash={!isUserLoggedIn}
              title="Profile Wall"
            />
          </Stack>
        </>
      )}
      <Divider />
      <Stack alignItems="center" direction="row" spacing={theme.spacing(1.5)}>
        {!isBusinessSetupCompleted ? (
          <SvgIcon
            inheritViewBox
            component={AddIcon}
            sx={{
              width: theme.spacing(2),
              height: theme.spacing(2),
              '& path': { fill: theme.palette.common.black },
            }}
          />
        ) : null}
        <LinkText
          hashValue="business"
          route={
            isUserLoggedIn
              ? isBusinessSetupCompleted
                ? `/business-wall/${userBusinessId}`
                : '/business-plan'
              : '/signup'
          }
          sendHash={!isUserLoggedIn}
          title={isBusinessSetupCompleted ? 'Business Wall' : 'Add Business'}
        />
      </Stack>

      {userRole.split(',').includes('admin') && (
        <>
          <Divider />
          <LinkText route="/admin-dashboard" title="Admin Dashboard" />
        </>
      )}
    </>
  );
}

export default HeaderMobileMenu;
