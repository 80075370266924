import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/system/Box';
import Stack from '@mui/system/Stack';
import { PLAN_NAMES, UserType } from 'constants/constants';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';
import { CookieNames, getCookieItem } from 'utils/cookies';
import { scrollToTop } from 'utils/utils';

function BusinessAccountSubmission() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const businessType = getCookieItem(CookieNames.BUSINESS_TYPE);
  const { businessDetails } = useAppSelector(s => s.business);

  useEffect(() => {
    scrollToTop();
  }, []);

  const isFree =
    location?.state === 'free'
      ? true
      : location?.state === 'payment_initiated'
      ? false
      : businessType === 'free' || businessType === '';

  return (
    <Stack alignItems="center" spacing={theme.spacing(2.5)}>
      <Typography
        align="center"
        component="span"
        marginBottom={theme.spacing(isMobile ? 1 : 2)}
        variant={isMobile ? 'h5' : 'h4'}
      >
        {isFree ? PLAN_NAMES.free_business : PLAN_NAMES.paid_business_initiated} Submission
      </Typography>
      {(businessType === UserType.PAYMENT_INITIATED || businessType === UserType.PAID) && (
        <Typography
          align="center"
          component="span"
          fontWeight={600}
          marginBottom={theme.spacing(isMobile ? 3 : 5)}
          variant="title2"
        >
          Payment Successful
        </Typography>
      )}
      <Typography
        align="center"
        component="span"
        marginBottom={theme.spacing(isMobile ? 3 : 5)}
        variant="title2"
      >
        Welcome to Freedom Square
      </Typography>
      <Typography
        align="center"
        component="span"
        marginBottom={theme.spacing(isMobile ? 3 : 5)}
        variant="title2"
      >
        Please take a moment to review your new business page.
      </Typography>
      <Typography
        align="center"
        component="span"
        marginBottom={theme.spacing(isMobile ? 3 : 5)}
        variant="title2"
      >
        Updates can be made via your account profile.
      </Typography>
      <Box sx={{ mt: `${theme.spacing(4)} !important` }} width="100%">
        <Button
          disableRipple
          disableTouchRipple
          fullWidth
          size="large"
          variant="contained"
          onClick={() => navigate(`/business-wall/${businessDetails?.documentId}`)}
        >
          Go to My Business Page
        </Button>
      </Box>
    </Stack>
  );
}

export default BusinessAccountSubmission;
