import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SvgIcon from '@mui/material/SvgIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchInputTextField from 'components/customFields/SearchInputField';
import { SearchIcon } from 'helpers/images';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { activeSearch, resetSearchState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

interface ISearchProps {
  searchMobile?: boolean;
}

function Search({ searchMobile }: ISearchProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const pathName = useMemo(() => location.pathname, [location.pathname]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { searchData, loading } = useAppSelector(s => s.search);

  useEffect(() => {
    if (!pathName.includes('/search')) {
      setSearchQuery('');
    } else if (pathName.includes('/search')) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  useEffect(() => {
    if (searchQuery.length === 0 && Object.keys(searchData.data).length > 0) {
      dispatch(resetSearchState());
    }
    const debounceSearchFn = setTimeout(() => {
      if (searchQuery.length >= 3 && searchQuery !== searchData.query) {
        dispatch(activeSearch(searchQuery, true, pathName)).then(() => {
          if (!pathName.includes('/search')) {
            setOpen(true);
          } else {
            scrollToTop();
            searchParams.set('q', searchQuery);
            setSearchParams(searchParams);
          }
        });
      }
    }, 200);

    return () => clearTimeout(debounceSearchFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (open) {
          setSearchQuery('');
          setOpen(false);
        }
      }}
    >
      {!isMobile || searchMobile ? (
        <Box
          autoComplete="off"
          boxSizing="border-box"
          component="form"
          maxWidth="422px"
          paddingLeft={theme.spacing(3)}
          paddingRight={theme.spacing(8)}
          width="100%"
          {...(searchMobile && {
            borderTop: `0.25px solid ${theme.palette.common.white}`,
            backgroundColor: theme.palette.primary.main,
            maxWidth: '100%',
            padding: theme.spacing(1.5, 3),
          })}
        >
          <SearchInputTextField
            loading={loading}
            open={open}
            posts={searchData.data}
            setOpen={setOpen}
            setValue={setSearchQuery}
            value={searchQuery}
          />
        </Box>
      ) : (
        <Box
          marginRight={theme.spacing(2)}
          marginTop={theme.spacing(0.75)}
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate('/search')}
        >
          <SvgIcon
            inheritViewBox
            component={SearchIcon}
            sx={{
              height: isMobile ? theme.spacing(2.25) : 'auto',
              width: isMobile ? theme.spacing(2.25) : 'auto',
              '& path': {
                fill: theme.palette.common.white,
              },
            }}
          />
        </Box>
      )}
    </ClickAwayListener>
  );
}

export default Search;
