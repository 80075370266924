const {
  REACT_APP_ADS_URL,
  REACT_APP_AUTH_URL,
  REACT_APP_BASE_UI_URL,
  REACT_APP_BASE_API_URL,
  REACT_APP_PAYMENT_URL,
  REACT_APP_NEWS_URL,
  REACT_APP_BUSINESS_URL,
  REACT_APP_RESOURCE_V1_URL,
  REACT_APP_RESOURCE_V2_URL,
  REACT_APP_PODCAST_URL,
  REACT_APP_TIMELINE_URL,
  REACT_APP_AFFILIATE_URL,
  REACT_APP_VERSION,
  REACT_APP_SPONSOR_URL,
} = process.env;

const baseApiUrl = `${REACT_APP_BASE_API_URL}`;
const baseUIUrl = `${REACT_APP_BASE_UI_URL}`;
const adsUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_ADS_URL}`;
const authUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_AUTH_URL}`;
const paymentUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_PAYMENT_URL}`;
const businessUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_BUSINESS_URL}`;
const newsUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_NEWS_URL}`;
const openGraphUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_RESOURCE_V1_URL}`;
const resourceUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_RESOURCE_V2_URL}`;
const podcastUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_PODCAST_URL}`;
const timelineUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_TIMELINE_URL}`;
const affiliateUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_AFFILIATE_URL}`;
const sponsorUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_SPONSOR_URL}`;
const appVersion = REACT_APP_VERSION;

export {
  adsUrl,
  affiliateUrl,
  appVersion,
  authUrl,
  baseApiUrl,
  baseUIUrl,
  businessUrl,
  newsUrl,
  openGraphUrl,
  paymentUrl,
  podcastUrl,
  resourceUrl,
  sponsorUrl,
  timelineUrl,
};
