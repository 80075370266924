import { useAppSelector } from 'store/hooks';
import { CookieNames, getCookieItem } from 'utils/cookies';

const useIsLoggedIn = () => {
  const { isLoggedIn } = useAppSelector(s => s.auth);

  const getUserToken = (): string => {
    const token = getCookieItem(CookieNames.ACCESS_TOKEN);
    return token;
  };

  const checkUserLoggedIn = (): boolean | null => {
    const token = getUserToken();
    return isLoggedIn && token !== '';
  };

  return { checkUserLoggedIn, getUserToken };
};

export default useIsLoggedIn;
