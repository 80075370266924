import { Reducer } from 'redux';

import { IOGResponse, IResourceState, ResourceActions } from './ResourceInterface';
import ResourceType from './ResourceTypes';

export const initialState: IResourceState = {
  loading: false,
  resources: undefined,
  openGraphData: {} as { [x: string]: IOGResponse },
};

const ResourceReducer: Reducer<IResourceState, ResourceActions> = (
  state = initialState,
  action = {} as ResourceActions,
) => {
  switch (action.type) {
    case ResourceType.GET_RESOURCES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ResourceType.GET_RESOURCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        resources: action.payload,
      };
    }
    case ResourceType.GET_RESOURCES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case ResourceType.GET_OPEN_GRAPH_SUCCESS: {
      return {
        ...state,
        openGraphData: {
          ...state.openGraphData,
          [action.payload.url]: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};

export default ResourceReducer;
