import { FormikHelpers, useFormik } from 'formik';
import { IAddPodcastProviderState } from 'store';

const useAddPodcastProviderForm = (
  onSubmit: (
    values: IAddPodcastProviderState,
    formikHelpers: FormikHelpers<IAddPodcastProviderState>,
  ) => void | Promise<unknown>,
  initialValues: IAddPodcastProviderState,
  validationSchema: any,
) => {
  return useFormik<IAddPodcastProviderState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export default useAddPodcastProviderForm;
