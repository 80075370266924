import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import NotFound from 'components/notFound/NotFound';
import CommentsList from 'components/reactions/CommentsList';
import CreateComment from 'components/reactions/CreateComment';
import GuestReactionView from 'components/reactions/GuestReactionView';
import { ICommentFormValues, useCommentForm } from 'components/reactions/useCommentForm';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { businessUrl } from 'helpers/urls';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ContentType,
  followBusiness,
  getBusinessPostDetailsById,
  IAddCommentParams,
  IGetCommentsParams,
  likeDislikeBusiness,
  resetBusinessPostState,
  unFollowBusiness,
} from 'store';
import { addComment, getComments } from 'store/comment/CommentReducer';
import getPostShareUrl from 'store/common/CommonActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { newsCommentValidationSchema } from 'utils/validationSchema';

import BusinessSinglePost from './BusinessSinglePost';

interface IBusinessPostProps {
  postId?: string;
}

export default function BusinessPost(props: IBusinessPostProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const commentsRef = useRef<any>();
  const { contentId } = useParams();
  const { postId } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();
  const [showModal, setShowModal] = useState<boolean>(false);

  const { businessPostDetails, loading } = useAppSelector(s => s.business);
  const { comments, loading: loadingComments, addCommentLoading } = useAppSelector(s => s.comment);
  const hasMoreData = businessPostDetails?.commentsCount > comments.length;
  const businessPostId = contentId || postId;

  useEffect(() => {
    if (businessPostId) dispatch(getBusinessPostDetailsById(businessPostId));
    return () => {
      dispatch(resetBusinessPostState());
    };
  }, [dispatch, businessPostId]);

  useEffect(() => {
    setTimeout(() => {
      if (
        !loading &&
        businessPostDetails &&
        Object.keys(businessPostDetails).length > 0 &&
        isUserLoggedIn() &&
        commentsRef?.current &&
        location?.state === 'scroll-to-comments'
      ) {
        onCommentClick();
        navigate(location.pathname, {});
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const getBusinessComments = (initialLoad: boolean) => {
    if (businessPostId) {
      const params: IGetCommentsParams = {
        contentId: businessPostId,
        offset: !initialLoad ? comments.length : 0,
        type: ContentType.BUSINESS,
      };

      dispatch(getComments(params));
    }
  };

  const onCommentClick = () => {
    if (isUserLoggedIn() && commentsRef?.current) {
      commentsRef.current.scrollIntoView(true);
    } else {
      setShowModal(true);
    }
  };

  const handleAddComment = async (submittedValues: ICommentFormValues) => {
    const payload: IAddCommentParams = {
      contentId: businessPostId,
      comment: submittedValues.comment,
      type: ContentType.BUSINESS,
    };
    await dispatch(addComment(payload));
    resetForm();
  };

  const commentState: ICommentFormValues = { comment: '' };

  const commentFormik = useCommentForm(handleAddComment, commentState, newsCommentValidationSchema);

  const { resetForm } = commentFormik;

  const onLikeClick = async (setDisableLike: (value: boolean) => void) => {
    if (isUserLoggedIn()) {
      await dispatch(
        likeDislikeBusiness(
          businessPostId as string,
          businessPostDetails?.isLiked,
          'business',
          'business-single-post',
        ),
      );
      setDisableLike(false);
    } else {
      setShowModal(true);
      setDisableLike(false);
    }
  };

  const shareBusiness = async () => {
    if (isUserLoggedIn()) {
      await dispatch(getPostShareUrl(`${businessUrl}business/${businessPostId}/share?type=post`));
    } else {
      setShowModal(true);
    }
  };

  const handleRelationChange = async () => {
    if (isUserLoggedIn()) {
      if (businessPostDetails?.isFollowing) {
        await dispatch(unFollowBusiness(businessPostDetails?.businessId));
      } else {
        await dispatch(followBusiness(businessPostDetails?.businessId));
      }
    } else {
      setShowModal(true);
    }
  };

  return (
    <HomeWrapper maxWidth="lg">
      <Container disableGutters maxWidth="lg">
        <Grid container alignItems="center" justifyContent="center">
          {loading ? (
            <Loader />
          ) : (
            businessPostDetails &&
            Object.keys(businessPostDetails).length > 0 && (
              <Grid item md={8} xs={12}>
                <BusinessSinglePost
                  handleRelationChange={handleRelationChange}
                  shareBusiness={shareBusiness}
                  onCommentClick={onCommentClick}
                  onLikeClick={onLikeClick}
                />

                {isUserLoggedIn() && (
                  <Box ref={commentsRef} sx={{ my: theme.spacing(3) }}>
                    <ComponentWrapper
                      isTitleInsideWrapper
                      withInfiniteScroll
                      infiniteScrollProps={{
                        loadOnMount: true,
                        hasMoreData,
                        isLoading: loadingComments,
                        onBottomHit: getBusinessComments,
                      }}
                      title={`Responses (${businessPostDetails?.commentsCount ?? 0})`}
                      withDivider={false}
                    >
                      <CreateComment
                        commentFormik={commentFormik}
                        isLoading={addCommentLoading}
                        type="create"
                      />
                      <CommentsList comments={comments} type={ContentType.BUSINESS} />
                      {loadingComments && <Loader />}
                    </ComponentWrapper>
                  </Box>
                )}
              </Grid>
            )
          )}
          {!loading && Object.keys(businessPostDetails).length === 0 && <NotFound />}
          {/* <Grid item display={isMobile ? "none" : "block"} md={4} /> */}
        </Grid>
      </Container>
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </HomeWrapper>
  );
}
