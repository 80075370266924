import { FormikHelpers, useFormik } from 'formik';
import { IAffiliatePaymentState } from 'store';
import { affiliatePaymentReceiveValidationSchema } from 'utils/validationSchema';

const useAffiliatePaymentReceiveForm = (
  onSubmit: (
    values: IAffiliatePaymentState,
    formikHelpers: FormikHelpers<IAffiliatePaymentState>,
  ) => void | Promise<unknown>,
  initialValues: IAffiliatePaymentState,
) => {
  return useFormik<IAffiliatePaymentState>({
    initialValues,
    validationSchema: affiliatePaymentReceiveValidationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export default useAffiliatePaymentReceiveForm;
