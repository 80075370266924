import styled from '@emotion/styled';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import theme from 'theme';

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.tertiary,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    padding: theme.spacing(3, 0),
    paddingLeft: theme.spacing(4),
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: 400,
    fontSize: '14px',
    color: theme.palette.text.secondary,
    lineHeight: '22px',
    padding: theme.spacing(2.5, 4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  'td,th': {
    border: 0,
  },
}));
