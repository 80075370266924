import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import GuestReactionView from 'components/reactions/GuestReactionView';
import PostTile from 'components/tile/PostTile';
import Tile from 'components/tile/Tile';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { activeSearch, ITimelinePost, resetSearchState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

function SearchResults() {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { searchData, loading, loadNextPage } = useAppSelector(s => s.search);
  const location = useLocation();
  const pathName = useMemo(() => location.pathname, [location.pathname]);
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q')?.toString() as string;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (searchQuery?.length > 0 && Object.keys(searchData.data).length === 0) {
      dispatch(activeSearch(searchQuery, true, pathName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    scrollToTop();
    return () => {
      dispatch(resetSearchState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSearchResults = () => {
    if (searchData.query.length > 0) {
      dispatch(activeSearch(searchData.query, false, pathName, searchData.page + 1));
    }
  };

  return (
    <>
      <HomeWrapper maxWidth="midmd">
        <Stack spacing={theme.spacing(5)} width="100%">
          <ComponentWrapper
            withInfiniteScroll
            infiniteScrollProps={{
              loadOnMount: false,
              hasMoreData: searchData.hasMoreData,
              isLoading: loading || loadNextPage,
              onBottomHit: getSearchResults,
            }}
            title="RESULTS"
          >
            {loading ? (
              <Loader />
            ) : searchData.data && searchData.data.length > 0 ? (
              <>
                {searchData.data.map((data: ITimelinePost, index: number) =>
                  ['paid-member-post', 'business', 'town-crier-post'].includes(data?.type) ? (
                    <PostTile
                      componentType="search"
                      post={data}
                      withDivider={!(searchData.data?.length === index + 1)}
                      onNavToPost={() => {
                        setShowModal(true);
                      }}
                    />
                  ) : (
                    <Tile
                      key={(data?.postId as string) + index.toString()}
                      commentsCount={data?.commentsCount}
                      componentType="search"
                      description={data?.description}
                      id={data?.postId}
                      image={data?.thumbnail as string}
                      isLiked={data?.isLiked}
                      likesCount={data?.likesCount}
                      name={data?.postProvider}
                      postedAt={data?.postedAt as string}
                      providerId={data.postProviderId}
                      providerLogo={data?.postProviderLogo}
                      tileType={isMobile ? 'small' : 'large'}
                      title={data?.title}
                      type={data?.type}
                      withDivider={!(searchData.data?.length === index + 1)}
                    />
                  ),
                )}
              </>
            ) : (
              !loading && (
                <Typography align="center" component="div" variant="body1Bold">
                  No results found
                </Typography>
              )
            )}
            {loadNextPage && <Loader />}
          </ComponentWrapper>
        </Stack>
      </HomeWrapper>
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </>
  );
}

export default SearchResults;
