import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomInputField from 'components/customFields/CustomInputField';
import AuthWrapper from 'components/wrapper/AuthWrapper';
import ErrorMessages from 'constants/ErrorMessages';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IResponseData, ResponseCodes, sendResetPassword } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { validateEmail } from 'utils/validator';

const { NOT_FOUND } = ResponseCodes;

function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(s => s.auth);

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const handleSubmit = async () => {
    const response: IResponseData = (await dispatch(sendResetPassword(email))) as IResponseData;
    if (!response.error) {
      navigate('/forgot-password-success', {
        state: { email, page: 'forgotPassword' },
      });
    } else if (response.status === NOT_FOUND) {
      setEmailError(ErrorMessages.forgotPassword.emailNotPresent);
    } else {
      setEmailError(response.message);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setEmail(value);
  };
  const handleBlur = () => {
    if (email) {
      const error = validateEmail(email);
      if (!error) {
        setEmailError(ErrorMessages.forgotPassword.email);
      }
    } else {
      setEmailError(ErrorMessages.forgotPassword.emailRequired);
    }
  };

  return (
    <AuthWrapper>
      <Stack
        noValidate
        alignItems="center"
        component="form"
        spacing={theme.spacing(2.5)}
        width="100%"
      >
        <Typography align="center" component="div" variant={isMobile ? 'h5' : 'h2'}>
          Reset Password
        </Typography>
        <Typography
          align="center"
          fontWeight={400}
          sx={{ color: theme.palette.text.secondary }}
          variant={isMobile ? 'menu' : 'title2'}
        >
          Please enter email associated with your account.
        </Typography>

        <CustomInputField
          fullWidth
          required
          error={!!emailError}
          errormessage={emailError}
          id="email"
          label="Email"
          name="email"
          type="email"
          value={email}
          onBlur={handleBlur}
          onChange={handleInputChange}
          onFocus={() => setEmailError('')}
        />

        <Stack
          spacing={theme.spacing(3.5)}
          sx={{ mt: `${theme.spacing(4)} !important` }}
          width="100%"
        >
          <LoadingButton
            disableRipple
            disableTouchRipple
            disabled={!validateEmail(email)}
            loading={loading}
            size="large"
            variant="contained"
            onClick={handleSubmit}
          >
            Reset Password
          </LoadingButton>
          <Typography
            align="center"
            color={theme.palette.text.secondary}
            component="span"
            variant="h6"
            onClick={() => navigate('/login')}
          >
            Back to
            <Typography
              color={theme.palette.secondary.main}
              component="span"
              sx={{
                ml: theme.spacing(1),
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Sign In
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </AuthWrapper>
  );
}

export default memo(ForgotPassword);
