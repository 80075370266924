import './App.css';
import '@fontsource/inter';

import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import Fab from '@mui/material/Fab';
import { ThemeProvider } from '@mui/material/styles';
import Toast from 'components/toast/Toast';
import { CURRENT_AD_SEQUENCE_TEXT, DEFAULT_AD_SEQUENCE } from 'constants/constants';
import { useEffect, useState } from 'react';
import Routes from 'routes';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

function App() {
  // useEffect(() => {
  //   redirectToMobileApp();
  // }, []);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    localStorage.removeItem('currentSequence'); // Remove this line in future
    if (!localStorage.getItem(CURRENT_AD_SEQUENCE_TEXT)) {
      localStorage.setItem(CURRENT_AD_SEQUENCE_TEXT, JSON.stringify(DEFAULT_AD_SEQUENCE));
    }
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Toast />
      <Routes />
      <Fab
        aria-label="Scroll to top"
        color="primary"
        size="small"
        style={{ display: visible ? 'inline' : 'none' }}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        onClick={scrollToTop}
      >
        <KeyboardArrowUpRoundedIcon />
      </Fab>
    </ThemeProvider>
  );
}

export default App;
