enum AccountType {
  GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR',

  UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST',
  UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS',
  UPDATE_USER_INFO_ERROR = 'UPDATE_USER_INFO_ERROR',

  CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR',

  UPLOAD_PROFILE_AVATAR_REQUEST = 'UPLOAD_PROFILE_AVATAR_REQUEST',
  UPLOAD_PROFILE_AVATAR_SUCCESS = 'UPLOAD_PROFILE_AVATAR_SUCCESS',
  UPLOAD_PROFILE_AVATAR_ERROR = 'UPLOAD_PROFILE_AVATAR_ERROR',

  DELETE_PROFILE_PHOTO_REQUEST = 'DELETE_PROFILE_PHOTO_REQUEST',
  DELETE_PROFILE_PHOTO_SUCCESS = 'DELETE_PROFILE_PHOTO_SUCCESS',
  DELETE_PROFILE_PHOTO_ERROR = 'DELETE_PROFILE_PHOTO_ERROR',

  DELETE_PROFILE_MEDIA_REQUEST = 'DELETE_PROFILE_MEDIA_REQUEST',
  DELETE_PROFILE_MEDIA_SUCCESS = 'DELETE_PROFILE_MEDIA_SUCCESS',
  DELETE_PROFILE_MEDIA_ERROR = 'DELETE_PROFILE_MEDIA_ERROR',

  CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST',
  CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS',
  CHANGE_EMAIL_ERROR = 'CHANGE_EMAIL_ERROR',

  DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR',

  UPDATE_PROFILE_INFO_REQUEST = 'UPDATE_PROFILE_INFO_REQUEST',
  UPDATE_PROFILE_INFO_SUCCESS = 'UPDATE_PROFILE_INFO_SUCCESS',
  UPDATE_PROFILE_INFO_ERROR = 'UPDATE_PROFILE_INFO_ERROR',

  GET_PROFILE_WALL_INFO_REQUEST = 'GET_PROFILE_WALL_INFO_REQUEST',
  GET_PROFILE_WALL_INFO_SUCCESS = 'GET_PROFILE_WALL_INFO_SUCCESS',
  GET_PROFILE_WALL_INFO_ERROR = 'GET_PROFILE_WALL_INFO_ERROR',

  CREATE_PROFILE_PAYMENT_REQUEST = 'CREATE_PROFILE_PAYMENT_REQUEST',
  CREATE_PROFILE_PAYMENT_SUCCESS = 'CREATE_PROFILE_PAYMENT_SUCCESS',
  CREATE_PROFILE_PAYMENT_ERROR = 'CREATE_PROFILE_PAYMENT_ERROR',

  CREATE_PROFILE_POST_REQUEST = 'CREATE_PROFILE_POST_REQUEST',
  CREATE_PROFILE_POST_SUCCESS = 'CREATE_PROFILE_POST_SUCCESS',
  CREATE_PROFILE_POST_ERROR = 'CREATE_PROFILE_POST_ERROR',

  EDIT_PROFILE_POST_REQUEST = 'EDIT_PROFILE_POST_REQUEST',
  EDIT_PROFILE_POST_SUCCESS = 'EDIT_PROFILE_POST_SUCCESS',
  EDIT_PROFILE_POST_ERROR = 'EDIT_PROFILE_POST_ERROR',

  FETCH_PROFILE_POSTS_REQUEST = 'FETCH_PROFILE_POSTS_REQUEST',
  FETCH_PROFILE_POSTS_SUCCESS = 'FETCH_PROFILE_POSTS_SUCCESS',
  FETCH_PROFILE_POSTS_ERROR = 'FETCH_PROFILE_POSTS_ERROR',

  DELETE_PROFILE_POST_REQUEST = 'DELETE_PROFILE_POST_REQUEST',
  DELETE_PROFILE_POST_SUCCESS = 'DELETE_PROFILE_POST_SUCCESS',
  DELETE_PROFILE_POST_ERROR = 'DELETE_PROFILE_POST_ERROR',

  LIKE_DISLIKE_PROFILE_WALL_POST_REQUEST = 'LIKE_DISLIKE_PROFILE_WALL_POST_REQUEST',
  LIKE_DISLIKE_PROFILE_WALL_POST_SUCCESS = 'LIKE_DISLIKE_PROFILE_WALL_POST_SUCCESS',
  LIKE_DISLIKE_PROFILE_DETAILS_POST_SUCCESS = 'LIKE_DISLIKE_PROFILE_DETAILS_POST_SUCCESS',
  LIKE_DISLIKE_PROFILE_WALL_POST_ERROR = 'LIKE_DISLIKE_PROFILE_WALL_POST_ERROR',

  FETCH_PROFILE_POST_DETAILS_REQUEST = 'FETCH_PROFILE_POST_DETAILS_REQUEST',
  FETCH_PROFILE_POST_DETAILS_SUCCESS = 'FETCH_PROFILE_POST_DETAILS_SUCCESS',
  FETCH_PROFILE_POST_DETAILS_ERROR = 'FETCH_PROFILE_POST_DETAILS_ERROR',

  FOLLOW_AND_UNFOLLOW_PROFILE_WALL_REQUEST = 'FOLLOW_AND_UNFOLLOW_PROFILE_WALL_REQUEST',
  FOLLOW_AND_UNFOLLOW_PROFILE_WALL_SUCCESS = 'FOLLOW_AND_UNFOLLOW_PROFILE_WALL_SUCCESS',
  FOLLOW_AND_UNFOLLOW_PROFILE_WALL_ERROR = 'FOLLOW_AND_UNFOLLOW_PROFILE_WALL_ERROR',

  FETCH_PROFILE_MEDIA_REQUEST = 'FETCH_PROFILE_MEDIA_REQUEST',
  FETCH_PROFILE_MEDIA_SUCCESS = 'FETCH_PROFILE_MEDIA_SUCCESS',
  FETCH_PROFILE_MEDIA_ERROR = 'FETCH_PROFILE_MEDIA_ERROR',

  RESET_PROFILE_WALL_STATE = 'RESET_PROFILE_WALL_STATE',
  RESET_PROFILE_POSTS_STATE = 'RESET_PROFILE_POSTS_STATE',
  RESET_PROFILE_POST_DETAILS_STATE = 'RESET_PROFILE_POST_DETAILS_STATE',

  RESET_PROFILE_MEDIA_STATE = 'RESET_PROFILE_MEDIA_STATE',

  UPDATE_COMMENT_COUNT = 'UPDATE_COMMENT_COUNT_PROFILE',
  PROFILE_UPDATE_POST_CONTENT = 'PROFILE_UPDATE_POST_CONTENT',
}

export default AccountType;
