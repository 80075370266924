import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import theme from 'theme';

type IProps = {
  handleSelectedPlan: (type: string) => void;
};

const BUSINESS_TYPES = [
  { label: 'Business', type: 'free' },
  { label: 'Freedom Chamber', type: 'payment_initiated' },
];

function BusinessTypeModule({ handleSelectedPlan }: IProps) {
  return (
    <Stack alignSelf="center" width="60%">
      {BUSINESS_TYPES.map(item => {
        return (
          <Box key={item.type} margin={5}>
            <Typography
              noWrap
              align="center"
              color="text.secondary"
              component="h1"
              sx={{ mb: 2.5 }}
              variant="h5"
            >
              {item.label}
            </Typography>

            <Divider
              flexItem
              sx={{
                borderColor: theme.palette.divider,
              }}
            />

            <Button
              fullWidth
              size="large"
              sx={{ mb: 3 }}
              variant="contained"
              onClick={() => handleSelectedPlan(item.type)}
            >
              Get Started
            </Button>
            <Divider
              flexItem
              sx={{
                borderColor: theme.palette.divider,
              }}
            />
          </Box>
        );
      })}
    </Stack>
  );
}

export default BusinessTypeModule;
