import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import ModalContainer from 'components/modal/ModalContainer';
import { PreferenceIcon } from 'helpers/images';
import React, { memo, useState } from 'react';
import { getFollowingPosts, resetFollowingTimelineState } from 'store';
import { useAppDispatch } from 'store/hooks';
import theme from 'theme';

import Preferences from './Preferences';

function PreferencesBtn() {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const [showPreferenceModal, setShowPreferenceModal] = useState(false);

  const handleTogglePreferenceModal = () => {
    setShowPreferenceModal(false);
    dispatch(resetFollowingTimelineState());
    dispatch(getFollowingPosts(0));
  };

  return (
    <>
      <Button
        size="small"
        startIcon={<PreferenceIcon />}
        variant="contained"
        onClick={() => setShowPreferenceModal(true)}
      >
        PREFERENCES
      </Button>
      {showPreferenceModal && (
        <ModalContainer
          disableScroll
          fullWidth
          showCloseIcon
          handleClose={handleTogglePreferenceModal}
          maxWidth="md"
          open={showPreferenceModal}
          PaperProps={{
            sx: {
              ...(!isMobile && {
                maxHeight: '600px',
                minHeight: '600px',
              }),
            },
          }}
          title="Preferences"
        >
          <Preferences />
        </ModalContainer>
      )}
    </>
  );
}

export default memo(PreferencesBtn);
