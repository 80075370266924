import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import React from 'react';
import {
  alertSuccess,
  // getFollowingPosts,
  // resetFollowingTimelineState,
  // showPreferencesModal,
} from 'store';
import { useAppDispatch } from 'store/hooks';
import theme from 'theme';

interface IProps {
  disableFn: () => boolean;
  onSave: () => Promise<void>;
  loading: boolean;
}

function SavePreferencesBtn({ disableFn, onSave, loading }: IProps) {
  const dispatch = useAppDispatch();

  const handleSavePreferences = async () => {
    await onSave()
      .then(() => {})
      .catch(() => {
        dispatch(alertSuccess({ message: 'Something went wrong.' }));
      });
  };

  return (
    <Stack
      bottom={0}
      direction="row"
      justifyContent="center"
      padding={theme.spacing(2, 0)}
      position="sticky"
      spacing={theme.spacing(2)}
      sx={{ background: theme.palette.common.white }}
    >
      <LoadingButton
        disabled={disableFn()}
        loading={loading}
        size="large"
        sx={{ width: '50%' }}
        variant="contained"
        onClick={handleSavePreferences}
      >
        Confirm
      </LoadingButton>
    </Stack>
  );
}

export default SavePreferencesBtn;
