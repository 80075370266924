enum AffiliateType {
  CREATE_AFFILIATE_LINK_REQUEST = 'CREATE_AFFILIATE_LINK_REQUEST',
  CREATE_AFFILIATE_LINK_SUCCESS = 'CREATE_AFFILIATE_LINK_SUCCESS',
  CREATE_AFFILIATE_LINK_ERROR = 'CREATE_AFFILIATE_LINK_ERROR',

  LINK_AFFILIATE_REQUEST = 'LINK_AFFILIATE_REQUEST',
  LINK_AFFILIATE_SUCCESS = 'LINK_AFFILIATE_SUCCESS',
  LINK_AFFILIATE_ERROR = 'LINK_AFFILIATE_ERROR',

  ADD_AFFILIATE_PAYMENT_REQUEST = 'ADD_AFFILIATE_PAYMENT_REQUEST',
  ADD_AFFILIATE_PAYMENT_SUCCESS = 'ADD_AFFILIATE_PAYMENT_SUCCESS',
  ADD_AFFILIATE_PAYMENT_ERROR = 'ADD_AFFILIATE_PAYMENT_ERROR',

  GET_AFFILIATE_PAYMENT_REQUEST = 'GET_AFFILIATE_PAYMENT_REQUEST',
  GET_AFFILIATE_PAYMENT_SUCCESS = 'GET_AFFILIATE_PAYMENT_SUCCESS',
  GET_AFFILIATE_PAYMENT_ERROR = 'GET_AFFILIATE_PAYMENT_ERROR',

  UPDATE_AFFILIATE_PAYMENT_REQUEST = 'UPDATE_AFFILIATE_PAYMENT_REQUEST',
  UPDATE_AFFILIATE_PAYMENT_SUCCESS = 'UPDATE_AFFILIATE_PAYMENT_SUCCESS',
  UPDATE_AFFILIATE_PAYMENT_ERROR = 'UPDATE_AFFILIATE_PAYMENT_ERROR',

  DELETE_AFFILIATE_PAYMENT_REQUEST = 'DELETE_AFFILIATE_PAYMENT_REQUEST',
  DELETE_AFFILIATE_PAYMENT_SUCCESS = 'DELETE_AFFILIATE_PAYMENT_SUCCESS',
  DELETE_AFFILIATE_PAYMENT_ERROR = 'DELETE_AFFILIATE_PAYMENT_ERROR',

  ADD_CLICK_EVENT_REQUEST = 'ADD_CLICK_EVENT_REQUEST',
  ADD_CLICK_EVENT_SUCCESS = 'ADD_CLICK_EVENT_SUCCESS',
  ADD_CLICK_EVENT_ERROR = 'ADD_CLICK_EVENT_ERROR',

  GET_AFFILIATE_COUNTS_REQUEST = 'GET_AFFILIATE_COUNTS_REQUEST',
  GET_AFFILIATE_COUNTS_SUCCESS = 'GET_AFFILIATE_COUNTS_SUCCESS',
  GET_AFFILIATE_COUNTS_ERROR = 'GET_AFFILIATE_COUNTS_ERROR',

  GET_AFFILIATE_EXISTS_REQUEST = 'GET_AFFILIATE_EXISTS_REQUEST',
  GET_AFFILIATE_EXISTS_SUCCESS = 'GET_AFFILIATE_EXISTS_SUCCESS',
  GET_AFFILIATE_EXISTS_ERROR = 'GET_AFFILIATE_EXISTS_ERROR',
}

export default AffiliateType;
