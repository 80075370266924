import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomSwitch from 'components/customFields/CustomSwitch';
import PlanTile from 'components/tile/PlanTile';
import { BUSINESS_PLANS } from 'constants/constants';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';

function BusinessPlan() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const [yearly, setYearly] = useState(false);

  const handleSelectedPlan = (plan: string) => {
    navigate('/business-setup', { state: plan });
  };

  return (
    <Stack
      sx={{
        backgroundColor: '#FBFBFB',
        height: '100vh',
      }}
    >
      <Container sx={{ py: 8 }}>
        <Stack alignItems="center" justifyContent="center">
          <Stack pb={2}>
            <Typography
              noWrap
              align="center"
              color="text.secondary"
              component="h2"
              variant={isMobile ? 'h5' : 'h2'}
            >
              Select a level that works for you
            </Typography>
            <Typography
              align="center"
              color="text.secondary"
              fontWeight={400}
              marginTop={theme.spacing(1.5)}
              variant={isMobile ? 'menu' : 'title2'}
            >
              Discover a perfect combination of abilities and perks tailored to suit your needs.
            </Typography>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
              marginTop={theme.spacing(3.5)}
              pb={4}
              pt={2.5}
              spacing={theme.spacing(3)}
            >
              <Typography
                align="center"
                color="text.secondary"
                id="monthly-label"
                sx={{
                  ...(!yearly && {
                    textUnderlineOffset: '2px',
                    textDecoration: 'underline',
                  }),
                }}
                variant="menu"
              >
                Monthly
              </Typography>
              <CustomSwitch
                checked={yearly}
                id="isMonthly"
                inputProps={{ 'aria-label': 'Account Type' }}
                name="isMonthly"
                value={yearly}
                onChange={() => setYearly(!yearly)}
              />
              <Typography
                align="center"
                color="text.secondary"
                id="yearly-label"
                sx={{
                  ...(yearly && {
                    textUnderlineOffset: '2px',
                    textDecoration: 'underline',
                  }),
                }}
                variant="menu"
              >
                Annually
              </Typography>
            </Stack>
          </Stack>
          <Grid container spacing={2}>
            {BUSINESS_PLANS.map((data: any) => (
              <PlanTile
                key={data.plan}
                data={data}
                gridValue={6}
                handleSelectedPlan={handleSelectedPlan}
                yearly={yearly}
              />
            ))}
          </Grid>
          <Button size="large" sx={{ mt: 3 }} variant="outlined" onClick={() => navigate('/home')}>
            Cancel
          </Button>
        </Stack>
      </Container>
    </Stack>
  );
}

export default memo(BusinessPlan);
