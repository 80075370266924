import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import LinkPreview from 'components/linkPreview';
import BusinessPostMenu from 'components/menu/BusinessPostMenu';
import Reactions from 'components/reactions';
import GuestReactionView from 'components/reactions/GuestReactionView';
import { businessUrl } from 'helpers/urls';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import CreateBusinessPost from 'pages/business/BusinessWall/CreateBusinessPost';
import { useMemo, useState } from 'react';
import {
  deleteBusinessPost,
  fetchBusinessPosts,
  IBusinessPostImage,
  IPostType,
  likeDislikeBusiness,
} from 'store';
import getPostShareUrl from 'store/common/CommonActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import {
  getPreviewLink,
  replaceLinkWithAnchorTag,
  REPORTED_CONTENT_STRING,
  timeDiffCalc,
} from 'utils/utils';

const DEFAULT_IMAGE = {
  imageURL: '',
  croppedImageUrl: '',
  imageViewAttribute: '',
};

interface ITileProps {
  postId: string;
  businessId: string;
  commentsCount: number;
  type?: string;
  userImage: string;
  userId: string;
  postType: IPostType;
  image: string;
  imageProps?: IBusinessPostImage;
  userName: string;
  textContent: string;
  postedAt: string;
  likesCount: number;
  isLiked: boolean;
  isReported: boolean;
  withDivider: boolean;
  navigateToBusinessPost: (isComment: boolean) => void;
  navigateToBusinessWall?: () => void;
  componentType?: string;
}

function BusinessPostTile(props: ITileProps) {
  const {
    postId,
    businessId,
    commentsCount,
    componentType = 'business',
    type = '',
    userImage,
    userName,
    postType,
    textContent,
    image,
    imageProps = {} as IBusinessPostImage,
    postedAt,
    likesCount,
    isLiked,
    isReported,
    withDivider = true,
    navigateToBusinessPost,
    navigateToBusinessWall = () => {},
  } = props;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();
  const dispatch = useAppDispatch();

  const {
    editBusinessPostLoading,
    businessWallDetails,
    businessPostsState: { offset },
  } = useAppSelector(s => s.business);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isPostEdit, setIsPostEdit] = useState<boolean>(false);

  const previewLink = useMemo(() => {
    const uniqueLink = getPreviewLink(textContent);
    return !image ? uniqueLink : '';
  }, [image, textContent]);

  const textHtmlContent = useMemo(() => {
    return isReported ? REPORTED_CONTENT_STRING : replaceLinkWithAnchorTag(textContent);
  }, [isReported, textContent]);

  const handleDeletePost = () => {
    dispatch(deleteBusinessPost(postId)).then(response => {
      const params = { businessId, offset: 0, limit: 10, reset: true };
      if (!response?.error) dispatch(fetchBusinessPosts(params));
    });
  };

  const onLikeClick = async (setDisableLike: (value: boolean) => void) => {
    if (isUserLoggedIn()) {
      await dispatch(
        likeDislikeBusiness(postId as string, isLiked as boolean, componentType, type),
      );
      setDisableLike(false);
    } else {
      setShowModal(true);
      setDisableLike(false);
    }
  };

  const shareBusiness = async () => {
    if (isUserLoggedIn()) {
      await dispatch(getPostShareUrl(`${businessUrl}business/${postId}/share?type=post`));
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      {isPostEdit ? (
        <CreateBusinessPost
          businessId={businessId}
          handleEditPost={() => setIsPostEdit(false)}
          imageProps={imageProps || DEFAULT_IMAGE}
          postId={postId}
          textContent={textContent}
          type="edit"
        />
      ) : (
        <Grid container item justifyContent="space-between">
          <Grid container item xs={12}>
            <Stack spacing={theme.spacing(isMobile ? 2 : 3)} width="100%">
              <Stack
                alignItems="center"
                direction="row"
                height="fit-content"
                justifyContent="space-between"
                spacing={theme.spacing(1.5)}
                width="100%"
              >
                <Stack direction="row" spacing={theme.spacing(1.5)}>
                  <Avatar
                    alt="provider-logo"
                    src={userImage}
                    sx={{ width: theme.spacing(5), height: theme.spacing(5) }}
                    variant={componentType === 'business' ? 'circular' : 'square'}
                    {...(componentType !== 'business' && {
                      onClick: navigateToBusinessWall,
                      className: 'cursor-pointer',
                    })}
                  />
                  <Stack>
                    <Typography
                      component="div"
                      variant="body3"
                      {...(componentType !== 'business' && {
                        onClick: navigateToBusinessWall,
                        sx: {
                          cursor: 'pointer',
                          textUnderlineOffset: '2px',
                          textDecoration: 'none',
                          color: theme.palette.primary.main,
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        },
                      })}
                    >
                      {userName}
                    </Typography>
                    {postedAt && (
                      <Typography noWrap color="text.tertiary" component="span" variant="subtitle2">
                        {timeDiffCalc(postedAt)}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                {componentType === 'business' && !isReported && !editBusinessPostLoading && (
                  <BusinessPostMenu
                    contentId={postId}
                    contentType="content"
                    handleDelete={handleDeletePost}
                    handleEdit={() => setIsPostEdit(true)}
                    isOwner={businessWallDetails?.role === 'owner'}
                    postContentType={componentType}
                    type="post"
                  />
                )}
              </Stack>
              <Stack flexGrow={1}>
                <Stack
                  alignItems="center"
                  direction="row"
                  height="100%"
                  spacing={theme.spacing(2)}
                  width="100%"
                >
                  <Stack flexGrow={1} spacing={theme.spacing(1)}>
                    <Typography
                      className="text-clamp-2 cursor-pointer"
                      color={isReported ? 'GrayText' : undefined}
                      component="div"
                      dangerouslySetInnerHTML={{ __html: textHtmlContent }}
                      sx={{ wordBreak: 'break-word', wordWrap: 'break-word' }}
                      variant={isMobile ? 'body2' : 'body1Bold'}
                      onClick={() => navigateToBusinessPost(false)}
                    />

                    {image && !isReported && (
                      <Box
                        sx={{ my: theme.spacing(2) }}
                        onClick={() => navigateToBusinessPost(false)}
                      >
                        <img
                          alt="post"
                          className="cursor-pointer"
                          src={image}
                          style={{ borderRadius: '10px' }}
                          width="100%"
                        />
                      </Box>
                    )}

                    {previewLink && !isReported ? (
                      <LinkPreview key={previewLink} type="post" url={previewLink} />
                    ) : null}

                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="flex-start"
                      spacing={theme.spacing(1)}
                    >
                      <Typography color="text.secondary" component="div" variant="subtitle2Bold">
                        BUSINESS
                      </Typography>
                      {!isMobile && (
                        <Box
                          height={theme.spacing(0.5)}
                          sx={{
                            background: theme.palette.common.black,
                            borderRadius: '50%',
                          }}
                          width={theme.spacing(0.5)}
                        />
                      )}
                      <Reactions
                        showCount
                        commentsCount={commentsCount}
                        isDeleted={isReported}
                        isLiked={isLiked}
                        isMobile={isMobile}
                        likesCount={likesCount}
                        onCommentClick={() => navigateToBusinessPost(true)}
                        onLikeClick={onLikeClick}
                        onShareClick={shareBusiness}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      )}
      {withDivider && (
        <Divider
          sx={{
            borderBottomWidth: '1px',
            borderColor: theme.palette.divider,
            margin: theme.spacing(3, 0),
          }}
        />
      )}

      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </>
  );
}

export default BusinessPostTile;
