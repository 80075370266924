import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CommentIcon, ShareIcon } from 'helpers/images';
import { useState } from 'react';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';

interface IReactionsProps {
  commentsCount?: number;
  isMobile: boolean;
  isLiked: boolean;
  likesCount?: number;
  onCommentClick: () => void;
  onLikeClick: (setDisableLike: React.Dispatch<React.SetStateAction<boolean>>) => void;

  onShareClick?: () => void;
  showCount?: boolean;
  showReactionText?: boolean;
  component?: string;
  showShare?: boolean;
  isDeleted?: boolean;
}

function Reactions(props: IReactionsProps) {
  const {
    commentsCount = 0,
    isMobile,
    isLiked,
    likesCount = 0,
    onCommentClick,
    onLikeClick,
    onShareClick,
    showCount = false,
    showReactionText = true,
    component,
    showShare = true,
    isDeleted,
  } = props;
  const [disableLike, setDisableLike] = useState<boolean>(false);
  const { loading: shareLoading } = useAppSelector(s => s.common);
  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={theme.spacing(showCount ? 2 : 0)}
      sx={{
        ...(component !== 'tile' && { my: theme.spacing(showCount ? 1 : 0) }),
      }}
    >
      {!isMobile ? (
        <>
          <IconButton
            disabled={disableLike || isDeleted}
            sx={{
              borderRadius: '10%',
            }}
            onClick={() => {
              setDisableLike(true);
              onLikeClick(setDisableLike);
            }}
          >
            {isLiked ? <ThumbUpIcon fontSize="small" /> : <ThumbUpOffAltIcon fontSize="small" />}
            {showCount && (
              <Typography className="ml-4" variant="subtitle1" whiteSpace="nowrap">
                {likesCount ?? 0} {showReactionText && `Likes`}
              </Typography>
            )}
          </IconButton>
          <IconButton
            sx={{
              borderRadius: '10%',
            }}
            onClick={onCommentClick}
          >
            <CommentIcon />
            {showCount && (
              <Typography className="ml-4" variant="subtitle1" whiteSpace="nowrap">
                {commentsCount ?? 0} {showReactionText && `Responses`}
              </Typography>
            )}
          </IconButton>

          {showShare && (
            <>
              <IconButton
                disabled={shareLoading}
                sx={{
                  borderRadius: '10%',
                }}
                onClick={() => onShareClick?.()}
              >
                <ShareIcon />
                {showReactionText && (
                  <Typography className="ml-4" variant="subtitle1">
                    Share
                  </Typography>
                )}
              </IconButton>
            </>
          )}
        </>
      ) : null}
    </Stack>
  );
}

export default Reactions;
