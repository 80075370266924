import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import theme from 'theme';

import ModalHeader, { IModalHeaderProps } from './ModalHeader';

export interface IModalProps extends IModalHeaderProps, DialogProps {
  showHeader?: boolean;
  disableScroll?: boolean;
}

function Modal(props: IModalProps) {
  const {
    children,
    disableScroll = false,
    handleBack,
    handleClose,
    open,
    showBackIcon = false,
    showCloseIcon = false,
    showHeader = true,
    title = '',
    maxWidth,
    fullWidth,
    PaperProps,
  } = props;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      disableEnforceFocus
      fullScreen={!!isMobile}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      PaperProps={PaperProps}
      sx={{
        zIndex: isMobile ? 1306 : 1300,
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            borderRadius: isMobile ? '0px' : theme.spacing(0.5),
          },
        },
        '& .MuiDialogContent-root': {
          display: 'flex',
          padding: 0,
          overflowX: 'hidden',
          ...(disableScroll && {
            overflow: 'hidden',
          }),
        },
        '& .MuiDialogActions-root': {
          padding: 0,
        },
        '& .MuiDialogTitle-root': {
          padding: theme.spacing(2.5, 4),
          borderBottom: `1px solid ${theme.palette.text.secondary}`,
        },
      }}
      transitionDuration={{
        enter: theme.transitions.duration.enteringScreen,
        exit: 0,
      }}
      onClose={() => {}}
    >
      {showHeader && (
        <DialogTitle id="scroll-dialog-title">
          <ModalHeader
            handleBack={handleBack}
            handleClose={handleClose}
            showBackIcon={showBackIcon}
            showCloseIcon={showCloseIcon}
            title={title}
          />
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default Modal;
