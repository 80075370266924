import cloneDeep from 'clone-deep';
import { Reducer } from 'redux';
import { UpdateCommentCountType } from 'store/comment/CommentInterface';

import {
  BusinessActions,
  IBusiness,
  IBusinessCategory,
  IBusinessDetails,
  IBusinessImageData,
  IBusinessImages,
  IBusinessMediaImages,
  IBusinessPost,
  IBusinessPostDetails,
  IBusinessState,
  ImageType,
  IState,
} from './BusinessInterface';
import BusinessType from './BusinessTypes';

const initialBusinessState = {
  offset: 0,
  hasMoreData: true,
  businesses: [] as IBusiness[],
};

export const initialState: IBusinessState = {
  loading: false,
  isGetBusinessDetailsLoading: false,
  isCreateBusinessLoading: false,
  isUpdateBusinessLoading: false,
  isDeleteBusinessPostLoading: false,
  businessDetails: {} as IBusinessDetails,
  featuredBusinessLoading: false,
  recentBusinessLoading: false,
  allBusinessLoading: false,
  featuredBusinesses: { ...initialBusinessState },
  recentBusinesses: { ...initialBusinessState },
  allBusinesses: { ...initialBusinessState },
  states: [] as IState[],
  filterBusinessCategories: [] as IBusinessCategory[],
  businessCategories: [] as IBusinessCategory[],
  followLoading: false,
  businessPostsState: {
    offset: 0,
    hasMoreData: true,
    posts: [],
  },
  businessPostsLoading: false,
  businessWallDetails: {} as IBusinessDetails,
  createBusinessPostLoading: false,
  editBusinessPostLoading: false,
  businessMediaLoading: false,
  businessMediaState: {
    offset: 0,
    hasMoreData: true,
    businessMedia: {
      businessImages: {} as IBusinessMediaImages,
      postImages: [],
    },
  },
  businessPostDetails: {} as IBusinessPostDetails,
  isBusinessUpdatedSuccessfully: null,
  userBusinessId: '',
  isMyOwnPost: false,
};

const BusinessReducer: Reducer<IBusinessState, BusinessActions> = (
  state = initialState,
  action = {} as BusinessActions,
) => {
  switch (action.type) {
    case BusinessType.SET_FILTER_BUSINESSES_CATEGORIES: {
      return {
        ...state,
        filterBusinessCategories: action.payload,
      };
    }
    case BusinessType.RESET_FILTER_BUSINESSES_CATEGORIES: {
      return {
        ...state,
        filterBusinessCategories: [],
      };
    }
    case BusinessType.UPDATE_COMMENT_COUNT: {
      const { commentsCount } = state.businessPostDetails;
      return {
        ...state,
        businessPostDetails: {
          ...state.businessPostDetails,
          commentsCount:
            action.payload === UpdateCommentCountType.INC ? commentsCount + 1 : commentsCount - 1,
        },
      };
    }

    case BusinessType.BUSINESS_UPDATE_POST_CONTENT: {
      const { payload } = action;
      const { posts, offset, hasMoreData } = cloneDeep(state.businessPostsState);

      const index = posts.findIndex(i => i.documentId === payload.postId);
      if (index > -1) {
        posts[index].isReported = true;
      }

      return {
        ...state,
        businessPostsState: { posts: [...posts], offset, hasMoreData },
      };
    }

    case BusinessType.SAVE_BUSINESS_DATA_REQUEST: {
      return {
        ...state,
        isCreateBusinessLoading: true,
      };
    }
    case BusinessType.SAVE_BUSINESS_DATA_SUCCESS: {
      return {
        ...state,
        isCreateBusinessLoading: false,
        businessDetails: action.payload,
        userBusinessId: action.payload.documentId,
      };
    }
    case BusinessType.SAVE_BUSINESS_DATA_ERROR: {
      return {
        ...state,
        isCreateBusinessLoading: false,
      };
    }
    case BusinessType.FETCH_BUSINESS_DETAILS_REQUEST: {
      return {
        ...state,
        isGetBusinessDetailsLoading: true,
      };
    }
    case BusinessType.FETCH_BUSINESS_DETAILS_SUCCESS: {
      return {
        ...state,
        isGetBusinessDetailsLoading: false,
        businessDetails: action.payload.data,
        userBusinessId: action.payload.updateUserBusinessId
          ? action.payload.data.documentId
          : state.userBusinessId,
      };
    }
    case BusinessType.FETCH_BUSINESS_DETAILS_ERROR: {
      return {
        ...state,
        isGetBusinessDetailsLoading: false,
      };
    }
    case BusinessType.ADD_BUSINESS_IMAGE_REQUEST:
    case BusinessType.UPDATE_BUSINESS_IMAGE_REQUEST:
    case BusinessType.DELETE_BUSINESS_IMAGE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case BusinessType.ADD_BUSINESS_IMAGE_SUCCESS: {
      const { imageType, response } = action.payload;
      const businessImageData =
        imageType === ImageType.MEDIA
          ? state.businessDetails.images.media && state.businessDetails.images.media.length > 0
            ? [...state.businessDetails.images.media, response]
            : [response]
          : response;
      return {
        ...state,
        loading: false,
        businessDetails: {
          ...state.businessDetails,
          images: {
            ...state.businessDetails.images,
            [imageType]: businessImageData,
          },
        },
      };
    }
    case BusinessType.UPDATE_BUSINESS_IMAGE_SUCCESS: {
      const { imageType, oldImageId, response } = action.payload;
      const businessImages: IBusinessImages = {
        ...state.businessDetails.images,
      };
      let updatedImages: IBusinessImageData | IBusinessImageData[];
      if (imageType === ImageType.MEDIA) {
        const mediaImages = [...businessImages.media];
        const index = mediaImages.findIndex(
          (image: IBusinessImageData) => image.documentId === oldImageId,
        );
        mediaImages[index] = response;
        updatedImages = mediaImages;
      } else {
        updatedImages = response;
      }
      return {
        ...state,
        loading: false,
        businessDetails: {
          ...state.businessDetails,
          images: {
            ...state.businessDetails.images,
            [imageType]: updatedImages,
          },
        },
      };
    }
    case BusinessType.DELETE_BUSINESS_IMAGE_SUCCESS: {
      const { imageId, imageType } = action.payload;
      const businessImages = { ...state.businessDetails.images };
      let updatedImageData: IBusinessImageData | IBusinessImageData[];
      if (imageType === ImageType.MEDIA) {
        const mediaImages = [...businessImages.media];
        mediaImages.splice(
          mediaImages.findIndex((image: IBusinessImageData) => image.documentId === imageId),
          1,
        );
        updatedImageData = mediaImages;
      } else {
        updatedImageData = {} as IBusinessImageData;
      }
      return {
        ...state,
        loading: false,
        businessDetails: {
          ...state.businessDetails,
          images: {
            ...state.businessDetails.images,
            [imageType]: updatedImageData,
          },
        },
      };
    }
    case BusinessType.ADD_BUSINESS_IMAGE_ERROR:
    case BusinessType.UPDATE_BUSINESS_IMAGE_ERROR:
    case BusinessType.DELETE_BUSINESS_IMAGE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case BusinessType.UPDATE_BUSINESS_DATA_REQUEST: {
      return {
        ...state,
        isUpdateBusinessLoading: true,
        isBusinessUpdatedSuccessfully: null,
      };
    }
    case BusinessType.UPDATE_BUSINESS_DATA_SUCCESS: {
      return {
        ...state,
        isUpdateBusinessLoading: false,
        businessDetails: { ...state.businessDetails, ...action.payload },
        isBusinessUpdatedSuccessfully: true,
      };
    }
    case BusinessType.UPDATE_BUSINESS_DATA_ERROR: {
      return {
        ...state,
        isUpdateBusinessLoading: false,
        isBusinessUpdatedSuccessfully: false,
      };
    }
    case BusinessType.CREATE_BUSINESS_PAYMENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case BusinessType.CREATE_BUSINESS_PAYMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        businessDetails: { ...state.businessDetails, ...action.payload },
      };
    }
    case BusinessType.CREATE_BUSINESS_PAYMENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case BusinessType.FETCH_FEATURED_BUSINESSES_REQUEST: {
      return {
        ...state,
        featuredBusinessLoading: true,
      };
    }
    case BusinessType.FETCH_FEATURED_BUSINESSES_SUCCESS: {
      return {
        ...state,
        featuredBusinessLoading: false,
        featuredBusinesses: {
          offset: action.payload.offset,
          hasMoreData: action.payload.hasMoreData,
          businesses: [...state.featuredBusinesses.businesses, ...action.payload.businesses],
        },
      };
    }
    case BusinessType.FETCH_FEATURED_BUSINESSES_ERROR: {
      return {
        ...state,
        featuredBusinessLoading: false,
      };
    }
    case BusinessType.FETCH_RECENT_BUSINESSES_REQUEST: {
      return {
        ...state,
        recentBusinessLoading: true,
      };
    }
    case BusinessType.FETCH_RECENT_BUSINESSES_SUCCESS: {
      return {
        ...state,
        recentBusinessLoading: false,
        recentBusinesses: {
          offset: action.payload.offset,
          hasMoreData: action.payload.hasMoreData,
          businesses: [...state.recentBusinesses.businesses, ...action.payload.businesses],
        },
      };
    }
    case BusinessType.FETCH_RECENT_BUSINESSES_ERROR: {
      return {
        ...state,
        recentBusinessLoading: false,
      };
    }
    case BusinessType.FETCH_ALL_BUSINESSES_REQUEST: {
      return {
        ...state,
        allBusinessLoading: true,
      };
    }
    case BusinessType.FETCH_ALL_BUSINESSES_SUCCESS: {
      return {
        ...state,
        allBusinessLoading: false,
        allBusinesses: {
          offset: action.payload.offset,
          hasMoreData: action.payload.hasMoreData,
          businesses: [...state.allBusinesses.businesses, ...action.payload.businesses],
        },
      };
    }
    case BusinessType.FETCH_ALL_BUSINESSES_ERROR: {
      return {
        ...state,
        allBusinessLoading: false,
      };
    }
    case BusinessType.RESET_BUSINESS_DIRECTORY_STATE: {
      return {
        ...state,
        featuredBusinesses: { ...initialBusinessState },
        recentBusinesses: { ...initialBusinessState },
        allBusinesses: { ...initialBusinessState },
      };
    }
    case BusinessType.FOLLOW_BUSINESS_REQUEST: {
      return {
        ...state,
        followLoading: true,
      };
    }
    case BusinessType.FOLLOW_BUSINESS_SUCCESS: {
      const featuredBusinesses = cloneDeep(state.featuredBusinesses.businesses);
      const featuredBusiness = featuredBusinesses.find(
        x => x.documentId === action.payload.businessId,
      );
      if (featuredBusiness) {
        featuredBusiness.isFollowing = true;
      }

      const recentBusinesses = cloneDeep(state.recentBusinesses.businesses);
      const recentBusiness = recentBusinesses.find(x => x.documentId === action.payload.businessId);
      if (recentBusiness) {
        recentBusiness.isFollowing = true;
      }

      const allBusinesses = cloneDeep(state.allBusinesses.businesses);
      const allBusiness = allBusinesses.find(x => x.documentId === action.payload.businessId);
      if (allBusiness) {
        allBusiness.isFollowing = true;
      }

      const businessWallDetails = cloneDeep(state.businessWallDetails);
      if (businessWallDetails) {
        businessWallDetails.isFollowing = true;
        businessWallDetails.followersCount += 1;
      }

      return {
        ...state,
        followLoading: false,
        featuredBusinesses: {
          ...state.featuredBusinesses,
          businesses: featuredBusinesses,
        },
        recentBusinesses: {
          ...state.recentBusinesses,
          businesses: recentBusinesses,
        },
        allBusinesses: { ...state.allBusinesses, businesses: allBusinesses },
        businessWallDetails,
        businessPostDetails: {
          ...state.businessPostDetails,
          isFollowing: true,
        },
      };
    }
    case BusinessType.FOLLOW_BUSINESS_ERROR: {
      return {
        ...state,
        followLoading: false,
      };
    }
    case BusinessType.UNFOLLOW_BUSINESS_REQUEST: {
      return {
        ...state,
        followLoading: true,
      };
    }
    case BusinessType.UNFOLLOW_BUSINESS_SUCCESS: {
      const featuredBusinesses = cloneDeep(state.featuredBusinesses.businesses);
      const featuredBusiness = featuredBusinesses.find(
        x => x.documentId === action.payload.businessId,
      );
      if (featuredBusiness) {
        featuredBusiness.isFollowing = false;
      }

      const recentBusinesses = cloneDeep(state.recentBusinesses.businesses);
      const recentBusiness = recentBusinesses.find(x => x.documentId === action.payload.businessId);
      if (recentBusiness) {
        recentBusiness.isFollowing = false;
      }

      const allBusinesses = cloneDeep(state.allBusinesses.businesses);
      const allBusiness = allBusinesses.find(x => x.documentId === action.payload.businessId);
      if (allBusiness) {
        allBusiness.isFollowing = false;
      }

      const businessWallDetails = cloneDeep(state.businessWallDetails);
      if (businessWallDetails) {
        businessWallDetails.isFollowing = false;
        businessWallDetails.followersCount -= 1;
      }

      return {
        ...state,
        followLoading: false,
        featuredBusinesses: {
          ...state.featuredBusinesses,
          businesses: featuredBusinesses,
        },
        recentBusinesses: {
          ...state.recentBusinesses,
          businesses: recentBusinesses,
        },
        allBusinesses: { ...state.allBusinesses, businesses: allBusinesses },
        businessWallDetails,
        businessPostDetails: {
          ...state.businessPostDetails,
          isFollowing: false,
        },
      };
    }
    case BusinessType.UNFOLLOW_BUSINESS_ERROR: {
      return {
        ...state,
        followLoading: false,
      };
    }
    case BusinessType.PAYMENT_EXISTING_CARD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case BusinessType.PAYMENT_EXISTING_CARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        businessDetails: { ...state.businessDetails, ...action.payload },
      };
    }
    case BusinessType.PAYMENT_EXISTING_CARD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case BusinessType.GET_STATES_REQUEST:
    case BusinessType.GET_STATES_ERROR: {
      return {
        ...state,
      };
    }
    case BusinessType.GET_STATES_SUCCESS: {
      return {
        ...state,
        states: action.payload,
      };
    }
    case BusinessType.GET_CATEGORIES_REQUEST:
    case BusinessType.GET_CATEGORIES_ERROR: {
      return {
        ...state,
      };
    }
    case BusinessType.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        businessCategories: action.payload,
      };
    }
    case BusinessType.FETCH_BUSINESS_DETAILS_BY_BUSINESSID_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case BusinessType.FETCH_BUSINESS_DETAILS_BY_BUSINESSID_SUCCESS: {
      return {
        ...state,
        loading: false,
        businessWallDetails: action.payload,
      };
    }
    case BusinessType.FETCH_BUSINESS_DETAILS_BY_BUSINESSID_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case BusinessType.FETCH_BUSINESS_POSTS_REQUEST: {
      return {
        ...state,
        businessPostsLoading: true,
      };
    }
    case BusinessType.FETCH_BUSINESS_POSTS_SUCCESS: {
      const { posts, reset, hasMoreData, offset } = action.payload;
      let updatedData = [...posts];
      if (!reset) {
        updatedData = [...state.businessPostsState.posts, ...action.payload.posts];
      }

      return {
        ...state,
        businessPostsLoading: false,
        businessPostsState: { offset, hasMoreData, posts: updatedData },
      };
    }
    case BusinessType.FETCH_BUSINESS_POSTS_ERROR: {
      return {
        ...state,
        businessPostsLoading: false,
      };
    }
    case BusinessType.CREATE_BUSINESS_POST_REQUEST: {
      return {
        ...state,
        createBusinessPostLoading: true,
      };
    }
    case BusinessType.CREATE_BUSINESS_POST_SUCCESS: {
      return {
        ...state,
        createBusinessPostLoading: false,
        businessPostsState: {
          ...state.businessPostsState,
          posts: [action.payload, ...state.businessPostsState.posts],
        },
      };
    }
    case BusinessType.CREATE_BUSINESS_POST_ERROR: {
      return {
        ...state,
        createBusinessPostLoading: false,
      };
    }

    case BusinessType.EDIT_BUSINESS_POST_REQUEST: {
      return {
        ...state,
        editBusinessPostLoading: true,
      };
    }
    case BusinessType.EDIT_BUSINESS_POST_SUCCESS: {
      const selectedIndex: number = state.businessPostsState.posts.findIndex(
        (e: IBusinessPost) => e.documentId === action.payload.documentId,
      );
      const posts = cloneDeep(state.businessPostsState.posts);
      if (selectedIndex !== -1) {
        posts[selectedIndex].textContent = action.payload.textContent;
        posts[selectedIndex].updatedAt = action.payload.updatedAt;
        posts[selectedIndex].image = action.payload.image;
      }
      return {
        ...state,
        editBusinessPostLoading: false,
        businessPostsState: {
          ...state.businessPostsState,
          posts,
        },
      };
    }
    case BusinessType.EDIT_BUSINESS_POST_ERROR: {
      return {
        ...state,
        editBusinessPostLoading: false,
      };
    }

    case BusinessType.DELETE_BUSINESS_POST_REQUEST: {
      return {
        ...state,
        isDeleteBusinessPostLoading: true,
      };
    }
    case BusinessType.DELETE_BUSINESS_POST_SUCCESS: {
      const selectedIndex: number = state.businessPostsState.posts.findIndex(
        (e: IBusinessPost) => e.documentId === action.payload,
      );
      const posts = cloneDeep(state.businessPostsState.posts);
      if (selectedIndex !== -1) {
        posts.splice(selectedIndex, 1);
      }
      return {
        ...state,
        isDeleteBusinessPostLoading: false,
        businessPostsState: {
          ...state.businessPostsState,
          posts,
        },
      };
    }
    case BusinessType.DELETE_BUSINESS_POST_ERROR: {
      return {
        ...state,
        isDeleteBusinessPostLoading: false,
      };
    }

    case BusinessType.FETCH_BUSINESS_MEDIA_REQUEST: {
      return {
        ...state,
        businessMediaLoading: true,
      };
    }
    case BusinessType.FETCH_BUSINESS_MEDIA_SUCCESS: {
      return {
        ...state,
        businessMediaLoading: false,
        businessMediaState: {
          offset: action.payload.offset,
          hasMoreData: action.payload.hasMoreData,
          businessMedia: {
            ...action.payload.businessMedia,
            postImages: [
              ...state.businessMediaState.businessMedia.postImages,
              ...action.payload.businessMedia.postImages,
            ],
          },
        },
      };
    }
    case BusinessType.FETCH_BUSINESS_MEDIA_ERROR: {
      return {
        ...state,
        businessMediaLoading: false,
      };
    }
    case BusinessType.RESET_BUSINESS_WALL_STATE: {
      return {
        ...state,
        businessPostsState: {
          offset: 0,
          hasMoreData: true,
          posts: [],
        },
        businessWallDetails: {} as IBusinessDetails,
        businessMediaState: {
          offset: 0,
          hasMoreData: true,
          businessMedia: {
            businessImages: {} as IBusinessMediaImages,
            postImages: [],
          },
        },
      };
    }
    case BusinessType.RESET_BUSINESS_POSTS_STATE: {
      return {
        ...state,
        businessPostsState: {
          offset: 0,
          hasMoreData: true,
          posts: [],
        },
      };
    }
    case BusinessType.RESET_BUSINESS_MEDIA_STATE: {
      return {
        ...state,
        businessMediaState: {
          offset: 0,
          hasMoreData: true,
          businessMedia: {
            businessImages: {} as IBusinessMediaImages,
            postImages: [],
          },
        },
      };
    }
    case BusinessType.FETCH_BUSINESS_POST_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case BusinessType.FETCH_BUSINESS_POST_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        businessPostDetails: action.payload,
        isMyOwnPost: action.payload.isMyOwnPost,
      };
    }
    case BusinessType.FETCH_BUSINESS_POST_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case BusinessType.RESET_BUSINESS_POST_DETAILS_STATE: {
      return {
        ...state,
        businessPostDetails: {} as IBusinessPostDetails,
      };
    }
    case BusinessType.LIKE_DISLIKE_BUSINESS_POST_REQUEST:
    case BusinessType.LIKE_DISLIKE_BUSINESS_POST_ERROR: {
      return {
        ...state,
      };
    }
    case BusinessType.LIKE_DISLIKE_BUSINESS_POST_SUCCESS: {
      const isLiked = !state.businessPostDetails.isLiked;
      const likesCount = isLiked
        ? state.businessPostDetails.likesCount + 1
        : state.businessPostDetails.likesCount - 1;

      return {
        ...state,
        businessPostDetails: {
          ...state.businessPostDetails,
          isLiked,
          likesCount,
        },
      };
    }
    case BusinessType.BUSINESS_LIKE_DISLIKE_BUSINESS_POST_SUCCESS: {
      const selectedIndex = state.businessPostsState.posts.findIndex(
        (e: IBusinessPost) => e.documentId === action.payload,
      );
      const posts = cloneDeep(state.businessPostsState.posts);
      if (selectedIndex !== -1) {
        const likeStatus = !posts[selectedIndex].isLiked;
        posts[selectedIndex].isLiked = likeStatus;
        posts[selectedIndex].likesCount = likeStatus
          ? posts[selectedIndex].likesCount + 1
          : posts[selectedIndex].likesCount - 1;
      }

      return {
        ...state,
        businessPostsState: {
          ...state.businessPostsState,
          posts,
        },
      };
    }

    case BusinessType.RESET_BUSINESS_DETAILS_STATE: {
      return {
        ...state,
        businessDetails: {} as IBusinessDetails,
      };
    }
    default:
      return state;
  }
};

export default BusinessReducer;
