import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ConfirmationModal from 'components/confirmationModal/ConfirmationModal';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import Loader from 'components/loader/Loader';
import ModalContainer from 'components/modal/ModalContainer';
import { StyledTableCell, StyledTableRow } from 'components/table/StyledTable';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import { useCallback, useEffect, useState } from 'react';
import { getBusinessDetailsByAdmin, IApprovedBusiness, resetBusinessDetailsState } from 'store';
import {
  deleteBusinessByAdmin,
  denyBusiness,
  getApprovedBusinesses,
  getApprovedBusinessesFile,
  resetApprovedBusinessesState,
} from 'store/admin/vettingDashboard/VettingDashboardActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { downloadCsv, handleFormatToUSNumber } from 'utils/utils';

import AddExistingMemberBusiness from './AddBusiness/AddExistingBusiness';
import AddNewMemberBusiness from './AddBusiness/AddNewMemberBusiness';
import DateRange, { IFilters } from './DateRange';
import EditBusiness from './EditBusiness';

const ADD_BUSINESS_TYPE_OPTIONS = [
  { label: 'Existing Member', value: 'existingMember' },
  { label: 'New Member', value: 'newMember' },
];

const BUSINESS_TYPE_OPTIONS = [
  { label: 'Freedom Chamber', value: 'paid' },
  { label: 'Basic', value: 'free' },
  { label: 'Deactivated', value: 'deactivated' },
  { label: 'Deleted', value: 'deleted' },
];

function ApprovedBusinessReport() {
  const dispatch = useAppDispatch();
  const [selectedBusiness, setSelectedBusiness] = useState({} as IApprovedBusiness);
  const [showModal, setShowModal] = useState(false);
  const [showModalType, setShowModalType] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [addBusinessModal, setAddBusinessModal] = useState(false);
  const [showExistingMemberModal, setShowExistingMemberModal] = useState<boolean>(false);
  const [showNewMemberModal, setShowNewMemberModal] = useState<boolean>(false);
  const [csvLoader, showCsvLoader] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilters>();

  const [selectedBusinessType, setSelectedBusinessType] = useState(BUSINESS_TYPE_OPTIONS[0].value);

  const { loading, approvedBusinessesData, isApprovedBusinessesLoading, dateRange } =
    useAppSelector(s => s.vettingDashboard);

  const { isBusinessUpdatedSuccessfully } = useAppSelector(s => s.business);
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  useEffect(() => {
    return () => {
      dispatch(resetApprovedBusinessesState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isBusinessUpdatedSuccessfully) {
      dispatch(resetApprovedBusinessesState());
      setShowEditModal(false);

      const businessType = ['paid', 'free'].includes(selectedBusinessType)
        ? selectedBusinessType
        : undefined;

      const status = ['deleted', 'deactivated'].includes(selectedBusinessType)
        ? selectedBusinessType
        : undefined;

      dispatch(
        getApprovedBusinesses({
          offset: 0,
          from: dateRange?.from,
          to: dateRange?.to,
          limit: approvedBusinessesData.offset,
          biztype: businessType,
          status,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBusinessUpdatedSuccessfully]);

  useEffect(() => {
    dispatch(resetApprovedBusinessesState());
    const debounceSearchFn = setTimeout(async () => {
      fetchApprovedBusinesses(true, selectedBusinessType);
    }, 200);

    return () => clearTimeout(debounceSearchFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const fetchApprovedBusinesses = useCallback(
    (initialLoad: boolean, type?: string) => {
      type = type || selectedBusinessType;

      const businessType = ['paid', 'free'].includes(type) ? type : undefined;
      const status = ['deleted', 'deactivated'].includes(type) ? type : undefined;

      const params = {
        offset: initialLoad ? 0 : approvedBusinessesData.offset,
        from: filters?.range?.from || '',
        to: filters?.range?.to || '',
        biztype: businessType,
        searchQuery: filters?.searchQuery || '',
        reset: initialLoad,
        status,
      };
      dispatch(getApprovedBusinesses(params));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [approvedBusinessesData.offset, filters, selectedBusinessType],
  );

  const onSubmit = () => {
    switch (showModalType) {
      case 'De-activate': {
        dispatch(denyBusiness(selectedBusiness?.documentId));
        setShowModal(false);
        break;
      }
      case 'Re-activate': {
        setShowModal(false);
        break;
      }
      case 'Delete': {
        dispatch(deleteBusinessByAdmin(selectedBusiness?.documentId));
        setShowModal(false);
        break;
      }

      default:
        break;
    }
  };

  const handleTypeChange = (type: string) => {
    setSelectedBusinessType(type);
    dispatch(resetApprovedBusinessesState());
    fetchApprovedBusinesses(true, type);
  };

  const downloadAsCSV = async ({ range, searchQuery }: IFilters) => {
    const csvFileName = 'businessReport';

    const businessType = ['paid', 'free'].includes(selectedBusinessType)
      ? selectedBusinessType
      : undefined;
    const status = ['deleted', 'deactivated'].includes(selectedBusinessType)
      ? selectedBusinessType
      : undefined;

    showCsvLoader(true);
    const csvData = await getApprovedBusinessesFile({
      range,
      searchQuery,
      biztype: businessType,
      status,
    });

    if (csvData && csvFileName) {
      downloadCsv(csvData, csvFileName);
      showCsvLoader(false);
    }
  };

  const handleAddBusinessModal = (item: string) => {
    setAddBusinessModal(false);

    if (item === 'existingMember') {
      setShowExistingMemberModal(true);
    } else {
      setShowNewMemberModal(true);
    }
  };

  const isDownloadBtnDisabled =
    approvedBusinessesData?.approvedBusinesses?.length === 0 || csvLoader;

  const getFormattedPhone = (phone: string) => {
    const formattedNum = phone.replace('+1', '').replace(/\D/g, '');
    if (formattedNum) {
      return `+1 ${handleFormatToUSNumber(formattedNum)}`;
    }
    return '';
  };

  return (
    <>
      <Box paddingBottom={2}>
        <DateRange
          isDownloadCSV
          showDateRange
          showSearch
          csvLoader={csvLoader}
          isDisabled={isDownloadBtnDisabled}
          isPayoutTab={false}
          searchPlaceHolder="Business"
          onChangeFilter={setFilters}
          onDownloadCSV={downloadAsCSV}
        />
      </Box>
      <ComponentWrapper
        withInfiniteScroll
        borderColor="light"
        infiniteScrollProps={{
          loadOnMount: false,
          hasMoreData: approvedBusinessesData.hasMoreData,
          isLoading: isApprovedBusinessesLoading,
          onBottomHit: fetchApprovedBusinesses,
          valueToBeAddedToInnerHeight: 50,
        }}
        title=""
        withPadding={false}
      >
        <Stack
          borderBottom={`0.25px solid ${theme.palette.divider}`}
          direction="column"
          padding={isMobile ? theme.spacing(2) : theme.spacing(3.65, 4, 3)}
          spacing={theme.spacing(1.5)}
        >
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography color="text.secondary" variant={isMobile ? 'title2' : 'title1'}>
              Businesses
            </Typography>
            <Button size="large" variant="contained" onClick={() => setAddBusinessModal(true)}>
              +ADD BUSINESS
            </Button>
          </Stack>

          <Stack direction="row" spacing={theme.spacing(4)}>
            {BUSINESS_TYPE_OPTIONS.map(type => (
              <Typography
                key={type.label}
                sx={{
                  cursor: 'pointer',
                  textTransform: 'capitalize',
                  ...(selectedBusinessType === type.value && {
                    textDecoration: 'underline',
                    textUnderlineOffset: '4px',
                  }),
                  '&:hover': {
                    textDecoration: 'underline',
                    textUnderlineOffset: '4px',
                  },
                }}
                variant="title2"
                onClick={() => handleTypeChange(type.value)}
              >
                {type.label}
              </Typography>
            ))}
          </Stack>
        </Stack>
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Business name</StyledTableCell>
                <StyledTableCell>Contact Person</StyledTableCell>
                <StyledTableCell>Business Phone</StyledTableCell>
                <StyledTableCell>Contact Email</StyledTableCell>
                <StyledTableCell>Sign up Date</StyledTableCell>
                {/* <StyledTableCell>Affiliate Link</StyledTableCell> */}
                {!['deleted'].includes(selectedBusinessType) ? (
                  <StyledTableCell>Action</StyledTableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {(!isApprovedBusinessesLoading ||
                approvedBusinessesData.approvedBusinesses.length > 0) &&
                approvedBusinessesData.approvedBusinesses.map(
                  (business: IApprovedBusiness, index: number) => (
                    <StyledTableRow key={`${business.documentId}_${index.toString()}`}>
                      <StyledTableCell>{business.name}</StyledTableCell>
                      <StyledTableCell>{business.contactPerson}</StyledTableCell>
                      <StyledTableCell>{getFormattedPhone(business.contactPhone)}</StyledTableCell>
                      <StyledTableCell>{business.email}</StyledTableCell>
                      <StyledTableCell>{business.dateSignUp}</StyledTableCell>
                      {/* <StyledTableCell>
                        {business.affiliateReferralLink && (
                          <a
                            href={business.affiliateReferralLink}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {!isMobile ? (
                              <>
                                <Tooltip
                                  title={
                                    <>
                                      <Typography
                                        color="common.white"
                                        variant="body2"
                                      >
                                        {business.affiliateReferralLink}
                                      </Typography>
                                    </>
                                  }
                                >
                                  <Typography>
                                    {business.affiliateReferralLink.substring(
                                      0,
                                      15,
                                    )}
                                    ...
                                  </Typography>
                                </Tooltip>
                              </>
                            ) : (
                              <>{business.affiliateReferralLink}</>
                            )}
                          </a>
                        )}
                      </StyledTableCell> */}

                      {!['deleted'].includes(selectedBusinessType) ? (
                        <StyledTableCell align="center">
                          {['paid', 'free'].includes(selectedBusinessType) && (
                            <Stack direction="row" spacing={theme.spacing(2)}>
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  dispatch(resetBusinessDetailsState());
                                  dispatch(getBusinessDetailsByAdmin(business.documentId));
                                  setShowEditModal(true);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                  setSelectedBusiness(business);
                                  setShowModal(true);

                                  setShowModalType('De-activate');
                                }}
                              >
                                De-activate
                              </Button>
                            </Stack>
                          )}

                          {['deactivated'].includes(selectedBusinessType) && (
                            <Stack direction="row" spacing={theme.spacing(2)}>
                              <Button
                                color="error"
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  setSelectedBusiness(business);
                                  setShowModal(true);
                                  setShowModalType('Delete');
                                }}
                              >
                                Delete
                              </Button>
                              {/* <Button
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                  setSelectedBusiness(business);
                                  setShowModal(true);
                                  setShowModalType('Re-activate');
                                }}
                              >
                                Re-activate
                              </Button> */}
                            </Stack>
                          )}
                        </StyledTableCell>
                      ) : null}
                    </StyledTableRow>
                  ),
                )}
            </TableBody>
          </Table>
        </TableContainer>
        {isApprovedBusinessesLoading && (
          <Box padding={theme.spacing(2, 0)} width="100%">
            <Loader />
          </Box>
        )}
        {!isApprovedBusinessesLoading && approvedBusinessesData.approvedBusinesses.length === 0 && (
          <Box padding={theme.spacing(4)} width="100%">
            <Typography align="center">No records found</Typography>
          </Box>
        )}
      </ComponentWrapper>
      <ToggleDrawerDialog
        showCloseIcon
        handleClose={() => setShowModal(false)}
        isMobile={isMobile}
        open={showModal}
        setOpen={setShowModal}
        title={`${showModalType} Business`}
      >
        <ConfirmationModal
          loading={loading}
          modalText={`Are you sure you want to ${
            showModalType === 'Delete'
              ? `permanently ${showModalType.toLocaleLowerCase()}`
              : showModalType.toLocaleLowerCase()
          } ${selectedBusiness.name}? ${
            showModalType === 'Delete' ? 'This action can not be undone.' : ''
          }`}
          submitBtnText="Confirm"
          onClose={() => setShowModal(false)}
          onSubmit={onSubmit}
        />
      </ToggleDrawerDialog>

      <ToggleDrawerDialog
        showCloseIcon
        handleClose={() => setAddBusinessModal(false)}
        isMobile={isMobile}
        open={addBusinessModal}
        setOpen={setAddBusinessModal}
        title="Add Business"
      >
        <Stack direction="row" justifyContent="space-around">
          {ADD_BUSINESS_TYPE_OPTIONS.map((item, i) => {
            return (
              <Button
                key={item.label}
                size="large"
                variant={i % 2 === 0 ? 'contained' : 'outlined'}
                onClick={() => handleAddBusinessModal(item.value)}
              >
                {item.label}
              </Button>
            );
          })}
        </Stack>
      </ToggleDrawerDialog>

      {showEditModal && (
        <ModalContainer
          disableScroll
          fullWidth
          showCloseIcon
          handleClose={() => setShowEditModal(false)}
          maxWidth="md"
          open={showEditModal}
          PaperProps={{
            sx: {
              ...(!isMobile && {
                maxHeight: '600px',
                minHeight: '600px',
              }),
            },
          }}
          title="Edit Business"
        >
          <EditBusiness />
        </ModalContainer>
      )}

      {showExistingMemberModal && (
        <AddExistingMemberBusiness
          setMemberModal={() => setShowExistingMemberModal(false)}
          showExistingMemberModal={showExistingMemberModal}
        />
      )}
      {showNewMemberModal && (
        <AddNewMemberBusiness
          setMemberModal={() => setShowNewMemberModal(false)}
          showNewMemberModal={showNewMemberModal}
        />
      )}
    </>
  );
}

export default ApprovedBusinessReport;
