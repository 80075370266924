import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import { StyledTableCell, StyledTableRow } from 'components/table/StyledTable';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import React, { useEffect, useState } from 'react';
import { getAffiliatesListFile, IAffiliateData } from 'store';
import {
  listAffiliates,
  resetAffiliatesListState,
} from 'store/admin/vettingDashboard/VettingDashboardActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { downloadCsv } from 'utils/utils';

import DateRange, { IFilters } from './DateRange';

function AffiliateReport() {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const [csvLoader, showCsvLoader] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilters>();

  const { affiliatesData, isListAffiliatesLoading } = useAppSelector(s => s.vettingDashboard);

  useEffect(() => {
    return () => {
      dispatch(resetAffiliatesListState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(resetAffiliatesListState());
    const debounceSearchFn = setTimeout(async () => {
      fetchAffiliates(true);
    }, 200);

    return () => clearTimeout(debounceSearchFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const fetchAffiliates = (initialLoad: boolean) => {
    dispatch(
      listAffiliates(
        initialLoad ? 0 : affiliatesData.offset,
        filters?.range.from,
        filters?.range.to,
      ),
    );
  };

  const downloadAsCSV = async ({ range }: IFilters) => {
    // Change case for modified tab
    showCsvLoader(true);
    const fileType = 'affiliates';
    const csvData = await getAffiliatesListFile(range.from, range.to);

    if (csvData && fileType) {
      downloadCsv(csvData, fileType);
      showCsvLoader(false);
    }
  };

  // const isDownloadBtnDisabled =
  //   affiliatesData?.affiliates?.length === 0 || csvLoader;

  return (
    <>
      <Box paddingBottom={2}>
        <DateRange
          isDisabled
          isDownloadCSV
          showDateRange
          csvLoader={csvLoader}
          isPayoutTab={false}
          showSearch={false}
          onChangeFilter={setFilters}
          onDownloadCSV={downloadAsCSV}
        />
      </Box>
      <ComponentWrapper
        withInfiniteScroll
        borderColor="light"
        infiniteScrollProps={{
          loadOnMount: true,
          hasMoreData: affiliatesData.hasMoreData,
          isLoading: isListAffiliatesLoading,
          onBottomHit: fetchAffiliates,
          valueToBeAddedToInnerHeight: 50,
        }}
        title=""
        withPadding={false}
      >
        <Stack
          alignItems="center"
          borderBottom={`0.25px solid ${theme.palette.divider}`}
          direction="row"
          padding={isMobile ? theme.spacing(2) : theme.spacing(3.65, 4, 3)}
          spacing={theme.spacing(1.5)}
        >
          <Typography color="text.secondary" variant={isMobile ? 'title2' : 'title1'}>
            Affiliates
          </Typography>
        </Stack>
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Username</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Number of Clicks</StyledTableCell>
                <StyledTableCell>Number of Registrants</StyledTableCell>
                <StyledTableCell>Number of Conversions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {affiliatesData.affiliates.map((affiliate: IAffiliateData, index: number) => (
                <StyledTableRow key={`${affiliate.userInfo.userId}_${index.toString()}`}>
                  <StyledTableCell>{affiliate.userInfo.userName}</StyledTableCell>
                  <StyledTableCell>{affiliate.userInfo.email}</StyledTableCell>
                  <StyledTableCell>{affiliate.clicks}</StyledTableCell>
                  <StyledTableCell>{affiliate.registers}</StyledTableCell>
                  <StyledTableCell>{affiliate.businessCount}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isListAffiliatesLoading && (
          <Box boxSizing="border-box" padding={theme.spacing(2, 0)} width="100%">
            <Loader />
          </Box>
        )}
        {!isListAffiliatesLoading && affiliatesData.affiliates.length === 0 && (
          <Box boxSizing="border-box" padding={theme.spacing(4)} width="100%">
            <Typography align="center">No records found</Typography>
          </Box>
        )}
      </ComponentWrapper>
    </>
  );
}

export default AffiliateReport;
