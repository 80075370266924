import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomInputField from 'components/customFields/CustomInputField';
import Loader from 'components/loader/Loader';
import { FormikErrors, FormikTouched } from 'formik';
import { BusinessComponentType, ISocialLinksState } from 'store/business/BusinessInterface';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';
import { handleAllowOnlyOneSpaceBetweenWords } from 'utils/utils';

const { SETUP } = BusinessComponentType;

interface ISocialLinksProps {
  touched?: FormikTouched<ISocialLinksState>;
  values: ISocialLinksState;
  errors?: FormikErrors<ISocialLinksState>;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T1 = string | React.ChangeEvent<any>>(field: T1): T1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: (e: React.FocusEvent) => void;
  componentType?: string;
}

export default function SocialLinks(props: ISocialLinksProps) {
  const { handleBlur, handleChange, errors, touched, values, componentType } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const { isGetBusinessDetailsLoading } = useAppSelector(s => s.business);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
  ) => handleChange(fieldName)(handleAllowOnlyOneSpaceBetweenWords(e.target.value));

  return (
    <Grid container rowSpacing={theme.spacing(2.5)}>
      <Grid item xs={12}>
        <Typography
          align={componentType === SETUP ? 'center' : 'left'}
          variant={isMobile ? 'h5' : componentType === SETUP ? 'h4' : 'h3'}
        >
          Social Links
        </Typography>
        {componentType === SETUP && (
          <Typography
            align="center"
            component="div"
            fontWeight={400}
            marginTop={theme.spacing(1.5)}
            variant="title2"
          >
            Add the social links to feature on your profile.
          </Typography>
        )}
      </Grid>
      {isGetBusinessDetailsLoading ? (
        <Loader />
      ) : (
        <Grid item xs={12}>
          <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(2)}>
            <CustomInputField
              fullWidth
              required
              error={!!(touched?.facebookUrl && errors?.facebookUrl)}
              errormessage={touched?.facebookUrl ? errors?.facebookUrl : undefined}
              id="facebookUrl"
              inputProps={{ maxLength: 75 }}
              label="Facebook URL"
              name="socialLinks.facebookUrl"
              type="text"
              value={values.facebookUrl}
              onBlur={handleBlur}
              onChange={e => handleInputChange(e, 'socialLinks.facebookUrl')}
            />
            <CustomInputField
              required
              error={!!(touched?.instagramUrl && errors?.instagramUrl)}
              errormessage={touched?.instagramUrl ? errors?.instagramUrl : undefined}
              id="instagramUrl"
              inputProps={{ maxLength: 75 }}
              label="Instagram URL"
              name="socialLinks.instagramUrl"
              type="text"
              value={values.instagramUrl}
              onBlur={handleBlur}
              onChange={e => handleInputChange(e, 'socialLinks.instagramUrl')}
            />
            <CustomInputField
              required
              error={!!(touched?.linkedinUrl && errors?.linkedinUrl)}
              errormessage={touched?.linkedinUrl ? errors?.linkedinUrl : undefined}
              id="linkedinUrl"
              inputProps={{ maxLength: 75 }}
              label="Linkedin URL"
              name="socialLinks.linkedinUrl"
              type="text"
              value={values.linkedinUrl}
              onBlur={handleBlur}
              onChange={e => handleInputChange(e, 'socialLinks.linkedinUrl')}
            />
            <CustomInputField
              required
              error={!!(touched?.twitterUrl && errors?.twitterUrl)}
              errormessage={touched?.twitterUrl ? errors?.twitterUrl : undefined}
              id="twitterUrl"
              inputProps={{ maxLength: 75 }}
              label="Twitter URL"
              name="socialLinks.twitterUrl"
              type="text"
              value={values.twitterUrl}
              onBlur={handleBlur}
              onChange={e => handleInputChange(e, 'socialLinks.twitterUrl')}
            />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}
