import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import HeaderMobileMenu from 'components/menu/HeaderMobileMenu';
import { CloseIcon, MenuIcon } from 'helpers/images';
import { appVersion } from 'helpers/urls';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import theme from 'theme';

export interface ILinkText {
  title: string;
  route: string;
}

export function LinkText({ title, route }: ILinkText) {
  return (
    <Box
      end
      component={NavLink}
      sx={{
        textDecoration: 'none !important',
        '&.active .MuiTypography-root': {
          fontWeight: 600,
          color: theme.palette.primary.main,
        },
      }}
      to={route}
    >
      <Typography variant="menu">{title}</Typography>
    </Box>
  );
}

export default function HeaderDrawer() {
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (openDrawer) {
      handleCloseDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <Box sx={{ cursor: 'pointer' }} onClick={() => setOpenDrawer(true)}>
        <MenuIcon />
      </Box>
      <Drawer
        anchor="left"
        open={openDrawer}
        PaperProps={{
          sx: {
            width: '400px',
            maxWidth: '100%',
            justifyContent: 'space-between',
          },
        }}
        onClose={handleCloseDrawer}
      >
        <Box boxSizing="border-box" padding={theme.spacing(3.25, 3)}>
          <IconButton onClick={handleCloseDrawer}>
            <SvgIcon
              inheritViewBox
              component={CloseIcon}
              sx={{
                width: theme.spacing(2.5),
                height: theme.spacing(2.5),
                color: theme.palette.common.black,
              }}
            />
          </IconButton>
          <Stack
            border={`0.25px solid ${theme.palette.text.secondary}`}
            marginTop={theme.spacing(3.75)}
            padding={theme.spacing(3)}
            spacing={theme.spacing(2.5)}
          >
            <HeaderMobileMenu />
          </Stack>
        </Box>
        <Box boxSizing="border-box" padding={theme.spacing(3)}>
          <Typography component="div" textAlign="center" variant="body1Bold">
            {`Version ${appVersion}`}
          </Typography>
        </Box>
      </Drawer>
    </>
  );
}
