import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BadgeAvatar from 'components/avatar/BadgeAvatar';
import CustomInputField from 'components/customFields/CustomInputField';
import ImageCropper from 'components/imageCropper/ImageCropper';
import { IMAGE_EXTENSIONS } from 'constants/constants';
import { useState } from 'react';
import {
  alertError,
  IBusinessPostImage,
  ICreateBusinessPostPayload,
  IImageUploadState,
  IResponseData,
} from 'store';
import { createBusinessPost, editBusinessPost } from 'store/business/BusinessAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { dataURItoBlob, isHtmlTagString, processImage } from 'utils/utils';
import { validateFileFormat, validateFileSize } from 'utils/validator';

interface ICreateBusinessProps {
  businessId: string;
  type: string;
  handleEditPost?: () => void;
  textContent: string;
  imageProps?: IBusinessPostImage;
  postId?: string;
}

interface IPostUploadState extends IImageUploadState {
  croppedFile: Blob | null;
  imageViewAttribute: string;
  originalImage: string;
}

interface ISelectedImageState {
  file: Blob | null;
  originalImage: string;
}

export default function CreateBusinessPost(props: ICreateBusinessProps) {
  const dispatch = useAppDispatch();
  const {
    businessId,
    type,
    handleEditPost,
    textContent,
    imageProps = {} as IBusinessPostImage,
    postId = '',
  } = props;
  const [postContent, setPostContent] = useState<string>(textContent);
  const [postContentErrorMsg, setPostContentErrorMsg] = useState<string>('');
  const [cropImageLoading, setCropImageLoading] = useState<boolean>(false);
  const [editBusinessPostLoading, setEditBusinessPostLoading] = useState<boolean>(false);
  const [upload, setUpload] = useState<IPostUploadState>({
    croppedFile: null,
    editImage: false,
    file: null,
    originalImage: imageProps?.imageURL || '',
    previewImage: imageProps?.croppedImageURL || imageProps?.croppedImageUrl || '',
    imageViewAttribute: imageProps?.imageViewAttribute || '',
  });
  const [selectedImage, setSelectedImage] = useState<ISelectedImageState>({
    file: null,
    originalImage: '',
  });

  const { businessDetails, createBusinessPostLoading } = useAppSelector(s => s.business);
  const { userData } = useAppSelector(s => s.account);

  const handleImageEdit = () => {
    setUpload({ ...upload, editImage: true });
  };

  const handleImageDelete = () => {
    setUpload({
      ...upload,
      croppedFile: null,
      editImage: false,
      file: null,
      previewImage: '',
      originalImage: '',
      imageViewAttribute: '',
    });
  };

  const selectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const isImage: boolean = file.type.includes('image');
    if (target?.files?.length === 1 && isImage) {
      if (isImage && !validateFileFormat(file.name, IMAGE_EXTENSIONS)) {
        dispatch(
          alertError({
            message:
              'File format is not supported. Please select one of the following file types: jpg, jpeg, png, heic or webp.',
          }),
        );
        return;
      }
      const maxSize = 100;
      const isFileSizeValid: boolean = validateFileSize(file.size, maxSize);

      if (isFileSizeValid) {
        setSelectedImage({ originalImage: await processImage(file), file });
        setUpload({ ...upload, editImage: true });
      } else {
        dispatch(
          alertError({
            message: `The image file must be ${maxSize} MB or less.`,
          }),
        );
      }
    } else {
      dispatch(
        alertError({
          message: 'File format is not supported. Please select an image and try again.',
        }),
      );
    }
  };

  const handleCancelUploadImage = () => {
    setSelectedImage({ file: null, originalImage: '' });
    setUpload({ ...upload, editImage: false });
  };

  const createFile = async (image: string, fileName: string) => {
    try {
      const response = await fetch(image);
      const data = await response.blob();
      const metadata = { type: 'image/jpeg' };
      const file = new File([data], fileName, metadata);
      // ... do something with the file or return it
      return file;
    } catch (error) {
      dispatch(
        alertError({
          message: `Error while uploading business image, Please try again later`,
        }),
      );
      return null;
    }
  };

  const handleApply = async (cropper: any, minZoom: number, zoom: number) => {
    setCropImageLoading(true);
    const croppedCanvas = cropper.getCroppedCanvas().toDataURL('image/jpeg');
    const blob = dataURItoBlob(croppedCanvas);
    const croppedFile = new File([blob], 'croppedBusinessPost.jpeg');
    let newFile: Blob | null = null;
    if (upload.file === null && selectedImage.file === null) {
      newFile = await createFile(upload.originalImage, 'businessPost.jpeg');
      if (newFile === null) return;
    }
    setUpload({
      ...upload,
      croppedFile,
      file: selectedImage.file !== null ? selectedImage.file : upload.file || newFile,
      originalImage:
        selectedImage.file !== null
          ? await processImage(selectedImage.file)
          : upload.file === null
          ? await processImage(newFile as any)
          : await processImage(upload.file),
      editImage: false,
      previewImage: await processImage(croppedFile),
      imageViewAttribute: JSON.stringify({
        data: cropper.getData(false),
        canvasData: cropper.getCanvasData(),
        cropBoxData: cropper.getCropBoxData(),
        minZoom,
        zoom,
      }),
    });
    setSelectedImage({ file: null, originalImage: '' });
    setCropImageLoading(false);
  };

  const handleBlur = () => {
    if (postContent.trim().length === 0) {
      setPostContentErrorMsg('Content is mandatory');
    } else if (postContent.trim().length > 3000) {
      setPostContentErrorMsg('Content should not exceed 3000 characters');
    } else {
      setPostContentErrorMsg('');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPostContent(e.target.value);
  };

  const resetBusinessPostData = () => {
    setPostContent('');
    setPostContentErrorMsg('');
    handleImageDelete();
  };

  const createPost = async () => {
    const payload: ICreateBusinessPostPayload = {
      contentDataType: upload.file || upload.originalImage ? 'image' : 'text',
      croppedMediaContent: upload.croppedFile,
      mediaContent: upload.file,
      imageViewAttribute: upload.imageViewAttribute,
      textContent: postContent,
    };

    if (isHtmlTagString(postContent)) {
      return dispatch(alertError({ message: 'html content is not allowed in post!' }));
    }

    if (type === 'create') {
      const response: IResponseData = (await dispatch(
        createBusinessPost(businessId as string, payload),
      )) as IResponseData;
      if (!response.error) {
        resetBusinessPostData();
      }
    } else if (
      type === 'edit' &&
      (postContent !== textContent || upload.imageViewAttribute !== imageProps?.imageViewAttribute)
    ) {
      setEditBusinessPostLoading(true);
      const response: IResponseData = (await dispatch(
        editBusinessPost(postId as string, payload),
      )) as IResponseData;
      if (!response.error) {
        handleEditPost?.();
      }
      setEditBusinessPostLoading(false);
    } else {
      handleEditPost?.();
    }

    return true;
  };

  return (
    <Stack
      sx={{
        border: '0.5px solid rgba(29, 29, 29, 0.8)',
        // filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25))",
        borderRadius: '20px',
        minHeight: '245px',
        my: theme.spacing(3),
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        spacing={theme.spacing(2)}
        sx={{ m: theme.spacing(3) }}
        width="100%"
      >
        <Avatar
          alt="provider-logo"
          src={userData?.croppedImageReadUrl ?? ''}
          sx={{
            width: theme.spacing(5),
          }}
          variant="circular"
        />
        <Typography component="h6" variant="h6Bold">
          {businessDetails?.name ?? 'User Name'}
        </Typography>
      </Stack>
      <CustomInputField
        fullWidth
        multiline
        required
        errormessage={postContentErrorMsg}
        id="comment"
        label=""
        minRows={5}
        name="comment"
        placeholder="What are your thoughts?"
        stackstyles={{
          width: 'auto',
          mx: theme.spacing(1.5),
        }}
        sx={{
          '&.MuiInputBase-root, &.MuiInputBase-root.Mui-focused': {
            border: 0,
          },
        }}
        type="textarea"
        value={postContent}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      {upload.previewImage ? (
        <Badge
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          badgeContent={
            <>
              <BadgeAvatar onClick={handleImageDelete}>
                <DeleteIcon fontSize="small" />
              </BadgeAvatar>
            </>
          }
          sx={{ m: theme.spacing(3) }}
        >
          <img alt="uploaded" src={upload.previewImage} width="100%" />
          <BadgeAvatar showSecondBadge onClick={handleImageEdit}>
            <AddPhotoAlternateIcon fontSize="small" />
          </BadgeAvatar>
        </Badge>
      ) : (
        <></>
      )}

      <Stack
        direction="row"
        justifyContent={upload.previewImage ? 'flex-end' : 'space-between'}
        sx={{ m: theme.spacing(3) }}
      >
        {!upload.previewImage && (
          <label htmlFor={`postImage-${type}`}>
            <Box component="span">
              <input
                accept={IMAGE_EXTENSIONS}
                id={`postImage-${type}`}
                name={`postImage-${type}`}
                style={{ display: 'none' }}
                type="file"
                onChange={selectFile}
                onClick={(e: any) => {
                  e.target.value = null;
                }}
              />
              <Stack alignItems="center" direction="column" justifyContent="center">
                <AddPhotoAlternateIcon fontSize="small" sx={{ cursor: 'pointer' }} />
              </Stack>
            </Box>
          </label>
        )}
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-end"
          spacing={theme.spacing(2)}
        >
          <Button
            disableRipple
            disableTouchRipple
            id={`cancel-${type}-button`}
            name={`cancel-${type}-button`}
            type="button"
            variant="text"
            onClick={type === 'create' ? () => resetBusinessPostData() : handleEditPost}
          >
            Cancel
          </Button>
          <LoadingButton
            disableRipple
            disableTouchRipple
            disabled={
              postContent.trim().length > 3000 ||
              (postContent.trim().length === 0 && !upload.originalImage)
            }
            id={`post-${type}-button`}
            loading={type === 'create' ? createBusinessPostLoading : editBusinessPostLoading}
            name={`post-${type}-button`}
            sx={{ borderRadius: '20px' }}
            type="submit"
            variant="contained"
            onClick={() => createPost()}
          >
            Post
          </LoadingButton>
        </Stack>
      </Stack>
      {upload.editImage && (
        <ImageCropper
          enableZoom
          footer
          header
          // aspectRatio={16 / 9}
          croppedImageData={upload.imageViewAttribute ? JSON.parse(upload.imageViewAttribute) : {}}
          handleApply={handleApply}
          handleCancelUploadImage={handleCancelUploadImage}
          image={selectedImage.originalImage ? '' : upload.originalImage}
          imageType="square"
          initialAspectRatio={1 / 1}
          loading={cropImageLoading}
          previewImage={selectedImage.originalImage}
          selectFile={selectFile}
          styles={{ minHeight: 400, maxHeight: 600, width: '100%' }}
          title="Edit Business Post"
        />
      )}
    </Stack>
  );
}
