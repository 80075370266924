import { FormikHelpers, useFormik } from 'formik';
import {
  IBusinessDetailsState,
  IBusinessInformationState,
  IBusinessSetupState,
  IProfileBusinessDetailsState,
  IProfileBusinessInformationState,
  ISetupBusinessDetailsState,
  ISetupBusinessInformationState,
  ISetupBusinessSocialLinksState,
  ISocialLinksState,
} from 'store/business/BusinessInterface';

export const useBusinessInformationForm = (
  onSubmit: (
    values: IBusinessInformationState,
    formikHelpers: FormikHelpers<IBusinessInformationState>,
  ) => void | Promise<unknown>,
  initialValues: IBusinessInformationState,
  validationSchema: any,
) => {
  return useFormik<IBusinessInformationState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export const useBusinessDetailsForm = (
  onSubmit: (
    values: IBusinessDetailsState,
    formikHelpers: FormikHelpers<IBusinessDetailsState>,
  ) => void | Promise<unknown>,
  initialValues: IBusinessDetailsState,
  validationSchema: any,
) => {
  return useFormik<IBusinessDetailsState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export const useSocialLinksForm = (
  onSubmit: (
    values: ISocialLinksState,
    formikHelpers: FormikHelpers<ISocialLinksState>,
  ) => void | Promise<unknown>,
  initialValues: ISocialLinksState,
  validationSchema: any,
) => {
  return useFormik<ISocialLinksState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export const useBusinessSetupForm = (
  onSubmit: (
    values: IBusinessSetupState,
    formikHelpers: FormikHelpers<IBusinessSetupState>,
  ) => void | Promise<unknown>,
  initialValues: IBusinessSetupState,
  validationSchema: any,
) => {
  return useFormik<IBusinessSetupState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export const useProfileBusinessInformationForm = (
  onSubmit: (
    values: IProfileBusinessInformationState,
    formikHelpers: FormikHelpers<IProfileBusinessInformationState>,
  ) => void | Promise<unknown>,
  initialValues: IProfileBusinessInformationState,
  validationSchema: any,
) => {
  return useFormik<IProfileBusinessInformationState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export const useProfileBusinessDetailsForm = (
  onSubmit: (
    values: IProfileBusinessDetailsState,
    formikHelpers: FormikHelpers<IProfileBusinessDetailsState>,
  ) => void | Promise<unknown>,
  initialValues: IProfileBusinessDetailsState,
  validationSchema: any,
) => {
  return useFormik<IProfileBusinessDetailsState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export const useSetupBusinessInformationForm = (
  onSubmit: (
    values: ISetupBusinessInformationState,
    formikHelpers: FormikHelpers<ISetupBusinessInformationState>,
  ) => void | Promise<unknown>,
  initialValues: ISetupBusinessInformationState,
  validationSchema: any,
) => {
  return useFormik<ISetupBusinessInformationState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export const useSetupBusinessDetailsForm = (
  onSubmit: (
    values: ISetupBusinessDetailsState,
    formikHelpers: FormikHelpers<ISetupBusinessDetailsState>,
  ) => void | Promise<unknown>,
  initialValues: ISetupBusinessDetailsState,
  validationSchema: any,
) => {
  return useFormik<ISetupBusinessDetailsState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export const useSetupSocialLinksForm = (
  onSubmit: (
    values: ISetupBusinessSocialLinksState,
    formikHelpers: FormikHelpers<ISetupBusinessSocialLinksState>,
  ) => void | Promise<unknown>,
  initialValues: ISetupBusinessSocialLinksState,
  validationSchema: any,
) => {
  return useFormik<ISetupBusinessSocialLinksState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};
