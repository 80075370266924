import { FormikHelpers, useFormik } from 'formik';
import { IResetPasswordState } from 'store';

const useResetPasswordForm = (
  onSubmit: (
    values: IResetPasswordState,
    formikHelpers: FormikHelpers<IResetPasswordState>,
  ) => void | Promise<unknown>,
  initialValues: IResetPasswordState,
  validationSchema: any,
) => {
  return useFormik<IResetPasswordState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export default useResetPasswordForm;
