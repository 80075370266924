import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import AuthWrapper from 'components/wrapper/AuthWrapper';
import ErrorMessages from 'constants/ErrorMessages';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  alertError,
  alertSuccess,
  IResponseData,
  sendResetPassword,
  sendVerificationMail,
} from 'store';
import { useAppDispatch } from 'store/hooks';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

interface PropState {
  email: string;
  page: string;
}

function VerifyMail() {
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { email, page } = location.state as PropState;

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleResendEmail = async () => {
    if (page === 'forgotPassword') {
      const response: IResponseData = (await dispatch(sendResetPassword(email))) as IResponseData;
      if (response.error) {
        dispatch(alertError({ message: response.message }));
      } else {
        dispatch(
          alertSuccess({
            message: ErrorMessages.forgotPassword.resendEmailSuccess,
          }),
        );
      }
    }
    if (page === 'emailVerification') {
      const response: IResponseData = (await dispatch(
        sendVerificationMail(email),
      )) as IResponseData;
      if (response.error) {
        dispatch(alertError({ message: response.message }));
      } else {
        dispatch(
          alertSuccess({
            message: ErrorMessages.verificationEmail.resendMailSuccess,
          }),
        );
      }
    }
  };

  return (
    <AuthWrapper>
      {page === 'forgotPassword' ? (
        <Stack
          alignSelf="flex-start"
          direction="row"
          marginBottom={theme.spacing(4)}
          sx={{
            cursor: 'pointer',
            width: 'fit-content',
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
          Back
        </Stack>
      ) : null}

      <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(7)}>
        <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(1.5)}>
          <Typography component="div" variant={isMobile ? 'h5' : 'h2'}>
            Check your email
          </Typography>
          <Typography
            align="center"
            color={theme.palette.text.secondary}
            component="span"
            fontWeight={400}
            variant={isMobile ? 'menu' : 'title2'}
          >
            We&apos;ve sent an email to
            <Typography
              component="span"
              sx={{
                ml: theme.spacing(0.5),
                color: theme.palette.secondary.main,
              }}
            >
              {email}
            </Typography>
          </Typography>
          <Typography
            align="center"
            color={theme.palette.text.secondary}
            component="div"
            fontWeight={400}
            marginTop={`${theme.spacing(4)} !important`}
            variant={isMobile ? 'menu' : 'title2'}
          >
            Click the link in email to{' '}
            {page === 'forgotPassword'
              ? 'reset your password'
              : 'confirm and continue creating your Account'}
            .
          </Typography>
        </Stack>
        <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(1.5)}>
          <Typography noWrap align="center" component="div" variant={isMobile ? 'h5' : 'h2'}>
            Didn&apos;t get the email?
          </Typography>
          <Typography
            align="center"
            color={theme.palette.text.secondary}
            component="span"
            fontWeight={400}
            variant="h6"
          >
            Check your spam folder or
            <Typography
              color={theme.palette.secondary.main}
              component="span"
              sx={{ cursor: 'pointer', ml: theme.spacing(0.5) }}
              onClick={handleResendEmail}
            >
              resend the email
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </AuthWrapper>
  );
}

export default VerifyMail;
