import client from 'Api';
import { openGraphUrl, resourceUrl } from 'helpers/urls';
import { Dispatch } from 'redux';
import { IResponseData } from 'store/common/CommonInterface';

import { ResourceActions } from './ResourceInterface';
import ResourceType from './ResourceTypes';

// eslint-disable-next-line import/prefer-default-export
export const getResources = () => {
  return async (dispatch: Dispatch<ResourceActions>) => {
    try {
      dispatch({ type: ResourceType.GET_RESOURCES_REQUEST });
      const response: IResponseData = await client.get(`${resourceUrl}resources`);
      dispatch({
        type: ResourceType.GET_RESOURCES_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: ResourceType.GET_RESOURCES_ERROR });
      return error;
    }
  };
};

export const getOpenGraphData = (url: string) => {
  return async (dispatch: Dispatch<ResourceActions>) => {
    try {
      const response: IResponseData = await client.get(`${openGraphUrl}opengraph?url=${url}`);
      dispatch({
        type: ResourceType.GET_OPEN_GRAPH_SUCCESS,
        payload: { url, data: response.data },
      });
      return response.data;
    } catch (error: unknown) {
      return error;
    }
  };
};
