import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import NotFound from 'components/notFound/NotFound';
import Reactions from 'components/reactions';
import CommentsList from 'components/reactions/CommentsList';
import CreateComment from 'components/reactions/CreateComment';
import GuestReactionView from 'components/reactions/GuestReactionView';
import { ICommentFormValues, useCommentForm } from 'components/reactions/useCommentForm';
import PostBanner from 'components/singlePost/PostBanner';
import PostContent from 'components/singlePost/PostContent';
import PostFooter from 'components/singlePost/PostFooter';
import PostHeader from 'components/singlePost/PostHeader';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { newsUrl } from 'helpers/urls';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { ElementRef, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ContentType,
  followUnFollowNewsProvider,
  getNewsArticleDetailsById,
  IAddCommentParams,
  IGetCommentsParams,
  likeDislikeNewsPost,
  Relationship,
  resetNewsArticleState,
} from 'store';
import { addComment, getComments } from 'store/comment/CommentReducer';
import getPostShareUrl from 'store/common/CommonActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { getNewsProviderLogo, isValidImageUrl } from 'utils/utils';
import { newsCommentValidationSchema } from 'utils/validationSchema';

interface INewArticleProps {
  postId?: string;
}

export default function NewsArticle(props: INewArticleProps) {
  const { postId } = props;
  const { articleId } = useParams();

  const newsArticleId = articleId || postId;

  const commentsRef = useRef<ElementRef<'div'>>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { newsArticle, isNewsLoading, isFollowStatusLoading } = useAppSelector(s => s.news);
  const { comments, loading: loadingComments, addCommentLoading } = useAppSelector(s => s.comment);
  const hasMoreData = newsArticle?.commentsCount > comments.length;

  const [showModal, setShowModal] = useState<boolean>(false);

  const getArticleComments = (initialLoad: boolean) => {
    const params: IGetCommentsParams = {
      contentId: newsArticleId,
      offset: !initialLoad ? comments.length : 0,
      type: ContentType.ARTICLE,
    };
    dispatch(getComments(params));
  };

  useEffect(() => {
    // commentsRef?.current.scrollIntoView(true);
    if (newsArticleId) dispatch(getNewsArticleDetailsById(newsArticleId));
    return () => {
      dispatch(resetNewsArticleState());
    };
  }, [dispatch, newsArticleId]);

  useEffect(() => {
    if (
      !isNewsLoading &&
      newsArticle &&
      Object.keys(newsArticle).length > 0 &&
      isUserLoggedIn() &&
      commentsRef?.current &&
      location?.state === 'scroll-to-comments'
    ) {
      onCommentClick();
      navigate(location.pathname, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewsLoading]);

  const onCommentClick = () => {
    if (isUserLoggedIn() && commentsRef?.current) {
      commentsRef.current.scrollIntoView(true);
    } else {
      setShowModal(true);
    }
  };

  const onLikeClick = async (setDisableLike: (value: boolean) => void) => {
    if (isUserLoggedIn()) {
      await dispatch(
        likeDislikeNewsPost(newsArticleId as string, newsArticle?.isLiked, 'news', 'article'),
      );
      setDisableLike(false);
    } else {
      setShowModal(true);
      setDisableLike(false);
    }
  };

  const sharePost = async () => {
    if (isUserLoggedIn()) {
      await dispatch(
        getPostShareUrl(`${newsUrl}users/news/${newsArticleId as string}/share?type=post`),
      );
    } else {
      setShowModal(true);
    }
  };

  const handleAddComment = async (submittedValues: ICommentFormValues) => {
    const payload: IAddCommentParams = {
      comment: submittedValues.comment,
      contentId: newsArticleId,
      type: ContentType.ARTICLE,
    };

    await dispatch(addComment(payload));
    resetForm();
  };

  const commentState: ICommentFormValues = {
    comment: '',
  };

  const commentFormik = useCommentForm(handleAddComment, commentState, newsCommentValidationSchema);

  const { resetForm } = commentFormik;

  const handleRelationChange = async () => {
    if (isUserLoggedIn()) {
      await dispatch(
        followUnFollowNewsProvider(newsArticle.providerID, newsArticle?.provider?.isFollowed),
      );
    } else {
      setShowModal(true);
    }
  };

  const newsProviderLogo = useMemo(() => {
    return newsArticle?.provider?.name ? getNewsProviderLogo(newsArticle?.provider?.name) : '';
  }, [newsArticle?.provider?.name]);

  return (
    <HomeWrapper maxWidth="lg">
      <Container disableGutters maxWidth="lg">
        <Grid container alignItems="center" justifyContent="center">
          {isNewsLoading ? (
            <Loader />
          ) : newsArticle && Object.keys(newsArticle).length > 0 ? (
            <Grid item md={8} xs={12}>
              <ComponentWrapper title="">
                <PostHeader
                  contentType="News"
                  date={newsArticle?.pubDate}
                  providerLogo={newsProviderLogo}
                  providerName={newsArticle?.provider?.name}
                  providerUrl={`/news-provider-wall/${newsArticle?.providerID}`}
                />
                <PostBanner
                  imgSrc={
                    isValidImageUrl(newsArticle?.thumbnail)
                      ? newsArticle?.thumbnail
                      : newsProviderLogo
                  }
                  isMobile={isMobile}
                  link={newsArticle?.link}
                  title={newsArticle?.title}
                />
                <Reactions
                  showCount
                  commentsCount={newsArticle?.commentsCount}
                  isLiked={newsArticle?.isLiked}
                  isMobile={false}
                  likesCount={newsArticle?.likesCount}
                  showReactionText={!isMobile}
                  onCommentClick={onCommentClick}
                  onLikeClick={onLikeClick}
                  onShareClick={sharePost}
                />
                <PostContent content={newsArticle?.content} />
                <PostFooter
                  followStatus={
                    newsArticle?.provider?.isFollowed ? Relationship.UNFOLLOW : Relationship.FOLLOW
                  }
                  isLoading={isFollowStatusLoading}
                  providerName={newsArticle?.provider?.name}
                  providerType="News"
                  onFollowBtnClick={handleRelationChange}
                />
              </ComponentWrapper>
              {isUserLoggedIn() && (
                <Box ref={commentsRef} sx={{ my: theme.spacing(3) }}>
                  <ComponentWrapper
                    isTitleInsideWrapper
                    withInfiniteScroll
                    infiniteScrollProps={{
                      loadOnMount: true,
                      hasMoreData,
                      isLoading: loadingComments,
                      onBottomHit: getArticleComments,
                    }}
                    title={`Responses (${newsArticle?.commentsCount})`}
                    withDivider={false}
                  >
                    <CreateComment
                      commentFormik={commentFormik}
                      isLoading={addCommentLoading}
                      type="create"
                    />
                    <CommentsList comments={comments} type={ContentType.ARTICLE} />
                    {loadingComments && <Loader />}
                  </ComponentWrapper>
                </Box>
              )}
            </Grid>
          ) : (
            <NotFound />
          )}
          {/* <Grid item display={isMobile ? "none" : "block"} md={4} /> */}
        </Grid>
      </Container>
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </HomeWrapper>
  );
}
