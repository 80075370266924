import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import LinkPreview from 'components/linkPreview';
import ProfilePostMenu from 'components/menu/BusinessPostMenu';
import Reactions from 'components/reactions';
import GuestReactionView from 'components/reactions/GuestReactionView';
import { TYPE_LABEL_MAP } from 'constants/constants';
import { authUrl } from 'helpers/urls';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import CreateProfilePost from 'pages/Profile/ProfileWall/CreateProfilePost';
import { useMemo, useState } from 'react';
import {
  deleteProfilePost,
  fetchProfilePosts,
  IPostsImage,
  IPostType,
  likeDislikeProfilePost,
} from 'store';
import getPostShareUrl from 'store/common/CommonActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { CookieNames, getCookieItem } from 'utils/cookies';
import {
  getPreviewLink,
  replaceLinkWithAnchorTag,
  REPORTED_CONTENT_STRING,
  timeDiffCalc,
} from 'utils/utils';

interface ITileProps {
  postId: string;
  commentsCount: number;
  type?: 'profile-wall' | 'featured' | 'all-home-business' | 'home-town-crier-post';
  componentType?: 'profile' | 'trending' | 'following' | 'home';
  userImage: string;
  userId: string;
  image: string;
  postType: IPostType;
  imageProps?: IPostsImage;
  userName: string;
  textContent: string;
  postedAt: string;
  likesCount: number;
  isLiked: boolean;
  isReported: boolean;
  withDivider: boolean;
  navigateToProfilePost: (isComment: boolean) => void;
  navigateToProfileWall?: () => void;
}

function ProfilePostTile(props: ITileProps) {
  const {
    postId,
    commentsCount,
    isReported,
    componentType = 'profile',
    type,
    postType,
    userImage,
    userId,
    userName,
    textContent,
    image,
    imageProps = {} as IPostsImage,
    postedAt,
    likesCount,
    isLiked,
    withDivider = true,
    navigateToProfilePost,
    navigateToProfileWall = () => {},
  } = props;

  const defaultImageProps = {
    imageUrl: '',
    croppedImageUrl: '',
    imageViewAttribute: '',
  };
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isPostEdit, setIsPostEdit] = useState<boolean>(false);

  const ownerUserId = getCookieItem(CookieNames.USER_ID);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();
  const dispatch = useAppDispatch();

  const { editProfilePostLoading, deleteProfilePostLoading } = useAppSelector(s => s.account);

  const previewLink = useMemo(() => {
    const uniqueLink = getPreviewLink(textContent);
    return !image ? uniqueLink : '';
  }, [image, textContent]);

  const textHtmlContent = useMemo(() => {
    return isReported ? REPORTED_CONTENT_STRING : replaceLinkWithAnchorTag(textContent);
  }, [isReported, textContent]);

  const handleDeletePost = () => {
    dispatch(deleteProfilePost(postId)).then(response => {
      const params = { userId, reset: true, offset: 0, limit: 10 };
      if (!response?.error) dispatch(fetchProfilePosts(params));
    });
  };

  const onLikeClick = async (setDisableLike: (value: boolean) => void) => {
    if (isUserLoggedIn()) {
      await dispatch(
        likeDislikeProfilePost(postId as string, isLiked as boolean, componentType, type),
      );
      setDisableLike(false);
    } else {
      setShowModal(true);
      setDisableLike(false);
    }
  };

  const shareProfile = async () => {
    if (isUserLoggedIn()) {
      await dispatch(getPostShareUrl(`${authUrl}share/${postId}?type=post`));
    } else {
      setShowModal(true);
    }
  };

  const renderMedia = () => {
    if (isReported) {
      return null;
    }

    if (image) {
      return (
        <Box sx={{ my: theme.spacing(2) }} onClick={() => navigateToProfilePost(false)}>
          <img
            alt="post"
            className="cursor-pointer"
            src={image}
            style={{ borderRadius: '10px' }}
            width="100%"
          />
        </Box>
      );
    }

    return null;
  };

  return (
    <>
      {isPostEdit ? (
        <CreateProfilePost
          handleEditPost={() => setIsPostEdit(false)}
          imageProps={imageProps || defaultImageProps}
          postId={postId}
          textContent={textContent}
          type="edit"
        />
      ) : (
        <Grid container item justifyContent="space-between">
          <Grid container item xs={12}>
            <Stack spacing={theme.spacing(isMobile ? 2 : 3)} width="100%">
              <Stack
                alignItems="center"
                direction="row"
                height="fit-content"
                justifyContent="space-between"
                spacing={theme.spacing(1.5)}
                width="100%"
              >
                <Stack direction="row" spacing={theme.spacing(1.5)}>
                  <Avatar
                    alt="provider-logo"
                    src={userImage}
                    sx={{ width: theme.spacing(5), height: theme.spacing(5) }}
                    variant={componentType === 'profile' ? 'circular' : 'square'}
                    {...(componentType !== 'profile' && {
                      onClick: navigateToProfileWall,
                      className: 'cursor-pointer',
                    })}
                  />
                  <Stack>
                    <Typography
                      component="div"
                      variant="body3"
                      {...(componentType !== 'profile' && {
                        onClick: navigateToProfileWall,
                        sx: {
                          cursor: 'pointer',
                          textUnderlineOffset: '2px',
                          textDecoration: 'none',
                          color: theme.palette.primary.main,
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        },
                      })}
                    >
                      {userName}
                    </Typography>
                    {postedAt && (
                      <Typography noWrap color="text.tertiary" component="span" variant="subtitle2">
                        {timeDiffCalc(postedAt)}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                {componentType === 'profile' && !isReported && !editProfilePostLoading && (
                  <ProfilePostMenu
                    contentId={postId}
                    contentType="content"
                    handleDelete={handleDeletePost}
                    handleEdit={() => setIsPostEdit(true)}
                    isDeleteCommentLoading={deleteProfilePostLoading}
                    isOwner={userId === ownerUserId}
                    postContentType={componentType}
                    type="post"
                  />
                )}
              </Stack>
              <Stack flexGrow={1}>
                <Stack
                  alignItems="center"
                  direction="row"
                  height="100%"
                  spacing={theme.spacing(2)}
                  width="100%"
                >
                  <Stack flexGrow={1} spacing={theme.spacing(1)}>
                    <Typography
                      className="text-clamp-2 cursor-pointer"
                      color={isReported ? 'GrayText' : undefined}
                      component="div"
                      dangerouslySetInnerHTML={{ __html: textHtmlContent }}
                      sx={{ wordBreak: 'break-word', wordWrap: 'break-word' }}
                      variant={isMobile ? 'body2' : 'body1Bold'}
                      onClick={() => navigateToProfilePost(false)}
                    />
                    {renderMedia()}

                    {!isReported && previewLink ? (
                      <LinkPreview key={previewLink} type="post" url={previewLink} />
                    ) : null}

                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="flex-start"
                      spacing={theme.spacing(1)}
                    >
                      <Typography color="text.secondary" component="div" variant="subtitle2Bold">
                        {TYPE_LABEL_MAP[postType] || type?.toUpperCase()}
                      </Typography>
                      {!isMobile && (
                        <Box
                          height={theme.spacing(0.5)}
                          sx={{
                            background: theme.palette.common.black,
                            borderRadius: '50%',
                          }}
                          width={theme.spacing(0.5)}
                        />
                      )}
                      <Reactions
                        showCount
                        commentsCount={commentsCount}
                        isDeleted={isReported}
                        isLiked={isLiked}
                        isMobile={isMobile}
                        likesCount={likesCount}
                        onCommentClick={() => navigateToProfilePost(true)}
                        onLikeClick={onLikeClick}
                        onShareClick={shareProfile}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      )}
      {withDivider && (
        <Divider
          sx={{
            borderBottomWidth: '1px',
            borderColor: theme.palette.divider,
            margin: theme.spacing(3, 0),
          }}
        />
      )}

      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </>
  );
}

export default ProfilePostTile;
