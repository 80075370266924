enum PodcastType {
  RESET_PODCAST_STATE = 'RESET_PODCAST_STATE',
  RESET_PODCAST_EPISODE_STATE = 'RESET_PODCAST_EPISODE_STATE',
  RESET_PODCAST_SHOW_EPISODES_LIST = 'RESET_PODCAST_SHOW_EPISODES_LIST',

  GET_ALL_PODCAST_EPISODES_REQUEST = 'GET_ALL_PODCAST_EPISODES_REQUEST',
  GET_ALL_PODCAST_EPISODES_SUCCESS = 'GET_ALL_PODCAST_EPISODES_SUCCESS',
  GET_ALL_PODCAST_EPISODES_ERROR = 'GET_ALL_PODCAST_EPISODES_ERROR',

  GET_FEATURED_PODCAST_EPISODES_REQUEST = 'GET_FEATURED_PODCAST_EPISODES_REQUEST',
  GET_FEATURED_PODCAST_EPISODES_SUCCESS = 'GET_FEATURED_PODCAST_EPISODES_SUCCESS',
  GET_FEATURED_PODCAST_EPISODES_ERROR = 'GET_FEATURED_PODCAST_EPISODES_ERROR',

  GET_EPISODE_DETAILS_REQUEST = 'GET_EPISODE_DETAILS_REQUEST',
  GET_EPISODE_DETAILS_SUCCESS = 'GET_EPISODE_DETAILS_SUCCESS',
  GET_EPISODE_DETAILS_ERROR = 'GET_EPISODE_DETAILS_ERROR',

  LIKE_DISLIKE_EPISODE_REQUEST = 'LIKE_DISLIKE_EPISODE_REQUEST',
  LIKE_DISLIKE_EPISODE_SUCCESS = 'LIKE_DISLIKE_EPISODE_SUCCESS',
  LIKE_DISLIKE_EPISODE_ERROR = 'LIKE_DISLIKE_EPISODE_ERROR',

  UPDATE_EPISODE_PROVIDER_RELATIONSHIP_STATUS_REQUEST = 'UPDATE_EPISODE_PROVIDER_RELATIONSHIP_STATUS_REQUEST',
  UPDATE_EPISODE_PROVIDER_RELATIONSHIP_STATUS_SUCCESS = 'UPDATE_EPISODE_PROVIDER_RELATIONSHIP_STATUS_SUCCESS',
  UPDATE_EPISODE_PROVIDER_RELATIONSHIP_STATUS_ERROR = 'UPDATE_EPISODE_PROVIDER_RELATIONSHIP_STATUS_ERROR',

  GET_PODCAST_SHOW_EPISODES_REQUEST = 'GET_PODCAST_SHOW_EPISODES_REQUEST',
  GET_PODCAST_SHOW_EPISODES_SUCCESS = 'GET_PODCAST_SHOW_EPISODES_SUCCESS',
  GET_PODCAST_SHOW_EPISODES_ERROR = 'GET_PODCAST_SHOW_EPISODES_ERROR',

  FETCH_PODCAST_DETAILS_REQUEST = 'FETCH_PODCAST_DETAILS_REQUEST',
  FETCH_PODCAST_DETAILS_SUCCESS = 'FETCH_PODCAST_DETAILS_SUCCESS',
  FETCH_PODCAST_DETAILS_ERROR = 'FETCH_PODCAST_DETAILS_ERROR',

  RESET_PODCAST_DETAILS_STATE = 'RESET_PODCAST_DETAILS_STATE',

  UPDATE_COMMENT_COUNT = 'UPDATE_COMMENT_COUNT_PODCAST',

  FEATURED_LIKE_DISLIKE_EPISODE_SUCCESS = 'FEATURED_LIKE_DISLIKE_EPISODE_SUCCESS',
  ALL_PODCAST_LIKE_DISLIKE_EPISODE_SUCCESS = 'ALL_PODCAST_LIKE_DISLIKE_EPISODE_SUCCESS',
}

export default PodcastType;
