import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import { useEffect, useMemo } from 'react';
import { adTracker, IAdvertisement } from 'store';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';
import { isValidURL } from 'utils/utils';

interface IAdsProps {
  disableMargin?: boolean;
  page: string;
  position: string;
  uniqueKey: number;
  useCommonData: boolean;
}

function AdvertisementTile(props: IAdsProps) {
  const { disableMargin = false, page, position, uniqueKey, useCommonData } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { commonAdsData, loading, positionAdsData } = useAppSelector(s => s.ads);
  // const adPosition = useMemo(
  //   () => (isMobile ? `M${position}` : position),
  //   [isMobile, position],
  // );

  const getAdByPosition: IAdvertisement = useMemo(() => {
    if (useCommonData && commonAdsData.length > 0) {
      return (
        commonAdsData?.find((ad: IAdvertisement) => ad?.position === position) ||
        ({} as IAdvertisement)
      );
    }
    const index = uniqueKey / 5 - 1;
    return positionAdsData?.[index];
  }, [position, commonAdsData, positionAdsData, uniqueKey, useCommonData]);

  const uniqueId = useMemo(() => `${page}Ad-${position}-${uniqueKey}`, [page, position, uniqueKey]);

  // useEffect(() => {
  //   if (getAdByPosition?.impressionUrl && uniqueId) {
  //     const target = document.getElementById(uniqueId);
  //     if (target) {
  //       document.addEventListener("scroll", () => {
  //         if (
  //           window.scrollY + 200 >=
  //           target.getBoundingClientRect().bottom + 500
  //         ) {
  //           // Dispatch the Impression URL API here
  //           adTracker(`${getAdByPosition?.impressionUrl}web`);
  //         }
  //       });
  //     }
  //   }
  //   return () => {
  //     document.removeEventListener("scroll", () => {});
  //   };
  // }, [getAdByPosition?.impressionUrl, uniqueId]);

  useEffect(() => {
    if (getAdByPosition?.impressionUrl) {
      adTracker(`${getAdByPosition?.impressionUrl}web`);
    }
  }, [getAdByPosition?.impressionUrl]);

  const onAdClick = () => {
    adTracker(`${getAdByPosition?.clickUrl}web`);
    window.open(
      isValidURL(getAdByPosition?.url) ? getAdByPosition?.url : `//${getAdByPosition?.url}`,
      '_blank',
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box
      flexGrow={1}
      {...(!disableMargin && { margin: theme.spacing(isMobile ? 3 : 4, 0) })}
      className="cursor-pointer"
    >
      {getAdByPosition?.media ? (
        <Box
          alt={uniqueId}
          component="img"
          id={uniqueId}
          src={getAdByPosition?.media}
          sx={{
            display: 'block',
            height: 'auto',
            width: '100%',
            borderRadius: '10px',
          }}
          onClick={onAdClick}
        />
      ) : (
        <Box />
      )}
    </Box>
  );
}

export default AdvertisementTile;
