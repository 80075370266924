import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Loader from 'components/loader/Loader';
import NoDataAvailable from 'components/notFound/NoDataAvailable';
import TrendingTile from 'components/tile/TrendingTile';
import { useEffect } from 'react';
import { getTrendingPosts, ITrendingPosts } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

function TrendingWidget() {
  const dispatch = useAppDispatch();

  const { trendingPosts, isTrendingLoading } = useAppSelector(s => s.trending);
  const { isLoggedIn } = useAppSelector(s => s.auth);
  const isMobile = useMediaQuery(theme.breakpoints.down('midmd'));

  useEffect(() => {
    if (!isMobile) {
      dispatch(getTrendingPosts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <Box
      border={`0.25px solid ${theme.palette.text.secondary}`}
      borderRadius="4px"
      boxSizing="border-box"
      padding={theme.spacing(2.5)}
      width="100%"
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="title2">Trending</Typography>
      </Stack>
      <Divider
        sx={{
          borderBottomWidth: '0.25px',
          borderColor: theme.palette.text.secondary,
          margin: theme.spacing(3, 0, 2),
        }}
      />
      {isTrendingLoading ? (
        <Loader />
      ) : (
        <>
          {trendingPosts?.length > 0 ? (
            <>
              {trendingPosts.map((trending: ITrendingPosts, index: number) => (
                <TrendingTile
                  key={(trending?.documentId as string) + index.toString()}
                  commentsCount={trending?.commentsCount}
                  description={trending?.description}
                  id={trending?.postId}
                  isLiked={trending?.isLiked}
                  likesCount={trending?.likesCount}
                  name={trending?.postProvider}
                  providerId={trending?.postProviderId}
                  providerLogo={trending?.postProviderLogo}
                  thumbnail={trending?.thumbnail}
                  title={trending?.title}
                  type={trending?.type}
                  withDivider={!(trendingPosts?.length === index + 1)}
                />
              ))}
            </>
          ) : (
            <NoDataAvailable type="Trending" />
          )}
        </>
      )}
    </Box>
  );
}

export default TrendingWidget;
