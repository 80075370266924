import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createSelector } from '@reduxjs/toolkit';
import Loader from 'components/loader/Loader';
import NoDataAvailable from 'components/notFound/NoDataAvailable';
import GuestReactionView from 'components/reactions/GuestReactionView';
import AdvertisementTile from 'components/tile/AdvertisementTile';
import PostTile from 'components/tile/PostTile';
import Tile from 'components/tile/Tile';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { Fragment, useEffect, useState } from 'react';
import {
  AdsPages,
  fetchAdsByPosition,
  getFollowingPosts,
  IResponseData,
  ITimelinePost,
  resetAdsState,
  resetFollowingTimelineState,
} from 'store';
import { RootState, useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

import PreferencesBtn from './PreferencesBtn';

function FollowingPosts() {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const memoizedFollowingPostsStateSelector = createSelector(
    (state: RootState) => state.followingTimeline.followingPostsState,
    followingPostsState => followingPostsState,
  );

  const memoizedFollowingPostsLoading = createSelector(
    (state: RootState) => state.followingTimeline.followingPostsLoading,
    followingPostsLoading => followingPostsLoading,
  );

  const { hasMoreData, offset, posts } = useAppSelector(memoizedFollowingPostsStateSelector);
  const followingPostsLoading = useAppSelector(memoizedFollowingPostsLoading);

  const fetchFollowingPosts = async () => {
    const response: IResponseData = await dispatch(getFollowingPosts(offset));
    if (response && response.data?.length > 0)
      dispatch(fetchAdsByPosition(AdsPages.FOLLOWING, 'A', 3));
  };

  useEffect(() => {
    scrollToTop();
    return () => {
      dispatch(resetFollowingTimelineState());
      dispatch(resetAdsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HomeWrapper maxWidth="midmd">
        <Stack spacing={theme.spacing(3)} width="100%">
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography component="div" marginBottom={theme.spacing(1)} variant="title1">
                Following
              </Typography>
              <Typography component="div" variant="subtitle1">
                Recommended based on your preferences
              </Typography>
            </Stack>
            <Box>
              <PreferencesBtn />
            </Box>
          </Stack>
          <ComponentWrapper
            withInfiniteScroll
            infiniteScrollProps={{
              loadOnMount: true,
              hasMoreData,
              isLoading: followingPostsLoading,
              onBottomHit: fetchFollowingPosts,
            }}
            title=""
          >
            <>
              {posts && posts.length > 0 ? (
                <>
                  {posts.map((post: ITimelinePost, index: number) => (
                    <Fragment key={(post?.postId as string) + index.toString()}>
                      {['paid-member-post', 'business', 'town-crier-post'].includes(post?.type) ? (
                        <PostTile
                          componentType="following"
                          post={post}
                          withDivider={
                            (index + 1) % 5 === 0 ? false : !(posts?.length === index + 1)
                          }
                        />
                      ) : (
                        <Tile
                          key={post?.documentId}
                          commentsCount={post?.commentsCount}
                          componentType="following"
                          description={post?.description}
                          id={post?.postId}
                          image={post?.thumbnail as string}
                          isLiked={post?.isLiked}
                          likesCount={post?.likesCount}
                          name={post?.postProvider}
                          postedAt={post?.postedAt as string}
                          providerId={post?.postProviderId}
                          providerLogo={post?.postProviderLogo}
                          tileType={isMobile ? 'small' : 'large'}
                          title={post?.title}
                          type={post?.type}
                          withDivider={
                            (index + 1) % 5 === 0 ? false : !(posts?.length === index + 1)
                          }
                        />
                      )}
                      {(index + 1) % 5 === 0 && (
                        <AdvertisementTile
                          page={AdsPages.FOLLOWING}
                          position="A"
                          uniqueKey={index + 1}
                          useCommonData={false}
                        />
                      )}
                    </Fragment>
                  ))}
                </>
              ) : (
                !followingPostsLoading && <NoDataAvailable />
              )}
              {followingPostsLoading && <Loader />}
            </>
          </ComponentWrapper>
        </Stack>
      </HomeWrapper>
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </>
  );
}

export default FollowingPosts;
