enum NewsType {
  RESET_NEWS_STATE = 'RESET_NEWS_STATE',

  RESET_NEWS_ARTICLE_STATE = 'RESET_NEWS_ARTICLE_STATE',
  UPDATE_COMMENT_COUNT = 'UPDATE_COMMENT_COUNT_NEWS',

  GET_ALL_NEWS_REQUEST = 'GET_ALL_NEWS_REQUEST',
  GET_ALL_NEWS_SUCCESS = 'GET_ALL_NEWS_SUCCESS',
  GET_ALL_NEWS_ERROR = 'GET_ALL_NEWS_ERROR',

  GET_FEATURED_NEWS_REQUEST = 'GET_FEATURED_NEWS_REQUEST',
  GET_FEATURED_NEWS_SUCCESS = 'GET_FEATURED_NEWS_SUCCESS',
  GET_FEATURED_NEWS_ERROR = 'GET_FEATURED_NEWS_ERROR',

  GET_ALL_NEWS_PROVIDERS_REQUEST = 'GET_ALL_NEWS_PROVIDERS_REQUEST',
  GET_ALL_NEWS_PROVIDERS_SUCCESS = 'GET_ALL_NEWS_PROVIDERS_SUCCESS',
  GET_ALL_NEWS_PROVIDERS_ERROR = 'GET_ALL_NEWS_PROVIDERS_ERROR',

  GET_USER_SELECTED_PROVIDERS_REQUEST = 'GET_USER_SELECTED_PROVIDERS_REQUEST',
  GET_USER_SELECTED_PROVIDERS_SUCCESS = 'GET_USER_SELECTED_PROVIDERS_SUCCESS',
  GET_USER_SELECTED_PROVIDERS_ERROR = 'GET_USER_SELECTED_PROVIDERS_ERROR',

  UPDATE_USER_SELECTED_PROVIDERS_REQUEST = 'UPDATE_USER_SELECTED_PROVIDERS_REQUEST',
  UPDATE_USER_SELECTED_PROVIDERS_SUCCESS = 'UPDATE_USER_SELECTED_PROVIDERS_SUCCESS',
  UPDATE_USER_SELECTED_PROVIDERS_ERROR = 'UPDATE_USER_SELECTED_PROVIDERS_ERROR',

  GET_NEWS_ARTICLE_DETAILS_REQUEST = 'GET_NEWS_ARTICLE_DETAILS_REQUEST',
  GET_NEWS_ARTICLE_DETAILS_SUCCESS = 'GET_NEWS_ARTICLE_DETAILS_SUCCESS',
  GET_NEWS_ARTICLE_DETAILS_ERROR = 'GET_NEWS_ARTICLE_DETAILS_ERROR',

  LIKE_DISLIKE_POST_REQUEST = 'LIKE_DISLIKE_POST_REQUEST',
  LIKE_DISLIKE_POST_SUCCESS = 'LIKE_DISLIKE_POST_SUCCESS',
  LIKE_DISLIKE_POST_ERROR = 'LIKE_DISLIKE_POST_ERROR',

  GET_USER_PROVIDER_RELATIONSHIP_STATUS_REQUEST = 'GET_USER_PROVIDER_RELATIONSHIP_STATUS_REQUEST',
  GET_USER_PROVIDER_RELATIONSHIP_STATUS_SUCCESS = 'GET_USER_PROVIDER_RELATIONSHIP_STATUS_SUCCESS',
  GET_USER_PROVIDER_RELATIONSHIP_STATUS_ERROR = 'GET_USER_PROVIDER_RELATIONSHIP_STATUS_ERROR',

  UPDATE_USER_PROVIDER_RELATIONSHIP_STATUS_REQUEST = 'USER_PROVIDER_RELATIONSHIP_STATUS_REQUEST',
  UPDATE_USER_PROVIDER_RELATIONSHIP_STATUS_SUCCESS = 'UPDATE_USER_PROVIDER_RELATIONSHIP_STATUS_SUCCESS',
  UPDATE_USER_PROVIDER_RELATIONSHIP_STATUS_ERROR = 'UPDATE_USER_PROVIDER_RELATIONSHIP_STATUS_ERROR',

  GET_NEWS_ARTICLES_BY_PROVIDER_ID_REQUEST = 'GET_NEWS_ARTICLES_BY_PROVIDER_ID_REQUEST',
  GET_NEWS_ARTICLES_BY_PROVIDER_ID_SUCCESS = 'GET_NEWS_ARTICLES_BY_PROVIDER_ID_SUCCESS',
  GET_NEWS_ARTICLES_BY_PROVIDER_ID_ERROR = 'GET_NEWS_ARTICLES_BY_PROVIDER_ID_ERROR',

  RESET_NEWS_PROVIDER_WALL_STATE = 'RESET_NEWS_PROVIDER_WALL_STATE',

  GET_NEWS_PROVIDER_INFO_REQUEST = 'GET_NEWS_PROVIDER_INFO_REQUEST',
  GET_NEWS_PROVIDER_INFO_SUCCESS = 'GET_NEWS_PROVIDER_INFO_SUCCESS',
  GET_NEWS_PROVIDER_INFO_ERROR = 'GET_NEWS_PROVIDER_INFO_ERROR',

  FEATURED_LIKE_DISLIKE_POST_SUCCESS = 'FEATURED_LIKE_DISLIKE_POST_SUCCESS',
  ALL_NEWS_LIKE_DISLIKE_POST_SUCCESS = 'ALL_NEWS_LIKE_DISLIKE_POST_SUCCESS',
  NEWS_PROVIDER_LIKE_DISLIKE_POST_SUCCESS = 'NEWS_PROVIDER_LIKE_DISLIKE_POST_SUCCESS',
}

export default NewsType;
