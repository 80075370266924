import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import React, { useEffect, useState } from 'react';
import { ICardData, setPreviewCardIndex, showCardForm } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CardFormType } from 'store/payment/PaymentTypes';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

import ListCardMenu from '../../../components/menu/ListCardMenu';
import RemoveCardModal from './RemoveCardModal';

const { EDIT } = CardFormType;

function ListCards() {
  const dispatch = useAppDispatch();
  const { cards } = useAppSelector(s => s.payment);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleOpenRemoveModal = () => {
    setShowRemoveModal(true);
  };

  const handleSetIndex = (index: number) => {
    dispatch(setPreviewCardIndex(index));
  };

  const handleDelete = (index: number) => {
    handleSetIndex(index);
    handleOpenRemoveModal();
  };

  const handleEdit = (index: number) => {
    handleSetIndex(index);
    dispatch(showCardForm(true, EDIT));
  };

  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

  return (
    <Stack boxSizing="border-box" spacing={theme.spacing(2)} width="100%">
      {cards.length > 0
        ? cards.map((card: ICardData, index: number) => {
            return (
              <Stack key={card.documentId} spacing={theme.spacing(0.75)}>
                <Box
                  border={`0.25px solid ${theme.palette.text.secondary}`}
                  borderRadius="4px"
                  padding={theme.spacing(2, 1.5)}
                >
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={theme.spacing(1.5)}
                    width="100%"
                  >
                    <Stack alignItems="flex-start" spacing={theme.spacing(0.5)}>
                      <Stack
                        alignItems="center"
                        direction={{
                          xs: 'column',
                          midxs: 'row',
                          midsm: 'column',
                          midmd: 'row',
                        }}
                        spacing={theme.spacing(0.5)}
                      >
                        <Typography component="div" sx={{ wordBreak: 'break-word' }} variant="menu">
                          {card.aliasName}
                        </Typography>
                        {card.isDefault && (
                          <Chip
                            color="primary"
                            label="Default"
                            size="small"
                            sx={{
                              alignSelf: 'flex-start',
                              '&.MuiChip-root': {
                                height: '22px',
                                fontSize: '12px',
                              },
                            }}
                          />
                        )}
                      </Stack>
                      <Typography color="text.tertiary" variant="body2">
                        Ending {card.lastNumbers}
                      </Typography>
                      <Typography color="text.tertiary" variant="body2">
                        Expiry: {card.expirationDate.slice(0, 2)}/{card.expirationDate.slice(2)}
                      </Typography>
                    </Stack>
                    <Box alignSelf="flex-start" display="flex" marginTop={theme.spacing(1)}>
                      <ListCardMenu
                        index={index}
                        onDelete={() => handleDelete(index as number)}
                        onEdit={() => handleEdit(index as number)}
                      />
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            );
          })
        : null}
      {showRemoveModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowRemoveModal(false)}
          isMobile={isMobile}
          open={showRemoveModal}
          setOpen={setShowRemoveModal}
          title="Delete Card"
        >
          <RemoveCardModal onClose={() => setShowRemoveModal(false)} />
        </ToggleDrawerDialog>
      )}
    </Stack>
  );
}

export default ListCards;
