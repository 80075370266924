import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import CustomInputField from 'components/customFields/CustomInputField';
import PasswordEyeIcon from 'components/customFields/PasswordEyeIcon';
import { PrivacyPolicy, TermsNConditions } from 'components/terms';
import AuthWrapper from 'components/wrapper/AuthWrapper';
import { PLAN_NAMES } from 'constants/constants';
import ErrorMessages from 'constants/ErrorMessages';
import { memo, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  checkUserExists,
  IResponseData,
  ISignUpPayload,
  ISignUpState,
  sendVerificationMail,
  signUpUser,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { clearAffiliateSessionData, CookieNames, getCookieItem } from 'utils/cookies';
import { handleRestrictSpaces, scrollToTop } from 'utils/utils';
import { signupValidationSchema } from 'utils/validationSchema';

import SignUpPlans from './SignUpPlans';
import { useSignupForm } from './useSignupForm';

enum ModalType {
  TNC = 'TNC',
  PRIVACY = 'PRIVACY',
}

function ShowTermsPrivacyModal({ type }: { type: string }) {
  return (
    <>
      {type === ModalType.TNC && <TermsNConditions />}
      {type === ModalType.PRIVACY && <PrivacyPolicy />}
    </>
  );
}

function SignUp() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  const [showTNCModal, setShowTNCModal] = useState<boolean>(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState<boolean>(false);
  const [selectPlan, setSelectPlan] = useState<string>('');
  const [showSignUpVideoModal, setShowSignUpVideoModal] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const onboardingUrl = 'https://s3.freedomsquare.com/static/freedom-square-user-onboarding.mp4';
  const businessOnboardingUrl =
    'https://s3.freedomsquare.com/static/freedom-square-business-onboarding.mp4';

  // const { hash } = location;

  const { loading, isSendMailLoading } = useAppSelector(s => s.auth);

  useEffect(() => {
    scrollToTop();
    if (Object.keys(searchParams).length === 0) {
      const affiliateId = getCookieItem(CookieNames.AFFILIATE_ID);
      if (affiliateId.length > 0) {
        setSearchParams({ affiliateId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const togglePassword = (type = 'p') => {
    if (type === 'p') setShowPassword(!showPassword);
    else setShowConfirmPassword(!showConfirmPassword);
  };

  const initialState: ISignUpState = {
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    tncAccepted: false,
    // isBusinessAccount:
    //   (searchParams.get("affiliateId")?.toString() as string)?.length > 0 ||
    //   hash === "#business",
  };

  const getAffiliateSessionData = () => {
    const affiliateId = getCookieItem(CookieNames.AFFILIATE_ID);
    const isAffiliate = getCookieItem(CookieNames.IS_AFFILIATE);
    return { affiliateId, isAffiliate };
  };

  const handelSubmit = async () => {
    if (values.email && values.userName) {
      const signupErrorMsg = ErrorMessages.signup;
      const response: IResponseData = (await checkUserExists(
        `email=${encodeURIComponent(values.email ?? '')}&userName=${encodeURIComponent(
          values.userName ?? '',
        )}`,
      )) as IResponseData;
      const { affiliateId, isAffiliate } = getAffiliateSessionData();
      const { emailExists, userNameExists } = response.data;
      if (!emailExists && !userNameExists) {
        const userPayload: ISignUpPayload = {
          email: values.email,
          password: values.password,
          privacyPolicyAccepted: values.tncAccepted,
          tncAccepted: values.tncAccepted,
          userName: values.userName,
          userType: selectPlan === 'paid_member_initiated' ? 'payment_initiated' : 'free',
          businessType:
            selectPlan === 'paid_business_initiated'
              ? 'payment_initiated'
              : selectPlan === 'free_business'
              ? 'free'
              : '',
          ...(isAffiliate && { affiliateUserId: affiliateId }),
        };
        const response: IResponseData = (await dispatch(signUpUser(userPayload))) as IResponseData;
        if (!response.error) {
          clearAffiliateSessionData();
          const sendVerificationMailResponse: IResponseData = (await dispatch(
            sendVerificationMail(values.email),
          )) as IResponseData;
          if (!sendVerificationMailResponse.error) {
            navigate('/verification-mail', {
              state: {
                email: values.email,
                page: 'emailVerification',
              },
            });
          }
        }
      } else {
        if (response?.data?.userNameExists) {
          setFieldTouched('userName', true, false);
          setFieldError('userName', signupErrorMsg.userNameExists);
        }
        if (response?.data?.emailExists) {
          setFieldTouched('email', true, false);
          setFieldError('email', signupErrorMsg.emailExists);
        }
      }
    }
  };

  const formik = useSignupForm(handelSubmit, initialState, signupValidationSchema);
  const {
    dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    touched,
    values,
    setFieldError,
    setFieldTouched,
  } = formik;

  const toggleModal = (type: ModalType) => {
    if (type === ModalType.TNC) setShowTNCModal(prev => !prev);
    else setShowPrivacyModal(prev => !prev);
  };

  const handleSelectedPlan = (plan: string) => {
    setSelectPlan(plan);
  };

  return (
    <>
      {!selectPlan ? (
        <SignUpPlans handleSelectedPlan={handleSelectedPlan} />
      ) : (
        <AuthWrapper>
          <Stack alignItems="center" justifyContent="center">
            <Typography
              noWrap
              align="center"
              color="text.secondary"
              component="h2"
              variant={isMobile ? 'h5' : 'h2'}
            >
              Join Freedom Square
            </Typography>
            <Typography
              align="center"
              color="text.secondary"
              fontWeight={400}
              marginTop={theme.spacing(1.5)}
              variant={isMobile ? 'menu' : 'title2'}
            >
              Explore great features and meet your new community.
            </Typography>
            <Stack
              noValidate
              autoComplete="off"
              component="form"
              spacing={theme.spacing(4)}
              onSubmit={handleSubmit}
            >
              <Stack
                alignItems="center"
                direction="column"
                justifyContent="center"
                marginTop={theme.spacing(3.5)}
                spacing={theme.spacing(3)}
              >
                <Typography align="center" color="text.secondary" variant="menu">
                  {PLAN_NAMES[selectPlan]}
                </Typography>
                <Typography
                  align="center"
                  color="text.secondary"
                  sx={{
                    textDecoration: 'underline',
                    textUnderlineOffset: '2px',

                    cursor: 'pointer',
                    m: `${theme.spacing(1)} !important`,
                  }}
                  variant="menu"
                  onClick={() => setSelectPlan('')}
                >
                  Change Level
                </Typography>
              </Stack>
              <Stack spacing={theme.spacing(2.5)}>
                <CustomInputField
                  fullWidth
                  required
                  error={!!(touched.email && errors.email)}
                  errormessage={touched.email ? errors.email : undefined}
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <CustomInputField
                  fullWidth
                  required
                  error={!!(touched.userName && errors.userName)}
                  errormessage={touched.userName ? errors.userName : undefined}
                  id="userName"
                  inputProps={{ maxLength: 75 }}
                  label="Username"
                  name="userName"
                  type="text"
                  value={values.userName}
                  onBlur={handleBlur}
                  onChange={e => handleChange('userName')(handleRestrictSpaces(e.target.value))}
                />
                <Stack direction="row" spacing={theme.spacing(2.5)}>
                  <CustomInputField
                    fullWidth
                    required
                    endAdornment={
                      <PasswordEyeIcon
                        handleOnClick={() => togglePassword()}
                        showPassword={showPassword}
                      />
                    }
                    error={!!(touched.password && errors.password)}
                    errormessage={touched.password ? errors.password : undefined}
                    id="password"
                    label="Password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={e => handleChange('password')(handleRestrictSpaces(e.target.value))}
                  />
                  <CustomInputField
                    fullWidth
                    required
                    endAdornment={
                      <PasswordEyeIcon
                        handleOnClick={() => togglePassword('cp')}
                        showPassword={showConfirmPassword}
                      />
                    }
                    error={!!(touched.confirmPassword && errors.confirmPassword)}
                    errormessage={touched.confirmPassword ? errors.confirmPassword : undefined}
                    id="confirmPassword"
                    label="Confirm Password"
                    name="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    onChange={e =>
                      handleChange('confirmPassword')(handleRestrictSpaces(e.target.value))
                    }
                  />
                </Stack>
                <Stack direction="row">
                  <Checkbox
                    className=""
                    color="secondary"
                    id="tncAccepted"
                    name="tncAccepted"
                    sx={{ p: 0, display: 'inline-block' }}
                    value={values.tncAccepted}
                    onChange={handleChange}
                  />
                  <Typography marginLeft={theme.spacing(1.5)}>
                    I agree with the{' '}
                    <Typography
                      component="span"
                      sx={{
                        color: theme.palette.secondary.main,
                        cursor: 'pointer',
                        fontWeight: '500',
                      }}
                      variant="body1"
                      onClick={() => toggleModal(ModalType.TNC)}
                    >
                      Terms of Use
                    </Typography>
                    {` and `}
                    <Typography
                      component="span"
                      sx={{
                        color: theme.palette.secondary.main,
                        cursor: 'pointer',
                        fontWeight: '500',
                      }}
                      variant="body1"
                      onClick={() => toggleModal(ModalType.PRIVACY)}
                    >
                      Privacy Policy.
                    </Typography>
                  </Typography>
                </Stack>
              </Stack>
              <Stack alignItems="center" spacing={theme.spacing(3)} width="100%">
                <LoadingButton
                  fullWidth
                  disabled={!(isValid && dirty)}
                  loading={loading || isSendMailLoading}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign Up
                </LoadingButton>
                <Typography color="text.secondary" variant="h6" onClick={() => navigate('/login')}>
                  Already have an account?
                  <Typography
                    color={theme.palette.secondary.main}
                    component="span"
                    sx={{
                      ml: theme.spacing(1),
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontWeight: '500',
                    }}
                  >
                    Sign In
                  </Typography>
                </Typography>
                <Typography
                  component="div"
                  sx={{
                    color: theme.palette.secondary.main,
                    cursor: 'pointer',
                    fontSize: theme.spacing(2.25),
                    textDecoration: 'underline',
                    fontWeight: '500',
                  }}
                  variant="h6"
                  onClick={() => setShowSignUpVideoModal(true)}
                >
                  {selectPlan.includes('business')
                    ? 'How to Sign Up Your Business'
                    : 'How to Sign Up'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </AuthWrapper>
      )}
      {showSignUpVideoModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowSignUpVideoModal(false)}
          isMobile={isMobile}
          open={showSignUpVideoModal}
          setOpen={setShowSignUpVideoModal}
          title={
            selectPlan.includes('business') ? 'How to Sign Up Your Business' : 'How to Sign Up'
          }
        >
          <video autoPlay controls height="100%" preload="auto" width="100%">
            <source
              src={selectPlan.includes('business') ? businessOnboardingUrl : onboardingUrl}
              type="video/mp4"
            />
            <track kind="captions" />
          </video>
        </ToggleDrawerDialog>
      )}
      {showTNCModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => toggleModal(ModalType.TNC)}
          isMobile={isMobile}
          open={showTNCModal}
          setOpen={setShowTNCModal}
          title="Terms of Use"
        >
          <ShowTermsPrivacyModal type={ModalType.TNC} />
        </ToggleDrawerDialog>
      )}
      {showPrivacyModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => toggleModal(ModalType.PRIVACY)}
          isMobile={isMobile}
          open={showPrivacyModal}
          setOpen={setShowPrivacyModal}
          title="Privacy Policy"
        >
          <ShowTermsPrivacyModal type={ModalType.PRIVACY} />
        </ToggleDrawerDialog>
      )}
    </>
  );
}

export default memo(SignUp);
