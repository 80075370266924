import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import AdvertisementTile from 'components/tile/AdvertisementTile';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import TrendingWidget from 'pages/Trending/TrendingWidget';
import { useEffect } from 'react';
import Sticky from 'react-stickynode';
import { AdsPages, fetchAds, getTownCrierPost, resetAdsState, resetHomeTimelineState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

import FeaturedPosts from './timelinePosts/FeaturedPosts';
import HeadlinesPosts from './timelinePosts/HeadlinesPosts';
import TownCrierPost from './timelinePosts/TownCrierPost';

function Home() {
  const { isLoggedIn } = useAppSelector(s => s.auth);
  const { townCrierPost } = useAppSelector(s => s.homeTimeline);

  const dispatch = useAppDispatch();
  const isTablet = useMediaQuery(theme.breakpoints.down('midmd'));

  useEffect(() => {
    scrollToTop();
    dispatch(fetchAds(AdsPages.HOME));

    return () => {
      dispatch(resetHomeTimelineState());
      dispatch(resetAdsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(getTownCrierPost());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomeWrapper>
      <Grid container>
        <Grid container item midmd={8} xs={12}>
          <Stack spacing={theme.spacing(5)} width="100%">
            {townCrierPost && Object?.keys?.(townCrierPost)?.length > 0 ? <TownCrierPost /> : null}
            <FeaturedPosts />
            <AdvertisementTile useCommonData page={AdsPages.HOME} position="B" uniqueKey={1} />
            <HeadlinesPosts />
          </Stack>
        </Grid>
        <Grid
          container
          item
          display={isTablet ? 'none' : 'block'}
          md={4}
          paddingLeft={theme.spacing(2.5)}
        >
          <Sticky innerClass="pb-3" top="#header-nav">
            <TrendingWidget />
          </Sticky>
        </Grid>
      </Grid>
    </HomeWrapper>
  );
}

export default Home;
