import { Button, SvgIcon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import ModalContainer from 'components/modal/ModalContainer';
import { BackIcon } from 'helpers/images';
import { useEffect, useState } from 'react';
import { IMembers } from 'store';
import theme from 'theme';

import AdminBusinessSignUp from './AdminBusinessSignUp/AdminBusinessSignUp';
import BusinessTypeModule from './BusinessTypeModule/BusinessTypeModule';
import MembersTypeSelectionModule from './MembersTypeSelectionModule/MembersTypeSelectionModule';

type IProps = {
  showExistingMemberModal: boolean;
  setMemberModal: () => void;
};

function AddExistingMemberBusiness({ showExistingMemberModal, setMemberModal }: IProps) {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const [selectedMemberType, setSelectedMemberType] = useState('basic');
  const [activeType, setActiveType] = useState('MemberType');
  const [selectedBusinessType, setSelectedBusinessType] = useState('');
  const [selectedMember, setSelectedMember] = useState<IMembers>({} as IMembers);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setSearchQuery(value);
  };

  const handleClearSearchField = () => {
    setSearchQuery('');
  };

  useEffect(() => {
    if (searchQuery.length > 0) {
      setSearchQuery('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCardOptionChange = (_event: any, value: string) => {
    setSelectedMemberType(value);
  };

  const onSelectMember = (members: IMembers) => {
    setSelectedMember(members);
    setActiveType('BusinessType');
  };

  const handleSelectedPlan = (type: string) => {
    setActiveType('AdminBusinessSignUp');
    setSelectedBusinessType(type);
  };

  return (
    <ModalContainer
      disableScroll
      fullWidth
      showCloseIcon
      handleClose={() => setMemberModal()}
      maxWidth="md"
      open={showExistingMemberModal}
      PaperProps={{
        sx: {
          ...(!isMobile && {
            maxHeight: '600px',
            minHeight: '600px',
          }),
        },
      }}
      title="Add New Business"
    >
      <Box sx={{ backgroundColor: theme.palette.common.white }} width="100%">
        {activeType === 'BusinessType' && (
          <Box mt={2}>
            <Button onClick={() => setActiveType('MemberType')}>
              <SvgIcon inheritViewBox component={BackIcon} sx={{ height: '16px', width: '10px' }} />
              <Typography component="span" sx={{ ml: theme.spacing(1.25) }}>
                Back
              </Typography>
            </Button>
          </Box>
        )}

        <Stack boxSizing="border-box" height="100%" padding={theme.spacing(0, 3)}>
          {activeType === 'MemberType' && (
            <MembersTypeSelectionModule
              handleCardOptionChange={handleCardOptionChange}
              handleClearSearchField={handleClearSearchField}
              handleInputChange={handleInputChange}
              searchQuery={searchQuery}
              selectedMemberType={selectedMemberType}
              onSelectMember={onSelectMember}
            />
          )}
          {activeType === 'BusinessType' && (
            <BusinessTypeModule handleSelectedPlan={handleSelectedPlan} />
          )}

          {activeType === 'AdminBusinessSignUp' && (
            <AdminBusinessSignUp
              selectedBusinessType={selectedBusinessType}
              selectedMemberData={selectedMember}
              setActiveType={setActiveType}
              setMemberModal={setMemberModal}
            />
          )}
        </Stack>
      </Box>
    </ModalContainer>
  );
}

export default AddExistingMemberBusiness;
