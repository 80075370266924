enum SearchType {
  ACTIVE_SEARCH_REQUEST = 'ACTIVE_SEARCH_REQUEST',
  ACTIVE_SEARCH_SUCCESS = 'ACTIVE_SEARCH_SUCCESS',
  ACTIVE_SEARCH_ERROR = 'ACTIVE_SEARCH_ERROR',

  RESET_SEARCH_STATE = 'RESET_SEARCH_STATE',

  SEARCH_LIKE_DISLIKE_POST_SUCCESS = 'SEARCH_LIKE_DISLIKE_POST_SUCCESS',
}

export default SearchType;
