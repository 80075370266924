import Typography from '@mui/material/Typography';

interface IProps {
  type?: string;
}

export default function NoDataAvailable(props: IProps) {
  const { type = '' } = props;
  return (
    <Typography component="h6" variant="h6Bold">
      No {type || 'posts'} available
    </Typography>
  );
}
