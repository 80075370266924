import axios from 'axios';
import { authRoutes } from 'hooks/useAuthRouteCheck';

import { IResponseData, refreshToken } from './store';
import { clearSession, CookieNames, getCookieItem } from './utils/cookies';

const client = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

client.interceptors.request.use(
  (request: any) => {
    const isAuthRoute = authRoutes.includes(request.url);
    const token = getCookieItem(CookieNames.ACCESS_TOKEN);
    if (!isAuthRoute && token && request.url.indexOf('refreshToken') === -1) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  response => {
    if (response.data?.error) {
      return Promise.reject(response?.data);
    }
    return Promise.resolve(response?.data);
  },
  async error => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url.indexOf('refreshToken') === -1
    ) {
      if (getCookieItem(CookieNames.REFRESH_TOKEN)) {
        try {
          originalRequest._retry = true;
          const refreshReponse: IResponseData = await refreshToken();
          if (refreshReponse.status === 200) {
            // eslint-disable-next-line no-param-reassign
            error.response.config.headers.Authorization = `Bearer ${refreshReponse.data.accessToken}`;
            return await client(originalRequest);
          }
        } catch (rError: any) {
          clearSession(true);
          return Promise.reject(rError.response.data);
        }
      }
    }
    if (originalRequest.url.indexOf('refreshToken') !== -1) clearSession(true);
    return Promise.reject(error.response.data);
  },
);

export default client;
