enum TrendingType {
  RESET_TRENDING_STATE = 'RESET_TRENDING_STATE',

  GET_TRENDING_POSTS_REQUEST = 'GET_TRENDING_POSTS_REQUEST',
  GET_TRENDING_POSTS_SUCCESS = 'GET_TRENDING_POSTS_SUCCESS',
  GET_TRENDING_POSTS_ERROR = 'GET_TRENDING_POSTS_ERROR',

  TRENDING_LIKE_DISLIKE_POST_SUCCESS = 'TRENDING_LIKE_DISLIKE_POST_SUCCESS',
}

export default TrendingType;
