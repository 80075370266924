import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import theme from 'theme';
import styles from 'theme/styles';
import { getResourceImage } from 'utils/utils';

interface IResourceTile {
  withDivider: boolean;
  image: string;
  name: string;
  url: string;
}

function ResourceTile(props: IResourceTile) {
  const { name, url, withDivider } = props;

  return (
    <>
      <Grid container item columnSpacing={theme.spacing(2)} xs={12}>
        <Grid item xs={3}>
          <Box
            height={theme.spacing(8)}
            overflow="hidden"
            sx={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            width="100%"
          >
            <Box
              alt={name ?? 'resource-logo'}
              component="img"
              src={getResourceImage(name)}
              sx={{
                width: '100%',
                height: '100%',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
              onClick={() => window.open(url)}
            />
          </Box>
        </Grid>
        <Grid item alignItems="center" flexGrow={1} xs={9}>
          <Stack
            alignItems="center"
            direction="row"
            height="100%"
            justifyContent="space-between"
            spacing={theme.spacing(2.5)}
          >
            <Typography
              sx={styles.viewAllStyles}
              variant="subtitle1Bold"
              onClick={() => window.open(url)}
            >
              {name}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      {withDivider && (
        <Divider
          sx={{
            borderBottomWidth: '0.25px',
            borderColor: theme.palette.divider,
            margin: theme.spacing(2, 0),
          }}
        />
      )}
    </>
  );
}

export default ResourceTile;
