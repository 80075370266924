import { Box, Button, CircularProgress, Stack, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import BusinessImages from 'components/business/BusinessImages';
import AuthWrapper from 'components/wrapper/AuthWrapper';
import { UserType } from 'constants/constants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BusinessComponentType, BusinessSetupSteps, getBusinessDetailsByUserId } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { CookieNames, getCookieItem } from 'utils/cookies';
import { scrollToTop } from 'utils/utils';

import BusinessAccountSubmission from '../BusinessAccountSubmission';
import BusinessPayment from '../BusinessPayment';
import BusinessDetailsSetup from './BusinessDetails';
import BusinessInformation from './BusinessInformation';
import BusinessSocialLinks from './BusinessSocialLinks';

const { SETUP } = BusinessComponentType;

const {
  BUSINESS_IMAGES,
  BUSINESS_INFO,
  BUSINESS_DETAILS,
  BUSINESS_SOCIAL_LINKS,
  PAYMENT,
  COMPLETED,
  PAYMENT_PROCESSING,
  PAYMENT_FAILED,
  FREE_BUSINESS_LISTED,
  PAYMENT_SUCCESS,
} = BusinessSetupSteps;

const stepNumberMapping: { [key: string]: number } = {
  [BUSINESS_INFO]: 1,
  [PAYMENT]: 2,
  // [BUSINESS_DETAILS]: 5,
  // [BUSINESS_SOCIAL_LINKS]: 3,
  // [BUSINESS_IMAGES]: 4,
};

export default function BusinessSetup() {
  const dispatch = useAppDispatch();
  const currentLocation = useLocation();
  const { state } = currentLocation;

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const { businessDetails, isGetBusinessDetailsLoading } = useAppSelector(s => s.business);
  const businessStep = businessDetails?.step ?? BUSINESS_INFO;
  const [step, updateStep] = useState<BusinessSetupSteps>(businessStep);

  const [loading, setLoading] = useState(true);
  const [showPaymentInfo, setShowPaymentInfo] = useState(true);
  const businessType = getCookieItem(CookieNames.BUSINESS_TYPE);

  useEffect(() => {
    if (isGetBusinessDetailsLoading !== loading) {
      setLoading(isGetBusinessDetailsLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetBusinessDetailsLoading]);

  useEffect(() => {
    window.onbeforeunload = async () => {
      return 'Are you sure, you want to leave?';
    };
    return () => {
      window.onbeforeunload = () => {};
    };
  }, []);

  useEffect(() => {
    scrollToTop();
    if (Object.keys(businessDetails)?.length === 0) {
      setLoading(true);
      dispatch(getBusinessDetailsByUserId());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateStep(businessDetails?.step ?? BUSINESS_INFO);
  }, [businessDetails?.step]);

  if (state?.isPaymentReq && showPaymentInfo) {
    return (
      <AuthWrapper>
        <Stack alignItems="center" spacing={theme.spacing(2.5)}>
          <Typography
            align="center"
            component="span"
            marginBottom={theme.spacing(isMobile ? 3 : 5)}
            variant="title2"
          >
            Welcome to Freedom Square
          </Typography>
          <Typography
            align="center"
            component="span"
            marginBottom={theme.spacing(isMobile ? 3 : 5)}
            variant="title2"
          >
            Please complete payment to finish your Freedom Chamber Account
          </Typography>

          <Box sx={{ mt: `${theme.spacing(4)} !important` }} width="100%">
            <Button
              disableRipple
              disableTouchRipple
              fullWidth
              size="large"
              variant="contained"
              onClick={() => setShowPaymentInfo(false)}
            >
              Make Payment
            </Button>
          </Box>
        </Stack>
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {stepNumberMapping[step] && (
            <Typography color="secondary.main" marginBottom={theme.spacing(0.5)} variant="h6">
              {((currentLocation?.state !== null &&
                currentLocation?.state === 'paid_business_initiated') ||
                businessType === UserType.PAYMENT_INITIATED) &&
                `Step ${stepNumberMapping[step]} of 2`}
            </Typography>
          )}
          {step === BUSINESS_INFO && (
            <BusinessInformation
              handleNext={() =>
                (currentLocation?.state !== null &&
                  currentLocation?.state === 'paid_business_initiated') ||
                businessType === UserType.PAYMENT_INITIATED
                  ? updateStep(PAYMENT)
                  : updateStep(PAYMENT_SUCCESS)
              }
            />
          )}
          {step === BUSINESS_DETAILS && (
            <BusinessDetailsSetup
              handleNext={() => updateStep(BUSINESS_SOCIAL_LINKS)}
              handlePrev={() => updateStep(BUSINESS_INFO)}
            />
          )}
          {step === BUSINESS_SOCIAL_LINKS && (
            <BusinessSocialLinks
              handleNext={() => updateStep(BUSINESS_IMAGES)}
              handlePrev={() => updateStep(BUSINESS_DETAILS)}
            />
          )}
          {step === BUSINESS_IMAGES && (
            <BusinessImages
              componentType={SETUP}
              handleNext={() => updateStep(PAYMENT)}
              handlePrev={() => updateStep(BUSINESS_SOCIAL_LINKS)}
            />
          )}
          {[PAYMENT, PAYMENT_FAILED].includes(step) && (
            <BusinessPayment
              handleNext={() => updateStep(PAYMENT_SUCCESS)}
              handlePrev={() => updateStep(BUSINESS_INFO)}
              type="business"
            />
          )}
          {[COMPLETED, PAYMENT_PROCESSING, PAYMENT_SUCCESS, FREE_BUSINESS_LISTED].includes(
            step,
          ) && <BusinessAccountSubmission />}
        </>
      )}
    </AuthWrapper>
  );
}
