import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import LinkPreview from 'components/linkPreview';
import Reactions from 'components/reactions';
import PostContent from 'components/singlePost/PostContent';
import PostFooter from 'components/singlePost/PostFooter';
import PostHeader from 'components/singlePost/PostHeader';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Relationship } from 'store';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';
import { CookieNames, getCookieItem } from 'utils/cookies';
import { getPreviewLink } from 'utils/utils';

interface IProfileSinglePostProps {
  handleRelationChange: () => void;
  shareProfilePost: () => void;
  onCommentClick: () => void;
  onLikeClick: (setDisableLike: React.Dispatch<React.SetStateAction<boolean>>) => void;
}

export default function ProfileSinglePost({
  handleRelationChange,
  shareProfilePost,
  onCommentClick,
  onLikeClick,
}: IProfileSinglePostProps) {
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const ownerUserId = getCookieItem(CookieNames.USER_ID);

  const { profilePostDetails, profileWallFollowingLoading } = useAppSelector(s => s.account);

  const isImage = !(
    profilePostDetails?.image?.croppedImageUrl || profilePostDetails?.image?.imageUrl
  );
  const previewLink = useMemo(() => {
    const uniqueLink = getPreviewLink(profilePostDetails?.textContent);
    return isImage ? uniqueLink : '';
  }, [isImage, profilePostDetails?.textContent]);

  const pathName = useMemo(() => location.pathname, [location.pathname]);

  const isTownCrierPost = pathName.includes('town-crier');

  return (
    <>
      <ComponentWrapper title="">
        <PostHeader
          contentType={isTownCrierPost ? 'Town Crier' : 'Profile'}
          date={profilePostDetails?.createdAt}
          providerLogo={profilePostDetails?.user?.croppedImageReadUrl}
          providerName={profilePostDetails?.user?.fullName || profilePostDetails?.user?.userName}
          providerUrl={`/profile-wall/${profilePostDetails?.userId}`}
        />
        <Box sx={{ my: theme.spacing(2) }}>
          <PostContent content={profilePostDetails?.textContent} post="profile" />
        </Box>
        {profilePostDetails?.image?.croppedImageUrl || profilePostDetails?.image?.imageUrl ? (
          <Box sx={{ my: theme.spacing(2) }}>
            <img
              alt="post_image"
              src={
                profilePostDetails?.image?.croppedImageUrl || profilePostDetails?.image?.imageUrl
              }
              style={{ borderRadius: '10px' }}
              width="100%"
            />
          </Box>
        ) : null}

        {previewLink ? <LinkPreview key={previewLink} type="post" url={previewLink} /> : null}
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Reactions
            showCount
            commentsCount={profilePostDetails?.commentsCount}
            isLiked={profilePostDetails?.isLiked}
            isMobile={false}
            likesCount={profilePostDetails?.likesCount}
            showReactionText={!isMobile}
            onCommentClick={onCommentClick}
            onLikeClick={onLikeClick}
            onShareClick={shareProfilePost}
          />
          {profilePostDetails.userId !== ownerUserId && (
            <PostFooter
              followStatus={
                profilePostDetails?.isFollowing ? Relationship.UNFOLLOW : Relationship.FOLLOW
              }
              isLoading={profileWallFollowingLoading}
              providerName=""
              providerType="Profile"
              showText={false}
              onFollowBtnClick={handleRelationChange}
            />
          )}
        </Stack>
      </ComponentWrapper>
    </>
  );
}
