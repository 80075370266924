import { FormikHelpers, useFormik } from 'formik';
import { ILoginState } from 'store';
import { loginValidationSchema } from 'utils/validationSchema';

const useLoginForm = (
  onSubmit: (
    values: ILoginState,
    formikHelpers: FormikHelpers<ILoginState>,
  ) => void | Promise<unknown>,
  initialValues: ILoginState,
) => {
  return useFormik<ILoginState>({
    initialValues,
    validationSchema: loginValidationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export default useLoginForm;
