import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Loader from 'components/loader/Loader';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { getOpenGraphData } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { getValidUrlWithProtocol, youtubeParser } from 'utils/utils';

interface ILinkPreviewPros {
  url: string;
  type: string;
  videoDimension?: string;
}

function LinkPreview({ url, type, videoDimension }: ILinkPreviewPros) {
  const dispatch = useAppDispatch();

  const isBoxCast = url?.includes('boxcast');
  const isRumbleYoutubeUrl = url?.includes('rumble') || isBoxCast || youtubeParser(url);

  const { openGraphData } = useAppSelector(s => s.resource);
  const [previewData, setPreviewData] = useState<any>({});

  const [boxWidth, setBoxWidth] = useState<number | null>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const formattedURL = getValidUrlWithProtocol(url);
    if (!Object.prototype.hasOwnProperty.call(openGraphData, formattedURL)) {
      dispatch(getOpenGraphData(formattedURL)).then(data => {
        setPreviewData(data);
      });
    } else {
      setPreviewData(openGraphData[formattedURL]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    function updateWidth() {
      if (boxRef.current) {
        const width = boxRef.current.clientWidth;
        setBoxWidth(width);
      }
    }

    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const calcHeight = useMemo(() => {
    if (boxWidth && isBoxCast) {
      return (boxWidth / 16) * 9;
    }
    return 360;
  }, [boxWidth, isBoxCast]);

  if (isRumbleYoutubeUrl && type !== 'comment') {
    return (
      <Box ref={boxRef} sx={{ cursor: 'pointer', width: '100%', overflow: 'hidden', marginTop: 2 }}>
        {previewData?.url ? (
          <iframe
            className="rumble"
            height={videoDimension || calcHeight}
            src={previewData?.url}
            style={{ borderWidth: 0 }}
            title="town-crier"
            width="100%"
          />
        ) : (
          <Loader />
        )}
      </Box>
    );
  }

  return (
    <>
      {(previewData?.title || previewData?.description) && (
        <>
          {type === 'post' ? (
            <Card
              elevation={0}
              sx={{
                border: '1px solid rgba(29, 29, 29, 0.2)',
                borderRadius: theme.spacing(2),
              }}
            >
              <CardActionArea onClick={() => window.open(previewData.url, '_blank')}>
                {previewData.image && (
                  <CardMedia
                    alt={previewData.title}
                    component="img"
                    image={previewData.image}
                    sx={{ objectFit: 'contain' }}
                  />
                )}
                <CardContent sx={{ borderTop: '1px solid rgba(29, 29, 29, 0.2)' }}>
                  <Typography gutterBottom component="div" variant="h5">
                    {previewData.title}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{ wordBreak: 'break-word', wordWrap: 'break-word' }}
                    variant="body2"
                  >
                    {previewData.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ) : (
            <Card
              elevation={0}
              sx={{
                border: '1px solid rgba(29, 29, 29, 0.2)',
                borderRadius: theme.spacing(2),
                display: 'flex',
              }}
            >
              <CardActionArea
                sx={{ display: 'flex', flexDirection: 'row' }}
                onClick={() => window.open(previewData.url, '_blank')}
              >
                {previewData.image && (
                  <CardMedia
                    alt={previewData.title}
                    component="img"
                    image={previewData.image}
                    sx={{ objectFit: 'contain', width: 180 }}
                  />
                )}
                <CardContent sx={{ py: 0 }}>
                  <Typography
                    gutterBottom
                    className="text-ellipsis text-clamp-2"
                    component="div"
                    variant="h5"
                  >
                    {previewData.title}
                  </Typography>
                  <Typography
                    className="text-ellipsis text-clamp-2"
                    color="text.secondary"
                    sx={{ wordBreak: 'break-word', wordWrap: 'break-word' }}
                    variant="body2"
                  >
                    {previewData.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
        </>
      )}
    </>
  );
}

export default LinkPreview;
