import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import NoDataAvailable from 'components/notFound/NoDataAvailable';
import AdvertisementTile from 'components/tile/AdvertisementTile';
import Tile from 'components/tile/Tile';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import React, { Fragment, useEffect } from 'react';
import {
  AdsPages,
  fetchAds,
  fetchAdsByPosition,
  getAllPodcastEpisodes,
  getFeaturedPodcastEpisodes,
  IPodcastEpisode,
  IPodcastImage,
  resetAdsState,
  resetPodcastState,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

function Podcasts() {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isGetPodcastsLoading, isGetFeaturedPodcastsLoading, featuredPodcasts, allPodcasts } =
    useAppSelector(s => s.podcast);
  const { podcasts, currentCount } = allPodcasts;

  const hasMoreData = currentCount === 10;
  const getAllPodcasts = () => {
    dispatch(getAllPodcastEpisodes(podcasts.length, 10));
    dispatch(fetchAdsByPosition(AdsPages.PODCASTS, 'C'));
  };

  useEffect(() => {
    scrollToTop();
    dispatch(getFeaturedPodcastEpisodes());
    dispatch(fetchAds(AdsPages.PODCASTS));
    return () => {
      dispatch(resetPodcastState());
      dispatch(resetAdsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomeWrapper maxWidth="midmd">
      <Stack spacing={theme.spacing(5)} width="100%">
        <ComponentWrapper title="Featured Podcasts">
          <>
            {isGetFeaturedPodcastsLoading ? (
              <Loader />
            ) : featuredPodcasts && featuredPodcasts.length > 0 ? (
              <>
                {featuredPodcasts.map((podcast: IPodcastEpisode, index: number) => {
                  const image: string = podcast?.thumbnail;
                  const podcastProviderLogo: IPodcastImage[] = podcast?.externalShowImages
                    ? JSON.parse(podcast.externalShowImages)
                    : [];
                  return (
                    <Fragment key={(podcast?.episodeID as string) + index.toString()}>
                      {/* {(index + 1) % 5 === 0 && ( */}
                      {/* {index === 1 && (
                          <AdvertisementTile
                            useCommonData
                            page={AdsPages.PODCASTS}
                            position="A"
                            uniqueKey={index + 1}
                          />
                        )} */}
                      <Tile
                        key={(podcast?.episodeID as string) + index.toString()}
                        isFeatured
                        commentsCount={podcast?.commentCount}
                        componentType="podcasts"
                        description={podcast?.episodeDescription}
                        id={podcast?.episodeID}
                        image={image}
                        isLiked={podcast?.isLiked}
                        likesCount={podcast?.likeCount}
                        name={podcast?.podcastShowName}
                        postedAt={podcast?.externalCreatedAt}
                        providerId={podcast.podcastShowID}
                        providerLogo={podcastProviderLogo?.[0]?.url || ''}
                        providerUrl={podcast?.podcastShowUrl}
                        tileType={isMobile ? 'small' : 'large'}
                        title={podcast?.episodeName}
                        type="podcasts"
                        withDivider={!(featuredPodcasts?.length === index + 1)}
                      />
                    </Fragment>
                  );
                })}
              </>
            ) : (
              <NoDataAvailable type="featured podcasts" />
            )}
          </>
        </ComponentWrapper>
        <AdvertisementTile useCommonData page={AdsPages.PODCASTS} position="B" uniqueKey={1} />
        <ComponentWrapper
          withInfiniteScroll
          infiniteScrollProps={{
            loadOnMount: true,
            hasMoreData,
            isLoading: isGetPodcastsLoading,
            onBottomHit: getAllPodcasts,
          }}
          title="All Podcasts"
        >
          <>
            {podcasts && podcasts.length > 0 ? (
              <>
                {podcasts.map((podcast: IPodcastEpisode, index: number) => {
                  const image: string = podcast?.thumbnail;
                  const podcastProviderLogo: IPodcastImage[] = podcast?.externalShowImages
                    ? JSON.parse(podcast.externalShowImages)
                    : [];
                  return (
                    <Fragment key={(podcast?.episodeID as string) + index.toString()}>
                      <Tile
                        key={(podcast?.episodeID as string) + index.toString()}
                        commentsCount={podcast?.commentCount}
                        componentType="podcasts"
                        description={podcast?.episodeDescription}
                        id={podcast?.episodeID}
                        image={image}
                        isFeatured={false}
                        isLiked={podcast?.isLiked}
                        likesCount={podcast?.likeCount}
                        name={podcast?.podcastShowName}
                        postedAt={podcast?.externalCreatedAt}
                        providerId={podcast.podcastShowID}
                        providerLogo={podcastProviderLogo?.[0]?.url || ''}
                        providerUrl={podcast?.podcastShowUrl}
                        tileType={isMobile ? 'small' : 'large'}
                        title={podcast?.episodeName}
                        type="podcasts"
                        withDivider={
                          (index + 1) % 5 === 0 ? false : !(podcasts.length === index + 1)
                        }
                      />
                      {(index + 1) % 5 === 0 && (
                        <AdvertisementTile
                          page={AdsPages.PODCASTS}
                          position="C"
                          uniqueKey={index + 1}
                          useCommonData={false}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </>
            ) : (
              !isGetPodcastsLoading && <NoDataAvailable type="podcasts" />
            )}
            {isGetPodcastsLoading && <Loader />}
          </>
        </ComponentWrapper>
      </Stack>
    </HomeWrapper>
  );
}

export default Podcasts;
