import client from 'Api';
import { timelineUrl } from 'helpers/urls';
import { Dispatch } from 'redux';
import {
  AlertActions,
  IAlertPayload,
  IResponseData,
  ISearchDataPayload,
  ITimelinePost,
  SearchActions,
} from 'store';
import { AlertType } from 'store/alerts/AlertTypes';

import SearchType from './SearchTypes';

export const resetSearchState = () => {
  return async (dispatch: Dispatch<SearchActions>) => {
    dispatch({ type: SearchType.RESET_SEARCH_STATE });
  };
};

export const activeSearch = (
  query: string,
  nextLoad = false,
  pathName = '',
  page = 1,
  limit = 10,
) => {
  return async (dispatch: Dispatch<SearchActions | AlertActions>) => {
    try {
      dispatch({ type: SearchType.ACTIVE_SEARCH_REQUEST, payload: nextLoad });
      const pageType =
        pathName === '/news'
          ? '&pageType=news'
          : pathName === '/podcasts'
          ? '&pageType=podcasts'
          : pathName === '/businesses'
          ? '&pageType=business'
          : '';
      const response: IResponseData = await client.get(
        `${timelineUrl}timeline/search?q=${query}&page=${page}&limit=${limit}${pageType}`,
      );
      const payload: ISearchDataPayload = {
        data: (response?.data || []) as ITimelinePost[],
        query,
        page,
        nextLoad,
        hasMoreData: response?.data?.length === 10,
      };
      dispatch({
        type: SearchType.ACTIVE_SEARCH_SUCCESS,
        payload,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: SearchType.ACTIVE_SEARCH_ERROR });
      return error;
    }
  };
};
