import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'components/infiniteScroll/InfiniteScroll';
import React from 'react';
import theme from 'theme';

type IInfiniteScrollProps = {
  onBottomHit: (initialLoad: boolean, type?: string) => void;
  isLoading: boolean;
  hasMoreData: boolean;
  loadOnMount: boolean;
  valueToBeAddedToInnerHeight?: number;
};

type ConditionalProps =
  | { withInfiniteScroll?: false; infiniteScrollProps?: never }
  | { withInfiniteScroll: true; infiniteScrollProps: IInfiniteScrollProps };

type CommonProps = {
  borderColor?: 'light' | 'dark' | 'red';
  title: string;
  isTitleInsideWrapper?: boolean;
  withPadding?: boolean;
  withDivider?: boolean;
  withBorder?: boolean;
  children: React.ReactElement | React.ReactNode | JSX.Element;
};

type Props = CommonProps & ConditionalProps;

function ComponentWrapper({
  borderColor = 'dark',
  title,
  isTitleInsideWrapper = false,
  withDivider = true,
  withPadding = true,
  withBorder = true,
  children,
  withInfiniteScroll,
  infiniteScrollProps,
}: Props) {
  return (
    <Box flexGrow={1} width="100%">
      {title.length > 0 && !isTitleInsideWrapper && (
        <Typography component="div" marginBottom={theme.spacing(3)} variant="title1">
          {title}
        </Typography>
      )}
      <Box
        boxSizing="border-box"
        height="100%"
        padding={{ xs: theme.spacing(2), sm: theme.spacing(3) }}
        width="100%"
        {...(withBorder && {
          border: `${borderColor === 'red' ? '2.5px' : '0.25px'} solid ${
            borderColor === 'light'
              ? theme.palette.divider
              : borderColor === 'red'
              ? theme.palette.error.main
              : theme.palette.text.secondary
          }`,
          borderRadius: '4px',
        })}
        {...(!withPadding && {
          padding: 0,
        })}
      >
        {isTitleInsideWrapper && (
          <Box>
            <Typography variant="title2">{title}</Typography>
            {withDivider && (
              <Divider
                sx={{
                  borderBottomWidth: '0.25px',
                  borderColor: theme.palette.text.secondary,
                  margin: { xs: theme.spacing(2, 0), sm: theme.spacing(3, 0) },
                }}
              />
            )}
          </Box>
        )}
        <>
          {withInfiniteScroll ? (
            <InfiniteScroll {...infiniteScrollProps}>
              <Box height="100%">{children}</Box>
            </InfiniteScroll>
          ) : (
            <Box>{children}</Box>
          )}
        </>
      </Box>
    </Box>
  );
}

export default ComponentWrapper;
