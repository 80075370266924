import { Dispatch } from 'redux';
import { IResponseData } from 'store/common/CommonInterface';

import client from '../../Api';
import { timelineUrl } from '../../helpers/urls';
import { HomeTimelineActions, IHeadlinesState, ITimelinePost } from './HomeTimelineInterface';
import HomeTimelineType from './HomeTimelineTypes';

export const resetHomeTimelineState = () => {
  return async (dispatch: Dispatch<HomeTimelineActions>) => {
    dispatch({ type: HomeTimelineType.RESET_HOME_TIMELINE_STATE });
  };
};

export const getTimelinePosts = (offset: number, limit: number, isFeatured = true) => {
  return async (dispatch: Dispatch<HomeTimelineActions>) => {
    try {
      if (isFeatured) {
        dispatch({
          type: HomeTimelineType.GET_TIMELINE_FEATURED_POSTS_REQUEST,
        });
      } else {
        dispatch({
          type: HomeTimelineType.GET_TIMELINE_HEADLINES_POSTS_REQUEST,
        });
      }
      const response: IResponseData = await client.get(
        `${timelineUrl}timeline/posts?isFeatured=${isFeatured}&limit=${limit}&offset=${offset}`,
      );
      if (isFeatured) {
        dispatch({
          type: HomeTimelineType.GET_TIMELINE_FEATURED_POSTS_SUCCESS,
          payload: (response?.data || []) as ITimelinePost[],
        });
      } else {
        dispatch({
          type: HomeTimelineType.GET_TIMELINE_HEADLINES_POSTS_SUCCESS,
          payload: {
            posts: response?.data,
            currentCount: response?.data?.length,
          } as IHeadlinesState,
        });
      }
      return response;
    } catch (error: unknown) {
      if (isFeatured) {
        dispatch({ type: HomeTimelineType.GET_TIMELINE_FEATURED_POSTS_ERROR });
      } else {
        dispatch({ type: HomeTimelineType.GET_TIMELINE_HEADLINES_POSTS_ERROR });
      }

      return error;
    }
  };
};

export const getTownCrierPost = () => {
  return async (dispatch: Dispatch<HomeTimelineActions>) => {
    try {
      dispatch({
        type: HomeTimelineType.GET_TOWN_CRIER_POST_REQUEST,
      });
      const response: IResponseData = await client.get(`${timelineUrl}timeline/town-crier-post`);

      dispatch({
        type: HomeTimelineType.GET_TOWN_CRIER_POST_SUCCESS,
        payload: response?.data[0] || {},
      });

      return response;
    } catch (error: unknown) {
      dispatch({ type: HomeTimelineType.GET_TOWN_CRIER_POST_ERROR });

      return error;
    }
  };
};
