export enum ContentType {
  BUSINESS = 'business',
  PROFILE = 'profile',
  ARTICLE = 'article',
  PODCAST = 'podcast',
  BUSINESS_WALL = 'business-wall',
}

export enum UpdateCommentCountType {
  INC = 'inc',
  DEC = 'dec',
}

export interface ICommentUser {
  businessType?: string;
  userType?: string;
  croppedImagePath: string;
  croppedImageReadUrl?: string;
  fullName: string;
  influencerStatus: boolean;
  userId: string;
  userName: string;
}

export interface BusinessImage {
  imageId: string;
  imageType: string;
  originalImageReadUrl: string;
  croppedImageReadUrl: string;
  croppedImageDetails: string;
}

interface ICommentBusinessParams {
  businessDocumentId: string;
  businessName: string;
  images: BusinessImage[];
}

export interface ICommonComment {
  comment: string;
  documentID: string;
  isLiked: boolean;
  isPrivate: boolean;
  isReported: boolean;
  likesCount: number;
  newsID?: string;
  parentCommentID?: string;
  parentCommentId?: string;
  repliesCount: number;
  user: ICommentUser;
  createdAt: string;
  updatedAt: string;
  contentId?: string;
  contentID?: string;
  episodeID?: string;
  podcastID?: string;
  userID?: string;
  business?: ICommentBusinessParams;
}

export interface ICommentsState {
  loading: boolean;
  addCommentLoading: boolean;
  deleteCommentLoading: boolean;
  reportContentLoading: boolean;
  editCommentLoading: boolean;
  commentsCount: number;
  comments: ICommonComment[];
  selectedComment: ICommonComment;
  commentReplies: ICommonComment[];
}

export interface IGetCommentsParams {
  contentId?: string;
  offset: number;
  limit?: number;
  type: ContentType;
}

export interface IGetCommentsPayload {
  data: ICommonComment[];
  initialLoad: boolean;
}

export interface IAddCommentParams {
  type: ContentType;
  contentId?: string;
  parentCommentID?: string;
  comment?: string;
  commentText?: string;
  isPrivate?: boolean;
}

export interface IAddCommentPayload {
  data: ICommonComment;
  commentsCount: number;
  parentCommentID?: string;
}

export interface IEditCommentParams {
  commentId: string;
  comment?: string;
  commentText?: string;
  type: ContentType;
}

export interface IDeleteCommentParams {
  commentId: string;
  parentCommentID?: string;
  type: ContentType;
}

export interface IReportCommentParams {
  postType: string;
  postId?: string;
  type: ContentType;
  category: 'comment' | 'content';
}

export interface ICommentLikeDisLikeParams {
  parentCommentId?: string;
  commentId: string;
  isLiked: boolean;
  type: ContentType;
}

export interface IGetUrlParams {
  commentId?: string;
  contentId?: string;
  type: ContentType;
}

export interface IUserReportState {
  other: string;
  reportOptions: string;
}
