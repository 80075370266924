import cloneDeep from 'clone-deep';
import { Reducer } from 'redux';

import { ICardData, IPaymentState, PaymentActions } from './PaymentInterface';
import PaymentType from './PaymentTypes';

const initialState: IPaymentState = {
  loading: false,
  isDeleteCardLoading: false,
  showForm: false,
  formType: undefined,
  cards: [] as ICardData[],
  previewCardIndex: 0,
  defaultCardIndex: 0,
};

const PaymentReducer: Reducer<IPaymentState, PaymentActions> = (
  state = initialState,
  action = {} as PaymentActions,
) => {
  switch (action.type) {
    case PaymentType.GET_CARDS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PaymentType.GET_CARDS_SUCCESS: {
      const index = action.payload.findIndex((card: ICardData) => card.isDefault);
      return {
        ...state,
        cards: [...action.payload],
        defaultCardIndex: index !== -1 ? index : 0,
        loading: false,
      };
    }
    case PaymentType.GET_CARDS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case PaymentType.SHOW_CARD_FORM: {
      const { showForm, formType } = action.payload;
      return {
        ...state,
        showForm,
        formType,
      };
    }
    case PaymentType.ADD_CARD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PaymentType.ADD_CARD_SUCCESS: {
      const cards = cloneDeep(state.cards);
      if (action.payload.isDefault) {
        if (cards.length > 0) {
          cards[state.defaultCardIndex].isDefault = false;
          cards.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
        }
        cards.unshift(action.payload);
      } else {
        cards.splice(1, 0, action.payload);
      }

      return {
        ...state,
        loading: false,
        cards,
      };
    }
    case PaymentType.ADD_CARD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case PaymentType.SET_PREVIEW_CARD_INDEX: {
      return {
        ...state,
        previewCardIndex: action.payload,
      };
    }
    case PaymentType.EDIT_CARD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PaymentType.EDIT_CARD_SUCCESS: {
      const cards = cloneDeep(state.cards);
      const selectedIndex = cards.findIndex(
        (card: ICardData) => card.documentId === action.payload.documentId,
      );

      if (action.payload.isDefault) {
        cards[state.defaultCardIndex].isDefault = false;
        if (selectedIndex !== -1) {
          cards.splice(selectedIndex, 1);
          cards.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
          cards.unshift(action.payload);
        }
      } else if (selectedIndex !== -1) cards[selectedIndex] = action.payload;

      return {
        ...state,
        loading: false,
        cards,
      };
    }
    case PaymentType.EDIT_CARD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case PaymentType.DELETE_CARD_REQUEST: {
      return {
        ...state,
        isDeleteCardLoading: true,
      };
    }
    case PaymentType.DELETE_CARD_SUCCESS: {
      const cards = cloneDeep(state.cards);
      const selectedIndex = cards.findIndex(
        (card: ICardData) => card.documentId === action.payload,
      );
      cards.splice(selectedIndex, 1);

      return {
        ...state,
        isDeleteCardLoading: false,
        cards,
      };
    }
    case PaymentType.DELETE_CARD_ERROR: {
      return {
        ...state,
        isDeleteCardLoading: false,
      };
    }
    case PaymentType.RESET_PAYMENT_STATE: {
      return {
        ...state,
        showForm: false,
        formType: undefined,
        cards: [] as ICardData[],
        previewCardIndex: 0,
      };
    }
    default:
      return state;
  }
};

export default PaymentReducer;
