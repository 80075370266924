import cloneDeep from 'clone-deep';
import { Reducer } from 'redux';
import { ISearchDataState, ISearchState, ITimelinePost, SearchActions } from 'store';

import SearchType from './SearchTypes';

const initialSearchDataState: ISearchDataState = {
  query: '',
  data: [] as ITimelinePost[],
  page: 1,
  hasMoreData: false,
};

const initialState: ISearchState = {
  loading: false,
  loadNextPage: false,
  searchData: initialSearchDataState,
};

const SearchReducer: Reducer<ISearchState, SearchActions> = (
  state = initialState,
  action = {} as SearchActions,
) => {
  switch (action.type) {
    case SearchType.RESET_SEARCH_STATE: {
      return {
        ...state,
        searchData: {
          query: '',
          data: [] as ITimelinePost[],
          page: 1,
          hasMoreData: false,
        },
      };
    }
    case SearchType.ACTIVE_SEARCH_REQUEST: {
      return {
        ...state,
        loading: action.payload,
        loadNextPage: !action.payload,
      };
    }
    case SearchType.ACTIVE_SEARCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadNextPage: false,
        searchData: {
          data:
            state.searchData.query !== action.payload.query
              ? action.payload.data
              : [...state.searchData.data, ...action.payload.data],
          query: action.payload.query,
          page: state.searchData.query !== action.payload.query ? 1 : action.payload.page,
          hasMoreData: action.payload.hasMoreData,
        },
      };
    }
    case SearchType.ACTIVE_SEARCH_ERROR: {
      return {
        ...state,
        loading: false,
        loadNextPage: false,
      };
    }
    case SearchType.SEARCH_LIKE_DISLIKE_POST_SUCCESS: {
      const selectedIndex = state.searchData.data.findIndex(
        (e: ITimelinePost) => e.postId === action.payload,
      );
      const data = cloneDeep(state.searchData.data);
      if (selectedIndex !== -1) {
        const likeStatus = !data[selectedIndex].isLiked;
        data[selectedIndex].isLiked = likeStatus;
        data[selectedIndex].likesCount = likeStatus
          ? data[selectedIndex].likesCount + 1
          : data[selectedIndex].likesCount - 1;
      }
      return {
        ...state,
        searchData: {
          ...state.searchData,
          data,
        },
      };
    }
    default:
      return state;
  }
};

export default SearchReducer;
