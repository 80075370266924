import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Loader from 'components/loader/Loader';
import NoDataAvailable from 'components/notFound/NoDataAvailable';
import BusinessDirectoryTile from 'components/tile/BusinessDirectoryTile';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import { useEffect, useState } from 'react';
import { getFeaturedBusinesses } from 'store/business/BusinessAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

function FeaturedBusinesses() {
  const dispatch = useAppDispatch();
  const { featuredBusinessLoading, featuredBusinesses } = useAppSelector(s => s.business);

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    dispatch(getFeaturedBusinesses(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ComponentWrapper isTitleInsideWrapper title="Featured Businesses">
      <>
        {featuredBusinessLoading ? (
          <Loader />
        ) : featuredBusinesses.businesses && featuredBusinesses.businesses.length > 0 ? (
          <Grid container spacing={4}>
            {featuredBusinesses.businesses
              .slice(0, expanded ? 12 : 4)
              .map((business, index: number) => (
                <Grid
                  key={business.documentId + index.toString()}
                  item
                  md={3}
                  midsm={4}
                  sm={6}
                  xs={12}
                >
                  <BusinessDirectoryTile
                    key={business?.documentId}
                    businessId={business?.documentId}
                    businessType={business?.businessType}
                    description={business?.description}
                    image={business?.imageLogo.croppedImageReadUrl as string}
                    isFollowing={business?.isFollowing}
                    isOwner={business?.role === 'owner'}
                    name={business?.name}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <NoDataAvailable type="featured businesses" />
        )}
        {featuredBusinesses.businesses.length > 4 && (
          <Typography
            component="div"
            sx={{
              width: '100%',
              textAlign: 'center',
              marginTop: theme.spacing(3.5),
              cursor: 'pointer',
            }}
            variant="title2"
            onClick={() => handleExpandClick()}
          >
            {expanded ? 'Show Less' : 'See More'}
          </Typography>
        )}
      </>
    </ComponentWrapper>
  );
}

export default FeaturedBusinesses;
