import { FormikHelpers, useFormik } from 'formik';

export type ICommentFormValues = {
  comment: string;
};

export const useCommentForm = (
  onSubmit: (
    values: ICommentFormValues,
    formikHelpers: FormikHelpers<ICommentFormValues>,
  ) => void | Promise<unknown>,
  initialValues: ICommentFormValues,
  validationSchema: any,
) => {
  return useFormik<ICommentFormValues>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};
