import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import AuthWrapper from 'components/wrapper/AuthWrapper';
import ProfilePayment from 'pages/business/BusinessPayment';
import { useEffect, useState } from 'react';
import { getUserPersonalInfo, UserSetUpSteps } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

import ProfileAccountSubmission from './ProfileAccountSubmission';
import ProfileInformation from './ProfileInformation';

const { USER_INFO, PAYMENT, PAYMENT_SUCCESS } = UserSetUpSteps;

const stepNumberMapping: { [key: string]: number } = {
  [USER_INFO]: 1,
  [PAYMENT]: 2,
};

export default function UserProfileSetUp() {
  const dispatch = useAppDispatch();
  const { userData, loading: userDataLoading } = useAppSelector(s => s.account);
  const userStep = userData?.step ?? USER_INFO;
  const [step, updateStep] = useState<UserSetUpSteps>(userStep);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userDataLoading !== loading) {
      setLoading(userDataLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataLoading]);

  useEffect(() => {
    window.onbeforeunload = async () => {
      return 'Are you sure, you want to leave?';
    };
    return () => {
      window.onbeforeunload = () => {};
    };
  }, []);

  useEffect(() => {
    scrollToTop();
    if (Object.keys(userData)?.length === 0) {
      setLoading(true);
      dispatch(getUserPersonalInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateStep(userData?.step ?? USER_INFO);
  }, [userData?.step]);

  return (
    <AuthWrapper>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {stepNumberMapping[step] && (
            <Typography color="secondary.main" marginBottom={theme.spacing(0.5)} variant="h6">
              Step {stepNumberMapping[step]} of 2
            </Typography>
          )}
          {step === USER_INFO && <ProfileInformation handleNext={() => updateStep(PAYMENT)} />}
          {step === PAYMENT && (
            <ProfilePayment
              handleNext={() => updateStep(PAYMENT_SUCCESS)}
              handlePrev={() => updateStep(USER_INFO)}
              type="member"
            />
          )}
          {[PAYMENT_SUCCESS].includes(step) && <ProfileAccountSubmission />}
        </>
      )}
    </AuthWrapper>
  );
}
