import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import BusinessInformation from 'components/business/BusinessInformation';
import { useSetupBusinessInformationForm } from 'components/business/useBusinessForm';
import { UserType } from 'constants/constants';
import { useLocation } from 'react-router-dom';
import { IResponseData } from 'store';
import { saveBusinessData, updateBusinessData } from 'store/business/BusinessAction';
import {
  BusinessComponentType,
  BusinessSetupSteps,
  IBusinessInformationState,
  ISetupBusinessInformationState,
} from 'store/business/BusinessInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { CookieNames, getCookieItem } from 'utils/cookies';
import { handleMaskToUSNumber, unmaskUSNumber } from 'utils/utils';
import { setupBusinessInformationSchema } from 'utils/validationSchema';

interface IBusinessInformationProps {
  handleNext: () => void;
}

const { SETUP } = BusinessComponentType;

function BusinessInfo({ handleNext }: IBusinessInformationProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const businessType = getCookieItem(CookieNames.BUSINESS_TYPE);
  const { businessDetails, isCreateBusinessLoading, isUpdateBusinessLoading } = useAppSelector(
    s => s.business,
  );

  const businessInfoInitialState: IBusinessInformationState = {
    name: businessDetails?.name ?? '',
    businessCategories: businessDetails?.businessCategories ?? [],
    address: businessDetails?.address ?? '',
    website: businessDetails?.website ?? '',
    city: businessDetails?.city ?? '',
    stateId: businessDetails?.stateId ?? '',
    phone: handleMaskToUSNumber(businessDetails?.phone) ?? '',
    zipcode: businessDetails?.zipcode ?? '',
    onlineOnly: businessDetails?.onlineOnly ?? false,
    email: businessDetails?.email ?? '',
    contactPersonName: businessDetails?.contactPersonName ?? '',
  };

  const getRequestData = () => {
    const data = {
      ...values.businessInformation,
      businessCategoryIds: values.businessInformation.businessCategories.map(c => c.documentId),
      phone: unmaskUSNumber(values.businessInformation.phone),
      type:
        (location?.state !== null && location?.state === 'paid_business_initiated') ||
        businessType === UserType.PAYMENT_INITIATED
          ? 'payment_initiated'
          : 'free',
    };
    return data;
  };

  const handleCreateBusiness = async () => {
    const requestData = getRequestData();
    const response = (await dispatch(saveBusinessData(requestData))) as IResponseData;
    if (response && typeof response.error !== 'undefined' && !response.error) {
      handleNext();
    }
  };

  const handleUpdateBusiness = async () => {
    const requestData = {
      name: values.businessInformation.name,
      phone: unmaskUSNumber(values.businessInformation.phone),
      onlineOnly: values.businessInformation.onlineOnly,
      email: values.businessInformation.email,
      contactPersonName: values.businessInformation.contactPersonName,
    };

    const response = (await dispatch(
      updateBusinessData(requestData, businessDetails?.documentId),
    )) as IResponseData;
    if (response && typeof response.error !== 'undefined' && !response.error) {
      handleNext();
    }
  };

  const handleBusinessInfo = () => {
    if (Object.keys(businessDetails).length > 0 && dirty) {
      handleUpdateBusiness();
    } else if (Object.keys(businessDetails).length > 0 && !dirty) {
      handleNext();
    } else {
      handleCreateBusiness();
    }
  };

  const setupBusinessInfoInitialState: ISetupBusinessInformationState = {
    businessInformation: businessInfoInitialState,
  };

  const formik = useSetupBusinessInformationForm(
    handleBusinessInfo,
    setupBusinessInfoInitialState,
    setupBusinessInformationSchema,
  );

  const isFree = businessDetails?.step === BusinessSetupSteps.FREE_BUSINESS_LISTED;

  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    setFieldTouched,
  } = formik;

  return (
    <Box noValidate component="form" onSubmit={handleSubmit}>
      <BusinessInformation
        componentType={SETUP}
        errors={errors.businessInformation}
        handleBlur={handleBlur}
        handleChange={handleChange}
        isFree={isFree}
        setFieldValue={setFieldValue}
        setTouched={setFieldTouched}
        touched={touched.businessInformation}
        values={values.businessInformation}
      />
      <LoadingButton
        disableRipple
        fullWidth
        disabled={!isValid}
        loading={isCreateBusinessLoading || isUpdateBusinessLoading}
        size="large"
        sx={{
          mt: theme.spacing(4),
        }}
        type="submit"
        variant="contained"
      >
        Next
      </LoadingButton>
    </Box>
  );
}

export default BusinessInfo;
