import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ConfirmationModal from 'components/confirmationModal/ConfirmationModal';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import Loader from 'components/loader/Loader';
import { StyledTableCell, StyledTableRow } from 'components/table/StyledTable';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import { useCallback, useState } from 'react';
import { ISponsor } from 'store';
import {
  deleteSponsor,
  fetchSponsorsList,
} from 'store/admin/vettingDashboard/VettingDashboardActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

import AddSponsor from './AddSponsor';

interface ISponsorReportProps {
  handleShowAdsPage: (show: boolean, sponsor: ISponsor) => void;
}

function SponsorsReport({ handleShowAdsPage }: ISponsorReportProps) {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const { getSponsorsDataLoading, deleteSponsorLoading, sponsorsData } = useAppSelector(
    s => s.vettingDashboard,
  );

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [selectedSponsor, setSelectedSponsor] = useState({} as ISponsor);
  const [showEditSponsor, setShowEditSponsor] = useState(false);

  const fetchAllSponsorsReport = useCallback(
    (initialLoad: boolean) => {
      dispatch(fetchSponsorsList(initialLoad ? 0 : sponsorsData.offset));
    },
    [dispatch, sponsorsData.offset],
  );

  const handleDeleteSponsor = () => {
    dispatch(deleteSponsor(selectedSponsor.documentId)).then((response: any) => {
      if (!response?.error) setShowDeleteConfirmationModal(false);
    });
  };

  return (
    <ComponentWrapper
      withInfiniteScroll
      borderColor="light"
      infiniteScrollProps={{
        loadOnMount: true,
        hasMoreData: sponsorsData.hasMoreData,
        isLoading: getSponsorsDataLoading,
        onBottomHit: fetchAllSponsorsReport,
        valueToBeAddedToInnerHeight: 50,
      }}
      title=""
      withPadding={false}
    >
      <Stack
        alignItems="center"
        borderBottom={`0.25px solid ${theme.palette.divider}`}
        direction="row"
        padding={isMobile ? theme.spacing(2) : theme.spacing(3.65, 4, 3)}
        spacing={theme.spacing(1.5)}
      >
        <Typography color="text.secondary" variant={isMobile ? 'title2' : 'title1'}>
          Sponsors List
        </Typography>
      </Stack>
      <TableContainer sx={{ overflowX: 'auto' }}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Url</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sponsorsData.sponsors.map((sponsor: ISponsor) => (
              <StyledTableRow key={sponsor.documentId}>
                <StyledTableCell>
                  <Typography
                    component="div"
                    // variant="body3"
                    sx={{
                      cursor: 'pointer',
                      textUnderlineOffset: '2px',
                      textDecoration: 'none',
                      color: theme.palette.primary.main,
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() => {
                      handleShowAdsPage(true, sponsor);
                    }}
                  >
                    {sponsor.name}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>{sponsor.url}</StyledTableCell>
                <StyledTableCell align="center">
                  <Stack direction="row" spacing={theme.spacing(2)}>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        setSelectedSponsor(sponsor);
                        setShowEditSponsor(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        setSelectedSponsor(sponsor);
                        setShowDeleteConfirmationModal(true);
                      }}
                    >
                      Delete
                    </Button>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {getSponsorsDataLoading && (
        <Box boxSizing="border-box" padding={theme.spacing(2, 0)} width="100%">
          <Loader />
        </Box>
      )}
      {!getSponsorsDataLoading && sponsorsData.sponsors.length === 0 && (
        <Box boxSizing="border-box" padding={theme.spacing(4)} width="100%">
          <Typography align="center">No records found</Typography>
        </Box>
      )}
      <ToggleDrawerDialog
        showCloseIcon
        handleClose={() => setShowDeleteConfirmationModal(false)}
        isMobile={isMobile}
        open={showDeleteConfirmationModal}
        setOpen={setShowDeleteConfirmationModal}
        title="Delete Sponsor"
      >
        <ConfirmationModal
          loading={deleteSponsorLoading}
          modalText={`Are you sure you want to delete ${selectedSponsor.name}?`}
          submitBtnText="Confirm"
          onClose={() => setShowDeleteConfirmationModal(false)}
          onSubmit={handleDeleteSponsor}
        />
      </ToggleDrawerDialog>

      {showEditSponsor && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowEditSponsor(false)}
          isMobile={isMobile}
          open={showEditSponsor}
          setOpen={setShowEditSponsor}
          title="Edit Sponsor"
        >
          <AddSponsor closeModal={() => setShowEditSponsor(false)} sponsor={selectedSponsor} />
        </ToggleDrawerDialog>
      )}
    </ComponentWrapper>
  );
}

export default SponsorsReport;
