import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';

type IEpisodeTileProps = {
  description: string;
  episodeId: string;
  imageSrc: string;
  title: string;
  tileType?: 'small' | 'large';
  showDivider: boolean;
};

export default function EpisodeTile(props: IEpisodeTileProps) {
  const { description, episodeId, imageSrc, title, tileType = 'large', showDivider } = props;
  const navigate = useNavigate();
  const isSmallTile = tileType === 'small';

  const navigateToEpisodeView = () => {
    navigate(`/podcast/${episodeId}`);
  };

  return (
    <>
      <Grid
        container
        item
        justifyContent="space-between"
        spacing={theme.spacing(2)}
        sx={{ mb: theme.spacing(2) }}
      >
        <Grid
          item
          display="flex"
          justifyContent="flex-end"
          midsm={3.25}
          midxs={3.5}
          paddingLeft={{
            ...(isSmallTile
              ? { lg: theme.spacing(3), xs: theme.spacing(2) }
              : {
                  lg: theme.spacing(8),
                  md: theme.spacing(7),
                  sm: theme.spacing(3),
                  xs: theme.spacing(2),
                }),
          }}
          xs={12}
        >
          <Box
            alt="episode-image"
            component="img"
            height={isSmallTile ? 'auto' : 168}
            loading="lazy"
            minHeight="100%"
            overflow="hidden"
            src={imageSrc}
            sx={{
              objectFit: 'cover',
              objectPosition: '50% 50%',
              cursor: 'pointer',
              borderRadius: '10px',
            }}
            width="100%"
            onClick={navigateToEpisodeView}
          />
        </Grid>
        <Grid container item midsm={8.75} midxs={8.5} xs={12}>
          <Stack direction="row" height="100%" spacing={theme.spacing(1.5)} width="100%">
            <Stack
              flexGrow={1}
              height="100%"
              justifyContent="space-evenly"
              spacing={theme.spacing(2)}
              width="100%"
            >
              <Stack height="inherit" justifyContent="space-evenly">
                <Typography
                  className="text-ellipsis text-clamp-2 text-ul-offset_4_hover"
                  variant={isSmallTile ? 'body2' : 'body1Bold'}
                  onClick={navigateToEpisodeView}
                >
                  {title}
                </Typography>
                <Typography
                  className="text-ellipsis text-clamp-2 text-ul-offset_4_hover"
                  variant="body2"
                  onClick={navigateToEpisodeView}
                >
                  {description}
                </Typography>
              </Stack>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                spacing={theme.spacing(2)}
                sx={{ m: theme.spacing(3) }}
              >
                <Button
                  disableRipple
                  disableTouchRipple
                  fullWidth
                  size={isSmallTile ? 'small' : 'medium'}
                  variant="contained"
                  onClick={navigateToEpisodeView}
                >
                  Listen
                </Button>
                <Button
                  disableRipple
                  disableTouchRipple
                  fullWidth
                  size={isSmallTile ? 'small' : 'medium'}
                  sx={{ whiteSpace: 'nowrap' }}
                  variant="contained"
                  onClick={() => {}}
                >
                  ADD to playlist
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      {showDivider && (
        <Divider
          flexItem
          orientation="horizontal"
          sx={{
            borderBottomWidth: '1.5px',
            borderColor: theme.palette.divider,
            my: theme.spacing(3),
          }}
        />
      )}
    </>
  );
}
