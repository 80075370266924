import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import CategoryFilter from 'components/businessCategoryFilter/CategoryFilter';
import AdvertisementTile from 'components/tile/AdvertisementTile';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { useEffect } from 'react';
import {
  AdsPages,
  fetchAds,
  getAllBusinesses,
  getBusinessCategories,
  getFeaturedBusinesses,
  getRecentBusinesses,
  resetAdsState,
  resetBusinessDirectoryState,
  // resetFilterBusinessCategories,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

import AllBusinesses from './AllBusinesses';
import FeaturedBusinesses from './FeaturedBusinesses';
import RecentBusinesses from './RecentBusinesses';

function Businesses() {
  const dispatch = useAppDispatch();
  const isTablet = useMediaQuery(theme.breakpoints.down('midmd'));

  const { businessCategories, filterBusinessCategories } = useAppSelector(s => s.business);

  const handleSubmit = () => {
    dispatch(resetBusinessDirectoryState());
    dispatch(getFeaturedBusinesses(0));
    dispatch(getRecentBusinesses(0, 12));
    dispatch(getAllBusinesses(0));
  };
  useEffect(() => {
    scrollToTop();
    dispatch(fetchAds(AdsPages.BUSINESS));
    if (businessCategories.length === 0) {
      fetchBusinessCategories();
    }
    return () => {
      dispatch(resetAdsState());
      dispatch(resetBusinessDirectoryState());
      // dispatch(resetFilterBusinessCategories());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBusinessCategories = async () => {
    await dispatch(getBusinessCategories());
  };

  return (
    <HomeWrapper>
      <Grid container {...(isTablet && { flexDirection: 'column-reverse' })}>
        <Grid container item midmd={8} xs={12}>
          <CategoryFilter
            businessCategories={businessCategories}
            categories={filterBusinessCategories}
            handleSubmit={handleSubmit}
            isTablet={isTablet}
          />
          <Stack spacing={theme.spacing(5)} width="100%">
            <FeaturedBusinesses />
            <RecentBusinesses scrollPagination={false} />
            <AllBusinesses />
          </Stack>
        </Grid>
        <Grid
          container
          item
          midmd={4}
          {...(!isTablet && { paddingLeft: theme.spacing(2.5) })}
          xs={12}
        >
          <AdvertisementTile
            useCommonData
            disableMargin={!isTablet}
            page={AdsPages.BUSINESS}
            position="A"
            uniqueKey={1}
          />
        </Grid>
      </Grid>
    </HomeWrapper>
  );
}

export default Businesses;
