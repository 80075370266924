import Grid from '@mui/material/Grid';
import Loader from 'components/loader/Loader';
import NoDataAvailable from 'components/notFound/NoDataAvailable';
import BusinessDirectoryTile from 'components/tile/BusinessDirectoryTile';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import { getAllBusinesses } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

function AllBusinesses() {
  const dispatch = useAppDispatch();
  const { allBusinessLoading, allBusinesses } = useAppSelector(s => s.business);

  // useEffect(() => {
  //   dispatch(getAllBusinesses(0));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const getAllBusinessList = () => {
    dispatch(getAllBusinesses(allBusinesses.offset));
  };

  return (
    <ComponentWrapper
      isTitleInsideWrapper
      withInfiniteScroll
      infiniteScrollProps={{
        loadOnMount: true,
        hasMoreData: allBusinesses.hasMoreData,
        isLoading: allBusinessLoading,
        onBottomHit: getAllBusinessList,
      }}
      title="All Businesses"
    >
      <>
        {allBusinesses.businesses && allBusinesses.businesses.length > 0 ? (
          <Grid container spacing={4}>
            {allBusinesses.businesses.map((business, index: number) => (
              <Grid
                key={business.documentId + index.toString()}
                item
                md={3}
                midsm={4}
                sm={6}
                xs={12}
              >
                <BusinessDirectoryTile
                  key={business?.documentId}
                  businessId={business?.documentId}
                  businessType={business?.businessType}
                  description={business?.description}
                  image={business?.imageLogo.croppedImageReadUrl as string}
                  isFollowing={business?.isFollowing}
                  isOwner={business?.role === 'owner'}
                  name={business?.name}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          !allBusinessLoading && <NoDataAvailable type="businesses" />
        )}
        {allBusinessLoading && <Loader />}
      </>
    </ComponentWrapper>
  );
}

export default AllBusinesses;
