import { Dispatch } from 'redux';
import {
  AlertActions,
  AlertType,
  FollowingTimelineActions,
  HomeTimelineActions,
  IAlertPayload,
  IResponseData,
  SearchActions,
  TrendingTimelineActions,
  UpdateCommentCountType,
} from 'store';
import FollowingTimelineType from 'store/followingTimeline/FollowingTimelineTypes';
import HomeTimelineType from 'store/homeTimeline/HomeTimelineTypes';
import SearchType from 'store/search/SearchTypes';
import store from 'store/store';
import TrendingType from 'store/trending/TrendingTypes';

import client from '../../Api';
import { businessUrl } from '../../helpers/urls';
import {
  BusinessActions,
  BusinessSetupStatus,
  BusinessSetupSteps,
  IBusinessCategory,
  IBusinessDetails,
  IBusinessImageData,
  IBusinessImagePayload,
  IBusinessImages,
  IBusinessListState,
  IBusinessPost,
  ICreateBusinessPaymentPayload,
  ICreateBusinessPostPayload,
  IGetBusinessPostsParams,
  ImageType,
} from './BusinessInterface';
import BusinessType from './BusinessTypes';

const multiPartFormHeader = {
  headers: { 'Content-Type': 'multipart/form-data' },
};

export const updateBusinessPostCommentCount = (type: UpdateCommentCountType) => {
  return async (dispatch: Dispatch<BusinessActions>) => {
    dispatch({ type: BusinessType.UPDATE_COMMENT_COUNT, payload: type });
  };
};

export const updateBusinessPostContent = (params: any) => {
  return async (dispatch: Dispatch<BusinessActions>) => {
    dispatch({ type: BusinessType.BUSINESS_UPDATE_POST_CONTENT, payload: params });
  };
};

export const saveBusinessData = (payload: any) => {
  return async (dispatch: Dispatch<BusinessActions>) => {
    try {
      dispatch({ type: BusinessType.SAVE_BUSINESS_DATA_REQUEST });
      const response: IResponseData = await client.post(`${businessUrl}business`, payload);

      dispatch({
        type: BusinessType.SAVE_BUSINESS_DATA_SUCCESS,
        payload: {
          documentId: response.data?.documentId,
          images: {} as IBusinessImages,
          step:
            payload.type === 'free'
              ? BusinessSetupSteps.FREE_BUSINESS_LISTED
              : BusinessSetupSteps.PAYMENT,
          status: BusinessSetupStatus.DRAFT,
          ...payload,
        },
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: BusinessType.SAVE_BUSINESS_DATA_ERROR });
      return error;
    }
  };
};

export const updateBusinessData = (payload: any, businessId: string) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.UPDATE_BUSINESS_DATA_REQUEST });
      const response: IResponseData = await client.patch(
        `${businessUrl}business/${businessId}`,
        payload,
      );
      dispatch({ type: BusinessType.UPDATE_BUSINESS_DATA_SUCCESS, payload });

      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: { message: 'Successfully saved business information' },
      });

      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.UPDATE_BUSINESS_DATA_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const getStates = () => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.GET_STATES_REQUEST });
      const response: IResponseData = await client.get(`${businessUrl}states`);
      dispatch({
        type: BusinessType.GET_STATES_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.GET_STATES_ERROR });
      return error;
    }
  };
};

export const getBusinessCategories = () => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.GET_CATEGORIES_REQUEST });
      const response: IResponseData = await client.get(`${businessUrl}categories`);
      dispatch({
        type: BusinessType.GET_CATEGORIES_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.GET_CATEGORIES_ERROR });
      return error;
    }
  };
};

export const getBusinessDetailsByUserId = () => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.FETCH_BUSINESS_DETAILS_REQUEST });
      const response: IResponseData = await client.get(`${businessUrl}business/users`);

      const payload = {
        data:
          response.data && response.data?.length > 0
            ? {
                ...response.data?.[0],
                phone: response.data?.[0]?.phone?.slice?.(2) || '',
              }
            : ({} as IBusinessDetails),
        updateUserBusinessId: true,
      };

      dispatch({ type: BusinessType.FETCH_BUSINESS_DETAILS_SUCCESS, payload });

      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.FETCH_BUSINESS_DETAILS_ERROR });
      return error;
    }
  };
};

const getFormData = (payload: IBusinessImagePayload) => {
  const formData = new FormData();
  formData.append('image', payload.image);
  formData.append('croppedImage', payload.croppedImage);
  formData.append('imageViewAttribute', payload.imageViewAttribute);
  formData.append('imageType', payload.imageType);
  return formData;
};

const getImageResponseData = (response: any) => {
  const data: IBusinessImageData = {
    croppedImageDetails: window.atob(response.croppedImageDetails),
    croppedImageReadUrl: response.croppedImageReadUrl,
    documentId: response.imageId,
    imageType: response.imageType,
    originalImageReadUrl: response.originalImageReadUrl,
  };
  return data;
};

export const addBusinessImage = (payload: IBusinessImagePayload, businessId: string) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.ADD_BUSINESS_IMAGE_REQUEST });
      const addImagePayload = getFormData(payload);
      const response: IResponseData = await client.post(
        `${businessUrl}business/${businessId}/image`,
        addImagePayload,
        multiPartFormHeader,
      );
      if (response.data && Object.keys(response.data).length > 0) {
        const responseData = getImageResponseData(response.data);
        dispatch({
          type: BusinessType.ADD_BUSINESS_IMAGE_SUCCESS,
          payload: {
            response: responseData,
            imageType: response.data?.imageType,
          },
        });
        const successPayload: IAlertPayload = { message: 'Upload Successful' };
        dispatch({
          type: AlertType.ALERT_SUCCESS,
          payload: successPayload,
        });
      }
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.ADD_BUSINESS_IMAGE_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const updateBusinessImage = (payload: IBusinessImagePayload, imageId: string) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.UPDATE_BUSINESS_IMAGE_REQUEST });
      const updateImagePayload = getFormData(payload);
      const response: IResponseData = await client.put(
        `${businessUrl}business/image/${imageId}`,
        updateImagePayload,
        multiPartFormHeader,
      );
      if (response.data && Object.keys(response.data).length > 0) {
        const responseData = getImageResponseData(response.data);
        dispatch({
          type: BusinessType.UPDATE_BUSINESS_IMAGE_SUCCESS,
          payload: {
            imageType: response.data?.imageType,
            oldImageId: imageId,
            response: responseData,
          },
        });
        const successPayload: IAlertPayload = { message: 'Upload Successful' };
        dispatch({ type: AlertType.ALERT_SUCCESS, payload: successPayload });
      }
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.UPDATE_BUSINESS_IMAGE_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const deleteBusinessImage = (imageId: string, imageType: ImageType) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.DELETE_BUSINESS_IMAGE_REQUEST });
      const response: IResponseData = await client.delete(
        `${businessUrl}business/image/${imageId}`,
      );
      if (!response.error) {
        dispatch({
          type: BusinessType.DELETE_BUSINESS_IMAGE_SUCCESS,
          payload: { imageId, imageType },
        });
      }
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.DELETE_BUSINESS_IMAGE_ERROR });
      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const createBusinessPayment = (
  payload: ICreateBusinessPaymentPayload,
  businessId: string,
) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.CREATE_BUSINESS_PAYMENT_REQUEST });
      const response: IResponseData = await client.post(
        `${businessUrl}business/${businessId}/payments`,
        payload,
      );
      dispatch({
        type: BusinessType.CREATE_BUSINESS_PAYMENT_SUCCESS,
        payload: response?.data?.businessInfo,
      });
      if (response.data.status === 'failed') {
        const errorPayload: IAlertPayload = {
          message: 'Payment failed.',
        };
        dispatch({
          type: AlertType.ALERT_ERROR,
          payload: errorPayload,
        });
        return { ...response, error: true };
      }
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.CREATE_BUSINESS_PAYMENT_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const setFilterBusinessCategories = (categories: IBusinessCategory[]) => {
  return async (dispatch: Dispatch<BusinessActions>) => {
    dispatch({
      type: BusinessType.SET_FILTER_BUSINESSES_CATEGORIES,
      payload: categories,
    });
  };
};

export const resetFilterBusinessCategories = () => {
  return async (dispatch: Dispatch<BusinessActions>) => {
    dispatch({
      type: BusinessType.RESET_FILTER_BUSINESSES_CATEGORIES,
    });
  };
};

export const getFeaturedBusinesses = (offset: number) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>, getState: any) => {
    try {
      dispatch({ type: BusinessType.FETCH_FEATURED_BUSINESSES_REQUEST });
      const { filterBusinessCategories } = getState().business;
      const categories = filterBusinessCategories.map((e: IBusinessCategory) => e.documentId);
      const response: IResponseData = await client.get(
        `${businessUrl}businesses?limit=12&offset=${offset}&type=all&isFeatured=true${
          categories.length > 0 ? `&categoryIds=${categories}` : ''
        }`,
      );
      const payload: IBusinessListState = {
        businesses: response.data,
        offset: offset + 4,
        hasMoreData: response.data && response.data.length === 4,
      };
      dispatch({
        type: BusinessType.FETCH_FEATURED_BUSINESSES_SUCCESS,
        payload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.FETCH_FEATURED_BUSINESSES_ERROR });
      return error;
    }
  };
};

export const getRecentBusinesses = (offset: number, limit: number) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>, getState: any) => {
    try {
      dispatch({ type: BusinessType.FETCH_RECENT_BUSINESSES_REQUEST });
      const { filterBusinessCategories } = getState().business;
      const categories = filterBusinessCategories.map((e: IBusinessCategory) => e.documentId);
      const response: IResponseData = await client.get(
        `${businessUrl}businesses?limit=${limit}&offset=${offset}&type=recent${
          categories.length > 0 ? `&categoryIds=${categories}` : ''
        }`,
      );
      const payload: IBusinessListState = {
        businesses: response.data,
        offset: offset + limit,
        hasMoreData: response.data && response.data.length === limit,
      };
      dispatch({
        type: BusinessType.FETCH_RECENT_BUSINESSES_SUCCESS,
        payload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.FETCH_RECENT_BUSINESSES_ERROR });
      return error;
    }
  };
};

export const getAllBusinesses = (offset: number) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>, getState: any) => {
    try {
      dispatch({ type: BusinessType.FETCH_ALL_BUSINESSES_REQUEST });
      const { filterBusinessCategories } = getState().business;
      const categories = filterBusinessCategories.map((e: IBusinessCategory) => e.documentId);
      const response: IResponseData = await client.get(
        `${businessUrl}businesses?limit=8&offset=${offset}&type=all${
          categories.length > 0 ? `&categoryIds=${categories}` : ''
        }`,
      );
      const payload: IBusinessListState = {
        businesses: response.data,
        offset: offset + 8,
        hasMoreData: response.data && response.data.length === 8,
      };
      dispatch({
        type: BusinessType.FETCH_ALL_BUSINESSES_SUCCESS,
        payload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.FETCH_ALL_BUSINESSES_ERROR });
      return error;
    }
  };
};

export const resetBusinessDirectoryState = () => {
  return async (dispatch: Dispatch<BusinessActions>) => {
    dispatch({ type: BusinessType.RESET_BUSINESS_DIRECTORY_STATE });
  };
};

export const followBusiness = (businessId: string) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.FOLLOW_BUSINESS_REQUEST });
      const response: IResponseData = await client.post(
        `${businessUrl}business/${businessId}/follow`,
      );
      dispatch({
        type: BusinessType.FOLLOW_BUSINESS_SUCCESS,
        payload: {
          businessId,
        },
      });
      const successPayload: IAlertPayload = {
        message: 'Successfully followed business',
      };
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: successPayload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.FOLLOW_BUSINESS_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const unFollowBusiness = (businessId: string) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.UNFOLLOW_BUSINESS_REQUEST });
      const response: IResponseData = await client.post(
        `${businessUrl}business/${businessId}/unfollow`,
      );
      dispatch({
        type: BusinessType.UNFOLLOW_BUSINESS_SUCCESS,
        payload: {
          businessId,
        },
      });
      const successPayload: IAlertPayload = {
        message: 'Successfully unfollowed business',
      };
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: successPayload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.UNFOLLOW_BUSINESS_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const paymentExistingCard = (businessId: string, cardId: string, amount: number) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.PAYMENT_EXISTING_CARD_REQUEST });
      const response: IResponseData = await client.post(
        `${businessUrl}business/${businessId}/cardPayments`,
        { cardId, amount },
      );
      dispatch({
        type: BusinessType.PAYMENT_EXISTING_CARD_SUCCESS,
        payload: response?.data?.businessInfo,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.PAYMENT_EXISTING_CARD_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const getBusinessDetailsByBusinessId = (businessId: string) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({
        type: BusinessType.FETCH_BUSINESS_DETAILS_BY_BUSINESSID_REQUEST,
      });
      const response: IResponseData = await client.get(`${businessUrl}business/${businessId}`);
      dispatch({
        type: BusinessType.FETCH_BUSINESS_DETAILS_BY_BUSINESSID_SUCCESS,
        payload: response.data as IBusinessDetails,
      });
      return response;
    } catch (error: any) {
      dispatch({
        type: BusinessType.FETCH_BUSINESS_DETAILS_BY_BUSINESSID_ERROR,
      });
      return error;
    }
  };
};

export const getBusinessDetailsByAdmin = (businessId: string) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({
        type: BusinessType.FETCH_BUSINESS_DETAILS_REQUEST,
      });
      const response: IResponseData = await client.get(`${businessUrl}business/${businessId}`);
      const payload = {
        data: {
          ...response.data,
          phone: response.data?.phone.slice(2),
        },
        updateUserBusinessId: false,
      };
      dispatch({
        type: BusinessType.FETCH_BUSINESS_DETAILS_SUCCESS,
        payload,
      });
      return response;
    } catch (error: any) {
      dispatch({
        type: BusinessType.FETCH_BUSINESS_DETAILS_ERROR,
      });
      return error;
    }
  };
};

export const fetchBusinessPosts = (params: IGetBusinessPostsParams) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      const { businessId, offset, limit, reset } = params;
      dispatch({ type: BusinessType.FETCH_BUSINESS_POSTS_REQUEST });
      const response: IResponseData = await client.get(
        `${businessUrl}${businessId}/content?limit=${limit}&offset=${offset}`,
      );

      const payload = {
        posts: (response.data || []) as IBusinessPost[],
        offset: offset + 10,
        hasMoreData: response?.data?.length === 10,
        reset,
      };

      dispatch({ type: BusinessType.FETCH_BUSINESS_POSTS_SUCCESS, payload });
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.FETCH_BUSINESS_POSTS_ERROR });
      return error;
    }
  };
};

const getBusinessPostFormData = (payload: ICreateBusinessPostPayload) => {
  const formData = new FormData();
  formData.append('textContent', payload.textContent);
  formData.append('contentDataType', payload.contentDataType);
  if (payload.mediaContent) {
    formData.append('mediaContent', payload.mediaContent);
    formData.append('croppedMediaContent', payload.croppedMediaContent);
    formData.append('imageViewAttribute', payload.imageViewAttribute);
  }
  return formData;
};

export const createBusinessPost = (businessId: string, payload: ICreateBusinessPostPayload) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.CREATE_BUSINESS_POST_REQUEST });
      const createBusinessPostPayload = getBusinessPostFormData(payload);

      const response: IResponseData = await client.post(
        `${businessUrl}${businessId}/content`,
        createBusinessPostPayload,
        multiPartFormHeader,
      );
      dispatch({
        type: BusinessType.CREATE_BUSINESS_POST_SUCCESS,
        payload: response.data as IBusinessPost,
      });

      const successPayload: IAlertPayload = {
        message: 'Successfully created business post',
      };
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: successPayload,
      });

      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.CREATE_BUSINESS_POST_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const editBusinessPost = (businessId: string, payload: ICreateBusinessPostPayload) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.EDIT_BUSINESS_POST_REQUEST });
      const editBusinessPostPayload = getBusinessPostFormData(payload);

      const response: IResponseData = await client.put(
        `${businessUrl}content/${businessId}`,
        editBusinessPostPayload,
        multiPartFormHeader,
      );
      dispatch({
        type: BusinessType.EDIT_BUSINESS_POST_SUCCESS,
        payload: response.data as IBusinessPost,
      });

      const successPayload: IAlertPayload = {
        message: 'Successfully edited business post',
      };
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: successPayload,
      });

      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.EDIT_BUSINESS_POST_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const deleteBusinessPost = (postId: string) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.DELETE_BUSINESS_POST_REQUEST });
      const response: IResponseData = await client.delete(`${businessUrl}content/${postId}`);
      dispatch({
        type: BusinessType.DELETE_BUSINESS_POST_SUCCESS,
        payload: postId,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.DELETE_BUSINESS_POST_ERROR });
      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const fetchBusinessMedia = (businessId: string, offset: number, limit: number) => {
  return async (dispatch: Dispatch<BusinessActions | AlertActions>) => {
    try {
      dispatch({ type: BusinessType.FETCH_BUSINESS_MEDIA_REQUEST });
      const response: IResponseData = await client.get(
        `${businessUrl}business/${businessId}/image?limit=${limit}&offset=${offset}`,
      );
      dispatch({
        type: BusinessType.FETCH_BUSINESS_MEDIA_SUCCESS,
        payload: {
          businessMedia: response?.data ? response.data : {},
          offset: offset + 10,
          hasMoreData: response?.data?.length === 10,
        },
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BusinessType.FETCH_BUSINESS_MEDIA_ERROR });
      return error;
    }
  };
};

export const resetBusinessWallState = () => {
  return async (dispatch: Dispatch<BusinessActions>) => {
    dispatch({ type: BusinessType.RESET_BUSINESS_WALL_STATE });
  };
};

export const resetBusinessPostsState = () => {
  return async (dispatch: Dispatch<BusinessActions>) => {
    dispatch({ type: BusinessType.RESET_BUSINESS_POSTS_STATE });
  };
};

export const resetBusinessMediaState = () => {
  return async (dispatch: Dispatch<BusinessActions>) => {
    dispatch({ type: BusinessType.RESET_BUSINESS_MEDIA_STATE });
  };
};

export const getBusinessPostDetailsById = (contentId: string) => {
  return async (dispatch: Dispatch<BusinessActions>) => {
    try {
      dispatch({ type: BusinessType.FETCH_BUSINESS_POST_DETAILS_REQUEST });
      const response: IResponseData = await client.get(`${businessUrl}contents/${contentId}`);

      const { account } = store.getState();
      const { userData } = account;

      const isMyOwnPost = userData.documentId === response.data.userId;

      dispatch({
        type: BusinessType.FETCH_BUSINESS_POST_DETAILS_SUCCESS,
        payload: { ...response.data, isMyOwnPost },
      });
      return response;
    } catch (error) {
      dispatch({ type: BusinessType.FETCH_BUSINESS_POST_DETAILS_ERROR });
      return error;
    }
  };
};

export const resetBusinessPostState = () => {
  return async (dispatch: Dispatch<BusinessActions>) => {
    dispatch({ type: BusinessType.RESET_BUSINESS_POST_DETAILS_STATE });
  };
};

export const likeDislikeBusiness = (
  contentId: string,
  isLiked: boolean,
  component: string,
  type?: string,
) => {
  return async (
    dispatch: Dispatch<
      | BusinessActions
      | AlertActions
      | FollowingTimelineActions
      | SearchActions
      | HomeTimelineActions
      | TrendingTimelineActions
    >,
  ) => {
    try {
      dispatch({ type: BusinessType.LIKE_DISLIKE_BUSINESS_POST_REQUEST });
      let response: IResponseData;
      const likeDislikeUrl = `${businessUrl}content/${contentId}/like`;
      if (!isLiked) {
        response = await client.post(likeDislikeUrl);
      } else {
        response = await client.delete(likeDislikeUrl);
      }
      if (component === 'business' && type === 'business-single-post') {
        dispatch({ type: BusinessType.LIKE_DISLIKE_BUSINESS_POST_SUCCESS });
      } else if (component === 'business' && type === 'business-wall') {
        dispatch({
          type: BusinessType.BUSINESS_LIKE_DISLIKE_BUSINESS_POST_SUCCESS,
          payload: contentId,
        });
      } else if (component === 'following') {
        dispatch({
          type: FollowingTimelineType.FOLLOWING_LIKE_DISLIKE_POST_SUCCESS,
          payload: contentId,
        });
      } else if (component === 'search') {
        dispatch({
          type: SearchType.SEARCH_LIKE_DISLIKE_POST_SUCCESS,
          payload: contentId,
        });
      } else if (component === 'home' && type === 'featured') {
        dispatch({
          type: HomeTimelineType.FEATURED_LIKE_DISLIKE_POST_SUCCESS,
          payload: contentId,
        });
      } else if (component === 'home' && type === 'all-home-business') {
        dispatch({
          type: HomeTimelineType.ALL_POST_LIKE_DISLIKE_POST_SUCCESS,
          payload: contentId,
        });
      } else if (component === 'trending') {
        dispatch({
          type: TrendingType.TRENDING_LIKE_DISLIKE_POST_SUCCESS,
          payload: contentId,
        });
      }
      return response;
    } catch (error: unknown) {
      dispatch({ type: BusinessType.LIKE_DISLIKE_BUSINESS_POST_ERROR });
      const e = error as IResponseData;
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: e?.message },
      });
      return error;
    }
  };
};

export const resetBusinessDetailsState = () => {
  return async (dispatch: Dispatch<BusinessActions>) => {
    dispatch({
      type: BusinessType.RESET_BUSINESS_DETAILS_STATE,
    });
  };
};
