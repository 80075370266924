import I1776_report from './freedom-square-1776_report.webp';
import I1776_unites from './freedom-square-1776_unites.webp';
import anystance from './freedom-square-anystance.webp';
import bill_of_rights from './freedom-square-bill_of_rights.webp';
import declaration_of_independence from './freedom-square-declaration_of_independence.webp';
import federal_spending from './freedom-square-federal_spending.webp';
import federal_unemployment from './freedom-square-federal_unemployment.webp';
import founding_fathers from './freedom-square-founding_fathers.webp';
import gettr from './freedom-square-gettr.webp';
import hillsdale_library from './freedom-square-hillsdale_library.webp';
import job_creators_network from './freedom-square-job_creators_network.webp';
import liberty_documents from './freedom-square-liberty_documents.webp';
import lincoln_presidential_library from './freedom-square-lincoln_presidential_library.webp';
import moms_for_america from './freedom-square-moms_for_america.webp';
import open_the_books from './freedom-square-open_the_books.webp';
import pending_house_bills from './freedom-square-pending_house_bills.webp';
import pending_senate_bills from './freedom-square-pending_senate_bills.webp';
import pepperdine_colonial_collection from './freedom-square-pepperdine_colonial_collection.webp';
import prager_u from './freedom-square-prager_u.webp';
import reagan_library from './freedom-square-reagan_library.webp';
import rumble from './freedom-square-rumble.webp';
import second_vote from './freedom-square-second_vote.webp';
import the_federalist_papers from './freedom-square-the_federalist_papers.webp';
import true_the_vote from './freedom-square-true_the_vote.webp';
import truth_social from './freedom-square-truth_social.webp';
import turning_point_action from './freedom-square-turning_point_action.webp';
import us_census from './freedom-square-us_census.webp';
import us_constitution from './freedom-square-us_constitution.webp';
import us_debt_clock from './freedom-square-us_debt_clock.webp';
import us_federal_debt from './freedom-square-us_federal_debt.webp';

const Images = {
  // resource images
  '1776_unites': I1776_unites,
  '1776_report': I1776_report,
  bill_of_rights,
  declaration_of_independence,
  founding_fathers,
  hillsdale_library,
  liberty_documents,
  lincoln_presidential_library,
  pepperdine_colonial_collection,
  prager_u,
  reagan_library,
  the_federalist_papers,
  us_constitution,
  us_federal_debt,
  federal_spending,
  federal_unemployment,
  us_census,
  job_creators_network,
  open_the_books,
  anystance,
  gettr,
  moms_for_america,
  rumble,
  second_vote,
  true_the_vote,
  truth_social,
  turning_point_action,
  us_debt_clock,
  pending_house_bills,
  pending_senate_bills,
};

export type ImagesType = keyof typeof Images;

export { Images };
