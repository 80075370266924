import { FormikHelpers, FormikValues, useFormik } from 'formik';

const useProfileInformationForm = <T extends FormikValues>(
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<unknown>,
  initialValues: T,
  validationSchema: any,
) => {
  return useFormik<T>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export default useProfileInformationForm;
