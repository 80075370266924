import { FormikHelpers, useFormik } from 'formik';
import { paymentValidationSchema } from 'utils/validationSchema';

import { ICardState } from './CardForm';

const useCardForm = (
  onSubmit: (
    values: ICardState,
    formikHelpers: FormikHelpers<ICardState>,
  ) => void | Promise<unknown>,
  initialValues: ICardState,
  formType: number,
) => {
  return useFormik<ICardState>({
    initialValues,
    validationSchema: paymentValidationSchema(formType),
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};
export default useCardForm;
