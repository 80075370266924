enum VettingDashboardType {
  RESET_DASHBOARD_STATE = 'RESET_DASHBOARD_STATE',

  GET_UNAPPROVED_BUSINESSES_REQUEST = 'GET_UNAPPROVED_BUSINESSES_REQUEST',
  GET_UNAPPROVED_BUSINESSES_SUCCESS = 'GET_UNAPPROVED_BUSINESSES_SUCCESS',
  GET_UNAPPROVED_BUSINESSES_ERROR = 'GET_UNAPPROVED_BUSINESSES_ERROR',

  GET_BUSINESS_DETAILS_REQUEST = 'GET_BUSINESS_DETAILS_REQUEST',
  GET_BUSINESS_DETAILS_SUCCESS = 'GET_BUSINESS_DETAILS_SUCCESS',
  GET_BUSINESS_DETAILS_ERROR = 'GET_BUSINESS_DETAILS_ERROR',

  APPROVE_BUSINESS_REQUEST = 'APPROVE_BUSINESS_REQUEST',
  APPROVE_BUSINESS_SUCCESS = 'APPROVE_BUSINESS_SUCCESS',
  APPROVE_BUSINESS_ERROR = 'APPROVE_BUSINESS_ERROR',

  DENY_BUSINESS_REQUEST = 'DENY_BUSINESS_REQUEST',
  DENY_BUSINESS_SUCCESS = 'DENY_BUSINESS_SUCCESS',
  DENY_BUSINESS_ERROR = 'DENY_BUSINESS_ERROR',

  DELETE_BUSINESS_ADMIN_REQUEST = 'DELETE_BUSINESS_ADMIN_REQUEST',
  DELETE_BUSINESS_ADMIN_SUCCESS = 'DELETE_BUSINESS_ADMIN_SUCCESS',
  DELETE_BUSINESS_ADMIN_ERROR = 'DELETE_BUSINESS_ADMIN_ERROR',

  DELETE_USER_ADMIN_REQUEST = 'DELETE_USER_ADMIN_REQUEST',
  DELETE_USER_ADMIN_SUCCESS = 'DELETE_USER_ADMIN_SUCCESS',
  DELETE_USER_ADMIN_ERROR = 'DELETE_USER_ADMIN_ERROR',

  SET_TOWN_CRIER_ADMIN_REQUEST = 'SET_TOWN_CRIER_ADMIN_REQUEST',
  SET_TOWN_CRIER_ADMIN_SUCCESS = 'SET_TOWN_CRIER_ADMIN_SUCCESS',
  SET_TOWN_CRIER_ADMIN_ERROR = 'SET_TOWN_CRIER_ADMIN_ERROR',

  SEND_EMAIL_TO_BUSINESS_REQUEST = 'SEND_EMAIL_TO_BUSINESS_REQUEST',
  SEND_EMAIL_TO_BUSINESS_SUCCESS = 'SEND_EMAIL_TO_BUSINESS_SUCCESS',
  SEND_EMAIL_TO_BUSINESS_ERROR = 'SEND_EMAIL_TO_BUSINESS_ERROR',

  LIST_AFFILIATES_REQUEST = 'LIST_AFFILIATES_REQUEST',
  LIST_AFFILIATES_SUCCESS = 'LIST_AFFILIATES_SUCCESS',
  LIST_AFFILIATES_ERROR = 'LIST_AFFILIATES_ERROR',

  SET_DATE_RANGE = 'SET_DATE_RANGE',

  RESET_BUSINESS_DETAILS_STATE = 'RESET_BUSINESS_DETAILS_STATE',
  RESET_AFFLIATES_LIST_STATE = 'RESET_AFFLIATES_LIST_STATE',
  RESET_AFFILIATES_PAYOUT_STATE = 'RESET_AFFILIATES_PAYOUT_STATE',
  RESET_ADS_REPORT_STATE = 'RESET_ADS_REPORT_STATE',

  GET_INFLUENCERS_REQUEST = 'GET_INFLUENCERS_REQUEST',
  GET_INFLUENCERS_SUCCESS = 'GET_INFLUENCERS_SUCCESS',
  GET_INFLUENCERS_ERROR = 'GET_INFLUENCERS_ERROR',

  AFFILIATES_PAYOUT_REQUEST = 'AFFILIATES_PAYOUT_REQUEST',
  AFFILIATES_PAYOUT_SUCCESS = 'AFFILIATES_PAYOUT_SUCCESS',
  AFFILIATES_PAYOUT_ERROR = 'AFFILIATES_PAYOUT_ERROR',

  CREATE_PAYOUT_REQUEST = 'CREATE_PAYOUT_REQUEST',
  CREATE_PAYOUT_SUCCESS = 'CREATE_PAYOUT_SUCCESS',
  CREATE_PAYOUT_ERROR = 'CREATE_PAYOUT_ERROR',

  GET_APPROVED_BUSINESSES_REQUEST = 'GET_APPROVED_BUSINESSES_REQUEST',
  GET_APPROVED_BUSINESSES_SUCCESS = 'GET_APPROVED_BUSINESSES_SUCCESS',
  GET_APPROVED_BUSINESSES_ERROR = 'GET_APPROVED_BUSINESSES_ERROR',

  GET_MEMBERS_REQUEST = 'GET_MEMBERS_REQUEST',
  GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS',
  GET_MEMBERS_ERROR = 'GET_MEMBERS_ERROR',

  RESET_APPROVED_BUSINESSES_STATE = 'RESET_APPROVED_BUSINESSES_STATE',
  RESET_MEMBERS_STATE = 'RESET_MEMBERS_STATE',

  DELETE_PAYOUT_REQUEST = 'DELETE_PAYOUT_REQUEST',
  DELETE_PAYOUT_SUCCESS = 'DELETE_PAYOUT_SUCCESS',
  DELETE_PAYOUT_ERROR = 'DELETE_PAYOUT_ERROR',

  SET_SELECTED_PROVIDER_TYPE = 'SET_SELECTED_PROVIDER_TYPE',

  ADD_PODCAST_PROVIDER_REQUEST = 'ADD_PODCAST_PROVIDER_REQUEST',
  ADD_PODCAST_PROVIDER_SUCCESS = 'ADD_PODCAST_PROVIDER_SUCCESS',
  ADD_PODCAST_PROVIDER_ERROR = 'ADD_PODCAST_PROVIDER_ERROR',

  GET_ALL_BUSINESSES_ADMIN_REQUEST = 'GET_ALL_BUSINESSES_ADMIN_REQUEST',
  GET_ALL_BUSINESSES_ADMIN_SUCCESS = 'GET_ALL_BUSINESSES_ADMIN_SUCCESS',
  GET_ALL_BUSINESSES_ADMIN_ERROR = 'GET_ALL_BUSINESSES_ADMIN_ERROR',

  GET_ALL_NEWS_PROVIDERS_ADMIN_REQUEST = 'GET_ALL_NEWS_PROVIDERS_ADMIN_REQUEST',
  GET_ALL_NEWS_PROVIDERS_ADMIN_SUCCESS = 'GET_ALL_NEWS_PROVIDERS_ADMIN_SUCCESS',
  GET_ALL_NEWS_PROVIDERS_ADMIN_ERROR = 'GET_ALL_NEWS_PROVIDERS_ADMIN_ERROR',

  GET_ALL_PODCASTS_ADMIN_REQUEST = 'GET_ALL_PODCASTS_ADMIN_REQUEST',
  GET_ALL_PODCASTS_ADMIN_SUCCESS = 'GET_ALL_PODCASTS_ADMIN_SUCCESS',
  GET_ALL_PODCASTS_ADMIN_ERROR = 'GET_ALL_PODCASTS_ADMIN_ERROR',

  SAVE_FEATURED_NEWS_PROVIDERS_REQUEST = 'SAVE_FEATURED_NEWS_PROVIDERS_REQUEST',
  SAVE_FEATURED_NEWS_PROVIDERS_SUCCESS = 'SAVE_FEATURED_NEWS_PROVIDERS_SUCCESS',
  SAVE_FEATURED_NEWS_PROVIDERS_ERROR = 'SAVE_FEATURED_NEWS_PROVIDERS_ERROR',

  SAVE_FEATURED_PODCAST_PROVIDERS_REQUEST = 'SAVE_FEATURED_PODCAST_PROVIDERS_REQUEST',
  SAVE_FEATURED_PODCAST_PROVIDERS_SUCCESS = 'SAVE_FEATURED_PODCAST_PROVIDERS_SUCCESS',
  SAVE_FEATURED_PODCAST_PROVIDERS_ERROR = 'SAVE_FEATURED_PODCAST_PROVIDERS_ERROR',

  SAVE_FEATURED_BUSINESS_REQUEST = 'SAVE_FEATURED_BUSINESS_REQUEST',
  SAVE_FEATURED_BUSINESS_SUCCESS = 'SAVE_FEATURED_BUSINESS_SUCCESS',
  SAVE_FEATURED_BUSINESS_ERROR = 'SAVE_FEATURED_BUSINESS_ERROR',

  GET_ADS_REPORT_REQUEST = 'GET_ADS_REPORT_REQUEST',
  GET_ADS_REPORT_SUCCESS = 'GET_ADS_REPORT_SUCCESS',
  GET_ADS_REPORT_ERROR = 'GET_ADS_REPORT_ERROR',

  CREATE_ADS_REQUEST = 'CREATE_ADS_REQUEST',
  CREATE_ADS_SUCCESS = 'CREATE_ADS_SUCCESS',
  CREATE_ADS_ERROR = 'CREATE_ADS_ERROR',

  ADD_SPONSOR_REQUEST = 'ADD_SPONSOR_REQUEST',
  ADD_SPONSOR_SUCCESS = 'ADD_SPONSOR_SUCCESS',
  ADD_SPONSOR_ERROR = 'ADD_SPONSOR_ERROR',

  GET_SPONSORS_LIST_REQUEST = 'GET_SPONSORS_LIST_REQUEST',
  GET_SPONSORS_LIST_SUCCESS = 'GET_SPONSORS_LIST_SUCCESS',
  GET_SPONSORS_LIST_ERROR = 'GET_SPONSORS_LIST_ERROR',

  GET_ADS_BY_SPONSORID_REQUEST = 'GET_ADS_BY_SPONSORID_REQUEST',
  GET_ADS_BY_SPONSORID_SUCCESS = 'GET_ADS_BY_SPONSORID_SUCCESS',
  GET_ADS_BY_SPONSORID_ERROR = 'GET_ADS_BY_SPONSORID_ERROR',

  RESET_SPONSOR_ADS_STATE = 'RESET_SPONSOR_ADS_STATE',

  DELETE_SPONSOR_REQUEST = 'DELETE_SPONSOR_REQUEST',
  DELETE_SPONSOR_SUCCESS = 'DELETE_SPONSOR_SUCCESS',
  DELETE_SPONSOR_ERROR = 'DELETE_SPONSOR_ERROR',

  EDIT_SPONSOR_REQUEST = 'EDIT_SPONSOR_REQUEST',
  EDIT_SPONSOR_SUCCESS = 'EDIT_SPONSOR_SUCCESS',
  EDIT_SPONSOR_ERROR = 'EDIT_SPONSOR_ERROR',

  DELETE_SPONSOR_AD_REQUEST = 'DELETE_SPONSOR_AD_REQUEST',
  DELETE_SPONSOR_AD_SUCCESS = 'DELETE_SPONSOR_AD_SUCCESS',
  DELETE_SPONSOR_AD_ERROR = 'DELETE_SPONSOR_AD_ERROR',

  EDIT_SPONSOR_AD_REQUEST = 'EDIT_SPONSOR_AD_REQUEST',
  EDIT_SPONSOR_AD_SUCCESS = 'EDIT_SPONSOR_AD_SUCCESS',
  EDIT_SPONSOR_AD_ERROR = 'EDIT_SPONSOR_AD_ERROR',

  CREATE_BUSINESS_ADMIN_REQUEST = 'CREATE_BUSINESS_ADMIN_REQUEST',
  CREATE_BUSINESS_ADMIN_SUCCESS = 'CREATE_BUSINESS_ADMIN_SUCCESS',
  CREATE_BUSINESS_ADMIN_ERROR = 'CREATE_BUSINESS_ADMIN_ERROR',

  EDIT_PODCAST_REQUEST = 'EDIT_PODCAST_REQUEST',
  EDIT_PODCAST_SUCCESS = 'EDIT_PODCAST_SUCCESS',
  EDIT_PODCAST_ERROR = 'EDIT_PODCAST_ERROR',

  DELETE_PODCAST_REQUEST = 'DELETE_PODCAST_REQUEST',
  DELETE_PODCAST_SUCCESS = 'DELETE_PODCAST_SUCCESS',
  DELETE_PODCAST_ERROR = 'DELETE_PODCAST_ERROR',
}

export default VettingDashboardType;
