import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Checkbox } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CustomInputField from 'components/customFields/CustomInputField';
import { FormikProps } from 'formik';
import { useLocation } from 'react-router-dom';
import { ContentType } from 'store';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';
import { capitalizeFirstLetter, checkPaidBusiness } from 'utils/utils';

import { ICommentFormValues } from './useCommentForm';

interface ICreateCommentProps {
  commentFormik: FormikProps<ICommentFormValues>;
  isLoading: boolean;
  isPrivate?: boolean;
  type: string;
  handleCancel?: () => void;
  toggleIsPrivate?: (() => void | undefined) | undefined;
  createType?: ContentType;
}

export default function CreateComment(props: ICreateCommentProps) {
  const { commentFormik, isLoading, isPrivate, type, createType, handleCancel, toggleIsPrivate } =
    props;

  const {
    dirty,
    errors,
    isValid,
    touched,
    values,
    resetForm,
    handleBlur,
    handleChange,
    handleSubmit,
  } = commentFormik;

  const location = useLocation();

  const isOwn = location.search.includes('true');

  const { userData } = useAppSelector(s => s.account);
  const userName = capitalizeFirstLetter(userData?.fullName);
  const { businessDetails } = useAppSelector(s => s.business);
  const { selectedComment } = useAppSelector(s => s.comment);

  const isPaidBusiness = checkPaidBusiness(businessDetails);

  const { images } = businessDetails;

  const businessImage = images?.logo?.croppedImageReadUrl;

  const isUserComment = userData.documentId !== selectedComment?.user?.userId && isOwn;

  const isPrivateReplies = createType === ContentType.BUSINESS && isPaidBusiness && isUserComment;

  return (
    <Stack
      sx={{
        border: '0.5px solid rgba(29, 29, 29, 0.8)',
        // filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25))",
        borderRadius: '20px',
        minHeight: '245px',
        my: theme.spacing(3),
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        spacing={theme.spacing(2)}
        sx={{ m: theme.spacing(3) }}
        width="100%"
      >
        <Avatar
          alt="provider-logo"
          src={isPaidBusiness ? businessImage : userData?.croppedImageReadUrl}
          sx={{
            width: theme.spacing(5),
          }}
          variant="circular"
        />
        <Typography component="h6" variant="h6Bold">
          {isPaidBusiness ? businessDetails?.name : userName}
        </Typography>
        {userData?.influencerStatus && <CheckCircleRoundedIcon color="primary" fontSize="small" />}
      </Stack>
      <CustomInputField
        fullWidth
        multiline
        required
        error={!!(touched?.comment && errors?.comment)}
        errormessage={touched?.comment ? errors?.comment : undefined}
        id="comment"
        label=""
        minRows={5}
        name="comment"
        placeholder="Share your comment..."
        stackstyles={{
          width: 'auto',
          mx: theme.spacing(1.5),
        }}
        sx={{ '&.MuiInputBase-root, &.MuiInputBase-root.Mui-focused': { border: 0 } }}
        type="textarea"
        value={values.comment}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <Stack
        alignItems="center"
        alignSelf={isPrivateReplies ? '' : 'flex-end'}
        direction="row"
        display="flex"
        justifyContent="space-between"
      >
        {isPrivateReplies ? (
          <Box alignItems="center" borderRadius={2} display="flex" flexDirection="row" ml={2}>
            <Checkbox
              checked={isPrivate}
              className=""
              color="primary"
              disabled={selectedComment.isPrivate}
              id="isPrivate"
              name="isPrivate"
              onChange={() => toggleIsPrivate?.()}
            />
            <Typography
              color={selectedComment.isPrivate ? 'GrayText' : ''}
              marginLeft={theme.spacing(0.1)}
            >
              Invite to private conversation
            </Typography>
          </Box>
        ) : null}

        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-end"
          spacing={theme.spacing(2)}
          sx={{ m: theme.spacing(3) }}
        >
          <Button
            disableRipple
            disableTouchRipple
            type="button"
            variant="text"
            onClick={() => (type === 'create' ? resetForm() : handleCancel?.())}
          >
            Cancel
          </Button>
          <LoadingButton
            disableRipple
            disableTouchRipple
            disabled={!(isValid && dirty)}
            loading={isLoading}
            sx={{ borderRadius: '20px' }}
            type="submit"
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Respond
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
