import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import InfiniteScroll from 'components/infiniteScroll/InfiniteScroll';
import Loader from 'components/loader/Loader';
import GuestReactionView from 'components/reactions/GuestReactionView';
import BusinessPostTile from 'components/tile/PostTile/BusinessPostTile';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IBusinessPost } from 'store';
import { fetchBusinessPosts, resetBusinessPostsState } from 'store/business/BusinessAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

function BusinessPosts() {
  const { businessId } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();
  const {
    businessWallDetails,
    businessPostsLoading,
    businessPostsState: { posts, hasMoreData, offset },
  } = useAppSelector(s => s.business);

  const getBusinessPosts = useCallback(
    (initialLoad: boolean) => {
      const params = {
        businessId: businessId || '',
        offset: initialLoad ? 0 : offset,
        limit: 10,
        reset: initialLoad,
      };
      dispatch(fetchBusinessPosts(params));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [businessId, offset],
  );

  useEffect(() => {
    return () => {
      dispatch(resetBusinessPostsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, businessId]);

  const navigateToBusinessPost = (contentId: string, isComment: boolean) => {
    if (isComment) {
      if (isUserLoggedIn()) {
        navigate(`/business/${contentId}`, { state: 'scroll-to-comments' });
      } else {
        setShowModal(true);
      }
    } else {
      navigate(`/business/${contentId}`);
    }
  };

  return (
    <>
      <InfiniteScroll
        loadOnMount
        hasMoreData={hasMoreData}
        isLoading={businessPostsLoading}
        onBottomHit={getBusinessPosts}
      >
        <Box>
          <Grid container item width="100%" xs={12}>
            {posts && posts.length > 0 ? (
              <>
                {posts.map((post: IBusinessPost, index: number) => (
                  <Fragment key={post.documentId + index.toString()}>
                    <Grid item xs={12}>
                      <BusinessPostTile
                        key={post?.documentId}
                        businessId={post?.businessId}
                        commentsCount={post?.commentsCount}
                        image={
                          post?.image?.croppedImageURL ||
                          post?.image?.croppedImageUrl ||
                          post?.image?.imageURL
                        }
                        imageProps={post?.image}
                        isLiked={post?.isLiked}
                        isReported={post?.isReported}
                        likesCount={post?.likesCount}
                        navigateToBusinessPost={(isComment: boolean) =>
                          navigateToBusinessPost(post?.documentId, isComment)
                        }
                        postedAt={post?.createdAt as string}
                        postId={post.documentId}
                        postType="business"
                        textContent={post?.textContent}
                        type="business-wall"
                        userId={post?.userId}
                        userImage={
                          businessWallDetails?.images?.logo?.croppedImageReadUrl ||
                          post?.user?.croppedImageReadUrl
                        }
                        userName={
                          businessWallDetails?.name || post?.user?.fullName || post?.user?.userName
                        }
                        withDivider={!(posts?.length === index + 1)}
                      />
                    </Grid>
                  </Fragment>
                ))}
              </>
            ) : (
              !businessPostsLoading && (
                <Typography component="h6" variant="h6Bold">
                  No posts available
                </Typography>
              )
            )}
            {businessPostsLoading && <Loader />}
          </Grid>
        </Box>
      </InfiniteScroll>
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </>
  );
}

export default BusinessPosts;
