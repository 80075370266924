import cloneDeep from 'clone-deep';
import { Reducer } from 'redux';
import { IBusiness, IProvider, ITimelinePost } from 'store';

import {
  FollowingTimelineActions,
  IFollowingTimelineState,
  IPaidUser,
  IPreferencePodcast,
} from './FollowingTimelineInterface';
import FollowingTimelineType from './FollowingTimelineTypes';

const initialBusinessState = {
  offset: 0,
  hasMoreData: false,
  businesses: [] as IBusiness[],
};

const initialSearchBusinessState = {
  query: '',
  page: 0,
  hasMoreData: false,
  businesses: [] as IBusiness[],
};

const initialPaidUsersState = {
  offset: 0,
  hasMoreData: false,
  paidUsers: [] as IPaidUser[],
};

const initialSearchPaidUserState = {
  query: '',
  page: 0,
  hasMoreData: false,
  paidUsers: [] as IPaidUser[],
};

export const initialState: IFollowingTimelineState = {
  followingPostsState: {
    offset: 0,
    hasMoreData: true,
    posts: [] as ITimelinePost[],
  },
  followingPostsLoading: false,

  allBusinessLoading: false,
  allBusinesses: { ...initialBusinessState },
  allNewsProvidersLoading: false,
  allNewsProviders: [] as IProvider[],
  allPodcastsLoading: false,
  allPodcasts: [] as IPreferencePodcast[],
  followPodcastsLoading: false,
  followBusinessesLoading: false,
  followNewsProvidersLoading: false,
  showPreferenceModal: false,
  isSearchBusinessLoading: false,
  searchedBusinesses: { ...initialSearchBusinessState },
  followedBusinesses: [],
  isFollowedBusinessLoading: false,

  allPaidUsersLoading: false,
  allPaidUsers: { ...initialPaidUsersState },
  isSearchPaidUsersLoading: false,
  searchedPaidUsers: { ...initialSearchPaidUserState },
  followedPaidUsers: [],
  isFollowedPaidUsersLoading: false,
  followPaidUsersLoading: false,
};

const FollowingTimelineReducer: Reducer<IFollowingTimelineState, FollowingTimelineActions> = (
  state = initialState,
  action = {} as FollowingTimelineActions,
) => {
  switch (action.type) {
    case FollowingTimelineType.RESET_FOLLOWING_TIMELINE_STATE: {
      return {
        ...state,
        followingPostsState: {
          offset: 0,
          hasMoreData: true,
          posts: [] as ITimelinePost[],
        },
      };
    }
    case FollowingTimelineType.RESET_FOLLOWING_PREFERENCES_STATE: {
      return {
        ...state,
        searchedBusinesses: { ...initialSearchBusinessState },
        allBusinesses: { ...initialBusinessState },
        allNewsProviders: [] as IProvider[],
        allPodcasts: [] as IPreferencePodcast[],
      };
    }

    case FollowingTimelineType.GET_FOLLOWING_POSTS_REQUEST: {
      return {
        ...state,
        followingPostsLoading: true,
      };
    }
    case FollowingTimelineType.GET_FOLLOWING_POSTS_SUCCESS: {
      return {
        ...state,
        followingPostsLoading: false,
        followingPostsState: {
          offset: action.payload.offset,
          hasMoreData: action.payload.hasMoreData,
          posts: [...state.followingPostsState.posts, ...action.payload.posts],
        },
      };
    }
    case FollowingTimelineType.GET_FOLLOWING_POSTS_ERROR: {
      return {
        ...state,
        followingPostsLoading: false,
      };
    }
    case FollowingTimelineType.FOLLOWING_LIKE_DISLIKE_POST_SUCCESS: {
      const selectedIndex = state.followingPostsState.posts.findIndex(
        (e: ITimelinePost) => e.postId === action.payload,
      );
      const posts = cloneDeep(state.followingPostsState.posts);
      if (selectedIndex !== -1) {
        const likeStatus = !posts[selectedIndex].isLiked;
        posts[selectedIndex].isLiked = likeStatus;
        posts[selectedIndex].likesCount = likeStatus
          ? posts[selectedIndex].likesCount + 1
          : posts[selectedIndex].likesCount - 1;
      }

      return {
        ...state,
        followingPostsState: {
          ...state.followingPostsState,
          posts,
        },
      };
    }
    case FollowingTimelineType.GET_ALL_BUSINESSES_REQUEST: {
      return {
        ...state,
        allBusinessLoading: true,
      };
    }
    case FollowingTimelineType.GET_ALL_BUSINESSES_SUCCESS: {
      return {
        ...state,
        allBusinessLoading: false,
        allBusinesses: {
          offset: action.payload.offset,
          hasMoreData: action.payload.hasMoreData,
          businesses: [...state.allBusinesses.businesses, ...action.payload.businesses],
        },
      };
    }
    case FollowingTimelineType.GET_ALL_BUSINESSES_ERROR: {
      return {
        ...state,
        allBusinessLoading: false,
      };
    }

    case FollowingTimelineType.GET_ALL_NEWS_PROVIDERS_REQUEST: {
      return {
        ...state,
        allNewsProvidersLoading: true,
      };
    }
    case FollowingTimelineType.GET_ALL_NEWS_PROVIDERS_SUCCESS: {
      return {
        ...state,
        allNewsProvidersLoading: false,
        allNewsProviders: action.payload,
      };
    }
    case FollowingTimelineType.GET_ALL_NEWS_PROVIDERS_ERROR: {
      return {
        ...state,
        allNewsProvidersLoading: false,
      };
    }

    case FollowingTimelineType.GET_ALL_PODCASTS_REQUEST: {
      return {
        ...state,
        allPodcastsLoading: true,
      };
    }
    case FollowingTimelineType.GET_ALL_PODCASTS_SUCCESS: {
      return {
        ...state,
        allPodcastsLoading: false,
        allPodcasts: action.payload,
      };
    }
    case FollowingTimelineType.GET_ALL_PODCASTS_ERROR: {
      return {
        ...state,
        allPodcastsLoading: false,
      };
    }
    case FollowingTimelineType.FOLLOW_BUSINESSES_REQUEST: {
      return {
        ...state,
        followBusinessesLoading: true,
      };
    }
    case FollowingTimelineType.FOLLOW_BUSINESSES_SUCCESS: {
      return {
        ...state,
        followBusinessesLoading: false,
        ...(!action.payload.fromSearch
          ? {
              allBusinesses: {
                ...state.allBusinesses,
                businesses: action.payload.businesses,
              },
            }
          : {
              searchedBusinesses: {
                ...state.searchedBusinesses,
                businesses: action.payload.businesses,
              },
            }),
      };
    }
    case FollowingTimelineType.FOLLOW_BUSINESSES_ERROR: {
      return {
        ...state,
        followBusinessesLoading: false,
      };
    }
    case FollowingTimelineType.FOLLOW_PODCASTS_REQUEST: {
      return {
        ...state,
        followPodcastsLoading: true,
      };
    }
    case FollowingTimelineType.FOLLOW_PODCASTS_SUCCESS: {
      return {
        ...state,
        followPodcastsLoading: false,
        allPodcasts: action.payload,
      };
    }
    case FollowingTimelineType.FOLLOW_PODCASTS_ERROR: {
      return {
        ...state,
        followPodcastsLoading: false,
      };
    }
    case FollowingTimelineType.FOLLOW_NEWS_PROVIDERS_REQUEST: {
      return {
        ...state,
        followNewsProvidersLoading: true,
      };
    }
    case FollowingTimelineType.FOLLOW_NEWS_PROVIDERS_SUCCESS: {
      return {
        ...state,
        followNewsProvidersLoading: false,
        allNewsProviders: action.payload,
      };
    }
    case FollowingTimelineType.FOLLOW_NEWS_PROVIDERS_ERROR: {
      return {
        ...state,
        followNewsProvidersLoading: false,
      };
    }
    case FollowingTimelineType.SEARCH_BUSINESSES_REQUEST: {
      return {
        ...state,
        isSearchBusinessLoading: true,
        searchedBusinesses: {
          ...state.searchedBusinesses,
          businesses:
            action.payload !== state.searchedBusinesses.query
              ? []
              : [...state.searchedBusinesses.businesses],
        },
      };
    }
    case FollowingTimelineType.SEARCH_BUSINESSES_SUCCESS: {
      return {
        ...state,
        isSearchBusinessLoading: false,
        searchedBusinesses: {
          query: action.payload.query,
          page: action.payload.page,
          hasMoreData: action.payload.hasMoreData,
          businesses:
            action.payload.query !== state.searchedBusinesses.query
              ? [...action.payload.businesses]
              : [...state.searchedBusinesses.businesses, ...action.payload.businesses],
        },
      };
    }
    case FollowingTimelineType.SEARCH_BUSINESSES_ERROR: {
      return {
        ...state,
        isSearchBusinessLoading: false,
      };
    }
    case FollowingTimelineType.RESET_SEARCH_STATE: {
      return {
        ...state,
        searchedBusinesses: { ...initialSearchBusinessState },
      };
    }
    case FollowingTimelineType.RESET_ALL_BUSINESSES_STATE: {
      return {
        ...state,
        allBusinesses: { ...initialBusinessState },
      };
    }
    case FollowingTimelineType.GET_ALL_FOLLOWED_BUSINESSES_REQUEST: {
      return {
        ...state,
        isFollowedBusinessLoading: true,
      };
    }
    case FollowingTimelineType.GET_ALL_FOLLOWED_BUSINESSES_SUCCESS: {
      return {
        ...state,
        isFollowedBusinessLoading: false,
        followedBusinesses: action.payload,
      };
    }
    case FollowingTimelineType.GET_ALL_FOLLOWED_BUSINESSES_ERROR: {
      return {
        ...state,
        isFollowedBusinessLoading: false,
      };
    }

    case FollowingTimelineType.GET_ALL_PAID_USERS_REQUEST: {
      return {
        ...state,
        allPaidUsersLoading: true,
      };
    }
    case FollowingTimelineType.GET_ALL_PAID_USERS_SUCCESS: {
      return {
        ...state,
        allPaidUsersLoading: false,
        allPaidUsers: {
          offset: action.payload.offset,
          hasMoreData: action.payload.hasMoreData,
          paidUsers: [...state.allPaidUsers.paidUsers, ...action.payload.paidUsers],
        },
      };
    }
    case FollowingTimelineType.GET_ALL_PAID_USERS_ERROR: {
      return {
        ...state,
        allPaidUsersLoading: false,
      };
    }
    case FollowingTimelineType.FOLLOW_PAID_USERS_REQUEST: {
      return {
        ...state,
        followPaidUsersLoading: true,
      };
    }
    case FollowingTimelineType.FOLLOW_PAID_USERS_SUCCESS: {
      return {
        ...state,
        followPaidUsersLoading: false,
        ...(!action.payload.fromSearch
          ? {
              allPaidUsers: {
                ...state.allPaidUsers,
                paidUsers: action.payload.paidUsers,
              },
            }
          : {
              searchedBusinesses: {
                ...state.searchedBusinesses,
                paidUsers: action.payload.paidUsers,
              },
            }),
      };
    }
    case FollowingTimelineType.FOLLOW_PAID_USERS_ERROR: {
      return {
        ...state,
        followPaidUsersLoading: false,
      };
    }
    case FollowingTimelineType.SEARCH_PAID_USERS_REQUEST: {
      return {
        ...state,
        isSearchPaidUsersLoading: true,
        searchedPaidUsers: {
          ...state.searchedPaidUsers,
          paidUsers:
            action.payload !== state.searchedPaidUsers.query
              ? []
              : [...state.searchedPaidUsers.paidUsers],
        },
      };
    }
    case FollowingTimelineType.SEARCH_PAID_USERS_SUCCESS: {
      return {
        ...state,
        isSearchPaidUsersLoading: false,
        searchedPaidUsers: {
          query: action.payload.query,
          page: action.payload.page,
          hasMoreData: action.payload.hasMoreData,
          paidUsers:
            action.payload.query !== state.searchedPaidUsers.query
              ? [...action.payload.paidUsers]
              : [...state.searchedPaidUsers.paidUsers, ...action.payload.paidUsers],
        },
      };
    }
    case FollowingTimelineType.SEARCH_PAID_USERS_ERROR: {
      return {
        ...state,
        isSearchPaidUsersLoading: false,
      };
    }
    case FollowingTimelineType.RESET_SEARCH_PAID_USERS_STATE: {
      return {
        ...state,
        searchedPaidUsers: { ...initialSearchPaidUserState },
      };
    }
    case FollowingTimelineType.RESET_ALL_PAID_USERS_STATE: {
      return {
        ...state,
        allPaidUsers: { ...initialPaidUsersState },
      };
    }
    case FollowingTimelineType.GET_ALL_FOLLOWED_PAID_USERS_REQUEST: {
      return {
        ...state,
        isFollowedPaidUsersLoading: true,
      };
    }
    case FollowingTimelineType.GET_ALL_FOLLOWED_PAID_USERS_SUCCESS: {
      return {
        ...state,
        isFollowedPaidUsersLoading: false,
        followedPaidUsers: action.payload,
      };
    }
    case FollowingTimelineType.GET_ALL_FOLLOWED_PAID_USERS_ERROR: {
      return {
        ...state,
        isFollowedPaidUsersLoading: false,
      };
    }
    default:
      return state;
  }
};

export default FollowingTimelineReducer;
