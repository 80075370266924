import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/system/Stack';
import AuthWrapper from 'components/wrapper/AuthWrapper';
import React, { useEffect } from 'react';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

import AffiliatePaymentReceiveForm from './AffiliatePaymentReceiveForm';

function AffiliatePayment() {
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <AuthWrapper>
      <Stack spacing={theme.spacing(4)} width="100%">
        <Stack spacing={theme.spacing(3)}>
          <Typography
            align="center"
            color="text.secondary"
            component="div"
            variant={isMobile ? 'h5' : 'h2'}
          >
            Freedom Square Affiliate Program
          </Typography>
          <Typography align="center" color="text.secondary" component="div" variant="title2">
            Direct Deposit Form
          </Typography>
        </Stack>
        <AffiliatePaymentReceiveForm />
      </Stack>
    </AuthWrapper>
  );
}

export default AffiliatePayment;
