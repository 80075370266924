import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomSwitch from 'components/customFields/CustomSwitch';
import Loader from 'components/loader/Loader';
import { StyledTableCell, StyledTableRow } from 'components/table/StyledTable';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { getAffiliatesListFile, IAffiliatePayoutData } from 'store';
import {
  createPayout,
  deletePayout,
  listAffiliatesPayout,
  resetAffiliatesPayoutState,
} from 'store/admin/vettingDashboard/VettingDashboardActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { downloadCsv } from 'utils/utils';

import DateRange, { IFilters } from './DateRange';

interface ILoadingState {
  [id: string]: boolean;
}

function AffiliatePayoutReport() {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const [csvLoader, showCsvLoader] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilters>();

  const { affiliatesPayoutData, isListAffiliatesLoading } = useAppSelector(s => s.vettingDashboard);
  const [isLoading, setIsLoading] = useState<ILoadingState>({});

  useEffect(() => {
    return () => {
      dispatch(resetAffiliatesPayoutState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const payoutReportDateRange = useMemo(() => {
    let dateFrom = '';
    let dateTo = '';
    if (
      (filters?.range?.from as string)?.length > 0 &&
      (filters?.range?.to as string)?.length > 0
    ) {
      dateFrom = filters?.range.from as string;
      dateTo = filters?.range.to as string;
    } else {
      const currentMonth = moment().subtract(1, 'months').format('YYYY-MM-DD');
      dateFrom = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
      dateTo = moment(currentMonth).endOf('month').format('YYYY-MM-DD');
    }
    return { dateFrom, dateTo };
  }, [filters?.range]);

  const fetchAffiliatesPayout = (initialLoad: boolean) => {
    const payoutRange = payoutReportDateRange;
    const currentMonth = moment().subtract(1, 'months').format('YYYY-MM-DD');
    const dateFrom = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
    const dateTo = moment(currentMonth).endOf('month').format('YYYY-MM-DD');
    dispatch(
      listAffiliatesPayout(
        initialLoad ? 0 : affiliatesPayoutData.offset,
        initialLoad ? dateFrom : payoutRange.dateFrom,
        initialLoad ? dateTo : payoutRange.dateTo,
      ),
    );
  };

  const handleCreatePayout = (index: number, affiliate: IAffiliatePayoutData) => {
    setIsLoading(prev => ({ ...prev, [affiliate.userInfo.userId]: true }));
    if (affiliate.type !== 'paid') {
      dispatch(createPayout(index, affiliate.userInfo.userId)).then(() => {
        setIsLoading(prev => ({
          ...prev,
          [affiliate.userInfo.userId]: false,
        }));
      });
    } else {
      dispatch(deletePayout(affiliate.documentId, index)).then(() => {
        setIsLoading(prev => ({
          ...prev,
          [affiliate.userInfo.userId]: false,
        }));
      });
    }
  };

  const downloadAsCSV = async ({ range }: IFilters) => {
    showCsvLoader(true);
    const fileType = 'payouts';
    const csvData = await getAffiliatesListFile(range.from, range.to);

    if (csvData && fileType) {
      downloadCsv(csvData, fileType);
      showCsvLoader(false);
    }
  };

  // const isDownloadBtnDisabled=affiliatesPayoutData?.affiliatesPayout?.length === 0 || csvLoader;

  return (
    <>
      <Box paddingBottom={2}>
        <DateRange
          isDisabled
          isDownloadCSV
          isPayoutTab
          showDateRange
          csvLoader={csvLoader}
          showSearch={false}
          onChangeFilter={setFilters}
          onDownloadCSV={downloadAsCSV}
        />
      </Box>
      <ComponentWrapper
        withInfiniteScroll
        borderColor="light"
        infiniteScrollProps={{
          loadOnMount: true,
          hasMoreData: affiliatesPayoutData.hasMoreData,
          isLoading: isListAffiliatesLoading,
          onBottomHit: fetchAffiliatesPayout,
          valueToBeAddedToInnerHeight: 50,
        }}
        title=""
        withPadding={false}
      >
        <Stack
          alignItems="center"
          borderBottom={`0.25px solid ${theme.palette.divider}`}
          direction="row"
          padding={isMobile ? theme.spacing(2) : theme.spacing(3.65, 4, 3)}
          spacing={theme.spacing(1.5)}
        >
          <Typography color="text.secondary" variant={isMobile ? 'title2' : 'title1'}>
            Payouts
          </Typography>
        </Stack>
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Affiliate Name</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Number of Conversions</StyledTableCell>
                <StyledTableCell>Payout Amount</StyledTableCell>
                <StyledTableCell>Paid Indicator</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {affiliatesPayoutData.affiliatesPayout.map(
                (affiliate: IAffiliatePayoutData, index: number) => (
                  <StyledTableRow key={`${affiliate.userInfo.userId}_${index.toString()}`}>
                    <StyledTableCell>{affiliate.userInfo.userName}</StyledTableCell>
                    <StyledTableCell>{affiliate.userInfo.email}</StyledTableCell>
                    <StyledTableCell>{affiliate.conversions}</StyledTableCell>
                    <StyledTableCell>{affiliate.amountCredits}</StyledTableCell>
                    <StyledTableCell>
                      <CustomSwitch
                        checked={affiliate.type === 'paid'}
                        // disabled={isLoading[affiliate.userInfo.userId]}
                        id="affiliate-payout-indicator"
                        inputProps={{
                          'aria-label': 'Affiliate-Payout-Indicator',
                        }}
                        name="affiliate-payout-indicator"
                        // sx={{
                        //   ...(affiliate.type === "unpaid" && {
                        //     "&.MuiSwitch-root": {
                        //       outline: `1px solid ${theme.palette.common.white}`,
                        //       borderRadius: "13px",
                        //     },
                        //   }),
                        //   pointerEvents:
                        //     affiliate.type === "paid" ? "none" : "auto",
                        // }}
                        onClick={() => handleCreatePayout(index, affiliate)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isListAffiliatesLoading && (
          <Box boxSizing="border-box" padding={theme.spacing(2, 0)} width="100%">
            <Loader />
          </Box>
        )}
        {!isListAffiliatesLoading && affiliatesPayoutData.affiliatesPayout.length === 0 && (
          <Box boxSizing="border-box" padding={theme.spacing(4)} width="100%">
            <Typography align="center">No records found</Typography>
          </Box>
        )}
      </ComponentWrapper>
    </>
  );
}

export default AffiliatePayoutReport;
