import Stack from '@mui/material/Stack';
import styles from 'theme/styles';

export default function CoreValues() {
  return (
    <Stack sx={styles.tncStyles}>
      <div>
        <h3>
          <b>Freedom Square Core Values</b>
        </h3>
      </div>
      <ul>
        <li>
          <p>
            <u>
              <b>Patriotism:</b>
            </u>
            &nbsp; Viewing all decisions and issues through a lens of what is best for America, with
            an emphasis on adhering to our founding documents, traditional American values and
            maintaining a strong sense of National Pride.
          </p>
        </li>
        <li>
          <p>
            <u>
              <b>Freedom:</b>
            </u>
            &nbsp; We will remain free of constraint in our choices, words and actions. We will
            celebrate the ability to exercise our 1st amendment rights.
          </p>
        </li>
        <li>
          <p>
            <u>
              <b>Integrity:</b>
            </u>
            &nbsp; All operations will be ethical and will be conducted with reverence to
            traditional American values.
          </p>
        </li>
        <li>
          <p>
            <u>
              <b>Progress:</b>
            </u>
            &nbsp; Our goal is to come together and steer progress back in a positive direction for
            America, through networking, connecting and supporting American values.
          </p>
        </li>
      </ul>
    </Stack>
  );
}
