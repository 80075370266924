import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomInputField from 'components/customFields/CustomInputField';
import Loader from 'components/loader/Loader';
import { FormikErrors, FormikTouched } from 'formik';
import { BusinessComponentType, IBusinessDetailsState } from 'store/business/BusinessInterface';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';
import { handleAllowOnlyOneSpaceBetweenWords } from 'utils/utils';

const { SETUP } = BusinessComponentType;

interface IBusinessDetailsProps {
  touched?: FormikTouched<IBusinessDetailsState>;
  values: IBusinessDetailsState;
  errors?: FormikErrors<IBusinessDetailsState>;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T1 = string | React.ChangeEvent<any>>(field: T1): T1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: (e: React.FocusEvent) => void;
  componentType?: string;
}

export default function BusinessDetails(props: IBusinessDetailsProps) {
  const { handleBlur, handleChange, errors, touched, values, componentType } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const { isGetBusinessDetailsLoading } = useAppSelector(s => s.business);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
  ) => handleChange(fieldName)(handleAllowOnlyOneSpaceBetweenWords(e.target.value));

  return (
    <Grid container rowSpacing={theme.spacing(2.5)}>
      <Grid item xs={12}>
        <Typography
          align={componentType === SETUP ? 'center' : 'left'}
          variant={isMobile ? 'h5' : componentType === SETUP ? 'h4' : 'h3'}
        >
          Business Details
        </Typography>
        {componentType === SETUP && (
          <Typography
            align="center"
            component="div"
            fontWeight={400}
            marginTop={theme.spacing(1.5)}
            variant="title2"
          >
            Set the tagline and description for your business profile.
          </Typography>
        )}
      </Grid>
      {isGetBusinessDetailsLoading ? (
        <Loader />
      ) : (
        <Grid item xs={12}>
          <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(2)}>
            <CustomInputField
              fullWidth
              required
              error={!!(touched?.tagline && errors?.tagline)}
              errormessage={touched?.tagline ? errors?.tagline : undefined}
              id="tagline"
              label="Tagline"
              name="businessDetails.tagline"
              type="text"
              value={values.tagline}
              onBlur={handleBlur}
              onChange={e => handleInputChange(e, 'businessDetails.tagline')}
            />
            <CustomInputField
              fullWidth
              multiline
              required
              error={!!(touched?.description && errors?.description)}
              errormessage={touched?.description ? errors?.description : undefined}
              id="description"
              label="Description"
              name="businessDetails.description"
              rows={10}
              type="textarea"
              value={values.description}
              onBlur={handleBlur}
              onChange={e => handleInputChange(e, 'businessDetails.description')}
            />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}
