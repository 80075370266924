import Stack from '@mui/material/Stack';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { useEffect } from 'react';
import { resetBusinessDirectoryState } from 'store';
import { useAppDispatch } from 'store/hooks';
import theme from 'theme';

import RecentBusinesses from '../RecentBusinesses';

function RecentBusinessContainer() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetBusinessDirectoryState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomeWrapper maxWidth="midmd">
      <Stack spacing={theme.spacing(5)} width="100%">
        <RecentBusinesses scrollPagination />
      </Stack>
    </HomeWrapper>
  );
}

export default RecentBusinessContainer;
