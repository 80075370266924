import cloneDeep from 'clone-deep';
import { Reducer } from 'redux';

import {
  HomeTimelineActions,
  IHeadlinesState,
  IHomeTimelineState,
  ITimelinePost,
} from './HomeTimelineInterface';
import HomeTimelineType from './HomeTimelineTypes';

const initialHealinesState: IHeadlinesState = {
  posts: [],
  currentCount: 0,
};

export const initialState: IHomeTimelineState = {
  isGetFeaturedPostsLoading: false,
  townCrierLoading: false,
  isGetHeadlinesPostsLoading: false,
  featuredPosts: [] as ITimelinePost[],
  townCrierPost: {} as ITimelinePost,
  headlines: initialHealinesState,
};

const HomeTimelineReducer: Reducer<IHomeTimelineState, HomeTimelineActions> = (
  state = initialState,
  action = {} as HomeTimelineActions,
) => {
  switch (action.type) {
    case HomeTimelineType.RESET_HOME_TIMELINE_STATE: {
      return {
        ...state,
        featuredPosts: [],
        headlines: initialHealinesState,
      };
    }
    case HomeTimelineType.GET_TIMELINE_FEATURED_POSTS_REQUEST: {
      return {
        ...state,
        isGetFeaturedPostsLoading: true,
      };
    }
    case HomeTimelineType.GET_TIMELINE_FEATURED_POSTS_SUCCESS: {
      return {
        ...state,
        isGetFeaturedPostsLoading: false,
        featuredPosts: action.payload,
      };
    }
    case HomeTimelineType.GET_TIMELINE_FEATURED_POSTS_ERROR: {
      return {
        ...state,
        isGetFeaturedPostsLoading: false,
      };
    }
    case HomeTimelineType.GET_TIMELINE_HEADLINES_POSTS_REQUEST: {
      return {
        ...state,
        isGetHeadlinesPostsLoading: true,
      };
    }
    case HomeTimelineType.GET_TIMELINE_HEADLINES_POSTS_SUCCESS: {
      return {
        ...state,
        isGetHeadlinesPostsLoading: false,
        headlines: {
          posts: [...state.headlines.posts, ...action.payload.posts],
          currentCount: action.payload.currentCount,
        },
      };
    }
    case HomeTimelineType.GET_TIMELINE_HEADLINES_POSTS_ERROR: {
      return {
        ...state,
        isGetHeadlinesPostsLoading: false,
      };
    }

    case HomeTimelineType.GET_TOWN_CRIER_POST_REQUEST: {
      return {
        ...state,
        townCrierLoading: true,
      };
    }
    case HomeTimelineType.GET_TOWN_CRIER_POST_SUCCESS: {
      return {
        ...state,
        townCrierLoading: false,
        townCrierPost: action.payload,
      };
    }
    case HomeTimelineType.GET_TOWN_CRIER_POST_ERROR: {
      return {
        ...state,
        townCrierLoading: false,
      };
    }

    case HomeTimelineType.FEATURED_LIKE_DISLIKE_POST_SUCCESS: {
      const selectedIndex = state.featuredPosts.findIndex(
        (e: ITimelinePost) => e.postId === action.payload,
      );
      const featuredPosts = cloneDeep(state.featuredPosts);
      if (selectedIndex !== -1) {
        const likeStatus = !featuredPosts[selectedIndex].isLiked;
        featuredPosts[selectedIndex].isLiked = likeStatus;
        featuredPosts[selectedIndex].likesCount = likeStatus
          ? featuredPosts[selectedIndex].likesCount + 1
          : featuredPosts[selectedIndex].likesCount - 1;
      }

      return {
        ...state,
        featuredPosts,
      };
    }
    case HomeTimelineType.ALL_POST_LIKE_DISLIKE_POST_SUCCESS: {
      const selectedIndex = state.headlines.posts.findIndex(
        (e: ITimelinePost) => e.postId === action.payload,
      );
      const posts = cloneDeep(state.headlines.posts);
      if (selectedIndex !== -1) {
        const likeStatus = !posts[selectedIndex].isLiked;
        posts[selectedIndex].isLiked = likeStatus;
        posts[selectedIndex].likesCount = likeStatus
          ? posts[selectedIndex].likesCount + 1
          : posts[selectedIndex].likesCount - 1;
      }

      return {
        ...state,
        headlines: {
          ...state.headlines,
          posts,
        },
      };
    }

    case HomeTimelineType.TOWN_CRIER_POST_LIKE_DISLIKE_POST_SUCCESS: {
      const post = cloneDeep(state.townCrierPost);

      const isLiked = !post.isLiked;
      const likesCount = isLiked ? post.likesCount + 1 : post.likesCount - 1;

      return {
        ...state,
        townCrierPost: {
          ...state.townCrierPost,
          isLiked,
          likesCount,
        },
      };
    }

    //
    default:
      return state;
  }
};

export default HomeTimelineReducer;
