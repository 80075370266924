import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ConfirmationModal from 'components/confirmationModal/ConfirmationModal';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import Loader from 'components/loader/Loader';
import { StyledTableCell, StyledTableRow } from 'components/table/StyledTable';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import { useEffect, useState } from 'react';
import { ISponsor, ISponsorAd } from 'store';
import {
  deleteSponsorAdCoupons,
  fetchAllSponsorsReportBySponsorId,
  resetSponsorAdsState,
} from 'store/admin/vettingDashboard/VettingDashboardActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

import { SPONSORS_TYPE_OPTIONS, SponsorsType } from '../AllSponsorsReport';
import AddAds from './AddAds';

interface IAllSponsorsReportProps {
  selectedSponsor: ISponsor;
  setAddModal: () => void;
  setTitle: (type: string) => void;
}

function AllSponsorsReport({ selectedSponsor, setAddModal, setTitle }: IAllSponsorsReportProps) {
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [selectedAd, setSelectedAd] = useState({} as ISponsorAd);
  const [showEditAd, setShowEditAd] = useState(false);
  const [selectedSponsorType, setSelectedSponsorType] = useState<SponsorsType>(
    SPONSORS_TYPE_OPTIONS[0].value,
  );

  const { sponsorAds, getAllSponsorsReportLoading, deleteSponsorAdLoading } = useAppSelector(
    s => s.vettingDashboard,
  );

  useEffect(() => {
    if (selectedSponsor) {
      getAllAllSponsorsReportBySponsorId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSponsor]);

  useEffect(() => {
    return () => {
      dispatch(resetSponsorAdsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllAllSponsorsReportBySponsorId = (type?: string) => {
    dispatch(
      fetchAllSponsorsReportBySponsorId(selectedSponsor?.documentId, type || selectedSponsorType),
    );
  };

  const handleDeleteSponsorAdCoupons = () => {
    dispatch(deleteSponsorAdCoupons(selectedAd.documentId, selectedSponsorType)).then(
      (response: any) => {
        if (!response?.error) {
          setShowDeleteConfirmationModal(false);
          getAllAllSponsorsReportBySponsorId();
        }
      },
    );
  };

  const onCreateAd = () => {
    setShowEditAd(false);
    dispatch(resetSponsorAdsState());
    getAllAllSponsorsReportBySponsorId();
  };

  const handleTypeChange = (type: SponsorsType) => {
    dispatch(resetSponsorAdsState());
    setSelectedSponsorType(type);
    getAllAllSponsorsReportBySponsorId(type);
  };

  return (
    <ComponentWrapper borderColor="light" title="" withPadding={false}>
      <Stack
        borderBottom={`0.25px solid ${theme.palette.divider}`}
        padding={isMobile ? theme.spacing(2) : theme.spacing(3.65, 4, 3)}
        spacing={theme.spacing(1.5)}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography color="text.secondary" variant={isMobile ? 'title2' : 'title1'}>
            Sponsor Detail: {selectedSponsor.name}
          </Typography>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              setAddModal();
              setTitle(selectedSponsorType);
            }}
          >
            +ADD {selectedSponsorType}
          </Button>
        </Stack>

        <Stack direction="row" spacing={theme.spacing(4)}>
          {SPONSORS_TYPE_OPTIONS.map(type => (
            <Typography
              key={type.label}
              sx={{
                cursor: 'pointer',
                textTransform: 'capitalize',
                ...(selectedSponsorType === type.value && {
                  textDecoration: 'underline',
                  textUnderlineOffset: '4px',
                }),
                '&:hover': {
                  textDecoration: 'underline',
                  textUnderlineOffset: '4px',
                },
              }}
              variant="title2"
              onClick={() => handleTypeChange(type.value)}
            >
              {type.label}
            </Typography>
          ))}
        </Stack>
      </Stack>
      <TableContainer sx={{ overflowX: 'auto' }}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Location</StyledTableCell>
              <StyledTableCell>Start Date</StyledTableCell>
              <StyledTableCell>End Date</StyledTableCell>
              {selectedSponsorType === 'ad' ? (
                <StyledTableCell>Ad Url</StyledTableCell>
              ) : (
                <StyledTableCell>Coupon Url</StyledTableCell>
              )}
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sponsorAds.map((sponsorAd: ISponsorAd) => (
              <StyledTableRow key={sponsorAd.documentId}>
                <StyledTableCell>
                  {sponsorAd.page}-{sponsorAd.position}
                </StyledTableCell>
                <StyledTableCell>{sponsorAd.startdate}</StyledTableCell>
                <StyledTableCell>{sponsorAd.enddate}</StyledTableCell>
                <StyledTableCell>{sponsorAd.url}</StyledTableCell>

                <StyledTableCell align="center">
                  <Stack direction="row" spacing={theme.spacing(2)}>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        setSelectedAd(sponsorAd);
                        setShowEditAd(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        setSelectedAd(sponsorAd);
                        setShowDeleteConfirmationModal(true);
                      }}
                    >
                      Delete
                    </Button>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {getAllSponsorsReportLoading && (
        <Box boxSizing="border-box" padding={theme.spacing(2, 0)} width="100%">
          <Loader />
        </Box>
      )}
      {!getAllSponsorsReportLoading && sponsorAds.length === 0 && (
        <Box boxSizing="border-box" padding={theme.spacing(4)} width="100%">
          <Typography align="center">No records found</Typography>
        </Box>
      )}

      <ToggleDrawerDialog
        showCloseIcon
        handleClose={() => setShowDeleteConfirmationModal(false)}
        isMobile={isMobile}
        open={showDeleteConfirmationModal}
        setOpen={setShowDeleteConfirmationModal}
        title={`Delete ${selectedSponsorType === 'ad' ? 'Ad' : 'Coupon'}`}
      >
        <ConfirmationModal
          loading={deleteSponsorAdLoading}
          modalText={`Are you sure you want to delete ${selectedAd.page}-${selectedAd.position}?`}
          submitBtnText="Confirm"
          onClose={() => setShowDeleteConfirmationModal(false)}
          onSubmit={handleDeleteSponsorAdCoupons}
        />
      </ToggleDrawerDialog>

      {showEditAd && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowEditAd(false)}
          isMobile={isMobile}
          open={showEditAd}
          setOpen={setShowEditAd}
          title={`Edit ${selectedSponsorType === 'ad' ? 'Ad' : 'Coupon'}`}
        >
          <AddAds
            closeModal={() => setShowEditAd(false)}
            sponsorAd={selectedAd}
            sponsorId={selectedSponsor.documentId}
            sponsorType={selectedSponsorType}
            onCreate={onCreateAd}
          />
        </ToggleDrawerDialog>
      )}
    </ComponentWrapper>
  );
}

export default AllSponsorsReport;
