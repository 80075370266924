const browserPath: any = {
  '/home': 'Home Timeline',
  '/following': 'Following Timeline',
  '/article': 'News Article',
  '/news-provider-wall': 'News Wall',
  '/news': 'News Timeline',
  '/podcasts': 'Podcast Timeline',
  '/podcast-show': 'Podcast Wall',
  '/podcast': 'Podcast Episode',
  '/businesses': 'Business Network',
  '/business-wall': 'Business Wall',
  '/business-setup': 'Business Setup',
  '/business-plan': 'Business Plans',
  '/profile-setup': 'Profile Setup',
  '/profile-wall': 'Profile Wall',
  '/post': 'Profile Post',
  '/resources': 'Resources',
  '/about': 'About',
  '/search': 'Search Results',
  '/account/profile': 'Account',
  '/account/payment': 'Manage Payment',
  '/account/business-information': 'Edit Business Info',
  '/account/business-details': 'Edit Business Details',
  '/account/business-photos': 'Edit Business Media',
  '/account/terms': 'Terms of Use',
  '/account/privacy-terms': 'Private Policy',
  '/account/coreValues': 'Core Values',
  '/privacy-policy': 'Private Policy',
  '/terms-and-conditions': 'Terms of Use',
  '/core-values': 'Core Values',
  '/trending': 'Trending',
  '/login': 'Login',
  '/signup': 'Signup',
  '/forgot-password': 'Forgot Password',
  '/activate-account': 'Activate User Account',
  '/activate-business': 'Activate Business Account',
  '/reset-password': 'Reset Password',
  '/verification-mail': 'Email Verification',
  '/forgot-password-success': 'Forgot Password Success',
  '/delete-account': 'Delete Account',
  '/set-username': 'Set Username',
  '/affiliate-payment': 'Affiliate Payment Account',
  '/affiliate': 'Affiliate',
  '/change-email': 'Change Email',
  '/business': 'Business Post',
};

export default browserPath;
