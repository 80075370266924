import client from 'Api';
import { paymentUrl } from 'helpers/urls';
import { Dispatch } from 'react';
import { AlertActions, AlertType, IAlertPayload, IResponseData } from 'store';

import { ICardData, ICreateCardRequestData, PaymentActions } from './PaymentInterface';
import PaymentType from './PaymentTypes';

export const showCardForm = (showForm: boolean, formType: number | undefined) => {
  return async (dispatch: Dispatch<PaymentActions>) => {
    dispatch({
      type: PaymentType.SHOW_CARD_FORM,
      payload: { showForm, formType },
    });
  };
};

export const getCards = () => {
  return async (dispatch: Dispatch<PaymentActions | AlertActions>) => {
    try {
      dispatch({ type: PaymentType.GET_CARDS_REQUEST });
      const response: IResponseData = await client.get(`${paymentUrl}creditCards`);
      dispatch({ type: PaymentType.GET_CARDS_SUCCESS, payload: response.data });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: PaymentType.GET_CARDS_ERROR });
      return error;
    }
  };
};

export const addCard = (cardData: ICreateCardRequestData) => {
  return async (dispatch: Dispatch<PaymentActions | AlertActions>) => {
    try {
      dispatch({ type: PaymentType.ADD_CARD_REQUEST });
      const response: IResponseData = await client.post(`${paymentUrl}creditCards`, {
        ...cardData,
      });
      dispatch({
        type: PaymentType.ADD_CARD_SUCCESS,
        payload: response.data as ICardData,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: PaymentType.ADD_CARD_ERROR });
      return error;
    }
  };
};

export const setPreviewCardIndex = (index: number) => {
  return async (dispatch: Dispatch<PaymentActions>) => {
    dispatch({ type: PaymentType.SET_PREVIEW_CARD_INDEX, payload: index });
  };
};

export const editCard = (cardData: ICreateCardRequestData, cardID: string) => {
  return async (dispatch: Dispatch<PaymentActions | AlertActions>) => {
    try {
      dispatch({ type: PaymentType.EDIT_CARD_REQUEST });
      const response: IResponseData = await client.put(`${paymentUrl}creditCards/${cardID}`, {
        ...cardData,
      });
      dispatch({
        type: PaymentType.EDIT_CARD_SUCCESS,
        payload: response.data as ICardData,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: PaymentType.EDIT_CARD_ERROR });
      return error;
    }
  };
};

export const deleteCard = (cardID: string) => {
  return async (dispatch: Dispatch<PaymentActions | AlertActions>) => {
    try {
      dispatch({ type: PaymentType.DELETE_CARD_REQUEST });
      const response: IResponseData = await client.delete(`${paymentUrl}creditCards/${cardID}`);
      dispatch({
        type: PaymentType.DELETE_CARD_SUCCESS,
        payload: cardID,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: PaymentType.DELETE_CARD_ERROR });
      return error;
    }
  };
};

export const resetPaymentState = () => {
  return async (dispatch: Dispatch<PaymentActions>) => {
    dispatch({ type: PaymentType.RESET_PAYMENT_STATE });
  };
};
