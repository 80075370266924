import { Dispatch } from 'redux';
import { AlertActions, AlertType, IAlertPayload } from 'store';

import client from '../../../Api';
import {
  affiliateUrl,
  authUrl,
  businessUrl,
  newsUrl,
  podcastUrl,
  sponsorUrl,
} from '../../../helpers/urls';
import { IResponseData } from '../../common/CommonInterface';
import {
  IAddPodcastProviderState,
  IAddSponsorState,
  IAffiliateData,
  IAffiliatePayoutData,
  IAllSponsorsReportState,
  IApprovedBusiness,
  IApprovedBusinessesState,
  IDeleteAccountParams,
  IGetBusinessDownloadCSVParams,
  IGetBusinessParams,
  IGetMembersParams,
  IInfluencer,
  IListAffiliatesPayoutPayload,
  IListAffiliatesState,
  IMembers,
  IMembersState,
  ISponsor,
  ISponsorAd,
  ISponsorsListPayload,
  IUnApprovedBusinessesState,
  VettingDashboardActions,
} from './VettingDashboardInterface';
import VettingDashboardType from './VettingDashboardTypes';

const PER_PAGE = 10;

const multiPartFormHeader = {
  headers: { 'Content-Type': 'multipart/form-data' },
};

export const resetVettingDashboardState = () => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    dispatch({ type: VettingDashboardType.RESET_DASHBOARD_STATE });
  };
};

export const getUnApprovedBusinesses = (offset: number) => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    try {
      dispatch({
        type: VettingDashboardType.GET_UNAPPROVED_BUSINESSES_REQUEST,
      });
      const response: IResponseData = await client.get(
        `${businessUrl}admin/unapprovedBusiness?offset=${offset}&limit=10`,
      );

      const payload: IUnApprovedBusinessesState = {
        unApprovedBusinesses: response?.data,
        offset: offset + 10,
        hasMoreData: response?.data?.length === 10,
      };

      dispatch({
        type: VettingDashboardType.GET_UNAPPROVED_BUSINESSES_SUCCESS,
        payload,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: VettingDashboardType.GET_UNAPPROVED_BUSINESSES_ERROR });
      return error;
    }
  };
};

export const getBusinessesDetailsByBusinessId = (businessId: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    try {
      dispatch({ type: VettingDashboardType.GET_BUSINESS_DETAILS_REQUEST });
      const response: IResponseData = await client.get(`${businessUrl}business/${businessId}`);
      dispatch({
        type: VettingDashboardType.GET_BUSINESS_DETAILS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: VettingDashboardType.GET_BUSINESS_DETAILS_ERROR });
      return error;
    }
  };
};

export const approveBusiness = (businessId: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.APPROVE_BUSINESS_REQUEST });
      const response: IResponseData = await client.post(
        `${businessUrl}business/${businessId}/approve`,
      );
      dispatch({
        type: VettingDashboardType.APPROVE_BUSINESS_SUCCESS,
        payload: businessId,
      });
      const successPayload: IAlertPayload = {
        message: 'Successfully approved Business',
      };
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: successPayload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: VettingDashboardType.APPROVE_BUSINESS_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const denyBusiness = (businessId: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.DENY_BUSINESS_REQUEST });
      const response: IResponseData = await client.delete(
        `${businessUrl}admin/businesses/${businessId}/deactivate`,
      );
      dispatch({
        type: VettingDashboardType.DENY_BUSINESS_SUCCESS,
        payload: businessId,
      });
      const successPayload: IAlertPayload = {
        message: 'Successfully de-activated business',
      };
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: successPayload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: VettingDashboardType.DENY_BUSINESS_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const deleteBusinessByAdmin = (businessId: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.DELETE_BUSINESS_ADMIN_REQUEST });
      const response: IResponseData = await client.delete(`${businessUrl}business/${businessId}`);

      dispatch({
        type: VettingDashboardType.DELETE_BUSINESS_ADMIN_SUCCESS,
        payload: businessId,
      });

      const successPayload: IAlertPayload = { message: 'Business removed successfully' };

      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: successPayload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: VettingDashboardType.DELETE_BUSINESS_ADMIN_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const sendEmailToBusiness = (businessId: string, emailText: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.SEND_EMAIL_TO_BUSINESS_REQUEST });
      const response: IResponseData = await client.post(
        `${businessUrl}business/${businessId}/email`,
        {
          emailText,
        },
      );
      dispatch({
        type: VettingDashboardType.SEND_EMAIL_TO_BUSINESS_SUCCESS,
        payload: businessId,
      });
      const successPayload: IAlertPayload = {
        message: 'Successfully sent a email to Business',
      };
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: successPayload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: VettingDashboardType.SEND_EMAIL_TO_BUSINESS_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const resetVettingDashboardBusinessDetailsState = () => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    dispatch({ type: VettingDashboardType.RESET_BUSINESS_DETAILS_STATE });
  };
};

export const resetAffiliatesListState = () => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    dispatch({ type: VettingDashboardType.RESET_AFFLIATES_LIST_STATE });
  };
};

export const setDateRange = (
  from: string | undefined,
  to: string | undefined,
  tabIndex: number,
) => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    dispatch({
      type: VettingDashboardType.SET_DATE_RANGE,
      payload: { from, to, tabIndex },
    });
  };
};

export const listAffiliates = (offset: number, from?: string, to?: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    try {
      dispatch({ type: VettingDashboardType.LIST_AFFILIATES_REQUEST });
      const response: IResponseData = await client.get(
        `${affiliateUrl}list?offset=${offset}&limit=10${from ? `&from=${from}` : ''}${
          to ? `&to=${to}` : ''
        }`,
      );

      const payload: IListAffiliatesState = {
        affiliates: (response?.data || []) as IAffiliateData[],
        offset: offset + 10,
        hasMoreData: response?.data?.length === 10,
      };
      dispatch({
        type: VettingDashboardType.LIST_AFFILIATES_SUCCESS,
        payload,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: VettingDashboardType.LIST_AFFILIATES_ERROR });
      return error;
    }
  };
};

export const getInfluencersList = () => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    try {
      dispatch({
        type: VettingDashboardType.GET_INFLUENCERS_REQUEST,
      });
      const response: IResponseData = await client.get(`${authUrl}influencers`);
      dispatch({
        type: VettingDashboardType.GET_INFLUENCERS_SUCCESS,
        payload: (response?.data || []) as IInfluencer[],
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: VettingDashboardType.GET_INFLUENCERS_ERROR });
      return error;
    }
  };
};

export const resetAffiliatesPayoutState = () => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    dispatch({ type: VettingDashboardType.RESET_AFFILIATES_PAYOUT_STATE });
  };
};

export const listAffiliatesPayout = (offset: number, from?: string, to?: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    try {
      dispatch({
        type: VettingDashboardType.AFFILIATES_PAYOUT_REQUEST,
      });
      const response: IResponseData = await client.get(
        `${affiliateUrl}payouts?offset=${offset}&limit=10${from ? `&from=${from}` : ''}${
          to ? `&to=${to}` : ''
        }`,
      );
      const payload: IListAffiliatesPayoutPayload = {
        affiliatesPayout: (response?.data || []) as IAffiliatePayoutData[],
        offset: offset + 10,
        hasMoreData: response?.data?.length === 10,
        initialLoad: offset === 0,
      };
      dispatch({
        type: VettingDashboardType.AFFILIATES_PAYOUT_SUCCESS,
        payload,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: VettingDashboardType.AFFILIATES_PAYOUT_ERROR });
      return error;
    }
  };
};

export const createPayout = (index: number, affiliateUserId: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({
        type: VettingDashboardType.CREATE_PAYOUT_REQUEST,
      });
      const response: IResponseData = await client.post(`${affiliateUrl}payouts`, {
        affiliateUserId,
      });
      dispatch({
        type: VettingDashboardType.CREATE_PAYOUT_SUCCESS,
        payload: {
          index,
          documentId: response.data.documentId,
        },
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: VettingDashboardType.CREATE_PAYOUT_ERROR });

      return error;
    }
  };
};

export const getApprovedBusinesses = (params: IGetBusinessParams) => {
  const { biztype, offset, from, to, limit = 10, searchQuery = '', reset, status } = params;

  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    try {
      dispatch({
        type: VettingDashboardType.GET_APPROVED_BUSINESSES_REQUEST,
      });

      let response: IResponseData;

      const queryParam = `${
        biztype ? `&biztype=${biztype}` : ''
      }&q=${searchQuery}&limit=${limit}&offset=${offset}${from ? `&from=${from}` : ''}${
        to ? `&from=${to}` : ''
      }${status ? `&status=${status}` : ''}`;

      if (searchQuery.length >= 3) {
        response = await client.get(`${businessUrl}business/searchInReport?${queryParam}`);
      } else {
        response = await client.get(
          `${businessUrl}approvedBusinesses?offset=${offset}&limit=${limit}${
            from ? `&from=${from}` : ''
          }${to ? `&to=${to}` : ''}${biztype ? `&biztype=${biztype}` : ''}${
            status ? `&status=${status}` : ''
          }`,
        );
      }

      const payload: IApprovedBusinessesState = {
        approvedBusinesses: (response?.data || []) as IApprovedBusiness[],
        offset: offset + 10,
        reset,
        hasMoreData: response?.data?.length === 10,
      };

      dispatch({
        type: VettingDashboardType.GET_APPROVED_BUSINESSES_SUCCESS,
        payload,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: VettingDashboardType.GET_APPROVED_BUSINESSES_ERROR });
      return error;
    }
  };
};

export const resetApprovedBusinessesState = () => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    dispatch({ type: VettingDashboardType.RESET_APPROVED_BUSINESSES_STATE });
  };
};

export const getMembersReport = (params: IGetMembersParams) => {
  const { userType, offset, limit = 10, searchQuery = '', reset, userWithBusiness } = params;

  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    try {
      dispatch({
        type: VettingDashboardType.GET_MEMBERS_REQUEST,
      });

      let response: IResponseData;

      if (searchQuery.length >= 3) {
        response = await client.get(
          `${authUrl}admin/users/search?q=${searchQuery}&userType=${userType}${
            userWithBusiness ? `&userWithBusiness=${userWithBusiness}` : ''
          }&offset=${offset}&limit=${limit}`,
        );
      } else {
        response = await client.get(
          `${authUrl}admin/users?userType=${userType}&offset=${offset}&limit=${limit}`,
        );
      }

      const payload: IMembersState = {
        members: (response?.data || []) as IMembers[],
        offset: offset + 10,
        reset,
        hasMoreData: response?.data?.length === 10,
      };

      dispatch({ type: VettingDashboardType.GET_MEMBERS_SUCCESS, payload });

      return response;
    } catch (error: unknown) {
      dispatch({ type: VettingDashboardType.GET_MEMBERS_ERROR });
      return error;
    }
  };
};

export const setAsTownCrierByAdmin = (memberId: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.SET_TOWN_CRIER_ADMIN_REQUEST });

      const url = `${authUrl}admin/users/town-crier?userId=${memberId}`;
      const response: IResponseData = await client.put(url, { isTownCrier: true });

      dispatch({ type: VettingDashboardType.SET_TOWN_CRIER_ADMIN_SUCCESS });

      const successPayload: IAlertPayload = { message: response.message };

      dispatch({ type: AlertType.ALERT_SUCCESS, payload: successPayload });
      return response;
    } catch (error: any) {
      dispatch({ type: VettingDashboardType.SET_TOWN_CRIER_ADMIN_ERROR });

      const errorPayload: IAlertPayload = { message: error?.message };

      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const deleteUserByAdmin = (payload: IDeleteAccountParams, memberId: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.DELETE_USER_ADMIN_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}users/delete-account`, payload);

      dispatch({
        type: VettingDashboardType.DELETE_USER_ADMIN_SUCCESS,
        payload: memberId,
      });

      const successPayload: IAlertPayload = { message: 'User removed successfully' };

      dispatch({ type: AlertType.ALERT_SUCCESS, payload: successPayload });
      return response;
    } catch (error: any) {
      dispatch({ type: VettingDashboardType.DELETE_USER_ADMIN_ERROR });

      const errorPayload: IAlertPayload = {
        message: error?.message || 'Problem while deleting account, please try again.',
      };

      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const getMembersReportFile = async (
  userType: string,
  searchQuery?: string,
): Promise<string> => {
  try {
    const response: string = await client.get(
      `${authUrl}admin/users/csv?${searchQuery ? `q=${searchQuery}` : ''}&userType=${userType}`,
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const resetMembersState = () => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    dispatch({ type: VettingDashboardType.RESET_MEMBERS_STATE });
  };
};

export const getInfluencersListFile = async () => {
  try {
    const response: IResponseData = await client.get(`${authUrl}influencersFile`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getApprovedBusinessesFile = async (
  params: IGetBusinessDownloadCSVParams,
): Promise<string> => {
  try {
    const { range, searchQuery, biztype, status } = params;
    const queryParam = `?${range?.from ? `from=${range.from}` : ''}${
      range?.to ? `&to=${range.to}` : ''
    }${biztype ? `&biztype=${biztype}` : ''}${status ? `&status=${status}` : ''}${
      searchQuery ? `&q=${searchQuery}` : ''
    }`;

    const response: string = await client.get(`${businessUrl}approvedBusinessesFile${queryParam}`);
    return response;
  } catch (error: any) {
    return error;
  }
};

export const deletePayout = (id: string, index: number) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.DELETE_PAYOUT_REQUEST });
      const response: IResponseData = await client.delete(`${affiliateUrl}payouts/${id}`);

      dispatch({ type: VettingDashboardType.DELETE_PAYOUT_SUCCESS, payload: index });

      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: VettingDashboardType.DELETE_PAYOUT_ERROR });

      return error;
    }
  };
};

export const setSelectedProviderType = (providerType: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    dispatch({
      type: VettingDashboardType.SET_SELECTED_PROVIDER_TYPE,
      payload: providerType,
    });
  };
};

export const addPodcastProvider = (payload: IAddPodcastProviderState) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({
        type: VettingDashboardType.ADD_PODCAST_PROVIDER_REQUEST,
      });
      const response: IResponseData = await client.post(`${podcastUrl}podcasts`, payload);
      dispatch({
        type: VettingDashboardType.ADD_PODCAST_PROVIDER_SUCCESS,
        payload: { ...response.data, isFeatured: false },
      });

      const successPayload: IAlertPayload = { message: response.message };
      dispatch({ type: AlertType.ALERT_SUCCESS, payload: successPayload });

      return response;
    } catch (error: any) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: VettingDashboardType.ADD_PODCAST_PROVIDER_ERROR });
      return error;
    }
  };
};

export const editPodcast = (params: any) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      const { name, documentId, externalShowLink, isUrlChanged } = params;

      dispatch({ type: VettingDashboardType.EDIT_PODCAST_REQUEST });

      const response: IResponseData = await client.put(`${podcastUrl}podcasts/${documentId}`, {
        name,
        externalShowLink,
      });

      dispatch({ type: VettingDashboardType.EDIT_PODCAST_SUCCESS, payload: { ...params } });

      if (isUrlChanged) {
        const successPayload: IAlertPayload = {
          message: 'Podcast updated, changes will appear in 12 hours.',
        };
        dispatch({ type: AlertType.ALERT_WARNING, payload: successPayload });
      } else {
        const successPayload: IAlertPayload = { message: response.message };
        dispatch({ type: AlertType.ALERT_SUCCESS, payload: successPayload });
      }

      return response;
    } catch (error: any) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: VettingDashboardType.EDIT_PODCAST_ERROR });
      return error;
    }
  };
};

export const deletePodcast = (id: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.DELETE_PODCAST_REQUEST });

      const response: IResponseData = await client.delete(`${podcastUrl}podcasts/${id}`);

      dispatch({ type: VettingDashboardType.DELETE_PODCAST_SUCCESS, payload: id });

      const successPayload: IAlertPayload = { message: response.message };
      dispatch({ type: AlertType.ALERT_SUCCESS, payload: successPayload });
      return response;
    } catch (error: any) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: VettingDashboardType.DELETE_PODCAST_ERROR });

      return error;
    }
  };
};

export const getAllBusinessesProviders = () => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.GET_ALL_BUSINESSES_ADMIN_REQUEST });
      const featuredResponse: IResponseData = await client.get(
        `${businessUrl}businesses?type=all&isFeatured=true`,
      );
      const unfeaturedResponse: IResponseData = await client.get(
        `${businessUrl}businesses?type=all&isFeatured=false`,
      );
      const allBusiness = [...featuredResponse.data, ...unfeaturedResponse.data];
      // const payload: IBusinessListState = {
      //   businesses: featuredResponse.data,
      //   offset: offset + limit,
      //   hasMoreData: response.data && response.data.length === limit,
      // };
      dispatch({
        type: VettingDashboardType.GET_ALL_BUSINESSES_ADMIN_SUCCESS,
        payload: allBusiness,
      });
      return allBusiness;
    } catch (error: any) {
      dispatch({ type: VettingDashboardType.GET_ALL_BUSINESSES_ADMIN_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

// export const getAllBusinessesProviders = (isFeatured: boolean, offset: number, limit = 20) => {
//   return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
//     try {
//       dispatch({ type: VettingDashboardType.GET_ALL_BUSINESSES_REQUEST });
//       const response: IResponseData = await client.get(
//         `${businessUrl}businesses?isFeatured=${isFeatured}&type=all&limit=${limit}&offset=${offset}&p=prefs`,
//       );
//       const payload: IBusinessListState = {
//         businesses: response.data,
//         offset: offset + limit,
//         hasMoreData: response.data && response.data.length === limit,
//       };
//       dispatch({
//         type: VettingDashboardType.GET_ALL_BUSINESSES_SUCCESS,
//         payload,
//       });
//       return response;
//     } catch (error: any) {
//       dispatch({ type: VettingDashboardType.GET_ALL_BUSINESSES_ERROR });
//       const errorPayload: IAlertPayload = { message: error?.message };
//       dispatch({
//         type: AlertType.ALERT_ERROR,
//         payload: errorPayload,
//       });
//       return error;
//     }
//   };
// };

export const getAllNewsProvidersList = () => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.GET_ALL_NEWS_PROVIDERS_ADMIN_REQUEST });
      const response: IResponseData = await client.get(`${newsUrl}providers`);

      dispatch({
        type: VettingDashboardType.GET_ALL_NEWS_PROVIDERS_ADMIN_SUCCESS,
        payload: response?.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: VettingDashboardType.GET_ALL_NEWS_PROVIDERS_ADMIN_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const getAllPodcasts = () => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.GET_ALL_PODCASTS_ADMIN_REQUEST });
      const response: IResponseData = await client.get(`${podcastUrl}podcasts`);

      dispatch({
        type: VettingDashboardType.GET_ALL_PODCASTS_ADMIN_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: VettingDashboardType.GET_ALL_PODCASTS_ADMIN_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const featuredPodcasts = (podcastIds: string[]) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.SAVE_FEATURED_PODCAST_PROVIDERS_REQUEST });
      const response: IResponseData = await client.put(`${podcastUrl}featured`, {
        podcastIDs: podcastIds,
      });
      dispatch({
        type: VettingDashboardType.SAVE_FEATURED_PODCAST_PROVIDERS_SUCCESS,
        payload: podcastIds,
      });
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: { message: 'Successfully saved Featured Podcasts' },
      });
      return response;
    } catch (error: any) {
      dispatch({ type: VettingDashboardType.SAVE_FEATURED_PODCAST_PROVIDERS_ERROR });
      const errorPayload: IAlertPayload = {
        message: 'Something went wrong saving PODCASTS preferences',
      };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const featuredBusinesses = (businessIds: string[]) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.SAVE_FEATURED_BUSINESS_REQUEST });
      const response: IResponseData = await client.put(`${businessUrl}features`, {
        businessIds,
      });
      dispatch({
        type: VettingDashboardType.SAVE_FEATURED_BUSINESS_SUCCESS,
        payload: businessIds,
      });
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: { message: 'Successfully saved Featured Businesses' },
      });
      return response;
    } catch (error: any) {
      dispatch({ type: VettingDashboardType.SAVE_FEATURED_BUSINESS_ERROR });
      const errorPayload: IAlertPayload = {
        message: 'Something went wrong saving BUSINESSES preferences',
      };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const featuredNewsProviders = (providerIds: string[]) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.SAVE_FEATURED_NEWS_PROVIDERS_REQUEST });
      const response: IResponseData = await client.put(`${newsUrl}featured/news/providers`, {
        providerIDs: providerIds,
      });
      dispatch({
        type: VettingDashboardType.SAVE_FEATURED_NEWS_PROVIDERS_SUCCESS,
        payload: providerIds,
      });
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: { message: 'Successfully saved Featured News Providers' },
      });
      return response;
    } catch (error: any) {
      dispatch({ type: VettingDashboardType.SAVE_FEATURED_NEWS_PROVIDERS_ERROR });
      const errorPayload: IAlertPayload = {
        message: 'Something went wrong saving NEWS preferences',
      };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const getAllSponsorsReport = (params: any) => {
  const { offset, from, to, limit = 10, type, reset } = params;
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    try {
      dispatch({ type: VettingDashboardType.GET_ADS_REPORT_REQUEST });

      const sponsorsType = type === 'coupon' ? 'coupons' : 'sponsors';

      const response: IResponseData = await client.get(
        `${sponsorUrl}${sponsorsType}/reports?&offset=${offset}&limit=${limit}${
          from ? `&from=${from}` : ''
        }${to ? `&to=${to}` : ''}`,
      );
      const payload: IAllSponsorsReportState = {
        AllSponsorsReport: (response?.data || []) as any[],
        offset: offset + 10,
        hasMoreData: response?.data?.length === 10,
        reset,
      };
      dispatch({ type: VettingDashboardType.GET_ADS_REPORT_SUCCESS, payload });
      return response;
    } catch (error: unknown) {
      dispatch({ type: VettingDashboardType.GET_ADS_REPORT_ERROR });
      return error;
    }
  };
};

export const resetAllSponsorsReportState = () => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    dispatch({ type: VettingDashboardType.RESET_ADS_REPORT_STATE });
  };
};

export const createAdsCoupons = (payload: any, type: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.CREATE_ADS_REQUEST });
      // const adsFormData = getFormData(payload);

      const sponsorsType = type === 'coupon' ? 'coupons' : 'sponsors';

      const response: IResponseData = await client.post(
        `${sponsorUrl}${sponsorsType}`,
        payload,
        multiPartFormHeader,
      );
      dispatch({ type: VettingDashboardType.CREATE_ADS_SUCCESS, payload: response.data });

      const successPayload: IAlertPayload = { message: `Successfully created ${type}` };

      dispatch({ type: AlertType.ALERT_SUCCESS, payload: successPayload });
      return response;
    } catch (error: any) {
      dispatch({ type: VettingDashboardType.CREATE_ADS_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const addSponsor = (payload: IAddSponsorState) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.ADD_SPONSOR_REQUEST });
      const response: IResponseData = await client.post(`${sponsorUrl}sponsors/add`, payload);
      dispatch({
        type: VettingDashboardType.ADD_SPONSOR_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: VettingDashboardType.ADD_SPONSOR_ERROR });
      return error;
    }
  };
};

export const fetchSponsorsList = (offset: number) => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    try {
      dispatch({ type: VettingDashboardType.GET_SPONSORS_LIST_REQUEST });
      const response: IResponseData = await client.get(
        `${sponsorUrl}sponsors/list?offset=${offset}&limit=${PER_PAGE}`,
      );

      const payload: ISponsorsListPayload = {
        sponsors: (response?.data || []) as ISponsor[],
        offset: offset + 10,
        hasMoreData: response?.data?.length === 10,
        initialLoad: offset === 0,
      };
      dispatch({ type: VettingDashboardType.GET_SPONSORS_LIST_SUCCESS, payload });
      return response;
    } catch (error: unknown) {
      dispatch({ type: VettingDashboardType.GET_SPONSORS_LIST_ERROR });
      return error;
    }
  };
};
export const fetchAllSponsorsReportBySponsorId = (sponsorId: string, type?: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    try {
      dispatch({
        type: VettingDashboardType.GET_ADS_BY_SPONSORID_REQUEST,
      });

      const sponsorsType = type === 'coupon' ? 'coupons' : 'sponsors';

      // https://qa.freedomsquare.com/services/sponsors/v1/admin/sponsors/reports?type=ad&offset=0&limit=10
      // https://qa.freedomsquare.com/sponsors/v1/admin/sponsors/reports?offset=0&limit=100

      const response: IResponseData = await client.get(
        `${sponsorUrl}${sponsorsType}?sponsor_id=${sponsorId}`,
      );
      const payload: ISponsorAd[] = response.data;
      dispatch({ type: VettingDashboardType.GET_ADS_BY_SPONSORID_SUCCESS, payload });

      return response;
    } catch (error: unknown) {
      dispatch({ type: VettingDashboardType.GET_ADS_BY_SPONSORID_ERROR });
      return error;
    }
  };
};

export const resetSponsorAdsState = () => {
  return async (dispatch: Dispatch<VettingDashboardActions>) => {
    dispatch({ type: VettingDashboardType.RESET_SPONSOR_ADS_STATE });
  };
};

export const deleteSponsor = (id: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.DELETE_SPONSOR_REQUEST });
      const response: IResponseData = await client.delete(
        `${sponsorUrl}sponsors/remove?sponsorId=${id}`,
      );
      dispatch({
        type: VettingDashboardType.DELETE_SPONSOR_SUCCESS,
        payload: id,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: VettingDashboardType.DELETE_SPONSOR_ERROR });

      return error;
    }
  };
};

export const editSponsor = (payload: IAddSponsorState, sponsorId: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.EDIT_SPONSOR_REQUEST });
      const response: IResponseData = await client.post(
        `${sponsorUrl}sponsors/update?sponsorId=${sponsorId}`,
        payload,
      );
      dispatch({
        type: VettingDashboardType.EDIT_SPONSOR_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: VettingDashboardType.EDIT_SPONSOR_ERROR });
      return error;
    }
  };
};

export const deleteSponsorAdCoupons = (id: string, type: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.DELETE_SPONSOR_AD_REQUEST });

      const params = type === 'coupon' ? `coupons?couponId=${id}` : `sponsors?adId=${id}`;

      const response: IResponseData = await client.delete(`${sponsorUrl}${params}`);
      dispatch({
        type: VettingDashboardType.DELETE_SPONSOR_AD_SUCCESS,
      });
      return response;
    } catch (error: any) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: VettingDashboardType.DELETE_SPONSOR_AD_ERROR });
      return error;
    }
  };
};

export const editSponsorAdCoupons = (payload: any, adId: string, type: string) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.EDIT_SPONSOR_AD_REQUEST });

      const params = type === 'coupon' ? `coupons?couponId=${adId}` : `sponsors?adId=${adId}`;

      const response: IResponseData = await client.put(`${sponsorUrl}${params}`, payload);
      dispatch({
        type: VettingDashboardType.EDIT_SPONSOR_AD_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: VettingDashboardType.EDIT_SPONSOR_AD_ERROR });
      return error;
    }
  };
};

export const createBusinessFromAdmin = (payload: any) => {
  return async (dispatch: Dispatch<VettingDashboardActions | AlertActions>) => {
    try {
      dispatch({ type: VettingDashboardType.CREATE_BUSINESS_ADMIN_REQUEST });

      const response: IResponseData = await client.post(`${businessUrl}admin/businesses`, payload);

      dispatch({ type: VettingDashboardType.CREATE_BUSINESS_ADMIN_SUCCESS });

      const successPayload: IAlertPayload = { message: 'Successfully created new business' };
      dispatch({ type: AlertType.ALERT_SUCCESS, payload: successPayload });

      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: VettingDashboardType.CREATE_BUSINESS_ADMIN_ERROR });
      return error;
    }
  };
};
