import { UpdateCommentCountType } from 'store/comment/CommentInterface';

import BusinessType from './BusinessTypes';

interface ISaveBusinessDataRequest {
  type: BusinessType.SAVE_BUSINESS_DATA_REQUEST;
}

interface ISaveBusinessDataSuccess {
  type: BusinessType.SAVE_BUSINESS_DATA_SUCCESS;
  payload: IBusinessDetails;
}

interface ISaveBusinessDataError {
  type: BusinessType.SAVE_BUSINESS_DATA_ERROR;
}

interface IFetchBusinessImageRequest {
  type: BusinessType.FETCH_BUSINESS_DETAILS_REQUEST;
}

interface IFetchBusinessImageSuccess {
  type: BusinessType.FETCH_BUSINESS_DETAILS_SUCCESS;
  payload: { data: IBusinessDetails; updateUserBusinessId: boolean };
}

interface IFetchBusinessImageError {
  type: BusinessType.FETCH_BUSINESS_DETAILS_ERROR;
}

interface IAddBusinessImageRequest {
  type: BusinessType.ADD_BUSINESS_IMAGE_REQUEST;
}

interface IAddBusinessImageSuccess {
  type: BusinessType.ADD_BUSINESS_IMAGE_SUCCESS;
  payload: { response: IBusinessImageData; imageType: ImageType };
}

interface IAddBusinessImageError {
  type: BusinessType.ADD_BUSINESS_IMAGE_ERROR;
}

interface IUpdateBusinessImageRequest {
  type: BusinessType.UPDATE_BUSINESS_IMAGE_REQUEST;
}

interface IUpdateBusinessImageSuccess {
  type: BusinessType.UPDATE_BUSINESS_IMAGE_SUCCESS;
  payload: {
    imageType: ImageType;
    oldImageId: string;
    response: IBusinessImageData;
  };
}

interface IUpdateBusinessImageError {
  type: BusinessType.UPDATE_BUSINESS_IMAGE_ERROR;
}

interface IDeleteBusinessImageRequest {
  type: BusinessType.DELETE_BUSINESS_IMAGE_REQUEST;
}

interface IDeleteBusinessImageSuccess {
  type: BusinessType.DELETE_BUSINESS_IMAGE_SUCCESS;
  payload: { imageId: string; imageType: ImageType };
}

interface IDeleteBusinessImageError {
  type: BusinessType.DELETE_BUSINESS_IMAGE_ERROR;
}

interface IUpdateBusinessDataRequest {
  type: BusinessType.UPDATE_BUSINESS_DATA_REQUEST;
}

interface IUpdateBusinessDataSuccess {
  type: BusinessType.UPDATE_BUSINESS_DATA_SUCCESS;
  payload: IBusinessDetails;
}

interface IUpdateBusinessDataError {
  type: BusinessType.UPDATE_BUSINESS_DATA_ERROR;
}

interface ICreateBusinessPaymentRequest {
  type: BusinessType.CREATE_BUSINESS_PAYMENT_REQUEST;
}

interface ICreateBusinessPaymentSuccess {
  type: BusinessType.CREATE_BUSINESS_PAYMENT_SUCCESS;
  payload: IBusinessDetails;
}

interface ICreateBusinessPaymentError {
  type: BusinessType.CREATE_BUSINESS_PAYMENT_ERROR;
}

interface IFetchFeaturedBusinessesRequest {
  type: BusinessType.FETCH_FEATURED_BUSINESSES_REQUEST;
}

interface IFetchFeaturedBusinessesSuccess {
  type: BusinessType.FETCH_FEATURED_BUSINESSES_SUCCESS;
  payload: IBusinessListState;
}

interface IFetchFeaturedBusinessesError {
  type: BusinessType.FETCH_FEATURED_BUSINESSES_ERROR;
}

interface IFetchRecentBusinessesRequest {
  type: BusinessType.FETCH_RECENT_BUSINESSES_REQUEST;
}

interface IFetchRecentBusinessesSuccess {
  type: BusinessType.FETCH_RECENT_BUSINESSES_SUCCESS;
  payload: IBusinessListState;
}

interface IFetchRecentBusinessesError {
  type: BusinessType.FETCH_RECENT_BUSINESSES_ERROR;
}

interface IFetchAllBusinessesRequest {
  type: BusinessType.FETCH_ALL_BUSINESSES_REQUEST;
}

interface IFetchAllBusinessesSuccess {
  type: BusinessType.FETCH_ALL_BUSINESSES_SUCCESS;
  payload: IBusinessListState;
}

interface IFetchAllBusinessesError {
  type: BusinessType.FETCH_ALL_BUSINESSES_ERROR;
}
interface IResetBusinessDirectoryState {
  type: BusinessType.RESET_BUSINESS_DIRECTORY_STATE;
}

interface IFollowBusinessRequest {
  type: BusinessType.FOLLOW_BUSINESS_REQUEST;
}

interface IFollowBusinessSuccess {
  type: BusinessType.FOLLOW_BUSINESS_SUCCESS;
  payload: { businessId: string };
}

interface IFollowBusinessError {
  type: BusinessType.FOLLOW_BUSINESS_ERROR;
}

interface IUnFollowBusinessRequest {
  type: BusinessType.UNFOLLOW_BUSINESS_REQUEST;
}

interface IUnFollowBusinessSuccess {
  type: BusinessType.UNFOLLOW_BUSINESS_SUCCESS;
  payload: { businessId: string };
}

interface IUnFollowBusinessError {
  type: BusinessType.UNFOLLOW_BUSINESS_ERROR;
}

interface IPaymentExistingCardRequest {
  type: BusinessType.PAYMENT_EXISTING_CARD_REQUEST;
}

interface IPaymentExistingCardSuccess {
  type: BusinessType.PAYMENT_EXISTING_CARD_SUCCESS;
  payload: IBusinessDetails;
}

interface IPaymentExistingCardError {
  type: BusinessType.PAYMENT_EXISTING_CARD_ERROR;
}

interface IGetStateRequest {
  type: BusinessType.GET_STATES_REQUEST;
}

interface IGetStateSuccess {
  type: BusinessType.GET_STATES_SUCCESS;
  payload: IState[];
}

interface IGetStateError {
  type: BusinessType.GET_STATES_ERROR;
}

interface IGetCategoriesRequest {
  type: BusinessType.GET_CATEGORIES_REQUEST;
}
interface IGetCategoriesSuccess {
  type: BusinessType.GET_CATEGORIES_SUCCESS;
  payload: IBusinessCategory[];
}
interface IGetCategoriesError {
  type: BusinessType.GET_CATEGORIES_ERROR;
}

interface IFetchBusinessDetailsByBusinessIdRequest {
  type: BusinessType.FETCH_BUSINESS_DETAILS_BY_BUSINESSID_REQUEST;
}

interface IFetchBusinessDetailsByBusinessIdSuccess {
  type: BusinessType.FETCH_BUSINESS_DETAILS_BY_BUSINESSID_SUCCESS;
  payload: IBusinessDetails;
}

interface IFetchBusinessDetailsByBusinessIdError {
  type: BusinessType.FETCH_BUSINESS_DETAILS_BY_BUSINESSID_ERROR;
}

interface IFetchBusinessPostsRequest {
  type: BusinessType.FETCH_BUSINESS_POSTS_REQUEST;
}

interface IFetchBusinessPostsSuccess {
  type: BusinessType.FETCH_BUSINESS_POSTS_SUCCESS;
  payload: IBusinessPostsState;
}

interface IFetchBusinessPostsError {
  type: BusinessType.FETCH_BUSINESS_POSTS_ERROR;
}

interface ICreateBusinessPostRequest {
  type: BusinessType.CREATE_BUSINESS_POST_REQUEST;
}

interface ICreateBusinessPostSuccess {
  type: BusinessType.CREATE_BUSINESS_POST_SUCCESS;
  payload: IBusinessPost;
}

interface ICreateBusinessPostError {
  type: BusinessType.CREATE_BUSINESS_POST_ERROR;
}

interface IEditBusinessPostRequest {
  type: BusinessType.EDIT_BUSINESS_POST_REQUEST;
}

interface IEditBusinessPostSuccess {
  type: BusinessType.EDIT_BUSINESS_POST_SUCCESS;
  payload: IBusinessPost;
}

interface IEditBusinessPostError {
  type: BusinessType.EDIT_BUSINESS_POST_ERROR;
}

interface IDeleteBusinessPostRequest {
  type: BusinessType.DELETE_BUSINESS_POST_REQUEST;
}

interface IDeleteBusinessPostSuccess {
  type: BusinessType.DELETE_BUSINESS_POST_SUCCESS;
  payload: string;
}

interface IDeleteBusinessPostError {
  type: BusinessType.DELETE_BUSINESS_POST_ERROR;
}

interface IFetchBusinessMediaRequest {
  type: BusinessType.FETCH_BUSINESS_MEDIA_REQUEST;
}

interface IFetchBusinessMediaSuccess {
  type: BusinessType.FETCH_BUSINESS_MEDIA_SUCCESS;
  payload: IBusinessMediaState;
}

interface IFetchBusinessMediaError {
  type: BusinessType.FETCH_BUSINESS_MEDIA_ERROR;
}

interface IResetBusinessWallState {
  type: BusinessType.RESET_BUSINESS_WALL_STATE;
}

interface IResetBusinessPostsState {
  type: BusinessType.RESET_BUSINESS_POSTS_STATE;
}

interface IResetBusinessMediaState {
  type: BusinessType.RESET_BUSINESS_MEDIA_STATE;
}

interface IFetchSingleBusinessDetailsRequest {
  type: BusinessType.FETCH_BUSINESS_POST_DETAILS_REQUEST;
}

interface IFetchSingleBusinessDetailsSuccess {
  type: BusinessType.FETCH_BUSINESS_POST_DETAILS_SUCCESS;
  payload: IBusinessPostDetails;
}

interface IFetchSingleBusinessDetailsError {
  type: BusinessType.FETCH_BUSINESS_POST_DETAILS_ERROR;
}

interface IResetBusinessPostState {
  type: BusinessType.RESET_BUSINESS_POST_DETAILS_STATE;
}

interface ILikeDislikeBusinessPostRequest {
  type: BusinessType.LIKE_DISLIKE_BUSINESS_POST_REQUEST;
}

interface ILikeDislikeBusinessPostSuccess {
  type: BusinessType.LIKE_DISLIKE_BUSINESS_POST_SUCCESS;
}

interface ILikeDislikeBusinessPostError {
  type: BusinessType.LIKE_DISLIKE_BUSINESS_POST_ERROR;
}

interface ISetFilterBusinessCategories {
  type: BusinessType.SET_FILTER_BUSINESSES_CATEGORIES;
  payload: IBusinessCategory[];
}

interface IResetFilterBusinessCategories {
  type: BusinessType.RESET_FILTER_BUSINESSES_CATEGORIES;
}

interface IBusinessLikeDislikeBusinessPostSuccess {
  type: BusinessType.BUSINESS_LIKE_DISLIKE_BUSINESS_POST_SUCCESS;
  payload: string;
}

interface IResetBusinessDetailsState {
  type: BusinessType.RESET_BUSINESS_DETAILS_STATE;
}

interface IUpdateCommentCount {
  type: BusinessType.UPDATE_COMMENT_COUNT;
  payload: UpdateCommentCountType;
}

interface IUpdatePostContent {
  type: BusinessType.BUSINESS_UPDATE_POST_CONTENT;
  payload: UpdateContentParams;
}

export type BusinessActions =
  | ISaveBusinessDataRequest
  | ISaveBusinessDataSuccess
  | ISaveBusinessDataError
  | IFetchBusinessImageRequest
  | IFetchBusinessImageSuccess
  | IFetchBusinessImageError
  | IAddBusinessImageRequest
  | IAddBusinessImageSuccess
  | IAddBusinessImageError
  | IUpdateBusinessImageRequest
  | IUpdateBusinessImageSuccess
  | IUpdateBusinessImageError
  | IDeleteBusinessImageRequest
  | IDeleteBusinessImageSuccess
  | IDeleteBusinessImageError
  | IUpdateBusinessDataRequest
  | IUpdateBusinessDataSuccess
  | IUpdateBusinessDataError
  | ICreateBusinessPaymentRequest
  | ICreateBusinessPaymentSuccess
  | ICreateBusinessPaymentError
  | IFetchFeaturedBusinessesSuccess
  | IFetchFeaturedBusinessesRequest
  | IFetchFeaturedBusinessesError
  | IFetchRecentBusinessesRequest
  | IFetchRecentBusinessesSuccess
  | IFetchRecentBusinessesError
  | IFetchAllBusinessesRequest
  | IFetchAllBusinessesSuccess
  | IFetchAllBusinessesError
  | IResetBusinessDirectoryState
  | IFollowBusinessRequest
  | IFollowBusinessSuccess
  | IFollowBusinessError
  | IUnFollowBusinessRequest
  | IUnFollowBusinessSuccess
  | IUnFollowBusinessError
  | IPaymentExistingCardRequest
  | IPaymentExistingCardSuccess
  | IPaymentExistingCardError
  | IGetStateRequest
  | IGetStateSuccess
  | IGetStateError
  | IGetCategoriesRequest
  | IGetCategoriesSuccess
  | IGetCategoriesError
  | IFetchBusinessDetailsByBusinessIdRequest
  | IFetchBusinessDetailsByBusinessIdSuccess
  | IFetchBusinessDetailsByBusinessIdError
  | IFetchBusinessPostsRequest
  | IFetchBusinessPostsSuccess
  | IFetchBusinessPostsError
  | ICreateBusinessPostRequest
  | ICreateBusinessPostSuccess
  | ICreateBusinessPostError
  | IEditBusinessPostRequest
  | IEditBusinessPostSuccess
  | IEditBusinessPostError
  | IDeleteBusinessPostRequest
  | IDeleteBusinessPostSuccess
  | IDeleteBusinessPostError
  | IFetchBusinessMediaRequest
  | IFetchBusinessMediaSuccess
  | IFetchBusinessMediaError
  | IResetBusinessWallState
  | IResetBusinessPostsState
  | IResetBusinessMediaState
  | IFetchSingleBusinessDetailsRequest
  | IFetchSingleBusinessDetailsSuccess
  | IFetchSingleBusinessDetailsError
  | IResetBusinessPostState
  | ILikeDislikeBusinessPostRequest
  | ILikeDislikeBusinessPostSuccess
  | ILikeDislikeBusinessPostError
  | ISetFilterBusinessCategories
  | IResetFilterBusinessCategories
  | IBusinessLikeDislikeBusinessPostSuccess
  | IResetBusinessDetailsState
  | IUpdateCommentCount
  | IUpdatePostContent;

export interface IBusinessState {
  loading: boolean;
  isMyOwnPost: boolean;
  isGetBusinessDetailsLoading: boolean;
  isCreateBusinessLoading: boolean;
  isUpdateBusinessLoading: boolean;
  isDeleteBusinessPostLoading: boolean;
  businessDetails: IBusinessDetails;
  featuredBusinessLoading: boolean;
  recentBusinessLoading: boolean;
  allBusinessLoading: boolean;
  featuredBusinesses: IBusinessListState;
  recentBusinesses: IBusinessListState;
  allBusinesses: IBusinessListState;
  states: IState[];
  filterBusinessCategories: IBusinessCategory[];
  businessCategories: IBusinessCategory[];
  followLoading: boolean;
  businessPostsState: IBusinessPostsState;
  businessPostsLoading: boolean;
  businessWallDetails: IBusinessDetails;
  createBusinessPostLoading: boolean;
  editBusinessPostLoading: boolean;
  businessMediaLoading: boolean;
  businessMediaState: IBusinessMediaState;
  businessPostDetails: IBusinessPostDetails;
  isBusinessUpdatedSuccessfully: null | boolean;
  userBusinessId: string;
}

export interface IBusinessPostDetails {
  businessId: string;
  businessName: string;
  commentsCount: number;
  contentDataType: string;
  createdAt: string;
  documentId: string;
  isFollowing: boolean;
  isLiked: boolean;
  likes: number;
  likesCount: number;
  logo: IBusinessImageData;
  role: string;
  textContent: string;
  updatedAt: string;
  user: IBusinessPostUser;
  userId: string;
  image: IBusinessMediaPostImage;
  isBusinessDeactivated?: boolean;
  isMyOwnPost: boolean;
}

export interface IBusinessListState {
  offset: number;
  hasMoreData: boolean;
  businesses: IBusiness[];
  totalCount?: number;
}

export interface IBusinessPostsState {
  offset: number;
  hasMoreData: boolean;
  posts: IBusinessPost[];
  reset?: boolean;
}

export interface IGetBusinessPostsParams {
  businessId: string;
  offset: number;
  limit?: number;
  reset?: boolean;
}

export interface IBusinessMediaState {
  offset: number;
  hasMoreData: boolean;
  businessMedia: IBusinessMedia;
}

export interface IBusinessMedia {
  businessImages: IBusinessMediaImages;
  postImages: IBusinessMediaPostImage[];
}

export interface IBusinessMediaImages {
  logo: IBusinessImageData;
  background: IBusinessImageData;
  media: IBusinessImageData[];
}

export interface IBusinessMediaPostImage {
  imageId: string;
  imageURL: string;
  imageUrl: string;
  contentId: string;
  croppedImageUrl: string;
  imageViewAttribute: string;
}

export interface IBusinessInformationState {
  name: string;
  businessCategories: IBusinessCategory[];
  address: string;
  website: string;
  city: string;
  stateId: string;
  phone: string;
  zipcode: string;
  onlineOnly: boolean;
  email: string;
  contactPersonName: string;
  deactivatedAt?: string;
  deletedAt?: string;
}
export interface IBusinessDetailsState {
  tagline: string;
  description: string;
}
export interface ISocialLinksState {
  facebookUrl: string;
  instagramUrl: string;
  linkedinUrl: string;
  twitterUrl: string;
}

export interface IBusinessSetupState {
  businessInformation: IBusinessInformationState;
  businessDetails: IBusinessDetailsState;
  socialLinks: ISocialLinksState;
}

export interface IProfileBusinessInformationState {
  businessInformation: IBusinessInformationState;
}

export interface IProfileBusinessDetailsState {
  businessDetails: IBusinessDetailsState;
  socialLinks: ISocialLinksState;
}

export interface ISetupBusinessInformationState {
  businessInformation: IBusinessInformationState;
}

export interface ISetupBusinessDetailsState {
  businessDetails: IBusinessDetailsState;
}

export interface ISetupBusinessSocialLinksState {
  socialLinks: ISocialLinksState;
}

export interface IBusinessDetails
  extends IBusinessInformationState,
    IBusinessDetailsState,
    ISocialLinks {
  documentId: string;
  images: IBusinessImages;
  step: BusinessSetupSteps;
  status: BusinessSetupStatus;
  state: IState;
  isFollowing: boolean;
  role: string;
  followersCount: number;
}

export interface IBusinessSetup
  extends IBusinessInformationState,
    IBusinessDetailsState,
    ISocialLinks {
  documentId?: string;
  businessCategoryIds?: string[];
}

export interface ISocialLink {
  socialType: string;
  link: string;
}
export interface ISocialLinks {
  socialLinks: ISocialLink[];
}

export interface IBusinessImageData {
  croppedImageDetails: string;
  croppedImageReadUrl: string;
  documentId: string;
  imageType: string;
  originalImageReadUrl: string;
}

export interface IBusinessImages {
  background: IBusinessImageData;
  media: IBusinessImageData[];
  logo: IBusinessImageData;
}

export interface IBusinessImagePayload {
  image: any;
  croppedImage: any;
  imageViewAttribute: string;
  imageType: string;
}

export interface ICreateBusinessPaymentPayload {
  billingName: string;
  numbers: string;
  expirationDate: string;
  address: string;
  city: string;
  stateID: string;
  zipcode: string;
  amount: number;
  subscriptionType: string;
  alias: string;
}

export interface IBusiness {
  description: string;
  documentId: string;
  imageLogo: IBusinessImageData;
  name: string;
  isFollowing: boolean;
  role: string;
  dateFeatured: string | null;
  isFeatured: boolean;
  totalCount: number;
  businessType: string;
}

export enum UserSetUpSteps {
  USER_INFO = 'user_info',
  PAYMENT = 'payment',
  PAYMENT_SUCCESS = 'payment_successful',
}

export enum BusinessSetupSteps {
  BUSINESS_INFO = 'business_info',
  BUSINESS_DETAILS = 'business_details',
  BUSINESS_SOCIAL_LINKS = 'business_socials',
  BUSINESS_IMAGES = 'business_images',
  PAYMENT = 'payment',
  PAYMENT_PROCESSING = 'payment_processing',
  PAYMENT_SUCCESS = 'payment_successful',
  PAYMENT_FAILED = 'payment_failed',
  PAYMENT_REFUND_PROCESSING = 'payment_refund_processing',
  PAYMENT_REFUND_SUCCESS = 'payment_refund_successful',
  PAYMENT_REFUND_FAILED = 'payment_refund_failed',
  COMPLETED = 'completed',
  FREE_BUSINESS_LISTED = 'free_business_listed',
}

export enum BusinessSetupStatus {
  DRAFT = 'draft',
  PAYMENT_SUCCESSFUL = 'payment_successful',
  PAYMENT_DECLINED = 'payment_declined',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum ImageType {
  BACKGROUND = 'background',
  MEDIA = 'media',
  LOGO = 'logo',
  AD = 'ad',
  COUPON = 'coupon',
}

export enum BusinessComponentType {
  SETUP = 'business_setup',
  PROFILE = 'business_profile',
}

export interface IState {
  name: string;
  documentId: string;
}

export interface IBusinessCategory {
  name: string;
  documentId: string;
}

export interface UpdateContentParams {
  postId: string;
}

export interface IBusinessPost {
  businessId: string;
  commentsCount: number;
  contentDataType: string;
  documentId: string;
  createdAt: string;
  image: IBusinessPostImage;
  isLiked: boolean;
  isReported: boolean;
  likes: number;
  likesCount: number;
  textContent: string;
  updatedAt: string;
  user: IBusinessPostUser;
  userId: string;
}
export interface IBusinessPostImage {
  createdAt: string;
  croppedImageURL: string;
  croppedImageUrl: string;
  imageID: string;
  imageId: string;
  imageURL: string;
  imageUrl: string;
  imageViewAttribute: string;
  updatedAt: string;
}

export interface IBusinessPostUser {
  businessType: string;
  croppedImageReadUrl: string;
  fullName: string;
  influencerStatus: boolean;
  userId: string;
  userName: string;
  userType: string;
}

export interface ICreateBusinessPostPayload {
  contentDataType: string;
  croppedMediaContent: any;
  imageViewAttribute: string;
  mediaContent: any;
  textContent: string;
}
