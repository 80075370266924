import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import InfiniteScroll from 'components/infiniteScroll/InfiniteScroll';
import Loader from 'components/loader/Loader';
import GuestReactionView from 'components/reactions/GuestReactionView';
import ProfilePostTile from 'components/tile/PostTile/ProfilePostTile';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchProfilePosts, IProfilePost, resetProfilePostsState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

function ProfilePosts() {
  const { userId } = useParams();

  const dispatch = useAppDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();
  const {
    profilePostsLoading,
    profilePostsState: { posts, hasMoreData, offset },
  } = useAppSelector(s => s.account);

  const getProfilePosts = useCallback(
    (initialLoad: boolean) => {
      const params = {
        userId: userId || '',
        offset: initialLoad ? 0 : offset,
        limit: 10,
        reset: initialLoad,
      };
      dispatch(fetchProfilePosts(params));
    },
    [userId, offset],
  );

  useEffect(() => {
    getProfilePosts(true);
    return () => {
      dispatch(resetProfilePostsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pathName = useMemo(() => location.pathname, [location.pathname]);

  const isTownCrierPost = pathName.includes('town-crier');

  const navigateToProfilePost = (contentId: string, isComment: boolean) => {
    if (isComment) {
      if (isUserLoggedIn()) {
        navigate(`/post/${contentId}`, {
          state: 'scroll-to-comments',
        });
      } else {
        setShowModal(true);
      }
    } else {
      navigate(`/post/${contentId}`);
    }
  };

  return (
    <>
      <InfiniteScroll
        hasMoreData={hasMoreData}
        isLoading={profilePostsLoading}
        loadOnMount={false}
        onBottomHit={getProfilePosts}
      >
        <Box>
          <Grid container item width="100%" xs={12}>
            {posts?.length > 0 ? (
              <>
                {posts.map((post: IProfilePost, index: number) => (
                  <Fragment key={post.documentId + index.toString()}>
                    <Grid item xs={12}>
                      <ProfilePostTile
                        key={post?.documentId}
                        commentsCount={post?.commentsCount}
                        image={post?.image?.croppedImageUrl || post?.image?.imageUrl}
                        imageProps={post?.image}
                        isLiked={post?.isLiked}
                        isReported={post?.isReported}
                        likesCount={post?.likesCount}
                        navigateToProfilePost={(isComment: boolean) =>
                          navigateToProfilePost(post?.documentId, isComment)
                        }
                        postedAt={post?.createdAt as string}
                        postId={post.documentId}
                        postType={isTownCrierPost ? 'town-crier-post' : 'paid-member-post'}
                        textContent={post?.textContent}
                        type="profile-wall"
                        userId={post?.userId}
                        userImage={post?.user?.croppedImageReadUrl}
                        userName={post?.user?.fullName}
                        withDivider={!(posts?.length === index + 1)}
                      />
                    </Grid>
                  </Fragment>
                ))}
              </>
            ) : (
              !profilePostsLoading && (
                <Typography component="h6" variant="h6Bold">
                  No posts available
                </Typography>
              )
            )}
            {profilePostsLoading && <Loader />}
          </Grid>
        </Box>
      </InfiniteScroll>
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </>
  );
}

export default ProfilePosts;
