import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CustomInputField from 'components/customFields/CustomInputField';
import PasswordEyeIcon from 'components/customFields/PasswordEyeIcon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  deleteUserAccount,
  IDeleteAccountPayload,
  IDeleteAccountState,
  IResponseData,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { clearSession, CookieNames, getCookieItem } from 'utils/cookies';
import { handleRestrictSpaces } from 'utils/utils';

import useDeleteAccountForm from './useDeleteAccountForm';

interface IDeleteAccountProps {
  onClose: () => void;
  isMobile: boolean;
}

function DeleteAccount({ onClose, isMobile }: IDeleteAccountProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAccountDeleting } = useAppSelector(s => s.account);
  const initialState: IDeleteAccountState = {
    email: '',
    password: '',
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onSubmit = async () => {
    const refreshToken = getCookieItem(CookieNames.REFRESH_TOKEN);
    const { email, password } = values;
    const deleteAccountPayload: IDeleteAccountPayload = {
      email,
      password,
      refreshToken,
    };
    const response: IResponseData = (await dispatch(
      deleteUserAccount(deleteAccountPayload),
    )) as IResponseData;
    if (response && !response.error) {
      onClose();
      clearSession();
      navigate('/delete-account', { replace: true });
    }
  };

  const formik = useDeleteAccountForm(onSubmit, initialState);
  const { touched, values, errors, handleChange, handleBlur, handleSubmit, isValid, dirty } =
    formik;

  return (
    <Stack margin={theme.spacing(0, 2)} spacing={theme.spacing(3)}>
      <Stack alignItems="flex-start" spacing={theme.spacing(3)}>
        <Typography variant={isMobile ? 'h6' : 'title2'}>
          Your personal information, payment information, images, and user content will be
          permanently deleted and all subscriptions canceled.
        </Typography>
        <Typography variant={isMobile ? 'h6' : 'title2'}>
          To confirm enter your username and password.
        </Typography>
      </Stack>
      <Stack
        noValidate
        autoComplete="off"
        component="form"
        spacing={theme.spacing(2)}
        onSubmit={handleSubmit}
      >
        <CustomInputField
          fullWidth
          required
          error={!!(touched.email && errors.email)}
          errormessage={touched.email ? errors.email : undefined}
          id="email"
          label="Email"
          name="email"
          type="text"
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <CustomInputField
          fullWidth
          required
          endAdornment={
            <PasswordEyeIcon
              handleOnClick={() => setShowPassword(!showPassword)}
              showPassword={showPassword}
            />
          }
          error={!!(touched.password && errors.password)}
          errormessage={touched.password ? errors.password : undefined}
          id="password"
          label="Password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          value={values.password}
          onBlur={handleBlur}
          onChange={e => handleChange('password')(handleRestrictSpaces(e.target.value))}
        />
        <LoadingButton
          disableRipple
          disableTouchRipple
          fullWidth
          color="secondary"
          disabled={!(isValid && dirty)}
          loading={isAccountDeleting}
          size="large"
          type="submit"
          variant="contained"
        >
          Delete Account
        </LoadingButton>
      </Stack>
    </Stack>
  );
}

export default DeleteAccount;
