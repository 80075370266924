import Avatar from '@mui/material/Avatar/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Link as RouteLink, useNavigate } from 'react-router-dom';
import theme from 'theme';
import { timeDiffCalc } from 'utils/utils';

type ContentType = 'News' | 'Podcast' | 'Business' | 'Profile' | 'Town Crier';

interface IPostHeaderProps {
  contentType: ContentType;
  // category: string;
  date: string;
  providerLogo: string;
  providerName: string;
  providerUrl: string;
}

const CustomRouteLink = styled(RouteLink)`
  color: ${theme.palette.primary.main};
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:active {
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
    // color: ${theme.palette.primary.main};
  }
`;

export default function PostHeader(props: IPostHeaderProps) {
  const navigate = useNavigate();
  const { contentType, date, providerLogo = '', providerName, providerUrl } = props;

  return (
    <Stack direction="row" spacing={theme.spacing(1.5)} width="100%">
      <Avatar
        alt="provider-logo"
        src={providerLogo}
        sx={{
          width: theme.spacing(3),
          height: theme.spacing(3),
          cursor: 'pointer',
        }}
        variant="square"
        onClick={() => navigate(providerUrl)}
      />
      <Stack alignItems="flex-start" spacing={theme.spacing(2)}>
        {/* {["Business", "Podcast"].includes(contentType) && ( */}
        <CustomRouteLink to={providerUrl}>{providerName}</CustomRouteLink>
        {/* )} */}
        {/* {contentType === "News" && (
          <Link
            href={providerUrl}
            rel="noopener"
            target="_blank"
            underline="hover"
            variant="title2"
          >
            {providerName}
          </Link>
        )} */}

        <Stack
          alignItems="center"
          direction="row"
          spacing={theme.spacing(1)}
          sx={{ marginTop: `0 !important` }}
        >
          {contentType && (
            <>
              <Typography color="text.secondary" variant="subtitle2Bold">
                {contentType === 'Profile' ? 'PATRIOT' : contentType.toUpperCase()}
              </Typography>
              <Box
                height={theme.spacing(0.5)}
                sx={{
                  background: theme.palette.common.black,
                  borderRadius: '50%',
                }}
                width={theme.spacing(0.5)}
              />
            </>
          )}
          {/* {category && (
            <Typography
              className="text-ellipsis text-clamp-1"
              color="secondary.main"
              variant="subtitle2Bold"
            >
              {category.toUpperCase()}
            </Typography>
          )} */}
          <Typography noWrap color="text.tertiary" variant="subtitle2">
            {timeDiffCalc(date, contentType.toUpperCase() === 'PODCAST')}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
