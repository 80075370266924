import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from 'react';
import { AlertType, createAffiliateLink } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

function AffiliateLink() {
  const dispatch = useAppDispatch();
  const { affiliateLink, affiliateSyncData } = useAppSelector(s => s.affiliate);
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  useEffect(() => {
    if (affiliateLink.length === 0 && affiliateSyncData?.status === 'exists') {
      dispatch(createAffiliateLink());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopyLink = () => {
    if (navigator.clipboard) navigator.clipboard.writeText(affiliateLink);
    dispatch({
      type: AlertType.ALERT_SUCCESS,
      payload: { message: 'Link copied!' },
    });
  };

  return (
    <Stack spacing={theme.spacing(1.5)} width="100%">
      <Typography variant="title2">My Affiliate Link</Typography>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        height="100%"
        spacing={theme.spacing(2)}
        width="100%"
      >
        <Stack
          border={`0.25px solid ${theme.palette.text.secondary}`}
          borderRadius={theme.spacing(0.5)}
          boxSizing="border-box"
          flexGrow={1}
          justifyContent="center"
          padding={theme.spacing(0, 2)}
          width="100%"
        >
          <Typography
            component="div"
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            variant="h6"
          >
            {affiliateLink}
          </Typography>
        </Stack>
        <Button
          disableRipple
          size="large"
          sx={{
            textTransform: 'none',
            whiteSpace: 'noWrap',
          }}
          variant="contained"
          onClick={handleCopyLink}
        >
          Copy Link
        </Button>
      </Stack>
    </Stack>
  );
}

export default AffiliateLink;
