enum PaymentType {
  GET_CARDS_REQUEST = 'GET_CARDS_REQUEST',
  GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS',
  GET_CARDS_ERROR = 'GET_CARDS_ERROR',

  SHOW_CARD_FORM = 'SHOW_CARD_FORM',

  ADD_CARD_REQUEST = 'ADD_CARD_REQUEST',
  ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS',
  ADD_CARD_ERROR = 'ADD_CARD_ERROR',

  SET_PREVIEW_CARD_INDEX = 'SET_PREVIEW_CARD_INDEX',

  EDIT_CARD_REQUEST = 'EDIT_CARD_REQUEST',
  EDIT_CARD_SUCCESS = 'EDIT_CARD_SUCCESS',
  EDIT_CARD_ERROR = 'EDIT_CARD_ERROR',

  DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST',
  DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS',
  DELETE_CARD_ERROR = 'DELETE_CARD_ERROR',

  RESET_PAYMENT_STATE = 'RESET_PAYMENT_STATE',
}

export enum CardFormType {
  ADD = 1,
  EDIT = 2,
}

export default PaymentType;
