import LoadingButton from '@mui/lab/LoadingButton';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/system/Box';
import ToggleDrawerDialog from 'components/customDrawer/ToggleDrawerDialog';
import CustomInputField from 'components/customFields/CustomInputField';
import { EllipsisIconLarge } from 'helpers/images';
import React, { useState } from 'react';
import { IReportCommentParams, IUserReportState } from 'store';
import { userContentReport } from 'store/comment/CommentReducer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { userReportValidationSchema } from 'utils/validationSchema';

import CustomMenu from './CustomMenu';
import useUserReportForm from './userReportForm';

interface IBusinessPostMenuProps {
  type: string;
  isOwner: boolean;
  contentId: string;
  postContentType: string;
  contentType: 'comment' | 'content';
  handleDelete: () => void;
  handleEdit: () => void;
  isDeleteCommentLoading?: boolean;
}

const initialState: IUserReportState = {
  other: '',
  reportOptions: '',
};

const REPORT_OPTIONS = [
  {
    label: 'Offensive/Harmful',
    value: 'offensive/harmful',
  },
  {
    label: 'Illegal',
    value: 'illegal',
  },
  {
    label: 'Spam',
    value: 'spam',
  },
  {
    label: 'Fake Account',
    value: 'fake_account',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

function BusinessPostMenu(props: IBusinessPostMenuProps) {
  const {
    type,
    handleDelete,
    handleEdit,
    isDeleteCommentLoading = false,
    contentId,
    contentType,
    postContentType,
    isOwner,
  } = props;

  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { isDeleteBusinessPostLoading } = useAppSelector(s => s.business);
  const { reportContentLoading } = useAppSelector(s => s.comment);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const deleteHandler = () => {
    handleDelete();
    handleClose();
    setShowDeleteModal(false);
  };

  const submitReport = () => {
    const params = {
      postType: contentType,
      postId: contentId,
      category: values.reportOptions,
      type: postContentType,
    };

    dispatch(userContentReport(params as IReportCommentParams));

    handleCloseReportModal();
  };

  const formik = useUserReportForm(submitReport, initialState, userReportValidationSchema);

  const {
    dirty,
    errors,
    resetForm,
    handleSubmit,
    handleChange,
    handleBlur,
    isValid,
    touched,
    values,
  } = formik;

  const handleCloseReportModal = () => {
    resetForm();
    setShowReportModal(false);
  };

  return (
    <>
      <CustomMenu
        excludeMarginTop
        anchorEl={anchorEl}
        open={open}
        variant="menu"
        onClick={handleClose}
        onClose={handleClose}
      >
        {isOwner && (
          <div>
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={() => setShowDeleteModal(true)}>Delete</MenuItem>
          </div>
        )}

        {!isOwner && <MenuItem onClick={() => setShowReportModal(true)}>Report</MenuItem>}
      </CustomMenu>
      <Box alignSelf="flex-start" display="flex" sx={{ cursor: 'pointer' }} onClick={handleClick}>
        <IconButton sx={{ padding: theme.spacing(1.75, 1) }}>
          <EllipsisIconLarge />
        </IconButton>
      </Box>
      {showDeleteModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={() => setShowDeleteModal(false)}
          isMobile={isMobile}
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          title={`Delete ${type === 'post' ? 'Post' : 'Comment'}`}
        >
          <Stack margin={theme.spacing(0, 2)} spacing={theme.spacing(3)}>
            <Stack alignItems="flex-start" spacing={theme.spacing(3)}>
              <Typography variant={isMobile ? 'h6' : 'title2'}>
                Are you sure you want to delete this?
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction={isMobile ? 'column' : 'row'}
              justifyContent="center"
              marginTop={`${theme.spacing(4)} !important`}
              spacing={theme.spacing(3)}
              width="100%"
            >
              <Button
                fullWidth
                size="large"
                variant="outlined"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                disableRipple
                disableTouchRipple
                fullWidth
                disabled={type === 'post' ? isDeleteBusinessPostLoading : isDeleteCommentLoading}
                loading={type === 'post' ? isDeleteBusinessPostLoading : isDeleteCommentLoading}
                size="large"
                type="button"
                variant="contained"
                onClick={deleteHandler}
              >
                Confirm
              </LoadingButton>
            </Stack>
          </Stack>
        </ToggleDrawerDialog>
      )}

      {showReportModal && (
        <ToggleDrawerDialog
          showCloseIcon
          handleClose={handleCloseReportModal}
          isMobile={isMobile}
          open={showReportModal}
          setOpen={setShowReportModal}
          title="Help us understand the problem"
        >
          <Stack margin={theme.spacing(0, 2)} spacing={theme.spacing(3)}>
            <Stack alignItems="flex-start" spacing={theme.spacing(0.5)}>
              <Typography variant={isMobile ? 'h6' : 'title2'}>
                {`Why don't you want to see this ${contentType}?`}
              </Typography>

              <Typography color="GrayText" variant="caption">
                {`Reporting ${contentType} will remove it immediately from your view. Freedom Square values your input and will review this ${contentType}.`}
              </Typography>

              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="reportOptions"
                value={values.reportOptions}
                onChange={handleChange}
              >
                {REPORT_OPTIONS.map(item => {
                  return (
                    <FormControlLabel control={<Radio />} label={item.label} value={item.value} />
                  );
                })}
              </RadioGroup>
              {values.reportOptions === 'other' ? (
                <CustomInputField
                  fullWidth
                  required
                  error={!!(touched.other && errors.other)}
                  errormessage={touched.other ? errors.other : undefined}
                  id="userName"
                  inputProps={{ maxLength: 75 }}
                  name="other"
                  placeholder="Please describe problem"
                  type="text"
                  value={values.other}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              ) : null}
            </Stack>

            <Stack
              alignItems="center"
              direction={isMobile ? 'column' : 'row'}
              justifyContent="center"
              marginTop={`${theme.spacing(4)} !important`}
              spacing={theme.spacing(3)}
              width="100%"
            >
              <Button fullWidth size="large" variant="outlined" onClick={handleCloseReportModal}>
                Cancel
              </Button>
              <LoadingButton
                disableRipple
                disableTouchRipple
                fullWidth
                disabled={!(isValid && dirty)}
                loading={reportContentLoading}
                size="large"
                type="button"
                variant="contained"
                onClick={() => handleSubmit()}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Stack>
        </ToggleDrawerDialog>
      )}
    </>
  );
}

export default BusinessPostMenu;
