import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/system/Stack';
import CustomInputField from 'components/customFields/CustomInputField';
import PasswordEyeIcon from 'components/customFields/PasswordEyeIcon';
import AuthWrapper from 'components/wrapper/AuthWrapper';
import { UserType } from 'constants/constants';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ILoginState, IResponseData, linkAffiliate, loginUser, sendVerificationMail } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { clearAffiliateSessionData, CookieNames, getCookieItem } from 'utils/cookies';
import { scrollToTop } from 'utils/utils';

import useLoginForm from './useLoginForm';

type RedirectLocationState = {
  redirectTo: Location;
};

function Login() {
  const initialState: ILoginState = {
    email: '',
    password: '',
  };

  const { state: locationState } = useLocation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { loading } = useAppSelector(s => s.auth);
  const { isGetBusinessDetailsLoading } = useAppSelector(s => s.business);
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const useQuery = () => new URLSearchParams(useLocation().search);

  const query = useQuery();

  const isBusinessRedirect = query.get('post_login_redirect');

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const linkAffiliateById = () => {
    const affiliateId = getCookieItem(CookieNames.AFFILIATE_ID);
    const userId = getCookieItem(CookieNames.USER_ID);
    if (affiliateId.length > 0 && affiliateId !== userId) {
      dispatch(linkAffiliate(affiliateId)).then(() => clearAffiliateSessionData());
    }
  };

  const navToHome = (affiliateId: string) => {
    if (locationState) {
      const { redirectTo } = locationState as RedirectLocationState;
      navigate(`${redirectTo.pathname}${redirectTo.search}`);
    } else {
      if (affiliateId.length > 0) clearAffiliateSessionData();
      navigate('/home');
    }
  };

  const handleCheckAffiliate = async (hasAffiliateId: boolean) => {
    const affiliateId = getCookieItem(CookieNames.AFFILIATE_ID);
    if (!hasAffiliateId && affiliateId.length > 0) {
      linkAffiliateById();
      navToHome(affiliateId);
    } else {
      navToHome(affiliateId);
    }
  };

  const onSubmit = async () => {
    const res = await dispatch(loginUser(values.email, values.password));
    const { isFirstLogin, user } = res.data || {};

    if (res && typeof res.error !== 'undefined' && !res.error) {
      if (isBusinessRedirect) {
        if (['business-setup'].includes(isBusinessRedirect)) {
          return navigate('/business-setup', { state: { isPaymentReq: true } });
        }
        return navigate('/business-setup');
      }
      if (
        isFirstLogin &&
        ((user?.userType === UserType.FREE && user?.businessType === UserType.FREE) ||
          (user?.userType === UserType.FREE && user?.businessType === UserType.PAYMENT_INITIATED))
      ) {
        linkAffiliateById();
        navigate('/business-setup');
      } else if (
        isFirstLogin &&
        user?.userType === UserType.PAYMENT_INITIATED &&
        user?.businessType === ''
      ) {
        linkAffiliateById();
        navigate('/profile-setup');
      } else {
        handleCheckAffiliate(user?.affiliateUserId.length > 0);
      }
    } else if (res.error && res.status === 406) {
      const sendVerificationMailResponse: IResponseData = (await dispatch(
        sendVerificationMail(values.email),
      )) as IResponseData;
      if (!sendVerificationMailResponse.error) {
        navigate('/verification-mail', {
          state: {
            email: values.email,
            page: 'emailVerification',
          },
        });
      }
    }
    return true;
  };

  const handleSpaces = (value: string) => {
    return value !== ' ' ? value.replace(/\s+/g, '') : value.trim();
  };

  const formik = useLoginForm(onSubmit, initialState);
  const { touched, values, errors, handleChange, handleBlur, handleSubmit, isValid, dirty } =
    formik;

  return (
    <AuthWrapper>
      <Stack
        noValidate
        component="form"
        spacing={theme.spacing(4)}
        width="100%"
        onSubmit={handleSubmit}
      >
        <Typography
          align="center"
          color="text.secondary"
          component="div"
          variant={isMobile ? 'h5' : 'h2'}
        >
          Welcome Back
        </Typography>
        <Stack spacing={theme.spacing(2.5)}>
          <CustomInputField
            fullWidth
            error={!!(touched.email && errors.email)}
            errormessage={touched.email ? errors.email : undefined}
            id="email"
            label="Email"
            name="email"
            type="email"
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <CustomInputField
            fullWidth
            endAdornment={
              <PasswordEyeIcon handleOnClick={handleTogglePassword} showPassword={showPassword} />
            }
            error={!!(touched.password && errors.password)}
            errormessage={touched.password ? errors.password : undefined}
            id="password"
            label="Password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            onBlur={handleBlur}
            onChange={e => handleChange('password')(handleSpaces(e.target.value))}
          />
          <Typography
            align="right"
            color={theme.palette.secondary.main}
            component="span"
            marginTop={`${theme.spacing(1.5)} !important`}
            sx={{ cursor: 'pointer', fontWeight: '500' }}
            variant="h6"
            onClick={() => navigate('/forgot-password')}
          >
            Forgot Password?
          </Typography>
        </Stack>
        <LoadingButton
          disableRipple
          disableTouchRipple
          fullWidth
          disabled={!(isValid && dirty)}
          loading={loading || isGetBusinessDetailsLoading}
          size="large"
          sx={{ whiteSpace: 'nowrap' }}
          type="submit"
          variant="contained"
        >
          Sign In
        </LoadingButton>
        <Typography align="center" marginTop={theme.spacing(3)} variant="h6">
          Don&apos;t have an account?
          <Typography
            color={theme.palette.secondary.main}
            component="span"
            marginLeft={theme.spacing(1)}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              fontWeight: '500',
            }}
            onClick={() => navigate('/signup')}
          >
            Sign Up Now
          </Typography>
        </Typography>
      </Stack>
    </AuthWrapper>
  );
}

export default Login;
