import ErrorMessages from 'constants/ErrorMessages';
import jwt_decode from 'jwt-decode';
import { Dispatch } from 'redux';
import {
  AccountActions,
  AlertActions,
  AlertType,
  IAlertPayload,
  IResponseData,
  ResponseCodes,
} from 'store';
import {
  clearSession,
  CookieNames,
  getCookieItem,
  getTokenExpiry,
  setCookieItem,
  setSession,
} from 'utils/cookies';

import client from '../../Api';
import { authUrl } from '../../helpers/urls';
import AccountType from '../account/AccountTypes';
import { AuthActions, ISignUpPayload } from './AuthenticationInterface';
import AuthenticationType from './AuthenticationTypes';

export const signUpUser = (payload: ISignUpPayload) => {
  return async (dispatch: Dispatch<AuthActions | AccountActions | AlertActions>) => {
    try {
      dispatch({ type: AuthenticationType.SIGNUP_USER_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}signup`, payload);
      dispatch({ type: AuthenticationType.SIGNUP_USER_SUCCESS });
      dispatch({
        type: AccountType.GET_USER_INFO_SUCCESS,
        payload: response.data?.user,
      });
      return response;
    } catch (error) {
      const e = error as IResponseData;
      if (e?.status !== ResponseCodes.BAD_REQUEST) {
        const errorPayload: IAlertPayload = { message: e?.message };
        dispatch({
          type: AlertType.ALERT_ERROR,
          payload: errorPayload,
        });
      }
      dispatch({ type: AuthenticationType.SIGNUP_USER_ERROR });
      return error;
    }
  };
};

export const checkUserExists = async (query: string) => {
  try {
    const response: IResponseData = await client.get(`${authUrl}users/exists?${query}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const loginUser = (email: string, password: string) => {
  return async (
    dispatch: Dispatch<AuthActions | AlertActions | AccountActions>,
  ): Promise<IResponseData> => {
    try {
      dispatch({ type: AuthenticationType.LOGIN_USER_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}login`, {
        email,
        password,
      });
      const decoded: any = jwt_decode(response.data?.accessToken);

      setSession({
        accessToken: response.data?.accessToken,
        documentId: response.data?.user?.documentId,
        refreshToken: response.data?.refreshToken,
        userRole: decoded.role,
        userType: response.data?.user?.userType,
        businessType: response.data?.user?.businessType,
      });
      dispatch({ type: AuthenticationType.LOGIN_USER_SUCCESS });
      dispatch({
        type: AccountType.GET_USER_INFO_SUCCESS,
        payload: response.data?.user,
      });
      return response;
    } catch (error: any) {
      const e = error as IResponseData;
      if (
        [ResponseCodes.BAD_REQUEST, ResponseCodes.NOT_FOUND].includes(e?.status as ResponseCodes)
      ) {
        const errorPayload: IAlertPayload = {
          message:
            ResponseCodes.NOT_FOUND === e.status
              ? ErrorMessages.login.wrongCredentials
              : e?.message,
        };
        dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      }
      dispatch({ type: AuthenticationType.LOGIN_USER_ERROR });
      return error;
    }
  };
};

export const logout = () => {
  return async (dispatch: Dispatch<AuthActions>) => {
    try {
      // const response = await client.post(`${authUrl}signout`);
      clearSession();
      dispatch({ type: AuthenticationType.USER_LOGOUT });
      return null;
    } catch (error) {
      return error;
    }
  };
};

export const updateLoggedInStatus = (payload: boolean | null) => {
  return async (dispatch: Dispatch<AuthActions>) => {
    dispatch({ type: AuthenticationType.UPDATE_LOGGEDIN_STATUS, payload });
  };
};

export const sendResetPassword = (email: string) => {
  return async (dispatch: Dispatch<AuthActions>): Promise<IResponseData> => {
    try {
      dispatch({ type: AuthenticationType.SEND_RESET_PASSWORD_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}sendResetPassword`, { email });
      dispatch({
        type: AuthenticationType.SEND_RESET_PASSWORD_SUCCESS,
        payload: response,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: AuthenticationType.SEND_RESET_PASSWORD_ERROR });
      return error;
    }
  };
};

export const resetPassword = (newPassword: string, token: string) => {
  return async (dispatch: Dispatch<AuthActions>) => {
    try {
      dispatch({ type: AuthenticationType.RESET_PASSWORD_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}resetPassword`, {
        token,
        newPassword,
      });
      dispatch({ type: AuthenticationType.RESET_PASSWORD_SUCCESS });
      return response;
    } catch (error: unknown) {
      dispatch({ type: AuthenticationType.RESET_PASSWORD_ERROR });
      return error;
    }
  };
};

export const sendVerificationMail = (email: string) => {
  return async (dispatch: Dispatch<AuthActions | AlertActions>) => {
    try {
      dispatch({ type: AuthenticationType.SEND_VERIFICATION_EMAIL_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}users/email/verify`, {
        email: window.decodeURIComponent(email),
      });
      dispatch({ type: AuthenticationType.SEND_VERIFICATION_EMAIL_SUCCESS });
      return response;
    } catch (error: unknown) {
      dispatch({ type: AuthenticationType.SEND_VERIFICATION_EMAIL_ERROR });
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = {
        message: e?.message,
      };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const verifyToken = (token: string) => {
  return async (dispatch: Dispatch<AuthActions | AlertActions>) => {
    try {
      dispatch({ type: AuthenticationType.VERIFY_TOKEN_REQUEST });
      const response: IResponseData = await client.get(`${authUrl}verify/emailToken/${token}`);
      dispatch({ type: AuthenticationType.VERIFY_TOKEN_SUCCESS });
      return response;
    } catch (error: unknown) {
      dispatch({ type: AuthenticationType.VERIFY_TOKEN_ERROR });
      // const e = error as IResponseData;
      // const errorPayload: IAlertPayload = {
      //   message: e?.message,
      // };
      // dispatch({
      //   type: AlertType.ALERT_ERROR,
      //   payload: errorPayload,
      // });
      return error;
    }
  };
};

export const verifyEmail = (token: string) => {
  return async (dispatch: Dispatch<AuthActions | AlertActions>) => {
    try {
      dispatch({ type: AuthenticationType.VERIFY_EMAIL_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}verify/email`, {
        token,
      });
      dispatch({ type: AuthenticationType.VERIFY_EMAIL_SUCCESS });
      return response;
    } catch (error: unknown) {
      dispatch({ type: AuthenticationType.VERIFY_EMAIL_ERROR });
      return error;
    }
  };
};

export const refreshToken = async () => {
  try {
    const rToken = getCookieItem(CookieNames.REFRESH_TOKEN);
    if (rToken === '') {
      clearSession(true);
    }
    const data = { refreshToken: rToken };
    const response: IResponseData = await client.post(`${authUrl}users/refreshToken`, data);
    if (response.data?.accessToken) {
      const tokenExpiry: any = getTokenExpiry();
      setCookieItem(CookieNames.REFRESH_TOKEN, rToken, tokenExpiry);
      setCookieItem(CookieNames.ACCESS_TOKEN, response.data.accessToken, tokenExpiry);
    }
    return response;
  } catch (error: any) {
    return error;
  }
};
