import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageBadgeComponent from 'components/business/image/ImageBadgeComponent';
import UploadImageComponent from 'components/business/image/UploadImageComponent';
import CustomInputField from 'components/customFields/CustomInputField';
import CustomSelectField from 'components/customSelect/CustomSelectField';
import { AD_IMAGE_RESOLUTIONS, IMAGE_EXTENSIONS } from 'constants/constants';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import {
  alertError,
  AlertType,
  createAdsCoupons,
  editSponsorAdCoupons,
  IAddAdsState,
  IAlertPayload,
  IAllSponsorsReport,
  ImageType,
  IResponseData,
  ISponsorAd,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { processImage } from 'utils/utils';
import { addAdsValidationSchema } from 'utils/validationSchema';
import { validateFileFormat, validateFileResolution, validateFileSize } from 'utils/validator';

import useAddAdsForm from './useAddAdsForm';

interface IAddAdsProps {
  closeModal: () => void;
  onCreate: () => void;
  sponsorId: string;
  sponsorAd?: ISponsorAd | IAllSponsorsReport;
  sponsorType?: string;
}

interface IPage {
  value: string;
  label: string;
}
const { AD, COUPON } = ImageType;

const ALL_PAGE_POSITIONS: IPage[] = [
  { value: 'businesses-A', label: 'Businesses-A' },
  { value: 'businesses-MA', label: 'Businesses-MA' },
  { value: 'following-A', label: 'Following-A' },
  { value: 'following-MA', label: 'Following-MA' },

  { value: 'home-A', label: 'Home-A' },
  { value: 'home-B', label: 'Home-B' },
  { value: 'home-C', label: 'Home-C' },
  { value: 'home-MA', label: 'Home-MA' },
  { value: 'home-MB', label: 'Home-MB' },
  { value: 'home-MC', label: 'Home-MC' },

  { value: 'news-A', label: 'News-A' },
  { value: 'news-B', label: 'News-B' },
  { value: 'news-C', label: 'News-C' },
  { value: 'news-MA', label: 'News-MA' },
  { value: 'news-MB', label: 'News-MB' },
  { value: 'news-MC', label: 'News-MC' },

  { value: 'podcasts-A', label: 'Podcasts-A' },
  { value: 'podcasts-B', label: 'Podcasts-B' },
  { value: 'podcasts-C', label: 'Podcasts-C' },
  { value: 'podcasts-MA', label: 'Podcasts-MA' },
  { value: 'podcasts-MB', label: 'Podcasts-MB' },
  { value: 'podcasts-MC', label: 'Podcasts-MC' },
];

function AddAds(props: IAddAdsProps) {
  const { closeModal, onCreate, sponsorId, sponsorAd, sponsorType = 'ad' } = props;

  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { sponsorAds, addAdsLoading } = useAppSelector(s => s.vettingDashboard);

  const [mediaFile, setMediaFile] = useState<File | null>();
  const [mediaFileURL, setMediaFileURL] = useState('');
  const [pagePositions, setPagePositions] = useState(ALL_PAGE_POSITIONS);

  useEffect(() => {
    if (sponsorAd) {
      setMediaFileURL(sponsorAd.media);
    }
  }, [sponsorAd]);

  useEffect(() => {
    if (sponsorAds.length > 0) {
      let positions: IPage[] = [];
      ALL_PAGE_POSITIONS.forEach(x => {
        const pagePosition = x.value.split('-');
        const index = sponsorAds.findIndex(
          sa => sa.page === pagePosition[0] && sa.position === pagePosition[1],
        );
        if (index === -1) {
          positions.push(x);
        } else if (sponsorAd) {
          if (sponsorAd.page === pagePosition[0] && sponsorAd.position === pagePosition[1]) {
            positions.push(x);
          }
        }
      });

      if (!sponsorAd) {
        positions = positions.filter(i => !['news-A', 'podcasts-A'].includes(i.value));
      }

      setPagePositions(positions);
    } else {
      setPagePositions(ALL_PAGE_POSITIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorAds]);

  const initialState: IAddAdsState = {
    pagePosition: sponsorAd ? `${sponsorAd.page}-${sponsorAd.position}` : 'businesses-A',
    url: sponsorAd ? sponsorAd.url : '',
    couponUrl: sponsorAd ? sponsorAd.couponUrl : '',
    sponsorType,
    startDate: sponsorAd ? moment(sponsorAd.startdate).format('YYYY-MM-DD') : '',
    endDate: sponsorAd ? moment(sponsorAd.enddate).format('YYYY-MM-DD') : '',
  };

  const handelSubmit = async () => {
    if (!mediaFileURL) {
      const errorPayload: IAlertPayload = { message: 'Media is required' };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return;
    }

    const pagePosition = values.pagePosition.split('-');

    const formData = new FormData();
    formData.append('sponsorId', sponsorId);
    formData.append('type', 'normal');
    formData.append('mediaType', 'image');
    formData.append('media', mediaFile || mediaFileURL);
    formData.append('url', values.url);
    formData.append('page', pagePosition[0]);
    formData.append('position', pagePosition[1]);
    formData.append('startdate', values.startDate || '');
    formData.append('enddate', values.endDate || '');

    let response;
    if (sponsorAd) {
      response = await dispatch(editSponsorAdCoupons(formData, sponsorAd.documentId, sponsorType));
    } else {
      response = (await dispatch(createAdsCoupons(formData, sponsorType))) as IResponseData;
    }
    if (!response.error) {
      onCreate();
    }
  };

  const formik = useAddAdsForm(handelSubmit, initialState, addAdsValidationSchema);
  const { touched, values, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    fieldName: string,
  ) => {
    if (e.target.name === 'startDate' || e.target.name === 'endDate') {
      handleChange(fieldName)(moment(e.target.value).format('YYYY-MM-DD'));
    } else {
      handleChange(fieldName)(e.target.value);
    }
  };

  const selectFile = (imgType: string) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const isImage: boolean = file.type.includes('image');
    if (target?.files?.length === 1 && isImage) {
      if (isImage && !validateFileFormat(file.name, IMAGE_EXTENSIONS)) {
        dispatch(
          alertError({
            message:
              'File format is not supported. Please select one of the following file types: jpg, jpeg, png, heic or webp.',
          }),
        );
        return;
      }
      const maxSize = 100;
      const isFileSizeValid: boolean = validateFileSize(file.size, maxSize);
      const imageDimensions = AD_IMAGE_RESOLUTIONS[values.pagePosition];

      const isFileResolutionValid = await validateFileResolution(
        file,
        imageDimensions.width,
        imageDimensions.height,
      );
      if (isFileSizeValid && isFileResolutionValid) {
        setMediaFile(file);
        setMediaFileURL(await processImage(file));
      } else {
        const errorMessage = !isFileSizeValid
          ? `The image file must be ${maxSize} MB or less.`
          : `The image resolution must be ${imageDimensions.width} * ${imageDimensions.height} or higher.`;
        dispatch(alertError({ message: errorMessage }));
      }
    } else {
      dispatch(
        alertError({
          message: 'File format is not supported. Please select an image and try again.',
        }),
      );
    }
  };

  const handleDeleteImage = () => {
    setMediaFile(null);
    setMediaFileURL('');
  };

  const handleDateRangeChange = (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(type, moment(e.target.value).format('YYYY-MM-DD'));
  };

  return (
    <Stack alignItems="center" justifyContent="center" width="100%">
      <Stack
        noValidate
        // alignItems="center"
        autoComplete="off"
        component="form"
        spacing={theme.spacing(3)}
        width="100%"
        onSubmit={handleSubmit}
      >
        <CustomSelectField
          fullWidth
          disabled={sponsorAd && Object.keys(sponsorAd).length > 0}
          inputProps={{ name: 'pagePosition' }}
          label="Page-Position"
          value={values.pagePosition}
          onBlur={handleBlur}
          onChange={e => handleInputChange(e, 'pagePosition')}
        >
          {pagePositions.map((pagePosition, index) => {
            return (
              <option key={`${pagePosition.value} - ${index + 1}`} value={pagePosition.value}>
                {pagePosition.label}
              </option>
            );
          })}
        </CustomSelectField>

        <Stack direction="row" justifyContent="space-around" spacing={theme.spacing(2.5)}>
          <Box flex={1}>
            <Typography component="h5" variant="body1Bold">
              Start Date
            </Typography>

            <CustomInputField
              required
              error={!!(touched.startDate && errors.startDate)}
              errormessage={touched.startDate ? errors.startDate : undefined}
              fullWidth={!!isMobile}
              inputProps={{ min: moment().format('YYYY-MM-DD') }}
              sx={{ borderColor: `${theme.palette.text.tertiary} !important` }}
              type="date"
              value={values.startDate}
              onChange={handleDateRangeChange('startDate')}
            />
          </Box>

          <Box flex={1}>
            <Typography component="h5" variant="body1Bold">
              End Date
            </Typography>

            <CustomInputField
              required
              error={!!(touched.endDate && errors.endDate)}
              fullWidth={!!isMobile}
              type="date"
              value={values.endDate}
              onChange={handleDateRangeChange('endDate')}
              errormessage={touched.endDate ? errors.endDate : undefined}
              // inputProps={{ max: moment().format('YYYY-MM-DD') }}
              sx={{ borderColor: `${theme.palette.text.tertiary} !important` }}
            />
          </Box>
        </Stack>

        <CustomInputField
          fullWidth
          required
          error={!!(touched.url && errors.url)}
          errormessage={touched.url ? errors.url : undefined}
          id="url"
          label={`${sponsorType === 'ad' ? 'Ad' : 'Coupon'} URL`}
          name="url"
          type="text"
          value={values.url}
          onBlur={handleBlur}
          onChange={e => handleInputChange(e, 'url')}
        />

        <Stack spacing={theme.spacing(0.75)}>
          <Typography component="h5" variant="body1Bold">
            {`${sponsorType === 'ad' ? 'Ad' : 'Coupon'} Media`}
            <Typography component="h5" sx={{ marginBottom: theme.spacing(1.5) }} variant="caption">
              {`Media image resolution must be ${
                AD_IMAGE_RESOLUTIONS[values.pagePosition].width
              } * ${AD_IMAGE_RESOLUTIONS[values.pagePosition].height} or higher.`}
            </Typography>
          </Typography>
          <Stack alignItems="start">
            {mediaFileURL ? (
              <ImageBadgeComponent
                changeImage
                showDelete
                handleDeleteImage={() => handleDeleteImage()}
                handleEditImage={() => {}}
                imageSrc={mediaFileURL}
                imageType={sponsorType}
                selectFile={selectFile(AD)}
              />
            ) : (
              <UploadImageComponent fileType={sponsorType} selectFile={selectFile(AD)} />
            )}
          </Stack>
        </Stack>

        <Stack
          alignItems="center"
          direction={isMobile ? 'column' : 'row'}
          justifyContent="center"
          marginTop={`${theme.spacing(4)} !important`}
          spacing={theme.spacing(3)}
          width="100%"
        >
          <Button fullWidth size="large" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <LoadingButton
            disableRipple
            disableTouchRipple
            fullWidth
            // disabled={!(formik.isValid && formik.dirty)}
            loading={addAdsLoading}
            size="large"
            type="submit"
            variant="contained"
          >
            Submit
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default memo(AddAds);
