import cloneDeep from 'clone-deep';
import { Reducer } from 'redux';
import { UpdateCommentCountType } from 'store/comment/CommentInterface';

import {
  AccountActions,
  IAccountState,
  IProfileMediaPostImage,
  IProfilePost,
  IUserData,
} from './AccountInterface';
import AccountType from './AccountTypes';

export const initialState: IAccountState = {
  loading: false,
  isUpdateUserDataLoading: false,
  isChangeEmailLoading: false,
  userData: {} as IUserData,
  isChangePasswordLoading: false,
  profilePhotoLoader: false,
  profileMediaLoader: false,
  isAccountDeleting: false,
  isUpdateProfileInfoLoading: false,
  profileWallLoading: false,
  profileWallData: {} as IUserData,
  profilePaymentLoading: false,
  createProfilePostLoading: false,
  editProfilePostLoading: false,
  profilePostsState: {
    offset: 0,
    hasMoreData: true,
    posts: [] as IProfilePost[],
  },
  profilePostsLoading: false,
  deleteProfilePostLoading: false,
  profilePostDetailsLoading: false,
  profilePostDetails: {} as IProfilePost,
  profilePostCommentsLoading: false,
  profileWallFollowingLoading: false,
  isEditProfileCommentLoading: false,
  isDeleteProfileCommentLoading: false,
  profileMediaLoading: false,
  profileMediaState: {
    offset: 0,
    hasMoreData: true,
    profileMedia: {
      postImages: [] as IProfileMediaPostImage[],
    },
  },
};

const AccountReducer: Reducer<IAccountState, AccountActions> = (
  state = initialState,
  action = {} as AccountActions,
) => {
  switch (action.type) {
    case AccountType.UPDATE_COMMENT_COUNT: {
      const { commentsCount } = state.profilePostDetails;
      return {
        ...state,
        profilePostDetails: {
          ...state.profilePostDetails,
          commentsCount:
            action.payload === UpdateCommentCountType.INC ? commentsCount + 1 : commentsCount - 1,
        },
      };
    }

    case AccountType.PROFILE_UPDATE_POST_CONTENT: {
      const { payload } = action;
      const { posts, offset, hasMoreData } = cloneDeep(state.profilePostsState);

      const index = posts.findIndex(i => i.documentId === payload.postId);

      if (index > -1) {
        posts[index].isReported = true;
      }

      return {
        ...state,
        profilePostsState: {
          ...state.profilePostsState,
          posts: [...posts],
          offset,
          hasMoreData,
        },
      };
    }
    case AccountType.GET_USER_INFO_REQUEST: {
      return {
        ...state,
        loading: true,
        userData: {} as IUserData,
      };
    }
    case AccountType.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };
    }
    case AccountType.GET_USER_INFO_ERROR: {
      return {
        ...state,
        loading: false,
        userData: {} as IUserData,
      };
    }
    case AccountType.UPDATE_USER_INFO_REQUEST: {
      return {
        ...state,
        isUpdateUserDataLoading: true,
      };
    }
    case AccountType.UPDATE_USER_INFO_SUCCESS: {
      const { userName } = action.payload;
      return {
        ...state,
        isUpdateUserDataLoading: false,
        userData: { ...state.userData, userName },
      };
    }
    case AccountType.UPDATE_USER_INFO_ERROR: {
      return {
        ...state,
        isUpdateUserDataLoading: false,
      };
    }

    case AccountType.CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        isChangePasswordLoading: true,
      };
    }
    case AccountType.CHANGE_PASSWORD_SUCCESS:
    case AccountType.CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        isChangePasswordLoading: false,
      };
    }
    case AccountType.UPLOAD_PROFILE_AVATAR_REQUEST: {
      return {
        ...state,
        profilePhotoLoader: true,
      };
    }
    case AccountType.UPLOAD_PROFILE_AVATAR_SUCCESS: {
      return {
        ...state,
        profilePhotoLoader: false,
        userData: action.payload,
      };
    }
    case AccountType.UPLOAD_PROFILE_AVATAR_ERROR: {
      return {
        ...state,
        profilePhotoLoader: false,
      };
    }
    case AccountType.DELETE_PROFILE_PHOTO_REQUEST: {
      return {
        ...state,
        profilePhotoLoader: true,
      };
    }
    case AccountType.DELETE_PROFILE_PHOTO_SUCCESS: {
      return {
        ...state,
        profilePhotoLoader: false,
        userData: action.payload,
      };
    }
    case AccountType.DELETE_PROFILE_PHOTO_ERROR: {
      return {
        ...state,
        profilePhotoLoader: false,
      };
    }

    case AccountType.DELETE_PROFILE_MEDIA_REQUEST: {
      return {
        ...state,
        profileMediaLoader: true,
      };
    }

    case AccountType.DELETE_PROFILE_MEDIA_SUCCESS: {
      const { imageId, imageType } = action.payload;

      const selectedIndex: number = state.userData.images.media.findIndex(
        e => e.documentId === imageId,
      );
      const media = cloneDeep(state.userData.images.media);
      if (selectedIndex !== -1) {
        media.splice(selectedIndex, 1);
      }

      return {
        ...state,
        profileMediaLoader: false,
        userData: {
          ...state.userData,
          images: {
            ...state.userData.images,
            [imageType]: media,
          },
        },
      };
    }
    case AccountType.DELETE_PROFILE_MEDIA_ERROR: {
      return {
        ...state,
        profileMediaLoader: false,
      };
    }

    //
    case AccountType.CHANGE_EMAIL_REQUEST: {
      return {
        ...state,
        isChangeEmailLoading: true,
      };
    }
    case AccountType.CHANGE_EMAIL_SUCCESS: {
      return {
        ...state,
        isChangeEmailLoading: false,
      };
    }
    case AccountType.CHANGE_EMAIL_ERROR: {
      return {
        ...state,
        isChangeEmailLoading: false,
      };
    }
    case AccountType.DELETE_ACCOUNT_REQUEST: {
      return {
        ...state,
        isAccountDeleting: true,
      };
    }
    case AccountType.DELETE_ACCOUNT_SUCCESS:
    case AccountType.DELETE_ACCOUNT_ERROR: {
      return {
        ...state,
        isAccountDeleting: false,
      };
    }
    case AccountType.UPDATE_PROFILE_INFO_REQUEST: {
      return {
        ...state,
        isUpdateProfileInfoLoading: true,
      };
    }
    case AccountType.UPDATE_PROFILE_INFO_SUCCESS: {
      return {
        ...state,
        isUpdateProfileInfoLoading: false,
        userData: { ...state.userData, ...action.payload },
      };
    }
    case AccountType.UPDATE_PROFILE_INFO_ERROR: {
      return {
        ...state,
        isUpdateProfileInfoLoading: false,
      };
    }
    case AccountType.GET_PROFILE_WALL_INFO_REQUEST: {
      return {
        ...state,
        profileWallLoading: true,
        profileWallData: {} as IUserData,
      };
    }
    case AccountType.GET_PROFILE_WALL_INFO_SUCCESS: {
      return {
        ...state,
        profileWallLoading: false,
        profileWallData: action.payload,
      };
    }
    case AccountType.GET_PROFILE_WALL_INFO_ERROR: {
      return {
        ...state,
        profileWallLoading: false,
        profileWallData: {} as IUserData,
      };
    }
    case AccountType.CREATE_PROFILE_PAYMENT_REQUEST: {
      return {
        ...state,
        profilePaymentLoading: true,
      };
    }
    case AccountType.CREATE_PROFILE_PAYMENT_SUCCESS: {
      return {
        ...state,
        profilePaymentLoading: false,
      };
    }
    case AccountType.CREATE_PROFILE_PAYMENT_ERROR: {
      return {
        ...state,
        profilePaymentLoading: false,
      };
    }
    case AccountType.FETCH_PROFILE_POSTS_REQUEST: {
      return {
        ...state,
        profilePostsLoading: true,
      };
    }

    case AccountType.FETCH_PROFILE_POSTS_SUCCESS: {
      const { offset, hasMoreData, reset, posts } = action.payload;

      let updatedData = [...posts];

      if (!reset) {
        updatedData = [...state.profilePostsState.posts, ...action.payload.posts];
      }
      return {
        ...state,
        profilePostsLoading: false,
        profilePostsState: {
          offset,
          hasMoreData,
          posts: updatedData,
        },
      };
    }
    case AccountType.FETCH_PROFILE_POSTS_ERROR: {
      return {
        ...state,
        profilePostsLoading: false,
      };
    }
    case AccountType.CREATE_PROFILE_POST_REQUEST: {
      return {
        ...state,
        createProfilePostLoading: true,
      };
    }
    case AccountType.CREATE_PROFILE_POST_SUCCESS: {
      return {
        ...state,
        createProfilePostLoading: false,
        profilePostsState: {
          ...state.profilePostsState,
          posts: [action.payload, ...state.profilePostsState.posts],
        },
      };
    }
    case AccountType.CREATE_PROFILE_POST_ERROR: {
      return {
        ...state,
        createProfilePostLoading: false,
      };
    }
    case AccountType.EDIT_PROFILE_POST_REQUEST: {
      return {
        ...state,
        editProfilePostLoading: true,
      };
    }
    case AccountType.EDIT_PROFILE_POST_SUCCESS: {
      const selectedIndex: number = state.profilePostsState.posts.findIndex(
        (e: IProfilePost) => e.documentId === action.payload.documentId,
      );
      const posts = cloneDeep(state.profilePostsState.posts);
      if (selectedIndex !== -1) {
        posts[selectedIndex].textContent = action.payload.textContent;
        posts[selectedIndex].updatedAt = action.payload.updatedAt;
        posts[selectedIndex].image = action.payload.image;
      }
      return {
        ...state,
        editProfilePostLoading: false,
        profilePostsState: {
          ...state.profilePostsState,
          posts,
        },
      };
    }
    case AccountType.EDIT_PROFILE_POST_ERROR: {
      return {
        ...state,
        editProfilePostLoading: false,
      };
    }
    case AccountType.DELETE_PROFILE_POST_REQUEST: {
      return {
        ...state,
        deleteProfilePostLoading: true,
      };
    }
    case AccountType.DELETE_PROFILE_POST_SUCCESS: {
      const selectedIndex: number = state.profilePostsState.posts.findIndex(
        (e: IProfilePost) => e.documentId === action.payload,
      );
      const posts = cloneDeep(state.profilePostsState.posts);
      if (selectedIndex !== -1) {
        posts.splice(selectedIndex, 1);
      }
      return {
        ...state,
        deleteProfilePostLoading: false,
        profilePostsState: {
          ...state.profilePostsState,
          posts,
        },
      };
    }
    case AccountType.DELETE_PROFILE_POST_ERROR: {
      return {
        ...state,
        deleteProfilePostLoading: false,
      };
    }
    case AccountType.LIKE_DISLIKE_PROFILE_WALL_POST_REQUEST:
    case AccountType.LIKE_DISLIKE_PROFILE_WALL_POST_ERROR: {
      return {
        ...state,
      };
    }
    case AccountType.LIKE_DISLIKE_PROFILE_WALL_POST_SUCCESS: {
      const selectedIndex = state.profilePostsState.posts.findIndex(
        (e: IProfilePost) => e.documentId === action.payload,
      );
      const posts = cloneDeep(state.profilePostsState.posts);
      if (selectedIndex !== -1) {
        const likeStatus = !posts[selectedIndex].isLiked;
        posts[selectedIndex].isLiked = likeStatus;
        posts[selectedIndex].likesCount = likeStatus
          ? posts[selectedIndex].likesCount + 1
          : posts[selectedIndex].likesCount - 1;
      }

      return {
        ...state,
        profilePostsState: {
          ...state.profilePostsState,
          posts,
        },
      };
    }

    case AccountType.LIKE_DISLIKE_PROFILE_DETAILS_POST_SUCCESS: {
      const likeStatus = !state.profilePostDetails.isLiked;

      return {
        ...state,
        profilePostDetails: {
          ...state.profilePostDetails,
          isLiked: likeStatus,
          likesCount: likeStatus
            ? state.profilePostDetails.likesCount + 1
            : state.profilePostDetails.likesCount - 1,
        },
      };
    }
    case AccountType.FETCH_PROFILE_POST_DETAILS_REQUEST: {
      return {
        ...state,
        profilePostDetailsLoading: true,
      };
    }
    case AccountType.FETCH_PROFILE_POST_DETAILS_SUCCESS: {
      return {
        ...state,
        profilePostDetailsLoading: false,
        profilePostDetails: action.payload,
      };
    }
    case AccountType.FETCH_PROFILE_POST_DETAILS_ERROR: {
      return {
        ...state,
        profilePostDetailsLoading: false,
      };
    }
    case AccountType.FOLLOW_AND_UNFOLLOW_PROFILE_WALL_REQUEST: {
      return {
        ...state,
        profileWallFollowingLoading: true,
      };
    }
    case AccountType.FOLLOW_AND_UNFOLLOW_PROFILE_WALL_SUCCESS: {
      const followingStatus = !state.profileWallData.isFollowing;
      return {
        ...state,
        profileWallFollowingLoading: false,
        profileWallData: {
          ...state.profileWallData,
          isFollowing: followingStatus,
          followersCount: followingStatus
            ? state.profileWallData.followersCount + 1
            : state.profileWallData.followersCount - 1,
        },
      };
    }
    case AccountType.FOLLOW_AND_UNFOLLOW_PROFILE_WALL_ERROR: {
      return {
        ...state,
        profileWallFollowingLoading: false,
      };
    }
    case AccountType.FETCH_PROFILE_MEDIA_REQUEST: {
      return {
        ...state,
        profileMediaLoading: true,
      };
    }

    case AccountType.FETCH_PROFILE_MEDIA_SUCCESS: {
      const { offset, hasMoreData, profileMedia, reset } = action.payload;

      let updatedData = [...(profileMedia.postImages || [])];
      if (!reset) {
        updatedData = [
          ...state.profileMediaState.profileMedia.postImages,
          ...(profileMedia?.postImages || []),
        ];
      }

      return {
        ...state,
        profileMediaLoading: false,
        profileMediaState: {
          offset,
          hasMoreData,
          profileMedia: {
            ...action.payload.profileMedia,
            postImages: updatedData,
          },
        },
      };
    }
    case AccountType.FETCH_PROFILE_MEDIA_ERROR: {
      return {
        ...state,
        profileMediaLoading: false,
      };
    }
    case AccountType.RESET_PROFILE_WALL_STATE: {
      return {
        ...state,
        profileWallData: {} as IUserData,
      };
    }
    case AccountType.RESET_PROFILE_POSTS_STATE: {
      return {
        ...state,
        profilePostsState: {
          offset: 0,
          hasMoreData: true,
          posts: [],
        },
      };
    }
    case AccountType.RESET_PROFILE_POST_DETAILS_STATE: {
      return {
        ...state,
        profilePostDetails: {} as IProfilePost,
        profilePostComments: [] as any[],
      };
    }
    case AccountType.RESET_PROFILE_MEDIA_STATE: {
      return {
        ...state,
        profileMediaState: {
          offset: 0,
          hasMoreData: true,
          profileMedia: {
            postImages: [],
          },
        },
      };
    }
    default:
      return state;
  }
};

export default AccountReducer;
