import { IBusiness, IBusinessListState, IProvider, ITimelinePost } from 'store';

import FollowingTimelineTypes from './FollowingTimelineTypes';

interface IResetFollowingTimelineState {
  type: FollowingTimelineTypes.RESET_FOLLOWING_TIMELINE_STATE;
}

interface IResetFollowingPreferencesState {
  type: FollowingTimelineTypes.RESET_FOLLOWING_PREFERENCES_STATE;
}

interface IGetFollowingPostsRequest {
  type: FollowingTimelineTypes.GET_FOLLOWING_POSTS_REQUEST;
}

interface IGetFollowingPostsSuccess {
  type: FollowingTimelineTypes.GET_FOLLOWING_POSTS_SUCCESS;
  payload: IFollowingPostsState;
}

interface IGetFollowingPostsError {
  type: FollowingTimelineTypes.GET_FOLLOWING_POSTS_ERROR;
}

interface IGetAllBusinessesRequest {
  type: FollowingTimelineTypes.GET_ALL_BUSINESSES_REQUEST;
}

interface IGetAllBusinessesSuccess {
  type: FollowingTimelineTypes.GET_ALL_BUSINESSES_SUCCESS;
  payload: IBusinessListState;
}

interface IGetAllBusinessesError {
  type: FollowingTimelineTypes.GET_ALL_BUSINESSES_ERROR;
}

interface IGetAllNewsProvidersRequest {
  type: FollowingTimelineTypes.GET_ALL_NEWS_PROVIDERS_REQUEST;
}

interface IGetAllNewsProvidersSuccess {
  type: FollowingTimelineTypes.GET_ALL_NEWS_PROVIDERS_SUCCESS;
  payload: IProvider[];
}

interface IGetAllNewsProvidersError {
  type: FollowingTimelineTypes.GET_ALL_NEWS_PROVIDERS_ERROR;
}

interface IGetAllPodcastsRequest {
  type: FollowingTimelineTypes.GET_ALL_PODCASTS_REQUEST;
}

interface IGetAllPodcastsSuccess {
  type: FollowingTimelineTypes.GET_ALL_PODCASTS_SUCCESS;
  payload: IPreferencePodcast[];
}

interface IGetAllPodcastsError {
  type: FollowingTimelineTypes.GET_ALL_PODCASTS_ERROR;
}

interface IFollowPodcastsRequest {
  type: FollowingTimelineTypes.FOLLOW_PODCASTS_REQUEST;
}

interface IFollowPodcastsSuccess {
  type: FollowingTimelineTypes.FOLLOW_PODCASTS_SUCCESS;
  payload: IPreferencePodcast[];
}

interface IFollowPodcastsError {
  type: FollowingTimelineTypes.FOLLOW_PODCASTS_ERROR;
}

interface IFollowBusinessesRequest {
  type: FollowingTimelineTypes.FOLLOW_BUSINESSES_REQUEST;
}

interface IFollowBusinessesSuccess {
  type: FollowingTimelineTypes.FOLLOW_BUSINESSES_SUCCESS;
  payload: {
    businesses: IBusiness[];
    fromSearch: boolean;
  };
}

interface IFollowBusinessesError {
  type: FollowingTimelineTypes.FOLLOW_BUSINESSES_ERROR;
}

interface IFollowNewsProvidersRequest {
  type: FollowingTimelineTypes.FOLLOW_NEWS_PROVIDERS_REQUEST;
}

interface IFollowNewsProvidersSuccess {
  type: FollowingTimelineTypes.FOLLOW_NEWS_PROVIDERS_SUCCESS;
  payload: IProvider[];
}

interface IFollowNewsProvidersError {
  type: FollowingTimelineTypes.FOLLOW_NEWS_PROVIDERS_ERROR;
}

interface ISearchBusinessesRequest {
  type: FollowingTimelineTypes.SEARCH_BUSINESSES_REQUEST;
  payload: string;
}

interface ISearchBusinessesSuccess {
  type: FollowingTimelineTypes.SEARCH_BUSINESSES_SUCCESS;
  payload: ISearchBusinessesState;
}

interface ISearchBusinessesError {
  type: FollowingTimelineTypes.SEARCH_BUSINESSES_ERROR;
}

interface IResetSearchState {
  type: FollowingTimelineTypes.RESET_SEARCH_STATE;
}

interface IResetAllBusinessesState {
  type: FollowingTimelineTypes.RESET_ALL_BUSINESSES_STATE;
}

interface IFollowingLikeDislikePostSuccess {
  type: FollowingTimelineTypes.FOLLOWING_LIKE_DISLIKE_POST_SUCCESS;
  payload: string;
}

interface IGetAllFollowedBusinessesRequest {
  type: FollowingTimelineTypes.GET_ALL_FOLLOWED_BUSINESSES_REQUEST;
}

interface IGetAllFollowedBusinessesSuccess {
  type: FollowingTimelineTypes.GET_ALL_FOLLOWED_BUSINESSES_SUCCESS;
  payload: string[];
}

interface IGetAllFollowedBusinessesError {
  type: FollowingTimelineTypes.GET_ALL_FOLLOWED_BUSINESSES_ERROR;
}

interface IGetAllFollowedPaidUsersRequest {
  type: FollowingTimelineTypes.GET_ALL_FOLLOWED_PAID_USERS_REQUEST;
}

interface IGetAllFollowedPaidUsersSuccess {
  type: FollowingTimelineTypes.GET_ALL_FOLLOWED_PAID_USERS_SUCCESS;
  payload: string[];
}
interface IGetAllFollowedPaidUsersError {
  type: FollowingTimelineTypes.GET_ALL_FOLLOWED_PAID_USERS_ERROR;
}

interface ISearchPaidUsersRequest {
  type: FollowingTimelineTypes.SEARCH_PAID_USERS_REQUEST;
  payload: string;
}

interface ISearchPaidUsersSuccess {
  type: FollowingTimelineTypes.SEARCH_PAID_USERS_SUCCESS;
  payload: ISearchPaidUsersState;
}

interface ISearchPaidUsersError {
  type: FollowingTimelineTypes.SEARCH_PAID_USERS_ERROR;
}

interface IResetAllPaidusersState {
  type: FollowingTimelineTypes.RESET_ALL_PAID_USERS_STATE;
}

interface IFollowPaidUsersRequest {
  type: FollowingTimelineTypes.FOLLOW_PAID_USERS_REQUEST;
}

interface IFollowPaidUsersSuccess {
  type: FollowingTimelineTypes.FOLLOW_PAID_USERS_SUCCESS;
  payload: {
    paidUsers: IPaidUser[];
    fromSearch: boolean;
  };
}

interface IFollowPaidUsersError {
  type: FollowingTimelineTypes.FOLLOW_PAID_USERS_ERROR;
}

interface IGetAllPaidUsersRequest {
  type: FollowingTimelineTypes.GET_ALL_PAID_USERS_REQUEST;
}

interface IGetAllPaidUsersSuccess {
  type: FollowingTimelineTypes.GET_ALL_PAID_USERS_SUCCESS;
  payload: IPaidUsersListState;
}

interface IGetAllPaidUsersError {
  type: FollowingTimelineTypes.GET_ALL_PAID_USERS_ERROR;
}
interface IResetSearchPaidUsersState {
  type: FollowingTimelineTypes.RESET_SEARCH_PAID_USERS_STATE;
}

export type FollowingTimelineActions =
  | IResetFollowingTimelineState
  | IGetFollowingPostsRequest
  | IGetFollowingPostsSuccess
  | IGetFollowingPostsError
  | IGetAllBusinessesRequest
  | IGetAllBusinessesSuccess
  | IGetAllBusinessesError
  | IGetAllNewsProvidersRequest
  | IGetAllNewsProvidersSuccess
  | IGetAllNewsProvidersError
  | IGetAllPodcastsRequest
  | IGetAllPodcastsSuccess
  | IGetAllPodcastsError
  | IFollowPodcastsRequest
  | IFollowPodcastsSuccess
  | IFollowPodcastsError
  | IFollowBusinessesRequest
  | IFollowBusinessesSuccess
  | IFollowBusinessesError
  | IFollowNewsProvidersRequest
  | IFollowNewsProvidersSuccess
  | IFollowNewsProvidersError
  | IResetFollowingPreferencesState
  | ISearchBusinessesRequest
  | ISearchBusinessesSuccess
  | ISearchBusinessesError
  | IResetSearchState
  | IResetAllBusinessesState
  | IFollowingLikeDislikePostSuccess
  | IGetAllFollowedBusinessesRequest
  | IGetAllFollowedBusinessesSuccess
  | IGetAllFollowedBusinessesError
  | IGetAllFollowedPaidUsersRequest
  | IGetAllFollowedPaidUsersSuccess
  | IGetAllFollowedPaidUsersError
  | ISearchPaidUsersRequest
  | ISearchPaidUsersSuccess
  | ISearchPaidUsersError
  | IResetAllPaidusersState
  | IFollowPaidUsersRequest
  | IFollowPaidUsersSuccess
  | IFollowPaidUsersError
  | IGetAllPaidUsersRequest
  | IGetAllPaidUsersSuccess
  | IGetAllPaidUsersError
  | IResetSearchPaidUsersState;

export interface IFollowingTimelineState {
  followingPostsState: IFollowingPostsState;
  followingPostsLoading: boolean;
  allBusinessLoading: boolean;
  allBusinesses: IBusinessListState;
  allNewsProvidersLoading: boolean;
  allNewsProviders: IProvider[];
  allPodcastsLoading: boolean;
  allPodcasts: IPreferencePodcast[];
  followPodcastsLoading: boolean;
  followBusinessesLoading: boolean;
  followNewsProvidersLoading: boolean;
  showPreferenceModal: boolean;
  isSearchBusinessLoading: boolean;
  searchedBusinesses: ISearchBusinessesState;
  followedBusinesses: string[];
  isFollowedBusinessLoading: boolean;

  allPaidUsersLoading: boolean;
  allPaidUsers: IPaidUsersListState;
  isSearchPaidUsersLoading: boolean;
  searchedPaidUsers: ISearchPaidUsersState;
  followedPaidUsers: string[];
  isFollowedPaidUsersLoading: boolean;
  followPaidUsersLoading: boolean;
}

export interface ISearchBusinessesState {
  query: string;
  page: number;
  hasMoreData: boolean;
  businesses: IBusiness[];
}
export interface IFollowingPostsState {
  offset: number;
  hasMoreData: boolean;
  posts: ITimelinePost[];
}

export interface IPreferencePodcast {
  documentId: string;
  externalShowImages: string;
  externalShowLink: string;
  isFollowed: boolean;
  images: string;
  showDescription: string;
  name: string;
  dateFeatured: string | null;
  isFeatured: boolean;
}

export enum PreferenceAccordionTypes {
  BUSINESSES = 'businesses',
  PODCASTS = 'podcasts',
  NEWS_PROVIDERS = 'news',
  USERS = 'users',
}

export interface IPaidUsersListState {
  offset: number;
  hasMoreData: boolean;
  paidUsers: IPaidUser[];
  totalCount?: number;
}

export interface IPaidUser {
  documentId: string;
  userName: string;
  fullName: string;
  isFollowing: boolean;
}

export interface ISearchPaidUsersState {
  query: string;
  page: number;
  hasMoreData: boolean;
  paidUsers: IPaidUser[];
}
