import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardNumberInput from 'components/customFields/CardNumberInput';
import CustomInputField from 'components/customFields/CustomInputField';
import CustomSelectField from 'components/customSelect/CustomSelectField';
import USStatesSelectField from 'components/customSelect/USStatesSelect';
import useCard from 'hooks/useCard';
import React, { useEffect } from 'react';
import { addCard, editCard, ICreateCardRequestData, showCardForm } from 'store';
import { IResponseData } from 'store/common/CommonInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CardFormType } from 'store/payment/PaymentTypes';
import theme from 'theme';
import {
  handleAllowOnlyNumber,
  handleAllowOnlyOneSpaceBetweenWords,
  handleAllowOnlyOneSpaceBetweenWordsAndAlphabets,
  scrollToTop,
} from 'utils/utils';

import usePaymentForm from './usePaymentForm';

export interface ICardState {
  cardAlias: string;
  name: string;
  cardInfo: {
    cardNumber: string;
    expiryMonth: string;
    expiryYear: string;
  };
  billingAddress: {
    address: string;
    city: string;
    state: string;
    zipcode: string;
  };
  setAsDefault: boolean;
}

const { ADD, EDIT } = CardFormType;

function AddCardForm() {
  const dispatch = useAppDispatch();
  const { cards, previewCardIndex, formType, loading, showForm } = useAppSelector(s => s.payment);
  const currentYear = new Date().getFullYear().toString().slice(-2);
  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
  const { removeNonNumber } = useCard();

  const initialAddState = {
    cardAlias: '',
    name: '',
    cardInfo: {
      cardNumber: '',
      expiryMonth: currentMonth,
      expiryYear: currentYear,
    },
    billingAddress: {
      address: '',
      city: '',
      state: 'Alabama',
      zipcode: '',
    },
    setAsDefault: cards.length === 0,
  };

  const card = cards[previewCardIndex];
  const initialEditState = {
    cardAlias: card?.aliasName,
    name: card?.billingName,
    cardInfo: {
      cardNumber: `Ending in ${card?.lastNumbers}  `,
      expiryMonth: card?.expirationDate.slice(0, 2),
      expiryYear: card?.expirationDate.slice(2),
    },
    billingAddress: {
      address: card?.billingAddress.address,
      city: card?.billingAddress.city,
      state: card?.billingAddress.state,
      zipcode: card?.billingAddress.zip,
    },
    setAsDefault: card?.isDefault,
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const onAddCardSubmit = async () => {
    const {
      cardAlias,
      name,
      setAsDefault,
      billingAddress: { address, city, state, zipcode },
      cardInfo: { cardNumber, expiryYear, expiryMonth },
    } = values;

    const createRequestObj: ICreateCardRequestData = {
      aliasName: cardAlias.trim(),
      billingName: name.trim(),
      numbers: removeNonNumber(cardNumber),
      expirationDate: `${expiryMonth}${expiryYear}`,
      billingAddress: {
        address: address.trim(),
        city: city.trim(),
        state,
        zip: zipcode.trim(),
      },
      isDefault: setAsDefault,
    };

    const response = (await dispatch(addCard(createRequestObj))) as IResponseData;
    if (response && typeof response.error !== 'undefined' && !response.error) {
      dispatch(showCardForm(false, undefined));
    }
  };

  const onEditCardSubmit = async () => {
    const {
      cardAlias,
      name,
      setAsDefault,
      billingAddress: { address, city, state, zipcode },
      cardInfo: { expiryYear, expiryMonth },
    } = values;

    const createRequestObj: ICreateCardRequestData = {
      aliasName: cardAlias.trim(),
      billingName: name.trim(),
      expirationDate: `${expiryMonth}${expiryYear}`,
      billingAddress: {
        address: address.trim(),
        city: city.trim(),
        state,
        zip: zipcode.trim(),
      },
      isDefault: setAsDefault,
    };

    const response = (await dispatch(
      editCard(createRequestObj, cards[previewCardIndex].documentId),
    )) as IResponseData;
    if (response && typeof response.error !== 'undefined' && !response.error) {
      dispatch(showCardForm(false, undefined));
    }
  };

  const handleDisablePastMonth = (month: string): boolean => {
    return parseInt(values.cardInfo.expiryYear, 10) > parseInt(currentYear, 10)
      ? false
      : parseInt(month, 10) < parseInt(currentMonth, 10);
  };

  const handleDisableDefaultCheckbox = () => {
    if (formType === EDIT) {
      return card?.isDefault;
    }
    return cards.length === 0;
  };

  const formik = usePaymentForm(
    formType === EDIT ? onEditCardSubmit : onAddCardSubmit,
    formType === EDIT ? initialEditState : initialAddState,
    formType as number,
  );
  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
    dirty,
  } = formik;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
    allowOnlyAlphabets = false,
  ) => {
    const value = allowOnlyAlphabets ? e.target.value.replace(/[^a-zA-Z ]/gi, '') : e.target.value;

    if (fieldName === 'name') {
      handleChange(fieldName)(handleAllowOnlyOneSpaceBetweenWordsAndAlphabets(value));
    } else {
      handleChange(fieldName)(handleAllowOnlyOneSpaceBetweenWords(value));
    }
  };

  const handleHideForm = () => {
    if (formType === ADD) {
      dispatch(showCardForm(false, undefined));
    } else {
      dispatch(showCardForm(false, undefined));
    }
  };

  useEffect(() => {
    if (
      values.cardInfo.expiryYear === currentYear &&
      !(values.cardInfo.expiryMonth > currentMonth)
    ) {
      setFieldValue('cardInfo.expiryMonth', currentMonth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.cardInfo.expiryYear]);

  return (
    <Stack
      noValidate
      autoComplete="off"
      component="form"
      spacing={theme.spacing(3)}
      onSubmit={handleSubmit}
    >
      {showForm && (
        <Typography component="div" variant="title1">
          {showForm && formType === ADD && 'Add New Card'}
          {showForm && formType === EDIT && 'Edit Card'}
        </Typography>
      )}
      <CustomInputField
        autoComplete="off"
        error={!!(touched.cardAlias && errors.cardAlias)}
        errormessage={touched.cardAlias ? errors.cardAlias : undefined}
        inputProps={{ maxLength: 75 }}
        label="Alias"
        name="cardAlias"
        type="text"
        value={values.cardAlias}
        onBlur={handleBlur}
        onChange={e => handleInputChange(e, 'cardAlias')}
      />
      <CardNumberInput
        disabled={formType === EDIT}
        errorMsg={errors.cardInfo?.cardNumber}
        handleBlur={handleBlur('cardInfo.cardNumber')}
        handleChange={handleChange('cardInfo.cardNumber')}
        label="Card Number"
        name="cardInfo.cardNumber"
        setFieldValue={setFieldValue}
        sx={{
          '& .Mui-disabled': {
            WebkitTextFillColor: theme.palette.text.primary,
            fontWeight: 'bold',
          },
        }}
        touched={touched.cardInfo?.cardNumber}
        value={values.cardInfo.cardNumber}
      />
      <CustomInputField
        autoComplete="off"
        error={!!(touched.name && errors?.name)}
        errormessage={touched.name ? errors?.name : undefined}
        inputProps={{ maxLength: 30 }}
        label="Billing Name"
        name="name"
        type="text"
        value={values.name}
        onBlur={handleBlur}
        onChange={e => handleInputChange(e, 'name')}
      />
      <Stack direction="row" spacing={theme.spacing(2)} width="100%">
        <CustomSelectField
          error={!!(touched.cardInfo?.expiryMonth && errors?.cardInfo?.expiryMonth)}
          errormessage={touched.cardInfo?.expiryMonth ? errors?.cardInfo?.expiryMonth : undefined}
          inputProps={{
            name: 'cardInfo.expiryMonth',
          }}
          label="Expiry Month"
          value={values.cardInfo.expiryMonth}
          onBlur={handleBlur}
          onChange={handleChange}
        >
          {Array(12)
            .fill('')
            .map((_, index) => {
              const month = (index + 1).toString().padStart(2, '0');
              return (
                <option
                  key={`expiryMonth-${index + 1}`}
                  disabled={handleDisablePastMonth(month)}
                  value={month}
                >
                  {month}
                </option>
              );
            })}
        </CustomSelectField>
        <CustomSelectField
          error={!!(touched.cardInfo?.expiryYear && errors?.cardInfo?.expiryYear)}
          errormessage={touched.cardInfo?.expiryYear ? errors?.cardInfo?.expiryYear : undefined}
          inputProps={{
            name: 'cardInfo.expiryYear',
          }}
          label="Expiry Year"
          value={values.cardInfo?.expiryYear}
          onBlur={handleBlur}
          onChange={handleChange}
        >
          {Array(16)
            .fill('')
            .map((_, index) => (
              <option key={`expiryYear-${index + 1}`} value={parseInt(currentYear, 10) + index}>
                {parseInt(currentYear, 10) + index}
              </option>
            ))}
        </CustomSelectField>
      </Stack>
      <Divider
        sx={{
          borderColor: theme.palette.secondary.main,
        }}
      />
      <CustomInputField
        autoComplete="new-password"
        error={!!(touched.billingAddress?.address && errors?.billingAddress?.address)}
        errormessage={touched.billingAddress?.address ? errors?.billingAddress?.address : undefined}
        inputProps={{ maxLength: 32 }}
        label="Billing Address"
        name="billingAddress.address"
        type="text"
        value={values.billingAddress.address}
        onBlur={handleBlur}
        onChange={e => handleInputChange(e, 'billingAddress.address')}
      />
      <Stack direction="row" spacing={theme.spacing(2)} width="100%">
        <CustomInputField
          fullWidth
          autoComplete="new-password"
          error={!!(touched.billingAddress?.city && errors?.billingAddress?.city)}
          errormessage={touched.billingAddress?.city ? errors?.billingAddress?.city : undefined}
          inputProps={{ maxLength: 36 }}
          label="City"
          name="billingAddress.city"
          type="text"
          value={values.billingAddress.city}
          onBlur={handleBlur}
          onChange={e => handleInputChange(e, 'billingAddress.city', true)}
        />
        <USStatesSelectField
          error={!!(touched.billingAddress?.state && errors?.billingAddress?.state)}
          errormessage={touched.billingAddress?.state ? errors?.billingAddress?.state : undefined}
          inputProps={{
            name: 'billingAddress.state',
          }}
          label="State"
          value={values.billingAddress.state}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Stack>
      <Grid item paddingRight={theme.spacing(1)} xs={6}>
        <CustomInputField
          autoComplete="new-password"
          error={!!(touched?.billingAddress?.zipcode && errors?.billingAddress?.zipcode)}
          errormessage={
            touched?.billingAddress?.zipcode ? errors?.billingAddress?.zipcode : undefined
          }
          inputProps={{ maxLength: 5 }}
          label="Zip Code"
          name="billingAddress.zipcode"
          type="text"
          value={values.billingAddress.zipcode}
          onBlur={handleBlur}
          onChange={e => handleInputChange(e, 'billingAddress.zipcode')}
          onKeyPress={handleAllowOnlyNumber}
        />
      </Grid>
      <Stack direction="column" spacing={theme.spacing(3)}>
        <Divider
          sx={{
            borderColor: theme.palette.secondary.main,
          }}
        />
        <Stack alignItems="center" alignSelf="flex-start" direction="row" justifyContent="center">
          <Checkbox
            disableRipple
            checked={values.setAsDefault}
            color="secondary"
            id="setAsDefault"
            name="setAsDefault"
            sx={{
              p: 0,
              pointerEvents: handleDisableDefaultCheckbox() ? 'none' : 'auto',
            }}
            onChange={handleChange}
          />
          <Typography marginLeft={theme.spacing(1)}>Set as Default card</Typography>
        </Stack>
        <Divider
          sx={{
            borderColor: theme.palette.secondary.main,
          }}
        />
      </Stack>
      <Stack direction="row" spacing={theme.spacing(3)}>
        <LoadingButton
          disabled={!(isValid && dirty)}
          loading={loading}
          size="medium"
          sx={{ width: '100%' }}
          type="submit"
          variant="contained"
        >
          {formType === ADD && 'Add Card'}
          {formType === EDIT && 'Save'}
        </LoadingButton>
        <Button
          disabled={loading}
          size="medium"
          sx={{ width: '100%' }}
          type="submit"
          variant="outlined"
          onClick={handleHideForm}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

export default AddCardForm;
