import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import AuthWrapper from 'components/wrapper/AuthWrapper';
import React from 'react';
import theme from 'theme';

function DeleteAccountSuccessPage() {
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  return (
    <AuthWrapper>
      <Stack spacing={theme.spacing(4)}>
        <Typography
          align="center"
          color="text.secondary"
          component="div"
          variant={isMobile ? 'h5' : 'h4'}
        >
          Delete Account Confirmed
        </Typography>
        <Typography
          align="center"
          color="text.secondary"
          fontWeight={400}
          marginTop={theme.spacing(1.5)}
          variant={isMobile ? 'menu' : 'title2'}
        >
          Please check your email for further details.
        </Typography>
      </Stack>
    </AuthWrapper>
  );
}

export default DeleteAccountSuccessPage;
