import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'components/infiniteScroll/InfiniteScroll';
import Loader from 'components/loader/Loader';
import ModalContainer from 'components/modal/ModalContainer';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IBusinessImageData, IBusinessMediaPostImage } from 'store';
import { fetchBusinessMedia, resetBusinessMediaState } from 'store/business/BusinessAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

function BusinessMedia() {
  const { businessId } = useParams();

  const dispatch = useAppDispatch();

  const {
    businessMediaLoading,
    businessMediaState: { businessMedia, hasMoreData },
  } = useAppSelector(s => s.business);

  const {
    businessImages: { background, logo, media },
    postImages,
  } = businessMedia;

  const getBusinessPosts = () => {
    dispatch(fetchBusinessMedia(businessId || '', postImages.length, 8));
  };

  const [selectedImage, setSelectedImage] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  useEffect(() => {
    return () => {
      dispatch(resetBusinessMediaState());
    };
  }, [dispatch]);

  const onImageClick = (imageUrl: string, title: string) => {
    setModalTitle(title);
    setSelectedImage(imageUrl);
  };

  return (
    <>
      <InfiniteScroll
        loadOnMount
        hasMoreData={hasMoreData}
        isLoading={businessMediaLoading}
        onBottomHit={getBusinessPosts}
      >
        <Stack spacing={theme.spacing(5)}>
          <Stack width="100%">
            <Typography component="h4" variant="h4">
              LOGO
            </Typography>
            <Divider
              sx={{
                borderBottomWidth: '1px',
                borderColor: theme.palette.divider,
                margin: theme.spacing(2, 0),
              }}
            />

            {logo?.croppedImageReadUrl ? (
              <Stack
                width={{ md: '25%', xs: '100%' }}
                onClick={() => onImageClick(logo?.croppedImageReadUrl, 'Logo')}
              >
                <img
                  alt="logo-img"
                  className="cursor-pointer"
                  src={logo?.croppedImageReadUrl}
                  style={{ borderRadius: '10px' }}
                  width="100%"
                />
              </Stack>
            ) : !businessMediaLoading ? (
              <Typography component="h6" variant="h6Bold">
                No Logo available
              </Typography>
            ) : (
              <Loader />
            )}
          </Stack>

          <Stack width="100%">
            <Typography component="h4" variant="h4">
              BACKGROUND
            </Typography>
            <Divider
              sx={{
                borderBottomWidth: '1px',
                borderColor: theme.palette.divider,
                margin: theme.spacing(2, 0),
              }}
            />
            {background?.croppedImageReadUrl ? (
              <Stack
                width={{ md: '50%', xs: '100%' }}
                onClick={() => onImageClick(background?.croppedImageReadUrl, 'Background')}
              >
                <img
                  alt="background-img"
                  className="cursor-pointer"
                  src={background?.croppedImageReadUrl}
                  style={{ borderRadius: '10px' }}
                  width="100%"
                />
              </Stack>
            ) : !businessMediaLoading ? (
              <Typography component="h6" variant="h6Bold">
                No Background available
              </Typography>
            ) : (
              <Loader />
            )}
          </Stack>
          <Stack width="100%">
            <Typography component="h4" variant="h4">
              MEDIA
            </Typography>
            <Divider
              sx={{
                borderBottomWidth: '1px',
                borderColor: theme.palette.divider,
                margin: theme.spacing(2, 0),
              }}
            />
            {media && media.length > 0 ? (
              <Grid container spacing={theme.spacing(4)}>
                {media.map((image: IBusinessImageData, index: number) => (
                  <Grid
                    key={image.documentId + index.toString()}
                    item
                    md={3}
                    xs={12}
                    onClick={() => onImageClick(image.croppedImageReadUrl, 'Media')}
                  >
                    <img
                      alt="media-img"
                      className="cursor-pointer"
                      src={image.croppedImageReadUrl}
                      style={{ borderRadius: '10px' }}
                      width="100%"
                    />
                  </Grid>
                ))}
              </Grid>
            ) : !businessMediaLoading ? (
              <Typography component="h6" variant="h6Bold">
                No Media available
              </Typography>
            ) : (
              <Loader />
            )}
          </Stack>

          <Stack width="100%">
            <Typography component="h4" variant="h4">
              MISC
            </Typography>
            <Divider
              sx={{
                borderBottomWidth: '1px',
                borderColor: theme.palette.divider,
                margin: theme.spacing(2, 0),
              }}
            />
            {postImages && postImages.length > 0 ? (
              <Grid container spacing={theme.spacing(4)}>
                {postImages.map((postImage: IBusinessMediaPostImage, index: number) => (
                  <Grid
                    key={postImage.contentId + index.toString()}
                    item
                    md={3}
                    xs={12}
                    onClick={() =>
                      onImageClick(postImage.croppedImageUrl || postImage.imageUrl, 'Misc')
                    }
                  >
                    <img
                      alt="posts-img"
                      className="cursor-pointer"
                      src={postImage.croppedImageUrl || postImage.imageUrl}
                      style={{ borderRadius: '10px' }}
                      width="100%"
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              !businessMediaLoading && (
                <Typography component="h6" variant="h6Bold">
                  No Misc available
                </Typography>
              )
            )}
            {businessMediaLoading && <Loader />}
          </Stack>
        </Stack>
      </InfiniteScroll>
      {selectedImage && (
        <ModalContainer
          showCloseIcon
          handleClose={() => setSelectedImage('')}
          open={selectedImage !== ''}
          title={modalTitle}
        >
          <Stack sx={{ margin: theme.spacing(2) }}>
            <img alt="img" src={selectedImage} style={{ borderRadius: '10px' }} width="100%" />
          </Stack>
        </ModalContainer>
      )}
    </>
  );
}

export default BusinessMedia;
