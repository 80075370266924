import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import NoDataAvailable from 'components/notFound/NoDataAvailable';
import AdvertisementTile from 'components/tile/AdvertisementTile';
import Tile from 'components/tile/Tile';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { Fragment, useEffect } from 'react';
import {
  AdsPages,
  fetchAds,
  fetchAdsByPosition,
  getFeaturedNews,
  getNews,
  IFeaturedNews,
  resetAdsState,
  resetNewsState,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

function News() {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isFeaturedNewsLoading, featuredNews, isNewsLoading, allNews } = useAppSelector(
    s => s.news,
  );
  const news = allNews.news as IFeaturedNews[];

  const getAllNews = () => {
    dispatch(getNews(allNews.pageNumber + 1, 'detail'));
    dispatch(fetchAdsByPosition(AdsPages.NEWS, 'C'));
  };

  useEffect(() => {
    scrollToTop();
    dispatch(getFeaturedNews());
    dispatch(fetchAds(AdsPages.NEWS));
    return () => {
      dispatch(resetNewsState());
      dispatch(resetAdsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HomeWrapper maxWidth="midmd">
      <Stack spacing={theme.spacing(5)} width="100%">
        <ComponentWrapper title="Featured News">
          <>
            {isFeaturedNewsLoading ? (
              <Loader />
            ) : featuredNews && featuredNews.length > 0 ? (
              <>
                {featuredNews.map((fNews: IFeaturedNews, index: number) => (
                  <Fragment key={(fNews?.documentID as string) + index.toString()}>
                    {/* {(index + 1) % 5 === 0 && ( */}
                    {/* {index === 1 && (
                      <AdvertisementTile
                        useCommonData
                        page={AdsPages.NEWS}
                        position="A"
                        uniqueKey={index + 1}
                      />
                    )} */}
                    <Tile
                      key={(fNews?.documentID as string) + index.toString()}
                      isFeatured
                      commentsCount={fNews?.commentsCount}
                      componentType="news"
                      description={fNews?.desc}
                      id={fNews?.documentID}
                      image={fNews?.thumbnail}
                      isLiked={fNews?.isLiked}
                      likesCount={fNews?.likesCount}
                      name={fNews?.provider.name}
                      postedAt={fNews?.updateDate}
                      providerId={fNews.provider.documentID}
                      providerUrl={fNews?.provider?.url}
                      tileType={isMobile ? 'small' : 'large'}
                      title={fNews?.title}
                      type="news"
                      withDivider={!(featuredNews?.length === index + 1)}
                    />
                  </Fragment>
                ))}
              </>
            ) : (
              <NoDataAvailable type="featured news" />
            )}
          </>
        </ComponentWrapper>
        <AdvertisementTile useCommonData page={AdsPages.NEWS} position="B" uniqueKey={1} />
        <ComponentWrapper
          withInfiniteScroll
          infiniteScrollProps={{
            loadOnMount: true,
            hasMoreData: allNews.hasMoreData,
            isLoading: isNewsLoading,
            onBottomHit: getAllNews,
          }}
          title="All News"
        >
          <>
            {news && news.length > 0 ? (
              <>
                {news.map((news: IFeaturedNews, index: number) => (
                  <Fragment key={(news?.documentID as string) + index.toString()}>
                    <Tile
                      key={(news?.documentID as string) + index.toString()}
                      commentsCount={news?.commentsCount}
                      componentType="news"
                      description={news?.desc}
                      id={news?.documentID}
                      image={news?.thumbnail}
                      isFeatured={false}
                      isLiked={news?.isLiked}
                      likesCount={news?.likesCount}
                      name={news?.provider.name}
                      postedAt={news?.updateDate}
                      providerId={news.providerID}
                      providerUrl={news?.provider?.url}
                      tileType={isMobile ? 'small' : 'large'}
                      title={news?.title}
                      type="news"
                      withDivider={
                        (index + 1) % 5 === 0 ? false : !(allNews.news.length === index + 1)
                      }
                    />
                    {(index + 1) % 5 === 0 && (
                      <AdvertisementTile
                        page={AdsPages.NEWS}
                        position="C"
                        uniqueKey={index + 1}
                        useCommonData={false}
                      />
                    )}
                  </Fragment>
                ))}
              </>
            ) : (
              !isNewsLoading && <NoDataAvailable type="news" />
            )}
            {isNewsLoading && <Loader />}
          </>
        </ComponentWrapper>
      </Stack>
    </HomeWrapper>
  );
}

export default News;
