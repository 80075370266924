import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import theme from 'theme';

import { BackIcon, CloseIcon } from '../../helpers/images';

export interface IModalHeaderProps {
  showCloseIcon?: boolean;
  showBackIcon?: boolean;
  handleBack?: () => void;
  handleClose?: () => void;
  title?: string;
}

function ModalHeader({
  handleBack,
  handleClose,
  showBackIcon = false,
  showCloseIcon = false,
  title = '',
}: IModalHeaderProps) {
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="center"
      sx={{
        position: 'relative',
      }}
    >
      {showBackIcon && (
        <Box sx={{ position: 'absolute', left: 0 }}>
          <Button onClick={handleBack}>
            <SvgIcon inheritViewBox component={BackIcon} sx={{ height: '16px', width: '10px' }} />
            <Typography component="span" sx={{ ml: theme.spacing(1.25) }}>
              Back
            </Typography>
          </Button>
        </Box>
      )}

      <Box>
        <Typography variant="title1" whiteSpace="nowrap">
          {title}
        </Typography>
      </Box>

      {showCloseIcon && (
        <Box sx={{ position: 'absolute', right: 0 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Stack>
  );
}

export default ModalHeader;
