import { Dispatch } from 'redux';
import { IBusiness, IBusinessListState, IProvider } from 'store';
import { AlertActions, IAlertPayload } from 'store/alerts/AlertInterface';
import { AlertType } from 'store/alerts/AlertTypes';

import client from '../../Api';
import { authUrl, businessUrl, newsUrl, podcastUrl, timelineUrl } from '../../helpers/urls';
import { IResponseData } from '../common/CommonInterface';
import {
  FollowingTimelineActions,
  IFollowingPostsState,
  IPaidUser,
  IPaidUsersListState,
  IPreferencePodcast,
  ISearchBusinessesState,
  ISearchPaidUsersState,
} from './FollowingTimelineInterface';
import FollowingPostsType from './FollowingTimelineTypes';

export const resetFollowingTimelineState = () => {
  return async (dispatch: Dispatch<FollowingTimelineActions>) => {
    dispatch({ type: FollowingPostsType.RESET_FOLLOWING_TIMELINE_STATE });
  };
};

export const getFollowingPosts = (offset: number) => {
  return async (dispatch: Dispatch<FollowingTimelineActions | AlertActions>) => {
    try {
      dispatch({ type: FollowingPostsType.GET_FOLLOWING_POSTS_REQUEST });
      const response: IResponseData = await client.get(
        `${timelineUrl}timeline/following?limit=15&offset=${offset}`,
      );

      const payload: IFollowingPostsState = {
        posts: response.data,
        offset: offset + 15,
        hasMoreData: response.data && response.data.length === 15,
      };

      dispatch({
        type: FollowingPostsType.GET_FOLLOWING_POSTS_SUCCESS,
        payload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: FollowingPostsType.GET_FOLLOWING_POSTS_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const getPreferencesAllBusinesses = (offset: number, limit = 20) => {
  return async (dispatch: Dispatch<FollowingTimelineActions | AlertActions>) => {
    try {
      dispatch({ type: FollowingPostsType.GET_ALL_BUSINESSES_REQUEST });
      const response: IResponseData = await client.get(
        `${businessUrl}businesses?type=all&limit=${limit}&offset=${offset}&p=prefs`,
      );
      const payload: IBusinessListState = {
        businesses: response.data,
        offset: offset + limit,
        hasMoreData: response.data && response.data.length === limit,
      };
      dispatch({
        type: FollowingPostsType.GET_ALL_BUSINESSES_SUCCESS,
        payload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: FollowingPostsType.GET_ALL_BUSINESSES_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const getPreferencesAllNewsProviders = () => {
  return async (dispatch: Dispatch<FollowingTimelineActions | AlertActions>) => {
    try {
      dispatch({ type: FollowingPostsType.GET_ALL_NEWS_PROVIDERS_REQUEST });
      const response: IResponseData = await client.get(`${newsUrl}providers`);

      dispatch({
        type: FollowingPostsType.GET_ALL_NEWS_PROVIDERS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: FollowingPostsType.GET_ALL_NEWS_PROVIDERS_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const getPreferencesAllPodcasts = () => {
  return async (dispatch: Dispatch<FollowingTimelineActions | AlertActions>) => {
    try {
      dispatch({ type: FollowingPostsType.GET_ALL_PODCASTS_REQUEST });
      const response: IResponseData = await client.get(`${podcastUrl}podcasts`);

      dispatch({
        type: FollowingPostsType.GET_ALL_PODCASTS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: FollowingPostsType.GET_ALL_PODCASTS_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const followPodcasts = (podcastIds: string[], allPodcasts: IPreferencePodcast[]) => {
  return async (dispatch: Dispatch<FollowingTimelineActions | AlertActions>) => {
    try {
      dispatch({ type: FollowingPostsType.FOLLOW_PODCASTS_REQUEST });
      const response: IResponseData = await client.put(`${podcastUrl}podcasts/follow`, {
        podcastIDs: podcastIds,
      });
      dispatch({
        type: FollowingPostsType.FOLLOW_PODCASTS_SUCCESS,
        payload: allPodcasts,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: FollowingPostsType.FOLLOW_PODCASTS_ERROR });
      const errorPayload: IAlertPayload = {
        message: 'Something went wrong saving PODCASTS preferences',
      };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const followBusinesses = (
  businessIds: string[],
  businesses: IBusiness[],
  fromSearch: boolean,
) => {
  return async (dispatch: Dispatch<FollowingTimelineActions | AlertActions>) => {
    try {
      dispatch({ type: FollowingPostsType.FOLLOW_BUSINESSES_REQUEST });
      const response: IResponseData = await client.put(`${businessUrl}businesses/follow`, {
        businessIds,
      });
      dispatch({
        type: FollowingPostsType.FOLLOW_BUSINESSES_SUCCESS,
        payload: { businesses, fromSearch },
      });
      return response;
    } catch (error: any) {
      dispatch({ type: FollowingPostsType.FOLLOW_BUSINESSES_ERROR });
      const errorPayload: IAlertPayload = {
        message: 'Something went wrong saving BUSINESSES preferences',
      };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const followNewsProviders = (providerIds: string[], newsProviders: IProvider[]) => {
  return async (dispatch: Dispatch<FollowingTimelineActions | AlertActions>) => {
    try {
      dispatch({ type: FollowingPostsType.FOLLOW_NEWS_PROVIDERS_REQUEST });
      const response: IResponseData = await client.put(`${newsUrl}users/news/providers`, {
        providerIDs: providerIds,
      });
      dispatch({
        type: FollowingPostsType.FOLLOW_NEWS_PROVIDERS_SUCCESS,
        payload: newsProviders,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: FollowingPostsType.FOLLOW_NEWS_PROVIDERS_ERROR });
      const errorPayload: IAlertPayload = {
        message: 'Something went wrong saving NEWS preferences',
      };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const resetFollowingPreferencesState = () => {
  return async (dispatch: Dispatch<FollowingTimelineActions>) => {
    dispatch({ type: FollowingPostsType.RESET_FOLLOWING_PREFERENCES_STATE });
  };
};

export const resetBusinessSearchState = () => {
  return async (dispatch: Dispatch<FollowingTimelineActions>) => {
    dispatch({ type: FollowingPostsType.RESET_SEARCH_STATE });
  };
};

export const searchBusinesses = (query: string, page: number, limit = 20) => {
  return async (dispatch: Dispatch<FollowingTimelineActions | AlertActions>) => {
    try {
      dispatch({
        type: FollowingPostsType.SEARCH_BUSINESSES_REQUEST,
        payload: query,
      });
      const response: IResponseData = await client.get(
        `${businessUrl}business/search?q=${query}&page=${page}&limit=${limit}&p=prefs`,
      );
      const payload: ISearchBusinessesState = {
        query,
        businesses: response.data,
        page,
        hasMoreData: response.data && response.data.length === limit,
      };
      dispatch({
        type: FollowingPostsType.SEARCH_BUSINESSES_SUCCESS,
        payload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: FollowingPostsType.SEARCH_BUSINESSES_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const resetAllBusinessesState = () => {
  return async (dispatch: Dispatch<FollowingTimelineActions>) => {
    dispatch({ type: FollowingPostsType.RESET_ALL_BUSINESSES_STATE });
  };
};

export const fetchFollowedBusinesses = () => {
  return async (dispatch: Dispatch<FollowingTimelineActions>) => {
    try {
      dispatch({
        type: FollowingPostsType.GET_ALL_FOLLOWED_BUSINESSES_REQUEST,
      });
      const response: IResponseData = await client.get(`${businessUrl}getFollowed`);
      dispatch({
        type: FollowingPostsType.GET_ALL_FOLLOWED_BUSINESSES_SUCCESS,
        payload: response.data.businessIds || [],
      });
      return response;
    } catch (error: any) {
      dispatch({ type: FollowingPostsType.GET_ALL_FOLLOWED_BUSINESSES_ERROR });
      return error;
    }
  };
};

export const getPreferencesAllPaidUsers = (offset: number, limit = 20) => {
  return async (dispatch: Dispatch<FollowingTimelineActions | AlertActions>) => {
    try {
      dispatch({ type: FollowingPostsType.GET_ALL_PAID_USERS_REQUEST });
      const response: IResponseData = await client.get(
        `${authUrl}users/paidUsers?limit=${limit}&offset=${offset}`,
      );

      const payload: IPaidUsersListState = {
        paidUsers: response.data,
        offset: offset + limit,
        hasMoreData: response.data && response.data.length === limit,
      };
      dispatch({ type: FollowingPostsType.GET_ALL_PAID_USERS_SUCCESS, payload });
      return response;
    } catch (error: any) {
      dispatch({ type: FollowingPostsType.GET_ALL_PAID_USERS_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const searchPaidUsers = (query: string, page: number, limit = 20) => {
  return async (dispatch: Dispatch<FollowingTimelineActions | AlertActions>) => {
    try {
      dispatch({
        type: FollowingPostsType.SEARCH_PAID_USERS_REQUEST,
        payload: query,
      });
      const response: IResponseData = await client.get(
        `${authUrl}user/search?q=${query}&page=${page}&limit=${limit}`,
      );
      const payload: ISearchPaidUsersState = {
        query,
        paidUsers: response.data || [],
        page,
        hasMoreData: response.data && response.data.length === limit,
      };

      dispatch({ type: FollowingPostsType.SEARCH_PAID_USERS_SUCCESS, payload });
      return response;
    } catch (error: any) {
      dispatch({ type: FollowingPostsType.SEARCH_PAID_USERS_ERROR });
      const errorPayload: IAlertPayload = { message: error?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const resetAllPaidUsersState = () => {
  return async (dispatch: Dispatch<FollowingTimelineActions>) => {
    dispatch({ type: FollowingPostsType.RESET_ALL_PAID_USERS_STATE });
  };
};

export const fetchFollowedPaidUsers = () => {
  return async (dispatch: Dispatch<FollowingTimelineActions>) => {
    try {
      dispatch({
        type: FollowingPostsType.GET_ALL_FOLLOWED_PAID_USERS_REQUEST,
      });
      const response: IResponseData = await client.get(`${authUrl}user/followers`);
      dispatch({
        type: FollowingPostsType.GET_ALL_FOLLOWED_PAID_USERS_SUCCESS,
        payload: response.data || [],
      });
      return response;
    } catch (error: any) {
      dispatch({ type: FollowingPostsType.GET_ALL_FOLLOWED_PAID_USERS_ERROR });
      return error;
    }
  };
};

export const followPaidUsers = (userIds: string[], paidUsers: IPaidUser[], fromSearch: boolean) => {
  return async (dispatch: Dispatch<FollowingTimelineActions | AlertActions>) => {
    try {
      dispatch({ type: FollowingPostsType.FOLLOW_PAID_USERS_REQUEST });
      const response: IResponseData = await client.put(`${authUrl}user/follow`, {
        userIds,
      });
      dispatch({
        type: FollowingPostsType.FOLLOW_PAID_USERS_SUCCESS,
        payload: { paidUsers, fromSearch },
      });
      return response;
    } catch (error: any) {
      dispatch({ type: FollowingPostsType.FOLLOW_PAID_USERS_ERROR });
      const errorPayload: IAlertPayload = {
        message: 'Something went wrong saving Paid Users preferences',
      };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      return error;
    }
  };
};

export const resetPaidUsersSearchState = () => {
  return async (dispatch: Dispatch<FollowingTimelineActions>) => {
    dispatch({ type: FollowingPostsType.RESET_SEARCH_PAID_USERS_STATE });
  };
};
