import { Box, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import SearchInputTextField from 'pages/FollowingTimeline/Preferences/SearchInput';
import { IMembers } from 'store';
import theme from 'theme';

import MembersList from './MembersList';

interface IProps {
  selectedMemberType: string;
  searchQuery: string;
  handleCardOptionChange:
    | ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void)
    | undefined;
  handleClearSearchField: () => void;
  handleInputChange: React.ChangeEventHandler<HTMLInputElement>;
  onSelectMember: (members: IMembers) => void;
}

function MembersTypeSelectionModule(props: IProps) {
  const {
    selectedMemberType,
    searchQuery,
    handleCardOptionChange,
    handleClearSearchField,
    handleInputChange,
    onSelectMember,
  } = props;

  return (
    <>
      <Stack
        alignItems="center"
        // alignSelf="flex-start"
        direction="row"
        justifyContent="center"
        my={2}
      >
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          defaultValue="basic"
          name="controlled-radio-buttons-group"
          value={selectedMemberType}
          onChange={handleCardOptionChange}
        >
          <FormControlLabel control={<Radio />} label="Basic" value="basic" />
          <FormControlLabel control={<Radio />} label="Patriot" value="patriot" />
        </RadioGroup>

        <Box
          border="1px solid black"
          borderRadius={theme.spacing(3.275)}
          boxSizing="border-box"
          height={theme.spacing(6)}
          //   width="100%"
        >
          <SearchInputTextField
            placeholder="Search for Members"
            sx={{ border: '1px solid black' }}
            value={searchQuery}
            onChange={handleInputChange}
            onClear={handleClearSearchField}
          />
        </Box>
      </Stack>

      <MembersList
        searchQuery={searchQuery.trim()}
        selectedMemberType={selectedMemberType}
        onSelectMember={onSelectMember}
      />
    </>
  );
}

export default MembersTypeSelectionModule;
