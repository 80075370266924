import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import theme from 'theme';

interface IPostBannerProps {
  isMobile: boolean;
  imgSrc: string;
  link?: string;
  title: string;
}

export default function PostBanner(props: IPostBannerProps) {
  const { isMobile, imgSrc, link = '', title } = props;

  return (
    <>
      <Box sx={{ my: theme.spacing(2) }}>
        <img alt={title} src={imgSrc} style={{ borderRadius: '10px' }} width="100%" />
      </Box>
      <Typography
        className="text-ellipsis text-clamp-2"
        sx={{ mb: theme.spacing(1.5) }}
        variant={isMobile ? 'title2' : 'title1'}
      >
        {title}
      </Typography>
      {link && (
        <Link
          href={link}
          rel="noopener"
          target="_blank"
          // underline="hover"
        >
          Link to the article
        </Link>
      )}
    </>
  );
}
