import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Loader from 'components/loader/Loader';
import NoDataAvailable from 'components/notFound/NoDataAvailable';
import ResourceTile from 'components/tile/ResourceTile';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import React, { Fragment } from 'react';
import { IResource } from 'store';
import theme from 'theme';

interface IProps {
  title: string;
  loading: boolean;
  isLaptop: boolean;
  resources: IResource[];
}

function ResourcesSubComponent(props: IProps) {
  const { loading, title, resources, isLaptop } = props;

  return (
    <ComponentWrapper title={title}>
      {loading ? (
        <Loader />
      ) : (
        <Grid container item width="100%" xs={12}>
          {resources?.length ? (
            <>
              {resources.map((resource: IResource, index: number) => (
                <Fragment key={resource.documentID + index.toString()}>
                  <Grid item lg={3.33} midxs={5.5} xs={12}>
                    <ResourceTile
                      image={resource?.imageUrl}
                      name={resource?.resourceName}
                      url={resource?.resourceViewUrl}
                      withDivider={!(index + 1 === resources.length)}
                    />
                  </Grid>
                  {!((index + 1) % (isLaptop ? 2 : 3) === 0) && (
                    <Grid
                      container
                      item
                      alignItems="center"
                      display={{ xs: 'none', midxs: 'flex' }}
                      justifyContent="center"
                      midxs={1}
                      xs={0}
                    >
                      <Divider
                        flexItem
                        orientation="vertical"
                        sx={{
                          height: '100%',
                          borderColor: theme.palette.divider,
                        }}
                      />
                    </Grid>
                  )}
                </Fragment>
              ))}
            </>
          ) : (
            <NoDataAvailable type="Resources" />
          )}
        </Grid>
      )}
    </ComponentWrapper>
  );
}

export default ResourcesSubComponent;
