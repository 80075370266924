import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const termRoutes = ['/core-values', '/terms-and-conditions', '/privacy-policy'];

export default function useTermRouteCheck() {
  const location = useLocation();

  const isTermsRoute = useMemo(() => {
    return termRoutes.includes(location.pathname);
  }, [location.pathname]);

  return isTermsRoute;
}
