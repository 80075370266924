import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';

function SignUpTile() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      borderRadius="10px"
      padding={{ xs: theme.spacing(2, 4), sm: theme.spacing(4, 7) }}
      sx={{
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Stack
        alignItems={{ xs: 'center', sm: 'flex-start' }}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        spacing={{ xs: theme.spacing(2), sm: theme.spacing(12) }}
      >
        <Typography
          color="common.white"
          fontWeight={400}
          textAlign={{ xs: 'center', sm: 'start' }}
          variant={isMobile ? 'subtitle4' : 'h5'}
        >
          Connect with the viewers that align with your business.
        </Typography>
        <Stack spacing={theme.spacing(1)} sx={{ whiteSpace: 'nowrap' }}>
          <Button
            disableRipple
            size={isMobile ? 'medium' : 'large'}
            variant="outlined"
            onClick={() => navigate('/signup')}
          >
            Sign up
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

export default SignUpTile;
