import { Reducer } from 'redux';

import { AdsActions, IAdsState, IAdvertisement } from './AdvertisementInterface';
import AdsType from './AdvertisementTypes';

export const initialState: IAdsState = {
  loading: false,
  positionAdsLoading: false,
  commonAdsData: [] as IAdvertisement[],
  positionAdsData: [] as IAdvertisement[],
};

const AdsReducer: Reducer<IAdsState, AdsActions> = (
  state = initialState,
  action = {} as AdsActions,
) => {
  switch (action.type) {
    case AdsType.FETCH_COMMON_ADS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AdsType.FETCH_COMMON_ADS_SUCCESS:
      return {
        ...state,
        loading: false,
        commonAdsData: action.payload,
      };
    case AdsType.FETCH_COMMON_ADS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case AdsType.FETCH_POSITION_ADS_REQUEST:
      return {
        ...state,
        positionAdsLoading: true,
      };
    case AdsType.FETCH_POSITION_ADS_SUCCESS:
      return {
        ...state,
        positionAdsLoading: false,
        positionAdsData: [...state.positionAdsData, ...action.payload],
      };
    case AdsType.FETCH_POSITION_ADS_ERROR:
      return {
        ...state,
        positionAdsLoading: false,
      };
    case AdsType.REMOVE_AD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AdsType.REMOVE_AD_SUCCESS:
    case AdsType.REMOVE_AD_ERROR:
      return {
        ...state,
        loading: false,
      };
    case AdsType.RESET_ADS_STATE: {
      return {
        ...state,
        commonAdsData: [] as IAdvertisement[],
        positionAdsData: [] as IAdvertisement[],
      };
    }
    default:
      return state;
  }
};

export default AdsReducer;
