import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FSLogoDesktop, FSLogoMobile } from 'helpers/images';
import useAuthRouteCheck from 'hooks/useAuthRouteCheck';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';

interface IHeaderLogoProps {
  boxStyles?: { [k: string]: any };
  enableNavigation?: boolean;
  height?: string | number;
  spacing?: number;
  width?: string | number;
}

export default function HeaderLogo(props: IHeaderLogoProps) {
  const navigate = useNavigate();
  const isAuthRoute = useAuthRouteCheck();

  const { boxStyles, enableNavigation = true, height, spacing = 0, width = 'auto' } = props;
  const isLaptop = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const showMobileLogo = isLaptop && !isAuthRoute && !(isMobile && isAuthRoute);
  const { checkUserLoggedIn } = useIsLoggedIn();

  return (
    <Box
      sx={{
        cursor: 'pointer',
        ...(spacing > 0 && { mb: theme.spacing(spacing) }),
        ...boxStyles,
      }}
      {...(enableNavigation && {
        onClick: () => navigate(checkUserLoggedIn() ? '/home' : '/'),
      })}
    >
      <SvgIcon
        inheritViewBox
        component={showMobileLogo ? FSLogoMobile : FSLogoDesktop}
        sx={{ display: 'block', height, width }}
      />
    </Box>
  );
}
