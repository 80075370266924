import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import HeaderLogo from 'components/header/HeaderLogo';
import { AuthLeftImage } from 'helpers/images';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';

interface IAuthProps {
  children: React.ReactNode;
}

const Image = styled('img')(() => ({
  objectFit: 'cover',
  objectPosition: '50% 50%',
  height: '100%',
  width: '100%',
}));

function AuthWrapper({ children }: IAuthProps) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container overflow="hidden">
      <Grid item height="100vh" md={6} midlg={7} xs={0}>
        {!isMobile && (
          <Box height="inherit" width="100%">
            <Box
              left={theme.spacing(8)}
              position="absolute"
              top={theme.spacing(3.75)}
              onClick={() => navigate('/')}
            >
              <HeaderLogo height={theme.spacing(4.75)} />
            </Box>
            <Image alt="image" src={AuthLeftImage} />
          </Box>
        )}
      </Grid>
      <Grid item height={isMobile ? '100%' : '100vh'} md={6} midlg={5} xs={12}>
        <Box
          boxSizing="border-box"
          height="100%"
          sx={{ overflowY: isMobile ? 'hidden' : 'auto' }}
          width="100%"
        >
          <Stack
            boxSizing="border-box"
            minHeight="100%"
            padding={{
              xs: theme.spacing(5, 2),
              midxs: theme.spacing(5, 12),
              md: theme.spacing(5, 8),
              midlg: theme.spacing(5, 12),
            }}
            width="100%"
          >
            <Stack
              alignItems="center"
              flexGrow={1}
              justifyContent={isMobile ? 'flex-start' : 'center'}
            >
              {children}
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AuthWrapper;
