import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useNavigate } from 'react-router-dom';
import { ITimelinePost } from 'store';

import BusinessPostTile from './BusinessPostTile';
import ProfilePostTile from './ProfilePostTile';

interface IPostTileProps {
  post: ITimelinePost;
  componentType: 'search' | 'trending' | 'home' | 'following' | 'profile';
  type?: 'featured' | 'all-home-business' | 'home-town-crier-post';
  withDivider: boolean;
  onNavToPost?: () => void;
}

function PostTile(props: IPostTileProps) {
  const { post, type, withDivider, componentType, onNavToPost } = props;

  const navigate = useNavigate();

  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();

  const navigateToBusinessPost = (isComment: boolean) => {
    if (isComment) {
      if (isUserLoggedIn()) {
        navigate(`/business/${post?.postId}`, {
          state: 'scroll-to-comments',
        });
      } else {
        onNavToPost?.();
      }
    } else {
      navigate(`/business/${post?.postId}`);
    }
  };

  const navigateToProfilePost = (isComment: boolean) => {
    const pathName = post?.type === 'town-crier-post' ? 'town-crier' : 'post';
    if (isComment) {
      if (isUserLoggedIn()) {
        navigate(`/${pathName}/${post?.postId}`, {
          state: 'scroll-to-comments',
        });
      } else {
        onNavToPost?.();
      }
    } else {
      navigate(`/${pathName}/${post?.postId}`);
    }
  };

  const navigateToBusinessWall = () => navigate(`/business-wall/${post?.postProviderId}`);

  const navigateToProfileWall = () => {
    if (post?.type === 'town-crier-post') {
      navigate(`/town-crier-wall/${post?.postProviderId}`);
    } else {
      navigate(`/profile-wall/${post?.postProviderId}`);
    }
  };

  if (post.type === 'business') {
    return (
      <BusinessPostTile
        key={post?.postId}
        businessId={post?.postProviderId}
        commentsCount={post?.commentsCount}
        componentType={componentType}
        image={post?.thumbnail || ''}
        isLiked={post?.isLiked}
        isReported={post?.isReported}
        likesCount={post?.likesCount}
        navigateToBusinessPost={navigateToBusinessPost}
        navigateToBusinessWall={navigateToBusinessWall}
        postedAt={post?.postedAt || ''}
        postId={post?.postId}
        postType={post.type}
        textContent={post?.description}
        type={type}
        userId={post?.postId}
        userImage={post?.postProviderLogo}
        userName={post?.postProvider}
        withDivider={withDivider}
      />
    );
  }

  if (post.type === 'paid-member-post' || post?.type === 'town-crier-post') {
    return (
      <ProfilePostTile
        key={post?.documentId}
        commentsCount={post?.commentsCount}
        componentType={componentType as any}
        image={post.thumbnail || ''}
        isLiked={post?.isLiked}
        isReported={post?.isReported}
        likesCount={post?.likesCount}
        navigateToProfilePost={navigateToProfilePost}
        navigateToProfileWall={navigateToProfileWall}
        postedAt={post?.postedAt || ''}
        postId={post?.postId}
        postType={post.type}
        textContent={post?.description}
        type={type}
        userId={post?.postId}
        userImage={post?.postProviderLogo}
        userName={post?.postProvider}
        withDivider={withDivider}
      />
    );
  }

  return <div />;
}

export default PostTile;
