import { Images, ImagesType } from 'assets/resource-thumbnails';
import { monthNames, UserType } from 'constants/constants';
import heic2any from 'heic2any';
import { baseUIUrl } from 'helpers/urls';
import { BusinessSetupStatus, BusinessSetupSteps, IBusinessDetails, IUserData } from 'store';

// Utility Functions
export const handleMaskToUSNumber = (value: string): string => {
  if (value) {
    const n = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let maskedNumber = '';
    if (n) {
      maskedNumber = !n[2] ? n[1] : `(${n[1]}) ${n[2]}${n[3] ? `-${n[3]}` : ''}`;
    }
    return maskedNumber;
  }
  return value;
};

export const handleFormatToUSNumber = (number: string) => {
  const newNum = number.replace('-','')
  const num = newNum.match(/(\d{3})(\d{3})(\d{4})/);
  const numberFormatted = num ? `(${num[1]}) ${num[2]}-${num[3]}` : '';
  return numberFormatted;
};

export const unmaskUSNumber = (number: string) => {
  return number.replace(/[()\s-]/g, '');
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const isNumberKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const keyPressed = parseInt(e.key, 10);
  const isNumber = keyPressed >= 0 && keyPressed <= 9;
  if (!isNumber) e.preventDefault();
};

export const getRoundedCanvas = (sourceCanvas: any) => {
  const canvas = document.createElement('canvas');
  const context: any = canvas.getContext('2d');
  const { width } = sourceCanvas;
  const { height } = sourceCanvas;

  canvas.width = width;
  canvas.height = height;
  context.imageSmoothingEnabled = true;
  context.drawImage(sourceCanvas, 0, 0, width, height);
  context.globalCompositeOperation = 'destination-in';
  context.beginPath();
  context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
  context.fill();
  return canvas;
};

export const dataURItoBlob = (dataURI: string) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    // byteString = Buffer.from(dataURI.split(",")[1], "base64").toString();
    byteString = window.atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const handleAllowOnlyOneSpaceBetweenWords = (value: string) => {
  return value !== ' ' ? value.replace(/\s{2,}/g, ' ').replace(/^\s/g, '') : value.trim();
};

export const handleAllowOnlyOneSpaceBetweenWordsAndAlphabets = (value: string) => {
  return value !== ' '
    ? value
        .replace(/[0-9]/, '')
        .replace(/\s{2,}/g, ' ')
        .replace(/^\s/g, '')
    : value.trim();
};

export const handleRestrictSpaces = (value: string) => {
  return value !== ' ' ? value.replace(/\s/g, '') : value.trim();
};

export const handleAllowOnlyNumber = (event: React.KeyboardEvent<HTMLInputElement>) => {
  isNumberKey(event);
};

const getMonthName = (monthNumber: number) => {
  return monthNames[monthNumber];
};

const getShortMonthName = (monthNumber: number) => {
  return getMonthName(monthNumber).substring(0, 3);
};

export const timeDiffCalc = (date: any, isPodcast = false) => {
  let dateFuture = date;
  if (dateFuture === undefined) return '';
  const dateNow: any = new Date();
  dateFuture = new Date(dateFuture);

  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = '';
  if (days > 0 && days <= 7) {
    difference = days === 1 ? `${days} day ago` : `${days} days ago`;
    return difference;
  }
  if (days <= 0 && (hours > 0 || minutes >= 0)) {
    if (hours > 0) {
      difference = hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
      return difference;
    }
    if (minutes >= 0) {
      difference = minutes === 0 || minutes === 1 ? `Just now` : `${minutes} minutes ago`;
      return difference;
    }
  } else {
    // return the post created date
    const getCurrentYear =
      dateFuture.getFullYear() !== dateNow.getFullYear()
        ? dateFuture.getFullYear()
        : isPodcast
        ? dateFuture.getFullYear()
        : '';

    difference = `${getShortMonthName(dateFuture.getMonth())} ${
      !isPodcast ? dateFuture.getDate() : ''
    } ${getCurrentYear !== '' ? ` ${getCurrentYear}` : ''}`;
    return difference;
  }
  return difference;
};

export const capitalizeFirstLetter = (word: string) => {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : '';
};

const excludePath = ['terms-and-conditions', 'privacy-policy', 'core-values', 'signup'];

export const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

export const redirectToMobileApp = () => {
  const hRef = window.location.href;
  const isExcluded = excludePath.some(path => hRef.includes(path));
  if (!isExcluded) {
    const app = window.location.href.replace(baseUIUrl, 'freedomsquare:/');
    if (isMobile) {
      window.location = app as unknown as Location;
    }
  }
};

export const redirectToMobileForAffiliate = (affiliateId: string) => {
  if (isMobile && affiliateId.length > 0) {
    const affiliatePath = `freedomsquare:/affiliate/${affiliateId}`;
    window.location = affiliatePath as unknown as Location;
  }
};

export const getNewsProviderLogo = (providerName: string): string => {
  const providerImageName = providerName.toLowerCase().replaceAll(' ', '-');
  let thumbnail: string;
  try {
    thumbnail =
      // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
      require(`assets/provider-thumbnails/freedom-square-${providerImageName}.webp`);
  } catch (e: any) {
    thumbnail = providerName;
  }
  return thumbnail;
};

export const getNewsProviderBackground = (providerName: string): string => {
  const providerImageName = providerName.toLowerCase().replaceAll(' ', '-');
  let thumbnail: string;
  try {
    thumbnail =
      // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
      require(`assets/provider-background-images/freedom-square-${providerImageName}.webp`);
  } catch (e: any) {
    thumbnail = '';
  }
  return thumbnail;
};

export const isDeletedBusiness = (business: IBusinessDetails) => {
  return business?.deletedAt && business?.deletedAt?.startsWith('0001');
};

export const isDeactivatedBusiness = (business: IBusinessDetails) => {
  return business?.deactivatedAt && !business?.deactivatedAt?.startsWith('0001');
};

export const checkBusiness = (business: IBusinessDetails) => {
  return checkPaidBusiness(business) || business?.step === BusinessSetupSteps.FREE_BUSINESS_LISTED;
};

export const checkPaidUser = (user: IUserData) => {
  return user.userType === UserType.PAID;
};

export const checkPaidBusiness = (business: IBusinessDetails) => {
  return (
    (business?.step === BusinessSetupSteps.COMPLETED &&
      business?.status === BusinessSetupStatus.APPROVED) ||
    (business?.step === BusinessSetupSteps.PAYMENT_SUCCESS &&
      business?.status === BusinessSetupStatus.PAYMENT_SUCCESSFUL)
  );
};

export const getResourceImage = (name: string) => {
  const key = name.toLowerCase().replaceAll('.', '').replaceAll(' ', '_');
  return Images[key as ImagesType] ?? null;
};

export const getResourceLogo = (resourceName: string): string => {
  const resourceImageName = resourceName.toLowerCase().replaceAll(' ', '-');
  let thumbnail: string;
  try {
    thumbnail =
      // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
      require(`assets/resource-thumbnails/freedom-square-${resourceImageName}.webp`);
  } catch (e: any) {
    thumbnail = resourceImageName;
  }
  return thumbnail;
};

export function isValidURL(websiteUrl: string) {
  let url;
  try {
    url = new URL(websiteUrl);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export function downloadCsv(csvData: string, filename: string) {
  const blob = new Blob([`\ufeff${csvData}`], {
    type: 'text/csv;charset=utf-8;',
  });
  const dwldLink = document.createElement('a');
  const url = URL.createObjectURL(blob);
  const isSafariBrowser =
    navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

  // if Safari open in new window to save file with filename.
  if (isSafariBrowser) dwldLink.setAttribute('target', '_blank');

  dwldLink.setAttribute('href', url);
  dwldLink.setAttribute('download', `${filename}.csv`);
  dwldLink.style.visibility = 'hidden';
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

export const isValidImageUrl = (url: string): boolean => {
  if (typeof url !== 'string') {
    return false;
  }
  const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
  const fileFormat = ['mov', 'mp4', 'wmv', 'avi', 'mkv', 'mp3'];
  if (fileFormat.indexOf(ext) >= 0) {
    return false;
  }
  return true;

  // return url.match(/^http[^\\?]*.(jpg|jpeg|png|webp)(\?(.*))?$/gim) !== null;
};

export const isInvalidImageType = (url: string): boolean => {
  if (typeof url !== 'string') {
    return false;
  }
  return /[\\.](mp4)$/i.test(url);
};

// Assume Objects Lengths are equal
export const compareArrayObjects = (o1: any, o2: any, keyToCompare: string) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < o1.length; i++) {
    if (o1?.[i]?.[keyToCompare] !== undefined && o2?.[i]?.[keyToCompare] !== undefined) {
      if (o1[i][keyToCompare] !== o2[i][keyToCompare]) {
        return false;
      }
    }
  }
  return true;
};

export const replaceLinkWithAnchorTag = (content: string) => {
  // URLs starting with http://, https://,
  const pattern1 = /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  const pattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;

  let updatedString = content;

  if (content?.match?.(pattern1)) {
    updatedString = updatedString.replace(
      pattern1,
      '<a href="$1" target="_blank" onclick="event.stopPropagation();">$1</a>',
    );
  }
  if (content?.match?.(pattern2)) {
    updatedString = updatedString.replace(
      pattern2,
      '$1<a href="http://$2" target="_blank" onclick="event.stopPropagation();">$2</a>',
    );
  }

  updatedString = updatedString.replace(/(?:\r\n|\r|\n)/g, '<br>');

  return updatedString;
};

export const isHtmlTagString = (text: string) => {
  const regex = /<\/?[^>]+(>|$)/g;
  return text.match(regex);
};

export const youtubeParser = (url: string) => {
  const regExp =
    /^.*(?:youtu\.be\/|youtube\.com(?:\/(?:[^/]+\/.+\/|(?:embed|v|watch)(?:\/|.*[?&]v=))|.*[?&]v=))([^"&?/\s]{11}).*/;
  const match = url.match(regExp);
  return match && match[1] ? match[1] : false;
};

export const getValidUrlWithProtocol = (url: string) => {
  return url.includes('http://') || url.includes('https://') ? url : `https://${url}`;
};

export const getPreviewLink = (textContent: string) => {
  const uniqueMatches: string[] = [];
  const regex =
    /(https?:\/\/|www\.)[-a-zA-Z0-9@:%._+~#=]{1,256}\.(xn--)?[a-z0-9-]{2,20}\b([-a-zA-Z0-9@:%_+[\],.~#?&/=]*[-a-zA-Z0-9@:%_+\]~#?&/=])*/gi;
  const matches = textContent?.match(regex);

  matches?.forEach(c => {
    if (!c.match(/<\/?[\w\s="/.':;#-\\?]+>/gi)) {
      uniqueMatches.push(c);
    }
  });
  return uniqueMatches.length > 0 ? uniqueMatches[0] : '';
};

export async function processImage(file: File | Blob | null) {
  if (file) {
    if (file.type === 'image/heic') {
      const processedFile = await heic2any({
        blob: file,
        toType: 'image/jpeg',
      });
      return URL.createObjectURL(processedFile as Blob);
    }

    return URL.createObjectURL(file);
  }
  return '';
}

export const DELETED_COMMENT_STRING = 'This comment has been deleted.';

export const REPORTED_CONTENT_STRING = 'This content has been reported.';

export const REPORTED_COMMENT_STRING = 'This comment has been reported.';
