import valid from 'card-validator';
import {
  BUSINESS_ADDRESS_REGEX,
  BUSINESS_NAME_REGEX,
  FACEBOOK_URL_REGEX,
  INSTAGRAM_URL_REGEX,
  LINKEDIN_URL_REGEX,
  NAME_REGEX,
  NAME_REGEX_3,
  PASSWORD_REGEX,
  TWITTER_URL_REGEX,
  URL_REGEX,
  US_PHONE_NUMBER_REGEX,
  USERNAME_REGEX,
} from 'constants/constants';
import ErrorMessages from 'constants/ErrorMessages';
import { CardFormType } from 'store/payment/PaymentTypes';
import * as yup from 'yup';

export const defaultSchema = yup.object().shape({});

const loginErrorMsg = ErrorMessages.login;
export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email(loginErrorMsg.emailInvalidFormat)
    .required(loginErrorMsg.emailInvalidFormat),
  password: yup
    .string()
    // .matches(PASSWORD_REGEX, loginErrorMsg.password)
    .required(''),
});

const signupErrorMsg = ErrorMessages.signup;
export const signupValidationSchema = yup.object().shape({
  userName: yup
    .string()
    .required(signupErrorMsg.userName)
    .min(4, signupErrorMsg.userName)
    .matches(USERNAME_REGEX, signupErrorMsg.userName),
  // fullName: yup
  //   .string()
  //   .min(1, signupErrorMsg.fullName)
  //   .matches(NAME_REGEX, signupErrorMsg.fullName)
  //   .required(signupErrorMsg.fullName),
  email: yup.string().email(signupErrorMsg.email).required(signupErrorMsg.email),
  password: yup
    .string()
    .required(signupErrorMsg.password)
    .matches(PASSWORD_REGEX, signupErrorMsg.password),
  confirmPassword: yup
    .string()
    .required(signupErrorMsg.confirmPassword)
    .oneOf([yup.ref('password'), null], signupErrorMsg.confirmPassword),
  tncAccepted: yup.boolean().oneOf([true], signupErrorMsg.tncAccepted),
});

export const adminAddUserValidationSchema = yup.object().shape({
  userName: yup
    .string()
    .required(signupErrorMsg.userName)
    .min(4, signupErrorMsg.userName)
    .matches(USERNAME_REGEX, signupErrorMsg.userName),

  email: yup.string().email(signupErrorMsg.email).required(signupErrorMsg.email),
});

export const deleteAcountValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email(loginErrorMsg.emailInvalidFormat)
    .required(loginErrorMsg.emailInvalidFormat),
  password: yup.string().required(''),
});

export const userNameValidationSchema = yup.object().shape({
  userName: yup
    .string()
    .required(signupErrorMsg.userName)
    .min(4, signupErrorMsg.userName)
    .matches(USERNAME_REGEX, signupErrorMsg.userName),
  // fullName: yup
  //   .string()
  //   .min(1, signupErrorMsg.fullName)
  //   .matches(NAME_REGEX, signupErrorMsg.fullName)
  //   .required(signupErrorMsg.fullName),
});

export const emailValidationSchema = yup.object().shape({
  email: yup.string().email(signupErrorMsg.email).required(signupErrorMsg.email),
});

export const changePasswordValidationSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required(signupErrorMsg.password)
    .matches(PASSWORD_REGEX, signupErrorMsg.password),
  newPassword: yup
    .string()
    .required(signupErrorMsg.password)
    .matches(PASSWORD_REGEX, signupErrorMsg.password),
  confirmPassword: yup
    .string()
    .required(signupErrorMsg.confirmPassword)
    .oneOf([yup.ref('newPassword'), null], signupErrorMsg.confirmPassword),
});

export const resetPasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required(signupErrorMsg.password)
    .matches(PASSWORD_REGEX, signupErrorMsg.password),
  confirmPassword: yup
    .string()
    .required(signupErrorMsg.confirmPassword)
    .oneOf([yup.ref('password'), null], signupErrorMsg.confirmPassword),
});

const reportErrorMsg = ErrorMessages.userReport;

export const userReportValidationSchema = yup.object().shape(
  {
    other: yup
      .string()
      .nullable()
      .notRequired()
      .when('other', {
        is: (value: string) => value?.length,
        then: rule => rule.required(reportErrorMsg.others),
      }),
  },
  [['other', 'other']],
);

const { ADD } = CardFormType;
const paymentErrorMsg = ErrorMessages.payment;
const addressValidationSchema = yup.object().shape({
  address: yup
    .string()
    .trim()
    .min(5, paymentErrorMsg.address)
    .required(paymentErrorMsg.addressEmpty),
  city: yup.string().trim().min(2, paymentErrorMsg.city).required(paymentErrorMsg.cityEmpty),
  state: yup.string().required(paymentErrorMsg.state),
  zipcode: yup.string().trim().length(5, paymentErrorMsg.zipcode).required(paymentErrorMsg.zipcode),
});

const cardInfoValidationSchema = (
  needCvvValidation: boolean,
  needCardNoValidation: boolean,
  includeAlias = false,
) => {
  return yup.object().shape({
    ...(needCardNoValidation && {
      cardNumber: yup
        .string()
        .test('card-validator', paymentErrorMsg.cardNumber, value => {
          const numberValidation = valid.number(value);
          return numberValidation.isValid;
        })
        .required(paymentErrorMsg.cardNumber),
    }),
    expiryMonth: yup.string().required(paymentErrorMsg.cardExpiryMonth),
    expiryYear: yup.string().required(paymentErrorMsg.cardExpiryYear),
    ...(needCvvValidation && {
      cvv: yup.string().required(paymentErrorMsg.cvv).min(3, paymentErrorMsg.cvv),
    }),
    ...(includeAlias && {
      alias: yup
        .string()
        .trim()
        .min(2, paymentErrorMsg.cardAlias)
        .matches(BUSINESS_NAME_REGEX, paymentErrorMsg.cardAlias)
        .required(paymentErrorMsg.cardAlias),
    }),
  });
};

export const paymentValidationSchema = (formType: number) =>
  yup.object().shape({
    cardAlias: yup
      .string()
      .trim()
      .min(2, paymentErrorMsg.cardAlias)
      .matches(BUSINESS_NAME_REGEX, paymentErrorMsg.cardAlias)
      .required(paymentErrorMsg.cardAlias),
    name: yup
      .string()
      .trim()
      .min(1, paymentErrorMsg.name)
      .matches(NAME_REGEX_3, paymentErrorMsg.name)
      .required(paymentErrorMsg.name),
    billingAddress: addressValidationSchema,
    cardInfo: cardInfoValidationSchema(false, formType === ADD),
  });

export const businessPaymentValidationSchema = yup.object().shape({
  billingName: yup
    .string()
    .trim()
    .min(1, paymentErrorMsg.firstName)
    .matches(NAME_REGEX_3, paymentErrorMsg.firstName)
    .required(paymentErrorMsg.firstName),
  // lastName: yup
  //   .string()
  //   .trim()
  //   .min(1, paymentErrorMsg.firstName)
  //   .matches(NAME_REGEX, paymentErrorMsg.firstName)
  //   .required(paymentErrorMsg.firstName),
  billingAddress: addressValidationSchema,
  paymentInfo: cardInfoValidationSchema(false, true, true), // true flag to include CVV Validation
});

const businessInformationErrorMsg = ErrorMessages.businessInformation;

// For Paid Business edit (Part-2)
const businessEditInformationSchema = yup.object().shape(
  {
    website: yup
      .string()
      .nullable()
      .notRequired()
      .when('website', {
        is: (value: string) => value?.length,
        then: rule => rule.matches(URL_REGEX, businessInformationErrorMsg.businessWebsiteRequired),
      }),
    address: yup
      .string()
      .trim()
      .nullable()
      .notRequired()
      .when('address', {
        is: (value: string) => value?.length,
        then: rule =>
          rule
            .min(5, businessInformationErrorMsg.address)
            .matches(BUSINESS_ADDRESS_REGEX, businessInformationErrorMsg.address),
      }),
    city: yup
      .string()
      .trim()
      .nullable()
      .notRequired()
      .when('city', {
        is: (value: string) => value?.length,
        then: rule => rule.min(2, businessInformationErrorMsg.city),
      }),

    stateId: yup
      .string()
      .nullable()
      .notRequired()
      .when('stateId', {
        is: (value: string) => value?.length,
        then: rule => rule.required(businessInformationErrorMsg.state),
      }),
    zipcode: yup
      .string()
      .trim()
      .nullable()
      .notRequired()
      .when('zipcode', {
        is: (value: string) => value?.length,
        then: rule => rule.min(5, businessInformationErrorMsg.zipcode),
      }),
  },
  [
    ['city', 'city'],
    ['website', 'website'],
    ['address', 'address'],
    ['zipcode', 'zipcode'],
    ['stateId', 'stateId'],
  ],
);

// For Paid Business edit (Part-1)
export const businessInformationSchema = yup.object().shape(
  {
    name: yup
      .string()
      .required(businessInformationErrorMsg.businessNameRequired)
      .min(2, businessInformationErrorMsg.businessNameRequired)
      .matches(BUSINESS_NAME_REGEX, businessInformationErrorMsg.businessNameRequired),

    phone: yup
      .string()
      .nullable()
      .notRequired()
      .when('phone', {
        is: (value: string | any[]) => value?.length,
        then: rule =>
          rule.matches(US_PHONE_NUMBER_REGEX, businessInformationErrorMsg.businessPhoneRequired),
      }),

    contactPersonName: yup
      .string()
      .nullable()
      .notRequired()
      .when('contactPersonName', {
        is: (value: string | any[]) => value?.length,
        then: rule =>
          rule
            .min(1, businessInformationErrorMsg.contactPersonName)
            .matches(NAME_REGEX, businessInformationErrorMsg.contactPersonName),
      }),
    email: yup
      .string()
      .email(businessInformationErrorMsg.email)
      .required(businessInformationErrorMsg.email),
  },
  [
    ['phone', 'phone'],
    ['contactPersonName', 'contactPersonName'],
  ],
);

const businessDetailsErrorMsg = ErrorMessages.businessDetails;

export const businessDetailsSchema = yup.object().shape(
  {
    tagline: yup
      .string()
      .nullable()
      .notRequired()
      .when('tagline', {
        is: (value: string) => value?.length,
        then: rule =>
          rule
            .min(20, businessDetailsErrorMsg.taglineLimit)
            .max(100, businessDetailsErrorMsg.taglineLimit),
      }),
    description: yup
      .string()
      .nullable()
      .notRequired()
      .when('description', {
        is: (value: string) => value?.length,
        then: rule =>
          rule
            .min(100, businessDetailsErrorMsg.descriptionLimit)
            .max(1000, businessDetailsErrorMsg.descriptionLimit),
      }),
  },
  [
    ['tagline', 'tagline'],
    ['description', 'description'],
  ],
);

const socialLinksErrorMsg = ErrorMessages.socialLinks;
export const socialLinksSchema = yup.object().shape({
  facebookUrl: yup
    .string()
    .trim()
    .matches(FACEBOOK_URL_REGEX, socialLinksErrorMsg.invalidFacebookUrl),
  // .required(socialLinksErrorMsg.invalidFacebookUrl),
  instagramUrl: yup
    .string()
    .trim()
    .matches(INSTAGRAM_URL_REGEX, socialLinksErrorMsg.invalidInstagramUrl),
  // .required(socialLinksErrorMsg.invalidInstagramUrl),
  linkedinUrl: yup
    .string()
    .trim()
    .matches(LINKEDIN_URL_REGEX, socialLinksErrorMsg.invalidLinkedInUrl),
  // .required(socialLinksErrorMsg.invalidLinkedInUrl),
  twitterUrl: yup.string().trim().matches(TWITTER_URL_REGEX, socialLinksErrorMsg.invalidTwitterUrl),
  // .required(socialLinksErrorMsg.invalidTwitterUrl),
});

// export const businessPaymentValidationSchema = yup.object().shape({
//   firstName: yup
//     .string()
//     .trim()
//     .min(1, paymentErrorMsg.firstName)
//     .matches(NAME_REGEX, paymentErrorMsg.firstName)
//     .required(paymentErrorMsg.firstName),
//   lastName: yup
//     .string()
//     .trim()
//     .min(1, paymentErrorMsg.firstName)
//     .matches(NAME_REGEX, paymentErrorMsg.firstName)
//     .required(paymentErrorMsg.firstName),
//   billingAddress: paymentAddressValidationSchema,
//   paymentInfo: paymentInfoValidationSchema(true), // true flag to include CVV Validation
// });

// For Free-Business edit
export const freeBusinessInformationSchema = yup.object().shape(
  {
    name: yup
      .string()
      .required(businessInformationErrorMsg.businessNameRequired)
      .min(2, businessInformationErrorMsg.businessNameRequired)
      .matches(BUSINESS_NAME_REGEX, businessInformationErrorMsg.businessNameRequired),
    phone: yup
      .string()
      .nullable()
      .notRequired()
      .when('phone', {
        is: (value: string | any[]) => value?.length,
        then: rule =>
          rule.matches(US_PHONE_NUMBER_REGEX, businessInformationErrorMsg.businessPhoneRequired),
      }),
    contactPersonName: yup
      .string()
      .nullable()
      .notRequired()
      .when('contactPersonName', {
        is: (value: string | any[]) => value?.length,
        then: rule =>
          rule
            .min(1, businessInformationErrorMsg.contactPersonName)
            .matches(NAME_REGEX, businessInformationErrorMsg.contactPersonName),
      }),
    email: yup
      .string()
      .email(businessInformationErrorMsg.email)
      .required(businessInformationErrorMsg.email),
    website: yup
      .string()
      .nullable()
      .notRequired()
      .when('website', {
        is: (value: string) => value?.length,
        then: rule => rule.matches(URL_REGEX, businessInformationErrorMsg.businessWebsiteRequired),
      }),
  },
  [
    ['name', 'name'],
    ['phone', 'phone'],
    ['contactPersonName', 'contactPersonName'],
    ['email', 'email'],
    ['website', 'website'],
  ],
);

// for Business-Setup (both free and paid)
export const setupBusinessInformationSchema = yup.object().shape({
  businessInformation: yup.object().shape({
    name: yup
      .string()
      .required(businessInformationErrorMsg.businessNameRequired)
      .min(2, businessInformationErrorMsg.businessNameRequired)
      .matches(BUSINESS_NAME_REGEX, businessInformationErrorMsg.businessNameRequired),
    phone: yup
      .string()
      .matches(US_PHONE_NUMBER_REGEX, businessInformationErrorMsg.businessPhoneRequired)
      .required(businessInformationErrorMsg.businessPhoneRequired),
    contactPersonName: yup
      .string()
      .required(businessInformationErrorMsg.contactPersonName)
      .min(1, businessInformationErrorMsg.contactPersonName)
      .matches(NAME_REGEX, businessInformationErrorMsg.contactPersonName),
    email: yup
      .string()
      .email(businessInformationErrorMsg.email)
      .required(businessInformationErrorMsg.email),
  }),
});

export const businessSetupSchema = yup.object().shape({
  businessInformation: businessInformationSchema,
  businessDetails: businessDetailsSchema,
  socialLinks: socialLinksSchema,
});

export const freeBusinessInfoSchema = yup.object().shape({
  businessInformation: freeBusinessInformationSchema,
});

export const profileBusinessInfoSchema = yup.object().shape({
  businessInformation: businessInformationSchema.concat(businessEditInformationSchema),
});

export const profileBusinessDetailsSchema = yup.object().shape({
  businessDetails: businessDetailsSchema,
  socialLinks: socialLinksSchema,
});

export const setupBusinessDetailsSchema = yup.object().shape({
  businessDetails: businessDetailsSchema,
});

export const setupBusinessSocialLinksSchema = yup.object().shape({
  socialLinks: socialLinksSchema,
});

const commentErrorMsg = ErrorMessages.addComment;
export const newsCommentValidationSchema = yup.object().shape({
  comment: yup
    .string()
    .trim()
    // .required(commentErrorMsg.comment)
    .min(1, commentErrorMsg.comment)
    .max(1000, commentErrorMsg.comment),
});

const affiliatePaymentReceiveMsg = ErrorMessages.affiliatePaymentReceiveForm;
export const affiliatePaymentReceiveValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required(affiliatePaymentReceiveMsg.name)
    .min(1, affiliatePaymentReceiveMsg.name)
    .matches(NAME_REGEX, affiliatePaymentReceiveMsg.name),
  routingNumber: yup
    .string()
    .required(affiliatePaymentReceiveMsg.routingNumber)
    .min(9, affiliatePaymentReceiveMsg.routingNumber),
  accountNumber: yup
    .string()
    .required(affiliatePaymentReceiveMsg.accountNumber)
    .min(8, affiliatePaymentReceiveMsg.accountNumber),
});

const addPodcastProviderErrorMsg = ErrorMessages.addPodcastProvider;
export const addPodcastProviderValidationSchema = yup.object().shape({
  name: yup.string().required(addPodcastProviderErrorMsg.name),
  externalShowLink: yup.string().required(addPodcastProviderErrorMsg.externalShowLink),
});

const profileErrorMsg = ErrorMessages.profileSetup;

export const profileEditInformationSchema = yup.object().shape(
  {
    fullName: yup
      .string()
      .nullable()
      .notRequired()
      .when('website', {
        is: (value: string) => value?.length,
        then: rule =>
          rule
            .min(2, profileErrorMsg.nameRequired)
            .matches(BUSINESS_NAME_REGEX, profileErrorMsg.nameRequired),
      }),
    tagline: yup
      .string()
      .nullable()
      .notRequired()
      .when('tagline', {
        is: (value: string) => value?.length,
        then: rule =>
          rule
            .min(20, businessDetailsErrorMsg.taglineLimit)
            .max(100, businessDetailsErrorMsg.taglineLimit),
      }),
    description: yup
      .string()
      .nullable()
      .notRequired()
      .when('description', {
        is: (value: string) => value?.length,
        then: rule =>
          rule
            .min(100, businessDetailsErrorMsg.descriptionLimit)
            .max(1000, businessDetailsErrorMsg.descriptionLimit),
      }),
  },
  [
    ['fullName', 'fullName'],
    ['tagline', 'tagline'],
    ['description', 'description'],
  ],
);

export const profileInformationSchema = yup.object().shape({
  fullName: yup
    .string()
    .required(profileErrorMsg.nameRequired)
    .min(2, profileErrorMsg.nameRequired)
    .matches(BUSINESS_NAME_REGEX, profileErrorMsg.nameRequired),
  tagline: yup
    .string()
    .required(profileErrorMsg.tagline)
    .min(20, businessDetailsErrorMsg.taglineLimit)
    .max(100, businessDetailsErrorMsg.taglineLimit),
});

export const setUpProfileInformationSchema = yup.object().shape({
  profileInformation: profileInformationSchema,
});

const addAdsErrorMsg = ErrorMessages.addAds;
export const addAdsValidationSchema = yup.object().shape({
  pagePosition: yup.string().required(addAdsErrorMsg.page),
  url: yup.string().when('sponsorType', {
    is: (value: string | any[]) => value?.includes('ad'),
    then: rule =>
      rule
        .required(addAdsErrorMsg.url)
        .matches(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
          addAdsErrorMsg.validUrl,
        ),
  }),
  startDate: yup.string().required(addAdsErrorMsg.startDate),
  endDate: yup.string().required(addAdsErrorMsg.endDate),
});

const addSponsorErrorMsg = ErrorMessages.addSponsor;
export const addSponsorValidationSchema = yup.object().shape({
  name: yup.string().required(addSponsorErrorMsg.name),
  url: yup
    .string()
    .required(addSponsorErrorMsg.url)
    .matches(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
      addSponsorErrorMsg.validUrl,
    ),
});
