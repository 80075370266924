import { US_STATES } from 'constants/constants';
import React from 'react';

import CustomSelectField, { ICustomSelectFieldProps } from './CustomSelectField';

function USStatesSelectField(props: ICustomSelectFieldProps) {
  return (
    <CustomSelectField {...props}>
      {US_STATES.map(state => (
        <option
          key={state}
          value={state === 'Federated States of Micronesia' ? 'Micronesia' : state}
        >
          {state}
        </option>
      ))}
    </CustomSelectField>
  );
}

export default USStatesSelectField;
