import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import theme from 'theme';

export default function NotFound() {
  return (
    <Stack alignItems="center" marginTop={10} spacing={theme.spacing(3)}>
      <Typography
        color="secondary"
        component="h1"
        sx={{ fontSize: '10rem', my: theme.spacing(10) }}
      >
        404
      </Typography>
      <Typography component="h4" variant="h4Bold">
        Page not found
      </Typography>
      <Link to="/">Go to Home Page</Link>
    </Stack>
  );
}
