import Box from '@mui/material/Box';
import React, { useEffect, useRef } from 'react';

interface IInfiniteScrollProps {
  onBottomHit: (initialLoad: boolean, type?: string) => void;
  isLoading: boolean;
  hasMoreData: boolean;
  loadOnMount: boolean;
  children: React.ReactNode | JSX.Element;
  valueToBeAddedToInnerHeight?: number;
}

function isBottom(ref: React.RefObject<HTMLDivElement>, valueToBeAddedToInnerHeight: number) {
  if (!ref.current) {
    return false;
  }
  return (
    ref.current.getBoundingClientRect().bottom <= window.innerHeight + valueToBeAddedToInnerHeight
  );
}

function InfiniteScroll({
  onBottomHit,
  isLoading,
  hasMoreData,
  loadOnMount,
  children,
  valueToBeAddedToInnerHeight = 300,
}: IInfiniteScrollProps) {
  const [initialLoad, setInitialLoad] = React.useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (loadOnMount && initialLoad) {
      onBottomHit(true);
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnMount, initialLoad]);

  useEffect(() => {
    const onScroll = () => {
      if (!isLoading && hasMoreData && isBottom(contentRef, valueToBeAddedToInnerHeight)) {
        onBottomHit(false);
      }
    };
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasMoreData]);

  return (
    <Box ref={contentRef} height="100%">
      {children}
    </Box>
  );
}

export default InfiniteScroll;
