import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import NotFound from 'components/notFound/NotFound';
import GuestReactionView from 'components/reactions/GuestReactionView';
import EpisodeTile from 'components/tile/EpisodeTile';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import { podcastUrl } from 'helpers/urls';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IPodcastEpisode } from 'store';
import getPostShareUrl from 'store/common/CommonActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getPodcastDetails,
  getPodcastShowEpisodes,
  resetPodcastDetails,
  resetPodcastShowEpisodesList,
} from 'store/podcast/PodcastActions';
import theme from 'theme';

export default function PodcastShow() {
  const dispatch = useAppDispatch();
  const { podcastShowId } = useParams();
  const { isEpisodeDetailsLoading, podcastEpisodesList, podcastDetails } = useAppSelector(
    s => s.podcast,
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('midxs'));
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { loading: shareLoading } = useAppSelector(s => s.common);
  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();
  const hasMoreData = podcastDetails?.episodeCount > podcastEpisodesList.length;

  const getPodcastEpisodesList = () => {
    if (podcastShowId) {
      dispatch(getPodcastShowEpisodes(podcastShowId as string, podcastEpisodesList.length));
    }
  };

  useEffect(() => {
    const fetchPodcastDetails = async () => {
      if (podcastShowId) {
        setLoading(true);
        await dispatch(getPodcastDetails(podcastShowId));
        setLoading(false);
      }
    };
    fetchPodcastDetails();
    getPodcastEpisodesList();
    return () => {
      dispatch(resetPodcastDetails());
      dispatch(resetPodcastShowEpisodesList());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, podcastShowId]);

  const sharePost = async () => {
    if (isUserLoggedIn()) {
      await dispatch(
        getPostShareUrl(`${podcastUrl}podcasts/${podcastShowId as string}/share?type=wall`),
      );
    } else {
      setShowModal(true);
    }
  };

  return (
    <HomeWrapper maxWidth="midsm">
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <ComponentWrapper
            withInfiniteScroll
            infiniteScrollProps={{
              loadOnMount: false,
              hasMoreData,
              isLoading: isEpisodeDetailsLoading,
              onBottomHit: getPodcastEpisodesList,
            }}
            title=""
          >
            <>
              {Object.keys(podcastDetails).length > 0 && (
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  spacing={theme.spacing(2)}
                  sx={{ mb: theme.spacing(3) }}
                >
                  <Grid
                    item
                    display="flex"
                    midxs={6}
                    paddingLeft={{
                      lg: theme.spacing(8),
                      md: theme.spacing(7),
                      sm: theme.spacing(3),
                      xs: theme.spacing(2),
                    }}
                    xs={12}
                  >
                    <Box
                      alt="episode-image"
                      component="img"
                      height="100%"
                      loading="lazy"
                      minHeight="100%"
                      overflow="hidden"
                      src={podcastDetails?.externalShowImages?.[0]?.url ?? ''}
                      sx={{
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                        borderRadius: '10px',
                      }}
                      width="100%"
                    />
                  </Grid>
                  <Grid item alignItems="center" display="flex" midxs={6} xs={12}>
                    <Stack spacing={theme.spacing(2)}>
                      <Typography
                        className="text-ellipsis text-clamp-11"
                        textAlign="justify"
                        variant="body1Bold"
                      >
                        {podcastDetails?.externalShowDescription}
                      </Typography>
                      <Link
                        href={podcastDetails?.externalShowLink}
                        rel="noopener"
                        sx={{
                          cursor: 'pointer',
                          textUnderlineOffset: '2px',
                          textAlign: 'center',
                        }}
                        target="_blank"
                        underline="hover"
                        variant="body3"
                      >
                        {podcastDetails?.externalShowName}
                      </Link>
                      <LoadingButton
                        disableRipple
                        disableTouchRipple
                        loading={shareLoading}
                        variant="contained"
                        onClick={() => sharePost()}
                      >
                        Share
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>
              )}
              {podcastEpisodesList && podcastEpisodesList.length > 0 ? (
                <>
                  {podcastEpisodesList.map((episode: IPodcastEpisode, index: number) => {
                    const image: string = episode?.thumbnail;

                    return (
                      <EpisodeTile
                        key={episode.episodeID + index.toString()}
                        description={episode.episodeDescription}
                        episodeId={episode.episodeID}
                        imageSrc={image}
                        showDivider={!(podcastEpisodesList.length === index + 1)}
                        tileType={isMobile ? 'small' : 'large'}
                        title={`${episode.episodeName} | ${new Date(
                          episode.externalCreatedAt,
                        ).toLocaleDateString()} `}
                      />
                    );
                  })}
                </>
              ) : (
                !loading &&
                !isEpisodeDetailsLoading &&
                Object.keys(podcastDetails).length === 0 &&
                podcastEpisodesList.length === 0 && <NotFound />
              )}
              {(loading || isEpisodeDetailsLoading) && <Loader />}
            </>
          </ComponentWrapper>
        </Grid>
      </Grid>
      {showModal && (
        <GuestReactionView isMobile={isMobile} setShowModal={setShowModal} showModal={showModal} />
      )}
    </HomeWrapper>
  );
}
