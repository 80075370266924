import { InputBaseProps } from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export interface ICustomLabelFieldProps extends InputBaseProps {
  label: string;
  value: string | string[];
}

function CustomLabelField({ label, value }: ICustomLabelFieldProps) {
  return (
    <Stack direction="column" spacing={0.2} sx={{ width: '100%' }}>
      <Typography component="span" variant="subtitle1Bold">
        {label}
      </Typography>
      {typeof value === 'string' ? (
        <Typography component="span" sx={{ overflowWrap: 'break-word' }} variant="subtitle1Bold">
          {value}
        </Typography>
      ) : (
        <>
          {value?.length > 0 &&
            value.map(t => {
              return (
                <Typography
                  component="span"
                  sx={{ overflowWrap: 'break-word' }}
                  variant="subtitle1Bold"
                >
                  {t}
                </Typography>
              );
            })}
        </>
      )}
    </Stack>
  );
}

export default CustomLabelField;
