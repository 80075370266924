export enum AlertType {
  CLEAR_ALERT = 'CLEAR_ALERT',
  ALERT_SUCCESS = 'ALERT_SUCCESS',
  ALERT_ERROR = 'ALERT_ERROR',
  ALERT_WARNING = 'ALERT_WARNING',
  ALERT_INFO = 'ALERT_INFO',
}

export enum AlertStatusTypes {
  ALERT_ERROR = 'error',
  ALERT_INFO = 'info',
  ALERT_SUCCESS = 'success',
  ALERT_WARNING = 'warning',
}
