import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ResourcesSubComponent from 'components/resources';
import HomeWrapper from 'components/wrapper/HomeWrapper';
import React, { useEffect } from 'react';
import { getResources, IResource } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { scrollToTop } from 'utils/utils';

interface Section {
  title: string;
  data: IResource[];
}

function Resources() {
  const dispatch = useAppDispatch();
  const { resources, loading } = useAppSelector(s => s.resource);

  const isLaptop = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    scrollToTop();
    if (!resources) {
      dispatch(getResources());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sectionInfo = React.useMemo(() => {
    const list: Section[] = [];
    if (resources?.historical) {
      list.push({ title: 'Historical Resources', data: resources?.historical });
    }
    if (resources?.current) {
      list.push({ title: 'Current Resources', data: resources?.current });
    }
    if (resources?.patriot_toolbox) {
      list.push({
        title: 'Patriotic Resources',
        data: resources?.patriot_toolbox,
      });
    }
    return list;
  }, [resources]);

  return (
    <HomeWrapper>
      {sectionInfo.map(item => {
        return (
          <Box marginBottom={5}>
            <ResourcesSubComponent
              isLaptop={isLaptop}
              loading={loading}
              resources={item.data}
              title={item.title}
            />
          </Box>
        );
      })}
    </HomeWrapper>
  );
}

export default Resources;
