import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'components/infiniteScroll/InfiniteScroll';
import Loader from 'components/loader/Loader';
import ModalContainer from 'components/modal/ModalContainer';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProfileMedia, IProfileMediaPostImage, resetProfileMediaState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

function ProfileMedia() {
  const { userId } = useParams();

  const dispatch = useAppDispatch();

  const {
    profileMediaLoading,
    profileMediaState: { profileMedia, hasMoreData, offset },
  } = useAppSelector(s => s.account);

  const { postImages } = profileMedia;

  const [selectedImage, setSelectedImage] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const getProfileMediaPosts = useCallback(
    (initialLoad: boolean) => {
      const params = {
        userId: userId || '',
        offset: initialLoad ? 0 : offset,
        limit: 8,
        reset: initialLoad,
      };
      dispatch(fetchProfileMedia(params));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offset, userId],
  );

  useEffect(() => {
    return () => {
      dispatch(resetProfileMediaState());
    };
  }, []);

  const onImageClick = (imageUrl: string, title: string) => {
    setModalTitle(title);
    setSelectedImage(imageUrl);
  };

  return (
    <>
      <InfiniteScroll
        loadOnMount
        hasMoreData={hasMoreData}
        isLoading={profileMediaLoading}
        onBottomHit={getProfileMediaPosts}
      >
        <Stack spacing={theme.spacing(5)}>
          <Stack width="100%">
            {postImages?.length > 0 ? (
              <Grid container spacing={theme.spacing(4)}>
                {postImages?.map((postImage: IProfileMediaPostImage, index: number) => (
                  <Grid
                    key={postImage.contentId + index.toString()}
                    item
                    md={3}
                    xs={12}
                    onClick={() =>
                      onImageClick(postImage.croppedImageUrl || postImage.imageUrl, 'Media')
                    }
                  >
                    <img
                      alt="posts-img"
                      className="cursor-pointer"
                      src={postImage.croppedImageUrl || postImage.imageUrl}
                      style={{ borderRadius: '10px' }}
                      width="100%"
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              !profileMediaLoading && (
                <Typography component="h6" variant="h6Bold">
                  No media available
                </Typography>
              )
            )}
            {profileMediaLoading && <Loader />}
          </Stack>
        </Stack>
      </InfiniteScroll>
      {selectedImage && (
        <ModalContainer
          showCloseIcon
          handleClose={() => setSelectedImage('')}
          open={selectedImage !== ''}
          title={modalTitle}
        >
          <Stack sx={{ margin: theme.spacing(2) }}>
            <img alt="img" src={selectedImage} style={{ borderRadius: '10px' }} width="100%" />
          </Stack>
        </ModalContainer>
      )}
    </>
  );
}

export default ProfileMedia;
