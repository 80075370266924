import Loader from 'components/loader/Loader';
import NoDataAvailable from 'components/notFound/NoDataAvailable';
import ProfilePostTile from 'components/tile/PostTile/ProfilePostTile';
import ComponentWrapper from 'components/wrapper/ComponentWrapper';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

function TownCrierPost() {
  const { checkUserLoggedIn: isUserLoggedIn } = useIsLoggedIn();
  const navigate = useNavigate();

  const { townCrierPost, townCrierLoading } = useAppSelector(s => s.homeTimeline);

  const navigateToProfilePost = (contentId: string, isComment: boolean) => {
    if (isComment) {
      if (isUserLoggedIn()) {
        navigate(`/town-crier/${contentId}`, {
          state: 'scroll-to-comments',
        });
      }
    } else {
      navigate(`/town-crier/${contentId}`);
    }
  };

  const navigateToProfileWall = () => {
    navigate(`/town-crier-wall/${townCrierPost?.postProviderId}`);
  };

  return (
    <ComponentWrapper borderColor="red" title="">
      {townCrierLoading ? (
        <Loader />
      ) : townCrierPost ? (
        <ProfilePostTile
          commentsCount={townCrierPost?.commentsCount}
          componentType="home"
          image={townCrierPost?.thumbnail || ''}
          isLiked={townCrierPost?.isLiked}
          isReported={townCrierPost?.isReported}
          likesCount={townCrierPost?.likesCount}
          navigateToProfilePost={(isComment: boolean) =>
            navigateToProfilePost(townCrierPost?.postId, isComment)
          }
          navigateToProfileWall={navigateToProfileWall}
          postedAt={townCrierPost?.postedAt || ''}
          postId={townCrierPost.postId}
          postType="town-crier-post"
          textContent={townCrierPost?.description}
          type="home-town-crier-post"
          userId={townCrierPost?.postProviderId}
          userImage={townCrierPost.postProviderLogo}
          userName={townCrierPost.postProvider}
          withDivider={false}
        />
      ) : (
        <NoDataAvailable />
      )}
    </ComponentWrapper>
  );
}

export default TownCrierPost;
