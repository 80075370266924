enum AuthenticationType {
  RESET_AUTH_STATE = 'RESET_AUTH_STATE',
  USER_LOGOUT = 'USER_LOGOUT',

  LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST',
  LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS',
  LOGIN_USER_ERROR = 'LOGIN_USER_ERROR',

  SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST',
  SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS',
  SIGNUP_USER_ERROR = 'SIGNUP_USER_ERROR',

  SEND_RESET_PASSWORD_REQUEST = 'SEND_RESET_PASSWORD_REQUEST',
  SEND_RESET_PASSWORD_SUCCESS = 'SEND_RESET_PASSWORD_SUCCESS',
  SEND_RESET_PASSWORD_ERROR = 'SEND_RESET_PASSWORD_ERROR',

  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR',

  UPDATE_LOGGEDIN_STATUS = 'UPDATE_LOGGEDIN_STATUS',

  SEND_VERIFICATION_EMAIL_REQUEST = 'SEND_VERIFICATION_EMAIL_REQUEST',
  SEND_VERIFICATION_EMAIL_SUCCESS = 'SEND_VERIFICATION_EMAIL_SUCCESS',
  SEND_VERIFICATION_EMAIL_ERROR = 'SEND_VERIFICATION_EMAIL_ERROR',

  VERIFY_TOKEN_REQUEST = 'VERIFY_TOKEN_REQUEST',
  VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS',
  VERIFY_TOKEN_ERROR = 'VERIFY_TOKEN_ERROR',

  VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST',
  VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR',
}

export default AuthenticationType;
