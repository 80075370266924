import { Action, combineReducers, Reducer } from 'redux';

import AccountReducer from './account/AccountReducer';
import VettingDashboardReducer from './admin/vettingDashboard/VettingDashboardReducer';
import AdsReducer from './advertisements/AdvertisementReducer';
import AffiliateReducer from './affiliate/AffiliateReducer';
import AlertReducer from './alerts/AlertReducer';
import AuthReducer from './authentication/AuthenticationReducer';
import AuthenticationType from './authentication/AuthenticationTypes';
import BusinessReducer from './business/BusinessReducer';
import CommentReducer from './comment/CommentReducer';
import CommonReducer from './common/CommonReducer';
import FollowingTimelineReducer from './followingTimeline/FollowingTimelineReducer';
import HomeTimelineReducer from './homeTimeline/HomeTimelineReducer';
import NewsReducer from './news/NewsReducer';
import PaymentReducer from './payment/PaymentReducer';
import PodcastReducer from './podcast/PodcastReducer';
import ResourceReducer from './resource/ResourceReducer';
import SearchReducer from './search/SearchReducer';
import TrendingReducer from './trending/TrendingReducer';

const combinedReducer = combineReducers({
  common: CommonReducer,
  ads: AdsReducer,
  auth: AuthReducer,
  alert: AlertReducer,
  account: AccountReducer,
  business: BusinessReducer,
  payment: PaymentReducer,
  news: NewsReducer,
  comment: CommentReducer,
  resource: ResourceReducer,
  podcast: PodcastReducer,
  homeTimeline: HomeTimelineReducer,
  vettingDashboard: VettingDashboardReducer,
  search: SearchReducer,
  followingTimeline: FollowingTimelineReducer,
  affiliate: AffiliateReducer,
  trending: TrendingReducer,
});

type AppState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer<AppState, Action> = (state, action) => {
  // action to clear application redux
  if (action.type === AuthenticationType.USER_LOGOUT) {
    // eslint-disable-next-line no-param-reassign
    state = {} as AppState;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
