import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'components/loader/Loader';
import React, { useCallback, useEffect, useState } from 'react';
import {
  fetchFollowedBusinesses,
  followBusinesses,
  getPreferencesAllBusinesses,
  IBusiness,
  IResponseData,
  resetAllBusinessesState,
  resetBusinessSearchState,
  searchBusinesses,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

import SavePreferencesBtn from '../SavePreferencesBtn';

interface IProps {
  searchQuery: string;
}

function Businesses({ searchQuery }: IProps) {
  const dispatch = useAppDispatch();
  const {
    allBusinesses,
    searchedBusinesses,
    allBusinessLoading,
    followBusinessesLoading,
    isSearchBusinessLoading,
    followedBusinesses,
  } = useAppSelector(s => s.followingTimeline);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [businesses, setBusinesses] = useState<IBusiness[]>([]);
  const [selectedBusinessIds, setSelectedBusinessIds] = useState<string[]>([]);

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const fetchAllBusinesses = async (offset: number) => {
    const response = (await dispatch(getPreferencesAllBusinesses(offset))) as IResponseData;
    if (!response.error) {
      const allBusinesses: IBusiness[] =
        offset === 0 ? [...response.data] : [...businesses, ...response.data];
      setBusinesses(allBusinesses);
    }
  };

  const fetchFollowedBusinessIds = async () => {
    const response = (await dispatch(fetchFollowedBusinesses())) as IResponseData;
    if (!response.error) {
      setSelectedBusinessIds(response.data.businessIds || []);
    }
  };

  const fetchSearchBusinesses = async (isSameQuery: boolean) => {
    if (!isSameQuery) {
      setBusinesses([]);
    }
    const response = (await dispatch(
      searchBusinesses(searchQuery, isSameQuery ? searchedBusinesses.page + 1 : 1),
    )) as IResponseData;
    if (!response.error) {
      let allSearchBusinesses: IBusiness[] = [];
      if (isSameQuery) {
        allSearchBusinesses = [...businesses, ...response.data];
        setBusinesses(allSearchBusinesses);
      } else {
        allSearchBusinesses = [...response.data];
        setBusinesses(allSearchBusinesses);
      }
    }
  };

  const handleScroll = (event: any) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;

    if (scrollHeight <= Math.ceil(scrollTop + clientHeight)) {
      if (searchQuery.length >= 3 && businesses.length > 0) {
        if (searchedBusinesses.hasMoreData && !isSearchBusinessLoading) {
          fetchSearchBusinesses(true);
        }
      } else if (searchQuery.length < 3 && allBusinesses.hasMoreData && !allBusinessLoading) {
        fetchAllBusinesses(allBusinesses.offset);
      }
    }
  };

  const handleBusinessCheck = (index: number) => {
    const businessIds = [...selectedBusinessIds];
    const businessIdIndex = businessIds.findIndex(x => x === businesses[index].documentId);
    if (businessIdIndex === -1) {
      businessIds.push(businesses[index].documentId);
    } else {
      businessIds.splice(businessIdIndex, 1);
    }
    setSelectedBusinessIds(businessIds);
  };

  const saveSelectedBusinesses = async () => {
    const response = (await dispatch(
      followBusinesses(selectedBusinessIds, businesses, searchQuery.length > 0),
    )) as IResponseData;
    if (!response.error) {
      fetchFollowedBusinessIds();
    }
  };

  const handleDisable = useCallback(() => {
    const businessIdsSelectedSorted = followedBusinesses.slice().sort();
    return (
      selectedBusinessIds.length === followedBusinesses.length &&
      selectedBusinessIds
        .slice()
        .sort()
        .every(function (value, index) {
          return value === businessIdsSelectedSorted[index];
        })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBusinesses.businesses, businesses, searchedBusinesses.businesses, selectedBusinessIds]);

  useEffect(() => {
    setIsLoading(true);
    fetchAllBusinesses(allBusinesses.offset);
    fetchFollowedBusinessIds();
    return () => {
      dispatch(resetAllBusinessesState());
      dispatch(resetBusinessSearchState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchQuery.length === 0 && searchedBusinesses.query.length > 0) {
      if (searchedBusinesses.businesses.length > 0) {
        setBusinesses([]);
        dispatch(resetBusinessSearchState());
        dispatch(resetAllBusinessesState());
        setIsLoading(true);
        fetchAllBusinesses(0);
      } else {
        setBusinesses(allBusinesses.businesses.length > 0 ? allBusinesses.businesses : []);
      }
    }
    const debounceSearchFn = setTimeout(async () => {
      if (searchQuery.length >= 3) {
        const isSameQuery = searchQuery === searchedBusinesses.query;
        if (isSameQuery) {
          return;
        }
        setIsLoading(true);
        fetchSearchBusinesses(isSameQuery);
      }
    }, 200);
    return () => clearTimeout(debounceSearchFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (allBusinessLoading !== isLoading) {
      setIsLoading(allBusinessLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBusinessLoading]);

  useEffect(() => {
    if (isSearchBusinessLoading !== isLoading) {
      setIsLoading(isSearchBusinessLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchBusinessLoading]);

  return (
    <>
      <Stack
        className="custom-pref-scrollbar"
        height={isMobile ? 'calc(100vh - 275px)' : '100%'}
        paddingBottom={theme.spacing(1)}
        paddingRight={theme.spacing(2)}
        spacing={theme.spacing(1.5)}
        sx={{
          overflowY: 'auto',
        }}
        onScroll={handleScroll}
      >
        {businesses && businesses.length > 0
          ? businesses.map(
              (business: IBusiness, index: number) =>
                business.role !== 'owner' && (
                  <Stack
                    key={business.documentId + index.toString()}
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Stack alignItems="center" direction="row">
                      <Avatar
                        alt={business.name ?? 'provider-logo'}
                        src={business.imageLogo.croppedImageReadUrl || business.name}
                        sx={{
                          width: theme.spacing(3),
                          height: theme.spacing(2.75),
                          background: theme.palette.secondary.main,
                          marginRight: theme.spacing(2),
                        }}
                        variant="square"
                      />
                      <Typography className="text-ellipsis text-clamp-2">
                        {business.name}
                      </Typography>
                    </Stack>
                    <IconButton
                      aria-label="remove"
                      color="primary"
                      size="medium"
                      sx={{ padding: theme.spacing(0) }}
                      onClick={() => handleBusinessCheck(businesses.indexOf(business))}
                    >
                      {selectedBusinessIds.includes(business.documentId) ? (
                        <CheckCircleIcon fontSize="inherit" />
                      ) : (
                        <ControlPointIcon fontSize="inherit" />
                      )}
                    </IconButton>
                  </Stack>
                ),
            )
          : !isLoading && (
              <Stack alignItems="center" flexGrow={1} height="100%" justifyContent="center">
                <Typography align="center" component="div" variant="title2">
                  No businesses found
                </Typography>
              </Stack>
            )}
        {isLoading && <Loader customPaddingThemeSpacing={1} size={30} />}
      </Stack>
      <SavePreferencesBtn
        disableFn={handleDisable}
        loading={followBusinessesLoading}
        onSave={saveSelectedBusinesses}
      />
    </>
  );
}

export default Businesses;
