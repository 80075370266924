import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomInputField from 'components/customFields/CustomInputField';
import PasswordEyeIcon from 'components/customFields/PasswordEyeIcon';
import { memo, useState } from 'react';
import { changeUserPassword, IChangePasswordState, IResponseData } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';
import { handleRestrictSpaces } from 'utils/utils';
import { changePasswordValidationSchema } from 'utils/validationSchema';

import useChangePasswordForm from './useChangePasswordForm';

interface IChangePasswordProps {
  closeModal: () => void;
}

function ChangePassword({ closeModal }: IChangePasswordProps) {
  const dispatch = useAppDispatch();
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isChangePasswordLoading } = useAppSelector(s => s.account);

  const togglePassword = (type = 'old') => {
    if (type === 'old') setShowOldPassword(!showOldPassword);
    else if (type === 'new') setShowNewPassword(!showNewPassword);
    else setShowConfirmPassword(!showConfirmPassword);
  };

  const initialState: IChangePasswordState = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const handelSubmit = async () => {
    const response: IResponseData = (await dispatch(
      changeUserPassword(values.newPassword, values.currentPassword),
    )) as IResponseData;
    if (!response.error) {
      closeModal();
    }
  };

  const formik = useChangePasswordForm(handelSubmit, initialState, changePasswordValidationSchema);
  const { touched, values, errors, handleChange, handleBlur, handleSubmit, isValid } = formik;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
  ) => {
    if (e.target.name === 'currentPassword') {
      handleChange(fieldName)(e.target.value);
    } else {
      handleChange(fieldName)(handleRestrictSpaces(e.target.value));
    }
  };

  return (
    <Stack alignItems="center" justifyContent="center" width="100%">
      <Stack
        noValidate
        alignItems="center"
        autoComplete="off"
        component="form"
        spacing={theme.spacing(3)}
        width="100%"
        onSubmit={handleSubmit}
      >
        <CustomInputField
          fullWidth
          required
          endAdornment={
            <PasswordEyeIcon
              handleOnClick={() => togglePassword('old')}
              showPassword={showOldPassword}
            />
          }
          error={!!(touched.currentPassword && errors.currentPassword)}
          errormessage={touched.currentPassword ? errors.currentPassword : undefined}
          id="currentPassword"
          label="Current Password"
          name="currentPassword"
          type={showOldPassword ? 'text' : 'password'}
          value={values.currentPassword}
          onChange={e => handleInputChange(e, 'currentPassword')}
        />
        <CustomInputField
          fullWidth
          required
          endAdornment={
            <PasswordEyeIcon
              handleOnClick={() => togglePassword('new')}
              showPassword={showNewPassword}
            />
          }
          error={!!(touched.newPassword && errors.newPassword)}
          errormessage={touched.newPassword ? errors.newPassword : undefined}
          id="newPassword"
          label="New Password"
          name="newPassword"
          type={showNewPassword ? 'text' : 'password'}
          value={values.newPassword}
          onBlur={handleBlur}
          onChange={e => handleInputChange(e, 'newPassword')}
        />
        <CustomInputField
          fullWidth
          required
          endAdornment={
            <PasswordEyeIcon
              handleOnClick={() => togglePassword('')}
              showPassword={showConfirmPassword}
            />
          }
          error={!!(touched.confirmPassword && errors.confirmPassword)}
          errormessage={touched.confirmPassword ? errors.confirmPassword : undefined}
          id="confirmPassword"
          label="Confirm Password"
          name="confirmPassword"
          type={showConfirmPassword ? 'text' : 'password'}
          value={values.confirmPassword}
          onBlur={handleBlur}
          onChange={e => handleInputChange(e, 'confirmPassword')}
        />
        <Stack
          alignItems="center"
          direction={isMobile ? 'column' : 'row'}
          justifyContent="center"
          marginTop={`${theme.spacing(4)} !important`}
          spacing={theme.spacing(3)}
          width="100%"
        >
          <Button fullWidth size="large" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <LoadingButton
            disableRipple
            disableTouchRipple
            fullWidth
            disabled={!isValid}
            loading={isChangePasswordLoading}
            size="large"
            type="submit"
            variant="contained"
          >
            Change Password
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default memo(ChangePassword);
