import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React from 'react';
import { IBusinessCategory, setFilterBusinessCategories } from 'store';
import { useAppDispatch } from 'store/hooks';
import theme from 'theme';
import styles from 'theme/styles';

interface ICategoryFilterProps {
  categories: IBusinessCategory[];
  isTablet: boolean;
  businessCategories: IBusinessCategory[];
  handleSubmit: () => void;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" sx={{ color: 'secondary.main' }} />;
export default function CategoryFilter(props: ICategoryFilterProps) {
  const dispatch = useAppDispatch();
  const { categories, isTablet, handleSubmit, businessCategories } = props;
  return (
    <Stack
      alignItems="center"
      direction={isTablet ? 'column' : 'row'}
      justifyContent="center"
      spacing={theme.spacing(2)}
      sx={{ width: '100%', marginBottom: theme.spacing(2) }}
    >
      <Stack spacing={0.75} sx={{ width: '100%' }}>
        <FormControl fullWidth>
          <Autocomplete
            disableCloseOnSelect
            multiple
            getLimitTagsText={(more: number) =>
              categories.length === 3 ? `+${more} category` : `+${more} categories`
            }
            getOptionLabel={option => option.name}
            id="business-directory-category-chip-input"
            isOptionEqualToValue={(option, value) => option.documentId === value.documentId}
            limitTags={2}
            options={businessCategories}
            renderInput={params => (
              <TextField
                name="businessInformation.businessCategories"
                {...params}
                placeholder={categories.length > 0 ? '' : 'Filter by Business Category'}
                sx={{
                  '& .MuiInputBase-root': {
                    borderRadius: '4px',
                    width: '100%',
                    padding: theme.spacing(1.25, 1.5),
                    '& .MuiInputBase-input': {
                      padding: 0,
                    },
                    '& fieldset': {
                      border: 'unset',
                    },
                  },
                  '& .MuiChip-root': {
                    height: '26px',
                    border: 'unset',
                    background: theme.palette.secondary.main,
                    '& .MuiChip-label': {
                      padding: theme.spacing(0, 1.5),
                      color: theme.palette.common.white,
                    },
                    '& .MuiChip-deleteIcon': {
                      color: theme.palette.common.white,
                    },
                    '&:hover': {
                      background: theme.palette.secondary.dark,
                    },
                  },
                  border: `0.25px solid ${theme.palette.text.secondary}`,
                  borderRadius: '4px',
                  padding: 0,
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '22px',
                  position: 'relative',
                }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  checked={selected}
                  checkedIcon={checkedIcon}
                  icon={icon}
                  sx={{
                    marginRight: theme.spacing(1),
                  }}
                />
                {option.name}
              </li>
            )}
            sx={{
              '.MuiOutlinedInput-root': {
                border: 0,
              },
            }}
            value={categories}
            onChange={(event, newValue) => {
              dispatch(setFilterBusinessCategories(newValue));
            }}
          />
        </FormControl>
      </Stack>
      <Stack>
        <Button
          disableRipple
          disableTouchRipple
          fullWidth
          size="medium"
          sx={{ ...styles.profileBtn, marginTop: 0 }}
          variant="contained"
          onClick={() => handleSubmit()}
        >
          Apply
        </Button>
      </Stack>
    </Stack>
  );
}
