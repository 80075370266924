import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/system/Stack';
import AuthWrapper from 'components/wrapper/AuthWrapper';
import ErrorMessages from 'constants/ErrorMessages';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  alertError,
  alertSuccess,
  IResponseData,
  sendVerificationMail,
  verifyEmail,
  verifyToken,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import theme from 'theme';

function ActivateAccount() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { checkUserLoggedIn } = useIsLoggedIn();
  const { loading } = useAppSelector(s => s.auth);

  const [linkExpired, setLinkExpired] = useState<boolean>(false);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [verifyEmailError, setVerifyEmailError] = useState<boolean>(false);
  const { token, email } = useParams();

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  const handleVerifyEmail = async () => {
    if (token) {
      const verifyTokenResponse = (await dispatch(verifyToken(token))) as IResponseData;

      if (!verifyTokenResponse.error) {
        const verifyEmailResponse = (await dispatch(verifyEmail(token))) as IResponseData;

        if (!verifyEmailResponse.error) {
          setEmailVerified(true);

          setTimeout(() => {
            if (location.pathname.includes('/change-email') && checkUserLoggedIn()) {
              navigate('/home');
            } else {
              navigate('/login');
            }
          }, 1000);
        } else {
          setVerifyEmailError(true);
        }
      } else {
        setLinkExpired(true);
      }
    }
  };

  useEffect(() => {
    handleVerifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResendMail = async () => {
    const response: IResponseData = (await dispatch(
      sendVerificationMail(encodeURIComponent(email as string)),
    )) as IResponseData;
    if (response.error) {
      dispatch(alertError({ message: response.message }));
    } else {
      dispatch(
        alertSuccess({
          message: ErrorMessages.verificationEmail.resendMailSuccess,
        }),
      );
    }
  };

  return (
    <AuthWrapper>
      <Stack alignItems="center" height="100%" width="100%">
        <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(2.5)}>
          <Typography component="div" variant={isMobile ? 'h5' : 'h2'}>
            Email Verification
          </Typography>
          {loading && <CircularProgress />}
          {emailVerified && (
            <Typography component="div" fontWeight={400} variant={isMobile ? 'menu' : 'title2'}>
              Your Email has been Verified!
            </Typography>
          )}
          {verifyEmailError && (
            <Typography component="div" fontWeight={400} variant={isMobile ? 'menu' : 'title2'}>
              Your Email is not verified, please try again later.
            </Typography>
          )}
          {linkExpired && (
            <>
              <Typography
                align="center"
                component="div"
                fontWeight={400}
                sx={{ color: theme.palette.text.secondary }}
                variant={isMobile ? 'menu' : 'title2'}
              >
                {ErrorMessages.verificationEmail.linkExpired}
              </Typography>
              <Typography
                color={theme.palette.text.secondary}
                component="span"
                fontWeight={400}
                variant="h6"
              >
                Click here to
                <Typography
                  color={theme.palette.secondary.main}
                  component="span"
                  sx={{ cursor: 'pointer', ml: theme.spacing(0.5) }}
                  onClick={handleResendMail}
                >
                  request a new email
                </Typography>
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
    </AuthWrapper>
  );
}

export default ActivateAccount;
