import { LoadingButton } from '@mui/lab';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import CustomInputField from 'components/customFields/CustomInputField';
import ErrorMessages from 'constants/ErrorMessages';
import { useAdminAddUserForm } from 'pages/signUp/useSignupForm';
import { useState } from 'react';
import { checkUserExists, IAdminAddUserState, IMembers, IResponseData, signUpUser } from 'store';
import { useAppDispatch } from 'store/hooks';
import theme from 'theme';
import { handleRestrictSpaces } from 'utils/utils';
import { adminAddUserValidationSchema } from 'utils/validationSchema';

interface IAdminAddUserProps {
  onUserCreate: (member: IMembers) => void;
}

function AdminAddUser({ onUserCreate }: IAdminAddUserProps) {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));
  const [loading, setLoading] = useState(false);

  const initialState: IAdminAddUserState = {
    userName: '',
    email: '',
  };

  const handleCreateMember = async () => {
    setLoading(true);
    if (values.email && values.userName) {
      const signupErrorMsg = ErrorMessages.signup;
      const response: IResponseData = (await checkUserExists(
        `email=${encodeURIComponent(values.email ?? '')}&userName=${encodeURIComponent(
          values.userName ?? '',
        )}`,
      )) as IResponseData;

      const { emailExists, userNameExists } = response.data;
      if (!emailExists && !userNameExists) {
        const userPayload = {
          email: values.email,
          userName: values.userName,
          userType: 'free',
        };
        const { data: response }: IResponseData = (await dispatch(
          signUpUser(userPayload),
        )) as IResponseData;
        setLoading(false);

        if (response) {
          onUserCreate(response?.user);
        }
      } else {
        setLoading(false);
        if (response?.data?.userNameExists) {
          setFieldTouched('userName', true, false);
          setFieldError('userName', signupErrorMsg.userNameExists);
        }
        if (response?.data?.emailExists) {
          setFieldTouched('email', true, false);
          setFieldError('email', signupErrorMsg.emailExists);
        }
      }
    }
  };

  const formik = useAdminAddUserForm(
    handleCreateMember,
    initialState,
    adminAddUserValidationSchema,
  );
  const {
    dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    touched,
    values,
    setFieldError,
    setFieldTouched,
  } = formik;

  return (
    <Stack margin={2}>
      <Typography
        noWrap
        align="center"
        color="text.secondary"
        component="h2"
        paddingY={2}
        variant={isMobile ? 'h5' : 'h4'}
      >
        Create New Member
      </Typography>

      <Stack
        noValidate
        autoComplete="off"
        border={1}
        borderRadius={2}
        component="form"
        mt={2}
        padding={5}
        paddingX={5}
        spacing={theme.spacing(4)}
      >
        <Stack spacing={theme.spacing(2.5)}>
          <CustomInputField
            fullWidth
            required
            error={!!(touched.email && errors.email)}
            errormessage={touched.email ? errors.email : undefined}
            id="email"
            label="Email"
            name="email"
            type="email"
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <CustomInputField
            fullWidth
            required
            error={!!(touched.userName && errors.userName)}
            errormessage={touched.userName ? errors.userName : undefined}
            id="userName"
            inputProps={{ maxLength: 75 }}
            label="Username"
            name="userName"
            type="text"
            value={values.userName}
            onBlur={handleBlur}
            onChange={e => handleChange('userName')(handleRestrictSpaces(e.target.value))}
          />
        </Stack>
        <Stack alignItems="center" spacing={theme.spacing(3)} width="100%">
          <LoadingButton
            fullWidth
            disabled={!(isValid && dirty)}
            loading={loading}
            size="large"
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Create
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default AdminAddUser;
