import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomInputField from 'components/customFields/CustomInputField';
import Loader from 'components/loader/Loader';
import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { IUserProfileInfoState } from 'store/account/AccountInterface';
import { useAppSelector } from 'store/hooks';
import theme from 'theme';

interface IProfileDetailsProps {
  touched?: FormikTouched<IUserProfileInfoState>;
  values: IUserProfileInfoState;
  errors?: FormikErrors<IUserProfileInfoState>;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T1 = string | React.ChangeEvent<any>>(field: T1): T1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: (e: React.FocusEvent) => void;
}

export default function ProfileDetailsForm(props: IProfileDetailsProps) {
  const { handleBlur, handleChange, errors, touched, values } = props;

  const { loading } = useAppSelector(s => s.account);

  const isMobile = useMediaQuery(theme.breakpoints.down('midsm'));

  return (
    <Grid container rowSpacing={theme.spacing(2.5)}>
      <Grid item xs={12}>
        <Typography align="center" variant={isMobile ? 'h5' : 'h4'}>
          Profile Information
        </Typography>
      </Grid>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid item xs={12}>
            <Stack alignItems="center" justifyContent="center" spacing={theme.spacing(2.5)}>
              <CustomInputField
                fullWidth
                required
                error={!!(touched?.fullName && errors?.fullName)}
                errormessage={touched?.fullName ? errors?.fullName : undefined}
                id="fullName"
                inputProps={{ maxLength: 75 }}
                label="Display Name"
                name="fullName"
                type="text"
                value={values.fullName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <CustomInputField
                fullWidth
                required
                error={!!(touched?.tagline && errors?.tagline)}
                errormessage={touched?.tagline ? errors?.tagline : undefined}
                id="tagline"
                inputProps={{ maxLength: 75 }}
                label="Tagline"
                name="tagline"
                type="text"
                value={values.tagline}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <CustomInputField
                fullWidth
                multiline
                required
                error={!!(touched?.description && errors?.description)}
                errormessage={touched?.description ? errors?.description : undefined}
                id="description"
                label="Description"
                name="description"
                rows={10}
                type="textarea"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Stack>
          </Grid>
        </>
      )}
    </Grid>
  );
}
